
export function desc(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  export function stableSort(array: any, cmp: any) {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
  }
  
  export function getSorting(order: any, orderBy: any) {
    return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
  }

  /**
   * 千分位加,
   */
  export function formatNumber(value:number){
    var reg=/\d{1,3}(?=(\d{3})+$)/g;   
    return (value + '').replace(reg, '$&,');  
  }
 /**
  * 还原数值
  */
  export function parseNumber(value:string){
    return value.replace(',','')
  }