import React from 'react';

import { EditorState } from 'draft-js';

import * as types from './types'
import { styles } from '../renders'

import Button from './Button'
import Icon from './Icon'
import OptionModal from './OptionModal'

const defaultOptions = [
    { label: '8', value: '8px' },
    { label: '9', value: '9px' },
    { label: '10', value: '10px' },
    { label: '12', value: '12px' },
    { label: '14', value: '14px' },
    { label: '16', value: '16px' },
    { label: '18', value: '18px' },
    { label: '24', value: '24px' },
    { label: '30', value: '30px' },
    { label: '36', value: '36px' },
    { label: '48', value: '48px' },
    { label: '72', value: '72px' }
]

const FontSize = (props: any) => {
    const {
        onChange,
        editorState,
        title,
        icon,
        options
    } = props

    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    function handleClick(event: any) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    const toggle = (event:any,value: string) => {
        event.preventDefault();
        const nextContentState = editorState.getCurrentContent()
        let nextEditorState = EditorState.push(
            editorState, 
            nextContentState,
            'change-inline-style'
        );
        nextEditorState = styles.fontSize.toggle(editorState, `${value}`);
        onChange(nextEditorState);
    }

    return (<>
        <Button onMouseDown={handleClick} title={title[0]} >
            <Icon name={icon} width={18} height={18} />
        </Button>

        <OptionModal {...{
            anchorEl,
            options: options || defaultOptions,
            select: (event:any,option: types.Option) => {
                toggle(event,option.value)
                setAnchorEl(null);
            },
            cancel: () => {
                setAnchorEl(null);
            }
        }}>
        </OptionModal>
    </>
    );

}

export default FontSize