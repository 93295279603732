import React, { useState } from 'react';
import Box from '@material-ui/core/Box'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Tree from './Tree';
import TreeItem from './TreeItem'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    drawer: {
      padding:0,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
  }))
export type RadioProps = {
  show:boolean,
  value:string,
  onChange:any,
  name:string
}
export type TreeViewProps = {
    className?:any,
    current?:any,
    items:any,
    renderNode?:(item:any)=>{},
    padding?:number,
    onItemClick?:(item:any)=>{},
    onItemSelected?:(item:any,selected:any)=>{},
    onChecked?:(checked:any)=>{},
    showCheckbox?:boolean,
    showDescri?:boolean
}
const TreeView = (props:TreeViewProps) => {
  const {
    current,
    items,
    renderNode,
    padding = 15,
    onItemClick,
    onItemSelected,
    onChecked,
    showCheckbox,
    showDescri
  } = props
  const [opens, setOpens] = useState();
  const [selected,setSelected] =  useState();
  const [checked,setChecked] =  useState();
  const classes = useStyles()

  const handleOpens = (item:any) => {
    const current = opens || {}
    current[item.id] = !current[item.id]
    setOpens(Object.assign({}, current))
  };

  // 打开当前所属的上级
  const openParents = (item:any, selfOpen:boolean) => {
    const current:any = {}
    if (selfOpen) {
      current[item.id] = true
    }
    item.parents.map((id:string) => {
      current[id] = true
    })
    setOpens(Object.assign({}, current))
  }
  const handlerChecked = (event:any,ck: boolean,item:any) => {
    const newChecked = Object.assign({},checked||{})
    if(ck){
      newChecked[item.id] = item
    }else if(newChecked[item.id]){
      // 移除选中
      delete newChecked[item.id]
    }
    setChecked(newChecked)
    if(onChecked){
      onChecked(newChecked)
    }
  }

  const handleItemClick = (event: any, item:any) => {
    const newSelected = Object.assign({},selected||{})
    if (newSelected[item.id]) {
      // 移除选中
      delete newSelected[item.id]
      setSelected(newSelected)
    }else{
      // 设置选中
      newSelected[item.id] = item
    }
    setSelected(newSelected)
    if(onItemClick){
      onItemClick(item)
    }
    if(onItemSelected){
      onItemSelected(item,newSelected)
    }
  }
  /** 
   *  渲染树节点
   */
  const renderTreeItem = (item:any,parents:string[],paddingLeft = 0) => {
    const active = (current && current.id === item.id)
    // 设置上级节点
    parents = Object.assign([],parents||[])
    if(parents.length === 0){
      parents.push(item.id)
    }
    if (parents !== item.id)
      parents.unshift(item.id)
    item['parents'] = parents

    if (active && opens && !opens[item.id]) {
      // 打开所有上级节点
      openParents(item,true)
    }
    if (item.children && item.children.length > 0) {
      // 渲染子菜单
      return (
        <Tree
          handleOpens={() => handleOpens(item)}
          {...{
            active,
            showCheckbox,
            showDescri,
            isOpen: opens && opens[item.id],
            icon: item.icon,
            label: item.label || item.name || item.title,
            descri:item.descri,
            value: item.id,
            handleItemClick:(event:any)=>{
              handleItemClick(event,item)
            },            
            handlerChecked:(event:any,checked:boolean)=>{
              handlerChecked(event,checked,item)
            },
            paddingLeft:paddingLeft+padding,
            renderNode:()=>{
              if(renderNode)
              return renderNode(item)
            }
          }}
        >{renderTree(item.children,parents,paddingLeft+padding)}</Tree>)
    }
    // 渲染子菜单条目
    return (
        <TreeItem
          handleOpens={() => handleOpens(item)}
          handleItemClick={(event:any)=>{
            handleItemClick(event,item)
          }}
          {...{
            active,
            showCheckbox,
            showDescri,
            isOpen: false,
            icon: item.icon,
            label: item.label || item.name || item.title,
            descri:item.descri,
            value: item.id,
            paddingLeft:paddingLeft+padding,
            handlerChecked:(event:any,checked:boolean)=>{
              handlerChecked(event,checked,item)
            },
            renderNode:()=>{
              if(renderNode)
              return renderNode(item)
            }
          }} />)
  }
  /**
   *  渲染菜单组
   */
  const renderTree = (items:any,parents:string[],paddingLeft = 0) => (<>
    {items.map((item:any, index:number) => (<Box key={index}>
      {renderTreeItem(item,parents,paddingLeft)}
    </Box>))
    }</>)
  if(items && items.length > 0){
    return (
        <Box 
            className={classes.drawer}
          > 
          {renderTree(items,[],0)}
        </Box>
    );
  }else{
    return <></>
  }
}

export default TreeView