import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="2353"
    width={width} height={height}><path fill={color} d="M597.333333 896c-4.266667 0-12.8 0-17.066667-4.266667C563.2 887.466667 554.666667 870.4 554.666667 853.333333l0-213.333333c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 119.466667 277.333333-247.466667L640 264.533333 640 384c0 25.6-17.066667 42.666667-42.666667 42.666667s-42.666667-17.066667-42.666667-42.666667L554.666667 170.666667c0-17.066667 8.533333-34.133333 25.6-38.4 17.066667-8.533333 34.133333-4.266667 46.933333 8.533333l384 341.333333c8.533333 8.533333 12.8 21.333333 12.8 29.866667s-4.266667 25.6-12.8 29.866667l-384 341.333333C618.666667 891.733333 605.866667 896 597.333333 896z" p-id="2354"></path>
    <path fill={color} d="M42.666667 896c-4.266667 0-4.266667 0-8.533333 0C12.8 891.733333 0 874.666667 0 853.333333 0 345.6 593.066667 341.333333 597.333333 341.333333c25.6 0 42.666667 17.066667 42.666667 42.666667 0 25.6-17.066667 42.666667-42.666667 42.666667-17.066667 0-405.333333 4.266667-494.933333 294.4C187.733333 661.333333 337.066667 597.333333 597.333333 597.333333c25.6 0 42.666667 17.066667 42.666667 42.666667s-17.066667 42.666667-42.666667 42.666667C166.4 682.666667 81.066667 866.133333 81.066667 870.4 76.8 887.466667 59.733333 896 42.666667 896z" p-id="2355"></path></svg>)
