import React from "react"

import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import TabInput from "./TabInput"

import { DataSource } from "../../../../types/ui"
import { TabItem } from "./types"

import { useItemMethods } from './components/Util';
 
type Props = {
    data?: TabItem[],
    onChange: (dataSource: DataSource, data: TabItem[]) => void
}

// 组件数据输入
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const { data = [], onChange } = props

    const {
        onItemAdd,
        onItemSave,
        onItemRemove,
        sortUp,
        sortDown
    } = useItemMethods({
        data,
        initialData: {
            tab: {
                label: "",
                link: "https://",
            },
            children: []
        },
        onChange: (data: any) => {
            if (onChange) {
                onChange({ type: "input" }, data)
            }
        }
    });

    return <Box>
        <Box className={classes.list}>
            <Box className={classes.listToolbar}>
                <Box className={classes.listToolbarTitle}> <IconList />标签页列表</Box>
                <Box className={classes.grow} />
                <Button color="primary" onClick={()=>onItemAdd()}>
                    <IconAdd />添加标签页
            </Button>
            </Box>

            {data.map((item: any, index: number) => <TabInput
                key={item.id || index}
                item={item}
                onSave={onItemSave}
                onRemove={onItemRemove}
                showUp={index > 0}
                showDown={index < data.length - 1}
                sortUp={() => {
                    sortUp(index)
                }}
                sortDown={() => {
                    sortDown(index)
                }} />)}

        </Box>
    </Box>
}