import React, { useEffect, useState } from 'react';
import { RichUtils } from 'draft-js';

import Icon from './Icon'
import Button from './Button'
import {
    getSelectedBlocksType
} from '../utils';


export default (props: any) => {
    const {
        onChange,
        editorState,
        title,
        icon,
        type
    } = props

    const [currentBlocksType, setCurrentBlocksType] = useState('')
    const query = () => {
        const blocksType = getSelectedBlocksType(editorState);
        setCurrentBlocksType(blocksType)
    }
    useEffect(query)

    /**
     * 切换样式
     */
    const toggleChange = (e: any) => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            onChange(RichUtils.toggleBlockType(
                editorState,
                type,
            ));
        }
    }

    return (<>
        <Button
            onMouseDown={toggleChange}
            active={currentBlocksType === type}
            title={title[0]} >
            <Icon name={icon}
                color={currentBlocksType === type ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
    </>
    );
}