import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TabPanel, StyleTab, StyleTabs } from './StyleTabs';
export {a11yProps} from  './StyleTabs';

export const FormTabPanel = TabPanel;
export const FormTabs = StyleTabs

/**
 * 标签条目
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      borderTopRightRadius:6,
      borderTopLeftRadius:6,
      border:'1px solid transparent',
      borderBottom:0,
      fontWeight: theme.typography.fontWeightRegular,
      padding:theme.spacing(0,3,0,3),
      margin:theme.spacing(0,1,0,1),
      minHeight: '38px',
      fontSize: '.9em',
      display: 'flex',
      color: '#484848',
      background:'tansparent',
      '&:hover': {
        opacity: 1,
        color: '#333',
        backgroundColor: '#f7f7f7'
      }
    },
    selected: {
      opacity: 1,
      color: '#000',
      border:'1px solid #dedede',
      borderBottom:0,
      fontWeight: theme.typography.fontWeightMedium,
      background: `-webkit-linear-gradient(#f7f7f7, #f0f0f0);
      -o-linear-gradient(#f7f7f7, #f0f0f0);
      -moz-linear-gradient(#f7f7f7, #f0f0f0);
      linear-gradient(#f7f7f7, #f0f0f0)`,
      //background:radial-gradient(40rpx at right bottom,transparent 50%,#1889F6 50%);
      '&:hover': {
        opacity: 1,
        color: '#000'
      },
    },
  }))
  export const FormTab = (props: any) => {
    const classes = useStyles()
    return (
      <StyleTab
        defaultClassName={classes.root}
        selectedClassName={classes.selected}
        {...props} />)
  }
  