
import {  makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor:"#fff",
      boxShadow: "0 5px 20px -6px rgba(100, 100, 100, 0.42), 0 2px 15px 0px rgba(100, 100, 100, 0.12), 0 4px 5px -2px rgba(100, 100, 100, 0.2)",
      // transition: theme.transitions.create(['width', 'margin'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      '& div':{
        background:"#fff",
        color:"#333"
      }
    }, 
    root: {
      background:"transparent",
      padding: theme.spacing(0,2),
      // boxShadow:"none",
      // borderBottom:"1px solid #dedede",
      boxShadow:
      "0 5px 20px -6px rgba(225, 225, 225, 0.52), 0 2px 15px 0px rgba(225, 225, 225, 0.22), 0 4px 5px -2px rgba(225, 225, 225, 0.3)",
    },
    spacer: {
      flex: 1,
    },
    actions: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      display: 'flex',
      flexWrap: 'wrap',
      userSelect: 'none',
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
      color:"inherit",
    },
    icon: {
      margin: "0 3px 0 0",
      padding:0,
      display: 'flex',
      alignItems: 'center',
      maxWidth:16,
      maxHeight:16
    },
    label:{

    },
    button: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(1)
    },
    tool: {
      display: 'flex',
      alignItems: 'center',
    },
    toolbar: {
      display: 'flex', 
      flexWrap: 'wrap',
      alignItems: 'center',
    }
  }));