import React from "react"
import Box from "@material-ui/core/Box";
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';

import { attachPrefix } from '../../../config';
import { OrderDetail,SendDetail} from "../../../types/trade" 

import { useListStyles } from "../../../assets/jss/components"
import SectionCommentList from "../../../components/comment/List"

import Error from '../../../components/Error'
import Icon from "../../../components/Icon"
 
type Props = {
  detail:OrderDetail,
  selected?:SendDetail[],
  onToggle?:(detail:OrderDetail)=>void
} 
export default  (props: Props) => {
  const classes = useListStyles()
  const { detail,selected,onToggle } = props
  const [open,setOpen] = React.useState<boolean>(false)

  if(!detail.product){
  return <Box className={classes.detailItem}>
        <Error message="商品不存在，已被强制删除！"/>
    </Box>
  }
  // 进价
  let inprice = detail.product.inprice
  let image = detail.product.image
  if(detail.product.specMode === '1' && detail.productSpec && detail.productSpec.image){
    image = detail.productSpec.image
    inprice = detail.productSpec.inprice
  }
  // 评价
  const [commentListLoaded,setCommentListLoaded]=React.useState<boolean>(false)

  const isSelected = selected && selected.filter((item:any)=>item.orderDetail.id === detail.id).length > 0

  return <Box className={classes.detailItem} style={{
    background:isSelected ?"#f0f0f0":"transparent",
    padding:"4px 0 0 0 "
  }}>
  <Box className={classes.productInfo}  style={{fontSize:"12px"}}>
        <Box className={classes.image} style={{
          backgroundImage:`url(${attachPrefix}${image})`
        }}/>
        <Box className={classes.title}>
        <a target="_blank" className={classes.href}  href={`/shopping/product/${detail.product.id}`}>
        {detail.product.title}
        </a>
        <span style={{marginLeft:16}}>商品进价：<span style={{fontWeight:"bold"}}>¥{inprice}</span></span>
        </Box>
        {detail.product.specMode === '1' && detail.productSpec && detail.specList &&
        <Box className={classes.spec}>
        {detail.specList.map((item:any,index:number)=>
          <Box className={classes.item} key={index}>
               <span className={classes.label} >{item.spec.name}</span>:{item.specVal.value}
        </Box>)}
        </Box>}

        <Box className={classes.grow}/>
        <Box className={classes.listPrice}>
        <Box style={{paddingRight:8,fontWeight:600}}> ¥{detail.price}</Box>
        <Box style={{paddingRight:8,fontWeight:600}}> x{detail.num}</Box>
        { onToggle && <Button 
          onClick={()=>{
            onToggle(detail)
          }}>
          <Icon name="Success" color={isSelected ?"green":"gray"}/>{ ' '}
            选择
          </Button>} 

        {!onToggle && detail.comment &&   <Button 
          color="primary"
          onClick={()=>{
            setOpen(!open)
          }}>
          {open?'收起':'展开'}
          评价 
          </Button> 
        }
        </Box>
    </Box>
    
    <Collapse in={open}>
    {!onToggle && detail.comment &&
      <SectionCommentList
          data={ [detail.comment]}
          targetId={detail.comment.id} 
          targetType="comment" 
          topTargetType="orderDetail"
          commentText="追加评价"
          subCommentText="追加评价"
          total={1}
          loaded={commentListLoaded}
          onLoaded={()=>setCommentListLoaded(true)}
          showUserInfo={false}
          enableLike={false}
          enableSubLike={false}
          /> }
   </Collapse>
   
    </Box>
}

