import React from "react"

import classNames from "classnames"
import { useUIInputsStyles, useUIPageStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';

import Icon from "../../../../components/Icon"
import FormField from '../../../../components/ui/form/FormField';
import RadioInput from '../../../../components/ui/form/RadioInput';
import ArticleCatInput from '../../../../components/content/CatInput';
import ProductCatInput from '../../../../components/shopping/CatInput';

import { SpecialTabItem } from "./types"
import InputListItem from "./InputListItem"

import ItemTools from "./components/ItemTools"

type Props = {
    item: SpecialTabItem,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave: (item: SpecialTabItem) => void,
    onRemove: (item: SpecialTabItem) => void,
}
/** 标签页输入 */
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const pageClasses = useUIPageStyles()
    const { item = {}, showUp, showDown, sortUp, sortDown,onSave, onRemove } = props

    const [form, setForm] = React.useState<any>(item || {})
    const setFormItem = (key: string, value: any) => {
        form[key] = value;
        setForm(Object.assign({}, form))
    }

    return <Box className={classNames(classes.listItem, {
        [classes.editing]: form.editing
    })}>
        <Box className={classes.itemFields}>

            {form.editing ? <RadioInput
                label="标签页类型："
                labelWidth={120}
                value={form.type}
                options={[
                    { label: "文章", value: "article" },
                    { label: "商品", value: "product" }
                ]}
                onChange={(e: any, value: any) => {
                    setFormItem("type", value)
                }} /> :
                <FormField
                    label="标签页类型："
                    labelWidth={120}>
                    {form.type === "article" && "文章"}
                    {form.type === "product" && "商品"}
                    {form.type !== "product" && form.type !== "article" && "未设置"}
                </FormField>
            }

            {form.editing ? <>
                {form.type === "article" && <ArticleCatInput
                    label="文章分类："
                    labelWidth={120}
                    value={form.cat && form.cat.id}
                    onChange={(item: any) => {
                        setFormItem("cat", item)
                    }} >
                    <Box className={pageClasses.startTips} style={{ paddingTop: 20 }}>
                        <Icon name="Alert" className={pageClasses.icon} />
                        请选择文章分类！
              </Box>
                </ArticleCatInput>}
                {form.type === "product" && <ProductCatInput
                    label="商品分类："
                    labelWidth={120}
                    value={form.cat && form.cat.id}
                    onChange={(item: any) => {
                        setFormItem("cat", item)
                    }} >
                    <Box className={pageClasses.startTips} style={{ paddingTop: 20 }}>
                        <Icon name="Alert" className={pageClasses.icon} />
                        请选择商品分类！
                   </Box>
                </ProductCatInput>}
            </> :
                <FormField
                    label="分类："
                    labelWidth={120}>
                    {form.cat ? <>
                        {form.cat.name}
                        <span className={pageClasses.label}>({form.cat.descri})</span>
                    </> :
                        "未设置"
                    }
                </FormField>
            }

            <InputListItem
                key={1}
                editing={form.editing}
                option={{
                    id: "1",
                    name: 'links',// 类型名称
                    alias: '链接数组',// 别名
                    variableType: 'Link[]',// 变量类型
                    notNull: true,// 非空
                }}
                value={form.links}
                onChange={(name: string, value: any) => {
                    setFormItem('links', value)
                }} />

        </Box>
        <Box className={classes.itemToolbar}>
        <ItemTools
            editing={form.editing}
            onSave={() => {
                setFormItem("editing", false);
                if (onSave) onSave(form);
            }}
            onCancel={() => setFormItem("editing", false)}
            onEditing={() => setFormItem("editing", true)}
            onRemove={() => {
                if (onRemove) onRemove(form);
            }}
            showUp={showUp}
            showDown={showDown}
            sortUp={sortUp}
            sortDown={sortDown} />
            
        </Box>
    </Box>
}