import React, { useState } from 'react';
import { css } from 'emotion';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/MenuBook';
import Box from '@material-ui/core/Box'
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link, withRouter } from "react-router-dom";

import { useConfigState } from '../provides/app'

import SubMenu from './components/SubMenu';
import SubMenuItem from './components/SubMenuItem'

import { sideBarWidth, sideBarCloseWidth,  sideBarPaddingLeft } from './size'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    drawer: {
      padding: 0,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    sideTitle:{
      fontSize:'1.15em',
      display:"flex",
      alignItems:"center",
      padding:theme.spacing(1,2),
      background:"#213148",
      color:"#44B395",
      boxShadow:"0 5px 20px -6px rgba(0, 0, 0, 0.42), 0 2px 15px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2px rgba(0, 0, 0, 0.2);",
      '& span':{
        marginLeft:theme.spacing(1),
        color:"#e1e2e3"
      }
    },
    drawerOpen: {
      width: sideBarWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    link:{
      textDecoration:'none'
    },
    drawerClose: {
      width: sideBarCloseWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      // width: theme.spacing(5) + 1,
      // [theme.breakpoints.up('sm')]: {
      //   width: theme.spacing(7) + 1,
      // },   
    }
  }))

const SideBar = (props: any) => {
  const {
    sidebarIsOpen,
    location
  } = props
  const { pathname } = location
  const { menus } = useConfigState().config
  
  const [opens, setOpens] = useState({} as any);
  const classes = useStyles()

  const handleOpens = (item: any) => {
    const current: any = opens || {}
    current[item.path] = !current[item.path]
    setOpens(Object.assign({}, current))
  };

  /**
   * 弹框
   */
  const popidPrefix = `menu-popover`;
  const [anchorEls, setAnchorEls] = React.useState({} as any);
  const popoverShow = (id: string) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    let newAnchorEls = anchorEls || {}
    Object.keys(newAnchorEls).forEach((key: string) => {
      if (key !== id)
        newAnchorEls[key] = null
    })
    newAnchorEls[id] = event.currentTarget
    setAnchorEls(Object.assign({}, newAnchorEls));
  }
  function popoverClose() {
    setAnchorEls({});
  }

  // 打开当前路由所属的上级菜单
  const openParents = (item: any, selfOpen: boolean) => {
    const current: any = {}
    if (selfOpen) {
      current[item.path] = true
    }
    item.paths.map((p: string) => {
      current[p] = true
    })
    setOpens(Object.assign({}, current))
  }
  /** 
   *  渲染菜单
   */
  const renderMenuItem = (item: any, paddingLeft = 0, anchorId: string, isTop: boolean) => {
    const active = pathname === item.path
    if (active && opens && !opens[item.path]) {
      openParents(item, true)
    }
    if (item.children && item.children.length > 0) {
      // 渲染子菜单
      return (
        <SubMenu
          handleOpens={() => handleOpens(item)}
          {...{
            sidebarIsOpen,
            active,
            isOpen: sidebarIsOpen && opens && opens[item.path],
            title: item.title,
            icon: item.icon,
            paddingLeft: paddingLeft + sideBarPaddingLeft,
            // 弹框元素
            isTop,
            anchorId,
            anchorEls,
            popoverShow,
            popoverClose
          }}
        >{renderMenu(item.children, paddingLeft + sideBarPaddingLeft, anchorId, false)}</SubMenu>)
    }
    // 渲染子菜单条目
    return (
      <Link to={item.path} className={classes.link}>
        <SubMenuItem
          handleOpens={() => {
            handleOpens(item)
            setAnchorEls({});
          }}
          {...{
            sidebarIsOpen,
            active,
            isOpen: false,
            title: item.title,
            icon: item.icon,
            paddingLeft: paddingLeft + sideBarPaddingLeft,
            // 弹框元素
            isTop,
            anchorId
          }} />
      </Link>)
  }
  /**
   *  渲染菜单组
   */
  const renderMenu = (items: any, paddingLeft = 0, anchorId: string, isTop: boolean) => (<MenuList>
    {items.map((item: any, i: number) => (<Box key={i}>
      {
        renderMenuItem(
          item,
          paddingLeft,
          anchorId.indexOf('-') > -1 ? anchorId : `${popidPrefix}-0-${i}`,
          isTop)
      }
    </Box>))
    }</MenuList>)

  const styles = {
    screenSide: css`
        flex-grow:1,
        margin: 0;
        background:#304156;
        box-shadow:0 5px 20px -6px rgba(0, 0, 0, 0.42), 0 2px 15px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2px rgba(0, 0, 0, 0.2);
        overflow-y: auto;
        -ms-scroll-chaining: chained;
        -ms-overflow-style: none;
        -ms-content-zooming: zoom;
        -ms-scroll-rails: none;
        -ms-content-zoom-limit-min: 100%;
        -ms-content-zoom-limit-max: 500%;
        -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
        /*webkit内核*/
        ::-webkit-scrollbar-button {
          background-color: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-track {
          background-color: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-track-piece {
          background-color: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-corner {
          background-color: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar-resizer {
          background-color: rgba(0, 0, 0, 0);
        }
        ::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      `
  }

  return (
    <Box className={styles.screenSide}>
      <Box
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebarIsOpen,
          [classes.drawerClose]: !sidebarIsOpen
        })}
      >
        <Box className={classes.sideTitle}>
          <MenuIcon/>
        {sidebarIsOpen && <span>功能菜单</span>}
        </Box>
        {renderMenu(menus, 0, '0', true)}
      </Box>
    </Box>
  );
}

export default withRouter(SideBar)