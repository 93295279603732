import React, { useState, useEffect } from 'react';

import { css } from 'emotion';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useLocale } from '../../config/Locale';
import FormField from '../ui/form/FormField'
import AreaTreeDialog from './AreaTreeDialog'
import { useArea } from '../../provides/common'

import Icon from '../Icon'

import { makeStyles, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      padding: "0 8px 0 0"
    },
    tip: {
      padding: "5px 3px 0 0"
    }
  }))
export type AreaInputProps = {
  label?: string,
  value?: any,
  labelWidth?: number,
  onChange: (value: any) => void
}
export default function AreaInput(props: AreaInputProps) {
  const { label, labelWidth, onChange, value } = props
  const [open, setOpen] = useState(false)
  const { translate } = useLocale()
  const [names, setNames] = useState()
  const classes = useStyles()

  const { getNames } = useArea()
  // 查询分类名称，含父级分类
  const queryNames = () => {
    if (value && !names) {
      getNames({
        id: value,
        success: (names: string) => {
          setNames(names)
        },
        failure: (message: string) => {
        }
      })
    } else if (!value) {
      setNames('')
    }
  }
  useEffect(queryNames);

  return (
    <FormField
      labelWidth={labelWidth}
      label={`${label || translate('area.self')}：`}>
      <Box>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true)
          }}
        >
          <Icon name="Tree" className={classes.icon} color="#fff" />
          {translate('area.choose')}
        </Button>
      </Box>
      <Box className={classes.tip}>
        {names}
      </Box>
      <AreaTreeDialog
        {...{
          open,
          currentId: value,
          ok: (item: any, names: string) => {
            onChange(item)
            setNames(names)
            setOpen(false)
          },
          cancel: () => {
            setOpen(false)
          }
        }} />
    </FormField>);
}

