import React from 'react';
import clsx from 'clsx';
import { fade, withStyles, createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { green } from '@material-ui/core/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';

import Icon from '../../Icon'
import { attachPrefix } from '../../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      margin: 0,
      padding: 0,
    },
    listItemBox: {
      position: 'relative',
      flex: 1,
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    listItemText: {
      padding: theme.spacing(1),
      fontSize: '.8em',
      alignItems: 'center'
    },
    listItemImg: {
    },
    squareContainer: {
      position: 'relative',
      justifyContent: 'center',
    },
    square: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      color: `${fade(theme.palette.text.primary, 0.6)}`,
      alignItems: 'center'
    },
    listItemDescri: {
      textAlign: 'left',
      justifyItems: 'flex-start',
      fontSize: '0.8em',
      color: '#aaa'
    },
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    active: {
      fontWeight: 'bold',
      color: '#333'
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checked: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
  }))

const TreeItem = (props: any) => {
  const {
    handleOpens,
    active,
    isOpen,
    descri,
    icon,
    label,
    existsChildren,
    value,
    handleItemClick,
    handlerChecked,
    renderNode,
    paddingLeft,
    showCheckbox,
    showDescri
  } = props

  const classes = useStyles()

  return (
    <ListItem
      dense
      button
      className={classes.listItem}
      onClick={handleItemClick}
    >
      <Box textAlign="left" className={classes.listItemBox} style={{ paddingLeft }}>
        <Box className={classes.squareContainer}>
          <Box className={classes.square}
            component="span"
            onClick={handleOpens}
          >
            {(isOpen || !existsChildren) ?
              <><Icon name="MinusSquare" /></> :
              <><Icon name="PlusSquare" /></>}
          </Box> 
        </Box>
        {showCheckbox && <Checkbox
          value={value}
          disableRipple
          color="default"
          className={classes.checked}
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          inputProps={{ 'aria-label': 'Checkbox A' }}
          onChange={handlerChecked}
        />}

        {icon && <img src={attachPrefix + icon} className={classes.listItemImg} width={16} height={16} />}
        <Box className={clsx(classes.listItemText, {
          [classes.active]: active,
          [classes.active]: !active,
        })}
        >
          {label}
        </Box>
        {showDescri && descri && <Box className={classes.listItemDescri}>({descri})</Box>}

        {renderNode()}
      </Box>
    </ListItem>
  );
}

export default TreeItem;
