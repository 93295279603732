import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024"
    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10434"
    width={width} height={height}>
    <path fill={color} d="M986.8 501.8h-73.3c-3.8-40-18.9-68.9-45.6-86.7-30.5-19.8-71.2-29.4-122.2-28.9-60.3-1.1-106 18.7-137 59.3l64 48.8c7-11.7 16.7-20.1 28.9-25 11.7-4.1 27.7-6.2 48-6.2 48.6-1 75.9 12 81.9 38.7H469.8L365 226.2l0.4-1-44.7 0.5-44.7-0.5 0.4 1-104.8 275.6H41.8v89.4h95.8l-75 197.4 88.3 0.9L225.6 593h190.3l74.7 196.5 88.3-0.9-75.1-197.4h119.4l-1.6 1.6c-22.4 22.4-33.6 50.6-33.6 84.7 0 35.7 12.8 65 38.6 87.9 24.4 23.4 59.3 35.4 104.6 35.9 27.6 0 49.2-3.4 64.8-10.2 15.8-6.6 27.6-16.7 35.1-30.5H832.8v35.9h81.6V591.2h72.4v-89.4zM320.6 342.7L381 501.8H260.2l60.4-159.1zM832.7 654c0 30.5-6.4 50-19.1 58.6-14 8.6-38.1 12.6-72.2 12.1-27.1 0-46.5-5.3-58.2-16-12.3-9.6-18.4-21.3-18.4-35.1-0.5-31.5 22.9-47.8 70.3-48.8h97.6V654z" p-id="10435"></path></svg>
    )
