
import * as actions from '../../store/actions/common/area'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    get: Function,
    save: Function,
    remove: Function,
    getNames:Function,
    buildJsonFile:Function,
    readJsonData:Function
}

export const useArea = (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const { success, failure } = props || {}
        dispatch(actions.fetchList()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                // 通知Redux
                dispatch(actions.fetchListSucess(list || []))
                // 成功返回
                if (success) {
                    success(list || [])
                }
            } else {
                // 失败
                const error = {
                    code: data && data.code || 0,
                    message: message || '请求失败'
                }
                dispatch(actions.fetchListFailure(error))
                // 失败返回
                if(failure)
                failure(message || '请求失败')
            }
        })
    }
    // 获取
    const get = (props: any) => {
        const { id ,success, failure } = props
        dispatch(actions.fetch(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.area)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取名称
    const getNames = (props: any) => {
        const { id,success, failure } = props
        dispatch(actions.fetchNames(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const names = data.result.names
                // 成功返回
                if (success) {
                    success(names)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 保存
    const save = (props: any) => {
        const { area ,success, failure } = props
        dispatch(actions.save(area)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    // 删除
    const remove = (props: any) => {
        const { id,success, failure } = props
        dispatch(actions.remove(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    const buildJsonFile= (props: any) => {
        const { success, failure } = props
        dispatch(actions.jsonFile()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    

    const readJsonData= (props: any) => {
        const { success, failure } = props
        dispatch(actions.jsonData()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.json)
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    return {
        getList,
        get,
        save,
        getNames,
        remove,
        buildJsonFile,
        readJsonData
    }
}