import React from "react"

import Table from "../../../components/ui/table/MaterialTable"
import { ComponentOption } from "../../../types/ui"

type Props = {
  options: ComponentOption[],
  edittable?: boolean,
  onChange?: (options: ComponentOption[]) => void
}
export default (props: Props) => {
  const { options, edittable, onChange } = props

  const handleAdd = (rowData: ComponentOption) => {
    const newOptions = options || []
    newOptions.push(rowData)
    if (onChange)
      onChange(newOptions)
  }
  const handleUpdate = (newData: ComponentOption, oldData: ComponentOption) => {
    const newOptions = options || []
    newOptions.forEach((item: ComponentOption, index: number) => {
      if (item.id === oldData.id) {
        newOptions.splice(index, 1, newData)
        if (onChange) {
          onChange(newOptions)
        }
        return
      }
    })
  }
  const handleDelete = (oldData: ComponentOption) => {
    const newOptions = options || []
    if (onChange) {
      if (newOptions.length === 1) {
        onChange([])
      } else {
        onChange(newOptions.filter((item: ComponentOption) => item.id !== oldData.id))
      }
    }
  }

  return <Table
    title="组件配置"
    columns={[
      {
        title: 'ID',
        field: 'id'
      },
      {
        title: '名称',
        field: 'name'
      },
      {
        title: '别名',
        field: 'alias'
      },
      {
        title: '数据类型',
        field: 'variableType',
        lookup: {
          'boolean': "布尔值",
          'string': '字符',
          'html': 'html文本',
          'string[]': '字符数组',
          'number': '数值',
          'number[]': '数值数组',
          'string|number': '字符或数值',
          'string[]|number[]': '字符数组或数值数组',
          "enum": "枚举",
          'any': '任意值',
          'link': '链接',
          'link[]': '链接数组',
          'image': '图片',
          'image[]': '图片数组',
          'color': '取色器',
        }
      },
      {
        title: '别名',
        field: 'alias'
      },
      {
        title: '枚举值',
        field: 'variableValues'
      },
      {
        title: '非空',
        field: 'notNull',
        lookup: { false: '否', true: '是' }
      },
    ]}
    data={options || []}
    options={{
      paging: false,
      search: false,
      headerStyle: {
        backgroundColor: '#f7f7f7',
        color: '#333',
        zIndex: "auto"
      }
    }}
    editable={edittable ? {
      onRowAdd: (rowData: ComponentOption) =>
        new Promise(resolve => {
          resolve();
          handleAdd(rowData)
        }),
      onRowUpdate: (rowData: ComponentOption, oldData: ComponentOption) =>
        new Promise(resolve => {
          resolve();
          handleUpdate(rowData, oldData)
        }),
      onRowDelete: (oldData: ComponentOption) =>
        new Promise(resolve => {
          resolve();
          handleDelete(oldData)
        }),
    } : {}} />
}