import React from 'react';
// import {
//   SideBySideMagnifier
// } from "react-image-magnifiers";
import ImageGallery from '../ui/ImageGallery';
import Box from '@material-ui/core/Box';
import { useProductStyles } from "../../assets/jss/components";
import { attachPrefix } from "../../config"
import Slider from "react-slick"

import { useMediaMatches, MOBILE } from "../MediaMatches"

type Props = {
  data: string[],
  width?:string|number
}
 

export default (props: Props) => {
  const classes = useProductStyles();
  const matches = useMediaMatches()
  const { data,width } = props
  if (!data) 
    return <></>
 
  const [imageSrc,setImageSrc] = React.useState<string>('')
  const images: any = [];
  if (data) {
    data.map((image: string) => images.push(attachPrefix+ image))
  }

  if (matches === MOBILE) {
    // 手机版
      const slideSetting = { 
        dots: true,
        arrows: false,
        fade: false,
        infinite:  true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: false
    }
    return  <Box className={classes.carousel} style={{
      width
    }}>
      <Slider
      {...slideSetting}
      className="rounddot-slick">
      {images.map((image: string, index: number) =>
        <Box 
        key={index}
        className={classes.carouselItem}>
        <Box 
        className={classes.carouselItemImage}
        style={{
          backgroundImage: `url(${image})`
        }}></Box>
        </Box>
      )}
    </Slider>
    </Box>
  }else{
    const state = {
      alwaysInPlace: false,
      overlayOpacity: 0.15,
      switchSides: false,
      fillAvailableSpace: true,
      fillAlignTop: true,
      fillGapLeft: 10,
      fillGapRight: 10,
      fillGapTop: 10,
      fillGapBottom: 10
      }
  
    return <Box style={{width}}>
          <Box className={classes.imageGallery} style={{border:"1px solid #dedede"}}>
               <ImageGallery
                          images={images || []}
                          height={80}
                          variant="gallery"
                          onSelected={(index:number)=>{
                              setImageSrc(images[index])
                            }}
                          setting={{ 
                            arrows:true,
                            slidesToShow:4,
                             dots: false,
                   }} />
               </Box>
          {imageSrc && <Box className={classes.imageBox}>
              <img
                className={classes.image}
                src={imageSrc}/>
          </Box>}
        </Box>
  } 
}

