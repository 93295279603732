
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useDialogStyles = makeStyles((theme: Theme) =>
createStyles({
  content: {
    display: 'flex',
    padding: theme.spacing(2),
    minWidth: theme.spacing(25),
    alignItems: 'center',
    justifyContent: 'center'
  },
  actions: {
    background: '#f7f7f7'
  },
  result: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
    textAlign: 'left'
  },
  progress: {
    marginRight: theme.spacing(1)
  },
  paperContainer:{
    margin:theme.spacing(1),
    border:'1px solid #dedede',
    borderRadius:6
  },
  paperTitle:{
    padding:theme.spacing(1),
    borderTopLeftRadius:6,
    borderTopRightRadius:6,
    background:'#f3f3f3'
  }
}));