import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box';

import { withRouter } from "react-router-dom";

// 逻辑
import { useLocale } from '../../../config/Locale'
import { useArticle } from '../../../provides/content'
import { useConfig } from '../../../provides/app'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { SubmitedDialog } from '../../../components/ui/dialog'

// 表单组件 
import TextInput from '../../../components/ui/form/TextInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import Editor from '../../../components/ui/editor'
import { useEditPageStyles } from '../../../assets/jss/pages'


const EditPage = (props: any) => {
  const { history } = props

  const { translate } = useLocale()
  const { getByCat, save } = useArticle()
  const { closePage } = useConfig()

  const classes = useEditPageStyles()

  const [loaded, setLoaded] = useState(false)
  const resetState = () => {
    setLoaded(false)// 富文本
  }
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  // 设置表单元素
  const [form, setForm] = useState<any>({})
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载文章...'
    })
    getByCat({
      catId: form.catId,
      success: (article: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        const newForm = article
        setForm(Object.assign({}, newForm))
        // 重置表单元素加载状态
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        resetState()
      }
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'catId') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    // 地址栏参数
    const pathname = history.location.pathname
    const catId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)
    if (catId !== form.catId) {
      resetForm()
      setFormItem('catId', catId)
      loadData()
    }
    return () => {
    }
  }, []);

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      article: form,
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route && route.path) {
        history.push(route.path)
      }
    })
  }

  return (<Box>
    <form onSubmit={onSubmit}>
      {/* 详情 */}
      <Box className={classes.contentContainer}>
        <Editor
          tabHided
          value={form.content}
          loaded={loaded}
          onLoaded={() => {
            setLoaded(true)
          }}
          onChange={(value: string) => {
            setFormItem('content', value)
          }} />
        <Box style={{height:5,marginBottom:10,boxShadow:'0  2px 4px rgba(125,125,125,0.2)'}}/>
        {/* 页面描述 */}
        <TextInput
          labelWidth={120}
          value={`${form.subtitle || ''}`}
          fullWidth
          label="页面描述："
          placeholder="页面描述，作为description输出"
          onChange={(event: any) => {
            setFormItem('subtitle', event.target.value)
          }} />
        {/* 关键字： */}
        <TextInput
          labelWidth={120}
          value={`${form.keywords || ''}`}
          fullWidth
          label="关键字："
          placeholder="用英文逗号或空格分隔关键字，作为Keywords输出"
          onChange={(event: any) => {
            setFormItem('keywords', event.target.value)
          }} />

        {/* 状态 */}
        <RadioInput
          labelWidth={120}
          value={form.status}
          options={[
            { label: "未启用", value: "0" },
            { label: "启用", value: "1" }
          ]}
          label="状态："
          onChange={(e: any, value: any) => {
            setFormItem('status', value)
          }} />
      </Box>
      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          },
          onReset: () => {
            loadData()
          }
        }} />
    </form>

    <LoadingBox {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goModify={() => {
        setMessage(initialMessage)
        loadData()
      }}
    />

  </Box>
  )
}
export default withRouter(EditPage)