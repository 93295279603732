import React, { useState, useEffect } from 'react'

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withRouter } from "react-router-dom";

// 逻辑
import { useLocale } from '../../../config/Locale'
import { useArticle, useCat } from '../../../provides/content'
import { useConfig } from '../../../provides/app'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { SubmitedDialog } from '../../../components/ui/dialog'
// 上传组件
import { getAccept } from '../../../components/ui/upload/Accepts'
import { FormTabPanel, FormTab, FormTabs } from '../../../components/ui/tabs/FormTabs';

// 表单组件 
import TextInput from '../../../components/ui/form/TextInput'
import ImagesInput from '../../../components/ui/form/ImagesInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import VideoInput from '../../../components/ui/form/VideoInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import CatInput from '../../../components/content/CatInput';
import InputList from "../../ui/page/inputs/InputList"
import Editor from '../../../components/ui/editor'
import { createId } from '../../../lib/util'
import { useEditPageStyles } from '../../../assets/jss/pages'

import { Attach } from "../../../types/common"

const a11yProps = (index: number, label: string) => {
  return {
    index,
    label,
    ariaprefix: "article-edit",
  };
}


const EditPage = (props: any) => {
  const { history } = props

  const { translate } = useLocale()
  const { get, save } = useArticle()
  const getCat = useCat().get
  const { closePage } = useConfig()

  const classes = useEditPageStyles()
  const theme = useTheme()

  const [loaded, setLoaded] = useState(false)
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const resetState = () => {
    setLoaded(false)// 富文本
    setImagesLoaded(false)// 图片列表
    setVideoLoaded(false)// 视频
  }

  const [cat, setCat] = useState<any>()

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }
  // 设置表单元素
  const [form, setForm] = useState({} as any)
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const loadCat = (id: string) => {
    getCat({
      id,
      success: (cat: any) => {
        setCat(cat)
      }
    })
  }
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载文章...'
    })
    get({
      id: form.id,
      success: (article: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        if (article)
          loadCat(article.catId)
        setForm(Object.assign({}, article))
        // 重置表单元素加载状态
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        reloadParams()
        resetState()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id"),
      catId: params.get("catId")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    const catId = params.get("catId")
    if (id !== form.id) {
      resetForm()
      setFormItem('id', id)
      setFormItem('catId', catId)
      if (catId)
        loadCat(catId)
      loadData()
    }
    return () => {
    }
  });

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      article: form,
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route && route.path) {
        history.push(route.path)
      }
    })
  }

  return (<Box>
    <form onSubmit={onSubmit}>
      <Box className={classes.contentHeader}>
        <FormTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}>
          <FormTab {...a11yProps(0, '基本信息')} />
          {cat && cat.catType === "3" ?
            <FormTab {...a11yProps(1, '图片列表')} />
            :
            <FormTab {...a11yProps(1, '文章详情')} />
          }
        </FormTabs>
      </Box>
      <Box className={classes.contentContainer}>
        <FormTabPanel
          ariaprefix="article-edit"
          value={value}
          index={0}
          dir={theme.direction}>
          <Box className={classes.margin}>
            {/* 分类 */}
            <CatInput
              label="文章分类："
              editing={false}
              notShowAll
              labelWidth={120}
              value={form.catId}
              onChange={(item: any) => {
                setFormItem('catId', item.id)
              }} />
            {/* 标题 */}
            <TextInput
              labelWidth={120}
              value={`${form.title || ''}`}
              fullWidth
              label="标题："
              required
              placeholder="请输入文章标题，不可重复"
              onChange={(event: any) => {
                setFormItem('title', event.target.value)
              }} />

            {/* 副标题 */}
            <TextInput
              labelWidth={120}
              value={`${form.subtitle || ''}`}
              fullWidth
              label="副标题："
              placeholder="文章内容概述，作为description输出"
              onChange={(event: any) => {
                setFormItem('subtitle', event.target.value)
              }} />
            {/* 关键字： */}
            <TextInput
              labelWidth={120}
              value={`${form.keywords || ''}`}
              fullWidth
              label="关键字："
              placeholder="用英文逗号或空格分隔关键字，作为Keywords输出"
              onChange={(event: any) => {
                setFormItem('keywords', event.target.value)
              }} />
            {
              // 组图
              cat && cat.catType === "3" ? <>
              </> :<>
                <ImagesInput
                  labelWidth={120}
                  value={form.images}
                  label="图片："
                  onChange={(urls: string[]) => {
                    setFormItem('images', urls)
                  }}
                  loaded={imagesLoaded}
                  onLoaded={() => { setImagesLoaded(true) }} />
                  {/* 视频 */}
                  <VideoInput
                    labelWidth={120}
                    value={form.video}
                    label="视频："
                    tips={`文件后缀名包含“${getAccept('video')}”。`}
                    onChange={(url: string) => {
                      setFormItem('video', url)
                    }}
                    loaded={videoLoaded}
                    onLoaded={() => { setVideoLoaded(true) }} />
              </>
            }

            {/* 排序 */}
            <TextInput
              labelWidth={120}
              value={`${form.sorter || 0}`}
              inputWidth={60}
              label="排序："
              type="number"
              onChange={(event: any) => {
                setFormItem('sorter', event.target.value)
              }} />
            {/* 状态 */}
            <RadioInput
              labelWidth={120}
              value={form.status}
              options={[
                { label: "未启用", value: "0" },
                { label: "启用", value: "1" }
              ]}
              label="状态："
              onChange={(e: any, value: any) => {
                setFormItem('status', value)
              }} />
          </Box>
        </FormTabPanel>

        <FormTabPanel
          ariaprefix="article-edit"
          value={value} index={1} dir={theme.direction}>
          {/* 详情 */}
          {cat && cat.catType === "3" ? <>
            <InputList
              options={[
                {
                  id: "1",
                  name: 'url',// 类型名称
                  alias: '图片',// 别名
                  variableType: 'image',// 变量类型
                  notNull: true,// 非空
                },
                {
                  id: "2",
                  name: 'title',// 类型名称
                  alias: '标题',// 别名
                  variableType: 'string',// 变量类型
                  notNull: true,// 非空
                },
                {
                  id: "3",
                  name: 'content',// 类型名称
                  alias: '内容',// 别名
                  variableType: 'html',// 变量类型
                  notNull: true,// 非空
                },
                {
                  id: "4",
                  name: 'link',// 类型名称
                  alias: '链接地址',// 别名
                  variableType: 'link',// 变量类型
                  notNull: false,// 非空
                }]}
              data={form.imageAttaches || []}
              edittable
              onChange={(data: Attach[]) => {
                setFormItem("imageAttaches", data)
              }} /> 
          </> :
            <Editor
              value={form.content}
              loaded={loaded}
              onLoaded={() => {
                setLoaded(true)
              }}
              onChange={(value: string) => {
                setFormItem('content', value)
              }} />
          }
        </FormTabPanel>

      </Box>
      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          },
          onReset: () => {
            loadData()
          }
        }} />
    </form>

    <LoadingBox {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/content/article/list/${form.catId}`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`?id=${createId()}&catId=${form.catId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        loadData()
      }}
    />
  </Box>
  )
}
export default withRouter(EditPage)