
import { shallowEqual, useSelector } from "react-redux";
import * as State from "../../store/state";

export { useAttr } from './AttrProvide'
export { useCat} from './CatProvide'
export { useProduct} from './ProductProvide'
export { useSpec} from './SpecProvide'
export { useBrand} from './BrandProvide'

export const useCatState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.shopping.cat.list || [],
      activeList: state.shopping.cat.activeList || [],
      pagination: state.shopping.cat.pagination,
      cat: state.shopping.cat.cat,
      parent: state.shopping.cat.parent,
      names: state.shopping.cat.names,
      error: state.shopping.cat.error,
      success: state.shopping.cat.success
    };
  }, shallowEqual);
};

export const useProductState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.shopping.product.list || [],
      activeList: state.shopping.product.activeList || [],
      pagination: state.shopping.product.pagination,
      product: state.shopping.product.product,
      error: state.shopping.product.error,
      success: state.shopping.product.success
    };
  }, shallowEqual);
};

export const useSpecState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.shopping.spec.list || [],
      activeList: state.shopping.spec.activeList || [],
      pagination: state.shopping.spec.pagination,
      spec: state.shopping.spec.spec,
      error: state.shopping.spec.error,
      success: state.shopping.spec.success
    };
  }, shallowEqual);
};

export const useAttrState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.shopping.attr.list || [],
      activeList: state.shopping.attr.activeList || [],
      pagination: state.shopping.attr.pagination,
      attr: state.shopping.attr.attr,
      error: state.shopping.attr.error,
      success: state.shopping.attr.success
    };
  }, shallowEqual);
};

export const useBrandState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.shopping.brand.list || [],
      activeList: state.shopping.brand.activeList || [],
      pagination: state.shopping.brand.pagination,
      brand: state.shopping.brand.brand,
      error: state.shopping.brand.error,
      success: state.shopping.brand.success
    };
  }, shallowEqual);
}

export const usePromotionState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.shopping.promotion.list || [],
      activeList: state.shopping.promotion.activeList || [],
      pagination: state.shopping.promotion.pagination,
      promotion: state.shopping.promotion.promotion,
      error: state.shopping.promotion.error,
      success: state.shopping.promotion.success
    };
  }, shallowEqual);
}