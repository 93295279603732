
import * as actions from '../../store/actions/ui/component'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    get: Function,
    getByName: Function,
    save: Function,
    remove: Function,
    setStatus: Function,
}

export const useComponent = (): Result => {
    const dispatch: Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const { success, failure } = props
        dispatch(actions.fetchList()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                // 通知Redux
                dispatch(actions.fetchListSucess(list))
                // 成功返回
                if (success) {
                    success(list)
                }
            } else {
                // 失败
                const error = {
                    code: data && data.code || 0,
                    message: message || '请求失败'
                }
                dispatch(actions.fetchListFailure(error))
                // 失败返回
                if (failure)
                    failure(message || '请求失败')
            }
        })
    }



    // 获取
    const get = (props: any) => {
        const { id, success, failure } = props
        dispatch(actions.fetch(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const component = data.result.component
                // 成功返回
                if (success) {
                    success(component)
                }
            } else {
                // 失败返回
                if (failure)
                    failure(message || '请求失败')
            }
        })
    }

    // 获取
    const getByName = (props: any) => {
        const { name, success, failure } = props
        dispatch(actions.fetchByName(name)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const component = data.result.component
                // 成功返回
                if (success) {
                    success(component)
                }
            } else {
                // 失败返回
                if (failure)
                    failure(message || '请求失败')
            }
        })
    }

    // 保存
    const save = (props: any) => {
        const { data, success, failure } = props
        dispatch(actions.save(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure) {
                    failure(message || '请求失败')
                }
            }
        })
    }


    // 删除
    const remove = (props: any) => {
        const { id, success, failure } = props
        dispatch(actions.remove(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure) {
                    failure(message || '请求失败')
                }
            }
        })
    }
    // 设置状态
    const setStatus = (props: any) => {
        const { data, success, failure } = props
        dispatch(actions.setStatus(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure) {
                    failure(message || '请求失败')
                }
            }
        })
    }

    return {
        getList,
        get,
        getByName,
        save,
        remove,
        setStatus
    }
}