import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as commonTypes from '../../../types/common'


// Action Types
export type Action = { 
    // 友情链接列表
    type: 'COMMON_FRIENDURL_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type: 'COMMON_FRIENDURL_FETCH_LIST_SUCCESS', payload: any }
    | { type: 'COMMON_FRIENDURL_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 保存
        type: 'COMMON_FRIENDURL_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 删除
        type: 'COMMON_FRIENDURL_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 设置状态
        type: 'COMMON_FRIENDURL_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };

    
// 友情链接列表
export const FETCH_LIST = 'COMMON_FRIENDURL_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'COMMON_FRIENDURL_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'COMMON_FRIENDURL_FETCH_LIST_FAILURE';
export const fetchList = (): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/friendUrl/getList`,
            method: 'GET'
        }
    }
})
export const fetchListSucess = (list: commonTypes.FriendUrl[], pagination: types.Pagination): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list, pagination
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});



// 保存
export const SAVE = 'COMMON_FRIENDURL_SAVE'
export const save = (data: any): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/friendUrl/save`,
            method: 'POST',
            data
        }
    }
})



// 删除分组
export const REMOVE = 'COMMON_FRIENDURL_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/friendUrl/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})




// 设置状态
export const SET_STATUS = 'COMMON_FRIENDURL_SET_STATUS'
export const setStatus = (id:string,status:string): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/friendUrl/setStatus`,
            method: 'POST',
            data: {
                id,status
            }
        }
    }
})
