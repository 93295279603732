
import { makeStyles, createStyles, Theme, fade } from '@material-ui/core/styles';

export const useUIInputsStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    fieldSet:{
      border:"1px solid #eee",
      borderRadius:4,
      display:"flex",
      flexDirection:"column"
    },
    fieldSetHeading:{
      borderBottom:"1px solid #eee",
      borderTopLeftRadius:4,
      borderTopRightRadius:4,
      background:"#f9f9f9",
      padding:theme.spacing(1,2)
    },
    wrapBox:{
      display:"flex",
      flexWrap:"wrap",
      padding:theme.spacing(1)
    },
    wrapBoxItem:{
      minWidth:180
    },
    list:{
      border:"1px solid #eee",
      borderRadius:4
    },
    listItem:{
      display:"flex",
      color:"#666",
      borderBottom:"1px solid #f3f3f3",
      '&:hover':{
        transition: 'all 300ms linear',
        '&>div,&>span':{
          color:"#484848"
        }
      },
      '&:last-child':{
        borderBottom:0
      }
    },
    editing:{
      color:"#484848",
      '&>div,&>span':{
        color:"#484848"
      }
    },
    listToolbar:{
      alignItems:"center",
      display:"flex",
      transition: 'all 300ms linear',
      padding:"4px 8px",
      background:"#f7f7f7",
      borderTopLeftRadius:4,
      borderTopRightRadius:4,
      color:"#777",
      fontSize:".975em",
    },
    light:{
      background:"#fafafa",
      padding:"4px 8px",
    },
    listToolbarTitle:{
      alignItems:"center",
      display:"flex",
    },
    itemToolbar:{
         borderLeft:"1px solid #fafafa",
        padding:8,
        display:"flex",
        flexDirection:"column",
        flexWrap:"wrap",
        alignItems:"center",
       justifyContent:"center",
        fontSize:".95em",
        '& button':{
          whiteSpace:"nowrap",
        }
    },
    itemFields:{
      padding:theme.spacing(1),
      flexGrow:1
    },
    fab:{
      padding:4,
      margin:"0 4px 4px 0",
    },
    fabIcon:{
      padding:4,
      margin:0,
      maxWidth:18,
      maxHeight:18,
    },
    icon:{
      maxWidth:18,
      maxHeight:18,
      marginRight:4
    }
  }));