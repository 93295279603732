import React from "react"

import classNames from "classnames"
import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';

import { Link } from "./types"
import InputListItem from "./InputListItem"

import ItemTools from "./components/ItemTools"

type Props = {
    link?: Link,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave?: (item: any) => void,
    onRemove?: (item: any) => void,
}
/** 标签页输入 */
export default (props: Props) => {
    const { link = { id: "0", title: "", link: "" }, showUp, showDown, sortUp, sortDown, onSave, onRemove } = props
    const classes = useUIInputsStyles()

    const [form, setForm] = React.useState<any>(link)
    const setFormItem = (key: "id" | "editing" | "title" | "link", value: any) => {
        form[key] = value;
        setForm(Object.assign({}, form))
    }

    return <Box className={classNames(classes.listItem, {
        [classes.editing]: form.editing
    })}>
        <Box className={classes.itemFields}>
            <InputListItem
                key={0}
                editing={form.editing}
                option={{
                    id: "0",
                    name: 'title',// 类型名称
                    alias: '链接文本',// 别名
                    variableType: 'string',// 变量类型
                    notNull: false,// 非空
                }}
                value={form.title}
                onChange={(name: string, value: any) => {
                    setFormItem('title', value)
                }} />

            <InputListItem
                key={1}
                editing={form.editing}
                option={{
                    id: "1",
                    name: 'link',// 类型名称
                    alias: '链接链接',// 别名
                    variableType: 'link',// 变量类型
                    notNull: true,// 非空
                }}
                value={form.link}
                onChange={(name: string, value: any) => {
                    setFormItem('link', value)
                }} />
        </Box>
        <ItemTools
            editing={form.editing}
            onSave={() => {
                setFormItem("editing", false);
                if (onSave) onSave(form);
            }}
            onCancel={() => setFormItem("editing", false)}
            onEditing={() => setFormItem("editing", true)}
            onRemove={() => {
                if (onRemove) onRemove(form);
            }}
            showUp={showUp}
            showDown={showDown}
            sortUp={sortUp}
            sortDown={sortDown} />
    </Box>
}