import React, { useState,useEffect } from 'react'
import clsx from "clsx"

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';

import Search from './Search';  
import Icon from '../Icon'
import { useLocale } from '../../config/Locale'
import { useToolbarStyles } from '../../assets/jss/pages'

export interface Props  {
    numSelected?: number;
    onAddClick?: () => void;
    onDelClick?: () => void;
    onRefresh?: () => void;
    onFilter?: (keyword: string) => void;
    onSearch?: (keyword: string) => void;
    placeholder?:string;
    keyword?: string;
    title: any;
    label?: {
        add?: string,
        removeBatch?:string,
        remove?:string,
        edit?:string
    };
    tools?: any
}


export default (props: Props) => {
    const classes = useToolbarStyles();

    const { translate } = useLocale()
    const { tools, label, title, numSelected = 0, onAddClick, onDelClick, onRefresh,placeholder, keyword, onFilter, onSearch } = props;

    const [dom, setDom] = React.useState<any>(null)
    const [element, setElement] = React.useState<any>(null)
    const [top, setTop] = useState<number>(0)
    const [left, setLeft] = useState<string|number>('')
    const [width, setWidth] = useState(0)
    const [scrolled, setScolled] = useState(false)
    const handleResize = () => {
        if (element){
            setWidth(element.clientWidth)
            setLeft(element.offsetLeft)
            setTop(element.offsetTop)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        const dom = document.getElementById('fullWindownElement') || document.getElementById('screenMain')
        if (dom) {
            setDom(dom)
            dom.addEventListener('scroll', onScroll)
        }
        return () => {
            if (dom) {
                setDom(dom)
                dom.removeEventListener('scroll', onScroll)
                window.removeEventListener("resize", handleResize)
            }
        }
    })
    const onScroll = (e: any) => {
        if (!dom) {
            return
        }
        
        if (dom.scrollTop > top ) {
            setScolled(true)
        } else if (dom.scrollTop <= top) {
            setScolled(false)
        }
    }

    const toolbar = <Toolbar className={clsx(classes.root)}>
        <div className={classes.title}>
            {title} 
        </div>
        <div className={classes.spacer} />
        <div className={classes.toolbar} >
            {tools}
            {(onFilter || onSearch) && <Search
                value={keyword || ""}
                onChange={onFilter}
                onSearch={onSearch}
                placeholder={placeholder}
            />}
            {onRefresh &&
                <Button
                    size="small"
                    color="default"
                    variant="outlined"
                    onClick={onRefresh}
                    className={classes.button}>
                    <span className={classes.icon}><Icon name="Refresh" className={classes.icon} /></span>
                    <span className={classes.label}>{translate('refresh')}</span>
                </Button>}
            {onAddClick &&
                <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={onAddClick}
                    className={classes.button}
                >
                    <span className={classes.icon}><IconAdd className={classes.icon} /></span>
                    <span className={classes.label}>{(label && label.add) || "添加"}</span>
                </Button>
            }
            {numSelected > 0 && onDelClick &&
                <Button
                    size="small"
                    color="default"
                    variant="outlined"
                    onClick={onDelClick}
                    className={classes.button}>
                    <span className={classes.icon}><Icon name="RemoveBatch" className={classes.icon} color="#ff0000" /></span>
                    <span className={classes.label}>{(label && label.removeBatch) || translate('removeBatch')}</span>
                </Button>
            }
        </div>
    </Toolbar>
    return <>
        <AppBar style={{left,width,display:scrolled?"flex":"none"}} className={classes.appBar}>
             {toolbar}
        </AppBar>
        <div ref={(element:any)=>{
            setElement(element)
            if(element){
                setWidth(element.clientWidth)
                setLeft(element.offsetLeft)
                setTop(element.offsetTop)
            }
        }}>
            {toolbar}
        </div>
    </>
};
