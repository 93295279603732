import React, { useState, useEffect, useCallback } from 'react'

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { createId } from '../../../lib/util'

// 表单组件
import TextInput from '../../../components/ui/form/TextInput'
import ImageInput from '../../../components/ui/form/ImageInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import FormField from '../../../components/ui/form/FormField';
import SubmitBar from '../../../components/ui/form/SubmitBar';
import Editor from '../../../components/ui/editor'
// 弹框
import { LoadingDialog, SubmitedDialog } from '../../../components/ui/dialog'

import { withRouter } from "react-router-dom";

import { useLocale } from '../../../config/Locale'

// 逻辑
import { useBrand } from '../../../provides/shopping/BrandProvide'
import { useConfig } from '../../../provides/app/ConfigProvide'

const EditPage = (props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { get, save } = useBrand()
  const { closePage } = useConfig()

  const [loaded, setLoaded] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const resetState = () => {
    setImageLoaded(false)// 图片
    setLoaded(false)// 图片
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const [form, setForm] = useState<any>({})
  // 设置表单元素
  const setFormItem = (key: string, value: any) => {
    const newForm = form || {}
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }

  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载数据...'
    })
    get({
      params:{
        id: form.id
      },
      success: (brand: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        setForm(Object.assign({}, brand))
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        resetForm()
        resetState()
      }
    })
  }

  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id && id !== form.id) {
      resetForm()
      setFormItem('id', id)
      loadData()
    }
    return () => {
    }
  });

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        brand:form
      },
      success: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route.path) {
        history.push(route.path)
      }
    })
  }


  const renderPageForm = (<Box style={{padding:"16px 0"}}>
    <form onSubmit={onSubmit}>
      {/* 名称 */}
      <TextInput
        labelWidth={120}
        value={`${form.name || ''}`}
        fullWidth
        label="品牌名称："
        required
        placeholder="请输入品牌名称"
        onChange={(event: any) => {
          setFormItem('name', event.target.value)
        }} />
      {/* 图标 */}
      <ImageInput
        labelWidth={120}
        value={form.image}
        label="品牌图标："
        onChange={(url: string) => {
          setFormItem('image', url)
        }}
        loaded={imageLoaded}
        onLoaded={() => { setImageLoaded(true) }}
      />

      {/* 别名 */}
      <FormField
        labelWidth={120}
        label="品牌说明：">
        <Box style={{border:"1px solid #efefef",borderRadius:4}}>
          <Editor
            fixed={false}
            value={form.descri}
            loaded={loaded}
            onLoaded={() => {
              setLoaded(true)
            }}
            onChange={(value: string) => {
              setFormItem('descri', value)
            }} />
            </Box>
          </FormField>


      {/* 排序 */}
      <TextInput
        labelWidth={120}
        value={`${form.sorter || 0}`}
        inputWidth={60}
        label="排序："
        type="number"
        onChange={(event: any) => {
          setFormItem('sorter', event.target.value)
        }} />

      {/* 状态 */}
      <RadioInput
        labelWidth={120}
        value={form.status}
        options={[
          { label: translate('off'), value: "0" },
          { label: translate('on'), value: "1" }
        ]}
        label="状态："
        onChange={(e: any, value: any) => {
          setFormItem('status', value)
        }} />

      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          }
        }} />
    </form>
  </Box>)


  return (<Box>
    <Box style={{
      flex: 1
    }}>
      {renderPageForm}
    </Box>

    <LoadingDialog open={loading.open} text={loading.text} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/shopping/brand`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`/shopping/brand/edit?id=${createId()}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        history.replace(`/shopping/brand/edit?id=${form.id}`)
      }}
    />
  </Box>
  )
}
export default withRouter(EditPage)