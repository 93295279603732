
import * as actions from '../../store/actions/trade/order'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    get: Function,
    deal: Function,
    report:Function,
    wxQuery:Function,
    alipayQuery:Function
}

/**
 * 系统配置逻辑
 */
export const useOrder = (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const {params, success, failure } = props
        dispatch(actions.fetchList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                const pagination = data.result.pagination
                // 成功返回
                if (success) {
                    success(list,pagination)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取报表
    const report = (props: any) => {
        const {params, success, failure } = props
        dispatch(actions.report(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取
    const get = (props: any) => {
        const { params ,success, failure } = props
        dispatch(actions.fetch(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.order)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 保存
    const deal = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.deal(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    // 微信订单查询
    const wxQuery = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.wxQuery(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    // 支付宝订单查询
    const alipayQuery = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.alipayQuery(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    return {
        getList,
        get,
        deal,
        report,
        wxQuery,
        alipayQuery
    }
}