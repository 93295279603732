import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024"
    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3713"
    width={width} height={height}><path fill={color} d="M426.666667 170.666667 426.666667 298.666667 520.96 298.666667 375.04 640 256 640 256 768 597.333333 768 597.333333 640 503.04 640 648.96 298.666667 768 298.666667 768 170.666667 426.666667 170.666667Z" p-id="3714"></path></svg>
    )
