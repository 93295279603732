import React  from "react";
import classNames from "classnames"
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconSend from '@material-ui/icons/Send'

import {createId,formatAPDateTime,pageY,scrollAnimation,removeHtmlTag} from "../../lib/util"
import Error from "../../components/Error"
import Icon from "../../components/Icon"
import Snackbar from '../../components/ui/Snackbar';
import EditorButton from '../../components/ui/editor/plugins/Button';
import { useSysState } from '../../provides/sys'
import { useChat } from '../../provides/chat'

import { useChatStyles } from '../../assets/jss/components'
  
import LoadingBox from "../../components/ui/LoadingBox"
import Editor from '../../components/ui/editor'

import {ChatMessage,AskData} from "./types"
import ChatAvatar from "./ChatAvatar"
import LoadMore from "../../components/ui/HtmlElementLoadMore"
import SimpleUploadDialog from './components/SimpleUploadDialog'

import ChatMessageFiles from "./ChatMessageFiles"
import ChatMessageImages from "./ChatMessageImages"
   
export default (props:any) => {
  const { other,client } = props
    const classes = useChatStyles() 

    const { account } = useSysState()
    const {uploadImages,uploadFiles,download} = useChat()
     
    if(!account){
      return <Error message="用户未登录"/>
    }
    // 上传
    const [msgType,setMsgType] = React.useState<string>('0')
    const [uploadOpen,setUploadOpen] = React.useState<boolean>(false)

    // 历史消息参数
    const [endTime,setEndTime] =  React.useState<string|number>(new Date().getTime())
    const [total,setTotal] = React.useState<number>(0)
    const [pageTotal,setPageTotal] = React.useState<number>(0)
    const [page,setPage] = React.useState<number>(0)
    
    const initialSnackbarProps = { open: false, message: '',className:"",variant:"info" }
    const [snackbarProps, setSnackbarProps] = React.useState<any>(initialSnackbarProps)
   
    const [text,setText] =  React.useState<string>('')
    const [editorLoaded, setEditorLoaded] = React.useState<boolean>(false)

    const [message,setMessage] = React.useState<any>({})
    const [list,setList] = React.useState<ChatMessage[]>([])

    const {
      onConnect,
      onDisconnect,
      disconnect,
      onMessageReceived,
      offMessageReceived,
      sendMessage,
      chatRequest,
      onError,
      chatHistory,
      readedRequest
    } = client

    // 上传进度
    const [uploadPrecents,setUploadPrecents] = React.useState<any>({})
    const [uploadStatuses,setUploadStatuses] = React.useState<any>({})

    const [connected,setConnected] = React.useState<boolean>(false)
    // 消息框高度
    const [height,setHeight] = React.useState<number|string>('auto')
    const [container,setContainer] =   React.useState<HTMLElement|null>(null)

    const [room,setRoom] = React.useState<string>('')
    // 滚动状态
    const [scrollStatus,setScrollStatus] = React.useState<'toBottom'|'toTop'|'toCurrent'|''>('')
    // 加载更多状态
    const [status,setStatus] = React.useState<"ready" | "loadBegin"| "loading" | "noMore" | "error">('ready')
    const [showReady,setShowReady] = React.useState<boolean>(false)

    // 封装消息
    const wrapperChatMessage = (chatMessage:ChatMessage,unshift:boolean,scrollToTop?:boolean)=>{
        if(chatMessage){
          if(unshift){ 
            list.unshift(chatMessage)
          }else{
            list.push(chatMessage)
          }
          if(list[0] && list[0].createTime)
            setEndTime(list[0].createTime)
          setList(list)
          if(scrollToTop){
            setScrollStatus('toTop')
          }else{
            setScrollStatus('toBottom')
          }
      }
    }

    // 查询历史消息
    const handleHistory = (room:string,loadMoreCallback?:(status: "ready" | "noMore" | "error") => void)=>{
      if(loadMoreCallback){
        setMessage({
          type:"",
          text:""
        });
      }else{
        setMessage({
          type:"loading",
          text:"加载历史消息..."
        });
      }
      chatHistory({
        auth:{
          token:account.token,
          userId:account.user.id,
        },
        endTime,
        otherId:other.id
      },(askData:AskData)=>{
        if(askData.type === 'success' && askData.result){ 
          setMessage({
            type:"",
            text:""
          });
          setShowReady(true)
          const {list,allPagination} = askData.result
          const {total,pageTotal} = allPagination
          const currentPage = page+1
          setTotal(total)
          setPageTotal(pageTotal)
          setPage(currentPage)
          const id:string[] = new Array()
          list.map((item:ChatMessage) =>{
            wrapperChatMessage(item,true,loadMoreCallback?true:false)
            if(item.toUid === account.user.id){
              id.push(item.id)
            }
          });
          readedRequest({
            auth:{
              token:account.token,
              userId:account.user.id,
            },
            id,
            room
          })

          if(loadMoreCallback){
            if (total > 0 && pageTotal === currentPage) {
              // 最后一页 
              loadMoreCallback("noMore")
            } else {
              loadMoreCallback("ready")
            }
          }
        }else{
          if (total > 0 && pageTotal <= page){
            setMessage({
              type:"",
              text: ""
            });
            if(loadMoreCallback)
              loadMoreCallback("noMore")
          }else{
            setMessage({
              type:"error",
              text:askData.message || "查询失败！"
            });
            if(loadMoreCallback)
              loadMoreCallback("error")
          }
        }
      })
    }

    const onChatRequest = ()=>{
      chatRequest({ 
        auth:{
          token:account.token,
          userId:account.user.id,
        },
        me:{
              id:account.user.id,
              username:account.user.username
        },
        other
      }, (askData:AskData) =>{
        if(askData.type === 'success' && askData.result){ 
            setRoom(askData.result.room)
            handleHistory(askData.result.room)
        }else{ 
            setMessage({ 
              type:"error",
              text:askData.message || "验证失败！"
            });
        }
      });
  }
  // 查询历史新消息时滚动到顶部
  const onScrollToTop = ()=>{
    if(container){
      const scrollTop = container.scrollTop;
      scrollAnimation(container,0,scrollTop)
      setScrollStatus('')
    }
  }

    // 发送/接收新消息时滚动到底部
    const onScrollToBottom = ()=>{
      if(container){
        const scrollTop = container.scrollTop;
        const clientHeight = container.clientHeight
        const scrollHeight = container.scrollHeight
        scrollAnimation(container,scrollHeight-clientHeight,scrollTop)
        setScrollStatus('')
      }
    }
    
  // 发送/接收新消息时滚动到原地
  const onScrollToCurrent = ()=>{
    if(container){
      const scrollTop = container.scrollTop;
      scrollAnimation(container,scrollTop,scrollTop)
      setScrollStatus('')
    }
  }

    React.useEffect(() => {
      if(container){
        const height = container.clientHeight
        setHeight(height)
      }
      if(scrollStatus === 'toBottom'){
        onScrollToBottom()
      }else if(scrollStatus === 'toTop'){
        onScrollToTop()
      }else if(scrollStatus === 'toCurrent'){
        onScrollToCurrent()
      }
    }) 
    React.useEffect(()=>{
      setMessage({
        type:"loading",
        text:"正在连接服务器..."
      });
      onConnect(()=> {
        setConnected(true)
        onChatRequest()
        onMessageReceived({
          user:account.user,
          chatMessageCallback:(chatMessage:ChatMessage) =>{
            if( chatMessage && chatMessage.fromUid !== account.user.id)
              wrapperChatMessage(chatMessage,false)
           },
          leaveCallback:(message:string)=>{

          },
          statusCallback:(ids:string[],status:string)=>{
            ids.forEach((id:string)=>list.forEach((item:ChatMessage,index:number)=>{
              if(item.id === id){ 
                item.status = status
                setScrollStatus('toCurrent')
              }
            }))
          }
        });
      });
      onDisconnect(()=> {
        setConnected(false)
        setMessage({
          type:"info",
          text:"已断开连接！"
        });
      });
      onError((err:any)=>{
        console.log("error:",err)
      })
      return ()=>{
        offMessageReceived()
        disconnect()
      }
    },[])

    const handleSendMessage = ()=>{
        if(!other){
            // 未选择聊天对象
            setMessage({
              type:"error",
              text:"未选择聊天对象，未发送！"
            });
            return;
        }
        if(!room){
            // 未选择聊天对象
            setMessage({
              type:"error",
              text:"未连接对话！"
            });
            return;
        }
        if(!connected){
          setMessage({
            type:"info",
            text:"服务器未连接！"
          });
          return;
        }
        if(!removeHtmlTag(text)){
          setSnackbarProps({
              message:'不能发送空消息',
              variant:"error",
              open:true
          })
          return;
        }
        setMessage({
          type:"loading",
          text:"正在发送消息..."
        });
        let avatar:string = ''
        // if(account && account.personal){
        //   avatar = account.personal.avatar
        // }else if(account && account.weixinInfo){
        //   avatar = account.weixinInfo.headimgurl
        // }
        const chatMessage = {
          msgType:"0",
          id:createId(),
          toUid:other.id,
          fromUid:account.user.id,
          body:text,
          status:"0",
          toUser:{
            id:other.id,
            username:other.username,
            avatar:other.avatar
          },
          fromUser:{
            id:account.user.id,
            username:account.user.username,
            avatar
          },
          createTime:new Date().getTime()+''
        }
        setText('')
        setEditorLoaded(false)
        wrapperChatMessage(chatMessage,false)
        sendMessage({
            auth:{
              token:account.token,
              userId:account.user.id,
            },
            room,
            chatMessage
          },
          (data:any) =>{
            setMessage({
              type:"",
              text:"" 
            });
            setText('')
            setEditorLoaded(false)
            setScrollStatus('toBottom')
          })
    }

    const loadMoreData = (loadMoreCallback?: (status: "ready" | "noMore" | "error") => void) => {
      if (total > 0 && pageTotal === page) {
        // 没有更多了
        if(loadMoreCallback)
        loadMoreCallback("noMore")
        return;
      }
      handleHistory(room,loadMoreCallback)
    }
    
   const editBar = <Box  className={classes.footerToolbar}>
      <Box style={{flex:1}}>
      <Box className={classes.editor}>
         <Editor {...{
                value:text,
                placeholder:"",
                loaded:editorLoaded,
                onLoaded:() => {
                    setEditorLoaded(true)
                },
                onChange:(value: string) => {
                    setText( value)
                },
                options:['emoji','media'],
                extraToolbar:  <Box className={classes.tools}>
                    <EditorButton onClick={()=>{
                      setMsgType("1")
                      setUploadOpen(true)
                    }}
                    title="发送图片">
                      <Icon name="Image"/>
                    </EditorButton>
                    <EditorButton onClick={()=>{
                      setMsgType("2")
                      setUploadOpen(true)
                    }}
                    title="发送文件">
                        <Icon name="Folder"/>
                    </EditorButton>
                    {/* <EditorButton><Icon name="Screenshot"/></EditorButton> */}
                      <Box className={classes.grow}/>
                      <Button  
                          color="primary"
                          onClick={handleSendMessage}
                          style={{whiteSpace:"nowrap"}}>发表<IconSend/></Button>
                </Box>
            }} />
      </Box>
   </Box>
   </Box>
   

    return (
      <Box className={classes.main}>
       <div className={classes.chatContainer} ref={(el:any)=>{
         if(el){
          setContainer(el) 
         }
       }}
       style={{
         height
       }}>
       {pageTotal > 1 && <div style={{ fontSize:"13px",}}>
          <LoadMore
          {...{
            showReady,
            loadData:loadMoreData,
            status,
            onStatusChange:(status:"ready" | "loadBegin"| "loading" | "noMore" | "error")=>{
              setStatus(status)
            },
            onMoreClick:()=>{
              setStatus("loadBegin")
            },
            options:{
              loadingText:'正在加载更多历史消息...',
              noMoreText:'~没有更多历史消息了~',
              defaultText:'点击加载更多历史消息'
            }
          }}
        />
        </div>}
       <div>

          {list.map((item:ChatMessage,index:number)=><Box 
          key={index} 
          className={classNames(classes.messageListItem,{
            [classes.messageMe]:account.user.id === item.fromUid
          })}>
            {account.user.id === item.toUid && item.fromUser && <ChatAvatar avatar={item.fromUser.avatar}/>}
            <Box className={classes.messageContainer}>
                <Box className={classNames(classes.messageUser,{
                    [classes.alignRight]:account.user.id === item.fromUid
                  })}>
                    {account.user.id === item.fromUid ? '我' :item.fromUser && item.fromUser.username}
                  </Box>
                  <Box style={{display:"flex"}}>
                    { item.msgType === '0' && <Box className={classNames(classes.messageBody,{
                      [classes.messageBodyMe]:account.user.id === item.fromUid
                    })}
                    dangerouslySetInnerHTML={{ __html: item.body ||""}} />}
                    { uploadStatuses[item.id] === 'uploading'? 
                    <LoadingBox open={true} text={`正在上传...${uploadPrecents[item.id] || 0}%`}/> :<>
                    { item.msgType === '1' && <ChatMessageImages id={item.id}/>}
                    { item.msgType === '2' && <ChatMessageFiles id={item.id}/>}
                    </>} 
              </Box>
                <Box className={classNames(classes.messageTime,{
                   [classes.alignStart ]:account.user.id === item.toUid && item.fromUser,
                    [classes.alignEnd ] :account.user.id === item.fromUid &&  item.fromUser 
                })}>
                  {account.user.id === item.fromUid &&  item.fromUser  &&
                  <span style={{
                    color:"#666",
                    fontSize:12,
                    borderRadius:4,
                    padding:"4px 8px",
                    alignItems:"flex-end",
                    background:"#efefef",
                    margin:"0 4px"
                  }}>
                    {item.status === '0' &&<>发送中...</>}
                    {item.status === '1' &&<>发送失败</>}
                    {item.status === '2' &&<>未读</>}
                    {item.status === '3' &&<>已读</>}
                  </span>}
                    {formatAPDateTime(item.createTime || '')}
                  </Box>
             </Box>
            {account.user.id === item.fromUid &&  item.fromUser  && <ChatAvatar avatar={item.fromUser.avatar}/>}
          </Box>)}
       </div>
         <Box style={container?{
          position:"fixed",
          top:container.offsetTop + container.clientHeight - 45,
          left:container.offsetLeft,
          width:container.clientWidth,
          height:45
         }:{}}>
         {message.type === 'loading' && <LoadingBox open={true} text={message.text}/>}
         {message.type === 'success' && <Box className={classes.success}><Icon name="Success"/>{message.text}</Box>}
         {message.type === 'error' && <Box className={classes.error}><Icon name="Error"/>{message.text}</Box>}
         {message.type === 'info' && <Box className={classes.info}><Icon name="Alert"/>{message.text}</Box>}
          </Box>
       </div>
       {editBar} 
      <Snackbar 
            open={snackbarProps.open}
            className={snackbarProps.className}
            message={snackbarProps.message}
            onClose={()=>setSnackbarProps(initialSnackbarProps)}
            variant={snackbarProps.variant}
            vertical="bottom"
            horizontal="center"
            transition="up"
            />  
            <SimpleUploadDialog
              {...{
                type:msgType === '1'?'image':'file',
                open:uploadOpen,
                success: (files: any) => {
                      setMessage({
                        type:"loading",
                        text:"正在发送消息..."
                      });
                      let avatar:string = ''
                      // if(account && account.personal){
                      //   avatar = account.personal.avatar
                      // }else if(account && account.weixinInfo){
                      //   avatar = account.weixinInfo.headimgurl
                      // }
                      const id = createId();
                      const chatMessage:ChatMessage = {
                          id,
                          toUid:other.id,
                          fromUid:account.user.id,
                          msgType,
                          status:"0",
                          toUser:{
                            id:other.id,
                            username:other.username,
                            avatar:other.avatar
                          },
                          fromUser:{
                            id:account.user.id,
                            username:account.user.username,
                            avatar
                          },
                          createTime:new Date().getTime()+''
                      }
                     const data = new FormData()
                     data.append('chatMessage', JSON.stringify(chatMessage))
                     files.forEach((file:any) => data.append('file', file))
                     let upload = msgType === '1' ? uploadImages:msgType === '2' && uploadFiles;
                     if(upload){
                      uploadStatuses[id] = 'uploading'
                      setUploadStatuses(Object.assign({},uploadStatuses))
                      wrapperChatMessage(chatMessage,false)
                      upload({
                        data,
                        onProgress:(event:any)=>{
                               if (event.lengthComputable) {
                                 //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
                                 //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
                                 // callback(progressEvent);
                                 //{{Math.ceil(precent)}}%
                                 // precent.toFixed(2)
                                 const loaded = event.loaded;
                                 const total = event.total;
                                 const precent = (loaded / total) * 100;
                                 uploadPrecents[id] = precent;
                                 setUploadPrecents(Object.assign({},uploadPrecents))
                               }
                        },
                        success:(chatMessage:ChatMessage)=>{
                          // 上传成功，发送消息
                         sendMessage({
                             auth:{
                               token:account.token,
                               userId:account.user.id,
                             },
                             room,
                             chatMessage
                           },
                           (data:any) =>{
                             setMessage({
                               type:"",
                               text:"" 
                             });
                             uploadStatuses[id] = 'success'
                             setUploadStatuses(Object.assign({},uploadStatuses))
                             setScrollStatus('toBottom')
                           })
                        },
                        failure:(message:string)=>{
                         setMessage({ 
                           type:"error",
                           text:message || "上传失败！"
                         });
                        }
                      })
                     }
                     setUploadOpen(false)
                },
                onClose: () => { 
                  setUploadOpen(false)
                }
              }} />
            </Box>
    )
}