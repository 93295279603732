
import { shallowEqual, useSelector } from "react-redux";
import * as State from "../../store/state";

export { useAttach } from './AttachProvide'
export { useFile} from './FileProvide'
export {useModule} from './ModuleProvide'
export {useAd} from './AdProvide'
export {useArea} from './AreaProvide'
export {useIndexed} from './IndexedProvide'
export {useFriendUrl} from './FriendUrlProvide'

export const useAttachState = ()=> {
  return useSelector((state: State.Root) => {
    return {
      list:state.common.attach.list || [],
      pagination:state.common.attach.pagination,
      groupList:state.common.attach.groupList || [],
      error:state.common.attach.error,
      success:state.common.attach.success,
    };
  }, shallowEqual);
};

export const useModuleState = ()=> {
  return useSelector((state: State.Root) => {
    return {
      list:state.common.module.list || [],
      module:state.common.module.module,
      error:state.common.module.error,
      success:state.common.module.success,
    };
  }, shallowEqual);
};

export const useAdState = ()=> {
  return useSelector((state: State.Root) => {
    return {
      list:state.common.ad.list || [],
      activeList: state.common.ad.activeList || [],
      pagination: state.common.ad.pagination,
      article: state.common.ad.ad,
      error: state.common.ad.error,
      success: state.common.ad.success
    };
  }, shallowEqual);
};

export const useAreaState = ()=> {
  return useSelector((state: State.Root) => {
    return {
      list:state.common.area.list || [],
      area:state.common.area.area,
      error:state.common.area.error,
      success:state.common.area.success,
    };
  }, shallowEqual);
};
