import React from "react"

import { useUIPageStyles } from "../../../../assets/jss/pages"
import Icon from "../../../../components/Icon"

import Box from '@material-ui/core/Box';
import RadioInput from '../../../../components/ui/form/RadioInput';
import ProductCatInput from '../../../../components/shopping/CatInput';
import NumberInput from '../../../../components/ui/form/NumberInput'

import { DataSource } from "../../../../types/ui"

type Props = {
    dataSource?: DataSource,
    onChange: (dataSource: DataSource) => void
}

// 组件数据输入
export default (props: Props) => {
    const classes = useUIPageStyles()
    const { dataSource, onChange } = props

    const labelWidth = 120

    const [dsForm, setDsForm] = React.useState<any>(dataSource || {
    })
    const setDataSourceItem = (key: "near" | "hots" | "type" | "dynamic" | "catId" | "maxItems", value: any) => {
        dsForm[key] = value
        setDsForm(Object.assign({}, dsForm))
        onChange(dsForm)
    }

    return <Box>

        <RadioInput
            labelWidth={labelWidth}
            label="数据获取方式："
            value={dsForm.dynamic ? "1" : "0"}
            options={[
                { label: "静态数据", value: "0" },
                { label: "动态数据", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                setDataSourceItem("dynamic", value === "1" ? true : false)
            }} />
        {
            dsForm.dynamic &&
            <Box className={classes.startTips} style={{ paddingBottom: 10 }}>
                <Icon name="Alert" className={classes.icon} />
                动态解析页面参数加载数据！
                    </Box>
        }
        {!dsForm.dynamic && <ProductCatInput
            labelWidth={labelWidth}
            label="选择商品分类："
            value={dataSource && dataSource.catId}
            onChange={(item: any) => {
                setDataSourceItem("type", "products")
                setDataSourceItem("catId", item.id)
            }} />}
        <RadioInput
            labelWidth={labelWidth}
            label="热门商品列表："
            value={dsForm.hots ? "1" : "0"}
            options={[
                { label: "关闭", value: "0" },
                { label: "开启", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                setDataSourceItem("hots", value === "1" ? true : false)
            }} />
        {dsForm.hots &&
            <NumberInput
                labelWidth={labelWidth}
                value={dsForm.near || 1}
                label="热门最近天数："
                onChange={(value: any) => {
                    setDataSourceItem("near", value);
                }} />}

        <NumberInput
            labelWidth={labelWidth}
            value={dsForm.maxItems || (!dsForm.dynamic ? 5 : 12)}
            label="最大条目数："
            onChange={(value: any) => {
                setDataSourceItem("maxItems", value);
            }} />

    </Box>
}