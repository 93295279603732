import React, { useState, useEffect } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormField from '../../ui/form/FormField';

import { useLocale } from '../../../config/Locale'
import { useSpec } from '../../../provides/shopping/SpecProvide'
import {Spec} from "../../../types/shopping"

import LoadingBox from '../../ui/LoadingBox'
import { MessageDialog } from '../../ui/dialog'

import { isFunction } from '../../../lib/util'
import { ProductSpecProps } from './types'

import Form from './Form'
import List from './List'
import Table from './Table'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            clear: 'both',
            margin: '5px 0',
            borderBottom: '1px solid #dedede'
        },
        rowLabel: {
            whiteSpace: 'nowrap',
            float: 'left',
            padding: '0 10px'
        },
        rowContent: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            flexWrap: 'wrap',
            userSelect: 'none'
        },
        button: {
            fontSize: '0.8em',
            marginRight: theme.spacing(1)
        },
        error: {
            display: 'inline-flex',
            color: '#ff6600',
            fontSize: '0.8em',
            alignItems: 'center'
        },
        form: {
            borderRadius: 5
        },
        list: {
            borderRadius: 5
        },
        icon: {
            margin: '0 5px 0 0',
            width: 16,
            height: 16
        },
        aBoxContainer: {
            margin: '15px 0',
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            flexWrap: 'wrap',
            userSelect: 'none'
        },
        aBox: {
            position: 'relative',
            border: '1px solid #eee',
            color: '#666',
            margin: '5px 10px 5px 0',
            padding: '5px 25px 5px 10px',
            cursor: 'pointer',
            borderRadius: 3,
            '&:hover': {
                border: '1px solid #666',
                color: '#000',
            }
        },
        aBoxValue: {
            fontStyle: 'italic',
            fontWeight: 'bold'
        },
        aBoxClose: {
            position: 'absolute',
            top: '-5px',
            right: '-5px',
            borderRadius: '50%',
            background: '#fff'
        },
        active: {
            color: '#333',
            background: '#f3f3f3',
            border: '1px inset #ddd',
        },
        paper: {
            margin: theme.spacing(1, 0)
        },
        table: {

        },
        dynamicTableCell: {
            borderRight: '1px solid #ddd',
            paddingLeft: 5
        },
        fixedTableCell: {
            width: 80,
            justifyContent: 'center'
        },
        tableInput: {
            width: 80
        },
        buttons: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            clear: 'both',
            margin: '5px 0'
        },
    }))

export default (props: ProductSpecProps) => {
    const { translate } = useLocale()
    const classes = useStyles()
    const { getListByCat } = useSpec()
    const {
        labelWidth,
        catId,
        loaded,
        onLoaded,
        productSpecs,
        onChange
    } = props

    const [specList,setSpecList] = useState<Spec[]>([])

    // 事件反馈
    const initialMessage = {
        open: false,
        type: '',
        title: '',
        text: ''
    }
    const [message, setMessage] = useState(initialMessage)
    const initialLoading = { open: false, text: '' }
    const [loading, setLoading] = useState({
        open: false,
        text: ''
    })

    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState<any>({})
    // 表格载入
    const [tableOnLoad, setTableOnLoad] = useState(false)

    const currentSelects = (specList:Spec[]) => {
        let newSelected: any = selected || {}
        let currentValId: any = []
        if (productSpecs)
            productSpecs.map((ps: any) => {
                const valId = ps.specValId
                const array = valId.split(',')
                currentValId = currentValId.concat(array)
            })
        currentValId = Array.from(new Set(currentValId))
        if (specList)
            specList.map((spec: any) => {
                if (spec.valList) {
                    spec.valList.map((specVal: any) => {
                        // 如果当前值已存在于已保存的商口规格
                        if (currentValId.indexOf(specVal.id) != -1) {
                            // 添加
                            newSelected[spec.id] = newSelected[spec.id] || {
                                spec,
                                specVals: {}
                            };
                            newSelected[spec.id]["specVals"][specVal.id] = specVal;
                        }
                    })
                }
            })
        setSelected(Object.assign({}, newSelected))
        // 重载表格
        setTableOnLoad(false)
    }

    // 查询规格列表
    const loadSpecList = () => {
        // 保存规格列表
        setLoading({
            open: true,
            text: translate('loadingText.data')
        })
        getListByCat({
            params: {
                catId
            },
            success: (list: Spec[]) => {
                // 关闭加载条
                setLoading(initialLoading)
                setSpecList(list)
                currentSelects(list)
            },
            failure: (message: string) => {
                // 关闭加载条
                setLoading(initialLoading)
                currentSelects([])
            }
        })
    }
    // 加载初始化列表
    useEffect(() => {
        if (!loaded) {
            if (onLoaded && isFunction(onLoaded)) {
                onLoaded()
            }
            loadSpecList()
        }
    })

    const renderButtons = (
        <Box className={classes.buttons}>
            <Button
                variant="outlined"
                className={classes.button}
                onMouseDown={() => {
                    setShow(true)
                }} >
                添加规格
             </Button>
            <Button
                variant="outlined"
                className={classes.button}
                onMouseDown={() => {
                    // go to spec list page
                }} >
                管理规格
        </Button>
        </Box>
    )

    /**
     * 表单
     */
    const renderForm = <Form {...{
        catId,
        classes,
        onHide: () => {
            setShow(false)
        },
        onSaved: (loading: any) => {
            setLoading(loading)
        },
        onSavedSuccess: (message: string) => {
            // 关闭加载条
            loadSpecList()
        },
        onSavedFailure: (message: string) => {
            // 关闭加载条
            setLoading(initialLoading)
            setMessage({
                open: true,
                type: 'error',
                title: translate('alert.tips'),// 提示
                text: message
            })
        }
    }} />

    // <!-- 已添加的键值对规格列表 -->
    const renderList = <List
        {...{
            classes,
            specList,
            selected,
            onSelected: (selected: any) => {
                setSelected(selected)
                // 重载表格
                setTableOnLoad(false)
            }
        }} />

    // 规格价格表格
    const renderTable = <Table
        {...{
            classes,
            selected,
            productSpecs,
            loaded: tableOnLoad,
            onLoaded: () => {
                setTableOnLoad(true)
            },
            onChange: (tableData: any) => {
                const productSpecs: any = [];
                tableData.map((item: any) => {
                    // 解析为商品规格，递交表单提交服务器
                    productSpecs.push({
                        price: item["price"],
                        oprice: item["oprice"],
                        inprice: item["inprice"],
                        stock: item["stock"],
                        image: item["image"],
                        specValId: item['valId']
                    });
                });
                onChange(productSpecs);
            }
        }} />
    return (<FormField
        label=""
        labelWidth={labelWidth}>
        {renderButtons}

        {show && renderForm}

        {renderList}

        {renderTable}

        <LoadingBox {...loading} />

        <MessageDialog
            onClose={() => {
                setMessage(initialMessage)
            }}
            message={message} />
    </FormField>)
}