import React, { useState, useEffect } from "react"
import Select from 'react-select';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import FormField from '../../../components/ui/form/FormField'
import TextInput from '../../../components/ui/form/TextInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import RadioInput from '../../../components/ui/form/RadioInput'
 
import { withRouter } from "react-router-dom";

import { useComponent, useDatatypeState } from '../../../provides/ui'
import { useConfig } from '../../../provides/app'
import { Component, Datatype } from "../../../types/ui"
import { useLocale } from '../../../config/Locale'
// 弹框
import { LoadingDialog, SubmitedDialog } from '../../../components/ui/dialog'

import { ComponentOption } from "../../../types/ui"

import OptionsTable from "./OptionsTable"

export default withRouter((props: any) => {
  const { history } = props
  const { get, save } = useComponent()
  const { closePage } = useConfig()
  const { translate } = useLocale()
  const datatypes = useDatatypeState().list

  const [form, setForm] = useState<any>({})
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载组件信息...'
    })
    get({
      id: form.id,
      success: (component: Component) => {
        // 关闭加载条
        setLoading(initialLoading)
        setForm(component)
      },
      failure: (message: string) => {
        // 关闭加载条 
        setLoading(initialLoading)
        reloadParams()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id !== form.id) {
      resetForm()
      setFormItem('id', id)
      loadData()
    }
    return () => {
    }
  });
  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        component: {
          ...form
        }
      },
      success: (message: string) => {
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: message
        })
      },
      failure: (message: string) => {
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  const renderPageTitle = (
    <Box className="page-title">
      {translate('ui.component.edit')}
    </Box>)

  const getDatatype = (value: string) => {
    const result = datatypes.filter((dt: Datatype) => dt.value === value)
    return result && result.length > 0 ? result[0] : {}
  }

  const renderForm = <form onSubmit={onSubmit}>

    <FormField
      labelWidth={120}
      label="数据类型：">
      <Select
        value={getDatatype(form.datatype)}
        onChange={(selectOption: any) => {
          setFormItem('datatype', selectOption.value)
        }}
        options={datatypes || []}
      />
    </FormField>

    <TextInput
      labelWidth={120}
      value={`${form.name || ''}`}
      fullWidth
      label="组件名称："
      placeholder="组件名称对应前端sectionType,具有唯一性..."
      onChange={(event: any) => {
        setFormItem('name', event.target.value)
      }} />

    <TextInput
      labelWidth={120}
      value={`${form.alias || ''}`}
      fullWidth
      label="组件别名："
      placeholder="便于设置时识别的别名"
      onChange={(event: any) => {
        setFormItem('alias', event.target.value)
      }} />

    <TextInput
      labelWidth={120}
      value={`${form.description || ''}`}
      fullWidth
      label="组件说明："
      placeholder="填写组件的用途，适用范围等，以便于维护..."
      onChange={(event: any) => {
        setFormItem('description', event.target.value)
      }} />

    <OptionsTable
      edittable
      options={form.options || []}
      onChange={(options: ComponentOption[]) => {
        setFormItem('options', options)
      }} />

    <SubmitBar
      {...{
        labelWidth: 120,
        onSubmit,
        padding: "0 0 0 120px",
        onCancel: () => {
          closePage(history.location.pathname, (props: any) => {
            const { route } = props
            if (route.path) {
              history.push(route.path)
            }
          })
        },
        onReset: () => {
          loadData()
        }
      }} />
  </form>

  return <Box>
    {renderPageTitle}
    <Divider />
    {renderForm}

    <LoadingDialog {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/ui/component`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`/ui/component/edit?id=${Math.ceil(Math.random() * 9999)}&parentId=${form.parentId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        history.replace(`/ui/component/edit?id=${form.id}`)
      }}
    />
  </Box>
})