import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as commonTypes from '../../../types/common'

// Action Types
export type Action = {
    // 列表
    type: 'COMMON_MODULE_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type: 'COMMON_MODULE_FETCH_LIST_SUCCESS', payload: any }
    | { type: 'COMMON_MODULE_FETCH_LIST_FAILURE', payload: { error: types.Error } }
        | {
        // 模块
        type: 'COMMON_MODULE_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'COMMON_MODULE_FETCH_SUCCESS', payload: any }
    | { type: 'COMMON_MODULE_FETCH_FAILURE', payload: { error: types.Error } }
    | {
        // 保存
        type: 'COMMON_MODULE_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 删除
        type: 'COMMON_MODULE_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 设置状态
        type: 'COMMON_MODULE_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };


// 列表
export const FETCH_LIST = 'COMMON_MODULE_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'COMMON_MODULE_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'COMMON_MODULE_FETCH_LIST_FAILURE';
export const fetchList = (): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/module/getList`,
            method: 'GET'
        }
    }
})
export const fetchListSucess = (list: commonTypes.Module[]): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});






// 按URL获取列表
export const FETCH = 'COMMON_MODULE_FETCH'
export const FETCH_SUCCESS = 'COMMON_MODULE_FETCH_SUCCESS';
export const FETCH_FAILURE = 'COMMON_MODULE_FETCH_FAILURE';
export const fetch = (id: string): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/module/get`,
            method: 'POST',
            params: {
                id
            }
        }
    }
})

export const fetchSucess = (list: commonTypes.Module): Action => ({
    type: FETCH_SUCCESS,
    payload: {
        list
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});



// 保存分组
export const SAVE = 'COMMON_MODULE_SAVE'
export const save = (data: any): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/module/save`,
            method: 'POST',
            data
        }
    }
})



// 删除分组
export const REMOVE = 'COMMON_MODULE_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/module/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})




// 设置状态
export const SET_STATUS = 'COMMON_MODULE_SET_STATUS'
export const setStatus = (data:any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/module/setStatus`,
            method: 'POST',
            data
        }
    }
})
