import React from "react";

import { useUIPageStyles } from "../../../../assets/jss/pages";

import Icon from "../../../../components/Icon";

import Box from "@material-ui/core/Box";

import RadioInput from "../../../../components/ui/form/RadioInput";
import AdListImport from "./AdListImport";
import InputList from "./InputList";

import { DataSource } from "../../../../types/ui";
import { CarouselItem } from "./types";

type Props = {
  data?: CarouselItem[];
  dataSource?: DataSource;
  onChange: (dataSource: DataSource, data?: CarouselItem[]) => void;
};
const options = [
  {
    id: "1",
    name: "backgroundColor", // 类型名称
    alias: "背景色", // 别名
    variableType: "color", // 变量类型
    notNull: false // 非空
  },
  {
    id: "2",
    name: "color", // 类型名称
    alias: "字体颜色", // 别名
    variableType: "color", // 变量类型
    notNull: false // 非空
  },
  {
    id: "3",
    name: "image", // 类型名称
    alias: "图片", // 别名
    variableType: "image", // 变量类型
    notNull: true // 非空
  },
  {
    id: "4",
    name: "title", // 类型名称
    alias: "标题", // 别名
    variableType: "string", // 变量类型
    notNull: false // 非空
  },
  {
    id: "5",
    name: "content", // 类型名称
    alias: "内容", // 别名
    variableType: "html", // 变量类型
    notNull: false // 非空
  },
  {
    id: "5",
    name: "buttons", // 类型名称
    alias: "按钮组", // 别名
    variableType: "Button[]", // 变量类型
    notNull: false // 非空
  },
  {
    id: "6",
    name: "link", // 类型名称
    alias: "链接地址", // 别名
    variableType: "link", // 变量类型
    notNull: false // 非空
  }
];

// 组件数据输入
export default (props: Props) => {
  const classes = useUIPageStyles();
  const { data, dataSource, onChange } = props;

  const [inputType, setInputType] = React.useState(
    (dataSource && dataSource.type) || ""
  );

  const tools = (
    <RadioInput
      labelWidth={0}
      value={inputType}
      options={[
        { label: "导入广告数据", value: "ads" },
        { label: "录入数据", value: "input" }
      ]}
      onChange={(e: any, value: any) => {
        setInputType(value);
      }}
    />
  );

  const renderInputs = () => {
    switch (inputType) {
      case "ads":
        return (
          <AdListImport
            tools={tools}
            ids={dataSource && dataSource.ids}
            onChange={(ids: string[]) => {
              // 需要转换为CarouselItem
              onChange({ type: "ads", ids });
            }}
          />
        );
      case "input":
        // 直接录入
        return (
          <InputList
            tools={tools}
            options={options}
            data={data || []}
            edittable
            onChange={(data: CarouselItem[]) => {
              onChange({ type: "input" }, data);
            }}
          />
        );
      default:
        break;
    }
    return (
      <>
        {tools}
        <Box className={classes.startTips} style={{ paddingTop: 20 }}>
          <Icon name="Alert" className={classes.icon} />
          请选择数据源类型！
        </Box>
      </>
    );
  };

  return <Box>{renderInputs()}</Box>;
};
