import * as actions from '../../store/actions/trade/send'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    get: Function,
    save: Function,
    remove:Function
}

/**
 * 逻辑
 */
export const useSend = (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const {params, success, failure } = props
        dispatch(actions.fetchList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.list)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取
    const get = (props: any) => {
        const {params, success, failure } = props
        dispatch(actions.fetch(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.send)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }


    // 保存
    const save = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.save(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '发货设置完成！')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败！')
                }
            }
        })
    }
    

    // 保存
    const remove = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.remove(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '发货信息已删除！')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败！')
                }
            }
        })
    }

    return {
        getList,
        save,
        get,
        remove
    }
}