


import { CompositeDecorator } from 'draft-js';

import Link from '../nodes/Link'
import Media from '../nodes/Media'

export function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges(
    (character: any) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}


export function findMediaEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges(
    (character: any) => {
      const entityKey = character.getEntity();
      const type = entityKey && contentState.getEntity(entityKey).getType()
      return (
        type &&
        (type === 'image' ||
          type === 'video' ||
          type === 'audio'
        )
      );
    },
    callback
  );
}


export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
  // {
  //   strategy: findMediaEntities,
  //   component: Media,
  // }
]);
export default decorator

