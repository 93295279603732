import { combineReducers } from 'redux';

import {State} from '../../state/common';

import {
    initialState as attachInitialState,
    reducer as attachReducer
} from './attach';

import {
    initialState as moduleInitialState,
    reducer as moduleReducer
} from './module';

import {
    initialState as adInitialState,
    reducer as adReducer
} from './ad';

import {
    initialState as friendUrlInitialState,
    reducer as friendUrlReducer
} from './friendUrl';

import {
    initialState as areaInitialState,
    reducer as areaReducer
} from './area';

export const initialState: State = {
    attach: attachInitialState,
    module: moduleInitialState,
    area: areaInitialState,
    ad: adInitialState,
    friendUrl: friendUrlInitialState,
};

export const reducers = combineReducers<State>({
    attach: attachReducer,
    module: moduleReducer,
    area: areaReducer,
    ad: adReducer,
    friendUrl: friendUrlReducer
});