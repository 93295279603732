import React from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {removeHtmlTag} from "../../lib/util"
import { useChatStyles } from '../../assets/jss/components'

import {ChatUser,Register,AskData } from "./types"
import { useSysState } from '../../provides/sys'
import LoadingBox from "../../components/ui/LoadingBox"
import Icon from "../../components/Icon"
import ChatAvatar from "./ChatAvatar"

type Props = {
  onSelect:(user:ChatUser)=>void,
  client:any
} 


export default (props:Props) => { 
    const classes = useChatStyles()
    const {
      onSelect,
      client
    } = props
    const { account } = useSysState()
    if(!account){ 
      return <></>
    }
    const [container, setContainer] = React.useState<HTMLElement | null>(null)

    const [maxHeight,setMaxHeight] = React.useState<string|number>('auto')
    const [list,setList] = React.useState<Register[]>([])
    const [message,setMessage] = React.useState<any>({})

    const [connected,setConnected] = React.useState<boolean>(false)
    const {onConnect,onDisconnect,disconnect,registerRequest,onRegistersReceived} = client

    const handleRegister = ()=>{
        setMessage({
                type:"loading",
                text: "正在获取在线用户列表..."
        });
        registerRequest({
          auth:{
              token:account.token,
              userId:account.user.id,
          }
        }, (askData:AskData) =>{
          if(askData.type === 'success'){ 
                setMessage({
                  type:"",
                  text:""
                });
                setList(Object.assign([],askData.result.list))
          }else{ 
              setMessage({
                type:"error",
                text:askData.message || "离线..."
              });
          }
        });
    }

    
    React.useEffect(() => {
      setMessage({
        type:"loading",
        text:"正在连接服务器..."
      });
      onConnect(()=> {
        setConnected(true)
        setMessage({
          type:"",
          text:""
        });
        handleRegister()
        onRegistersReceived((registers:Register[])=>{
          setList(Object.assign([],registers))
        })
      });
      onDisconnect(()=> {
        setConnected(false)
        setMessage({
          type:"info",
          text:"已断开连接！"
        });
      });
      return () => {
        disconnect()
      }
    }, [])  

    React.useEffect(()=>{
      setMaxHeight(window.innerHeight-200)
    })


    return (
       <Box style={{display:"flex",flexDirection:"column"}}>
        <div  ref={(ref: any) => {
        if (ref) {
          setContainer(ref)
        }
      }}
      style={{flexGrow:1,overflowY:"auto",maxHeight}}
      >
        <div>
       <Box className={classes.tips}>
          {message.type === 'loading' && <LoadingBox open={true} text={message.text}/>}
          {message.type === 'success' && <div className={classes.success}><Icon name="Success"/>{message.text}</div>}
          {message.type === 'error' && <div className={classes.error}><Icon name="Error"/>{message.text}</div>}
          {message.type === 'info' && <div className={classes.info}><Icon name="Alert"/>{message.text}</div>}
         </Box>
        <List>
        {list.map((item:Register,index:number)=><ListItem 
            key={index}
            dense 
            button 
            onClick={()=>{
              onSelect(item.other)
            }}>
            <ListItemIcon>
            <ChatAvatar  avatar={item.other.avatar}/>
            </ListItemIcon>
            <ListItemText primary={<Box className={classes.listitemText}>
            <Box className={classes.listitemUsername}>{item.other.username}</Box>
            {item.lastUnread && <Box className={classes.listitemBody}>{ removeHtmlTag(item.lastUnread.body || '',10)}</Box>}
            {item.unreadTotal > 0 && <Box><Box className={classes.unreadTotal}>{item.unreadTotal}</Box></Box>}
             </Box>}/>
        </ListItem>)}
        </List>
        </div>
        </div>
      </Box>
    )
}