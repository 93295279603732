
import * as api from '../api'
import { API, attachPrefix } from './index'
import SinglePage from '../pages/content/article/single'
import ListPage from '../pages/content/article/articles'
/**
 *  封装分类
 */
const parseCats = (cats: any) => {
  let items: any = []
  cats.map((cat: any) => {
    let item: any = {
      name: cat.id,
      title: cat.name,
      icon: 'Document',
      iconUrl: attachPrefix + cat.icon,
      component: cat.catType === '2' ? SinglePage : ListPage,
      path: cat.catType === '2' ? `/content/article/single/${cat.id}` : `/content/article/list/${cat.id}`
    }
    let children = cat.children
    if (children && children.length > 0) {
      item['children'] = parseCats(children)
    }
    items.push(item)
  })
  return items
}

/**
 * 获取模块列表
 */
const getModules = () => {
  return api.get({
    url: `${API}/common/module/getList`
  }).then((res: any) => {
    // 返回数据
    if (res.data.code === 1) {
      return res.data.result.list
    } else {
      return undefined
    }
  })
}
/**
 * 获取分类列表
 * @param {模块ID} moduleId
 */
const getCats =  (moduleId: string) => {
  
  return api.get({
    url: `${API}/content/cat/getActiveList`,
    params: {
      moduleId
    }
  }).then((res: any) => {

    // 返回数据
    if (res.data.code === 1) {
      return res.data.result.list
    } else {
      return undefined
    }
  })
}

export const fetchContentCats = async () => {
  // return new Promise(async resolve => {
  let data = []
  // 动态内容分类
  // 首先获取模块列表，再查询所有可用分类
  let modules: any = await getModules()
  if (modules) {
    for (let i = 0; i < modules.length; i++) {
      let module = modules[i]
      let cats = await getCats(module.id)
      // 将查询到的分类列表转换为解析器能识别的内容
      if (cats) {
        let children = parseCats(cats)
        if (children.length > 0) {
          if (modules.length === 1) {
            // 只有一个模块，则直接返回分类列表
            data = children
          } else {
            // 返回解析器能识别的数据
            data.push({
              name: module.id,
              title: module.name,
              icon: 'Folder',
              iconUrl: attachPrefix + module.icon,
              children: children
            })
          }
        }
      }
    }
  }
  return data
  // resolve(data)
  // })
}
