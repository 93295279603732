
import { shallowEqual, useSelector } from "react-redux";
import * as State from "../../store/state";

export { useCat} from './CatProvide'
export { useArticle} from './ArticleProvide'

export const useCatState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.content.cat.list || [],
      activeList: state.content.cat.activeList || [],
      pagination: state.content.cat.pagination,
      cat: state.content.cat.cat,
      parent: state.content.cat.parent,
      names: state.content.cat.names,
      error: state.content.cat.error,
      success: state.content.cat.success
    };
  }, shallowEqual);
};

export const useArticleState = () => {
  return useSelector((state: State.Root) => {
    return {
      list:state.content.article.list || [],
      activeList: state.content.article.activeList || [],
      pagination: state.content.article.pagination,
      article: state.content.article.article,
      error: state.content.article.error,
      success: state.content.article.success
    };
  }, shallowEqual);
};