import * as types from '../../../types/app'

// Action Types
export type Action = {// 应用Action
    type: 'FACTORY',
    payload: {config:types.Config}
} | {
    type: 'CURRENT_ROUTES',
    payload: {currentRoutes:types.CurrentRoutes}
} | {
    type: 'LOCALE_CONFIG',
    payload: {localeConfig: any}
} | {
  type: 'THEME_CONFIG',
  payload: {theme: any}
}

/** 配置初始化  */
export const FACTORY = 'FACTORY'
export const factory = (config: types.Config): Action => ({
    type: FACTORY,
    payload: {
        config
    }
})


// 返回当前路由列表
export const CURRENT_ROUTES = 'CURRENT_ROUTES';
export const currentRoutes = (currentRoutes: types.CurrentRoutes): Action => ({
    type: CURRENT_ROUTES,
    payload: {
        currentRoutes
    }
});

// 返回当前语种配置
export const LOCALE_CONFIG = 'LOCALE_CONFIG';
export const localeConfig = (localeConfig: any): Action => ({
    type: LOCALE_CONFIG,
    payload: {
        localeConfig
    }
});

// 返回当前主题
export const THEME_CONFIG = 'THEME_CONFIG';
export const themeConfig = (theme: any): Action => ({
    type: THEME_CONFIG,
    payload: {
        theme
    }
});
