
import React from "react"
import {createId} from "../../lib/util"
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { useCommentWriteStyles } from "../../assets/jss/components"

import {LoadingDialog} from '../ui/dialog'
import Snackbar from '../ui/Snackbar';
import Editor from "../ui/editor"
 
import { useComment } from '../../provides/user';
 
type Props = { 
    topTargetType:"article"|"product"|"orderDetail",
    targetType:"article"|"product"|"orderDetail"|"comment",
    targetTopid:string,
    targetId:string,  
    onSubmited?:(total:number)=>void
}

export default (props:Props)=>{
    const classes = useCommentWriteStyles()
    const {
        topTargetType,
        targetType,
        targetTopid,
        targetId,
        onSubmited
     } = props
    const initialSnackbarProps = { open: false, message: '',className:"",variant:"info" }
    const [snackbarProps, setSnackbarProps] = React.useState<any>(initialSnackbarProps)
    const setSnackbarPropsItem = (key:string,value:any)=>{
        snackbarProps[key] = value
        setSnackbarProps(snackbarProps)
    }
    const initialLoading = { open: false, text: '' }
    const [loading, setLoading] = React.useState<any>(initialLoading)

    const [value,setValue] = React.useState<string>("")
    const [loaded, setLoaded] = React.useState<boolean>(false)

    const onChange = (value:string)=>{
        setValue(value)
    }

    const {save} = useComment()
    const handleSubmit = ()=>{
        setSnackbarPropsItem("open",false)
        setLoading({
            open: true,
            text: '正在发布评论...'
        })
        save({
            comment:{
                id:createId(),
                topTargetType,
                targetType,
                targetTopid,
                targetId,
                content:value
            },
            success:(total:number)=>{
                setValue("")
                setLoaded(false)
                setSnackbarPropsItem("message","评论已完成!")
                setSnackbarPropsItem("variant","success")
                setSnackbarPropsItem("open",true)
                if(onSubmited){
                    onSubmited(total)
                }
                setLoading(initialLoading)
            },
            failure:(message:string)=>{
                setSnackbarPropsItem("message",message)
                setSnackbarPropsItem("variant","error")
                setSnackbarPropsItem("open",true)
                setLoading(initialLoading)
            }
        })
    }

    return <Box className={classes.section} >
            <Box  style={{border:"1px solid #dedede",borderRadius:4}}>
                    <Editor {...{
                        fixed:false,
                        value,
                        placeholder:"在此说出你的看法...",
                        loaded,
                        onLoaded:() => {
                            setLoaded(true)
                        },
                        onChange:(value: string) => {
                            onChange( value)
                        },
                        options:[
                            'fontWeight',
                            'fontStyle',
                            'underline',
                            'lineThrough',
                            'fontSize',
                            'fontColor',
                            'emoji'
                        ]
                    }} />
                    <Box style={{background:"#f7f7fc"}}>
                    <Box className={classes.grow}/>
                    <Button 
                        color="primary"
                        onClick={handleSubmit}
                        style={{whiteSpace:"nowrap"}}>提交评论</Button>
                    </Box>
            </Box>
            <LoadingDialog {...loading} />
            <Snackbar 
            open={snackbarProps.open}
            className={snackbarProps.className}
            message={snackbarProps.message}
            onClose={()=>setSnackbarPropsItem("open",false)}
            variant={snackbarProps.variant}
            vertical="bottom"
            horizontal="center"
            transition="up"
            />  
    </Box> 
} 