import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1152 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
    p-id="5998" width={width} height={height}><path fill={color} d="M1088 128h-64V64c0-35.2-28.8-64-64-64H64C28.8 0 0 28.8 0 64v768c0 35.2 28.8 64 64 64h64v64c0 35.2 28.8 64 64 64h896c35.2 0 64-28.8 64-64V192c0-35.2-28.8-64-64-64zM128 192v640H64.128L64 831.872V64.096l0.128-0.128h895.776l0.128 0.128v63.872h-768c-35.2 0-64 28.8-64 64z m960 767.872l-0.128 0.128H192.096l-0.128-0.128V192.096l0.128-0.128h895.776l0.128 0.128v767.776z" p-id="5999"></path><path d="M960 352a96 96 0 1 1-192 0 96 96 0 0 1 192 0zM1024 896H256v-128l224-384 256 320h64l224-192z" p-id="6000"></path></svg>
    )
