import { Action } from '../../actions';
import * as Actions from '../../actions/sys';
import {State} from '../../state/sys';
import { getAccount } from '../../../storage/AccountStorage';

export const initialState: State = {
    account:getAccount()
};

export const reducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case Actions.FETCH_APP_TOKEN_SUCCESS:
            return {
                ...state,
                appToken: action.payload.appToken
            };

        case Actions.FETCH_APP_TOKEN_FAILURE:
            return {
                ...state, 
                code: action.payload.code,
                message: action.payload.message
            };
        case Actions.LOGIN_SUCCESS:
            return {
                ...state,
                account: action.payload.account,
            };

        case Actions.LOGIN_FAILURE:
            return {
                ...state,
                code: action.payload.code,
                message: action.payload.message
            };

        case Actions.LOGOUT_FINISHED:
            return {
                ...state,
                account: undefined,
                code: action.payload.code,
                message: action.payload.message
            };

        default: return state;
    }
};