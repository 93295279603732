import React from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
// import { TransitionProps } from '@material-ui/core/transitions';
// import Slide from '@material-ui/core/Slide';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface Props {
    open:boolean,
    className?: string;
    message?: string;
    onClose?: () => void;
    variant: keyof typeof variantIcon;
    vertical?: 'top'|'bottom',
    horizontal?:'left'|'right'|'center',
    transition:"left"|"up"|"right"|"down"
  }

export interface ContentProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
}

const MySnackbarContentWrapper=(props: ContentProps)=> {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}
// function TransitionLeft(props: TransitionProps) {
//   return <Slide {...props} direction="left" />;
// }

// function TransitionUp(props: TransitionProps) {
//   return <Slide {...props} direction="up" />;
// }

// function TransitionRight(props: TransitionProps) {
//   return <Slide {...props} direction="right" />;
// }

// function TransitionDown(props: TransitionProps) {
//   return <Slide {...props} direction="down" />;
// }

export default (props:Props)=> {
  const {open,onClose,message,vertical,horizontal, variant,className} = props
  
  // const renderTransitionComponent = ()=>{
  //   switch (transition) {
  //     case "up":
  //       return TransitionUp
  //       case "left":
  //         return TransitionLeft
  //         case "right":
  //           return TransitionRight
  //     case "down":
  //       return TransitionDown
  //     default:
  //       break;
  //   }
  // }
  

  // TransitionComponent={renderTransitionComponent()}
  // message={message}
  return <Snackbar 
  anchorOrigin={{
    vertical: vertical || 'bottom',
    horizontal: horizontal || 'left'
  }}
    open={open}
    autoHideDuration={3000}
    onClose={onClose}
  > 
    <MySnackbarContentWrapper
    onClose={onClose}
    variant={variant}
    message={message}
    className={className}
  />
  </Snackbar>
}