import React from 'react';
import classNames from "classnames"
import { makeStyles,createStyles,Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuOff from "@material-ui/icons/FormatIndentDecrease"
import MenuOn from "@material-ui/icons/FormatIndentIncrease"
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';

import { withRouter } from "react-router-dom";
import Icon from '../components/Icon'


// 语种组件
// import LocaleView from '../components/app/locale'
// 帐户逻辑
import { useAccount,useSysState} from '../provides/sys'
// 自动补全
// import { AutoComplete } from '../components/ui/input'
import { useLocale } from '../config/Locale'

import Client from "../pages/chat/Client"
import {NotifyPayload,AskData} from   "../pages/chat/types"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: theme.spacing(1),
      boxShadow: "0 5px 20px -6px rgba(225, 225, 225, 0.52), 0 2px 15px 0px rgba(225, 225, 225, 0.22), 0 4px 5px -2px rgba(225, 225, 225, 0.3)",
    },
    sitename: {
      fontSize:18,
      display: 'none',
      paddingRight: '10px',
      color: '#333',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      },
    },
    grow: {
      flexGrow: 1,
    },
    image: {
      // backgroundImage: `url(${require("../assets/images/login-bg.jpg")})`,
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "cover",
      // backgroundPosition: "center",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor:"#0066CC",
      // boxShadow:"none",
      boxShadow: "0 5px 20px -6px rgba(100, 100, 100, 0.42), 0 2px 15px 0px rgba(100, 100, 100, 0.12), 0 4px 5px -2px rgba(100, 100, 100, 0.2)",
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }, 
    appBarShift: {
      // width: `calc(100% - ${sideBarWidth}px)`,
      // transition: theme.transitions.create(['width', 'margin'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },
    menuButton: {
    },
    button:{
      color:"#333"
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    icon: {
      margin: '0 10px 0 0',
      color:"#333",
      "& path":{
        fill:"#333",
      },
    },
    sectionDesktop: {
      display: 'none',
      alignItems:"center",
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    yellow:{
      color:"#ff6600",
    },
    green:{
      color:"green",
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    fab: {
      marginRight: theme.spacing(1),
      padding: theme.spacing(0),
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    fabIcon: {
      margin: 0,
      padding: 0
    }
  }));

const Header = (props:any) => {
  const { translate } = useLocale()
  const {
    toggleSidebarOpen,
    sidebarIsOpen,
    // history,
    onChat
  } = props
  const classes = useStyles();

  const { account } = useSysState()
  const { logout } = useAccount();

  const [unreadTotal,setUnreadTotal] = React.useState<number>(0)
  const client = Client()
  const {onConnect,onDisconnect,disconnect,registerNotifyRequest,onNotifyReceived} = client
  
  React.useEffect(() => {
    onConnect(()=> {
      console.log('已连接')
      if(account){
        registerNotifyRequest({
          token:account.token,
          userId:account.user.id,
        },(askData:AskData)=>{
          if(askData.type === 'success'){
            if(askData.result && askData.result.unreadTotal)
               setUnreadTotal(askData.result.unreadTotal)
          }
        })
        onNotifyReceived((payload:NotifyPayload)=>{
          const {unreadTotal} = payload
          setUnreadTotal(unreadTotal)
        })
      }
    });
    onDisconnect(()=> {
      console.log('已断开连接')
    });
    return () => {
     disconnect()
    }
  }, [])  


  return (<>
    <Box className={classes.grow}>
      {/* <AppBar
        className={classNames(classes.appBar,classes.image)}> */}
        <Toolbar className={classes.toolbar}>
          <Box>
            <IconButton  onClick={toggleSidebarOpen}>
           {sidebarIsOpen ?  <MenuOff /> :<MenuOn/>}
          </IconButton>
          </Box>
          <Box className="layout-header__inner__left">
            <Box className="app-logo">
              <img width={32} height={32} src={require('../assets/images/logo512.png')} />
            </Box>
          </Box> 
          <Box className={classes.sitename}>商务综合平台管理系统</Box>
          <Box className={classes.grow} />

          <Box className={classNames(classes.sectionDesktop,classes.green)}>
            <IconButton aria-label="show chat new notifications" color="inherit"
            onClick={onChat}>
              <Badge badgeContent={unreadTotal} color="error">
                <Tooltip title={translate('notify')}>
                  <NotificationsIcon />
                </Tooltip>
              </Badge>
            </IconButton>
          </Box>

          <Box className={classes.sectionDesktop}>
            
            <Button onClick={()=>{}} className={classes.button}>
              <Icon name="AccountSetting" className={classes.icon} width={18} height={18}  />
              {/* {translate('profile')} */}
               {account ? account.user.username :"未登录"}
            </Button>
          </Box>
          <Box className={classes.sectionDesktop}>
              <Button onClick={() => {
                logout();
                window.location.reload(true)
              }} 
              className={classes.button}>
                <Icon name="PowerOff" className={classes.icon} width={18} height={18} color="red" />
                {translate('logout')}
              </Button>
          </Box>
          
        </Toolbar>
      {/* </AppBar> */}
      {/* <Toolbar></Toolbar> */}
    </Box>
    </>
  );
}
export default withRouter(Header)