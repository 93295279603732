import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
})=>(<svg className={className}  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" 
p-id="5093"  width={width} height={height}>
<path fill={color} d="M187.733333 145.066667c-25.6 0-42.666667 17.066667-42.666666 42.666666v648.533334c0 25.6 17.066667 42.666667 42.666666 42.666666h648.533334c25.6 0 42.666667-17.066667 42.666666-42.666666V187.733333c0-25.6-17.066667-42.666667-42.666666-42.666666H187.733333z m0-59.733334h648.533334c59.733333 0 102.4 42.666667 102.4 102.4v648.533334c0 59.733333-42.666667 102.4-102.4 102.4H187.733333C128 938.666667 85.333333 896 85.333333 836.266667V187.733333C85.333333 128 128 85.333333 187.733333 85.333333z" p-id="5094"></path>
<path fill={color} d="M486.4 298.666667h51.2v426.666666h-51.2z"  p-id="5095"></path>
<path fill={color} d="M725.333333 486.4v51.2h-426.666666v-51.2z" p-id="5096"></path></svg>
)