import React, { useState, useEffect } from 'react'
import { createId } from '../../../lib/util'

import Box from '@material-ui/core/Box';
import Icon from '../../../components/Icon'

import { withRouter } from "react-router-dom";
// 逻辑
import { useCat } from '../../../provides/shopping'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, ConfirmDialog } from '../../../components/ui/dialog'
// 分类树
import DataTreeView from '../../../components/ui/tree/DataTree'
// 工具栏
import PageToolbar from '../../../components/ui/PageToolbar'

import { useLocale } from '../../../config/Locale'
import { useListPageStyles } from '../../../assets/jss/pages'

const CatPage = (props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { getList, remove } = useCat()
  const classes = useListPageStyles()

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  const [checked, setChecked] = useState<any>({})

  // 原始列表
  const [originList, setOriginList] = useState<any>([])
  // 最终显示列表
  const [list, setList] = useState<any>([])
  // 关键字
  const [keyword, setKeyword] = useState<string>("")
  // 过滤
  const search = (params?: any) => {
    let k = params && params.keyword
    let list = (params && params.list) || originList
    k = k ? k.trim().toLowerCase() : ""
    if (k) {
      setList(list.filter((item: any) => item.id.toLowerCase().indexOf(k) !== -1 ||
        (item.name && item.name.indexOf(k) !== -1) ||
        (item.alias && item.alias.indexOf(k) !== -1) ||
        (item.address && item.address.indexOf(k) !== -1)))
    } else {
      setList(list)
    }
  }

  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载分类列表数据...'
    })
    getList({
      success: (list: any) => {
        // 关闭加载条
        setOriginList(list)
        search({ list })
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setList([])
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadData()
    return () => {
    };
  }, []);

  // 警示框
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState({
    children: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    setConfirmOpen(true)
    setConfirmProps({
      children: <Box className={classes.alert}>
        <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
        <Box>
          {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
          <Box>
            <Box>{translate('shopping.removeCatTips')}</Box>
            <Box>{translate('shopping.removeAsk')}</Box>
          </Box>
        </Box>
      </Box>,
      title: '',
      ok: () => {
        setConfirmOpen(false)
        setLoading({
          open: true,
          text: translate('shopping.removingCat')//'正在删除商品...'
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadData()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setConfirmOpen(false)
      }
    })
  }


  const renderPageToolbar = (
    <PageToolbar
      {...{
        keyword,
        onFilter: (keyword: string) => {
          setKeyword(keyword)
          search({ keyword })
        },
        title: translate('shopping.productCat'),
        label: {
          add: translate('appendCat')
        },
        onAddClick: () => {
          // 跳转添加页面
          const location = {
            pathname: "/shopping/cat/edit",
            search: `?id=${createId()}`,
            state: {
              fromList: true
            }
          }
          history.push(location)
          history.replace(location)
        },
        onDelClick: () => {
          // 批量删除
          if (checked)
            handleDelete(Object.keys(checked))
        },
        onRefresh: () => {
          // 刷新数据
          loadData()
        },
      }} />)

  const renderPageContent = (<Box className={classes.pageContent}>
    <DataTreeView
      items={list}
      edit={true} 
      showCheckbox={true}
      showDescri={true}
      onEdit={(item: any) => {
        history.replace(`/shopping/cat/edit?id=${item.id}`)
      }}
      onAppend={(item: any) => {
        history.replace(`/shopping/cat/edit?parentId=${item.id}&id=${createId()}`)
      }}
      onRemove={(item: any) => {
        handleDelete([item.id])
      }}
      onItemChecked={(checked: any) => {
        setChecked(checked)
      }}
    />
  </Box>)


  return (<Box className={classes.root}>

    {renderPageToolbar}

    <LoadingBox {...loading} />

    {list.length === 0 && !loading.open && (<Box className="error">
      <Icon name="Error" width={32} height={32} color="gray" />
      <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
    </Box>)}

    {renderPageContent}

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 确认框 */}
    <ConfirmDialog open={confirmOpen} {...confirmProps} />
  </Box>
  )
}
export default withRouter(CatPage)