import React from "react"

import { useUIInputsStyles } from "../../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import IconUp from "@material-ui/icons/ArrowUpward"
import IconDown from "@material-ui/icons/ArrowDownward"

type Props = {
    editing?: boolean,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave?: () => void,
    onCancel?: () => void,
    onEditing?: () => void,
    onRemove?: () => void
}
/** 标签页输入 */
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const { editing, showUp, showDown, sortUp, sortDown, onSave, onCancel, onEditing, onRemove } = props

    return <Box className={classes.itemToolbar}>
        {editing ? <>
            {onSave && <Button size="small" color="primary"  onClick={()=>onSave()}>
                    <IconSave className={classes.icon} />保存
            </Button>}
            {onCancel && <Button size="small" color="secondary"  onClick={()=>onCancel()}>
                    <IconCancel className={classes.icon} />取消
            </Button>}
        </> : <>
                {onEditing && <Button size="small" color="primary"  onClick={()=>onEditing()}>
                        <IconEdit className={classes.icon} />
                        修改
                </Button>}
                {onRemove && <Button size="small" color="secondary"  onClick={()=>onRemove()}>
                        <IconDelete className={classes.icon} />删除
                </Button>}
            </>}
        {showUp &&
            <Button size="small"  onClick={sortUp}>
                    <IconUp className={classes.icon} />往前排
            </Button>}
        {showDown &&
            <Button size="small"  onClick={sortDown}>
                    <IconDown className={classes.icon} />往后排
            </Button>}
    </Box>
}