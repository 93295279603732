
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useProductStyles = makeStyles((theme: Theme) => createStyles({
  grow:{
    flexGrow:1
  },
  section: {
    width:"100%",
    position:"relative",
    display:"flex",
    background:"#fff",
    flexDirection:"column",
    margin:theme.spacing(1,0),
    "@media (max-width:650px)": {
      background:"#f3f3f3",
    },
  },
  header:{
    display:"flex",
    background:"#fff",
    "@media (max-width:650px)": {
    flexDirection:"column",
    },
  },
  images:{
    display:"flex",
    flexDirection:"column",
    border:"1px solid #dedede",
    position:"relative",
    "@media (max-width:650px)": {
      width: "100%",
      height:"280px",
      border:0,
      marginBottom:theme.spacing(1)
    },
    "@media (min-width:680px)": {
      width: 360,
    },
    "@media (min-width:960px)": {
      width: 360,
    },
    "@media (min-width:1200px)": {
      width: 360
    },
  },
  imageBox:{
    position:"relative",
    alignItems:"center",
    justifyContent:"center",
    padding:8,
    "@media (max-width:650px)": {
      display:'none'
    },
    "@media (min-width:680px)": {
      display:"flex",
      height:360,
    }
  },
  image:{
    position:"relative",
    maxWidth:"100%",
    maxHeight:"100%",
    '& > div > img':{
      maxHeight:340,
      maxWidth:340,
      width:'auto !important'
    }
  }, 
  imageGallery:{
    clear:"both",
    background:"#fff",
    overflow:"hidden",
  },
  carousel:{
      display: "block",
      position:"relative",
      overflow: "hidden"
  },
  carouselItem:{
      position:"relative",
      overflow:"hidden",
  },
  carouselItemImage:{
      height:280,
      position:"relative",
      backgroundRepeat:'no-repeat',
      backgroundPosition: "50%",
      backgroundSize: "auto 100%",
      overflow:"hidden",
  },
  info:{
    flex:1,
    paddingLeft:theme.spacing(1),
  },
  infoTitle:{
    fontSize:"1.2em"
  },
  tabs:{
    borderBottom:"1px solid #f0f0f0"
  },
  tabContents:{
    flex:1,
    overflow:"hidden",
    width:"100%",
  },
  tabPanel:{
  },
  detail:{
    overflow:"hidden",
    '& img':{
      maxWidth:"100%"
    }
  },
  priceContainer: {
    display: "flex",
    alignItems:"flex-end",
    padding:theme.spacing(1)
  },
  oprice: {
    color: "#999",
    fontStyle: "italic",
    fontFamily: "Verdana,Arial",
    fontWeight:400,
    textDecoration: "line-through",
    fontSize: "1em",
    marginLeft: 0,
    padding:"0 0 4px 4px"
  },
  price: {
    color: "#ff6600",
    fontFamily: "impact",
    fontSize: "1.8em",
  },
  mobileHeader:{
    position:"fixed",
    top:0,
    left:0,
    width:"100%",
    display:"flex",
    alignItems:"center",
    zIndex:2000,
    minHeight:50,
    // ...boxShadow
  },
  mobileHeaderRight:{
    display:"flex",
    alignItems:"center",
    justifyContent:"flex-end"
  },
  mobileTitle:{
    flexGrow:1,
    textAlign:"center",
    overflow: "hidden",
    textOverflow: 'ellipsis',
    whiteSpace:"nowrap"
  },
  mobileFooter:{
    position:"fixed",
    bottom:0,
    left:0,
    width:"100%",
    display:"flex",
    background:"#fefefe",
    borderTop:"1px solid #efefef",
    padding:0,
    margin:0 
  },
  content:{
    background: '#fff',
    display:"flex",
    flexDirection:"column",
    margin:theme.spacing(1,0)
  },
  contentTitle:{
    fontWeight:600,
    padding:theme.spacing(1),
    borderBottom:"1px solid #efefef",
    alignItems:"center"
  },
  footerTools:{
    display:"flex",
    flexGrow:1,
    alignItems:"center",
    padding:theme.spacing(0,1)
  },
 button:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    color:"#666",
    flex:1,
  },
    link: {
      color: "inherit",
      position: "relative",
      padding: theme.spacing(1),
      whiteSpace: "nowrap",
      fontWeight: 400,
      fontSize: ".9em",
      textTransform: "uppercase",
      borderRadius: "3px",
      margin: "0px",
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      cursor:"pointer",
      "&:hover,&:focus": {
        color: "#ff6600",
        backgroundColor: "rgba(255,255, 255, 0.8)",
        "& svg": {
          color: "#ff6600",
        },
        "& path": {
          fill: "#ff6600",
        },
      },
      "& path": {
        fill: "inherit",
      },
    },
    icon: {
      width: "22px",
      height: "22px",
      marginRight: 5,
    },
}));
