import { makeStyles, createStyles, fade,Theme } from '@material-ui/core/styles';

export const useSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow:{
      flexGrow:1
    },
    search: {
      position: 'relative',
      display: 'flex',
      flexGrow:1,
      padding: 0,
      margin: theme.spacing(1, 2),
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover;&:focus': {
        backgroundColor: fade(theme.palette.common.white, 0.3),
        '& input':{
          borderTop: '2px solid #dedede',
          borderBottom: '2px solid #dedede',
          borderRight: '2px solid #dedede',
          color:"#333"
        },
        '& span':{
          borderTop: '2px solid #dedede',
          borderLeft: '2px solid #dedede',
          borderBottom: '2px solid #dedede',
        }
      },
      "@media (max-width: 600px)": {
          margin: theme.spacing(1),
      },
    },
    searchIcon: {
      minWidth: theme.spacing(2),
      width: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#aaa',
      borderTopRightRadius: 30,
      borderBottomRightRadius: 30,
      borderTop: '2px solid #efefef',
      borderRight: '2px solid #efefef',
      borderBottom: '2px solid #efefef',
      borderLeft: 0,
      padding:0,
      '&:hover':{
        background:"transparent",
      }
    },
    searchButton:{
      minWidth: theme.spacing(7),
      width: theme.spacing(7),
      color: '#fff',
      background:"#ff6600",
      borderTop: '1px solid #ff6600',
      borderRight: '1px solid #ff6600',
      borderBottom: '1px solid #ff6600',
      cursor:"pointer",
      '&:hover':{
        background:"#ff0000",
      }
    },
    inputRoot: {
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      color: 'inherit',
      width: '100%',
      margin: 0,
      borderTop: '2px solid #efefef',
      borderBottom: '2px solid #efefef',
      borderLeft: '2px solid #efefef',
      borderRight: 0,
      paddingLeft:8,
    },
    inputSearch:{
      borderTop: '2px solid #ff6600',
      borderBottom: '2px solid #ff6600',
      borderLeft: '2px solid #ff6600',
    },
    inputInput: {
      margin: 0,
      padding: "6px 12px",
      transition: theme.transitions.create('width')
    }
  }))
