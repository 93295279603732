import React from 'react'
export default ({
    width = 16, height = 16, className = "icon", color = "gray"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="3855" width={width} height={height}>
    <path fill={color} d="M512.217964 598.623893a74.459852 74.459852 0 0 1-30.01971-6.309706l-375.410112-165.42461c-25.221428-11.116175-41.127699-34.512025-41.490973-61.057611-0.363274-26.489304 14.8318-50.256614 39.665394-62.018496l375.410112-177.881316c19.938098-9.435906 43.73713-9.452279 63.706951 0.016373l374.989533 177.87313c24.817222 11.778254 40.005133 35.529191 39.633672 62.003146-0.355087 26.538423-16.254196 49.933249-41.45925 61.057611L542.270421 592.307024a74.619488 74.619488 0 0 1-30.052457 6.316869z m0-413.637762c-1.227967 0-2.439562 0.25071-3.505847 0.759294l-375.410112 177.87313c-1.356904 0.638543-1.793856 1.203408-1.841951 1.325181 0.032746 0.064468 0.452301 0.703011 2.020007 1.38965l375.410111 165.42461c2.003634 0.88823 4.6366 0.896416 6.67298-0.008186l374.957811-165.416424c1.567705-0.686638 1.987261-1.325182 2.020006-1.421372-0.048095-0.080841-0.485047-0.653893-1.841951-1.292436L515.707438 185.746448c-1.066285-0.509606-2.27788-0.760317-3.489474-0.760317z" p-id="3856"></path>
    <path d="M512.008186 905.148102a33.131585 33.131585 0 0 1-14.298657-3.247974L246.029416 781.368984c-23.315008-11.164271-38.372959-35.085076-38.372959-60.928675v-262.754332c0-18.273179 14.81645-33.089629 33.089629-33.089629s33.089629 14.81645 33.089629 33.089629v262.754332L512.056282 835.349409l237.31494-113.664759 0.792039-263.998673c0-18.273179 14.81645-33.089629 33.08963-33.089629s33.089629 14.81645 33.089629 33.089629v262.754332c0 25.859971-15.074324 49.779753-38.389332 60.928675L526.306844 901.900128a33.131585 33.131585 0 0 1-14.298658 3.247974z" 
    fill={color} p-id="3857"></path>
    <path d="M918.246421 716.083072c-23.254633 9.68764-48.88129-7.397481-48.881291-32.589233V491.569692l79.428004-33.089629v211.784474a49.635467 49.635467 0 0 1-30.546713 45.818535z" 
    fill={color} p-id="3858"></path></svg>
    )