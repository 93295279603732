import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as shoppingTypes from '../../../types/shopping'


// Action Types
export type Action = {
    // 商品列表
    type:'SHOPPING_PRODUCT_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type:'SHOPPING_PRODUCT_FETCH_LIST_SUCCESS', payload: { list: shoppingTypes.Product[] ,pagination:types.Pagination} }
    | { type:'SHOPPING_PRODUCT_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 上架商品列表
        type:'SHOPPING_PRODUCT_FETCH_ACTIVE_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type:'SHOPPING_PRODUCT_FETCH_ACTIVE_LIST_SUCCESS', payload: { list: shoppingTypes.Product[] ,pagination:types.Pagination } }
    | { type:'SHOPPING_PRODUCT_FETCH_ACTIVE_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 商品
        type:'SHOPPING_PRODUCT_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type:'SHOPPING_PRODUCT_FETCH_SUCCESS', payload: any }
    | { type:'SHOPPING_PRODUCT_FETCH_FAILURE', payload: { error: types.Error } }
    | {
        // 保存商品
        type:'SHOPPING_PRODUCT_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 删除商品
        type:'SHOPPING_PRODUCT_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 设置状态
        type:'SHOPPING_PRODUCT_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };




// 商品列表
export const FETCH_LIST = 'SHOPPING_PRODUCT_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'SHOPPING_PRODUCT_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'SHOPPING_PRODUCT_FETCH_LIST_FAILURE';
export const fetchList = (params: any): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/getList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchListSucess = (list: shoppingTypes.Product[], pagination:types.Pagination): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});


// 上架商品列表
export const FETCH_ACTIVE_LIST = 'SHOPPING_PRODUCT_FETCH_ACTIVE_LIST'
export const FETCH_ACTIVE_LIST_SUCCESS = 'SHOPPING_PRODUCT_FETCH_ACTIVE_LIST_SUCCESS';
export const FETCH_ACTIVE_LIST_FAILURE = 'SHOPPING_PRODUCT_FETCH_ACTIVE_LIST_FAILURE';
export const fetchActiveList = (params: any): Action => ({
    type: FETCH_ACTIVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/getActiveList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchActiveListSucess = (list: shoppingTypes.Product[],pagination:types.Pagination): Action => ({
    type: FETCH_ACTIVE_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchActiveListFailure = (error: types.Error): Action => ({
    type: FETCH_ACTIVE_LIST_FAILURE,
    payload: {
        error
    }
});



// 商品
export const FETCH = 'SHOPPING_PRODUCT_FETCH'
export const FETCH_SUCCESS = 'SHOPPING_PRODUCT_FETCH_SUCCESS';
export const FETCH_FAILURE = 'SHOPPING_PRODUCT_FETCH_FAILURE';
export const fetch = (params: any): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/get`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchSucess = (Product: shoppingTypes.Product): Action => ({
    type: FETCH_SUCCESS,
    payload: {
        Product
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});


// 保存
export const SAVE = 'SHOPPING_PRODUCT_SAVE'
export const save = (data: shoppingTypes.Product): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/save`,
            method: 'POST',
            data: {
                ...data
            }
        }
    }
})



// 删除
export const REMOVE = 'SHOPPING_PRODUCT_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})



// 设置状态
export const SET_STATUS = 'SHOPPING_PRODUCT_SET_STATUS'
export const setStatus = (data: any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/setStatus`,
            method: 'POST',
            data
        }
    }
})