
import { EditorState, Modifier, convertToRaw, DefaultDraftInlineStyle } from 'draft-js';
import { Map } from 'immutable';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';

import {
  getSelectedBlocksList
} from '../utils/block';

const DEFAULT_PREFIX = 'CUSTOM_';

// see https://github.com/webdeveloperpr/draft-js-custom-styles
// This functionality has been taken from draft-js and modified for re-usability purposes.
// Maps over the selected characters, and applies a function to each character.
// Characters are of type CharacterMetadata.
export const mapSelectedCharacters = (callback: any) => (editorState: any) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const blockMap = contentState.getBlockMap();
  const startKey = selectionState.getStartKey();
  const startOffset = selectionState.getStartOffset();
  const endKey = selectionState.getEndKey();
  const endOffset = selectionState.getEndOffset();

  const newBlocks = blockMap.skipUntil((_: any, k: any) => {
    return k === startKey;
  }).takeUntil(((_: any, k: any) => {
    return k === endKey;
  })).concat(Map([[endKey, blockMap.get(endKey)]])).map((block: any, blockKey: any) => {
    let sliceStart;
    let sliceEnd;

    // sliceStart -> where the selection starts
    // endSlice -> Where the selection ends

    // Only 1 block selected
    if (startKey === endKey) {
      sliceStart = startOffset;
      sliceEnd = endOffset;
      // Gets the selected characters of the block when multiple blocks are selected.
    } else {
      sliceStart = blockKey === startKey ? startOffset : 0;
      sliceEnd = blockKey === endKey ? endOffset : block.getLength();
    }

    // Get the characters of the current block
    let chars = block.getCharacterList();
    let current;
    while (sliceStart < sliceEnd) {
      current = chars.get(sliceStart);
      const newChar = callback(current);
      chars = chars.set(sliceStart, newChar);
      sliceStart++;
    }

    return block.set('characterList', chars);
  });

  return contentState.merge({
    blockMap: blockMap.merge(newBlocks),
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });
};

const getContentStateWithoutStyle = (prefix: string, editorState: EditorState) => {
  return mapSelectedCharacters(filterDynamicStyle(prefix))(editorState);
};

const filterDynamicStyle = (prefix: string) => (char: any) => {
  const charStyles = char.get('style');
  const filteredStyles = charStyles.filter((style: string) => !style.startsWith(prefix));
  return char.set('style', filteredStyles);
};

const addStyle = (prefix: string) => (editorState: any, value: string) => {
  const style = prefix + value;
  const newContentState = Modifier.applyInlineStyle(
    getContentStateWithoutStyle(prefix, editorState),
    editorState.getSelection(),
    style
  );

  const isCollapsed = editorState.getSelection().isCollapsed();
  if (isCollapsed) {
    return addInlineStyleOverride(prefix, style, editorState);
  }

  return EditorState.push(editorState, newContentState, 'change-inline-style');
};

const removeStyle = (prefix: string) => (editorState: EditorState) => {
  return EditorState.push(editorState, getContentStateWithoutStyle(prefix, editorState), 'change-inline-style');
};

const filterOverrideStyles = (prefix: string, styles: any) => styles.filter(
  (style: string) => !style.startsWith(prefix));

const addInlineStyleOverride = (prefix: string, style: string, editorState: EditorState) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  // We remove styles with the prefix from the OrderedSet to avoid having
  // variants of the same prefix.
  const newStyles = filterOverrideStyles(prefix, currentStyle);

  return EditorState.setInlineStyleOverride(editorState, newStyles.add(style));
};

const toggleInlineStyleOverride = (prefix: string, style: string, editorState: EditorState) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  // We remove styles with the prefix from the OrderedSet to avoid having
  // variants of the same prefix.
  const newStyles = filterOverrideStyles(prefix, currentStyle);

  const styleOverride = currentStyle.has(style)
    ? newStyles.remove(style)
    : newStyles.add(style);

  return EditorState.setInlineStyleOverride(editorState, styleOverride);
};

const toggleStyle = (prefix: string) => (editorState: EditorState, value: string) => {
  const style = prefix + value;
  const currentStyle = editorState.getCurrentInlineStyle();
  const isCollapsed = editorState.getSelection().isCollapsed();

  if (isCollapsed) {
    return toggleInlineStyleOverride(prefix, style, editorState);
  }

  if (!currentStyle.has(style)) {
    return addStyle(prefix)(editorState, value);
  }

  const editorStateWithoutCustomStyles = EditorState.push(editorState, getContentStateWithoutStyle(prefix, editorState), 'change-inline-style');
  return EditorState.forceSelection(editorStateWithoutCustomStyles, editorState.getSelection());
};

/**
 *  style is an OrderedSet type
 */
const styleFn = (prefix: string, cssProp: any) => (style: any) => {
  if (!style.size) {
    return {};
  }
  const value = style.filter((val: string) => val.startsWith(prefix)).first();
  if (value) {
    const newVal = value.replace(prefix, '');
    return { [camelCase(cssProp)]: newVal };
  }
  return {};
};

const currentStyle = (prefix: string) => (editorState: EditorState) => {
  const selectionStyles = editorState.getCurrentInlineStyle();
  if (!selectionStyles.size) {
    return '';
  }

  const result = selectionStyles.filter((style: any) => style.startsWith(prefix)).first();

  return result ? result.replace(prefix, '') : result;
};

export const createCustomStyles = (prefix: string, conf: any) => {
  return conf.reduce((acc: any, prop: any) => {
    const camelCased = camelCase(prop);
    const newPrefix = `${prefix}${snakeCase(prop).toUpperCase()}_`;
    const copy = { ...acc };
    copy[camelCased] = {
      add: addStyle(newPrefix),
      remove: removeStyle(newPrefix),
      toggle: toggleStyle(newPrefix),
      current: currentStyle(newPrefix),
      styleFn: styleFn(newPrefix, prop),
    };

    return copy;
  }, {});
};

// customStyleFns
export const customStyleFns = (fnList: any) => (prefixedStyle: any) => {
  return fnList.reduce((css: any, fn: Function) => {
    return { ...css, ...fn(prefixedStyle) };
  }, {});
};

// exporter
export const getInlineStyles = (acc: any, block: any) => {
  const styleRanges = block.inlineStyleRanges;
  if (styleRanges && styleRanges.length) {
    const result = styleRanges.map((style: any) => style.style);

    return acc.concat(result);
  }
  return acc;
};

export const createInlineStyleExportObject = (prefix: string, customStyleMap: any) => (acc: any, style: any) => {
  // default inline styles
  // if (DefaultDraftInlineStyle.get(style,{})) {
  //   return Object.assign({}, acc, {
  //     [style]: {
  //       style: DefaultDraftInlineStyle.get(style,{}),
  //     },
  //   });
  // }

  // custom styleMap styles
  if (customStyleMap[style]) {
    return Object.assign({}, acc, {
      [style]: {
        style: customStyleMap[style],
      },
    });
  }

  const regex = new RegExp(`${prefix}(.+)_(.+)`);
  const match = style.match(regex);

  // no matches
  if (!match || !match[1] || !match[2]) {
    return acc;
  }

  // custom styles
  const css = match[1].toLowerCase();
  const value = match[2];
  const inlineStyle = {
    [style]: {
      style: {
        [camelCase(css)]: value,
      },
    },
  };

  return Object.assign({}, acc, inlineStyle);
};

/**
 * create css for convertToHtml
 * @param prefix 
 * @param customStyleMap 
 */
export const createCssProperies = (prefix: string, customStyleMap: any) => (acc: any, style: any) => {
  // custom styleMap styles
  if (customStyleMap[style]) {
    return Object.assign({}, acc, {
      [style]: {
        style: customStyleMap[style],
      },
    });
  }

  const regex = new RegExp(`${prefix}(.+)_(.+)`);
  const match = style.match(regex);

  // no matches
  if (!match || !match[1] || !match[2]) {
    return acc;
  }

  // custom styles
  const css = match[1].toLowerCase();
  const value = match[2];
  const inlineStyle = {
    [style]: {
      style: {
        [camelCase(css)]: value,
      },
    },
  };
  return Object.assign({}, acc, inlineStyle);
};
export const inlineStyleExporter = (prefix: string, customStyleMap: any) => (editorState: EditorState) => {
  const inlineStyles = convertToRaw(editorState.getCurrentContent()).blocks.reduce(getInlineStyles, []);
  if (!inlineStyles.length) return {};
  return inlineStyles.reduce(createInlineStyleExportObject(prefix, customStyleMap), {});
};

/**
* Function returns an object of inline styles currently applicable.
* Following rules are applicable:
* - styles are all false if editor is not focused
* - if focus is at beginning of the block and selection is collapsed
*     styles of first character in block is returned.
* - if focus id anywhere inside the block and selection is collapsed
*     style of a character before focus is returned.
*/
export function getSelectionInlineStyle(editorState: EditorState): Object {
  const currentSelection = editorState.getSelection();
  if (currentSelection.isCollapsed()) {
    const inlineStyles:any = {};
    const styleList = editorState.getCurrentInlineStyle().toList().toJS();
    styleList.forEach((s:string)=>{
      inlineStyles[s] = true
    })
    return inlineStyles;
  }
  const start = currentSelection.getStartOffset();
  const end = currentSelection.getEndOffset();
  const selectedBlocks = getSelectedBlocksList(editorState);
  if (selectedBlocks.size > 0) {
    const inlineStyles :any = {
      BOLD: true,
      ITALIC: true,
      UNDERLINE: true,
      STRIKETHROUGH: true,
      CODE: true,
      SUPERSCRIPT: true,
      SUBSCRIPT: true,
    };
    for (let i = 0; i < selectedBlocks.size; i += 1) {
      let blockStart = i === 0 ? start : 0;
      let blockEnd =
        i === (selectedBlocks.size - 1) ? end : selectedBlocks.get(i).getText().length;
      if (blockStart === blockEnd && blockStart === 0) {
        blockStart = 1;
        blockEnd = 2;
      } else if (blockStart === blockEnd) {
        blockStart -= 1;
      }
      for (let j = blockStart; j < blockEnd; j += 1) {
        const inlineStylesAtOffset = selectedBlocks.get(i).getInlineStyleAt(j);
        Object.keys(inlineStylesAtOffset).forEach((key:string)=>{
          inlineStyles[key] = inlineStyles[key] &&inlineStylesAtOffset.get(key)
        })
      }
    }
    return inlineStyles;
  }
  return {};
}

export const findCurrentStyle = (prefix: string) => (editorState: EditorState, styleName: string) => {
  const style = `${prefix}${snakeCase(styleName).toUpperCase()}_`;
  const inlineStyles = getSelectionInlineStyle(editorState)
  let result
  Object.keys(inlineStyles).forEach((key:string)=>{
      if(key.startsWith(style)){
        result = key.substring(style.length,key.length)
      }
  })
  return result || ''
};

export const validatePrefix = (prefix: string) => {
  if (typeof prefix !== 'string' || !prefix.length) {
    return DEFAULT_PREFIX;
  }

  if (prefix.match(/.+_$/)) {
    return prefix;
  }

  return `${prefix}_`;
};

export default (conf: any, prefix = DEFAULT_PREFIX, customStyleMap = {}) => {
  if (!conf) {
    console.log('Expecting an array with css properties');
    return { styles: {} };
  }

  if (!Array.isArray(conf) || !conf.length) {
    console.log('createStyles expects first parameter to be an array with css properties');
    return { styles: {} };
  }

  const checkedPrefix = (validatePrefix(prefix));
  const styles = createCustomStyles(checkedPrefix, conf);
  const fnList = Object.keys(styles).map(style => styles[style].styleFn);
  const customStyleFn = customStyleFns(fnList);
  const exporter = inlineStyleExporter(checkedPrefix, customStyleMap);
  const cssProperies = createCssProperies(checkedPrefix, customStyleMap)
  const getCurrentStyle = findCurrentStyle(checkedPrefix);

  return {
    getSelectionInlineStyle,
    styles,
    customStyleFn,
    exporter,
    cssProperies,
    getCurrentStyle
  };
};