
import * as actions from '../../store/actions/shopping/spec'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    getListByCat: Function,
    get: Function,
    save: Function,
    saveList:Function,
    remove: Function,
    setStatus: Function,
    checkName: Function,
}

/**
 * 系统配置逻辑
 */
export const useSpec = (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const { params,success, failure } = props
        dispatch(actions.fetchList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                const pagination = data.result.pagination
                // 通知Redux
                dispatch(actions.fetchListSucess(list,pagination))
                // 成功返回
                if (success) {
                    success(list,pagination)
                }
            } else {
                // 失败
                const error = {
                    code: data && data.code || 0,
                    message: message || '请求失败'
                }
                dispatch(actions.fetchListFailure(error))
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取列表
    const getListByCat= (props: any) => {
        const { params,success, failure } = props
        const {catId} = params
        dispatch(actions.fetchListByCat(catId)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                // 通知Redux
                dispatch(actions.fetchListByCatSucess(list))
                // 成功返回
                if (success) {
                    success(list)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }
    // 获取
    const get = (props: any) => {
        const { params,success, failure } = props
        dispatch(actions.fetch(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const spec = data.result.spec
                // 成功返回
                if (success) {
                    success(spec)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }


    // 保存列表
    const saveList = (props: any) => {
        const {data ,success, failure } = props
        const {specs,catId,status } = data
        dispatch(actions.saveList(specs,catId,status)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }


    // 保存
    const save = (props: any) => {
        const { data,success, failure } = props
        const {spec,values} = data
        dispatch(actions.save(spec,values)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }


    // 保存
    const checkName = (props: any) => {
        const { data ,success, failure } = props
        const {name,catId,id} = data
        dispatch(actions.checkName(name,catId,id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    // 删除
    const remove = (props: any) => {
        const { id,success, failure } = props
        dispatch(actions.remove(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    // 设置状态
    const setStatus = (props: any) => {
        const {data, success, failure } = props
        dispatch(actions.setStatus(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    return {
        getList,
        getListByCat,
        get,
        save,
        saveList,
        remove,
        setStatus,
        checkName,
    }
}