import React from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import IconSearch from '@material-ui/icons/Search';

import {attachPrefix} from "../../config"
import { useChatStyles } from '../../assets/jss/components'

import {ChatUser,AskData } from "./types"
import { useSysState } from '../../provides/sys'
import LoadingBox from "../../components/ui/LoadingBox"
import InputBase from "../../components/ui/form/InputBase"
import Icon from "../../components/Icon"
import ChatAvatar from "./ChatAvatar"

import LoadMore from "../../components/ui/HtmlElementLoadMore"

type Props = {
  onSelect:(user:ChatUser)=>void,
  client:any
}

const pageY=(elem:any):number=>{
  return elem.offsetParent ? elem.offsetTop + pageY(elem.offsetParent) : elem.offsetTop;
}

export default (props:Props) => { 
    const classes = useChatStyles()
    const {
      onSelect,
      client
    } = props
    const { account } = useSysState()
    if(!account){ 
      return <></>
    }
    const [container, setContainer] = React.useState<HTMLElement | null>(null)

    const [maxHeight,setMaxHeight] = React.useState<string|number>('auto')
    const [list,setList] = React.useState<ChatUser[]>([])
    const [keyword,setKeyword] = React.useState<string>('')
    const [page,setPage] = React.useState<number>(1)
    const [pageSize,setPageSize] = React.useState<number>(10)
    const [total,setTotal] = React.useState<number>(0)
    const [pageTotal,setPageTotal] = React.useState<number>(1)
    const [message,setMessage] = React.useState<any>({})

    const [connected,setConnected] = React.useState<boolean>(false)
    const {onConnect,onDisconnect,disconnect,usersRequest} = client
     
  const wrapperList =  (
    data: ChatUser[], 
    page:number,
    pageTotal:number,
    total:number,
    cb?:(status: "ready" | "noMore" | "error") => void
    ) => {
    if (data) {
      if(page === 1){
        setList(Object.assign([],data))
      }else{
        setList(Object.assign([],list.concat(data)))
      }
      if(cb)
      if (total > 0 && pageTotal === page) {
        // 最后一页 
        cb("noMore")
      } else {
        cb("ready")
      }
    } else {
      if(cb)
      cb("error")
    }
  }

  const loadMoreData = (cb?: (status: "ready" | "noMore" | "error") => void) => {
    if (total > 0 && pageTotal === page) {
      // 没有更多了
      if(cb)
      cb("noMore")
      return;
    }
    handleFetchUserlist(page+1,cb)
  }
  
    const handleFetchUserlist = (page:number,cb?:(status: "ready" | "noMore" | "error") => void)=>{
        setMessage({
                type:"loading",
                text: "正在获取用户列表..."
        });
        const usersPayload = { 
          auth:{
            token:account.token,
            userId:account.user.id,
          },
          userType:"0",
          keyword,
          page,
          pageSize
        }
        usersRequest(usersPayload, (askData:AskData) =>{
          if(askData.type === 'success'){ 
                setMessage({
                      type:"",
                      text: ""
                });
                const {pageTotal,total,page} = askData.result.pagination
                setPageTotal(pageTotal)
                setTotal(total)
                setPage(page)
                wrapperList(askData.result.list,page,pageTotal,total,cb)
          }else{ 
              setMessage({
                type:"error",
                text:askData.message || "请求用户列表失败！"
              });
              if(cb){
                cb("error")
              }
          }
        });
    }


    const onSubmit = (event: any) => {
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      else {
        event.cancelBubble = true;
      }

      if (event.preventDefault) {
        event.preventDefault();
      }
      else {
        event.returnValue = false;
      }
      handleFetchUserlist(1,(status: "ready" | "noMore" | "error")=>{
        setStatus(status)
      })
    }
    
    React.useEffect(() => {
      setMessage({
        type:"loading",
        text:"正在连接服务器..."
      });
      onConnect(()=> {
        setConnected(true)
        setMessage({
          type:"",
          text:""
        });
        handleFetchUserlist(page,(status: "ready" | "noMore" | "error")=>{
          setStatus(status)
        })
      });
      onDisconnect(()=> {
        setConnected(false)
        setMessage({
          type:"info",
          text:"已断开连接！"
        });
      });
      return () => {
        disconnect()
      }
    }, [])  

    React.useEffect(()=>{
      setMaxHeight(window.innerHeight-200)
    })

    const [status,setStatus] = React.useState<"ready" | "loadBegin"| "loading" | "noMore" | "error">('ready')
    const handleScroll = () => {
      if(container === null){
        return;
      }
      // 屏慕滚动
      const scrollTop = container.scrollTop;
      const clientHeight = container.clientHeight
      const scrollHeight = container.scrollHeight
      if(scrollTop+clientHeight>=scrollHeight && status ==="ready"){
        setStatus("loadBegin")
      }
  };

    return (
       <Box style={{display:"flex",flexDirection:"column"}}>
       <Box className={classes.search}>
        <form onSubmit={onSubmit} className={classes.form}>
            <InputBase
            value={`${keyword || ''}`}
            onChange={(event: any) => {
              setKeyword(event.target.value)
            }}
            style={{
              flexGrow:1,
              padding:5,
              width:100
            }} />
          <IconButton size="small" onClick={()=>{
              handleFetchUserlist(1,(status: "ready" | "noMore" | "error")=>{
                setStatus(status)
              })
          }}><IconSearch/></IconButton>
       </form>
       </Box>
        <div  ref={(ref: any) => {
        if (ref) {
          setContainer(ref)
        }
      }}
      style={{flexGrow:1,overflowY:"auto",maxHeight}}
      onScrollCapture={handleScroll}
      >
        <div>
       <Box className={classes.tips}>
          {message.type === 'loading' && <LoadingBox open={true} text={message.text}/>}
          {message.type === 'success' && <div className={classes.success}><Icon name="Success"/>{message.text}</div>}
          {message.type === 'error' && <div className={classes.error}><Icon name="Error"/>{message.text}</div>}
          {message.type === 'info' && <div className={classes.info}><Icon name="Alert"/>{message.text}</div>}
         </Box>
        <List>
        {list.map((item:ChatUser,index:number)=><ListItem 
            key={index}
            dense 
            button 
            onClick={()=>{
              onSelect(item)
            }}>
            <ListItemIcon>
            <ChatAvatar  avatar={item.avatar}/>
            </ListItemIcon>
            <ListItemText primary={<>
            {item.username}
             </>}/>
        </ListItem>)}
        </List>
        </div>
        {pageTotal > 1  && <div style={{ fontSize:"13px",}}>
          <LoadMore
          {...{
            showReady:true,
            loadData:loadMoreData,
            status,
            onStatusChange:(status:"ready" | "loadBegin"| "loading" | "noMore" | "error")=>{
              setStatus(status)
            }
          }}
        />
        </div>}
        </div>
      </Box>
    )
}