import React, { useState } from 'react'

import clsx from 'clsx'; 
import Box from '@material-ui/core/Box';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import IconArrow from "@material-ui/icons/ArrowRight"

import { TableRow, BodyTableCell, TableRowToolbar } from '../../../components/ui/table'

import Checkbox from '@material-ui/core/Checkbox';

import { useLocale } from '../../../config/Locale'

import { useListPageStyles } from '../../../assets/jss/pages'
import { Nav } from "../../../types/ui"
import DropdownList from "./DropdownList"

type Props = {
    row: Nav,
    index: number,
    isSelected: (id: string) => boolean,
    onClick: (event: any) => void,
    onEdit: () => void,
    onRemove: () => void
}

export default (props: Props) => {
    const { row, index, onEdit, onRemove, onClick, isSelected } = props
    const classes = useListPageStyles()
    const { translate } = useLocale()

    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;

    const [collapse, setCollapse] = useState(index === 0);
    const handleCollapse = () => {
        setCollapse(prev => !prev);
    }
    return <>
        <TableRow
            hover
            onClick={onClick}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            className={classes.tableRow}
        >
            <BodyTableCell padding="checkbox">
                <Box className={classes.inline}>
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="default"
                        className={classes.checked}
                        checkedIcon={<span className={clsx(classes.checkBoxIcon, classes.checkedIcon)} />}
                        icon={<span className={classes.checkBoxIcon} />}
                    />
                    <IconButton
                        component="span"
                        onClick={handleCollapse}>
                        <IconArrow className={clsx(classes.iconArrow, {
                            [classes.iconArrowActive]: collapse
                        })} />
                    </IconButton>
                    {index + 1}.
                </Box>
            </BodyTableCell>
            <BodyTableCell align="left">{row.name}</BodyTableCell>
            <BodyTableCell align="left">{row.icon}</BodyTableCell>
            <BodyTableCell align="left">{row.link}</BodyTableCell>
            <BodyTableCell align="left">
                {row.display === "all" && "所有"}
                {row.display === "mobile" && "仅手机"}
                {row.display === "pc" && "仅PC"}
            </BodyTableCell>
            <BodyTableCell align="left">
                {row.dropdown === "none" && "无下拉"}
                {row.dropdown === "all" && "所有平台"}
                {row.dropdown === "pc" && "仅PC"}
            </BodyTableCell>
            <BodyTableCell align="left">
                {row.dropdownType === "popper" && "弹出框"}
                {row.dropdownType === "drawer" && "抽屉"}
            </BodyTableCell>
            <BodyTableCell align="left">
                {row.dropdownOrientation === "horizontal" && "横排"}
                {row.dropdownOrientation === "vertical" && "竖排"}
            </BodyTableCell>
            <BodyTableCell align="left">
                {row.position === "1" && "顶部导航"}
                {row.position === "2" && "底部导航"}
            </BodyTableCell>
            <BodyTableCell align="left">
                {row.status === "1" ? "启用" : "未启用"}
            </BodyTableCell>
            <BodyTableCell align="right" className={classes.rowToolbar}>
                <TableRowToolbar
                    {...{
                        editLabel: translate('ui.nav.edit'),
                        deleteLabel: translate('ui.nav.delete'),
                        onEdit,
                        onDelete: () => {
                            onRemove()
                        },
                    }} />
            </BodyTableCell>
        </TableRow>
        {collapse &&
            <TableRow>
                <BodyTableCell></BodyTableCell>
                <BodyTableCell colSpan={8}>
                    <Collapse in={collapse}>
                        <Box className={classes.extend}>
                            <Box className={classes.extendItem}>
                                <DropdownList editing={false} data={row.dropdownList} />
                            </Box>
                        </Box>
                    </Collapse>
                </BodyTableCell>
            </TableRow>}
    </>
}