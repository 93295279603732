
import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconClose from '@material-ui/icons/Close'
/**
 * 关闭图标
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      position: 'absolute',
      width: '20px',
      height: '20px',
      top: '2px',
      right: '3px',
      cursor: 'pointer',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.3)'
      },
    },
    icon: {
      margin: 0,
      padding: 0,
      minHeight:16,
      minWidth:16,
      maxHeight:16,
      maxWidth:16
    }
  }))

type Props = {
  open?: boolean,
  close: Function,
  color?: string,
  hoverColor?: string
}

export default (props: Props) => {
  const { open, close, color, hoverColor } = props
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  return (
    <Box
      className={classes.root}
      onClick={(e) => { close(e) }}
      onMouseEnter={() => { setHover(true) }}
      onMouseLeave={() => { setHover(false) }}
      style={{
        color:hover ? hoverColor ? hoverColor : '#fff' : color ? color : '#222'
      }}>
      {open ?
        <IconClose className={classes.icon} />
        : <></>
      }

    </Box>)
}
