import * as actions from "../../store/actions/user/like";
import { useDispatch } from "react-redux";

interface Result {
  likeToMe: Function;
  liked:Function;
  toggle: Function;
}

export const useLike = (): Result => {
  const dispatch:any = useDispatch()
  // 获取列表 
  const likeToMe =  (props: any) => {
    const { params, success, failure } = props;
     dispatch(actions.likeToMe(params)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        const list = data.result.list;
        const pagination = data.result.pagination;
        // 成功返回
        if (success) success(list, pagination);
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };

  // 获取列表 
  const liked =  (props: any) => {
    const { params, success, failure } = props;
     dispatch(actions.liked(params)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        // 成功返回
        if (success) success(data.result.liked,data.result.likeTotal);
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };


  // 点赞或取消
  const toggle =  (props: any) => {
    const { log, success, failure } = props;
     dispatch(actions.toggle(log)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        // 成功返回
        if (success) success(data.result.liked,data.result.likeTotal);
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };
  
  return {
    likeToMe,
    liked,
    toggle
  };
};
