import React from "react"

import { attachPrefix } from "../../../../../config"
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from "../../../../../components/Icon"

import { ComponentOption } from "../../../../../types/ui"
import { SectionButton, Link } from "../types"

type OptionFieldProps = {
    value?: any,
    option: ComponentOption,
    showLabel?: boolean
}

type FieldProps = {
    labelWidth?: number,
    label?: any,
    value: any
}
const Field = (props: FieldProps) => {

    const { labelWidth, label, value } = props

    return <Box style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "4px 0"
    }}>
        <Box style={{
            width: labelWidth || "auto",
            textAlign: "right",
            color: "#777"
        }}>
            {label}
        </Box>
        <Box style={{
            flex: 1
        }}>
            {value}
        </Box>
    </Box>
}

// 组件配置输入
export default (props: OptionFieldProps) => {
    const { value, option, showLabel = true } = props

    const labelWidth = showLabel ? showLabel ? 150 : 0 : 0;
    const label = showLabel ? `${option.alias}：` : "";

    let field = <></>
    if (option.variableType === "string" ||
        option.variableType === "link" ||
        option.variableType === "enum" ||
        option.variableType === "string|number" ||
        option.variableType === "any") {
        // 字符或数值
        field = <Field
            labelWidth={labelWidth}
            value={value}
            label={label} />
    } else if (option.variableType === "number") {
        // 字符或数值
        field = <Field
            labelWidth={labelWidth}
            value={value}
            label={label} />
    } else if (option.variableType === "string[]" ||
        option.variableType === "number[]") {
        //字符数组
        field = <Field
            labelWidth={labelWidth}
            value={value && value.map((val: string, index: number) => <span key={index}
                style={{
                    padding: "5px 10px",
                    border: "1px solid #dedede",
                    marginRight: 10
                }}>{val}</span>)}
            label={label} />
    } else if (option.variableType === "boolean") {
        // 布尔值
        field = <Field
            labelWidth={labelWidth}
            value={value ? "是" : "否"}
            label={label} />
    } else if (option.variableType === "image") {
        // 图片
        field = <Field
            labelWidth={labelWidth}
            value={value && <img src={attachPrefix + value} height={32} />}
            label={label} />
    } else if (option.variableType === "image[]") {
        // 图片数组
        field = <Field
            labelWidth={labelWidth}
            value={value && value.map((src: string, index: number) => <img key={index} src={attachPrefix + src} height={32} />)}
            label={label} />
    } else if (option.variableType === "color") {
        // 取色器
        field = <Field
            labelWidth={labelWidth}
            value={value && <span style={{ color: value }}>{value}</span>}
            label={label} />
    } else if (option.variableType === "html") {
        // html文本
        field = <Field
            labelWidth={labelWidth}
            label={label}
            value={value && <Box dangerouslySetInnerHTML={{ __html: value }} />} />
    } else if (option.variableType === "Link[]") {
        // 链接组
        if (value && value.length > 0) {
            const nodes = value.map((item: Link, index: number) => <Box
                key={index}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: 12,
                    margin: "0 8px 8px 0",
                    padding: 8,
                    border: "1px solid #f0f0f0",
                    borderRadius: 4
                }}>
                <span>链接标题:{item.title}</span>
                <span> 链接地址:{item.link}</span>
            </Box>)
            field = <Field
                labelWidth={labelWidth}
                label={label}
                value={nodes} />
        }
    } else if (option.variableType === "Button[]") {
        // 按钮组
        if (value && value.length > 0) {
            const nodes = value.map((item: SectionButton, index: number) => <Box
                key={index}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: 12,
                    margin: "0 8px 8px 0",
                    padding: 8,
                    border: "1px solid #f0f0f0",
                    borderRadius: 4
                }}>
                <Button>
                    <Icon name={item.icon} />
                    {item.text}
                </Button>
                <span>颜色:{item.color}</span>
                <span>按钮地址:{item.link}</span>
            </Box>)
            field = <Field
                labelWidth={labelWidth}
                label={label}
                value={nodes} />
        }
    }else if (option.variableType === "icon") {
        // 图标
        if (value) {
            field = <Field
                labelWidth={labelWidth}
                label={label}
                value={<Icon
                    name={value}/>} />
        }
    }
    return field
}