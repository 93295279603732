import React from 'react'

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router-dom";

export default withRouter((props: any) => {
  const { history } = props
  return (<Box >
    <Box style={{ color: "#666", padding: 20 }}>
      请先点击“侧栏菜单=>内容管理=>分类管理”添加文章分类！<br />
      然后点击“侧栏菜单=>内容管理=>文章管理=>文章分类”管理文章<br /><br />
      <Button
        variant="contained"
        color="primary"
        onMouseDown={() => {
          history.replace(`/content/cat`)
        }}>
        管理文章分类
      </Button>
    </Box>
  </Box>
  )
})