import React,{useState,useEffect} from 'react';
import classNames from "classnames"
import { attachPrefix ,MODE_LIKE_COMMENT} from '../../config';
import { pageY ,getParentDom} from '../../lib/util';

import Box from "@material-ui/core/Box";
import Collapse from '@material-ui/core/Collapse';
import Button from "@material-ui/core/Button"; 
import IconButton from "@material-ui/core/IconButton"; 
import IconLike from "@material-ui/icons/ThumbUpAltOutlined";
import IconLiked from "@material-ui/icons/ThumbUp";
import IconSms from "@material-ui/icons/Sms";
// import IconDel from "@material-ui/icons/Delete";
import IconPlayArrow from "@material-ui/icons/PlayArrow";

import Rating from '@material-ui/lab/Rating';

import { useComment,useLike } from "../../provides/user"
import {Comment} from "../../types/user"
import { useSysState } from '../../provides/sys'

import {  formatDateTime } from "../../lib/util"
// 加载更多
import LoadMore from "../ui/LoadMore"
import LoadingBox from "../ui/LoadingBox"
import Icon from '../Icon' 
import { MessageDialog, AlertDialog ,LoadingDialog} from '../ui/dialog'

import { useCommentListStyles } from "../../assets/jss/components"

import Error from "../Error"  
import SectionCommentList from "./List"
import SectionCommentWrite from "./Write"
import Images from "./Images"

type ItemProps = {
  showUserInfo?:boolean,
  enableComment?:boolean,
  enableLike?:boolean,
  showSubUserInfo?:boolean,
  enableSubRemove?:boolean,
  enableSubComment?:boolean,
  enableSubLike?:boolean,
  vertical?:boolean,
  onCollapse:()=>void,
  collapse?:boolean,
  topTargetType:"article"|"product"|"orderDetail",
  targetTopid:string,
  item: Comment, 
  onRemove?:()=>void,
  commentText?:any,
  subCommentText?:any,
  footerStyle?:'default'|'simple'
}
type Props = {
  data?:Comment[],
  showUserInfo?:boolean,
  enableComment?:boolean,
  enableLike?:boolean,
  showSubUserInfo?:boolean,
  enableSubRemove?:boolean,
  enableSubComment?:boolean,
  enableSubLike?:boolean,
  vertical?:boolean,
  border?:boolean,
  loaded:boolean,
  onLoaded:()=>void,
  topTargetType:"article"|"product"|"orderDetail",
  targetType: string,
  targetId: string,
  title?:any,
  total:number,
  autoMore?:boolean, // 手动或自动加载更多
  near?:boolean,//加载最新的数据
  onNearLoaded?:()=>void,//最新的数据加载完成通知
  enableRemove?:boolean,
  commentText?:any,
  subCommentText?:any,
  footerStyle?:'default'|'simple'
}

/**
 * 条目
 * @param props 条目
 */
const Item = (props: ItemProps) => {
  const classes = useCommentListStyles();
  const { account } = useSysState();
  const {
     topTargetType,
     targetTopid,
     item,
     collapse,
     onCollapse,
     vertical,
     showUserInfo=true,
     enableComment=true,enableLike=true,
     showSubUserInfo=true,
     enableSubRemove=true,
     enableSubComment=true,
     enableSubLike=true,
     onRemove,
     commentText,
     subCommentText,
     footerStyle
   } = props

  // 宽度
  const [width,setWidth] = useState<string|number>('auto')
  const [showList,setShowList] = useState<boolean>(true)
  const [reload,setReload] = useState<boolean>(false)// 图片重载
  const [near,setNear] = useState<boolean>(false);// 是否加载新的数据
  const [commentListLoaded,setCommentListLoaded] = useState<boolean>(false)
  const [commentTotal,setCommentTotal] = useState<number>(item.commentTotal || 0)
  const [likeTotal,setLikeTotal] = useState<number>(item.likeTotal || 0)
  const [liked,setLiked] = useState<boolean>(false)
  const likeProvides = useLike();
  const likedFn = ()=>{
    likeProvides.liked({
      params:{
        mode:MODE_LIKE_COMMENT,
        content:item.id
      },
      success :(liked:boolean)=>setLiked(liked)
    })
  }

  useEffect(()=>{
    likedFn();
    return ()=>{}
    },[])

  return <div className={classNames(classes.listItem,{
    [classes.vertical]:vertical
  })}
  ref={(el:any)=>{if(el){setWidth(el.clientWidth-16)}}}
  > 

    {showUserInfo && <Box className={classNames({
    [classes.userInfo]:!vertical,
      [classes.alignStart]:vertical,
      [classes.fullWidth]:vertical
    })}>
        <span className={classes.uname}>
          {item.username}
        </span>
    </Box>}


    <Box className={classNames(classes.itemBody,{
      [classes.fullWidth]:vertical
    })}>
        {item.targetType === 'orderDetail' && <>
            <Rating name={`${item.id}`}
                      precision={0.5} 
                    value={item.star} 
                    readOnly />
        </>} 
        <Box className={classes.content} dangerouslySetInnerHTML={{ __html: item.content || "" }} />
        {item.images  && item.images.length > 0 && <>
             <Images {...{
               width,
               data:item.images,
               reload,
               onChange:()=>{
                 setReload(false)
               }
             }}/>
        </>}
        <Box className={classes.toolbar}>

          <Box className={classes.etc}>
          {formatDateTime(item.createTime || "")}
        </Box>
          <Box className={classes.grow}/>
         {enableComment &&
            <Button onClick={onCollapse}>
             {commentText ? <>
              {commentText}
              (<span className={classes.total}>{commentTotal || 0}</span>)
              </>:<>
             <IconSms />
             <span className={classes.total}>${commentTotal || 0}</span>
             </>}
            </Button>}

         {enableLike &&
            <>
            {liked ?<IconLiked  style={{color:"#ff6600"}}/>:<IconLike />}
            <span className={classes.total}>{likeTotal}</span>
          </>
          }
          {account &&  item.userId === account.user.id && onRemove && 
          <Button onClick={()=>onRemove()}>
          删除
        </Button> }

         {commentTotal > 0 &&
            <IconButton onClick={()=>setShowList(!showList)}>
              <IconPlayArrow className={classNames(classes.iconArrow,{
                [classes.iconArrowActive]:showList
              })}/>
          </IconButton>
          }
        </Box>

        <Collapse in={collapse} unmountOnExit style={{padding:0}}>
        <SectionCommentWrite
        {...{
          footerStyle,
          topTargetType,
          targetType: "comment",
          targetTopid,
          targetId: item.id, 
          onSubmited:(total:number)=>{
            setNear(true);
            setCommentTotal(total)
          }
        }}
      />
      </Collapse>
        <Collapse in={showList} >
        <SectionCommentList {...{
        showUserInfo:showSubUserInfo,
        enableComment:enableSubComment,
        enableLike:enableSubLike,
        enableRemove:enableSubRemove,
        showSubUserInfo,
        enableSubComment,
        enableSubLike,
        enableSubRemove,
        commentText:subCommentText,
        subCommentText,
         footerStyle,
        total:commentTotal,
        vertical:true,
        targetId:item.id,
        topTargetType,
        targetType:'comment',
        near,
        onNearLoaded:()=>setNear(false),
        loaded:commentListLoaded,
        onLoaded:()=>setCommentListLoaded(true)
      }}/>
      </Collapse>
    </Box>

  </div>
}


export default (props: Props) => {
  const classes = useCommentListStyles();
  const {
    data,
    loaded,
    onLoaded,
    near,
    onNearLoaded,
    topTargetType,
    targetType,
    targetId,
    title,
    border,
    vertical,
    autoMore,
    total,
    showUserInfo,
    enableRemove,
    enableComment,
    enableLike,
    showSubUserInfo,
    enableSubRemove,
    enableSubComment,
    enableSubLike,
    commentText,
    subCommentText,
    footerStyle
  } = props

  const [commentTotal,setCommentTotal] = useState<number>(total || 0)
  const initialLoading = { type:'box',open: false, text: '' }
  const [loading,setLoading] = useState<any>(initialLoading)
  const {getList,getTotal,remove} = useComment()
  const [list, setList] = useState<Comment[]>(data || [])
  // 最后一条时间
  const [lastTime, setLastTime] = useState<number>(0)
  // 第一条时间
  const [firstTime, setFirstTime] = useState<number>(0)

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const loadTotal = () => {
    getTotal({
     params: {
       targetType,
       targetId
     },
     success: (total:number) => {
       setCommentTotal(total)
     },
     failure: (message: string) => {
       if(message){}
     }
    })
   }

  const wrapperList =  (
    near:boolean,
    data: Comment[], 
    cb?:(status: "ready" | "noMore" | "error") => void
    ) => {
    if (data) {
      // 最新的数据加入到最前面
      let newList:Comment[] = []
      if(near){
        newList =Object.assign([],data.concat(list))
      }else{
        newList = Object.assign([],list.concat(data))
      }
      setList(newList)
      setFirstTime(newList.length>0?newList[0].createTime:0)
      setLastTime(newList.length>0?newList[newList.length-1].createTime:0)
      if(cb)
      if (list.length >= commentTotal) {
        // 最后一页 
        cb("noMore")
      } else {
        cb("ready")
      }
    } else {
      if(cb)
      cb("error")
    }
  }

  const loadData = (near:boolean,cb?:(status: "ready" | "noMore" | "error") => void)=>{
    getList({
     params: {
       near,
       firstTime,
       targetType,
       targetId,
       lastTime
     },
     success: (data: Comment[]) => {
       setLoading({open:false})
       wrapperList(near,data,cb)
     },
     failure: (message: string) => {
       setLoading({open:false})
       console.log(message)
       if(cb) 
       cb("error")
     }
    })
   }
 
  const loadMoreData = (cb?: (status: "ready" | "noMore" | "error") => void) => {
    if (commentTotal > 0 && list.length === commentTotal) {
      // 没有更多了
      if(cb)
      cb("noMore")
      return;
    }
    loadData(false,cb)
  }
  // 删除评价
  const onRemove = (id:string,index:number)=>{
    
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
          <Box>你正在删除评论！</Box>
          <Box> 此操作不可恢复，确定要继续么？</Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        setLoading({
          type:"dialog",
          open: true,
          text: '正在删除评论...'
        })
        remove({
          id:[id],
          success: (message: string) => {
            if(message){}
            setLoading(initialLoading)
            list.splice(index,1)
            setList(Object.assign([],list))
            loadTotal()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
}
  // 切换回复输入框
  const [selected, setSelected] = useState<string>("")

  // 显示加载更多指定节点
  const [element, setElement] = useState<HTMLElement | null>(null)
  const [scrolled, setScrolled] = useState<boolean>(false)
  const [width, setWidth] = useState<string | number>('auto')

  // fixed
  const handleScroll = () => {
    if (element) {
      // 根节点
      const rootDom = getParentDom(element,"screenMain")
      if(rootDom){
          // 屏慕滚动
          const scrollTop = window.pageYOffset;
          const offsetTop = pageY(rootDom)
          const { clientHeight,clientWidth } = rootDom;
          if(scrollTop > offsetTop+clientHeight ){
            setScrolled(true)
            setWidth(clientWidth)
          }else {
            setScrolled(false)
          }
      }
    }
  }
  useEffect(()  => {
    if(!loaded && autoMore){
      onLoaded()
      setLastTime(0)
      setLoading({type:"box",open:true,text:"正在加载评论列表..."})
      setList([])
      loadData(false)
    }
     if(loaded && near && onNearLoaded){
      // 加载最新的评论
      onNearLoaded()
      loadData(true)
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  })
   
  const renderMoreButton = ()=>{
    if(autoMore){
      return list && list.length > 0  && <LoadMore
          element={element}
          loadData={loadMoreData}
        />
    }else if(commentTotal){
      // 手动查看回复
      const restTotal = commentTotal - (list?list.length:0)
      if(restTotal > 0){
        return  <Button  className={classes.moreButton} onClick={()=>{
          setLoading({ type:"box",open:true,text:"正在加载回复列表...",align:"left"})
          if(list.length > 0){
            loadMoreData()
          }else{
            setLastTime(0)
            loadData(false)
          } 
          onLoaded()
        }}>——展开{restTotal}条回复</Button>
      }
    }
  } 

  return (<div className={classes.section}
    style={{
      position: scrolled ? "fixed" : "static",
      width: scrolled ? width : 'auto',
      top: scrolled ? 60 : "auto",
      zIndex: scrolled ? 999 : "auto"
    }}
    ref={(ref: any) => {
      if (ref) {
        setElement(ref)
      }
    }}>

    <Box className={classNames(classes.list,{
      [classes.border]:border
    })}>
    {title && <Box className={classes.title}> {title}</Box>}
    {loading.type === 'box' && <LoadingBox {...loading} /> }
    {list && list.length > 0 ? <>
      {list.map((item: Comment,index:number) => <Item
        key={index}
        {...{
          showUserInfo,
          enableComment,
          enableLike,
          showSubUserInfo,
          enableSubRemove,
          enableSubComment,
          enableSubLike,
          commentText,
          subCommentText,
          footerStyle,
          vertical,
          item, 
          // total:commentTotal,
          topTargetType,
          targetTopid:targetId, 
          collapse:selected === item.id,
          onCollapse:()=>{
            setSelected(selected === item.id?"":item.id)
          },
          onRemove:enableRemove?()=>{
            onRemove(item.id,index)
            }:undefined
        }} />)}
    </> :
    <>
    {!loading.open && autoMore && <Error message="目前还没有评论！" />}
    </>
    }
    {renderMoreButton()}
  </Box>

    {loading.type === 'dialog' && <LoadingDialog {...loading} /> }
    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </div>
  );
}
