import * as blocks from './blocks'
import * as customStyles from './styles'

export {decorator} from './decorator'

export const blockRender = blocks.blockRender
export const blockRenderMap = blocks.blockRenderMap
export const blockStyleFn = blocks.blockStyleFn

export const styleMap = customStyles.styleMap
export const styleFn = customStyles.styleFn
export const styles = customStyles.styles
export const styleNames = customStyles.styleNames
export const exporter = customStyles.exporter
export const cssProperies = customStyles.cssProperies
export const getCurrentStyle = customStyles.getCurrentStyle
  