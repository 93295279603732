
export const data = [{
    name: '',
    title: '首页',
    icon: 'Home',
    exact: true
  },{
    name: 'common',
    title: '公共管理',
    icon: 'Setting',
    children: [
      { name: `module`, title: '模块管理', icon: 'GridSetting', edit: true,editTitle:'编辑模块' },
      { name: 'area', title: '行政区划', icon: 'Area', edit: true,editTitle:'编辑行政区划'  },
      { name: `ad`, title: '广告管理', icon: 'Ad' ,edit: true,editTitle:'编辑广告'},
      { name: `setting`, title: '全局设置', icon: 'Setting' },
      { name: `client`, title: '客户端列表', icon: 'Client' },
      { name: `keyword`, title: '搜索关键词设置', icon: 'Keyword' },
      { name: `indexed`, title: '创建索引', icon: 'Indexed' },
      { name: `friendUrl`, title: '友情链接', icon: 'FLink' },
      { name: `board`, title: '留言管理', icon: 'Books' }
    ]
  }, {
    name: 'ui',
    title: '用户界面',
    icon: 'CircleLayout',
    children: [
      { name: `nav`, title: '导航设置', icon: 'CircleLayout', edit: true ,editTitle:'编辑导航条目'  },
      { name: `component`, title: '组件设置', icon: 'Component', edit: true ,editTitle:'编辑组件' },
      { name: `page`, title: '页面设置', icon: 'Html', edit: true ,editTitle:'编辑页面' }
    ]
  }, {
    name: 'content',
    title: '内容管理',
    icon: 'DocumentSetting',
    children: [
      { name: `cat`, title: '文章分类', icon: 'Tree', edit: true ,editTitle:'编辑文章分类'},
      { name: `article`, title: '文章管理', icon: 'Document', edit: true,editTitle:'编辑文章' }
    ]
  }, {
    name: 'shopping',
    title: '商城管理',
    icon: 'ShopSetting',
    children: [
      { name: `brand`, title: '品牌管理', icon: 'Brand', edit: true,editTitle:'编辑品牌' },
      { name: `cat`, title: '商品分类', icon: 'Tree', edit: true,editTitle:'编辑商品分类' },
      { name: `product`, title: '商品管理', icon: 'ThumbList', edit: true,editTitle:'编辑商品' },
      { name: `attr`, title: '属性管理', icon: 'CircleList', edit: true,editTitle:'编辑属性' },
      { name: `spec`, title: '规格管理', icon: 'DotList', edit: true,editTitle:'编辑规格' },
      { name: `promotion`, title: '促销活动', icon: 'Percent', edit: true,editTitle:'编辑促销活动' },
      { name: `coupon`, title: '优惠券', icon: 'Coupon', edit: true,editTitle:'编辑优惠券' },
      { name: `freight`, title: '运费设置', icon: 'Freight'}
    ]
  }, 
      {
        name: 'trade',
        title: '交易管理',
        icon: 'RMB',
        children: [
          // { name: `setting`, title: '交易设置', icon: 'RMB' },
          { name: `order`, title: '订单管理', icon: 'Books' },
          { name: `refund-reason`, title: '退款原因', icon: 'Refund' }
        ]
      },  {
    name: 'integral',
    title: '积分管理',
    icon: 'SolidIntegral',
    children: [
      { name: `setting`, title: '积分设置', icon: 'Integral' },
      { name: `exchange`, title: '积分兑换', icon: 'Integral' }
    ]
  }, {
    name: 'interact',
    title: '互动管理',
    icon: 'Interact',
    children: [
      { name: `comment`, title: '评论管理', icon: 'Comments' },
      { name: `share`, title: '分享管理', icon: 'ShareDown' },
      { name: `like`, title: '点赞管理', icon: 'Like' },
      { name: `collect`, title: '收藏管理', icon: 'StarSetting' }
    ]
  },{
    name: 'stat',
    title: '统计分析',
    icon: 'CircleStat',
    children: [
      { name: `index`, title: '文章统计', icon: 'Stat' },
      { name: `index`, title: '商城统计', icon: 'Stat' },
      { name: `users`, title: '用户行为分析', icon: 'Stat' }
    ]
  }, {
    name: 'user',
    title: '用户管理',
    icon: 'UserSetting',
    children: [
      { name: `comment`, title: '用户列表', icon: 'Users' }
    ]
  }]