import React, { Fragment } from "react";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";

import SubMenuItem from "./SubMenuItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarIsOpen: {
      paddingLeft: 0,
      paddingRight: 0,
      transition: "padding-left 295ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    sidebarIsClosed: {
      paddingLeft: 0,
      paddingRight: 0,
      transition: "padding-left 295ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
  })
);

const SubMenu = (props: any) => {
  const {
    handleOpens,
    sidebarIsOpen,
    active,
    isOpen,
    title,
    icon,
    children,
    paddingLeft,
    // 弹框元素
    isTop,
    anchorId,
    anchorEls,
    popoverShow,
    popoverClose,
  } = props;
  const classes = useStyles();

  return (
    <Fragment>
      <SubMenuItem
        {...{
          handleOpens,
          sidebarIsOpen,
          active,
          isOpen,
          title,
          icon,
          paddingLeft,
          // 弹框元素
          isTop,
          anchorId,
          popoverShow,
        }}
      />
      {sidebarIsOpen || !isTop ? (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List
            dense
            component="div"
            disablePadding
            className={
              sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
            }
          >
            {children}
          </List>
        </Collapse>
      ) : (
        <Popover
          id={anchorId}
          open={Boolean(anchorEls[anchorId])}
          anchorEl={anchorEls[anchorId]}
          onClose={popoverClose}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            style={{
              background: "#243448",
            }}
          >
            {children}
          </Box>
        </Popover>
      )}
    </Fragment>
  );
};

export default SubMenu;
