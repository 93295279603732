import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'


// Action Types
export type Action = {
    type: 'TRADE_REFUND_REASON_GET_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_REASON_SAVE',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_REASON_DELETE',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_AUDIT_OK',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_AUDIT_FAIL',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_PAYMENT',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_WXQUERY',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    type: 'TRADE_REFUND_ALIPAY_QUERY',
    payload: { client: 'default', request: AxiosRequestConfig }
};

// 退款原因列表
export const REASON_GET_LIST = 'TRADE_REFUND_REASON_GET_LIST'
export const getReasons = (): Action => ({
    type: REASON_GET_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/getReasons`,
            method: 'GET'
        }
    }
})


// 保存退款原因
export const REASON_SAVE = 'TRADE_REFUND_REASON_SAVE'
export const saveReason = (data:any): Action => ({
    type: REASON_SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/saveReason`,
            method: 'POST',
            data
        }
    }
})

// 删除退款原因
export const REASON_DELETE = 'TRADE_REFUND_REASON_DELETE'
export const removeReasons = (data:any): Action => ({
    type: REASON_DELETE,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/delReasons`,
            method: 'POST',
            data
        }
    }
})


// 审核退款通过
export const AUDIT_OK = 'TRADE_REFUND_AUDIT_OK'
export const auditOk = (data:any): Action => ({
    type: AUDIT_OK,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/auditOk`,
            method: 'POST',
            data
        }
    }
})

// 审核退款失败
export const AUDIT_FAIL = 'TRADE_REFUND_AUDIT_FAIL'
export const auditFail= (data:any): Action => ({
    type: AUDIT_FAIL,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/auditFail`,
            method: 'POST',
            data
        }
    }
})

// 审核完成退回资金
export const PAYMENT = 'TRADE_REFUND_PAYMENT'
export const payment= (data:any): Action => ({
    type: PAYMENT,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/payment`,
            method: 'POST',
            data
        }
    }
})

//  微信查询退款
export const WXQUERY = 'TRADE_REFUND_WXQUERY'
export const wxRefundQuery= (data:any): Action => ({
    type: WXQUERY,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/wxRefundQuery`,
            method: 'POST',
            data
        }
    }
})

//  支付宝查询退款
export const ALIPAY_QUERY = 'TRADE_REFUND_ALIPAY_QUERY'
export const alipayRefundQuery= (data:any): Action => ({
    type: ALIPAY_QUERY,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/refund/alipayRefundQuery`,
            method: 'POST',
            data
        }
    }
})
