
import { makeStyles, createStyles, Theme, fade } from '@material-ui/core/styles';

export const useUIPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    }, 
    grow: {
      flexGrow: 1
    },
    heading: {
      color: "#999",
      display: "inline-flex",
      alignItems: "center",
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      '& button': {
        minWidth:32
      },
      '& span': {
        marginRight: theme.spacing(2),
        display: "flex",
        alignItems: "center",
      },
      '&:hover':{
        backgroundColor: "#f3f3f3",
      }
    },
    headingActive:{
      backgroundColor: "#f7f7f7",
      borderBottom: "1px solid #dedede",
      '& span':{
        color: "#484848",
      }
    },
    label:{
      color: "#aaa",
      fontSize:14
    },
    headingLabel:{
      paddingLeft:theme.spacing(1),
      color: "#aaa",
    },
    headingValue:{
      color: "#666",
    },
    setting: {
      backgroundColor: "#fff",
      margin: theme.spacing(1),
      borderRadius: 4,
      border: "1px solid #dedede"
    },
    settingHeading: {
      backgroundColor: "#fafafa",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      borderBottom: "1px solid #dedede",
      padding: theme.spacing(1)
    },
    settingBody:{
      padding:theme.spacing(1)
    },
    alert: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alertIcon: {
      margin: theme.spacing(1)
    },
    collapse:{ 
      width:"100%",
    },
    list:{
      display: "flex",
      flexDirection:"column",
      margin:0,
      padding:0,
    },
    listItem: {
      clear: "both",
      padding: 0,
      borderBottom:"1px dotted #dedede",
      '&:last-child':{
        borderBottom:0
      }
    },
    buttonListItem:{

    },
    id: {
      borderRadius: 3,
      padding: theme.spacing(0,1,0,0),
      fontWeight: 300,
      color: "#999",
      fontSize:".825em"
    },
    tips: {
      display: "flex",
      padding: theme.spacing(0,1),
      alignItems: "center",
      color: "#999",
      justifyContent: "center",
      fontSize:".925em"
    },
    startTips: {
      display: "flex",
      padding: theme.spacing(0,1),
      alignItems: "center",
      color: "#999",
      justifyContent: "flex-start",
      fontSize:".925em"
    },
    icon: {
      color: "#666",
      maxWidth: 16,
      maxHeight: 16,
      marginRight:4
    },
    smallIcon: {
      color: "#666",
      maxWidth: 16,
      maxHeight: 16,
    },
    square: {
      cursor:"pointer",
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin:0,
      padding:4,
      color: `${fade(theme.palette.text.primary, 0.6)}`,
      alignItems: 'center',
    },
    listItemTitle: {
      width:"auto",
      clear: "both",
      display: "flex",
      alignItems: "center",
      borderRadius:4,
      padding:5,
      '&:hover':{
        background:"#f7f7f7"
      }
    },
    listItemTitleText: {
      flexGrow: 1,
      marginLeft: theme.spacing(1)
    },
    listItemButtons: {

    },
    dialog: {
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      background: `#fff`,
      marginTop: 10,
      height: 38,
      borderBottom: '1px solid #dedede'
    },
    contentContainer: {
      flex: 1,
    },
    alertBox:{
      padding:theme.spacing(2,0),
      display: 'flex',
      alignItems: "center",
    }
  }));