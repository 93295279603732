import React, { useState, useEffect } from 'react'

import clsx from 'clsx';
import { lighten, makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Icon from '../../../components/Icon'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';

import { withRouter } from "react-router-dom";
import { useModule } from '../../../provides/common/ModuleProvide'
import { useLocale } from '../../../config/Locale'

import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, AlertDialog } from '../../../components/ui/dialog'
import { attachPrefix } from '../../../config';

import RadioInput from '../../../components/ui/form/RadioInput'


function desc(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array: any, cmp: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

function getSorting(order: any, orderBy: any) {
  return order === 'desc' ? (a: any, b: any) => desc(a, b, orderBy) : (a: any, b: any) => -desc(a, b, orderBy);
}
const headRows = [
  { id: 'icon', numeric: false, disablePadding: false, label: '图标' },
  { id: 'id', numeric: false, disablePadding: true, label: '模块ID' },
  { id: 'name', numeric: false, disablePadding: true, label: '模块名称' },
  { id: 'address', numeric: false, disablePadding: false, label: '访问地址' },
  { id: 'status', numeric: false, disablePadding: false, label: '状态' },
  { id: 'sorter', numeric: false, disablePadding: false, label: '排序' }
];

function EnhancedTableHead(props: any) {
  const { translate } = useLocale()
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align="left"
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? translate('desc') : translate('esc')}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
      flex: 1,
    },
    actions: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      display: 'flex',
      flexWrap: 'wrap',
      userSelect: 'none',
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    }
  }));

const EnhancedTableToolbar = (props: any) => {
  const classes = useToolbarStyles();

  const { translate } = useLocale()
  const { numSelected, onAddClick, onDelClick, onRefresh } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} {translate('selected')}
          </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
              {translate('common.module.list')}
            </Typography>
          )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Tooltip title={translate('refresh')} aria-label="add">
          <IconButton onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={translate('common.module.add')} aria-label="add">
          <IconButton onClick={onAddClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        {numSelected > 0 && (
          <Tooltip title={translate('common.module.remove')} aria-label="add">
            <IconButton onClick={onDelClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    pageContent: {
      padding: 10
    },
    paper: {
      marginBottom: theme.spacing(2),
    },
    table: {
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    errorIcon: {
      margin: theme.spacing(1)
    },
    alert: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alertIcon: {
      margin: theme.spacing(1)
    },
    rowToolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap'
    },
    rowButton: {
    },
    icon: {
      width: 18,
      height: 18,
      margin: 0,
    },
    pagination: {
      padding: theme.spacing(1, 0, 1, 0)
    },
    preview: {
      padding: 10,
      maxWidth: 750,
      minWidth: 350,
    },
  }));
const ModuleListPage = (props: any) => {
  const classes = useStyles()
  const { history } = props
  const { translate } = useLocale()
  const { getList, remove, setStatus } = useModule()
  // 列表
  const [list, setList] = useState([] as any)

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  // 设置状态
  const handleStatus = (id: string, status: string) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在更改状态...'
    })
    setStatus({
      data: {
        id,
        status
      },
      success: (list: any) => {
        loadList()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  // 获取列表
  const loadList = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载模块列表...'
    })
    getList({
      success: (list: any) => {
        setList(list)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        setList([])
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadList();
    return () => {
      // 返回空方法，否则会陷入无限循环
    };
  }, []);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([] as string[])
  const [dense, setDense] = React.useState(true);

  const handleRequestSort = (event: any, property: any) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = list && list.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleClick = (event: any, id: string) => {
    let newSelected = selected || [];
    const index = newSelected.indexOf(id)
    if (index > -1) {
      newSelected.splice(newSelected.indexOf(id), 1)
    } else {
      newSelected.push(id)
    }
    setSelected(Object.assign([], newSelected));
  }


  const handleChangeDense = (event: any) => {
    setDense(event.target.checked);
  }

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
        {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
        <Box>
          <Box>{translate('common.module.removeTips')}</Box>
          <Box>{translate('common.module.removeAsk')}</Box>
        </Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        setLoading({
          open: true,
          text: translate('common.module.removing')//'正在删除模块...'
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadList()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
  }

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  const renderPageContent = (
    <div className={classes.pageContent}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected && selected.length}
          {...{
            onAddClick: () => {
              history.replace(`/common/module/edit`)
            },
            onDelClick: () => {
              handleDelete(selected)
            },
            onRefresh: () => {
              loadList()
            }
          }} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected && selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={list.length}
            />
            <TableBody>

              {stableSort(list, getSorting(order, orderBy))
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => {
                        if (row.enableEdit === '1') {
                          handleClick(event, row.id)
                        }
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        {
                          row.enableEdit === '1' &&
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        }
                      </TableCell>
                      <TableCell align="left" style={{ width: 60 }}>
                        {row.icon ? <img src={`${attachPrefix}${row.icon}`} width={32} height={32} />:'无'}
                      </TableCell>
                      <TableCell align="left"> {row.id}</TableCell>
                      <TableCell align="left">  {row.name}  </TableCell>
                      <TableCell align="left">{row.address}</TableCell>
                      <TableCell align="left">
                        {
                          row.enableEdit === '1' ?
                            <RadioInput
                              labelWidth={0}
                              value={row.status}
                              options={[
                                { label: "未启用", value: "0" },
                                { label: "启用", value: "1" }
                              ]}
                              label=""
                              margin="0"
                              onChange={(e: any, value: any) => {
                                e.preventDefault()
                                handleStatus(row.id, value)
                                return false;
                              }} /> :
                            <span>
                              {row.status === '1' ? '启用' : '未启用'}
                            </span>
                        }
                      </TableCell>
                      <TableCell align="left">{row.sorter}</TableCell>
                      <TableCell align="right" className={classes.rowToolbar}>
                        {
                          row.enableEdit === '1' && <>
                          <Tooltip title={translate('common.module.modify')} aria-label="add">
                            <IconButton 
                              className={classes.rowButton}
                              onClick={() => {
                                history.replace(`/common/module/edit?id=${row.id}`)
                              }}>
                              <EditIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                            <Tooltip title={translate('common.module.remove')} aria-label="add">
                              <IconButton 
                                className={classes.rowButton}
                                onClick={() => {
                                  handleDelete([row.id])
                                }}>
                                <DeleteIcon className={classes.icon} />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <LoadingBox {...loading} />

        {list.length === 0 && !loading.open && (<Box className="error">
          <Icon name="Error" width={32} height={32} color="gray" />
          <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
        </Box>)}
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label={translate('dense')}
      />
    </div>)

  return (<Box className={classes.root}>

    {renderPageContent}

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </Box>
  )
}
export default withRouter(ModuleListPage)