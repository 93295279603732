import React, { useState, useEffect } from 'react';

import { withRouter } from "react-router-dom";

import Box from "@material-ui/core/Box"
import { useTheme } from '@material-ui/core/styles';

import { FormTabPanel, FormTab, FormTabs } from '../../../components/ui/tabs/FormTabs';

import SubmitBar from '../../../components/ui/form/SubmitBar';
import TextInput from '../../../components/ui/form/TextInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import LoadingBox from '../../../components/ui/LoadingBox'
import { SubmitedDialog } from '../../../components/ui/dialog'

import { useEditPageStyles } from "../../../assets/jss/pages"

import { usePage } from "../../../provides/ui"
import { useLocale } from '../../../config/Locale'
import { useConfig } from '../../../provides/app'

import { Page, PageSetting } from "../../../types/ui"
import Settings from "./Settings"

const a11yProps = (index: number, label: string) => {
  return {
    index,
    label,
    ariaprefix: "page-edit",
  };
}
export default withRouter((props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { get, save } = usePage()
  const { closePage } = useConfig()

  const theme = useTheme()
  const classes = useEditPageStyles()
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  // 设置表单元素
  const [form, setForm] = useState<any>({})
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const [value, setValue] = React.useState(0);
  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载页面数据...'
    })
    get({
      id: form.id,
      success: (page: Page) => {
        // 关闭加载条
        setLoading(initialLoading)
        setForm(page)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        reloadParams()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id !== form.id) {
      resetForm()
      setFormItem('id', id)
      loadData()
    }
    return () => {
      // setForm({id:createId()})
    }
  });

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        page: form
      },
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route.path) {
        history.push(route.path)
      }
    })
  }

  const renderTitle = <Box className={classes.heading}>
    编辑页面：{form.id}({form.name})
    </Box>

  const submitBar = <SubmitBar
    {...{
      labelWidth: 120,
      onSubmit,
      padding: "0 0 0 120px",
      onCancel: () => {
        handleClose()
      },
      onReset: () => {
        loadData()
      }
    }} />
  const dialoags = <>
    <LoadingBox {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/ui/page`)
        })
      }}
      goModify={() => {
        setMessage(initialMessage)
        loadData()
      }}
    />
  </>


  const settings = <Settings
    setting={form.setting}
    onChange={(setting: PageSetting) => {
      if(setting.children){
        setting.children = setting.children.filter((item:PageSetting)=>item!=null)
      }
      setFormItem('setting', setting)
    }} 
  />

  return (<Box>
    <form onSubmit={onSubmit}>
         {form.editBase ? <>
          <Box className={classes.contentHeader}>
            <FormTabs
              variant="scrollable"
              value={value}
              onChange={handleChange}>
              <FormTab {...a11yProps(0, '页面信息')} />
              <FormTab {...a11yProps(1, '页面配置')} />
            </FormTabs>
          </Box>
          <Box className={classes.contentContainer}>
            <FormTabPanel
              ariaprefix="page-edit"
              value={value}
              index={0}
              dir={theme.direction}>
              {renderTitle}
              <Box className={classes.margin}>
                {/* 标题 */}
                <TextInput
                  labelWidth={120}
                  value={`${form.title || ''}`}
                  fullWidth
                  label="页面标题："
                  required
                  placeholder="请输入页面标题..."
                  onChange={(event: any) => {
                    setFormItem('title', event.target.value)
                  }} />

                {/* 描述 */}
                <TextInput
                  labelWidth={120}
                  value={`${form.description || ''}`}
                  fullWidth
                  label="页面描述："
                  placeholder="请页面描述description..."
                  onChange={(event: any) => {
                    setFormItem('description', event.target.value)
                  }} />
                {/* 关键字： */}
                <TextInput
                  labelWidth={120}
                  value={`${form.keywords || ''}`}
                  fullWidth
                  label="关键字："
                  placeholder="用英文逗号或空格分隔关键字..."
                  onChange={(event: any) => {
                    setFormItem('keywords', event.target.value)
                  }} />


            <RadioInput
              labelWidth={120}
              label="显示搜索类型："
              value={`${form.showSearchMode?"1":"0"}`}
              options={[
                { label: "显示", value: "1" },
                { label: "不显示", value: "0" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('showSearchMode', value==="1"?true:false)
              }} />

            <RadioInput
              labelWidth={120}
              label="设置搜索类型："
              value={`${form.searchMode}`}
              options={[
                { label: "文章搜索", value: "article" },
                { label: "商品搜索", value: "product" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('searchMode', value)
              }} />
              </Box>
            </FormTabPanel>

            <FormTabPanel
              ariaprefix="page-edit"
              value={value}
              index={1}
              dir={theme.direction}>
              {renderTitle}
              {settings}
            </FormTabPanel>
          </Box>
              </>:<Box>
              {renderTitle}
              {settings}
            </Box>
              }
      {submitBar}
    </form>
    {dialoags}
  </Box>)
})