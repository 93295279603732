import React from "react"
import Box from "@material-ui/core/Box";

// import { attachPrefix } from '../../../config';
import { OrderDetail} from "../../../types/trade" 

import { useListStyles } from "../../../assets/jss/components"

import Error from '../../../components/Error'
 
type Props = {
  detail:OrderDetail
} 
export default  (props: Props) => {
  const classes = useListStyles()
  const { detail } = props

  if(!detail.product){
  return <Box className={classes.detailItem}>
        <Error message="商品不存在，已被强制删除！"/>
    </Box>
  }
  // 进价
  let inprice = detail.product.inprice
  let image = detail.product.image
  if(detail.product.specMode === '1' && detail.productSpec && detail.productSpec.image){
    image = detail.productSpec.image
    inprice = detail.productSpec.inprice
  }
  return  <Box className={classes.productInfo}  style={{fontSize:"11px",margin:0,padding:"4px 0"}}>
        {/*<Box className={classes.image} style={{
          backgroundImage:`url(${attachPrefix}${image})`
        }}/>*/}
        <Box className={classes.title} style={{padding:0}}>
        <a target="_blank" className={classes.href}  style={{padding:0}}  href={`/shopping/product/${detail.product.id}`}>
        {detail.product.title}
        </a>
        </Box>
        {detail.product.specMode === '1' && detail.productSpec && detail.specList &&
        <Box className={classes.spec}>
        {detail.specList.map((item:any,index:number)=>
          <Box className={classes.item} key={index}>
               <span className={classes.label}>{item.spec.name}</span>:{item.specVal.value}
        </Box>)}
        </Box>}
        <Box style={{paddingLeft:8,fontWeight:600}}> ¥{detail.price}</Box>
        <Box style={{paddingLeft:8,fontWeight:600}}> x{detail.num}</Box>
        </Box>
}

