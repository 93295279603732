import { Action } from '../../actions';
import * as Actions from '../../actions/app';
import {State} from '../../state/app';

import {words} from '../../../config/lang/zh'
import { defaultTheme } from '../../../config/Themes';

export const localeNames = [
    {
      name: "简体中文",
      locale: "zh"
    },
    {
      name: "English",
      locale: "en"
    }
  ];
export const initialState: State = {
    config: {
        navs: [],
        menus: [],
        routes: [],
    },
    currentRoutes: {
        tabIndex: 0
    },
    localeConfig:{
        localeNames,
        name:'简体中文',
        locale:'zh',
        words:{
            ...words
        }
    },
    theme:defaultTheme
};

export const reducer = (state: State = initialState, action: Action): State => {
    switch (action.type) {
        case Actions.FACTORY:
            return {
                ...state,
                config: action.payload.config
            };

        case Actions.CURRENT_ROUTES:
            return {
                ...state,
                currentRoutes: action.payload.currentRoutes
            };

        case Actions.LOCALE_CONFIG:
            return {
                ...state,
                localeConfig: action.payload.localeConfig
            };

        case Actions.THEME_CONFIG:
            return {
                ...state,
                theme: action.payload.theme
            };
        default: return state;
    }
};