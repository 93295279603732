import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
    p-id="3474" width={width} height={height}>
    <path fill={color} d="M930.252361 202.301496 91.640652 202.301496c-15.421224 0-27.953655-12.527314-27.953655-27.952632 0-15.422248 12.532431-27.953655 27.953655-27.953655l838.611709 0c15.396665 0 27.952632 12.532431 27.952632 27.953655C958.206016 189.774181 945.649026 202.301496 930.252361 202.301496z" p-id="3475"></path>
    <path fill={color} d="M930.252361 370.023428 482.997967 370.023428c-15.426341 0-27.953655-12.527314-27.953655-27.953655 0-15.421224 12.527314-27.953655 27.953655-27.953655l447.255417 0c15.396665 0 27.952632 12.532431 27.952632 27.953655C958.206016 357.496114 945.649026 370.023428 930.252361 370.023428z" p-id="3476"></path>
    <path fill={color} d="M930.252361 537.745361 482.997967 537.745361c-15.426341 0-27.953655-12.554944-27.953655-27.952632 0-15.422248 12.527314-27.952632 27.953655-27.952632l447.255417 0c15.396665 0 27.952632 12.530384 27.952632 27.952632C958.206016 525.19144 945.649026 537.745361 930.252361 537.745361z" p-id="3477"></path>
    <path fill={color} d="M930.252361 705.468316 482.997967 705.468316c-15.426341 0-27.953655-12.554944-27.953655-27.952632 0-15.391549 12.527314-27.952632 27.953655-27.952632l447.255417 0c15.396665 0 27.952632 12.561084 27.952632 27.952632C958.206016 692.913373 945.649026 705.468316 930.252361 705.468316z" p-id="3478"></path>
    <path fill={color} d="M930.252361 873.196389 91.640652 873.196389c-15.421224 0-27.953655-12.56006-27.953655-27.952632 0-15.397688 12.532431-27.954679 27.953655-27.954679l838.611709 0c15.396665 0 27.952632 12.55699 27.952632 27.954679C958.206016 860.635305 945.649026 873.196389 930.252361 873.196389z" p-id="3479"></path>
    <path fill={color} d="M315.270918 705.468316c-5.922896 0-11.845793-1.910513-16.761756-5.566786L74.880943 532.179598c-7.042393-5.241374-11.192923-13.5936-11.192923-22.38687 0-8.78713 4.151553-17.115819 11.192923-22.356171l223.62822-167.723979c8.491395-6.388501 19.794835-7.396457 29.293163-2.644223 9.499351 4.77577 15.422248 14.437827 15.422248 25.00244l0 335.444888c0 10.594289-5.975085 20.313651-15.422248 25.007557C323.818595 704.490036 319.586201 705.468316 315.270918 705.468316zM138.21234 509.792729l149.1039 111.814622L287.31624 397.97913 138.21234 509.792729z" p-id="3480"></path></svg>
    )
