
import { createStyles, makeStyles,Theme } from "@material-ui/core/styles";

export const useLoadMoreStyles = makeStyles((theme:Theme) =>
  createStyles({
    loadingBox:{
      padding:theme.spacing(1),
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      "@media (max-width:650px)": {
        borderTop:"1px solid #efefef",
      }
    },
    loadingText:{
      padding:theme.spacing(0,1),
    },
    defaultBox:{
      cursor:"pointer",
      padding:theme.spacing(1),
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      "@media (max-width:650px)": {
        borderTop:"1px solid #efefef",
      }
    },
    defaultText:{
      color:"#666"
    },
    noMoreBox:{
      padding:theme.spacing(1),
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      "@media (max-width:650px)": {
        borderTop:"1px solid #efefef",
      }
    },
    noMoreText:{
      color:"#666"
    },
    errorBox:{
      padding:theme.spacing(1),
      display:"flex",
      justifyContent:"center",
      alignItems:"center",

    },
   errorText:{
      color:"#666"
    },
  }))
