import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "svg-icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="994"
    width={width} height={height}>
    <path fill={color} d="M96 192h832a32 32 0 0 1 0 64H96a32 32 0 1 1 0-64z m0 192h832a32 32 0 0 1 0 64H96a32 32 0 1 1 0-64z m0 192h832a32 32 0 0 1 0 64H96a32 32 0 0 1 0-64z m0 192h832a32 32 0 0 1 0 64H96a32 32 0 0 1 0-64z" p-id="995"></path></svg>
    )