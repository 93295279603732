import * as actions from '../../store/actions/common/friendUrl'
import { useDispatch } from "react-redux";
/**
 * 友情链接
 */
export const useFriendUrl = () => {
  const dispatch:Function = useDispatch();

  // 获取列表
  const getList = (props: any) => {
    const { success, failure } = props

    dispatch(actions.fetchList()).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        const list = data.result.list
        const pagination = data.result.pagination
        // 通知Redux
        dispatch(actions.fetchListSucess(list, pagination))
        // 成功返回
        if (success) {
          success({
            list,
            pagination,
            message: message || '请求成功'
          })
        }
      } else {
        // 失败
        const error = {
          code: data && data.code || 0,
          message: message || '请求失败'
        }
        dispatch(actions.fetchListFailure(error))
        // 失败返回
        failure(message || '请求失败')
      }
    })
  }
  
  // 删除
  const remove = (props: any) => {
    const { success, failure, id } = props
    // 提交redux
    dispatch(actions.remove(id)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        if (success) {
          success(data.message)
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '删除失败!')
      }
    })
  }

  // 保存
  const save= (props: any) => {
    const { success, failure, data } = props
    // 提交redux 
    dispatch(actions.save(data)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        // 成功返回
        if (success) {
          success(data.message || '保存成功')
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '添加失败')
      }
    })
  }

  // 设置状态
  const setStatus= (props: any) => {
    const { success, failure, id,status } = props
    // 提交redux 
    dispatch(actions.setStatus( id,status)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        // 成功返回
        if (success) {
          success(data.message || '保存成功')
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '添加失败')
      }
    })
  }
  
  // 返回实时信息提交页面处理
  return {
    getList,
    save,
    remove,
    setStatus
  }
}
