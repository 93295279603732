import React, { useState } from 'react'

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextInput from '../../ui/form/TextInput'
import FormField from '../../ui/form/FormField';

import Icon from '../../Icon'
import AddIcon from '@material-ui/icons/Add';

import { useLocale } from '../../../config/Locale'
import { useAttr } from '../../../provides/shopping/AttrProvide'

import { FormProps } from './types'

export default (props: FormProps) => {
    const { translate } = useLocale();
    const { saveList } = useAttr()
    const {
        classes,
        catId,
        onHide,
        onSaved,
        onSavedSuccess,
        onSavedFailure
    } = props

    const [attrs, setAttrs] = useState<any>([])
    const [addError, setAddError] = useState('')

    // 添加属性表单
    const initialForm = {
        name: '',
        value: ''
    }
    const [form, setForm] = useState<any>(initialForm)
    const setFormItem = (key: string, value: string) => {
        const newForm = form
        newForm[key] = value;
        setForm(Object.assign({}, newForm))
    }
    /**
     * 添加校验
     */
    const onAddCheck = () => {
        if (form.name === "") {
            setAddError("请填写属性名称");
            return false;
        }
        if (form.value === "") {
            setAddError("请填写属性值");
            return false;
        }
        const result = attrs.filter((attr: any) => attr.name === form.name && attr.value === form.value);
        if (result.length > 0) {
            setAddError('属性值已存在')
            return false;
        }
        setAddError("");
        return true;
    }
    // 添加属性
    const onAdd = (e: any) => {
        e.preventDefault()
        if (!onAddCheck()) {
            return
        }
        attrs.push(Object.assign({}, form))
        setAttrs(Object.assign([], attrs))
        setFormItem('value', '');
    }
    // 删除属性
    const onRemove = (index: number) => {
        attrs.splice(index, 1)
        setAttrs(Object.assign([], attrs))
    }
    // 取消
    const cancel = () => {
        onHide()
    }
    // 保存属性列表
    const saveAttrs = () => {
        // 保存属性列表
        onSaved({
            open: true,
            text: translate('shopping.saveAttrs')
        })
        saveList({
            data: {
                attrs,
                catId,
                status: ''
            },
            success: (message: string) => {
                // 关闭加载条
                onSavedSuccess(message)
                setAttrs([])
            },
            failure: (message: string) => {
                // 关闭加载条
                onSavedFailure(message)
            }
        })
    }
    const renderInputs = (
        <Box>
            <TextInput
                labelWidth={120}
                value={`${form.name}`}
                label="属性名称："
                onChange={(event: any) => {
                    setFormItem('name', event.target.value)
                }} />
            <Box className={classes.row}>
                <TextInput
                    labelWidth={120}
                    value={`${form.value}`}
                    label="属性值："
                    onChange={(event: any) => {
                        setFormItem('value', event.target.value)
                    }} />
                <Button
                    variant="outlined"
                    onMouseDown={onAdd}
                    size="small"
                    className={classes.button}>
                    <AddIcon className={classes.icon} />
                    添加
                    </Button>
            </Box>
            {
                addError && (
                    <FormField
                        label=""
                        labelWidth={120}>
                        <Box className={classes.error}>
                            <Icon name="Wrong" className={classes.icon} color="#ff0000" />
                            {addError}
                        </Box>
                    </FormField>
                )
            }
        </Box>
    )

    const renderAttrs = (<>
        {attrs.length > 0 &&
            <Box className={classes.aBoxContainer}>
                {
                    // 待添加的键值对属性列表 
                    attrs.map((item: any, index: number) => (
                        <a
                            className={classes.aBox}
                            key={index}
                            onMouseDown={() => {
                                onRemove(index)
                            }}>
                            <span>{item.name}</span>
                            <span>：</span>
                            <span className={classes.aBoxValue}>{item.value}</span>
                            <span className={classes.aBoxClose}>
                                <Icon name="Close" color="#999999" />
                            </span>
                        </a>))
                }
            </Box>
        }</>)

    const renderSubmitBar = (
        <FormField
            label=""
            labelWidth={120}>
            {attrs.length > 0 &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveAttrs}
                    size="small"
                    className={classes.button}>
                    <Icon name="Save" className={classes.icon} color='#fff' />保存
            </Button>
            }
            <Button
                variant="outlined"
                onClick={cancel} size="small"
                className={classes.button}>
                <Icon name="Cancel" className={classes.icon} />取消
        </Button>
        </FormField>)

    return (
        <Paper className={classes.paper}>
            <Box className={classes.form}>
                {renderInputs}
                {renderAttrs}
                {renderSubmitBar}
            </Box>
        </Paper>)
}