import React, { useState, useRef } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Icon from '../../Icon'
import Collapse from '@material-ui/core/Collapse';
import MoreVertIcon from '@material-ui/icons/MoreVertOutlined';
import ExpandMore from '@material-ui/icons/ExpandMoreOutlined';

import { useLocale } from '../../../config/Locale'

import { SubmitBarProps } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      bottom: 20,
      right: 20,
      background: 'transparent',
      zIndex: 98,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTop: '1px solid transparent',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap'
    },
    label: {
      textAlign: 'right',
      flexWrap: 'nowrap'
    },
    buttons: {
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      flexWrap: 'wrap',
      userSelect: 'none'
    },
    sideContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: 99,
      right: 40,
      bottom: 64,
    },
    sideToolbar: {
      display: 'flex',
      flexDirection: 'column',
    },
    fab: {
      margin: ' 0 0 10px 0',
      padding: theme.spacing(0),
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    iconButton: {
      padding: 8
    },
    icon: {
      width: 22,
      height: 22,
      margin: 0
    },
    collapse: {
      padding: 0
    },
    switchBox: {
      justifyContent: 'center'
    },
    button: {
      margin: theme.spacing(0, 1, 0, 0),
      alignItems: 'center',
      display: 'flex'
    },
    buttonIcon: {
      margin: '0 5px 0 0',
    }
  }),
);

const SubmitBar = (props: SubmitBarProps) => {
  const classes = useStyles()
  const { translate } = useLocale()
  const { onSubmit, onCancel, onReset, label, labelWidth,disableScroll } = props

  const labelStyle: React.CSSProperties = labelWidth ? {
    width: labelWidth
  } : {};

  const ref = useRef(null as any);

  const [scrolled, setScolled] = useState(false)
  React.useEffect(() => {
    const dom = document.getElementById('screenMainContainer')
    if (dom) {
      setScolled(!disableScroll && dom.scrollHeight > dom.clientHeight)
    }
    return () => {
    }
  })

  const [collapse, setCollapse] = React.useState(true);

  function handleCollapse() {
    setCollapse(prev => !prev);
  }
  const sideRender = (<Box className={classes.sideContainer}>
    <Box>
      <Collapse in={collapse} className={classes.collapse}>
        <Box className={classes.sideToolbar}>
          {/* 提交按钮 */}

          <Fab color="primary"
            className={classes.fab}
            onClick={onSubmit}>
            <Icon name="Save" width={16} color="#fff" height={16} />
          </Fab>

          {/* 取消按钮 */}
          {onCancel &&
            <Fab color="default"
              className={classes.fab}
              onClick={onCancel}>
              <Icon name="Cancel" width={16} color="#333" height={16} />
            </Fab>}

          {/* 重置按钮 */}
          {onReset &&
            <Fab color="default"
              className={classes.fab}
              onClick={onReset}>
              <Icon name="Reset" width={16} color="#333" height={16} />
            </Fab>}
        </Box>
      </Collapse>
    </Box>
    <Box className={classes.switchBox}>
      {/* <Tooltip title={collapse?translate('collapse.close'):translate('collapse.open')}> */}
      <IconButton
        onClick={handleCollapse}
        className={classes.iconButton}>
        {collapse ?
          <ExpandMore className={classes.icon} /> :
          <MoreVertIcon className={classes.icon} />
        }
      </IconButton>
      {/* </Tooltip> */}
    </Box>
  </Box>
  )
  const defaultRender = (
    <Box className={classes.root}>
      {/* 向左缩进 */}
      <Box component="span"
        style={labelStyle}
        className={classes.label}>
        {label}
      </Box>
      <Box component="span" className={classes.buttons}>
        {/* 提交按钮 */}
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          className={classes.button}>
          <Icon name="Save" className={classes.buttonIcon} color="#fff" />
          {translate('save')}
        </Button>
        {/* 取消按钮 */}
        {onCancel &&
          <Button
            size="small"
            color="default"
            variant="outlined"
            onClick={onCancel}
            className={classes.button}>
            <Icon name="Cancel" className={classes.buttonIcon} color="#666" />
            {translate('cancel')}
          </Button>}
        {/* 重置按钮 */}
        {onReset &&
          <Button
            size="small"
            color="default"
            variant="outlined"
            onClick={onReset}
            className={classes.button}>
            <Icon name="Reset" className={classes.buttonIcon} color="#666" />
            {translate('reset')}
          </Button>}
      </Box>
    </Box>
  )

  return (
    <FormControl ref={ref as any}>
      {defaultRender}
      {scrolled && sideRender}
    </FormControl>)
}
export default SubmitBar