import { Action } from '../../actions';
import * as Actions from '../../actions/common/module';
import * as State from '../../state/common';

export const initialState: State.Module = {
};

export const reducer = (state: State.Module = initialState, action: Action): State.Module => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return Object.assign({
                ...state,
                list: action.payload.list
            });

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                list:[],
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                module: action.payload.module
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default: return state;
    }
};