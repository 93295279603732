
const drawerWidth = 260;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const conatinerFluid = {
  padding: 0,
  margin: "0 auto"
};

const container = {
  ...conatinerFluid,
  "@media (max-width:650px)": {
    width: "100%",
  },
  "@media (min-width:680px)": {
    width: "100%",
  },
  "@media (min-width:960px)": {
    width: "1000px",
  },
  "@media (min-width:1200px)": {
    width: "1200px",
  },
}

const boxShadow = {
  boxShadow:
    "0 5px 20px -6px rgba(225, 225, 225, 0.42), 0 2px 15px 0px rgba(225, 225, 225, 0.12), 0 4px 5px -2px rgba(225, 225, 225, 0.2)",
   };

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "3px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff"
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 300,
  lineHeight: "1.5em"
};

const scrolling = {
  overflow: "auto",
  MsScrollChaining: "chained",
  MsOverflowStyle: "none",
  MsContentZooming: "zoom",
  MsScrollRails: "none",
  MsContentZoomLimitMin: "100%",
  MsContentZoomLimitMax: "500%",
  MsScrollSnapPointsX: "snapList(100%, 200%, 300%, 400%, 500%)",
  /*webkit内核*/
  "&::-webkit-scrollbar": {
    width: 0,
    height: 0, 
  },
  "&::-webkit-scrollbar-button": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "&::-webkit-scrollbar-track-piece": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "&::-webkit-scrollbar-thumb" :{
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "&::-webkit-scrollbar-corner": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
  "&::-webkit-scrollbar-resizer": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  }
}

const primaryColor = "#333";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999"

const whiteFontColor = {
  color: "#eee",
  '& a': {
    color: "#eee",
    textDecoration: "none",
    '&:hover': {
      color: "#fff",
      textDecoration: "none",
    }
  }
}
const grayFontColor = {
  color: "#666",
  '& a': {
    color: "#666",
    textDecoration: "none",
    '&:hover': {
      color: "#000",
      textDecoration: "none",
    }
  }
}
const blackFontColor = {
  color: "#333",
  '& a': {
    color: "#333",
    textDecoration: "none",
    '&:hover': {
      color: "#000",
      textDecoration: "none",
    }
  }
}
const greenFontColor = {
  color: "#006600",
  '& a': {
    color: "#006600",
    textDecoration: "none",
    '&:hover': {
      color: "#009900",
      textDecoration: "none",
    }
  }
}
const blueFontColor = {
  color: "#0B5FA4",
  '& a': {
    color: "#0B5FA4",
    textDecoration: "none",
    '&:hover': {
      color: "#3f8fd2",
      textDecoration: "none",
    }
  }
}
const redFontColor = {
  color: "#ff3300",
  '& a': {
    color: "#ff3300",
    textDecoration: "none",
    '&:hover': {
      color: "#cc0033",
      textDecoration: "none",
    }
  }
}
const yellowFontColor = {
  color: "#cc6600",
  '& a': {
    color: "#cc6600",
    textDecoration: "none",
    '&:hover': {
      color: "#ff9933",
      textDecoration: "none",
    }
  }
}

const whiteBorderTitleText={
  borderBottom:"3px solid #eee"
}
const blackBorderTitleText={
  borderBottom:"3px solid #333"
}

const grayBorderTitleText={
  borderBottom:"3px solid #666"
}

const blueBorderTitleText={
  borderBottom:"3px solid #0B5FA4"
}

const redBorderTitleText={
  borderBottom:"3px solid #ff3300"
}

const greenBorderTitleText={
  borderBottom:"3px solid #006600"
}

const yellowBorderTitleText={
  borderBottom:"3px solid rgb(245, 165, 16)"
}

const left = {
  float: "left",
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}
const right = {
  float: "right",
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}
const clearfix = {
  clear: "both"
}

const primaryBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)"
};
const infoBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)"
};
const successBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)"
};
const warningBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)"
};
const dangerBoxShadow = {
  boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)"
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
};

const warningCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  ...warningBoxShadow
};
const successCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  ...successBoxShadow
};
const dangerCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  ...dangerBoxShadow
};
const infoCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  ...infoBoxShadow
};
const primaryCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
  ...primaryBoxShadow
};
const roseCardHeader = {
  color: "#fff",
  background: "linear-gradient(60deg, #ec407a, #d81b60)",
  ...roseBoxShadow
};
const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid #eeeeee",
  height: "auto",
  ...defaultFont
};

const cardHeader = {
  margin: "-30px 15px 0",
  borderRadius: "3px",
  padding: "15px"
};

const defaultBoxShadow = {
  border: 0,
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s"
};

const title = {
  color: "#3C4858",
  margin: "1.75rem 0 0.875rem",
  textDecoration: "none",
  fontWeight: "700",
  fontFamily: `"Roboto Slab", "Times New Roman", serif`
};

const cardTitle = {
  ...title,
  marginTop: ".625rem"
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem"
  }
};

const cardSubtitle = {
  marginBottom: 0,
  marginTop: "-.375rem"
};
const grow = {
  flexGrow: 1
}

const more = {
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  '& a': {
    marginLeft: 10
  },
  '& svg': {
    padding: 0,
    margin: 0,
    width: 12,
    height: 12,
  }
}

const itemPrice = {
  fontWeight: 600,
  fontSize: '18px',
  color: '#ff6600'
}
const itemOPrice = {
  fontSize: '12px',
  marginLeft: 10,
  fontStyle: 'italic',
  textDecoration: 'line-through',
  color: '#999'
}

const border = {
  borderRadius: 4,
  border: "1px solid rgba(175,175,175,.3)",
}
const backgroundTitle = {
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  backgroundColor: 'rgba(175,185,182,0.08)',
  //background: "-webkit-linear-gradient(#fafafa 70%,#f3f3f3);  -o-linear-gradient(#fafafa 70%,#f3f3f3);   -moz-linear-gradient(#fafafa 70%,#f3f3f3);  linear-gradient(#fafafa 70%,#f3f3f3);",
  borderBottom: "1px solid #ddd",
}
const borderTitle = {
  borderBottom:"1px solid #f0f0f0",
}

const price = {
  color: "#333",
  fontSize: "1em",
  fontFamily: "impact"
}
const highlightPrice = {
  color: "#ff6600",
  fontSize: "1.5em",
  fontFamily: "impact"
}
const oprice = {
  color: "#999",
  fontSize: "1em",
  marginLeft: "5px",
  fontStyle: "italic",
  fontFamily: "Verdana,Arial",
  fontWeight: 600,
  textDecoration: "line-through"
}
const discount = {
  color: "#999",
  fontSize: "1em",
  marginLeft: "5px",
  fontFamily: "Verdana,Arial",
  fontWeight: 300,
}

const rmb = {
  fontSize: ".5em",
}

const rmbBig = {
  fontSize: "1.5em",
}

const sectionTitle = {
  paddingBottom: "16px",
  width: '100%',
  fontSize: '1.5em',
  display: 'flex',
  alignItems: 'center'
}
const sectionIcon = {
  fontSize: '1.5em',
  maxWidth: "100%",
  maxHeight: "22px"
}

const backgroundImageStyles = {
  backgroundRepeat:'no-repeat',
  backgroundPosition: "50%",
  backgroundSize: "auto 100%",
    transition: 'all 200ms linear',
  '&:hover':{
    /* 设置默认样式，开启3d硬件加速 */
    WebkitTransform:"translate3d(0,0,0)",
    MozTransform:"translate3d(0,0,0)",
    transform:"translate3d(0,0,0)",
    transition: 'all 300ms linear',
    backgroundSize: "auto 110%",
  }
}

const arrowDownIcon={
  transition: "all 150ms ease-in", 
  transform: "rotate(-90deg)",
  maxWidth:16,
  maxHeight:16,
  margin:0,
  padding:0,
}
const arrowDownIconActive={
  transform: "rotate(0deg)"
}

const toolbar ={
  margin:0,
  padding:0,
  width:"100%",
  display:"flex",
}

export {
  //variables
  drawerWidth,
  transition,
  conatinerFluid,
  container,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  title,
  cardTitle,
  cardLink,
  cardSubtitle,
  left,
  right,
  clearfix,
  grow,
  more,
  itemPrice,
  itemOPrice,
  border,
  backgroundTitle,
  borderTitle,
  oprice,
  highlightPrice,
  price,
  discount,
  rmb,
  rmbBig,
  whiteFontColor,
  grayFontColor,
  blackFontColor,
  greenFontColor,
  blueFontColor,
  redFontColor,
  yellowFontColor,
  sectionTitle,
  sectionIcon,
  whiteBorderTitleText,
  blackBorderTitleText,
  grayBorderTitleText,
  blueBorderTitleText,
  redBorderTitleText,
  greenBorderTitleText,
  yellowBorderTitleText,
  backgroundImageStyles,
  scrolling,
  arrowDownIcon,
  arrowDownIconActive,
  toolbar
};
