


import React from 'react'
import createStyles from './createStyles'
import snakeCase from 'lodash.snakecase';

export const link:React.CSSProperties = {
    color: 'blue',
    textDecoration: 'underline',
};
export const code: React.CSSProperties = {
    maxWidth:'100%',
    whiteSpace:'pre-wrap',
    wordWrap:'break-word',
    overflow:'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    padding: '3px 5px',
    borderRadius: 5,
    fontSize:'14px',
    fontFamily:'Verdana'
};
export const blockquote: React.CSSProperties = {
    maxWidth:'100%',
    borderLeft: '3px solid #eee',
    padding: '3px 5px'
};

export const UNDERLINE: React.CSSProperties = {
    textDecoration:'underline'
};
export const STRIKETHROUGH: React.CSSProperties = {
    textDecoration:'line-through'
};
export const BOLD: React.CSSProperties = {
    fontWeight:'bold'
};
export const ITALIC: React.CSSProperties = {
    fontStyle:'italic'
};

export const STYLE_PREFIX = 'STYLE'
export const styleMap = {
    UNDERLINE,
    STRIKETHROUGH,
    ITALIC,
    BOLD
};

export const styleNames = [
    'fontSize',
    'color',
    'backgroundColor',
    'background',
    'fontFamily',
    'lineHeight',
    'fontSyle',
    'fontWeight',
    'padding',
    'margin',
    'width',
    'height',
    'minWidth',
    'minHeight',
    'animation',
    'pointerEvents',
    'transform',
    'opacity',
    'zIndex',
    'justifyContent',
    'alignItems',
    'float',
    'overflow',
    'position',
    'left',
    'top',
    'right',
    'bottom',
    'textAlign',
    'textDecoration',
    'fontStyle',
    'whiteSpace',
    'border',
    'borderRadius',
    'boxShadow',
    'display',
    'flex',
    'flexDirection',
    'flexWrap',
    'borderColor',
    'borderStyle',
    'outline',
    'boxSizing',
    'italic',
    'cursor',
    'webkitTextStrokeWidth',
    'webkitTapHighlightColor']

const creater = createStyles([
    'font-size',
    'color',
    'background-color',
    'background',
    'font-family',
    'line-height',
    'font-style',
    'font-weight',
    'padding',
    'margin',
    'width',
    'height',
    'min-width',
    'min-height',
    'animation',
    'pointer-events',
    'transform',
    'opacity',
    'z-index',
    'justify-content',
    'align-items',
    'float',
    'overflow',
    'position',
    'left',
    'top',
    'right',
    'bottom',
    'text-align',
    'text-decoration',
    'font-style',
    'white-space',
    'border',
    'border-radius',
    'box-shadow',
    'display',
    'flex',
    'flex-direction',
    'flex-wrap',
    'border-color',
    'border-style',
    'outline',
    'box-sizing',
    'italic',
    'cursor',
    'webkit-text-stroke-width',
    'webkit-tap-highlight-color'
], STYLE_PREFIX, styleMap);

export const styleFn = creater.customStyleFn
export const styles = creater.styles
export const exporter = creater.exporter
export const cssProperies = creater.cssProperies
export const getCurrentStyle = creater.getCurrentStyle


// custom styles
export const convertStyle = (style: string) => {
    if (style && cssProperies) {
        const result = cssProperies({}, style)
        const css = result[style]
        if (css) {
            return css.style;
        }
    }
}

export const cssToHtml = (css: any) => {
    let style = ''
    if(css)
    Object.keys(css).forEach((s: string) => {
        const v = css[s]
        if (v) {
            style += (style ? ';' : '') + snakeCase(s).replace('_', '-') + ':' + v
        }
    })
    return style
}
export const parseStyleHtml = (data: any) => {
    let style = ''
    if(data)
    styleNames.forEach((s: string) => {
        const v = data.get(s)
        if (v) {
            style += (style ? ';' : '') + snakeCase(s).replace('_', '-') + ':' + v
        }
    })
    return style
}

