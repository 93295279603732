import React, { useState, useEffect } from 'react';

import { EditorState } from 'draft-js';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OptionModal from './OptionModal'

import { styles, getCurrentStyle } from '../renders'

import * as types from './types'

const Select = (props: any) => {
    const {
        onChange,
        editorState,
        title,
        options,
        type
    } = props
    const [currentValue, setCurrentValue] = useState('')
    const query = () => {
        if (getCurrentStyle) {
            const value = getCurrentStyle(editorState, type);
            setCurrentValue(( options.indexOf(value) > 0 ?value :'') || '')
        } else {
            setCurrentValue('')
        }
    }
    useEffect(query)


    const anchorRef = React.useRef(null as any);
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    function handleToggle() {
        setAnchorEl(anchorEl ? null : anchorRef.current);
    }


    const toggle = (event: any, value: string) => {
        event.preventDefault();
        const nextContentState = editorState.getCurrentContent()
        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );
        nextEditorState = styles[type].toggle(editorState, `${value}`);
        onChange(nextEditorState);
    }

    const renderModal = () => {
        const modalOptions: any = []
        if (!options)
            return
        options.forEach((option: string) => {
            modalOptions.push({
                label: option,
                value: option
            })
        })
        return <OptionModal {...{
            styleName: 'fontFamily',
            anchorEl,
            value: currentValue,
            options: modalOptions,
            select: (event: any, option: types.Option) => {
                toggle(event, option.value)
                setAnchorEl(null);
            },
            cancel: () => {
                setAnchorEl(null);
            }
        }}>
        </OptionModal>
    }

    return (<>
        <ButtonGroup
            className="toolbar-button-group"
            ref={anchorRef as any} >
            <Button
                size="small"
                onMouseDown={handleToggle}
                className="toolbar-button-toogle-button"
                title={title[0]} >
                {currentValue || title[0]}
                <ArrowDropDownIcon style={{width:16,height:16}} />
            </Button>
        </ButtonGroup>
        {renderModal()}
    </>
    );

}

export default Select