import React, { useState, useEffect } from 'react'

 // 日期控件
 import "moment/locale/zh-cn";
//  import moment from 'moment';
 import DateRangePicker from 'react-bootstrap-daterangepicker';
 // you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
//  import { DayPickerRangeController,DateRangePicker,DateRangePickerPhrases } from 'react-dates';

import { stableSort, getSorting } from '../../../lib/sort'
import { createId, formatDateTime2,formatNumber,formatDate } from '../../../lib/util'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconClear from '@material-ui/icons/Clear';
import Icon from '../../../components/Icon'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableRow, BodyTableCell, TableHead } from '../../../components/ui/table'
import OrderStatusInput from '../../../components/ui/form/OrderStatusInput';
// 工具栏
import PageToolbar from '../../../components/ui/PageToolbar'

import { withRouter } from "react-router-dom";
import { useLocale } from '../../../config/Locale'
import { useOrder } from '../../../provides/trade'
import { useRefund } from '../../../provides/trade'

import { useListPageStyles } from '../../../assets/jss/pages'
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, AlertDialog,BaseDialog } from '../../../components/ui/dialog'
import ListPagination from '../../../components/ui/pagination'
import * as Config  from '../../../config';

import OrderDetails from './OrderDetails'
import SendList from './SendList'
import Send from './Send'
import RefundDialog from './RefundDialog'

import { useSend } from '../../../provides/trade'
import { useSysState } from '../../../provides/sys'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

interface IRequestParams {
  keyword?: string;// 关键字
  status?: string;// 分类ID
  username?:string;
  productName?:string;
  areaName?:string;
  endTime?: string;// 结束时间
  startTime?: string;// 开始时间
  page?: number;// 当前页号
  pageSize?: number// 每页条数
}

const fields = [
  { id: '', numeric: false, disablePadding: false, label: '' },
  { id: 'no', numeric: false, disablePadding: false, label: '订单号' },
  { id: 'amount', numeric: false, disablePadding: true, label: '订单金额' },
  { id: 'username', numeric: true, disablePadding: false, label: '用户' },
  { id: 'paymode', numeric: true, disablePadding: false, label: '支付方式' },
  { id: 'createTime', numeric: false, disablePadding: false, label: '创建时间' },
  { id: 'updateTime', numeric: false, disablePadding: false, label: '最新修改' },
  { id: 'status', numeric: false, disablePadding: false, label: '状态' },
];

type StatusProps = {
  status:string,
  onSend:()=>void,
  onRefund:()=>void,
  onRefundQuery:()=>void
}

const OrderStatus = (props:StatusProps)=>{
  const {status,onSend,onRefund,onRefundQuery} = props;
  const classes = useListPageStyles()
  switch (status) {
        /** 生成订单 */
      case Config.ORDER_STATUS_CREATE :
          return <>生成订单</>
        /** 订单待付款 */
      case Config.ORDER_STATUS_PAY_WAIT :
          return <>待付款</>
        /** 订单已付款 */
      case Config.ORDER_STATUS_PAY_SUCCESS  :
          return <>
          <span className={classes.marginRight} style={{color:"green"}}>已付款</span>
          <Button size="small" color="primary" onClick={()=>onSend()}>发货</Button>
          </>
        /** 订单付款失败 */
      case Config.ORDER_STATUS_PAY_FAIL :
          return <>付款失败</>
        /** 订单已发货，在途 */
      case Config.ORDER_STATUS_SEND_ONWAY :
          return <>
          <span className={classes.marginRight} style={{color:"green"}}>已发货</span>
          <Button size="small"  onClick={()=>onSend()}>继续发货</Button>
          </>
        /** 订单已到货 */
      case Config.ORDER_STATUS_SEND_DEST :
          return <>
          <span className={classes.marginRight} style={{color:"green"}}>已到货</span>
        {/*  <Button size="small"  onClick={()=>onSend()}>继续发货</Button>*/}
          </>
        /** 订单已收货 */
      case Config.ORDER_STATUS_SEND_OK :
          return <>
          <span className={classes.marginRight} style={{color:"red"}}>已收货</span>
          </>
        /** 订单已评价 */
      case Config.ORDER_STATUS_COMMENTED :
          return <>已评价</>
        
        /** 订单拒收货 */
      case Config.ORDER_STATUS_REJECT :
          return <>拒收货</>
        /** 订单拒收退货在途 */
      case Config.ORDER_STATUS_REJECT_ONWAY :
          return <>拒收退货在途</>
        /** 订单拒收退货到途 */
      case Config.ORDER_STATUS_REJECT_DEST :
          return <>拒收退货到途</>
        /** 订单拒收资金退回 */
      case Config.ORDER_STATUS_REJECT_RETURN_FUND  :
          return <>拒收资金退回</>
        /** 订单拒收完成／资金到帐 */
      case Config.ORDER_STATUS_REJECT_OK :
          return <>拒收资金到帐</>
        
        /** 订单退款申请 */
      case Config.ORDER_STATUS_REFUND  :
          return <>
          <span className={classes.marginRight}>退款申请中</span>
          <Button size="small" color="primary" onClick={onRefund}>处理退款申请</Button>
          </>
        /** 订单退款申请通过 */
      case Config.ORDER_STATUS_REFUND_APPLY_OK  :
          return <>退款申请通过
          <Button size="small" color="primary" onClick={onRefund}>处理退款</Button>
        </>
        /** 订单退款申请不通过 */
      case Config.ORDER_STATUS_REFUND_APPLY_FAIL :
          return <>退款申请不通过
          <Button size="small" color="primary" onClick={onRefund}>查看退款</Button>
     </>
        /** 订单退款退货在途 */
      case Config.ORDER_STATUS_REFUND_ONWAY  :
          return <>退货在途
          <Button size="small" color="primary" onClick={onRefund}>查看退款</Button>
          <Button 
          size="small" 
          color="primary" 
          onClick={onRefundQuery}>刷新退款</Button>
       </>
        /** 订单退款退货到途 */
      case Config.ORDER_STATUS_REFUND_DEST  :
          return <>退货到途
          <Button size="small" color="primary" onClick={onRefund}>查看退款</Button>
          <Button 
          size="small" 
          color="primary" 
          onClick={onRefundQuery}>刷新退款</Button>
       </>
        /** 订单退款资金退回 */
      case Config.ORDER_STATUS_REFUND_RETURN_FUND  :
          return <>
          退款资金退回
          <Button size="small" color="primary" onClick={onRefund}>查看退款</Button>
          <Button 
            size="small" 
            color="primary" 
            onClick={onRefundQuery}>刷新退款</Button>
      </>
        /** 订单退款完成／资金到帐 */
      case Config.ORDER_STATUS_REFUND_OK :
          return <>
          退款资金到帐
          <Button size="small" color="primary" onClick={onRefund}>查看退款</Button>
      </>
        
        /** 订单取消 */
      case Config.ORDER_STATUS_CANCEL :
          return <>订单已取消</>

        /** 订单完成 */
      case Config.ORDER_STATUS_OK :
          return <>订单已完成</>
            
    default:
      break;
  }
  return <></>
}

const EnhancedPageToolbar = (props: any) => {

  const { translate } = useLocale()
  const { 
    status, 
    startDate,
    endDate,
    onDatesChange,
    onStatusChange, 
    onRefresh, 
    keyword, 
    onSearch 
  } = props;

  // const [focusedInput,setFocusedInput] = useState<any>()

  const title = <span>
    {translate('trade.order.pageTitle')}
  </span>

            // <SingleDatePicker
            // openDirection="down"
            // numberOfMonths={1}
            // readOnly={false}
            // enableOutsideDays={true}
            // date={startDate && moment(startDate)}
            // onDateChange={(date:any) => onDatesChange('startDate', date)}
            // isOutsideRange={() => false}
            // isDayBlocked={() => false}
            // focused={focusedInput}
            // onFocusChange={(focusedInput:any) => setFocusedInput(focusedInput)}
            // />

  return <PageToolbar
    {...{
      title,
      keyword,
      onSearch,
      onRefresh,
      tools:<>
      <Box style={{display:"flex",marginLeft:12,marginRight:12,alignItems:"center"}}>
      <span style={{whiteSpace:"nowrap"}}>
      日期：
      </span>
      <DateRangePicker
          initialSettings={{ 
            startDatePlaceholderText:"起始日期",
             startDate,
             endDate,
             maxDate:new Date(),
             applyButtonClasses:"date-picker-apply-button"
          }}
          onApply={(event:any, picker:any)=>{
              onDatesChange(picker)
          }}
      >
        <input type="text" className="date-picker-form-control" />
      </DateRangePicker>
      {/*
      <DateRangePicker
            small
            withPortal
            startDatePlaceholderText="起始日期"
            endDatePlaceholderText="截止日期"
            startDate={startDate &&  moment(startDate)} // momentPropTypes.momentObj or null,
            startDateId="startDate" // PropTypes.string.isRequired,
            endDate={endDate ? moment(endDate):moment()} // momentPropTypes.momentObj or null,
            endDateId="endDate" // PropTypes.string.isRequired,
            onDatesChange={onDatesChange} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput:any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
            displayFormat="yyyy-MM-DD"
            renderCalendarInfo={()=>{
              
            }}
        />*/}

          {/*<DayPickerRangeController
            small
            startDatePlaceholderText="起始日期"
            endDatePlaceholderText="截止日期"
            startDate={startDate && moment(startDate)} // momentPropTypes.momentObj or null,
            startDateId="startDate" // PropTypes.string.isRequired,
            endDate={endDate ? moment(endDate):moment()} // momentPropTypes.momentObj or null,
            endDateId="endDate" // PropTypes.string.isRequired,
            onDatesChange={onDatesChange} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput:any) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
            displayFormat="yyyy-MM-DD"
            openDirection="down"
            maxDate={moment()}/>*/}
          <IconButton size="small" onClick={()=>{onDatesChange({})}}><IconClear/></IconButton>
      </Box>
      
        
      <OrderStatusInput
        labelWidth={0}
        label="状态："
        value={`${status || ''}`}
        onChange={(value: any) => {
          onStatusChange(value)
        }} />
        </>
    }} />
};

const Row = (props:any)=>{
  const { 
    row,
    onSend,
    onWxQuery,
    onAlipayQuery,
    onEditSend,
    onRemoveSend,
    onRefund,
    onRefundQuery
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useListPageStyles()
  const rowClasses = useRowStyles();
   return (<React.Fragment>
      <TableRow className={rowClasses.root}>
                  <BodyTableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </BodyTableCell>
                    <BodyTableCell align="left">{row.no} </BodyTableCell>
                    <BodyTableCell align="left" style={{color:"#ff6600",fontWeight:600}}>¥{formatNumber(row.amount)}</BodyTableCell>
                    <BodyTableCell align="left">{row.username}</BodyTableCell>
                    <BodyTableCell align="left">
                      {row.paymode === 'weixin' && '微信'}
                      {row.paymode === 'alipay' && '支付宝'}
                    </BodyTableCell>
                    <BodyTableCell align="left">{formatDateTime2(row.createTime)}</BodyTableCell>
                    <BodyTableCell align="left">{formatDateTime2(row.updateTime)}</BodyTableCell>
                    <BodyTableCell align="left">
                    <OrderStatus 
                    status={row.status} 
                    onSend={onSend} 
                    onRefund={onRefund}
                    onRefundQuery={onRefundQuery}/>
                    {(row.status === '0' || row.status ==='1' || row.status === '3')  && <Button 
                      size="small" 
                      color="primary" 
                      onClick={()=>{
                          if(row.paymode === 'weixin' ){
                              onWxQuery()
                          }else if(row.paymode === 'alipay'){
                              onAlipayQuery()
                          }
                        }}>刷新</Button>}
                    </BodyTableCell>
        </TableRow>

        <TableRow>
          <BodyTableCell style={{ paddingBottom: 0, paddingTop: 0 }}  colSpan={8}>
                  <Collapse in={open} timeout="auto" unmountOnExit>

                   {row.receAddr && <Box margin={1} style={{border:'1px solid #dedede',borderRadius:4}}> 
                      <Box className={classes.blockTitle}>
                          <Box className={classes.blockTitleLabel}>收货人信息</Box>
                      </Box>
                      <Box className={classes.blockRows}>
                        <Box className={classes.wordRow}> 
                        <span className={classes.label}>收货地址：</span>
                        <span className={classes.value}>
                          {row.receAreaName || row.receAddr.areaName }
                          {row.receAddress || row.receAddr.address}
                        </span>
                        </Box>
                        <Box className={classes.wordRow}> 
                        <span className={classes.label}>收货邮编：</span>
                        <span className={classes.value}>
                        {row.recePostcode ||row.receAddr.postcode}
                        </span>
                        </Box>
                        <Box className={classes.wordRow}> 
                        <span className={classes.label}>收货人：</span>
                        <span className={classes.value}>
                        {row.receName ||row.receAddr.name}
                        </span>
                        </Box>
                        <Box className={classes.wordRow}> 
                        <span className={classes.label}>联系电话：</span>
                        <span className={classes.value}>
                        {row.recePhone ||row.receAddr.phone}
                        </span>
                        </Box>
                      </Box>
                    </Box>}

                   {row.board && <Box margin={1} style={{border:'1px solid #dedede',borderRadius:4}}> 
                      <Box className={classes.blockTitle}>
                          <Box className={classes.blockTitleLabel}>留言信息</Box>
                      </Box>
                      <Box className={classes.blockRows}>
                      <div  dangerouslySetInnerHTML={{ __html: row.board }} />
                      </Box>
                    </Box>}

                    <Box margin={1} style={{border:'1px solid #dedede',borderRadius:4}}> 
                        <OrderDetails list={row.details}/> 
                      </Box>
                      
                    {row.sendList && row.sendList.length > 0 && <Box margin={1} style={{border:'1px solid #dedede',borderRadius:4}}> 
                        <SendList list={row.sendList} onEdit={onEditSend} onDelete={onRemoveSend}/>
                      </Box>}
                  </Collapse> 
        </BodyTableCell>
      </TableRow>
     </React.Fragment>); 
}

export default withRouter((props: any) => {
  // const { history } = props
  const { getList, deal,report,wxQuery,alipayQuery } = useOrder()
  const {wxRefundQuery,alipayRefundQuery} = useRefund()
  const removeSend = useSend().remove
  const { translate } = useLocale()

  const { account } = useSysState()
  // 处理退货
  const initRefundProps = {
    open:false,
    close:()=>{},
    success:(order:any)=>{},
    order:{}
  }
  const [refundProps,setRefundProps] = useState<any>(initRefundProps)
  // 发货
  const [sendProps,setSendProps] = useState<any>({})

  // 列表
  const [list, setList] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})
  const [reportResult,setReportResult] = useState<any>({})
  const classes = useListPageStyles()
  // const [open,setOpen] = useState<string>('')

  // 分页
  const total = (pagination && pagination.total) || 0

  // 请求参数
  const [params, setParams] = useState<any>({
    pageSize: 15,
    page: 1,
    status:'paied'
  })

  const setParam = (key: "status" |"keyword" | "endTime" | "startTime" | "page" | "pageSize", value: string | number) => {
    const newParams = params || {  }
    newParams[key] = value
    setParams(Object.assign({}, newParams))
  }
  const onSearch = (keyword: string) => {
    setParam("keyword", keyword)
    setParam("page", 1)
    loadData()
  }
  const onPageChange = (page: number) => {
    setParam("page", page)
    loadData()
  }
  const onPageSizeChange = (pageSize: number) => {
    setParam("pageSize", pageSize)
    setParam("page", 1)
    loadData()
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  // 处理订单
  const handleDeal = (id: string, status: string,remark:string) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在处理订单...'
    })
    deal({
      data: {
        id,
        status,
        remark
      },
      success: () => {
        loadData()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }

  // 刷新微信支付订单
  const handleWxQuery = (out_trade_no: string) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在刷新微信支付订单...'
    })
    wxQuery({
      data: {
        out_trade_no
      },
      success: () => {
        setLoading(initialLoading)
        loadData()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }

  // 刷新支付宝支付订单
  const handleAlipayQuery = (outTradeNo: string) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在刷新支付宝支付订单...'
    })
    alipayQuery({
      data: {
        outTradeNo
      },
      success: () => {
        setLoading(initialLoading)
        loadData()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }

  // 刷新微信订单退款状态
  const handleWxRefundQuery = (orderId: string) => {
    setLoading({
      open: true,
      text: '正在刷新微信订单退款状态..'
    })
    wxRefundQuery({
      data: {
        orderId
      },
      success: (order:any) => {
        setLoading(initialLoading)
        loadData()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  // 刷新微信订单退款状态
  const handleAlipayRefundQuery= (orderId: string) => {
    setLoading({
      open: true,
      text: '正在刷新支付宝订单退款状态..'
    })
    alipayRefundQuery({
      data: {
        orderId
      },
      success: (order:any) => {
        setLoading(initialLoading)
        loadData()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  // 获取列表
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载订单列表...'
    })
    getList({
      params,
      success: (list:any, pagination:any) => {
        setList(list)
        setPagination(pagination)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        setList([])
        setPagination({})
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
    report({
      params,
      success: (result:any) => {
        setReportResult(result)
      },
      failure: (message: string) => {
        setReportResult({})
      }
    })
  }
  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  const [order, setOrder] = React.useState<"asc" | "desc">('asc');
  const [orderBy, setOrderBy] = React.useState('title');
  const [selected, setSelected] = React.useState([] as string[])

  const handleRequestSort = (event: any, property: any) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }


  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })


  // 删除发货信息
  const handleRemoveSend = (id: string[]) => {
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
        {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
        <Box>
          <Box>您正在删除发货信息！请谨慎操作！</Box>
          <Box>此操作不可恢复，确定要继续么？</Box>
        </Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        // 获取列表
        setLoading({
          open: true,
          text: '正在删除发货信息...'
        }) 
        removeSend({
          data: {
            id
          },
          success: () => {
            setLoading(initialLoading)
            loadData()
          },
          failure: (message: string) => {
            // 关闭加载条
            setLoading(initialLoading)
            setMessage({
              open: false,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
  }

  const renderPageContent = (
    <div className={classes.pageContent}>
      <EnhancedPageToolbar
        {...{
          keyword: params.keyword,
          onSearch,
          onRefresh: () => {
            loadData()
          },
          status: params.status,
          onStatusChange:(value:string)=>{
            setParam("status", value==='all'?"":value)
            setParam("page", 1)
            loadData() 
          },
          startDate:formatDate(params.startTime),
          endDate:formatDate(params.endTime),
          onDatesChange:(result:any)=>{
            const {startDate,endDate} = result
            if(startDate){
                 const str = startDate.year()+'-'+(startDate.month()+1)+'-'+startDate.date()+" 00:00:00"
                setParam("startTime",new Date(str).getTime())
            }else{
                setParam("startTime",'')
            }
            if(endDate){
                const str = endDate.year()+'-'+(endDate.month()+1)+'-'+endDate.date()+" 23:59:59"
                setParam("endTime",new Date(str).getTime())
            }else{
                setParam("endTime",'')
            }
            setParam("status", params.status==='all'?"":params.status)
            setParam("page", 1)
            loadData() 
          },
        }} />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead
            fields={fields}
            numSelected={selected && selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
          <TableBody>

            {stableSort(list, getSorting(order, orderBy)).map((row: any, index: number) =>
             <Row key={index} row={row}  onSend={()=>{
               setSendProps({
                 open:true,
                 order:row,
                 send:{id:createId(),orderId:row.id},
                 success:()=>{
                      setSendProps({})
                      loadData()
                 },
                 cancel:()=>setSendProps({})
               })
             }}
             onWxQuery={()=>{
               handleWxQuery(row.no)
             }}
             onAlipayQuery={()=>{
               handleAlipayQuery(row.no)
             }}
             onEditSend={(send:any)=>{
               setSendProps({
                 open:true,
                 order:row,
                 send,
                 success:()=>{
                      setSendProps({})
                      loadData()
                 },
                 cancel:()=>setSendProps({})
               })
             }}
             onRemoveSend={(id:string[])=>handleRemoveSend(id)}
             onRefund={()=>{
               //处理退款
               setRefundProps({
                open:true,
                order:row,
                success:()=>{
                  setRefundProps(initRefundProps)
                  loadData()
                },
                close:()=>setRefundProps(initRefundProps)
              })
             } }
             onRefundQuery={()=>{
               if(row.paymode === 'weixin'){
                handleWxRefundQuery(row.id)
               }else if(row.paymode === 'alipay'){
                handleAlipayRefundQuery(row.id)
               }
             }}
             />
             )}
          </TableBody>
        </Table>
      </div>
      <LoadingBox {...loading} />
 
      {list.length === 0 && !loading.open && (<Box className="error">
        <Icon name="Error" width={32} height={32} color="gray" />
        <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
      </Box>)}
      <Box className={classes.pagination}>
        <ListPagination
          {...{
            total,
            page: params.page,
            pageSize: params.pageSize,
            onPageChange,
            onPageSizeChange,
            maxShow: 5
          }}
        /> 
        <Box style={{fontSize:'12px',padding:"8px",borderRadius:4,border:"1px solid #efefef",background:"#f5f5f5",whiteSpace:"nowrap"}}>
        订单合计：<span style={{fontWeight:'bold',marginRight:"16px"}}>¥{reportResult.sumAmount }</span>
        支付宝：<span style={{fontWeight:'bold',marginRight:"16px"}}>¥{reportResult.sumAmountAlipay }</span>
        微信：<span style={{fontWeight:'bold',marginRight:"16px"}}>¥{reportResult.sumAmountWeixin }</span>
        总数量：<span style={{fontWeight:'bold',marginRight:"16px"}}>{reportResult.sumNum }件</span>
        商品总价：<span style={{fontWeight:'bold',marginRight:"16px"}}>¥{reportResult.sumPrice }</span>
       {account && account.user &&  <Button
        variant="outlined"
        size="small"
        onClick={()=>{
            window.open(Config.fullApi + Config.ordersDownloadUrl + '?'
            +'appId='+Config.appId
            +'&userId='+account.user.id
            +'&token='+account.token
            +(params.keyword ? '&keyword='+params.keyword:'')
            +(params.status ? '&status='+params.status:'')
            +(params.startTime ? '&startTime='+params.startTime:'')
            +(params.endTime ? '&endTime='+params.endTime:''))
          }}>导出Excel</Button>}
        </Box>
      </Box>
    </div>)

  return (<Box className={classes.root}>

    {renderPageContent}

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
   <BaseDialog 
        title="发货"
        open={sendProps.open}
        close={()=>{
          setSendProps({})
          loadData()
        }}
        maxWidth="md">
        <Send {...sendProps}/>
    </BaseDialog>
    {refundProps.open && <RefundDialog  {...refundProps}/>}
  </Box>
  )
})