import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as contentTypes from '../../../types/content'


// Action Types
export type Action = {
    // 文章列表
    type:'CONTENT_ARTICLE_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type:'CONTENT_ARTICLE_FETCH_LIST_SUCCESS', payload: { list: contentTypes.Article[] ,pagination:types.Pagination} }
    | { type:'CONTENT_ARTICLE_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        //  启用文章列表
        type:'CONTENT_ARTICLE_FETCH_ACTIVE_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type:'CONTENT_ARTICLE_FETCH_ACTIVE_LIST_SUCCESS', payload: { list: contentTypes.Article[] ,pagination:types.Pagination } }
    | { type:'CONTENT_ARTICLE_FETCH_ACTIVE_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 文章
        type:'CONTENT_ARTICLE_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type:'CONTENT_ARTICLE_FETCH_SUCCESS', payload: any }
    | { type:'CONTENT_ARTICLE_FETCH_FAILURE', payload: { error: types.Error } }
    |  {
        // 获取单页内容
        type:'CONTENT_ARTICLE_FETCH_BYCAT',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | {
        // 保存文章
        type:'CONTENT_ARTICLE_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 删除文章
        type:'CONTENT_ARTICLE_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 设置状态
        type:'CONTENT_ARTICLE_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };




// 文章列表
export const FETCH_LIST = 'CONTENT_ARTICLE_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'CONTENT_ARTICLE_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'CONTENT_ARTICLE_FETCH_LIST_FAILURE';
export const fetchList = (params: any): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/getList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchListSucess = (list: contentTypes.Article[], pagination:types.Pagination): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});


// 上架文章列表
export const FETCH_ACTIVE_LIST = 'CONTENT_ARTICLE_FETCH_ACTIVE_LIST'
export const FETCH_ACTIVE_LIST_SUCCESS = 'CONTENT_ARTICLE_FETCH_ACTIVE_LIST_SUCCESS';
export const FETCH_ACTIVE_LIST_FAILURE = 'CONTENT_ARTICLE_FETCH_ACTIVE_LIST_FAILURE';
export const fetchActiveList = (params: any): Action => ({
    type: FETCH_ACTIVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/getActiveList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchActiveListSucess = (list: contentTypes.Article[],pagination:types.Pagination): Action => ({
    type: FETCH_ACTIVE_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchActiveListFailure = (error: types.Error): Action => ({
    type: FETCH_ACTIVE_LIST_FAILURE,
    payload: {
        error
    }
});



// 文章
export const FETCH = 'CONTENT_ARTICLE_FETCH'
export const FETCH_SUCCESS = 'CONTENT_ARTICLE_FETCH_SUCCESS';
export const FETCH_FAILURE = 'CONTENT_ARTICLE_FETCH_FAILURE';
export const fetch = (id: any): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/get`,
            method: 'GET',
            params: {
                id
            }
        }
    }
})
export const fetchSucess = (article: contentTypes.Article): Action => ({
    type: FETCH_SUCCESS,
    payload: {
        article
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});


// 获取单页内容
export const FETCH_BYCAT = 'CONTENT_ARTICLE_FETCH_BYCAT'
export const fetchByCat = (catId: any): Action => ({
    type: FETCH_BYCAT,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/getByCat`,
            method: 'GET',
            params: {
                catId
            }
        }
    }
})

// 保存
export const SAVE = 'CONTENT_ARTICLE_SAVE'
export const save = (article: contentTypes.Article): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/save`,
            method: 'POST',
            data: {
                article
            }
        }
    }
})



// 删除
export const REMOVE = 'CONTENT_ARTICLE_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})



// 设置状态
export const SET_STATUS = 'CONTENT_ARTICLE_SET_STATUS'
export const setStatus = (data: any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/setStatus`,
            method: 'POST',
            data
        }
    }
})