import React, { useState,useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { useConfigState } from '../../../provides/app'
import { useLocale } from '../../../provides/app/LocaleProvide'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      margin: '10px',
      lineHeight: '32px'
    },
    selectedText: {
      border: '0',
      boxShadow: '0',
      padding: '0 5px 0 20px',
      color: theme.palette.primary.contrastText
    },
    menuItem: {
      minWidth: 100,
    },
    icon: {
    }
  }))

export default function LocaleView() {
  const classes = useStyles();
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const isLocaleMenuOpen = Boolean(langMenuAnchorEl)
  const { setLocaleConfig } = useLocale()

  const { localeConfig } = useConfigState();
  
  function handleLangChange(locale: string) {
    setLocaleConfig(locale)
    setLangMenuAnchorEl(null);
  }

  function handleLangMenuClose() {
    setLangMenuAnchorEl(null);
  }
  function handleToggleLocaleMenu(event: any) {
    if (langMenuAnchorEl) {
      setLangMenuAnchorEl(null);
    } else {
      setLangMenuAnchorEl(event.currentTarget);
    }
  }
  const langMenuId = 'primary-lang-menu';
  const renderLocaleMenu = (
    <Popper
      open={isLocaleMenuOpen}
      anchorEl={langMenuAnchorEl}
      transition
      disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            zIndex:2000
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleLangMenuClose}>
              <MenuList>
               {
                  localeConfig && localeConfig.localeNames.map((item:any, index:number) => (
                    <MenuItem key={index}
                      onClick={() => { handleLangChange(item.locale) }}>
                      <Box className={classes.menuItem}>
                        {item.name}
                      </Box>
                    </MenuItem>
                  ))
                } 
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.selectedText}>
        {localeConfig.name}
      </Box>
      <IconButton
        ref={langMenuAnchorEl}
        size="small"
        aria-controls={langMenuId}
        aria-owns={isLocaleMenuOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleLocaleMenu}
        color="inherit"
      >
        <ArrowDropDownIcon className={classes.icon} />
      </IconButton>
      {renderLocaleMenu}
    </Box>
  )
} 