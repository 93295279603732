import { Action } from '../../actions';
import * as Actions from '../../actions/ui/page';
import * as State from '../../state/ui';

export const initialState: State.Page = {
};

export const reducer = (state: State.Page = initialState, action: Action): State.Page => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list
            };

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                page: action.payload.page
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        default: return state;
    }
};