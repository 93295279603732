import React from 'react';
import { EditorState, Modifier } from 'draft-js';

import Popover from '@material-ui/core/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Icon from './Icon'
import Button from './Button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        emojiWrapper: {
            padding:8,
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
            position: "relative",
            flexWrap: "wrap",
            minWidth: 375,
            minHeight: 180,
            maxWidth:600,
            maxHeight:280
        },
        emojiIcon: {
            margin: "2.5px",
            height: "20px",
            width: "20px",
            cursor: "pointer",
            fontSize: "22px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border:"1px solid transparent",
            padding:4,
            borderRadius:3,
            '&:hover':{
                backgroundColor:"#f3f3f3",
                border:"1px solid #efefef"
            }
        }
    }))

export default (props: any) => {
    const {
        onChange,
        editorState,
        title,
        emojis
    } = props

    const classes = useStyles()

    const stopPropagation = (event: any) => {
        event.stopPropagation();
    };
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    function handleClick(event: any) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }

    const addEmoji = (emoji: string): void => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            emoji,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
        handleClose();
    };


    const renderModal = <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={stopPropagation}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
    >
        <Box className={classes.emojiWrapper}>
            {
                emojis.map((emoji: string, index: number) => (<span
                    key={index}
                    className={classes.emojiIcon}
                    onClick={(event: any) => {
                        addEmoji(event.target.innerHTML)
                    }}
                >{emoji}</span>))
            }
        </Box>
    </Popover>

    return (<>
        <Button onMouseDown={handleClick}
            title={title[0]}>
            <Icon name="Emoji" />
        </Button>
        {renderModal}
    </>
    );
}