
import { shallowEqual, useSelector } from "react-redux";
import * as SysState from "../../store/state/sys";
import * as State from "../../store/state";

export { useAccount } from './AccountProvide'

export const useSysState = (): SysState.State => {
  const state = useSelector((state: State.Root) => {
    return {
      appToken: state.sys.appToken,
      account: state.sys.account,
      code: state.sys.code, 
      message: state.sys.message
    };
  }, shallowEqual);
  return state;
};