import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
    p-id="1970" width={width} height={height}>
    <path fill={color} d="M1022.955 556.248c0 100.192-81.516 181.698-181.719 181.698H655.6c-11.298 0-20.467-9.169-20.467-20.466 0-11.308 9.17-20.466 20.467-20.466h185.637c77.628 0 140.787-63.148 140.787-140.766 0-77.424-62.841-140.449-140.203-140.766-0.42 0.03-0.83 0.05-1.249 0.061a20.492 20.492 0 0 1-15.666-6.702 20.471 20.471 0 0 1-5.158-16.25c1.33-10.807 1.944-19.76 1.944-28.193 0-60.764-23.658-117.874-66.617-160.833-42.969-42.958-100.09-66.617-160.843-66.617-47.369 0-92.742 14.45-131.208 41.782-37.617 26.739-65.953 63.7-81.926 106.884a20.478 20.478 0 0 1-14.828 12.894 20.512 20.512 0 0 1-18.86-5.536c-19.289-19.34-44.943-29.983-72.245-29.983-56.323 0-102.146 45.813-102.146 102.126 0 0.317 0.04 0.982 0.092 1.627 0.061 0.92 0.122 1.831 0.153 2.763a20.466 20.466 0 0 1-15.002 20.456c-32.356 8.943-61.541 28.55-82.181 55.217-21.305 27.517-32.572 60.508-32.572 95.413 0 86.244 70.188 156.423 156.443 156.423h169.981c11.298 0 20.466 9.158 20.466 20.466 0 11.297-9.168 20.466-20.466 20.466H199.951c-108.829 0-197.375-88.536-197.375-197.355 0-44.053 14.224-85.712 41.126-120.474 22.81-29.45 53.898-52.086 88.71-64.816 5.066-74.323 67.15-133.245 142.752-133.245 28.386 0 55.504 8.218 78.651 23.526 19.658-39.868 48.843-74.169 85.498-100.212 45.434-32.296 99.004-49.354 154.918-49.354 71.693 0 139.088 27.916 189.782 78.6 50.695 50.695 78.61 118.09 78.61 189.782 0 3.705-0.102 7.47-0.296 11.37 90.307 10.478 160.628 87.42 160.628 180.48z" p-id="1971"></path>
    <path fill={color} d="M629.259 589.106c-3.991 3.991-9.23 5.997-14.47 5.997s-10.479-2.006-14.47-5.997l-67.087-67.078v358.69c0 11.307-9.159 20.466-20.467 20.466-11.307 0-20.466-9.159-20.466-20.466v-358.69l-67.088 67.078c-7.992 7.992-20.947 7.992-28.939 0s-7.992-20.957 0-28.95l102.024-102.013c7.992-7.992 20.947-7.992 28.939 0l102.024 102.014c7.992 7.992 7.992 20.957 0 28.95z"  p-id="1972"></path></svg>
    )
