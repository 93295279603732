
import * as actions from '../../store/actions/trade/refund'
import { useDispatch } from "react-redux";

interface Result {
    getReasons: Function,
    saveReason: Function,
    removeReasons: Function,
    auditOk: Function,
    auditFail: Function,
    payment: Function,
    wxRefundQuery:Function,
    alipayRefundQuery:Function
}

export const useRefund = (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getReasons = (props: any) => {
        const { success, failure } = props
        dispatch(actions.getReasons()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.list)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    const saveReason = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.saveReason(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    const  removeReasons = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.removeReasons(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    const  auditOk = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.auditOk(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.order)
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    const  auditFail = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.auditFail(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.order)
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    
    const  payment = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.payment(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.order)
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    const  wxRefundQuery = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.wxRefundQuery(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.order)
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    const  alipayRefundQuery = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.alipayRefundQuery(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(data.result.order)
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    return {
        getReasons,
        saveReason,
        removeReasons,
        auditOk,
        auditFail,
        payment,
        wxRefundQuery,
        alipayRefundQuery
    }
}