import { Action } from '../../actions';
import * as Actions from '../../actions/shopping/attr';
import * as State from '../../state/shopping';

export const initialState: State.Attr = {
};

export const reducer = (state: State.Attr = initialState, action: Action): State.Attr => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list, 
                pagination: action.payload.pagination
            };

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_LIST_BY_CAT_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_LIST_BY_CAT_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                attr: action.payload.attr
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        default: return state;
    }
};