import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="14324"
    width={width} height={height}><path fill={color} d="M259.2 860.8l400-400-96-96L163.2 764.8l96 96zM944 195.2c19.2 19.2 19.2 51.2 0 70.4l-156.8 156.8 96 96-70.4 70.4-70.4-70.4L297.6 960H96c-19.2 0-32-12.8-32-32v-204.8L505.6 278.4l-70.4-70.4 70.4-70.4 96 96 156.8-156.8c6.4-9.6 19.2-12.8 32-12.8s25.6 3.2 35.2 12.8l118.4 118.4z" p-id="14325"></path></svg>
    )
