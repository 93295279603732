import React from "react"

import { useUIPageStyles } from "../../../../assets/jss/pages"
import Icon from "../../../../components/Icon"

import Box from '@material-ui/core/Box';
import NumberInput from '../../../../components/ui/form/NumberInput'
import RadioInput from '../../../../components/ui/form/RadioInput';
import ArticleCatInput from '../../../../components/content/CatInput';
import ProductCatInput from '../../../../components/shopping/CatInput';
import InputList from "./InputList"

import { DataSource } from "../../../../types/ui"
import { ImageTextListItem } from "./types"

type Props = {
    data?: ImageTextListItem[],
    dataSource?: DataSource,
    onChange: (dataSource: DataSource, data?: ImageTextListItem[]) => void
}

// 组件数据输入
export default (props: Props) => {
    const classes = useUIPageStyles()
    const { data, dataSource, onChange } = props

    const labelWidth = 120

    const [dsForm, setDsForm] = React.useState<any>(dataSource || {})
    const setDataSourceItem = (key: "near" | "hots" | "type" | "dynamic" | "catId" | "maxItems", value: any) => {
        dsForm[key] = value
        setDsForm(Object.assign({}, dsForm))
        onChange(dsForm, data)
    }

    const renderInputs = () => {
        switch (dsForm.type) {
            case "articles":
                return <ArticleCatInput
                    labelWidth={labelWidth}
                    label="选择文章分类："
                    value={dataSource && dataSource.catId}
                    onChange={(item: any) => {
                        setDataSourceItem("type", "articles")
                        setDataSourceItem("catId", item.id)
                    }} >
                    <Box className={classes.startTips} style={{ paddingTop: 20 }}>
                        <Icon name="Alert" className={classes.icon} />
                        请选择文章分类！
               </Box>
                </ArticleCatInput>
            case "products":
                return <ProductCatInput
                    labelWidth={labelWidth}
                    label="选择商品分类："
                    value={dataSource && dataSource.catId}
                    onChange={(item: any) => {
                        setDataSourceItem("type", "products")
                        setDataSourceItem("catId", item.id)
                    }} >
                    <Box className={classes.startTips} style={{ paddingTop: 20 }}>
                        <Icon name="Alert" className={classes.icon} />
                        请选择商品分类！
               </Box>
                </ProductCatInput>
            case "input":
                // 直接录入
                return <InputList
                    options={[
                        {
                            id: "1",
                            name: 'image',// 类型名称
                            alias: '图片',// 别名
                            variableType: 'image',// 变量类型
                            notNull: true,// 非空
                        },
                        {
                            id: "2",
                            name: 'title',// 类型名称
                            alias: '标题',// 别名
                            variableType: 'string',// 变量类型
                            notNull: true,// 非空
                        },
                        {
                            id: "3",
                            name: 'subtitle',// 类型名称
                            alias: '副标题',// 别名
                            variableType: 'string',// 变量类型
                            notNull: false,// 非空
                        },
                        {
                            id: "4",
                            name: 'link',// 类型名称
                            alias: '链接地址',// 别名
                            variableType: 'link',// 变量类型
                            notNull: false,// 非空
                        }]}
                    data={data || []}
                    edittable
                    onChange={(data: ImageTextListItem[]) => {
                        setDataSourceItem("type", "input")
                        setDataSourceItem("catId", "")
                        onChange(dsForm, data)
                    }} />
            default:
                break;
        }
        return <Box className={classes.startTips} style={{ paddingTop: 20 }}>
            <Icon name="Alert" className={classes.icon} />
            请选择数据源类型！
            </Box>
    }

    return <Box>

        <RadioInput
            labelWidth={labelWidth}
            label="数据获取方式："
            value={dsForm.dynamic ? "1" : "0"}
            options={[
                { label: "静态数据", value: "0" },
                { label: "动态数据", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                setDataSourceItem("dynamic", value === "1" ? true : false)
            }} />
        {
            !dsForm.dynamic && !dsForm.hots ? <RadioInput
                labelWidth={labelWidth}
                label="数据类型："
                value={dsForm.type}
                options={[
                    { label: "关联文章分类", value: "articles" },
                    { label: "关联商品分类", value: "products" },
                    { label: "录入数据", value: "input" }
                ]}
                onChange={(e: any, value: any) => {
                    setDataSourceItem("type", value)
                }} /> :
                <RadioInput
                    labelWidth={labelWidth}
                    label="数据类型："
                    value={dsForm.type}
                    options={[
                        { label: "关联文章分类", value: "articles" },
                        { label: "关联商品分类", value: "products" }
                    ]}
                    onChange={(e: any, value: any) => {
                        setDataSourceItem("type", value)
                    }} />
        }
        <RadioInput
            labelWidth={labelWidth}
            label="热文列表："
            value={dsForm.hots ? "1" : "0"}
            options={[
                { label: "关闭", value: "0" },
                { label: "开启", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                setDataSourceItem("hots", value === "1" ? true : false)
            }} />
        {dsForm.hots &&
            <NumberInput
                labelWidth={labelWidth}
                label="热门最近天数："
                value={dsForm.near || 1}
                onChange={(value: any) => {
                    setDataSourceItem("near", value);
                }} />}
        <NumberInput
            labelWidth={labelWidth}
            value={dsForm.maxItems || (!dsForm.dynamic ? 5 : 12)}
            label="最大条目数："
            onChange={(value: any) => {
                setDataSourceItem("maxItems", value);
            }} />
        {
            !dsForm.dynamic ?
                renderInputs() :
                <Box className={classes.startTips} style={{ paddingTop: 10 }}>
                    <Icon name="Alert" className={classes.icon} />
                    动态解析页面参数加载数据！
            </Box>
        }

    </Box>
}