import React from 'react';
import { useLocale } from '../../../../config/Locale'
import Icon from './Icon'
import Button from './Button'

const Preview = (props:any) => {
    const {onOpen,title} = props
    return (
        <Button
            onMouseDown={onOpen}
            title={title[0]}>
            <Icon name="Preview" width={18} height={18} />
        </Button>
    );
}

export default Preview