import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'


// Action Types
export type Action = {
    // 发货列表
    type: 'TRADE_SEND_GET_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 获取发货
    type: 'TRADE_SEND_GET',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 保存发货
    type: 'TRADE_SEND_SAVE',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 删除发货
    type: 'TRADE_SEND_DELETE',
    payload: { client: 'default', request: AxiosRequestConfig }
};



// 发货列表
export const SEND_GET_LIST = 'TRADE_SEND_GET_LIST'
export const fetchList = (params:any): Action => ({
    type: SEND_GET_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/send/getList`,
            method: 'GET',
            params
        }
    }
})


// 发货信息
export const SEND_GET = 'TRADE_SEND_GET'
export const fetch = (params:any): Action => ({
    type: SEND_GET,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/send/get`,
            method: 'GET',
            params
        }
    }
})

// 处理发货
export const SEND_SAVE = 'TRADE_SEND_SAVE'
export const save = (data:any): Action => ({
    type: SEND_SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/send/save`,
            method: 'POST',
            data
        }
    }
})


// 删除发货
export const SEND_DELETE = 'TRADE_SEND_DELETE'
export const remove = (data:any): Action => ({
    type: SEND_DELETE,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/send/del`,
            method: 'POST',
            data
        }
    }
})
