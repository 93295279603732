import React from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { useLocale } from '../../../config/Locale'

import TreeView from '.';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    buttons: {
      flex: 1,
      paddingRight: 10
    },
    button: {
      marginRight: theme.spacing(1)
    },
    fab: {
      margin: 0,
      marginRight: theme.spacing(1)
    },
    icon: {
      width: 16,
      height: 16,
      margin: 5
    },
    allCats:{
      cursor:'pointer',
      fontSize:'.9em',
      padding:5,
      '&:hover':{
        background:'#f7f7f7'
      }
    }
  }))


export default function DataTreeView(props: any) {
  const classes = useStyles();
  const { 
    notShowAll,
    items, 
    edit,
    onItemSelected,
    onItemChecked,
    onItemClick,
    onClickAll,
    onEdit, onRemove, onAppend,
    showCheckbox,
    showDescri
  } = props

  const { translate } = useLocale()
  const tree = (
    <TreeView
      className={classes.root}
      items={items}
      showCheckbox={showCheckbox}
      showDescri={showDescri}
      onItemClick={onItemClick}
      onItemSelected={onItemSelected}
      onChecked={onItemChecked}
      renderNode={(item: any) => {
        if (edit) {
          return (
            <Box className={classes.buttons} textAlign="right" >

              <IconButton
                size="small"
                className={classes.button}
                aria-haspopup="true"
                color="primary"
                onClick={() => {
                  if (onAppend) {
                    onAppend(item)
                  }
                }}
              >
                <Tooltip title={translate('appendCat')}  aria-label="add">
                  <AddIcon className={classes.icon} />
                </Tooltip>
              </IconButton>

              <IconButton
                size="small"
                className={classes.button}
                aria-haspopup="true"
                color="secondary"
                onClick={() => {
                  if (onEdit) {
                    onEdit(item)
                  }
                }}
              >
                <Tooltip title={translate('modifyCat')} aria-label="add">
                  <EditIcon className={classes.icon} />
                </Tooltip>
              </IconButton>


              <IconButton
                size="small"
                className={classes.button}
                aria-haspopup="true"
                onClick={() => {
                  if (onRemove) {
                    onRemove(item)
                  }
                }}
              >
                <Tooltip title={translate('removeCat')} aria-label="add">
                  <DeleteIcon className={classes.icon} />
                </Tooltip>
              </IconButton>
            </Box>
          )
        } else {
          return <></>
        }
      }}
  />
  );
  return (<Box>
    {items && items.length > 0 && !notShowAll  &&
      <Box onClick={onClickAll} className={classes.allCats}>
        {translate('allCats')}
      </Box>
    }
    {tree}
  </Box>)
}