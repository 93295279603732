import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="2235"
    width={width} height={height}><path fill={color} d="M426.666667 896c-8.533333 0-21.333333-4.266667-29.866667-12.8l-384-341.333333C4.266667 537.6 0 524.8 0 512s4.266667-25.6 12.8-29.866667l384-341.333333c12.8-12.8 29.866667-12.8 46.933333-8.533333C460.8 136.533333 469.333333 153.6 469.333333 170.666667l0 213.333333c0 25.6-17.066667 42.666667-42.666667 42.666667S384 409.6 384 384L384 264.533333 106.666667 512 384 759.466667 384 640c0-25.6 17.066667-42.666667 42.666667-42.666667s42.666667 17.066667 42.666667 42.666667l0 213.333333c0 17.066667-8.533333 34.133333-25.6 38.4C439.466667 896 430.933333 896 426.666667 896z" p-id="2236"></path>
    <path fill={color} d="M981.333333 896c-17.066667 0-34.133333-8.533333-38.4-25.6 0 0-85.333333-187.733333-516.266667-187.733333-25.6 0-42.666667-17.066667-42.666667-42.666667s17.066667-42.666667 42.666667-42.666667c260.266667 0 409.6 64 494.933333 123.733333C832 430.933333 443.733333 426.666667 426.666667 426.666667c-25.6 0-42.666667-17.066667-42.666667-42.666667 0-25.6 17.066667-42.666667 42.666667-42.666667 4.266667 0 597.333333 4.266667 597.333333 512 0 21.333333-12.8 38.4-34.133333 42.666667C985.6 896 985.6 896 981.333333 896z" p-id="2237"></path></svg>
    )
