import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="20862"
    width={width} height={height}><path fill={color} d="M517.688889 85.333333C278.755556 85.333333 91.022222 273.066667 91.022222 512c0 233.244444 187.733333 426.666667 420.977778 426.666667 233.244444 0 420.977778-193.422222 420.977778-426.666667 5.688889-238.933333-182.044444-426.666667-415.288889-426.666667zM312.888889 477.866667v68.266666c0 17.066667-11.377778 28.444444-28.444445 28.444445s-28.444444-11.377778-28.444444-28.444445V477.866667c0-85.333333 68.266667-153.6 153.6-153.6h125.155556v-34.133334c0-17.066667 11.377778-28.444444 28.444444-28.444444 11.377778 0 17.066667 5.688889 22.755556 11.377778l62.577777 62.577777c5.688889 5.688889 11.377778 11.377778 11.377778 22.755556 0 5.688889-5.688889 17.066667-11.377778 22.755556l-62.577777 62.577777c-17.066667 11.377778-34.133333 11.377778-45.511112 0-5.688889-5.688889-11.377778-17.066667-11.377777-22.755555v-34.133334H403.911111c-51.2 0-91.022222 39.822222-91.022222 91.022223z m460.8 68.266666c0 85.333333-68.266667 153.6-153.6 153.6H500.622222v34.133334c0 17.066667-11.377778 28.444444-28.444444 28.444444-11.377778 0-17.066667-5.688889-22.755556-11.377778l-62.577778-62.577777c-5.688889-5.688889-11.377778-11.377778-11.377777-22.755556s5.688889-17.066667 11.377777-22.755556l62.577778-62.577777c11.377778-11.377778 28.444444-11.377778 45.511111 0 5.688889 5.688889 11.377778 11.377778 11.377778 22.755555v34.133334H625.777778c51.2 0 91.022222-39.822222 91.022222-91.022223V477.866667c0-17.066667 11.377778-28.444444 28.444444-28.444445s28.444444 11.377778 28.444445 28.444445v68.266666z" p-id="20863"></path></svg>
    )
