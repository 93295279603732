import React from "react"

import { SelectOption } from "react-select-material-ui";
import Box from '@material-ui/core/Box';

import Icon from "../../../components/Icon"

import { PageSetting, Component } from '../../../types/ui';
import { ConfirmDialog } from "../../../components/ui/dialog"
import { useUIPageStyles } from "../../../assets/jss/pages"
import { SingleSelect } from "../../../components/ui/select"
import { FormTabPanel, FormTab, FormTabs } from '../../../components/ui/tabs/FormTabs';

import { useComponent, useComponentState } from '../../../provides/ui'

import { ComponentOption, DataSource } from "../../../types/ui"
// 配置数据录入
import OptionInput from "./inputs/components/OptionInput"
import DataInput from "./inputs/DataInput"

type Props = {
    setting: PageSetting,// 设置详细的组件配置
    onChange: (setting: PageSetting) => void,
    cancel: () => void
}


const a11yProps = (index: number, label: string) => {
    return {
        index,
        label,
        ariaprefix: "page-edit-component",
    };
}

export default (props: Props) => {
    const classes = useUIPageStyles()
    const { setting, onChange, cancel } = props
    // 查询已设置的组件失败
    const [error, setError] = React.useState<string>("");
    // 当前节点数据
    const [data, setData] = React.useState<any>(setting.data);
    // 当前节点数据源
    const [dataSource, setDataSource] = React.useState<DataSource>(setting.dataSource || { type: "input" });
    // 标签页 
    const [value, setValue] = React.useState(0);
    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }
    // 当前的组件
    const [component, setComponent] = React.useState<Component | null | undefined>(setting.component)
    // 组件列表转换下拉列表
    const [selectOptions, setSelectOptions] = React.useState<SelectOption[]>([])
    const components = useComponentState().list
    const getComponents = useComponent().getList
    const getComponent = useComponent().get
    // 初始化组件列表
    const loadComponents = () => getComponents({
        success: (list: Component[]) => {
            const components: SelectOption[] = []
            setSelectOptions(components.concat(list.map((component: Component) => Object.assign({}, {
                label: component.name + " " +
                    component.alias + " " +
                    (component.description ? "(" + component.description + ")" : ""),
                value: component.id
            }))))
        },
        failure: (message: string) => setSelectOptions([])
    })
    // 初始化已设置组件
    const loadComponent = () => {
        if (component) {
            getComponent({
                id: component.id,
                success: (component: Component) => {
                    setComponent(component)
                },
                failure: (message: string) => setError(message)
            })
        }
    }
    React.useEffect(() => {
        loadComponents()
        loadComponent()
    }, [])

    // 选中组件
    const handleSelect = (value: string) => {
        const result = components.filter((component: Component) => component.id === value)
        setComponent(result.length > 0 ? result[0] : {})
        setError("")
    }
    // 配置表单
    const [options, setOptions] = React.useState<any>(setting.options || {})
    const setOption = (key: string, value: any) => {
        const newForm = options
        newForm[key] = value;
        setOptions(Object.assign({}, newForm))
    }

    const onSubmit = (event: any) => {
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        else {
            event.cancelBubble = true;
        }

        if (event.preventDefault) {
            event.preventDefault();
        }
        else {
            event.returnValue = false;
        }
    }
    // 组件配置输入
    const componentOptions = component && component.options &&
        component.options.map((option: ComponentOption, index: number) =>
            <OptionInput
                key={index}
                option={option}
                value={options[option.name]}
                onChange={(name: string, value: any) => {
                    setOption(name, value)
                }} />)

    const children = <form onSubmit={onSubmit}>

        <Box className={classes.contentHeader} style={{
            padding: 0
        }}>
            <FormTabs
                variant="scrollable"
                value={value}
                onChange={handleChange}>
                <FormTab {...a11yProps(0, '组件数据')} />
                {componentOptions && <FormTab {...a11yProps(1, '组件配置')} />}
            </FormTabs>
        </Box>
        <Box className={classes.contentContainer}>
            {/**数据录入 */}
            {component && <FormTabPanel
                ariaprefix="page-edit-component"
                value={value}
                index={0}>
                <Box style={{ clear: "both", height: 10 }}></Box>
                <DataInput
                    data={data}
                    dataSource={dataSource}
                    datatype={component.datatype}
                    onChange={(dataSource: DataSource, data?: any) => {
                        setDataSource(dataSource)
                        setData(data)
                    }}
                />
            </FormTabPanel>}
            {/**配置录入 */}
            {componentOptions &&
                <FormTabPanel
                    ariaprefix="page-edit-component"
                    value={value}
                    index={1}>
                    <Box style={{ clear: "both", height: 10 }}></Box>
                    {componentOptions || <Box className={classes.alertBox}>
                        <Icon name="Alert" className={classes.icon} />
                        无组件配置信息!
                       </Box>}
                </FormTabPanel>}

            {error && <Box className={classes.alertBox}>
                <Icon name="Alert" className={classes.icon} />
                {error}
            </Box>}
        </Box>
    </form>

    return <ConfirmDialog
        {...{
            open: true,
            fullWidth: true,
            maxWidth: "md",
            title: <Box style={{ fontSize: ".9em" }}>
                <SingleSelect
                    value={(component && component.id) || ""}
                    placeholder="下拉选择组件或输入关键字查找组件..."
                    options={selectOptions}
                    onChange={handleSelect} />
            </Box>,
            ok: () => {
                if (component) {
                    onChange({
                        id: setting.id,
                        type: "component",
                        name: component.alias,
                        component,
                        options,
                        data,
                        dataSource,
                    })
                }
            },
            cancel: () => {
                cancel()
            },
            footer: <Box className={classes.tips}>
                <Icon name="Alert" className={classes.icon} />
                此页设置组显示参数及组件数据。 组件配置在组件管理界面设置。
               </Box>
        }}>
        <Box className={classes.dialog} style={{ minHeight: 300, width: "100%" }}>
            {component ? children : <Box className={classes.alertBox}>
                <Icon name="Alert" className={classes.icon} />
                未加载组件信息，请先选择组件!
            </Box>}
        </Box>
    </ConfirmDialog>
}