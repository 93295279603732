import 'babel-polyfill';
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// https://github.com/facebook/draft-js
// https://github.com/margox/braft-editor.git
import './css/index.scss'

import {
  Editor,
  EditorState
} from 'draft-js';

import events from './renders/events';
import {
  decorator,
  blockRender,
  blockRenderMap,
  blockStyleFn,
  styleMap,
  styleFn
} from './renders'

import convertFromHtml from './renders/convertFromHtml'
import convertToHtml from './renders/convertToHtml'
import pastedText from './renders/pastedText'
// import convertToHtml from './renders/exportHtml'

import { EditorProps } from './types'
import Toolbar from './Toolbar'
import { toolbarOptions } from "./toolbarOptions"

import { useLocale } from '../../../config/Locale'

import { headerHeight, tabHeight } from '../../../layout/size'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    fullScreen: {
      left: '50%',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      top: 0,
      position: 'fixed',
      transform: 'translateX(-50%)',
      zIndex: 999,
      background: '#fff'
    }
  }),
);

export default (props: EditorProps) => {
  const { placeholder, loaded, onLoaded, extraToolbar,value, onChange, tabHided, options,emojis, fixed = true ,showOptions = true} = props
  const classes = useStyles()
  const { translate } = useLocale() 

  const initialState = EditorState.createEmpty(decorator)
  const [editorState, setEditorState] = useState(initialState)

  // 预览
  const [preview, setPreview] = useState(false)
  const [html, setHtml] = useState('')

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setPreview(open);
  };
  const renderPreview = <Drawer anchor="right" open={preview} onClose={toggleDrawer(false)}>
    <div style={{ padding: 10, maxWidth: 750, minWidth: 350 }} >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  </Drawer>

  // 图片/视频/音频插件元素
  const [mediaPluginProps, setMediaPluginProps] = useState({
    uploadType: 'image',
    open: false,
    controlOpen: false,
    attributes: null as any,
    mediaProps: null as any,
    replace: false
  })
  // 节点点击事件
  const onMediaClick = (props: any) => {
    const {
      mediaProps,
      attributes,
      uploadType
    } = props
    const newProps = mediaPluginProps
    newProps['mediaProps'] = mediaProps
    newProps['attributes'] = attributes
    newProps['controlOpen'] = true
    newProps['open'] = false
    newProps['uploadType'] = uploadType
    setMediaPluginProps(Object.assign({}, newProps))
  }

  useEffect(() => {
    if (!loaded) {
      onLoaded(editor)
      setHtml(value || '')
      const editorState = EditorState.createWithContent(
        convertFromHtml(value || '')({
          onMediaClick
        }),
        decorator
      )
      setEditorState(editorState)
    }
  })

  const handlePastedText = (text: string, html: string, editorState: EditorState) => {
    return pastedText(
      text,
      html,
      editorState,
      onEditorChange,
      onMediaClick
    );
  };
  const onEditorChange = (editorState: any) => {
    setEditorState(editorState);
    const html = convertToHtml(editorState);
    setHtml(html)
    onChange(html)
  };

  const {
    keyBindingFn,
    handleKeyCommand,
    onTab,
    onFocus
  } = events(onEditorChange, editorState)

  // 编辑器ref
  const editor = useRef(null as any)
  const focus = () => { editor.current.focus() }
  // 全屏
  const [fullScreen, setFullScreen] = useState(false)
  // 样式调整
  const wrapper = React.useRef(null as any);
  const toolbarWrapper = React.useRef(null as any);
  const editorWrapper = React.useRef(null as any);
  const [dom, setDom] = React.useState(null as any)
  const [top, setTop] = useState('')
  const [width, setWidth] = useState(0)
  const [scrolled, setScolled] = useState(false)

  const handleResize = () => {
    setWidth(editorWrapper.current.clientWidth)
  }

  React.useEffect(() => {
    if (!fixed)
      return
    if (width === 0) {
      handleResize()
    }
    window.addEventListener("resize", handleResize)
    const dom = document.getElementById('screenMainContainer')
    if (dom) {
      setDom(dom)
      setWidth(dom.clientWidth)
      dom.addEventListener('scroll', onScroll)
    }
    return () => {
      if (dom) {
        setDom(dom)
        dom.removeEventListener('scroll', onScroll)
        window.removeEventListener("resize", handleResize)
      }
    }
  })
  const onScroll = (e: any) => {
    if (!fixed)
      return
    if (!dom) {
      return
    }
    let scrollY = (tabHided ? 0 : tabHeight)
    let top = headerHeight
    if (!fullScreen) {
      top = headerHeight + tabHeight
    }
    if (dom.scrollTop > scrollY) {
      setTop(`${top}px`)
      setScolled(true)
    } else if (dom.scrollTop <= scrollY) {
      setTop('')
      setScolled(false)
    }
  }


  return (<>
    <div ref={wrapper as any} className={clsx(classes.root, {
      [classes.fullScreen]: fullScreen
    })}>
      {showOptions && <div ref={toolbarWrapper as any} style={{
        width: scrolled && !fullScreen ? width : 'auto',
        top,
        zIndex:fixed? 999:"auto",
        background: '#ccc',
        position: scrolled && !fullScreen ? 'fixed' : 'static' 
      }}>
        <Toolbar {...{
          editor,
          onChange: onEditorChange,
          editorState,
          options: toolbarOptions({
            translate,
            options,
            emojis,
            mediaPluginProps,
            setMediaPluginProps,
            fullScreen,
            setFullScreen,
            setPreview
          })
        }} >{extraToolbar}</Toolbar> 
      </div>}
      <div ref={editorWrapper as any}
        style={{
          padding: 10,
          overflowY: 'auto',
          overflowX: 'auto',
          marginTop: scrolled && !fullScreen ? top : ''
        }}
        onClick={focus}>
        <Editor
          placeholder={placeholder || '在此填写内容'}
          ref={editor as any}
          onFocus={onFocus}
          onTab={onTab}
          editorState={editorState}
          onChange={onEditorChange}
          handlePastedText={handlePastedText}
          blockRendererFn={blockRender}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          blockRenderMap={blockRenderMap}
          blockStyleFn={blockStyleFn}
          customStyleFn={styleFn}
          customStyleMap={styleMap}
        />
      </div>
    </div>
    {renderPreview}
  </>
  );
}