import React from "react"

import { useUIPageStyles } from "../../../../assets/jss/pages"
import Icon from "../../../../components/Icon"

import Box from '@material-ui/core/Box';
import FormField from '../../../../components/ui/form/FormField'
import TextInput from '../../../../components/ui/form/TextInput'
import ImageInput from '../../../../components/ui/form/ImageInput'
import RadioInput from '../../../../components/ui/form/RadioInput'
import ArticleCatInput from '../../../../components/content/CatInput';
import Editor from '../../../../components/ui/editor'

import { DataSource } from "../../../../types/ui"
import { ImageText } from "./types"

type Props = {
    dataSource?: DataSource,
    data?: ImageText,
    onChange: (dataSource: DataSource, data?: ImageText) => void
}

// 组件数据输入
export default (props: Props) => {
    const classes = useUIPageStyles()
    const { dataSource, data, onChange } = props

    const [inputType, setInputType] = React.useState(dataSource && dataSource.type !== "input" ? "1" : "0")

    const [loaded, setLoaded] = React.useState(false)
    const [imageLoaded, setImageLoaded] = React.useState(false)

    const [form, setForm] = React.useState<ImageText>(data || {
        title: "",
        content: "",
        link: "",
        image: ""
    })
    const setFormItem = (key: "title" | "content" | "link" | "image", value: any) => {
        const newForm = form
        newForm[key] = value;
        setForm(Object.assign({}, newForm))
        onChange({ type: "input" }, form)
    }

    const renderForm = <Box>
        <TextInput
            labelWidth={120}
            value={`${form.title || ''}`}
            fullWidth
            label="标题："
            placeholder="请输入标题..."
            onChange={(event: any) => {
                setFormItem("title", event.target.value);
            }} />
        <ImageInput
            labelWidth={120}
            value={`${form.image || ''}`}
            label="图片："
            onChange={(url: string) => {
                setFormItem("image", url)
            }}
            loaded={imageLoaded}
            onLoaded={() => { setImageLoaded(true) }} />
        <TextInput
            labelWidth={120}
            value={`${form.link || ''}`}
            fullWidth
            label="链接地址："
            placeholder="链接地址由http://或https://开头..."
            onChange={(event: any) => {
                setFormItem("link", event.target.value);
            }} />
        <FormField
            labelWidth={120}
            label="内容：">
            <Editor
                value={`${form.content || ''}`}
                placeholder="请输入内容..."
                loaded={loaded}
                onLoaded={() => {
                    setLoaded(true)
                }}
                onChange={(value: string) => {
                    setFormItem("content", value)
                }}
                fixed={false}
                options={[
                    'fontWeight',
                    'fontStyle',
                    'underline',
                    'lineThrough',
                    'fontFamily',
                    'lineHeight',
                    'fontSize',
                    'fontColor',
                    'alignment',
                    'link',
                    'clear',
                ]
                } />
        </FormField>
    </Box>

    // 选择分类导入文章
    const renderImports = <Box>
        <ArticleCatInput
            label=""
            labelWidth={120}
            value={dataSource && dataSource.catId}
            onChange={(item: any) => {
                onChange({ type: "articleCat", catId: item.id }, form)
            }}>
            <Box className={classes.startTips} style={{paddingTop:20}}>
            <Icon name="Alert" className={classes.icon} />
            请选择单页栏目类型分类！
           </Box>
        </ArticleCatInput>
    </Box>

    return <Box>
        <RadioInput
            label=""
            labelWidth={120}
            value={inputType}
            options={[
                { label: "录入数据", value: "0" },
                { label: "关联文章分类", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                setInputType(value)
            }} />
        {inputType === "0" && renderForm}

        {inputType === "1" && renderImports}
    </Box>
}