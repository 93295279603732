import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as contentTypes from '../../../types/content'


// Action Types
export type Action = {
    // 文章分类列表
    type: 'ARTICLE_CAT_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type: 'ARTICLE_CAT_FETCH_LIST_SUCCESS', payload: any }
    | { type: 'ARTICLE_CAT_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 启用文章分类列表
        type: 'ARTICLE_CAT_FETCH_ACTIVE_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'ARTICLE_CAT_FETCH_ACTIVE_LIST_SUCCESS', payload: any }
    | { type: 'ARTICLE_CAT_FETCH_ACTIVE_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 文章分类
        type: 'ARTICLE_CAT_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'ARTICLE_CAT_FETCH_SUCCESS', payload: { cat: contentTypes.Cat } }
    | { type: 'ARTICLE_CAT_FETCH_FAILURE', payload: { error: types.Error } }
    | {
        // 分类名称
        type: 'ARTICLE_CAT_FETCH_NAMES',
        payload: { client: 'default', request: AxiosRequestConfig }
    } 
    | { type: 'ARTICLE_CAT_FETCH_NAMES_SUCCESS', payload: any }
    | { type: 'ARTICLE_CAT_FETCH_NAMES_FAILURE', payload: { error: types.Error } }
    | {
        // 上级分类
        type: 'ARTICLE_CAT_FETCH_PARENT',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'ARTICLE_CAT_FETCH_PARENT_SUCCESS', payload: any }
    | { type: 'ARTICLE_CAT_FETCH_PARENT_FAILURE', payload: { error: types.Error } }
    | {
        // 保存分类
        type: 'ARTICLE_CAT_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | {
        // 删除分类
        type: 'ARTICLE_CAT_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | {
        // 设置状态
        type: 'ARTICLE_CAT_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };




// 分类列表
export const FETCH_LIST = 'ARTICLE_CAT_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'ARTICLE_CAT_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'ARTICLE_CAT_FETCH_LIST_FAILURE';
export const fetchList = (params?:any): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/getList`,
            method: 'GET',
            params
        }
    }
})
export const fetchListSucess = (list:contentTypes.Cat[]): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});



// 启用的分类列表
export const FETCH_ACTIVE_LIST = 'ARTICLE_CAT_FETCH_ACTIVE_LIST'
export const FETCH_ACTIVE_LIST_SUCCESS = 'ARTICLE_CAT_FETCH_ACTIVE_LIST_SUCCESS';
export const FETCH_ACTIVE_LIST_FAILURE = 'ARTICLE_CAT_FETCH_ACTIVE_LIST_FAILURE';
export const fetchActiveList = (params?:any): Action => ({
    type: FETCH_ACTIVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/getActiveList`,
            method: 'GET',
            params
        }
    }
})
export const fetchActiveListSucess= (list: contentTypes.Cat[]): Action => ({
    type: FETCH_ACTIVE_LIST_SUCCESS,
    payload: {
        list
    }
});
export const fetchActiveListFailure= (error:types.Error): Action => ({
    type: FETCH_ACTIVE_LIST_FAILURE,
    payload: {
        error
    }
});


// 分类
export const FETCH = 'ARTICLE_CAT_FETCH'
export const FETCH_SUCCESS = 'ARTICLE_CAT_FETCH_SUCCESS';
export const FETCH_FAILURE = 'ARTICLE_CAT_FETCH_FAILURE';
export const fetch= (id: string): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/get`,
            method: 'GET',
            params:{
                id
            }
        }
    }
})
export const fetchSucess = (cat: contentTypes.Cat): Action => ({
    type: FETCH_SUCCESS,
    payload: { 
        cat
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});


// 分类名称
export const FETCH_NAMES = 'ARTICLE_CAT_FETCH_NAMES'
export const fetchNames = (id:string): Action => ({
    type: FETCH_NAMES,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/getNames`,
            method: 'GET',
            params: {
                id
            }
        }
    }
})
// 保存分类
export const SAVE = 'ARTICLE_CAT_SAVE'
export const save= (data: any): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/save`,
            method: 'POST',
            data
        }
    }
})

// 删除分类
export const REMOVE = 'ARTICLE_CAT_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})

// 设置分类状态
export const SET_STATUS = 'ARTICLE_CAT_SET_STATUS'
export const setStatus = (data: any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/cat/setStatus`,
            method: 'GET',
            data: {
                ...data
            }
        }
    }
})