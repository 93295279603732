import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
})=>(
<svg className={className}
 viewBox="0 0 1024 1024"
  version="1.1" xmlns="http://www.w3.org/2000/svg" 
  p-id="2978"  width={width} height={height}>
  <path fill={color} d="M961.536 673.1776a187.050667 187.050667 0 0 0-232.413867-26.385067l-158.0032-158.9248L968.533333 88.064 909.448533 28.672 512 428.373333 114.653867 28.672 55.569067 88.064 452.949333 487.867733l-157.969066 158.9248a188.757333 188.757333 0 1 0 32.8704 293.307734c63.010133-63.3856 71.748267-160.9728 26.2144-233.847467L512 547.328l158.037333 158.9248c-45.533867 72.874667-36.795733 170.461867 26.180267 233.847467a186.811733 186.811733 0 0 0 265.3184 0 189.781333 189.781333 0 0 0 0-266.922667zM268.765867 880.674133a103.7312 103.7312 0 0 1-147.114667 0 105.130667 105.130667 0 0 1 0-148.036266 103.594667 103.594667 0 0 1 147.114667 0 105.2672 105.2672 0 0 1 0 148.036266z m633.685333 0a103.765333 103.765333 0 0 1-147.114667 0 105.2672 105.2672 0 0 1 0-148.036266 103.594667 103.594667 0 0 1 147.114667 0 105.2672 105.2672 0 0 1 0 148.036266z" p-id="2979"></path></svg>
)