import React, { useState, useEffect } from 'react';
import { Modifier, EditorState, AtomicBlockUtils, ContentBlock, SelectionState } from 'draft-js';
import Icon from './Icon'
import Button from './Button'
import UploadDialog from '../../upload/UploadDialog'
import { attachPrefix } from '../../../../config'
import { replaceEntity, setBlockData, removeEntity } from '../utils';
import Control from './MediaControl'

const Media = (props: any) => {
    const {
        onChange,
        editorState,
        title,
        mediaPluginProps,
        setMediaPluginProps
    } = props

    let {
        uploadType,
        open,
        controlOpen,
        attributes,
        mediaProps,
        replace
    } = mediaPluginProps

    let mediaType = uploadType
    if (uploadType === 'image' || uploadType === 'images') {
        mediaType = 'image'
    } else if (uploadType === 'video' || uploadType === 'videos') {
        mediaType = 'video'
    } else if (uploadType === 'audio' || uploadType === 'audios') {
        mediaType = 'audio'
    } else if (uploadType === 'media' || uploadType === 'medias') {
        mediaType = 'media'
    } else if (uploadType === 'flash' || uploadType === 'flashs') {
        mediaType = 'flash'
    } else if (uploadType === 'file' || uploadType === 'files') {
        mediaType = 'file'
    }
    // 修改插件元素
    const setPluginItem = (key: string, value: any) => {
        const newProps = mediaPluginProps
        newProps[key] = value
        setMediaPluginProps(Object.assign({}, newProps))
    }

    // 设置
    const addBlockAlignmentData = (value: string, editorState: EditorState) => {
        return setBlockData(editorState, { 'textAlign': value })
    }
    const remove = () => {
        let nextState = removeEntity({
            editorState,
            ...mediaProps
        })
        if (nextState) {
            nextState = EditorState.moveFocusToEnd(nextState)
            onChange(nextState);
        }
    }
    /**
     * 更新entity
     * @param props 更新的参数
     */
    const updateMedia = (replaceProps: any) => {
        let nextState = replaceEntity({
            replaceProps,
            mediaProps,
            editorState
        })
        onChange(nextState);
    }

    /**
     * 新增一条
     * @param props 更新的参数
     */
    const addMedia = (src: string, attributes: any, editorState: EditorState) => {
        let entityKey
        const contentState = editorState.getCurrentContent()
        const data = {
            src,
            attributes,
            onClick: (props: any, attributes: any) => {
                setPluginItem('uploadType', mediaType)
                setPluginItem('mediaProps', props)
                setPluginItem('attributes', attributes)
                setPluginItem('controlOpen', true)
            }
        }
        let contentStateWithEntity
        contentStateWithEntity = contentState.createEntity(
            mediaType,
            'IMMUTABLE',
            data
        );
        entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            { currentContent: contentStateWithEntity },
        );
        let nextState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
        return nextState
    }

    const handleUpload = (type: string) => {
        setPluginItem('uploadType', type)
        setPluginItem('open', true)
        setPluginItem('replace', false)
        setPluginItem('controlOpen', false)
    }

    return (<>
        <Button title={title[0]}
            onClick={(e: any) => {
                handleUpload('images')
            }} >
            <Icon name="Images" width={18} height={18} />
        </Button>
        <Button title={title[1]}
            onClick={(e: any) => {
                handleUpload('videos')
            }} >
            <Icon name="Video" width={18} height={18} />
        </Button>
        <Button title={title[2]}
            onClick={(e: any) => {
                handleUpload('audios')
            }} >
            <Icon name="Audio" width={18} height={18} />
        </Button>
        <UploadDialog
            {...{
                type: uploadType,
                open,
                defaultValue: [],
                success: (props: any) => {
                    // 单文件/图片上传
                    if (uploadType === 'image'
                        || uploadType === 'video'
                        || uploadType === 'audio'
                        || uploadType === 'media'
                        || uploadType === 'flash'
                        || uploadType === 'file'
                        || replace) {
                        const attaches = props.attaches
                        let att = props.attach || (attaches && attaches.length > 0 && attaches[0])
                        let src = att ? attachPrefix + att.url : props.url
                        if (replace) {
                            // 更新
                            updateMedia({ src, attributes: props.attributes })
                        } else {
                            addMedia(src, props.attributes, editorState);
                        }
                    } else {
                        // 多文件/图片上传
                        let srcs = []
                        let attaches = props.attaches
                        if (attaches) {
                            attaches.map((attach: any) => srcs.push(attachPrefix + attach.url))
                        }
                        if (props.url && srcs.length === 0) {
                            srcs.push(props.url)
                        }
                        // 触发调用页面事件
                        let state = editorState
                        srcs.forEach((src: string, index: number) => {
                            let nextState = addMedia(src, props.attributes, state)
                            state = nextState
                        })
                        if (state) {
                            state = EditorState.moveFocusToEnd(state)
                            state = addBlockAlignmentData('', state)
                            onChange(state);
                        }
                    }
                    setPluginItem('open', false)
                    if (replace) {
                        setPluginItem('controlOpen', false)
                    }
                },
                onClose: () => {
                    setPluginItem('open', false)
                }
            }} />
        {controlOpen &&
            <Control {...{
                open: controlOpen,
                attributes,
                onReplace: () => {
                    setPluginItem('replace', true)
                    setPluginItem('open', true)
                },
                onRemove: (e: any) => {
                    remove()
                },
                changeAttributes: (attributes: any) => {
                    updateMedia({ attributes })
                },
                close: () => {
                    setPluginItem('controlOpen', false)
                }
            }}></Control>}
    </>
    );
}

export default Media