import React from "react"

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/ReplyOutlined';

import Icon from '../../Icon'
import { useListPageStyles } from '../../../assets/jss/pages'


export const TableRowToolbar = (props: any) => {

  const classes = useListPageStyles()

  const {
    editLabel,
    deleteLabel,
    saveLabel,
    previewLabel,
    onPreview,
    onEdit,
    onDelete,
    onSave,
    onCancel
  } = props

  return <div>
    {onEdit &&
      <Tooltip title={editLabel} aria-label="add">
        <IconButton
          className={classes.rowButton}
          onClick={onEdit}>
          <EditIcon className={classes.icon} />
        </IconButton>
      </Tooltip>}
    {onPreview &&
      <Tooltip title={previewLabel} aria-label="add">
        <IconButton
          className={classes.rowButton}
          onClick={onPreview}>
          <Icon name="Preview" className={classes.icon} width={18} height={18} />
        </IconButton>
      </Tooltip>
    }
    {onDelete &&
      <Tooltip title={deleteLabel} aria-label="add">
        <IconButton
          className={classes.rowButton}
          onClick={onDelete}>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      </Tooltip>}

    {onSave &&
      <Tooltip title={saveLabel} aria-label="add">
        <IconButton
          className={classes.rowButton}
          onClick={onSave}>
          <SaveIcon className={classes.icon} />
        </IconButton>
      </Tooltip>}

    {onCancel &&
      <Tooltip title="撤消" aria-label="add">
        <IconButton
          className={classes.rowButton}
          onClick={onCancel}>
          <CancelIcon className={classes.icon} />
        </IconButton>
      </Tooltip>}
  </div>
}
export default TableRowToolbar