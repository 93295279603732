import { combineReducers } from 'redux';

import {State} from '../../state/ui';

import {
    initialState as navInitialState,
    reducer as  navReducer
} from './nav';

import {
    initialState as pageInitialState,
    reducer as pageReducer
} from './page';

import {
    initialState as componentInitialState,
    reducer as componentReducer
} from './component';

import {
    initialState as datatypeInitialState,
    reducer as datatypeReducer
} from './datatype';

export const initialState: State = {
    nav: navInitialState,
    page: pageInitialState,
    component: componentInitialState,
    datatype: datatypeInitialState
};

export const reducers = combineReducers<State>({
    nav: navReducer,
    page: pageReducer,
    component:componentReducer,
    datatype:datatypeReducer
});  