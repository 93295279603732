
import React from 'react'
import {
  EditorState,
} from 'draft-js';

import { convertToHTML } from 'draft-convert'
import * as styles from './styles'
import { renderNode } from '../nodes/Media'
import { renderLink } from '../nodes/Link'

// custom styles
const convertStyle = (style: string) => {
  if (style && styles.cssProperies) {
    const result = styles.cssProperies({}, style)
    const css = result[style]
    if (css) {
      return <span style={css.style} />;
    }
  }
}
export default (editorState: EditorState) => {

  const html = convertToHTML({
    styleToHTML: (style: any) => {
      return convertStyle(style);
    },

    blockToHTML: (block: any) => {
      switch (block.type) {
        case 'bold':
          return <b />;
        case 'italic':
          return <i />;
        case 'paragraph':
          return <p />;
        case 'code':
          return <pre style={styles.code} />;
        case 'code-block':
          return <pre style={styles.code} />;
        case 'pre':
          return <pre style={styles.code} />;
        case 'heading-one':
          return <h1 style={{ ...block.data }} />;
        case 'heading-two':
          return <h2 style={{ ...block.data }} />;
        case 'heading-three':
          return <h3 style={{ ...block.data }} />;
        case 'heading-four':
          return <h4 style={{ ...block.data }} />;
        case 'heading-five':
          return <h5 style={{ ...block.data }} />;
        case 'heading-six':
          return <h6 style={{ ...block.data }} />;
        case 'blockquote':
          return <blockquote style={styles.blockquote} />;
        case 'unstyled':
          return <div style={{ ...block.data }} />;
        case 'section':
          return <section style={{ ...block.data }} />;
      }
    },

    entityToHTML: (entity: any, originalText: string) => {
      const { url, src, attributes } = entity.data
      if (entity.type === 'audio' || entity.type === 'image' || entity.type === 'video') {
        return renderNode(entity.type, src, attributes)
      }
      switch (entity.type) {
        case 'LINK':
          return renderLink(url,originalText)
        default:
          return originalText;
      }
    }

  })(editorState.getCurrentContent());

  return html
}
