
import React, { useEffect } from 'react'

import Box from '@material-ui/core/Box';
import CircularProgress from './CircularProgress';

import { useLoadMoreStyles } from '../../assets/jss/components';

type Props = {
    element: HTMLElement | null,
    loadData:(cb:(status:"ready" |"noMore" | "error")=>void)=>void,
    showReady?:boolean,
    options?:{
        loadingText?:string,
        noMoreText?:string,
        defaultText?:string
    } 
}  
const pageY=(elem:any):number=>{
        return elem.offsetParent ? elem.offsetTop + pageY(elem.offsetParent) : elem.offsetTop;
}
/**
 * 分页
 * @param props 
 */
export default (props: Props) => {

    const {element,options = {},loadData,showReady} = props
    const classes = useLoadMoreStyles()

    const [status, setStatus] = React.useState<"ready" | "loadBegin"| "loading" | "noMore" | "error">("ready")

    const handleScroll = (element: HTMLElement) => {
        // 屏慕滚动
        const scrollTop = window.pageYOffset;
        const windowHeight = window.innerHeight
        // 节点
        const offsetTop = pageY(element)
        const { scrollHeight } = element;
        // 节点+坐标的总高度
        const height = offsetTop + scrollHeight
        if(height > 0){
            // 滚动的总高度=节点总高度
            const isBottom = scrollTop + windowHeight  > height;
            if(isBottom === true && status ==="ready"){
                setStatus("loadBegin")
            }
        }
    };
    useEffect(() => {
        if (element) {
            window.addEventListener("scroll", () => handleScroll(element));
            return () => {
                window.removeEventListener("scroll", () => handleScroll(element));
            };
        }
    },[])

    const loadingText = options.loadingText || "正在加载更多..."
    const noMoreText = options.noMoreText || "〜到底了~"
    const defaultText =options.defaultText ||  "上拉加载更多."

    const render = ()=>{

        switch (status) {
            case "ready":
                if(showReady) 
                return <Box className={classes.defaultBox}>
                    <span className={classes.defaultText}>{defaultText}</span>
                </Box>
                return <></>
            case "loadBegin":
                setStatus("loading")
                loadData((status:"ready" | "noMore" | "error")=>{
                        setStatus(status)
                })
            case "loading":
                return <Box className={classes.loadingBox}>
                    <CircularProgress />
                    <span className={classes.loadingText}>{loadingText}</span>
                </Box>
            case "noMore":
                return <Box className={classes.noMoreBox}>
                    <span className={classes.noMoreText}>{noMoreText}</span>
                </Box>
            case "error":
                return <Box className={classes.errorBox}>
                    <span className={classes.errorText}>加载失败！</span>
                </Box>

        }
        return <></>
    }
    return render()
}