import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';

import Icon from './Icon'
import Button from './Button'


export default (props: any) => {
    const {
        onChange,
        editorState,
        title,
        icon
    } = props

    const [undoDisabled, setUndoDisabled] = useState(true)
    const [redoDisabled, setRedoDisabled] = useState(true)
    const query = () => {
        setUndoDisabled(editorState.getUndoStack().size === 0)
        setRedoDisabled(editorState.getRedoStack().size === 0)
    }
    useEffect(query)

    /**
     * 切换
     */
    const handleChange = (action: string) => {
        let nextState
        if (action === 'redo')
            nextState = EditorState.redo(editorState);
        if (action === 'undo')
            nextState = EditorState.undo(editorState);
        if (nextState) {
            onChange(nextState);
        }
    }

    return (<>
        <Button
            onMouseDown={()=>handleChange('undo')}
            value="undo"
            disabled={undoDisabled} 
            title={title[0]} > 
            <Icon name="Undo"
                color={undoDisabled ? '#333' : '#484848'}
                width={18} height={18} />
        </Button>
        <Button
            onMouseDown={()=>handleChange('redo')}
            value="redo"
            disabled={redoDisabled}
            title={title[1]} >
            <Icon name="Redo"
                color={redoDisabled ? '#aaa' : '#484848'}
                width={18} height={18} />
        </Button>
    </>
    );
}