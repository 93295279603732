import React, { useState, useEffect } from 'react';

import classNames from "classnames"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import IconArrow from "@material-ui/icons/ArrowRight"


import { attachPrefix } from '../../config';
import { useLocale } from '../../config/Locale';
import FormField from '../ui/form/FormField'
import Search from '../ui/Search';
import { useBrand } from '../../provides/shopping'
import * as types from '../../types/shopping'

import Icon from '../Icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    label: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(1,0)
    },
    input: {
      display: "flex",
      justifyContent: "flex-start"
    },
    value: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#666",
    },
    items: {
      display: "flex",
      flexWrap: "wrap",
      padding:theme.spacing(1,1,2,2)
    },
    item: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: 4,
      padding:theme.spacing(1),
      minWidth: 120,
      '&:hover': {
        backgroundColor: "#f0f0f0",
      },
      '& svg': {
        '& path': {
          fill: "#666"
        }
      }
    },
    box:{
      border: "1px solid #dedede",
      borderRadius:4
    },
    selected: {
      backgroundColor: "#f7f7f7",
      color: "#ff6600",
      '& svg': {
        '& path': {
          fill: "#ff6600"
        }
      }
    },
    iconArrow:{
      transform: "rotate(90deg)",
      transition: "all 150ms ease-in",
    },
    iconArrowActive:{
      transform: "rotate(-90deg)"
    },
    tips: {
      padding: theme.spacing(1, 0),
      fontSize: 12,
      color: "#999"
    },
    logo:{
      maxWidth:120,
      maxHeight:60,
      border:"none"
    },
    alert:{
      padding:theme.spacing(2,0),
      color:"#777"
    }
  })
);


export type BrandInputProps = {
  value?: any,
  onChange: (value:types.Brand) => void,
  loaded:boolean,
  onLoaded:()=>void,
  tips?:string
}
export default function BrandInput(props: BrandInputProps) {
  const { onChange, value,loaded,onLoaded,tips } = props
  const [open, setOpen] = useState(false)
  const { translate } = useLocale()

  const [keyword,setKeyword] = useState<string>('')
  const [list,setList] = useState<types.Brand[]>([])

  const { getListByName } = useBrand()

  const search = (keyword:string) => {
      getListByName({
        params:{
          keyword
        },
        success: (list: types.Brand[]) => {
          setList(list)
        },
        failure: (message: string) => {
          console.log(message)
          setList([])
        }
      })
  }
  useEffect(()=>{
    if(!loaded){
        onLoaded()
        search(keyword)
    }
    return ()=>{}
  });

  const handleChange = (value:types.Brand) => {
    onChange(value);
    setCollapse(false)
  };

    const [collapse, setCollapse] = useState(!value);
    const handleCollapse = () => {
        setCollapse(prev => !prev);
    }

  const classes = useStyles();

  const items = <Box className={classes.items}>
    {list.map((brand: types.Brand) => (
      <span
        className={classNames(classes.item, {
          [classes.selected]: value && value.id === brand.id
        })}
        onClick={() => handleChange(brand)}
        key={brand.id}>
         <img src={`${attachPrefix}/${brand.image}`} className={classes.logo}/>
        <span className={classes.label}>{brand.name}</span>
      </span>))}
  </Box>

  return (<Box >
        
        {value && value.image && value.name && <Box className={classes.input}>
              <Box className={classes.value}>
              <img src={`${attachPrefix}/${value.image}`} className={classes.logo}/>
              <span className={classes.label}>{value.name}</span>
              </Box>
              <Box>
                <IconButton 
                        component="span"
                        onClick={handleCollapse}>
                        <IconArrow className={classNames(classes.iconArrow, {
                            [classes.iconArrowActive]: collapse
                        })} />
              </IconButton>
            </Box>
        </Box>}
        {list.length > 0 ? 
        <Collapse in={collapse}>
        <Box className={classes.box}>
          <Box>
          <Search
            value={keyword || ""}
            onChange={(keyword: string) => {
              setKeyword(keyword)
              search(keyword)
            }}
          />
          </Box>
          {items}
          </Box>
       </Collapse>:<Box className={classes.alert}>
       品牌列表是空的，请点击菜单“品牌管理”添加品牌！
       </Box>}
        {tips && <Box className={classes.tips}>{tips}</Box>}
    </Box>);
}

