
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useEditPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      fontSize: '1rem'
    },
    heading: {
      display:"flex",
      color: "#666",
      alignItems: "center",
      fontSize: theme.typography.pxToRem(15),
      padding:theme.spacing(1),
      background:"#f7f7f7",
      borderBottom:"1px solid #f0f0f0"
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      background: `#fff`,
      marginTop: 10,
      height: 38,
      borderBottom: '1px solid #dedede'
    },
    contentContainer: {
      flex: 1,
    },
    margin: {
      margin: theme.spacing(1, 0)
    },
    thumbImage: {
      position: 'relative',
      width: 100,
      height: 100,
    },
    thumbButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      textAlign: 'center',
      verticalAlign: 'middile'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    }
  }))