import React from 'react'
import * as styles from '../renders/styles'

/**
 * 属性转换为样式
 * @param attributes 属性
 */
export const attrToStyle = (attributes: any) => {
    const regPercent = /\d+%/g
    const reg = /\d+/g;
    let width = 'auto', height = 'auto'
    let wrapperStyle: React.CSSProperties = {}
    let style: React.CSSProperties = {
        maxWidth: '100%',
        border: 0
    }
    if (attributes) {
        if (attributes.width) {
            let result = attributes.width.match(regPercent)
            if (result) {
                width = result
            } else {
                result = attributes.width.match(reg)
                if (result) {
                    width = result + 'px'
                }
            }
        }
        if (attributes.height) {
            let result = attributes.height.match(regPercent)
            if (result) {
                height = result
            } else {
                result = attributes.height.match(reg)
                if (result) {
                    height = result + 'px'
                }
            }
        }
        if (attributes.clear === 'both' || attributes.textAlign === 'center') {
            wrapperStyle = attributes.clear === 'both' ? {
                clear: 'both'
            } : {
                    textAlign: 'center'
                }
            style = {
                maxWidth: '100%',
                border: 0,
                width: `${width}`,
                height: `${height}`
            };
        } else {
            style = {
                maxWidth: '100%',
                border: 0,
                width: `${width}`,
                height: `${height}`,
                float:attributes.float?attributes.float:''
            };
        }
    }
    return { wrapperStyle, style }
}
export const renderNode = (type: string, src: string, attributes: any, ) => {
    if (type === 'audio' || type === 'image' || type === 'video') {
        const { wrapperStyle, style } = attrToStyle(attributes)
        let node
        if (type === 'video') {
            node = <video controls src={src} style={style} />
        } else if (type === 'audio') {
            node = <audio controls src={src} style={style} />
        } else {
            node = <img src={src} style={style} alt={attributes && attributes.alt || ''} />
        }

        if (Object.keys(wrapperStyle).length > 0) {
            return <div style={wrapperStyle}>{node}</div>
        } else {
            return node
        }
    }
}

export const nodeToHtml = (type: string, src: string, attributes: any, ) => {
    if (type === 'audio' || type === 'image' || type === 'video') {
        const { wrapperStyle, style } = attrToStyle(attributes)
        const cssText = styles.cssToHtml(style)
        let node
        if (type === 'video') {
            node = `<video controls src="${src}" style="${cssText}" />`
        } else if (type === 'audio') {
            node = `<audio controls  src="${src}" style="${cssText}" />`
        } else {
            node = `<img src="${src}" style="${cssText}" alt="${attributes && attributes.alt}" />`
        }

        if (Object.keys(wrapperStyle).length > 0) {
            const wrapperCssText = styles.cssToHtml(wrapperStyle)
            return `<div style="${wrapperCssText}">${node}</div>`
        } else {
            return node
        }
    }
}


export default (props: any) => {
    const { block, contentState } = props
    if (!block) {
        return <></>
    }
    if (!block.getCharacterList().get(0)) {
        return <></>
    }
    const entityKey = props.entityKey || block.getCharacterList().get(0).getEntity()
    const entity = contentState.getEntity(entityKey);
    const data = entity.getData();
    if (!data) {
        return <>{props.children}</>
    }
    const { src, attributes, onClick } = data

    return (<span>
        <span onMouseDown={() => {
            onClick(props, attributes)
        }}>
            {renderNode(entity.type, src, attributes)}
        </span>
    </span >)
}