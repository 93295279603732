import React from "react"

import { RadioInputOption } from "../../../../../components/ui/form/types"
import FormField from '../../../../../components/ui/form/FormField'
import ColorInput from '../../../../../components/ui/form/ColorInput'
import TextInput from '../../../../../components/ui/form/TextInput'
import NumberInput from '../../../../../components/ui/form/NumberInput'
import RadioInput from '../../../../../components/ui/form/RadioInput'
import ImageInput from '../../../../../components/ui/form/ImageInput'
import ImagesInput from '../../../../../components/ui/form/ImagesInput'
import IconInput from '../../../../../components/ui/form/IconInput';
import Editor from '../../../../../components/ui/editor'
import CarouselSettingsInput from '../CarouselSettingInput'
import ButtonsInput from '../ButtonsInput'
import LinksInput from '../LinksInput'

import { ComponentOption } from "../../../../../types/ui"
import { CarouselSetting, SectionButton, Link, OptionValue } from "../types"

type OptionInputProps = {
    value?: any,
    option: ComponentOption,
    onChange: (name: string, value: any) => void,
    showLabel?: boolean
}
// 组件配置输入
export default (props: OptionInputProps) => {
    const { value, option, onChange, showLabel = true } = props

    const [loaded, setLoaded] = React.useState(false)
    const [imageLoaded, setImageLoaded] = React.useState(false)
    const [imagesLoaded, setImagesLoaded] = React.useState(false)

    const labelWidth = showLabel ? showLabel ? 150 : 0 : 0;
    const label = showLabel ? `${option.alias}：` : "";

    let input = <></>
    if (option.variableType === "string" || option.variableType === "link") {
        // 字符或数值
        input = <TextInput
            labelWidth={labelWidth}
            value={`${value || ''}`}
            fullWidth
            label={label}
            placeholder={option.description}
            onChange={(event: any) => {
                onChange(option.name, event.target.value)
            }} />
    } else if (option.variableType === "number") {
        // 数值
        input = <NumberInput
            labelWidth={labelWidth}
            value={value}
            label={label}
            onChange={(value: any) => {
                onChange(option.name, value)
            }} />
    } else if (option.variableType === "string|number") {
        // 字符或数值
        input = <TextInput
            labelWidth={labelWidth}
            value={value}
            label={label}
            placeholder={option.description}
            onChange={(event: any) => {
                onChange(option.name, event.target.value)
            }} />
    } else if (option.variableType === "boolean") {
        // 布尔值
        input = <RadioInput
            labelWidth={labelWidth}
            label={label}
            value={value ? "1" : "0"}
            options={[
                { label: "否", value: "0" },
                { label: "是", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                if (value === "1") {
                    onChange(option.name, true)
                } else {
                    onChange(option.name, "")
                }
            }} />
    } else if (option.variableType === "enum" && option.variableValues) {
        // 枚举
        const options: RadioInputOption[] = []
        let values = []
        if (option.variableValues.startsWith("[") && option.variableValues.endsWith("]")) {
            // 数组
            values = JSON.parse(option.variableValues)
            values.map((value: any) => options.push({
                label: value.label,
                value: value.value,
            }))
        } else {
            values = option.variableValues.split(",")
            values.map((value: string | number) => options.push({
                label: value.toString(),
                value: value.toString(),
            }))
        }
        input = <RadioInput
            labelWidth={labelWidth}
            label={label}
            value={value}
            options={options}
            onChange={(e: any, value: any) => {
                onChange(option.name, value)
            }} />
    } else if (option.variableType === "Link[]") {
        // 链接数组
        input = <LinksInput
            data={value}
            onChange={(links: Link[]) => {
                onChange(option.name, links)
            }}
        />
    } else if (option.variableType === "image") {
        // 图片
        input = <ImageInput
            labelWidth={labelWidth}
            value={value}
            label={label}
            deleteButton
            onChange={(value: string) => {
                onChange(option.name, value)
            }}
            loaded={imageLoaded}
            onLoaded={() => { setImageLoaded(true) }} />

    } else if (option.variableType === "image[]") {
        // 图片数组
        input = <ImagesInput
            labelWidth={labelWidth}
            value={value}
            label={label}
            onChange={(value: string) => {
                onChange(option.name, value)
            }}
            loaded={imagesLoaded}
            onLoaded={() => { setImagesLoaded(true) }} />
    } else if (option.variableType === "color") {
        // 取色器
        input = <ColorInput
            labelWidth={labelWidth}
            inputWidth={80}
            value={value}
            label={label}
            onChange={(value: string) => {
                onChange(option.name, value)
            }} />
    } else if (option.variableType === "html") {
        // html文本
        input = <FormField
            labelWidth={labelWidth}
            label={label}>
            <Editor
                value={value}
                placeholder={option.description}
                loaded={loaded}
                onLoaded={() => {
                    setLoaded(true)
                }}
                onChange={(value: string) => {
                    onChange(option.name, value)
                }}
                fixed={false}
                options={[
                    'fontWeight',
                    'fontStyle',
                    'underline',
                    'lineThrough',
                    'fontFamily',
                    'lineHeight',
                    'fontSize',
                    'fontColor',
                    'alignment',
                    'link',
                    'clear',
                ]
                } />
        </FormField> 
    } else if (option.variableType === "CarouselSetting") {
        input = <CarouselSettingsInput
            value={value} 
            onChange={(value: CarouselSetting) => {
                onChange(option.name, value)
            }}
        />
    } else if (option.variableType === "Button[]") {
        input = <ButtonsInput
            data={value}
            onChange={(value: SectionButton[]) => {
                onChange(option.name, value)
            }}
        />
    } else if (option.variableType === "icon") {
        // 图标
        input = <IconInput
            labelWidth={labelWidth}
            value={value}
            label={label}
            onChange={(value: string) => {
                onChange(option.name, value)
            }}
        />
    }
    return input
}