import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "svg-icon"
}) => (<svg className={className} viewBox="0 0 1024 1024"
    version="1.1" xmlns="http://www.w3.org/2000/svg"
    p-id="3052" width={width} height={height}>
    <path fill={color} d="M896 256h-768a25.6 25.6 0 0 1 0-51.2h768a25.6 25.6 0 0 1 0 51.2zM896 409.6h-563.2a25.6 25.6 0 0 1 0-51.2h563.2a25.6 25.6 0 0 1 0 51.2zM896 563.2h-768a25.6 25.6 0 0 1 0-51.2h768a25.6 25.6 0 0 1 0 51.2zM896 716.8h-563.2a25.6 25.6 0 0 1 0-51.2h563.2a25.6 25.6 0 0 1 0 51.2zM896 870.4h-768a25.6 25.6 0 0 1 0-51.2h768a25.6 25.6 0 0 1 0 51.2z"  p-id="3053"></path></svg>
    )