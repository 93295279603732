import { saveLocale, getLocale } from "../../storage/ConfigStorage";

import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/app";
import { useConfig } from "./ConfigProvide";

import { words as defaultWords } from "../../config/lang/zh";
export const localeNames = [
  {
    name: "简体中文",
    locale: "zh"
  },
  {
    name: "English",
    locale: "en"
  }
];
// 获取语种名称
export const getName = (locale: string) => {
  let name;
  localeNames.map(l => {
    if (l.locale === locale) {
      name = l.name;
      return;
    }
  });
  return name;
};

interface StateResult {
  account: any;
  theme: any; 
}

interface Result {
  setLocaleConfig: Function;
}

/**
 * 生成语种数据
 */
export const useLocale = (): Result => {
  const dispatch = useDispatch()
  const { configFactory } = useConfig();

  // 切换语种
  const setLocaleConfig = async (l?: string) => {
    // 无值，则获取本地结果
    const locale = l || getLocale();
    const { words } = await import(`../../config/lang/${locale}`);
    const value = words || defaultWords;
    // 获取语种名称
    const name = () => getName(locale);
    const localeConfig = {
      localeNames,
      words: { ...value },
      locale,
      name
    };
    // 保存语种标识到本地
    saveLocale(locale); 
    // 执行redux
    dispatch(actions.localeConfig(localeConfig));
    // 数据工厂初始化配置数据
    configFactory();
    return Promise.resolve(localeConfig);
  };
  return {
    setLocaleConfig
  };
};
