import React, { useEffect } from 'react';
// import 'react-dates/initialize';
// import 'bootstrap/dist/css/bootstrap.css';
import './assets/style.scss'

import { useLocale, useConfig } from './provides/app'
import { useAccount } from './provides/sys'
import { useDatatype } from './provides/ui';
import { useModule } from './provides/common';
// import { useTheme } from './provides/app/ThemeLogic'

import Page from './Page'

const App = () => {
  const { configFactory } = useConfig();
  const { setLocaleConfig } = useLocale()
  const { fetchAppToken } = useAccount()
  const getDatatypeList = useDatatype().getList
  const getModuleList = useModule().getList
  // const { setTheme } = useTheme()

  // 应用初始化，不能与mapState同步进行，否则会陷入无限循环
  useEffect(() => {
    // 初始化
    fetchAppToken()
    configFactory()
    setLocaleConfig()
    getDatatypeList()
    getModuleList()
    // setTheme()
    return () => {
      console.log('initial...')
    };
  }, []);

  return <Page />
}

export default App