import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import * as Config from '../../../config'
import {attachPrefix,ORDER_STATUS} from '../../../config'
// 表单组件
import FormField from '../../../components/ui/form/FormField'
import Editor from "../../../components/ui/editor"
// 弹框
import { LoadingDialog, MessageDialog } from '../../../components/ui/dialog'

import { useLocale } from '../../../config/Locale'

import { useRefund } from '../../../provides/trade'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      '& button':{
        margin:theme.spacing(1)
      }
    },
    images:{
      padding:theme.spacing(1),
      '& img':{
        maxWidth:'100%',
        border:0
      }
    }
  }))

type Props = {
  order:any,
  success:(postd:any)=>void,
  close:()=>void
}

export default (props: Props) => {
  const {order,success,close} = props
  const classes = useStyles()
  const {refund} = order
  const { translate } = useLocale()
  const { auditOk,auditFail,payment } = useRefund()

  const [postd,setPostd] = useState<any>(null)
    // 编辑器加载状态
  const [loaded, setLoaded] = React.useState<boolean>(false)
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const [form, setForm] = useState<any>(refund)
  // 设置表单元素
  const setFormItem = (key: string, value: any) => {
    form[key] = value;
    setForm(Object.assign({}, form))
  }
  const handlePayment = () => {
      setPostd(null)
      // 打开进度条
      setLoading({
        open: true,
        text: translate('loadingText.submit')
      })
      payment({
        data: {
          orderId: order.id,
          refundAmount:order.amount
        },
        success: (order: any) => {
          setPostd(order)
          setLoading({
            open: false,
            text: ''
          })
          setMessage({
            open: true,
            type: 'success',
            title: '提示',
            text: '退款申请已提交支付平台处理，请关注资金账户！'
          })
        },
        failure: (msg: string) => {
          setPostd(null)
          setLoading({
            open: false,
            text: ''
          })
          setMessage({
            open: true,
            type: 'error',
            title: '提示',
            text: msg
          })
        }
      })
  }


  const handleAuditOk = () => {
    setPostd(null)
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    auditOk({
      data: {
        orderId: order.id,
        reply:form.reply
      },
      success: (order: any) => {
        setPostd(order)
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: '已将通过退款申请'
        })
      },
      failure: (msg: string) => {
        setPostd(null)
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }

  const handleAuditFail = () => {
    setPostd(null)
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    auditFail({
      data: {
        orderId: order.id,
        reply:form.reply
      },
      success: (order: any) => {
        setPostd(order)
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: '已将申请处理为退款申请不通过'
        })
      },
      failure: (msg: string) => {
        setPostd(null)
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }

  const renderPageForm = (<Box>
      <FormField labelWidth={120} label="退款状态：">
      {ORDER_STATUS[order.status]}
      </FormField>
      <FormField labelWidth={120} label="订单号码：">{order.no}</FormField>
      <FormField labelWidth={120} label="下单用户：">{order.username}</FormField>
      <FormField labelWidth={120} label="订单金额：">{order.amount}</FormField>
      <FormField labelWidth={120} label="订单时间：">{order.createTime}</FormField>
      <FormField labelWidth={120} label="退款原因：">{ refund.reason && refund.reason.value}</FormField>
      <FormField labelWidth={120} label="退款说明：">
      <div dangerouslySetInnerHTML={{__html:refund.descri || '无'}}/>
      </FormField>
      <FormField labelWidth={120} label="附件：">

      <div className={classes.images}>
      {refund.images.length === 0 && "没有上传附件."}
      {refund.images.map((image:string,index:number)=><img src={attachPrefix+image} key={index}/>)}
      </div>
      </FormField>
     
      <Editor {...{
        value:form.reply,
        placeholder:"请填写退款回复..",
        loaded,
        onLoaded:() => { 
            setLoaded(true)
        },
        onChange:(value: string) => {
            setFormItem("reply",value)
        },
        options:[],
    }} />

    <FormField labelWidth={0} label="">
      {
        order.status === '20' && <Box className={classes.buttons}>
        <Button 
        onClick={handleAuditOk} 
        variant="outlined">退款申请通过</Button>
        <Button 
        onClick={handlePayment} 
        variant="outlined">退款申请通过并立即退回资金</Button>
        <Button 
        onClick={handleAuditFail}
        variant="outlined">退款申请不通过</Button>
        </Box>
      }

      {
        order.status === '21' && <Box className={classes.buttons}>
        <Button 
        onClick={handlePayment} 
        variant="outlined">退回资金</Button>
        </Box>
      }

    </FormField>

  </Box>)


  return (<Box>
    <Box style={{
      flex: 1
    }}>
      {renderPageForm}
    </Box>
    <LoadingDialog open={loading.open} text={loading.text} />
    <MessageDialog
      onClose={() => {
        if(postd){
          success(postd)
        }else{
          close()
        }
        setMessage(initialMessage)
      }}
      message={message}
    />
  </Box>
  )
}