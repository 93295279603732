import React from "react"
import clsx from "clsx"
import Checkbox from '@material-ui/core/Checkbox';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import { HeadTableCell } from './TableCell'
import { useLocale } from '../../../config/Locale'

import { useListPageStyles } from '../../../assets/jss/pages'

type Field = {
    id: string,
    numeric?: boolean,
    disablePadding?: boolean,
    label: string
}
type Props = {
    showRowToolbar?: boolean,
    checkbox?: boolean,
    fields: Field[],
    order?: 'asc' | 'desc',
    orderBy?: string,
    onSelectAllClick?: (event: any) => void,
    numSelected?: number,
    rowCount?: number,
    onRequestSort?: (event: any, property: any) => void
}

export const TableHead = (props: Props) => {
    const classes = useListPageStyles()
    const { translate } = useLocale()
    const { showRowToolbar = true, checkbox, fields, onSelectAllClick, order = "asc", orderBy, numSelected = 0, rowCount = 0, onRequestSort } = props;
    const createSortHandler = (property: any) => (event: any) => {
        if (onRequestSort)
            onRequestSort(event, property);
    };

    return (
        <MuiTableHead>
            <MuiTableRow>
                {
                    checkbox &&
                    <HeadTableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                            color="default"
                            className={classes.checked}
                            checkedIcon={<span className={clsx(classes.checkBoxIcon, classes.checkedIcon)} />}
                            icon={<span className={classes.checkBoxIcon} />}
                        />
                    </HeadTableCell>}
                {fields.map(row => (
                    <HeadTableCell
                        key={row.id}
                        align="left"
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <MuiTableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? translate('desc') : translate('asc')}
                                </span>
                            ) : null}
                        </MuiTableSortLabel>
                    </HeadTableCell>
                ))}
                {showRowToolbar &&
                    <HeadTableCell>
                    </HeadTableCell>}
            </MuiTableRow>
        </MuiTableHead>
    );
}
export default TableHead