import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
})=>(
    <svg className={className}  viewBox="0 0 1024 1024" version="1.1"
     xmlns="http://www.w3.org/2000/svg" p-id="3241" width={width} height={height}>
     <path d="M736 448H288c-19.2 0-32 12.8-32 32s12.8 32 32 32h192v96h-160c-19.2 0-32 12.8-32 32s12.8 32 32 32h160v96c0 19.2 12.8 32 32 32s32-12.8 32-32v-96h160c19.2 0 32-12.8 32-32s-12.8-32-32-32h-160v-96h192c19.2 0 32-12.8 32-32s-12.8-32-32-32zM489.6 406.4c6.4 6.4 16 9.6 22.4 9.6s16-3.2 22.4-9.6l128-128c12.8-12.8 12.8-32 0-44.8s-32-12.8-44.8 0L512 339.2l-105.6-105.6c-12.8-12.8-32-12.8-44.8 0s-12.8 32 0 44.8l128 128z" 
     fill={color} p-id="3242"></path>
     <path d="M512 64C352 64 208 147.2 128 281.6V256c0-19.2-12.8-32-32-32s-32 12.8-32 32v128c0 19.2 12.8 32 32 32h128c19.2 0 32-12.8 32-32s-12.8-32-32-32H163.2c64-137.6 198.4-224 348.8-224 211.2 0 384 172.8 384 384s-172.8 384-384 384c-153.6 0-294.4-92.8-352-233.6-6.4-16-25.6-22.4-41.6-16-16 6.4-22.4 25.6-16 41.6C169.6 854.4 332.8 960 512 960c246.4 0 448-201.6 448-448S758.4 64 512 64z"
     fill={color} p-id="3243"></path></svg>
)