import React from "react";

import TextDataInput from "./TextDataInput";
import CatListDataInput from "./CatListDataInput";
import ImageTextDataInput from "./ImageTextDataInput";
import ImageLinksDataInput from "./ImageLinksDataInput";
import ProductListDataInput from "./ProductListDataInput";
import ImageTextListDataInput from "./ImageTextListDataInput";
import CarouselDataInput from "./CarouselDataInput";
import TextCarouselDataInput from "./TextCarouselDataInput";
import TabListInput from "./TabListInput";
import SpecialTabListInput from "./SpecialTabListInput";
import CardListInput from "./CardListInput";
import ShoppingSplashInput from "./ShoppingSplashInput";

import { DataSource, PageSetting } from "../../../../types/ui";
import {
  Text,
  ImageText,
  ImageLink,
  ImageTextListItem,
  CarouselItem,
  CardItem,
  TabItem,
  SpecialTabItem,
  ShoppingSplash,
  SettingData
} from "./types"

type DataInputProps = {
  dataSource: DataSource;
  data: SettingData;
  datatype: string;
  onChange: (dataSource: DataSource, data?: SettingData) => void;
};

// 组件数据输入
export default (props: DataInputProps) => {
  const { dataSource, data, datatype, onChange } = props;

  switch (datatype) {
    case "Cat[]":
      // 分类列表
      return <CatListDataInput
        dataSource={dataSource}
        onChange={onChange}
      />;
    case "Text":
      // 文本
      return (
        <TextDataInput
          dataSource={dataSource}
          data={data && data.text}
          onChange={(dataSource: DataSource, text?: Text) => onChange(dataSource, { text })}
        />
      );
    case "ImageText":
      // 图文
      return (
        <ImageTextDataInput
          dataSource={dataSource}
          data={data && data.imageText}
          onChange={(dataSource: DataSource, imageText?: ImageText) => onChange(dataSource, { imageText })}
        />
      );
    case "ImageTextListItem[]":
      // 图文列表
      return (
        <ImageTextListDataInput
          dataSource={dataSource}
          data={data && data.imageTexts}
          onChange={(dataSource: DataSource, imageTexts?: ImageTextListItem[]) => onChange(dataSource, { imageTexts })}
        />
      );
    case "ImageLink[]":
      // 图文列表
      return (
        <ImageLinksDataInput
          dataSource={dataSource}
          data={data && data.imageLinks}
          onChange={(dataSource: DataSource, imageLinks?: ImageLink[]) => onChange(dataSource, { imageLinks })}
        />
      );
    case "TabItem[]":
      // 标签页
      return <TabListInput
        data={data && data.tabs}
        onChange={(dataSource: DataSource, tabs?: TabItem[]) => onChange(dataSource, { tabs })}
      />
    case "SpecialTabItem[]":
      // 专用标签页
      return <SpecialTabListInput
        data={data && data.specialTabs}
        dataSource={dataSource}
        onChange={(dataSource: DataSource, specialTabs?: SpecialTabItem[]) => onChange(dataSource, { specialTabs })}
      />
    case "CardItem[]":
      // 卡片
      return <CardListInput
        data={data && data.cards}
        onChange={(dataSource: DataSource, cards?: CardItem[]) => onChange(dataSource, { cards })}
      />
    case "CarouselItem[]":
      // 轮播
      return (
        <CarouselDataInput
          dataSource={dataSource}
          data={data && data.carousels}
          onChange={(dataSource: DataSource, carousels?: CarouselItem[]) => onChange(dataSource, { carousels })}
        />
      );
    case "TextCarouselItem[]":
      // 文本轮播
      return <TextCarouselDataInput
        data={data && data.textCarousels}
        onChange={(dataSource: DataSource, textCarousels?: PageSetting[]) => onChange(dataSource, { textCarousels })}
      />
    case "Product[]":
      // 商品列表
      return <ProductListDataInput
        dataSource={dataSource}
        onChange={(dataSource: DataSource) => onChange(dataSource)}
      />
    case "ShoppingSplash":
      // 商品分类列表+轮播+专用标签页
      return <ShoppingSplashInput
        data={data && data.shoppingSplash}
        onChange={(dataSource: DataSource, shoppingSplash?: ShoppingSplash) => onChange(dataSource, { shoppingSplash })}
      />
    case "Promotion[]":
      // 优惠活动列表
      return <></>;
    case "ContentSplash":
      // 商品分类列表+轮播+专用标签页
      return <></>;
    default:
      break;
  }
  return <></>;
};
