import React from 'react';
import { EditorState, Modifier,RichUtils } from 'draft-js';

import Icon from './Icon'
import Button from './Button'
import {styles} from '../renders'

export default (props: any) => {
    const {
        onChange,
        editorState,
        title,
        icon
    } = props

    /**
     * 切换样式
     */
    const toggleChange = (e: any) => {
        e.preventDefault();
        // remove default inlineStyles
        let contentState = editorState.getCurrentContent();
        [
            'BOLD',
            'ITALIC',
            'UNDERLINE',
            'STRIKETHROUGH',
            'MONOSPACE',
            'SUPERSCRIPT',
            'SUBSCRIPT',
        ].forEach((style) => {
            contentState = Modifier.removeInlineStyle(
                contentState,
                editorState.getSelection(),
                style,
            );
        });
        // remove customstyles
        let nextState = EditorState.push(editorState, contentState, 'change-inline-style')
        Object.keys(styles).forEach((key:any)=>{
            nextState = styles[key].remove(nextState);
        })
        // remove links
        if(RichUtils.currentBlockContainsLink(editorState)){
            nextState = RichUtils.toggleLink(nextState, editorState.getSelection(), null)
        }
        // push change 
        if(nextState){
            onChange(nextState)
        }
    }

    return (<>
        <Button
            onMouseDown={toggleChange}
            title={title[0]} >
            <Icon name={icon}
                width={18} height={18} />
        </Button>
    </>
    );
}