import { AxiosRequestConfig } from 'axios';
import { API,appId,appSecret } from '../../../config'
import * as types from '../../../types/sys'

// Action Types
export type Action ={
  type: 'LOGIN',
  payload: {
    client: 'default',
    request: AxiosRequestConfig
  }
} | {
  type: 'LOGIN_SUCCESS',
  payload: {
    account: types.Account
  }
} | {
  type: 'LOGIN_FAILURE',
  payload: {
    code: number,
    message: string
  }
} | {
  type: 'LOGOUT',
  payload: {
    client: 'default',
    request: AxiosRequestConfig
  }
} | {
  type: 'LOGOUT_FINISHED',
  payload: {
    code: number,
    message: string
  }
} | {
  type: 'FETCH_APP_TOKEN',
  payload: {
    client: 'default',
    request: AxiosRequestConfig
  }
} | {
  type: 'FETCH_APP_TOKEN_SUCCESS',
  payload: {
    appToken: string
  }
} | {
  type: 'FETCH_APP_TOKEN_FAILURE',
  payload: {
    code: number,
    message: string
  }
} ;

// 登录
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const login = (data: any): Action => ({
  type: LOGIN,
  payload: {
    client: 'default',
    request: {
      url: `${API}login`,
      method:'POST',
      data:{
        ...data
      }
    }
  }
})
export const loginSuccess = (account:types.Account):Action => ({
  type: LOGIN_SUCCESS,
  payload:{
    account
  }
})
export const loginFailure = (code:number,message:string): Action => ({
  type: LOGIN_FAILURE,
  payload:{
    code,message
  }
})

/** 退出  */
export const LOGOUT = 'LOGOUT';
export const LOGOUT_FINISHED = 'LOGOUT_FINISHED';
export const logout = (): Action => ({
  type: LOGOUT,
  payload: {
    client: 'default',
    request: {
      url: `${API}logout`,
      method:'POST'
    }
  }
})
export const logoutFinished = (code:number,message:string):Action => ({
  type: LOGOUT_FINISHED,
  payload:{
    code,message
  }
})


/** 获取appToken  */
export const FETCH_APP_TOKEN = 'FETCH_APP_TOKEN';
export const FETCH_APP_TOKEN_SUCCESS = 'FETCH_APP_TOKEN_SUCCESS';
export const FETCH_APP_TOKEN_FAILURE = 'FETCH_APP_TOKEN_FAILURE';
export const fetchAppToken = (): Action => ({
  type: FETCH_APP_TOKEN,
  payload: {
    client: 'default',
    request: {
      url: `${API}token`,
      method:'POST',
      data: {
        appId,
        appSecret
      }
    }
  }
})

export const fetchAppTokenSuccess = (appToken:string):Action => ({
  type: FETCH_APP_TOKEN_SUCCESS,
  payload:{
    appToken
  }
})

