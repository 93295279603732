
import React, { useState } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import IconBack from '@material-ui/icons/ArrowBackIos'; 
import Toolbar from "@material-ui/core/Toolbar";
import Dialog  from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseButton from '../../../components/ui/button/CloseButton';

import { useLocale } from '../../../config/Locale'

import DialogActions from '@material-ui/core/DialogActions';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import {withDrawerStyles,useDrawerStyles} from "../../../assets/jss/components/ui/drawerStyles"

import SimpleUpload from './SimpleUpload'
import TextInput from '../../../components/ui/form/TextInput'

import Icon from '../../../components/Icon'
import { AlertDialog } from '../../../components/ui/dialog'

// 分辨率适配
import { useMediaMatches, MOBILE } from "../../../components/MediaMatches"

const Drawer = withDrawerStyles(SwipeableDrawer)

const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      background: '#f5f5f5',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px solid #dedede',
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
  }))

const DialogTitle = (props: any) => {
  const classes = useStylesTitle()
  const { children, onClose } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span>{children}</span>
      {onClose ? (
        <span className={classes.closeButton} onClick={(e) => { onClose(e) }}>
          <CloseButton open={true} close={onClose} />
        </span>
      ) : null}
    </MuiDialogTitle>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      background: `-webkit-linear-gradient(#f9f9f9, #f0f0f0);
      -o-linear-gradient(#f9f9f9, #f0f0f0);
      -moz-linear-gradient(#f9f9f9, #f0f0f0);
      linear-gradient(#f9f9f9, #f0f0f0)`,
      height: 38
    },
    contentContainer: {
      flex: 1,
      padding: 0,
    },
    thumbImage: {
      position: 'relative',
      width: 100,
      height: 100,
    },
    thumbButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      textAlign: 'center',
      verticalAlign: 'middile'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    footerInputs: {
      display: 'inline-flex',
    },
    footerInput: {
      margin: theme.spacing(0, 1, 0, 0)
    },
    alert: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alertIcon: {
      margin: theme.spacing(2)
    }
  }))


const SimpleUploadDialog = (props: any) => {
  const { open, success, onClose, fromEditor,onProgress,type } = props;
  const { translate } = useLocale()
  const classes = useStyles()
  const drawerClasses = useDrawerStyles()
  const matches = useMediaMatches()

  const [progress,setProgress] = useState(null as any)

  const maxWidth = 'md';
  // 图片尺寸，编辑器使用 
  const [imgOptions, setImgOptions] = useState({
    width: '',
    height: '',
    alt: ''
  })

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })


  const handleClose = () => {
    if(progress && progress.progress === true){
      const description = (<Box className={classes.alert}>
        <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
        <Box>
          {/* 正在上传文件，关闭窗口会中断上传!
        确定要继续么？ */}
          <Box>{translate('upload.interruptTips')}</Box>
          <Box>{translate('upload.interruptAsk')}</Box>
        </Box>
      </Box>)
      setAlertOpen(true)
      setAlertProps({
        description,
        title: '',
        ok: () => {
          setAlertOpen(false)
          setProgress(null)
          onClose()
        },
        cancel: () => {
          setAlertOpen(false)
        }
      })
    }else{
      onClose()
    }
  }
  const UploadTitle = <>
  {type === 'image' && translate('upload.chooseImage')}
  {type === 'file' && translate('upload.chooseFile')}
  </>

  const UploadBody = <>
        <Box className={classes.contentContainer}>
                  <SimpleUpload
                    {...{
                      onProgress:(props:any)=>{
                        setProgress(props)
                        if(onProgress){
                          onProgress(props)
                        }
                      },
                      success: (acceptedFiles: any) => {
                        success(acceptedFiles)
                      },
                      type
                    }} />
        </Box>
  </>

  const UploadActions = <>
          <Box className={classes.footerInputs}>
            <Box component="span" className={classes.footerInput}>
              <TextInput
                labelWidth={60}
                defaultValue={imgOptions.alt}
                label={`ALT：`}
                onChange={(event: any) => {
                  imgOptions.alt = event.target.value
                  setImgOptions(Object.assign({}, imgOptions))
                }} />
            </Box>
            <Box component="span" className={classes.footerInput}>
              <TextInput
                labelWidth={60}
                defaultValue={imgOptions.width}
                inputWidth={60}
                label={`${translate('width')}：`}
                onChange={(event: any) => {
                  imgOptions.width = event.target.value
                  setImgOptions(Object.assign({}, imgOptions))
                }} />
            </Box>
            <Box component="span" className={classes.footerInput}>
              <TextInput
                labelWidth={80}
                defaultValue={imgOptions.height}
                inputWidth={60}
                label={`${translate('height')}：`}
                onChange={(event: any) => {
                  imgOptions.height = event.target.value
                  setImgOptions(Object.assign({}, imgOptions))
                }} />
            </Box>
          </Box>
  </>

  const renderDialog =   <Dialog
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}>
      <DialogTitle onClose={handleClose}>
      {UploadTitle}
      </DialogTitle>
      <DialogContent className={classes.content} dividers={true}>
      {UploadBody}
      </DialogContent>
      {fromEditor && (
        <DialogActions>
          {UploadActions}
        </DialogActions>
      )}
    </Dialog>

  const renderDrawer =  <Drawer
            anchor="right"
            open={open}
            onOpen={()=>{}}
            onClose={handleClose}
            variant="temporary">
            <Box className={drawerClasses.page}>
            <Box className={drawerClasses.header}>
            <Toolbar className={drawerClasses.toolbar}>
                <IconButton onClick={handleClose}>
                    <IconBack />
                </IconButton>
                <Box className={drawerClasses.title}>
                     {UploadTitle}
                </Box>
                </Toolbar>
            </Box>
            <Box className={drawerClasses.content}>
                      {UploadBody}
            </Box>
            {fromEditor && (
            <Box className={drawerClasses.footer}>
                      {UploadActions}
            </Box>
               )}
            </Box>
        </Drawer>

  return (<>
      {matches === MOBILE ? renderDrawer:renderDialog}

      {/* 警告框 */ }
  <AlertDialog open={alertOpen} {...alertProps} />
  </>);
}
export default SimpleUploadDialog