import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "svg-icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="3585" width={width} height={height}>
    <path fill={color} d="M665.6 460.373333c41.173333-28.8 70.4-75.306667 70.4-119.04 0-96.213333-74.453333-170.666667-170.666667-170.666666H298.666667v597.333333h300.373333c89.386667 0 158.293333-72.533333 158.293333-161.706667 0-64.853333-36.906667-120.106667-91.733333-145.92zM426.666667 277.333333h128c35.413333 0 64 28.586667 64 64s-28.586667 64-64 64h-128v-128z m149.333333 384h-149.333333v-128h149.333333c35.413333 0 64 28.586667 64 64s-28.586667 64-64 64z" p-id="3586"></path></svg>
    )
