import React from 'react'
import { ContentBlock, DefaultDraftBlockRenderMap } from 'draft-js';
import Immutable from 'immutable'

import Media from '../nodes/Media'

export const blockRender = (contentBlock: any) => {
  const type = contentBlock.getType();
  switch (type) {
    case 'atomic':
      return {
        component: Media,
        editable: false
      };
  }
  // return null
}

export const newBlockRenderMap = Immutable.Map({
  'bold': {
    element: 'b'
  },
  'italic': {
    element: 'i',
  },
  'code': {
    element: 'pre'
  },
  'code-block': {
    element: 'pre',
  },
  'heading-one': {
    element: 'h1'
  },
  'heading-two': {
    element: 'h2'
  },
  'heading-three': {
    element: 'h3'
  },
  'heading-four': {
    element: 'h4'
  },
  'heading-five': {
    element: 'h5'
  },
  'heading-six': {
    element: 'h6'
  },
  'blockquote': {
    element: 'blockquote'
  },
  'paragraph': {
    element: 'p',
  },
  'unstyled': {
    element: 'div'
  },
  'section': {
    element: 'section'
  }
});

export const blockRenderMap = DefaultDraftBlockRenderMap.merge(
  newBlockRenderMap
);


export const blockStyleFn = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();
  console.log('blockStyleFn>type>', type)
  const textAlign = contentBlock.getData() && contentBlock.getData().get('textAlign');
  if (textAlign) {
    return `block-text-align--${textAlign.trim()}`;
  }
  return `block--${type}`;
}

