import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import TreeItem from './TreeItem'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            margin: 0
        }
    }))

const Tree = (props: any) => {
    const {
        isOpen,
        children,
        ...other
    } = props
    const classes = useStyles()
    return (
        <Box>
            <TreeItem 
            isOpen={isOpen}
            existsChildren={true}
            {...other} />
            {isOpen && (
                <Box className={classes.list}>
                    {children}
            </Box>)}
        </Box>
    );
}


export default Tree;
