import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
 viewBox="0 0 1024 1024" version="1.1" 
 xmlns="http://www.w3.org/2000/svg" p-id="8430" width={width} height={height}><path fill={color} d="M950.891516 170.970419H334.989681l-6.209864 215.82274h32.215528c4.919769-49.251956 16.307422-127.116387 34.051354-149.786232 17.743933-22.680084 43.445502-34.035996 77.071944-34.035996h114.270721v565.08743c0 20.115251-3.76585 33.411426-11.287311 39.87931-7.563441 6.46174-65.386341 10.831683-93.038412 13.081159v32.010751h315.616747v-32.010751c-27.658214-2.2505-77.901291-6.619419-85.454493-13.081159-7.526581-6.46686-11.297551-19.764058-11.297551-39.87931V202.970931h113.148543c33.653063 0 59.317772 11.355912 77.071944 34.035996 17.743933 22.669845 29.000528 94.428848 33.742141 143.675685h33.107332l-7.106788-209.712193zM66.719439 538.042523l-3.595885 124.954965h18.652119c2.848449-28.515206 9.441247-73.596877 19.714912-86.722063 10.273665-13.131329 25.153791-19.705697 44.62297-19.705697h66.159374v247.299023c0 11.645672-2.180876 19.344265-6.53546 23.088614-4.379158 3.741277-37.857136 6.271298-53.866607 7.57368v18.533348h182.733838V834.531045c-16.012543-1.302382-45.102149-3.832403-49.475163-7.57368-4.357656-3.744349-6.541603-11.442942-6.541603-23.088614v-247.299023h65.510231c19.484538 0 34.343162 6.574368 44.62297 19.705697 10.273665 13.125186 16.790695 54.671381 19.535732 83.184539h19.168157l-4.114995-121.417441H66.719439z" p-id="8431"></path></svg>
)
