import React from 'react';
import { saveHistory} from '../storage/RouteStorage'

import NotLiveRoute from './LiveRouter'
import { withRouter } from "react-router-dom";

import { useConfigState } from "../provides/app";
import * as types from '../types/app'

const LiveRoute = withRouter(NotLiveRoute)

export const AppRoute = (route:types.Route) => {
  return (
    <LiveRoute
      path={route.path}
      exact={route.exact}
      alwaysLive={true}
      component={route.component}
      onHide={(location) => {
         saveHistory(location.pathname)
      }}
      onReappear={(location) => {
         saveHistory(location.pathname)
      }}
  />);
}
export default () => {
  const { config } = useConfigState();

  return (<div>
    {config.routes.map((route:any,index:number) => (
      <AppRoute key={index} {...route} />
    ))}

    <LiveRoute
      path='/icons'
      key='/icons'
      exact
      alwaysLive={true}
      component={require('../pages/sys/Icons').default}
  />
  </div>);
}