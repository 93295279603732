import { AxiosRequestConfig } from "axios";
import { API } from "../../../config";
import * as types from "../../../types";
import * as uiTypes from "../../../types/ui";

// Action Types
export type Action =
  | {
      // 列表
      type: "UI_COMPONENT_FETCH_LIST";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | { type: "UI_COMPONENT_FETCH_LIST_SUCCESS"; payload: any }
  | {
      type: "UI_COMPONENT_FETCH_LIST_FAILURE";
      payload: { error: types.Error };
    }
  | {
      // 获取
      type: "UI_COMPONENT_FETCH";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
    | {
        // 获取
        type: "UI_COMPONENT_FETCH_BY_NAME";
        payload: { client: "default"; request: AxiosRequestConfig };
      }
  | { type: "UI_COMPONENT_FETCH_SUCCESS"; payload: any }
  | { type: "UI_COMPONENT_FETCH_FAILURE"; payload: { error: types.Error } }
  | {
      // 保存
      type: "UI_COMPONENT_SAVE";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 删除
      type: "UI_COMPONENT_REMOVE";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 设置状态
      type: "UI_COMPONENT_SET_STATUS";
      payload: { client: "default"; request: AxiosRequestConfig };
    };

// 列表
export const FETCH_LIST = "UI_COMPONENT_FETCH_LIST";
export const FETCH_LIST_SUCCESS = "UI_COMPONENT_FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "UI_COMPONENT_FETCH_LIST_FAILURE";
export const fetchList = (): Action => ({
  type: FETCH_LIST,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/component/getList`,
      method: "GET"
    }
  }
});
export const fetchListSucess = (list: uiTypes.Component[]): Action => ({
  type: FETCH_LIST_SUCCESS,
  payload: {
    list
  }
});
export const fetchListFailure = (error: types.Error): Action => ({
  type: FETCH_LIST_FAILURE,
  payload: {
    error
  }
});

// 获取
export const FETCH = "UI_COMPONENT_FETCH";
export const FETCH_BY_NAME = "UI_COMPONENT_FETCH_BY_NAME";
export const FETCH_SUCCESS = "UI_COMPONENT_FETCH_SUCCESS";
export const FETCH_FAILURE = "UI_COMPONENT_FETCH_FAILURE";
export const fetch = (id: any): Action => ({
  type: FETCH,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/component/get`,
      method: "GET",
      params:{
        id
      }
    }
  }
});

export const fetchByName = (name: any): Action => ({
  type: FETCH_BY_NAME,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/component/getByName`,
      method: "GET",
      params:{
        name
      }
    }
  }
});

export const fetchSucess = (component: uiTypes.Component): Action => ({
  type: FETCH_SUCCESS,
  payload: {
    component
  }
});
export const fetchFailure = (error: types.Error): Action => ({
  type: FETCH_FAILURE,
  payload: {
    error
  }
});

// 保存
export const SAVE = "UI_COMPONENT_SAVE";
export const save = (data: any): Action => ({
  type: SAVE,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/component/save`,
      method: "POST",
      data
    }
  }
});

// 删除
export const REMOVE = "UI_COMPONENT_REMOVE";
export const remove = (id: string[]): Action => ({
  type: REMOVE,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/component/del`,
      method: "POST",
      data: {
        id
      }
    }
  }
});

// 设置状态
export const SET_STATUS = "UI_COMPONENT_SET_STATUS";
export const setStatus = (data:any): Action => ({
  type: SET_STATUS,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/component/setStatus`,
      method: "POST",
      data
    }
  }
});
