import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconAdd from '@material-ui/icons/Add';
import IconClear from '@material-ui/icons/Clear';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

// 弹框
import { LoadingDialog,MessageDialog } from '../../../components/ui/dialog'
import { useLocale } from '../../../config/Locale'
// 逻辑
import { useIndexed } from '../../../provides/common'
 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons:{
      padding:theme.spacing(2),
      '& button':{
        marginRight:theme.spacing(2)
      }
    },
  }));

export default ()=>{
  const {  
    articleIndexed,
    clearArticleIndexed,
    productIndexed,
    clearProductIndexed 
  } = useIndexed()
  const { translate } = useLocale()

  const classes = useStyles()
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)


  const onIndexed = (mode:"articleIndexed"|"clearArticleIndexed"|"productIndexed"|"clearProductIndexed") => {
    let postMethod
    if(mode === "articleIndexed"){
      postMethod = articleIndexed
    }else if(mode === "clearArticleIndexed"){
      postMethod = clearArticleIndexed
    }else if(mode === "productIndexed"){
      postMethod = productIndexed
    }else if(mode === "clearProductIndexed"){
      postMethod = clearProductIndexed
    }
    if(!postMethod)
      return;
    
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    postMethod({
      success: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }

  const renderPageTitle = (
    <Box className="page-title">
      {translate('common.indexed.pageTitle')}
    </Box>)

  return <Box>
    {renderPageTitle}
    <Divider />
    <Box className={classes.buttons}>
      <Button variant="outlined" onClick={()=>onIndexed("articleIndexed")} ><IconAdd/>创建文章索引 </Button>
      <Button variant="outlined" onClick={()=>onIndexed("clearArticleIndexed")}><IconClear/>清除文章索引</Button>
    </Box>
    <Box className={classes.buttons}>
      <Button variant="outlined" onClick={()=>onIndexed("productIndexed")}><IconAdd/>创建商品索引</Button>
      <Button variant="outlined" onClick={()=>onIndexed("clearProductIndexed")}><IconClear/>清除商品索引</Button>
    </Box>
    <LoadingDialog open={loading.open} text={loading.text} />
    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
  </Box>
}