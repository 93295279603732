import * as actions from '../../store/actions/common/attach'
import { useDispatch } from "react-redux";
/**
 * 组织逻辑
 */
export const useAttach = () => {
  const dispatch:Function = useDispatch();

  // 获取附件列表
  const getAttachList = (props: any) => {
    const { params, success, failure } = props

    dispatch(actions.fetchList(params)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        const list = data.result.list
        const pagination = data.result.pagination
        // 通知Redux
        dispatch(actions.fetchListSucess(list, pagination))
        // 成功返回
        if (success) {
          success({
            list,
            pagination,
            message: message || '请求成功'
          })
        }
      } else {
        // 失败
        const error = {
          code: data && data.code || 0,
          message: message || '请求失败'
        }
        dispatch(actions.fetchListFailure(error))
        // 失败返回
        failure(message || '请求失败')
      }
    })
  }
  // 获取附件列表
  const getAttachListByUrls = (props: any) => {
    const { urls, success, failure } = props

    dispatch(actions.fetchListByUrls(urls)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        const list = data.result.list
        // 成功返回
        if (success) {
          success(list)
        }
      } else {
        // 失败返回
        failure(message || '请求失败')
      }
    })
  }


  // 获取附件分组列表
  const getAttachGroupList = (props: any) => {
    const { success, failure } = props

    dispatch(actions.fetchGroupList()).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        const list = data.result.list
        // 通知Redux
        dispatch(actions.fetchGroupListSucess(list))
        // 成功返回
        if (success) {
          success(message || '请求成功')
        }
      } else {
        // 失败
        const error = {
          code: data && data && data.code || 0,
          message: message || '请求失败'
        }
        dispatch(actions.fetchGroupListFailure(error))
        // 失败返回
        failure(message || '请求失败')
      }
    })
  }

  // 移动附件
  const moveAttach = (props: any) => {
    const { success, failure, id, groupId } = props
    // 提交redux
    dispatch(actions.move(id, groupId)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        if (success) {
          success(data.message || '移动图片完成')
          // 刷新附件列表
          // getAttachList({
          //   groupId,attType
          // })
          // getAttachList({
          //   prevGroupId,attType
          // })
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '移动图片失败')
      }
    })
  }

  // 保存分组
  const saveGroup = (props: any) => {
    const { success, failure, group } = props
    // 提交redux 
    dispatch(actions.saveGroup(group)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        // 成功返回
        if (success) {
          success(data.message || '保存成功')
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '添加失败')
      }
    })
  }

  // 删除分组
  const removeGroup = (props: any) => {
    const { success, failure, id } = props
    // 提交redux
    dispatch(actions.removeGroup(id)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        if (success) {
          success(data.message || '删除成功')
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '删除失败')
      }
    })
  }

  // 删除分组
  const removeAttach = (props: any) => {
    const { success, failure, id } = props
    // 提交redux
    dispatch(actions.remove(id)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        if (success) {
          success(data.message || '删除成功')
        }
      } else {
        // 失败返回
        const message = data && data.message
        failure(message || '删除失败')
      }
    })
  }
  // 返回实时信息提交页面处理
  return {
    getAttachList,
    getAttachListByUrls,
    getAttachGroupList,
    moveAttach,
    saveGroup,
    removeGroup,
    removeAttach
  }
}
