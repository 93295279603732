import { makeStyles, createStyles ,Theme} from '@material-ui/core/styles';
export const useGalleryStyles = makeStyles((theme:Theme) => createStyles({
  grow:{
    flexGrow:1
  },
  clearfix:{
    clear:"both"
  },
  staticContainer:{
    display: "block",
    position:"relative",
    overflow: "hidden",
  },
  item:{
    display: "flex",
    position:"relative",
    backgroundRepeat:'no-repeat',
    backgroundPosition: "50%",
    backgroundSize: "auto 100%",
    overflow:"hidden",
    '& img':{
      border:'none',
      margin:2
    }
  },
  imageItem:{
    cursor:"pointer",
    border:"2px solid transparent"
  },
  imageItemSelected:{
    border:"2px solid #ff6600"
  },
  itemInner:{
    flex:1,
    display:"flex",
    alignItems:"center",
    overflow:"hidden",
    width: "100%",
    height: "100%",
  },
  empty:{
    flexGrow:1,
    overflow:'hidden'
  },
  body:{
    zIndex:99,
    padding:theme.spacing(3),
    cursor:"pointer",
    textAlign:"left",
    maxWidth:"70%",
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-start",
    overflow:"hidden",
    '& a':{
      color:"#fff",
      textDecoration:"none",
      '&:hover':{
        textDecoration:"none",
      }
    },
    "@media (max-width:650px)": {
      maxWidth:"100%",
      padding:theme.spacing(2),
    },
  },
  alignCenter:{
    textAlign:"center",
    alignItems:"center",
    maxWidth:"60%",
    margin:"0 auto",
    "@media (max-width:650px)": {
      maxWidth:"100%",
    },
  },
  alignRight:{
    textAlign:"right",
    float:"right",
    alignItems:"center",
  },
  title:{
    fontSize:'1.8em',
    fontFamily:"微软雅黑,Verdana,Arail",
    fontWeight:600,
    padding:theme.spacing(1,0),
    clear:"both",
    "@media (max-width:650px)": {
      fontSize:'1.2em',
    },
  },
  content:{
    padding:theme.spacing(1,0),
    fontSize:'1em',
    "@media (max-width:650px)": {
      display:"none"
    },
  },
  buttons:{
    display:"flex",
    flexWrap:"wrap",
    padding:theme.spacing(2,0,0,0),
  },
  button:{
    fontSize:"1em",
    borderRadius:0,
    marginRight:theme.spacing(2),
    marginBottom:theme.spacing(2),
    paddingTop:theme.spacing(1),
    paddingBottom:theme.spacing(1),
  },
  // 拆分图文 
  splitItem:{
    width:"100%",
    height:"100%",
    display: "block",
    position:"relative",
    overflow:"hidden",
  },
  splitItemImage:{
    flexGrow:1,
    clear:"both",
    backgroundPosition:'center',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    overflow:"hidden"
  },
  splitItemInner:{
    display:"flex",
    flexDirection:"column",
    overflow:"hidden",
    width:"100%",
    height:"100%",
    position:"relative"
  }, 
  horizontal:{
    flexDirection:"row"
  },
  leftImage:{
    minHeight:"100%",
    float:"left",
    marginRight:4
  },
  rightImage:{
    minHeight:"100%",
    float:"right",
    marginLeft:4
  },
  splitItemTexts:{
    width:"100%",
    zIndex:99,
    padding:theme.spacing(1,0),
    cursor:"pointer",
    textAlign:"left",
    display:"flex",
    flexDirection:"column",
    overflow:"hidden", 
    '& a':{
      color:"#333",
      textDecoration:"none",
      '&:hover':{
        textDecoration:"none",
      }
    },
    "@media (max-width:650px)": {
      maxWidth:"100%",
    },
  },
  splitItemTitle:{
    width:"100%",
    fontSize:'1.12em',
    fontFamily:"微软雅黑,Verdana,Arail",
    fontWeight:500,
    padding:theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    "@media (max-width:650px)": {
      fontSize:'1em',
    },
  },
  splitItemContent:{
    padding:theme.spacing(1),
    fontSize:'.925em',
    color:"#666",
    "@media (max-width:650px)": {
      display:"none"
    },
  },
  splitItemAlignCenter:{
    textAlign:"center",
    alignItems:"center",
    margin:"0 auto",
    "@media (max-width:650px)": {
      maxWidth:"100%",
    },
  },
  splitItemAlignRight:{
    textAlign:"right",
    float:"right",
    alignItems:"center",
  },
}));
