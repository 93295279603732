
import { shallowEqual, useSelector } from "react-redux";
import * as State from "../../store/state";

export { useNav } from './NavProvide'
export { usePage } from './PageProvide'
export { useComponent } from './ComponentProvide'
export { useDatatype } from './DatatypeProvide'

export const useNavState = () => {
  return useSelector((state: State.Root) => {
    return {
      list: state.ui.nav.list || [],
      activeList: state.ui.nav.activeList || [],
      nav: state.ui.nav.nav,
      error: state.ui.nav.error,
      success: state.ui.nav.success
    };
  }, shallowEqual);
};


export const usePageState = () => {
  return useSelector((state: State.Root) => {
    return {
      list: state.ui.page.list || [],
      page: state.ui.page.page,
      error: state.ui.page.error,
      success: state.ui.page.success
    };
  }, shallowEqual);
};

export const useComponentState = () => {
  return useSelector((state: State.Root) => {
    return {
      list: state.ui.component.list || [],
      component: state.ui.component.component,
      error: state.ui.component.error,
      success: state.ui.component.success
    };
  }, shallowEqual);
};

export const useDatatypeState = () => {
  return useSelector((state: State.Root) => {
    return {
      list: state.ui.datatype.list || [],
      activeList: state.ui.datatype.activeList || [],
      datatype: state.ui.datatype.datatype,
      error: state.ui.datatype.error,
      success: state.ui.datatype.success
    };
  }, shallowEqual);
};