
import { convertFromHTML } from '../convert'
import * as styles from './styles'

import snakeCase from 'lodash.snakecase';
import camelCase from 'lodash.camelcase';
import { isFunction } from '../utils';

import { attachPrefix } from '../../../../config'

const cssTextToInlineStyle = (cssText: string) => {
  const result: string[] = []
  if (cssText) {
    const array = cssText.split(';')
    array.forEach((arr: string) => {
      const css = arr.split(':')
      if (css.length > 1) {
        const key = camelCase(css[0])
        const value = css[1]
        const style = `${styles.STYLE_PREFIX}_${snakeCase(key).toUpperCase()}_${value}`;
        result.push(style)
      }
    })
  }
  return result
}

const cssTextToStyle = (cssText: string) => {
  const style: any = {}
  if (cssText) {
    const array = cssText.split(';')
    array.forEach((arr: string) => {
      const css = arr.split(':')
      if (css.length > 1) {
        const key = camelCase(css[0])
        const value = css[1]
        style[key] = value
      }
    })
  }
  return style
}
export default (html: string) => ({
  onMediaClick = {} as any
}) => {
  // convert HTML to ContentState with blue text, links, and at-mentions
  const contentState = convertFromHTML({
    htmlToStyle: (nodeName: string, node: any, inlineStyle: any) => {
      let newStyle = inlineStyle
      if (nodeName === 'span' && isFunction(node.getAttribute)) {
        const styles = cssTextToInlineStyle(node.getAttribute('style'))
        styles.forEach((style: string) => {
          newStyle = newStyle.add(style)
        })
      }
      return newStyle;
    },
    htmlToEntity: (nodeName: any, node: any, createEntity: any) => {
      switch (nodeName) {
        case 'a': {
          return createEntity(
            'LINK',
            'MUTABLE',
            { url: node.href }
          )
        }
      }

      if (
        nodeName === 'img' ||
        nodeName === 'video' ||
        nodeName === 'audio'
      ) {
        let type = 'image'
        if (nodeName === 'video') {
          type = 'video'
        } else if (nodeName === 'audio') {
          type = 'audio'
        }
        const style = cssTextToStyle(node.getAttribute('style'))

        const parent = node.parentNode || node.parentElement
        const parentCssText = parent && parent.getAttribute('style')
        const parentStyle = cssTextToStyle(parentCssText)

        let src = node.getAttribute('src');

        // if (type === 'image' && src &&!src.startsWith(attachPrefix)) {
        // }

        return createEntity(
          type,
          'IMMUTABLE',
          {
            src,
            attributes: {
              alt: node.alt || '',
              ...style,
              textAlign: parentStyle.textAlign || '',
              clear: parentStyle.clear || ''
            },
            onClick: (props: any, attributes: any) => {
              onMediaClick({
                mediaProps: props,
                attributes: attributes,
                uploadType: type
              })
            }
          }
        )
      }
    },
    htmlToBlock: (nodeName: string, node: any, lastList: any, inBlock: any) => {
      const style = isFunction(node.getAttribute) ?
        cssTextToStyle(node.getAttribute('style')) : {}
      switch (nodeName) {
        case 'pre':
          return 'code';
        case 'div': {
          const child = node.firstChild && node.firstChild.nodeName && node.firstChild.nodeName.toLowerCase()
          if (child === 'img' || child === 'video' || child === 'audio') {
            return {
              type: 'atomic',
              data: {
                ...style
              }
            }
          }
          return {
            type: 'unstyled',
            data: {
              ...style
            }
          }
        }
      }
      if (nodeName === 'img' || nodeName === 'video' || nodeName === 'audio') {
        const parent = node.parentNode || node.parentElement
        if (parent && parent.nodeName !== 'div') {
          return {
            type: 'atomic',
            data: {
              ...style
            }
          }
        }
      } else if (nodeName === 'figure') {
        return {
          type: 'atomic',
          data: {
            ...style
          }
        }
      }
    }


  })(html);
  return contentState
}

