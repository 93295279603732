import * as actions from "../../store/actions/user/comment";
import { useDispatch } from "react-redux";

interface Result {
  getList: Function;
  getListByUser:Function;
  save: Function;
  remove: Function;
  getTotal:Function;
}

export const useComment = (): Result => {
  const dispatch:any = useDispatch()
  // 获取列表
  const getList =  (props?: any) => {
    const { params,success, failure } = props || {};
     dispatch(actions.fetchList(params)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        const list = data.result.list;
        const total = data.result.total;
        // 成功返回
        if (success) success(list,total);
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };
  
  // 获取总数
  const getTotal =  (props?: any) => {
    const { params,success, failure } = props || {};
     dispatch(actions.total(params)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        // 成功返回
        if (success) success(data.result.total);
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };

  // 获取列表
  const getListByUser =  (props?: any) => {
    const { params,success, failure } = props || {};
     dispatch(actions.fetchListByUser(params)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        const list = data.result.list;
        const total = data.result.total;
        // 成功返回
        if (success) success(list,total);
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };

  // 保存
  const save =  (props: any) => {
    const { comment,success, failure } = props;
     dispatch(actions.save(comment)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        // 成功返回
        if (success) {
          success(data.result.total,data.result.extra);
        }
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };

  // 删除
  const remove =  (props: any) => {
    const { id, success, failure } = props;
     dispatch(actions.remove(id)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message;
      if (data && data.code === 1) {
        // 成功返回
        if (success) {
          success(message); 
        }
      } else {
        // 失败返回
        if (failure) failure(message || "请求失败");
      }
    });
  };

  
  return {
    getList,
    getListByUser,
    remove,
    save,
    getTotal
  };
};
