import React from 'react'
import clsx from 'clsx'
import { ListProps } from './types'
import Paper from '@material-ui/core/Paper';

export default (props: ListProps) => {
    const {
        classes,
        attrList,
        selected,
        onSelected
    } = props

    const toggleSelect = (index: number, vIndex: number) => {
        // 选择属性作为商品属性
        let attr = attrList[index];
        let attrVal = attr.valList[vIndex];
        if (
            selected[attr.id] &&
            selected[attr.id]["attrVals"][attrVal.id]
        ) {
            // 已存在，则移除
            delete selected[attr.id]["attrVals"][attrVal.id];
            let keys = Object.keys(selected[attr.id]["attrVals"]);
            if (keys.length === 0) {
                // 值列表为空，删除当前属性
                delete selected[attr.id];
            }
        } else {
            // 添加
            selected[attr.id] = selected[attr.id] || {
                attr,
                attrVals: {}
            };
            selected[attr.id]["attrVals"][attrVal.id] = attrVal;
        }
        // 需要重新给对象赋值，否则页面无法监测变化
        onSelected(Object.assign({}, selected));
    }


    // <!-- 已添加的键值对属性列表 -->
    return (
        <Paper className={classes.paper}>
            <div className={classes.list}>
                {
                    attrList.map((attr: any, index: number) => (
                        <div key={attr.id} className={classes.row}>
                            <div className={classes.rowLabel}>{attr.name} ：</div>
                            <div className={classes.rowContent}>
                            {
                                attr.valList.map((val: any, vIndex: number) => (
                                    <a key={val.id}
                                        className={clsx(classes.aBox, {
                                            [classes.active]: selected[attr.id] && selected[attr.id]['attrVals'][val.id]
                                        })}
                                        onClick={() => { toggleSelect(index, vIndex) }}>
                                        <span>{val.value}</span>
                                    </a>
                                ))
                            }
                            </div>
                        </div>
                    ))
                }
            </div>
    </Paper>)
}