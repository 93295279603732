import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box';

// 表单组件
import TextInput from '../../../components/ui/form/TextInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import ExpressInput from '../../../components/ui/form/ExpressInput';
// 弹框
import { LoadingDialog, SubmitedDialog } from '../../../components/ui/dialog'

import { useLocale } from '../../../config/Locale'

import { useSend } from '../../../provides/trade'

import { OrderDetail,SendDetail} from "../../../types/trade" 
import OrderDetails from './OrderDetails'

type Props = {
  order:any,
  send:any,
  success:()=>void,
  cancel:()=>void
}

export default (props: Props) => {
  const {order,send,success,cancel} = props
  const { translate } = useLocale()
  const { save } = useSend()

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const [form, setForm] = useState<any>(send)
  // 设置表单元素
  const setFormItem = (key: string, value: any) => {
    form[key] = value;
    setForm(Object.assign({}, form))
  }

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        send: form
      },
      success: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }

  const renderPageForm = (<Box>
    <form onSubmit={onSubmit}>
      {/* 分类类型： */}
      <RadioInput
        labelWidth={120}
        value={form.mode}
        options={[
          { label: '快递', value: "0" },
          { label: '自提或其它', value: "1" }
        ]}
        label="寄送方式："
        onChange={(e: any, value: any) => {
          setFormItem('mode', value)
        }} />

      { form.mode === '0' && <><ExpressInput
        labelWidth={120}
        label="快递公司："
        value={`${form.express || ''}`}
        onChange={(value: any) => {
          setFormItem('express', value.code)
        }} />
 
      <TextInput
        labelWidth={120}
        value={`${form.expressNo || ''}`}
        fullWidth
        label="快递单号："
        required
        placeholder="请填写快递单号..."
        onChange={(event: any) => {
          setFormItem('expressNo', event.target.value)
        }} />
        </>}

      <TextInput
        labelWidth={120}
        value={`${form.remark || ''}`}
        fullWidth
        label="备注："
        required
        placeholder="请填写备注..."
        onChange={(event: any) => {
          setFormItem('remark', event.target.value)
        }} />

      <Box style={{marginTop:"8px",border:"1px solid #dedede",borderRadius:4}}>
          <Box style={{borderBottom:"1px solid #dedede",padding:"8px"}}>设置物流发货商品</Box>
          <OrderDetails 
              list={order.details} 
              selected={form.details || []}
              onToggle={(orderDetail:OrderDetail)=>{
                  let formDetails =  form.details || []
                  if(formDetails.filter((item:any)=>item.orderDetail.id === orderDetail.id).length > 0){
                    // 删除
                    formDetails = formDetails.filter((item:any)=>item.orderDetail.id !== orderDetail.id)
                  }else{
                    // 追加
                    formDetails.push({
                      sendId:form.id,
                      orderDetail
                    })
                  }
                  setFormItem("details",Object.assign([],formDetails))
              }}
          /> 
      </Box>
      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            cancel()
          },
          onReset: () => {
            setForm(send)
          }
        }} />
    </form>
  </Box>)


  return (<Box>

    <Box style={{
      flex: 1
    }}>
      {renderPageForm}
    </Box>

    <LoadingDialog open={loading.open} text={loading.text} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
        success()
      }}
      message={message}
      goModify={()=>setMessage(initialMessage)}
    />
  </Box>
  )
}