import React from "react";
import Icon from './Icon'

type Props = {
  message: string,
  onClick?: () => void
}

export default (props: Props) => {
  const { message, onClick } = props
  return <div className="error" onClick={onClick}>
    <Icon name="Error" />
    {message}
  </div>
} 