/* @flow */

import {
  getEntityRange,
  getCustomStyleMap,
  toggleCustomInlineStyle,
  getSelectionEntity,
  extractInlineStyle,
  removeAllInlineStyles,
  getSelectionInlineStyle,
  getSelectionCustomInlineStyle,
} from './inline';
import {
  getSelectedBlocksMap,
  getSelectedBlocksList,
  getSelectedBlock,
  getBlockBeforeSelectedBlock,
  getAllBlocks,
  getSelectedBlocksType,
  removeSelectedBlocksStyle,
  getSelectionText,
  addLineBreakRemovingSelection,
  insertNewUnstyledBlock,
  clearEditorContent,
  setBlockData,
  getSelectedBlocksMetadata,
  blockRenderMap,
} from './block';

import {
  replaceEntity,
  removeEntity,
  removeEntityOnBackspace,
  removeEntityOnDelete
} from './entities';

import handleNewLine from './keyPress';
import {
  isListBlock,
  changeDepth,
} from './list';

const isFunction = (obj:any) => !!(obj && obj.constructor === Function)

export  {
  // Functions related to blocks
  getSelectedBlocksMap,
  getSelectedBlocksList,
  getSelectedBlock,
  getBlockBeforeSelectedBlock,
  getAllBlocks,
  getSelectedBlocksType,
  removeSelectedBlocksStyle,
  getSelectionText,
  addLineBreakRemovingSelection,
  insertNewUnstyledBlock,
  clearEditorContent,
  setBlockData,
  getSelectedBlocksMetadata,
  blockRenderMap,
  // Functions related to inline styles
  getEntityRange,
  getCustomStyleMap,
  toggleCustomInlineStyle,
  getSelectionEntity,
  extractInlineStyle,
  removeAllInlineStyles,
  getSelectionInlineStyle,
  getSelectionCustomInlineStyle,
  // KeyPress related Functions
  handleNewLine,
  // Lists related Functions
  isListBlock,
  changeDepth,
  // atomic
  replaceEntity,
  removeEntity,
  removeEntityOnBackspace,
  removeEntityOnDelete,
  // type-check
  isFunction
};
