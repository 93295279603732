import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#E6172D",
    className = "icon"
})=>(<svg className={className} viewBox="0 0 1024 1024" version="1.1"
 xmlns="http://www.w3.org/2000/svg" p-id="16986" width={width} height={height}>
 <path d="M512 65.536C230.4 65.536 1.024 257.024 1.024 492.544c0 99.328 39.936 190.464 108.544 263.168l0 0c0 0-5.12 180.224-43.008 203.776 0 0 138.24 0 226.304-79.872 66.56 26.624 140.288 40.96 219.136 40.96 281.6 0 510.976-191.488 510.976-427.008C1022.976 257.024 793.6 65.536 512 65.536zM512 878.592c-71.68 0-140.288-13.312-203.776-37.888-5.12-2.048-10.24-3.072-15.36-3.072-10.24 0-19.456 4.096-27.648 10.24-38.912 35.84-91.136 53.248-132.096 61.44 4.096-16.384 7.168-34.816 9.216-56.32 5.12-40.96 7.168-78.848 7.168-92.16 2.048-12.288-2.048-24.576-10.24-33.792-63.488-67.584-97.28-149.504-97.28-234.496 0-212.992 210.944-386.048 470.016-386.048s470.016 173.056 470.016 386.048C982.016 705.536 771.072 878.592 512 878.592z" p-id="16987" fill={color}></path>
 <path d="M321.536 512m-55.296 0a5.4 5.4 0 1 0 110.592 0 5.4 5.4 0 1 0-110.592 0Z" p-id="16988" fill={color}></path>
 <path d="M527.36 512m-55.296 0a5.4 5.4 0 1 0 110.592 0 5.4 5.4 0 1 0-110.592 0Z" p-id="16989" fill={color}></path>
 <path d="M733.184 512m-55.296 0a5.4 5.4 0 1 0 110.592 0 5.4 5.4 0 1 0-110.592 0Z" p-id="16990" fill={color}></path></svg>
)