import React from "react"

import { SingleSelect as MuiSingleSelect, SelectOption } from "react-select-material-ui";

import { StylesConfig } from 'react-select';

const stylesFn: StylesConfig = {
    clearIndicator: (base: any) => ({
      ...base,
      color: '#f3f3f3',
      '&:hover': { color: '#ff0000' }
    }),
    control: (base: any, state: any) => ({
      ...base,
      background: 'transparent',
      borderBottom: state.isFocused ? '#ff0000' : '#f3f3f3',
      borderBottomWidth: 3,
      borderBottomStyle: 'solid',
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderRadius: 0,
      boxShadow: 'none',
      marginRight: 25
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: '#f3f3f3',
      '&:hover': { color: '#ff0000' }
    }),
    menuList: (base: any) => ({
      ...base,
      backgroundColor: '#123456',
      color: '#ffffff'
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: '#888888'
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: '#f3f3f3'
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: '#f3f3f3',
      '&:hover': { color: '#ff0000', backgroundColor: 'rgba(0,0,0,0)' }
    }),
    noOptionsMessage: (base: any) => ({
      ...base,
      backgroundColor: '#888888',
      color: '#ffff80',
      textAlign: 'left'
    }),
    option: (base: any, { isSelected = false }) => ({
      ...base,
      backgroundColor: isSelected ? '#123456' : '#333',
      color: isSelected ? '#ffff80' : '#ffffff',
      '&:hover': { backgroundColor: '#234567', color: '#fff' }
    }),
    singleValue: (base: any) => ({
      ...base,
      backgroundColor: 'transparent',
      color: '#333'
    }),
}
interface Props  {
    value?: string;
    style?:any,
    placeholder?: string;
    options: SelectOption[];
    onChange: (value: string) => void;
    width?:string|number
}

export const SingleSelect = (props: Props) => {

    const { value, placeholder, options, onChange,width,style, ...rest } = props

    return <MuiSingleSelect
        value={value}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        style={{width:width || "100%",...style}}
        SelectProps={{
          isClearable: true,
          styles: stylesFn
        }}
        {...rest} />
}
export default SingleSelect 