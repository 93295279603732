import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
})=>(
<svg className={className} viewBox="0 0 1024 1024" 
version="1.1" xmlns="http://www.w3.org/2000/svg" 
p-id="3713" width={width} height={height}><path fill={color} d="M614.4 409.6l102.4 0L716.8 307.2l-102.4 0L614.4 409.6zM307.2 409.6l102.4 0L409.6 307.2 307.2 307.2 307.2 409.6zM747.52 614.4c-40.96 92.16-128 153.6-235.52 153.6s-194.56-61.44-235.52-153.6L220.16 614.4c40.96 117.76 153.6 204.8 291.84 204.8s245.76-87.04 291.84-204.8L747.52 614.4zM512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0zM512 51.2c256 0 460.8 204.8 460.8 460.8s-204.8 460.8-460.8 460.8-460.8-204.8-460.8-460.8S256 51.2 512 51.2z" p-id="3714"></path></svg>
)