
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import ToolbarItem from './ToolbarItem'

import { ToolbarProps } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      borderBottom: '1px solid #dedede',
      background: '#fff',
      padding: theme.spacing(1)
    }
  }),
);

const Toolbar = (props: ToolbarProps) => {
  const classes = useStyles()
  const {
    editor,
    onChange,
    editorState,
    options,
    children
  } = props

  return <div className={classes.root}>
    {options.map((option: any,index:number) => <ToolbarItem key={index}
      editor={editor}
      onChange={onChange}
      editorState={editorState}
    {...option}/>)} 
    {children}
  </div>
}
export default Toolbar