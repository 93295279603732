import { Action } from '../../actions';
import * as Actions from '../../actions/shopping/promotion';
import * as State from '../../state/shopping';

export const initialState: State.Promotion = {
};

export const reducer = (state: State.Promotion = initialState, action: Action): State.Promotion => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_ACTIVE_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_ACTIVE_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                promotion: action.payload.promotion
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        default: return state;
    }
};