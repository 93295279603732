/**
 * 对原始进行排序，规格值列表长度大的往后排
 * 返回对象键集合
 * @param {原始数据} data
 */
export const sortKeys = (data:any) => {
  if (!data) {
    return []
  }
  // 数据库键集合
  const keys = Object.keys(data)
  // 临时对象
  let tmp
  // 排序
  const sortToMin = (i:number) => {
    if (i <= 0) { return }
    const next = data[keys[i - 1]]
    const current = data[keys[i]]
    if (Object.keys(next.specVals).length > Object.keys(current.specVals).length) {
      // 比下一条长度小，更换位置,下一条保存到临时对象
      tmp = keys[i - 1]
      keys[i - 1] = keys[i]
      keys[i] = tmp
      // 继续往前排
      sortToMin(i - 1)
    }
  }
  const sort = (i:number) => {
    if (i < keys.length) {
      const next = keys.length > i + 1 ? data[keys[i + 1]] : undefined
      const current = data[keys[i]]

      if (next && Object.keys(next.specVals).length < Object.keys(current.specVals).length) {
        // 比下一条长度大，更换位置,下一条保存到临时对象
        tmp = keys[i + 1]
        keys[i + 1] = keys[i]
        keys[i] = tmp
        // 往前排
        sortToMin(i)
      }
      // 继续往后排
      sort(i + 1)
    }
  }
  sort(0)
  return keys
}
/**
 * 判断值ID是否相等
 * @param {“,"分隔的字符串} a
 * @param {“,"分隔的字符串} b
 */
export const equals = (a:any, b:any) => {
  if (a.length !== b.length) {
    return false
  }
  var bool = false
  var array = a.split(',')
  var length = array.length
  for (var i = 0; i < length; i++) {
    var arr = array[i]
    if (b.indexOf(arr) !== -1) {
      bool = true
    } else {
      return false
    }
  }
  return bool
}

/**
 * 获取已存规格
 * 规格ID为用","分隔的ID串
 * @param {规格ID} valId
 * @param {已存的商口规格列表} productSpecs
 */
export const getProductSpec = (valId:string, productSpecs:any) => {
  if (!productSpecs) {
    return undefined
  }
  let result
  productSpecs.map((ps:any) => {
    if (equals(ps.specValId, valId) === true) {
      result = ps
    }
  })
  return result
}
/**
 * 获取表数据
 * @param {原始数据} data
 * @param {键} keys
 * @param {表字段} columns
 */
export const getTableData = ({ data = [] as any, keys = [] as any, productSpecs = {} as any }) => {
  let tableData:any = []

  // 生成行数据
  function mapRow (i:number, row:any) {
    if (i >= keys.length) { return }
    row = row || {}
    // 当前规格ID指向的对象
    let item = data[keys[i]]
    // 遍历规格值
    Object.keys(item.specVals).forEach(async key => {
      let val = Object.assign({}, item.specVals[key])
      // 规格值
      row[item.spec.id] = {
        id: val.id,
        value: val.value
      }
      if (i === keys.length - 1) {
        // 遍历到最后一个规格ID指向的对象，将该列数据封装到行数据
        let valId = ""
        // 规格值组合，与productId形成唯一性
        Object.keys(row).map(key => valId += row[key]['id']? (valId?',':'')+row[key]['id']:'')
        row['valId']=valId
        const productSpec:any = getProductSpec(row['valId'], productSpecs) || {}
        row['price'] = productSpec.price || 0
        row['oprice'] = productSpec.oprice || 0
        row['inprice'] = productSpec.inprice || 0
        row['stock'] = productSpec.stock || 0
        row['image'] = productSpec.image || ''
        tableData.push(Object.assign({}, row))
        // 设置合并行数
        Object.keys(row).map(key => setRowSpan(tableData.length - 1, key))
      } else {
        // 遍历下一条
        mapRow(i + 1, row)
      }
    })
  }

  // 顺序设置合并状态
  function setRowSpan (rowIndex:number, key:string) {
    // 查看上一行相同列数据
    if (rowIndex > 0) {
      // 当前
      let current = tableData[rowIndex][key]
      if (!current.value) {
        return
      }
      // 上行的同一列
      let prev = tableData[rowIndex - 1][key]

      if (prev.value === current.value) {
        // 设置当前状态
        current.rowSpan = (prev.rowSpan || 1) + 1
        prev.rowSpan = current.rowSpan
      }
    }
  }
  // 生成行数据
  mapRow(0, {})
  // 返回数据
  return tableData
}
