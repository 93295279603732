import React from 'react'
export default ({
  className = "icon", color = "gray"
}) => (<svg xmlns="http://www.w3.org/2000/svg"
  className={className} viewBox="0 0 100 100" version="1.1">
  <g id="17.-Bell" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
    <g transform="translate(2.000000, 3.000000)" stroke={color} strokeWidth="5">
      <path d="M1,82.6624363 C10.0657813,75.4737565 15.7929233,64.902803 15.7929233,53.117652 L15.7929233,38.411768 C15.7929233,22.1680971 30.2998277,9 48.1950012,9 C66.0901747,9 80.5970791,22.1680971 80.5970791,38.411768 L80.5970791,53.117652 C80.5970791,64.9028045 86.3242225,75.4737592 95.3899994,82.6624363" id="Layer-1"></path>
      <ellipse id="Layer-2" cx="48" cy="84.6" rx="48" ry="9.6"></ellipse>
      <path d="M59.6423952,76 C58.7027212,81.6902897 53.7598241,86.0302232 47.8032814,86.0302232 C41.9187538,86.0302232 37.0235101,81.7945959 36,76.2057913" id="Layer-3"></path>
      <path d="M38,10 C38,4.4771525 42.4771525,0 48,0 C53.5228475,0 58,4.4771525 58,10" id="Layer-4"></path>
    </g>
  </g>
</svg>)