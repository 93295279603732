import {
    keyPrefix,
    setObject,
    getObject
} from './base'

import {Account} from '../types/sys'

// 保存AppToken
export const saveAppToken = (appToken:string)=>{
    setObject(`${keyPrefix}-appToken`,{appToken})
}
// 获取AppToken
export const getAppToken = (): string =>{
    const object = getObject(`${keyPrefix}-appToken`)
    return object && object.appToken
}
// 获取当前帐户
export const getAccount = (): Account=>{
    return getObject(`${keyPrefix}-account`)
}
// 保存帐户
export const saveAccount = (account:object) =>{
    setObject(`${keyPrefix}-account`,account)
}
// 删除帐户
export const removeAccount=():void=>{
    setObject(`${keyPrefix}-account`,{})
}
