import React, { useState, useRef, useEffect } from 'react';
import { EditorState, RichUtils, Modifier } from 'draft-js';
import {
    getSelectionText,
    getEntityRange,
    getSelectionEntity
} from "../utils";
import linkifyIt from "linkify-it";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import RadioInput from '../../form/RadioInput';
import Icon from './Icon'
import Button from './Button'
import Modal from './Modal'

const linkify = linkifyIt();
const linkifyLink = (params: any) => {
    const links = linkify.match(params.target);
    return { ...params, target: (links && links[0] && links[0].url) || params.target };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputContainer: {
            padding: 0,
            minWidth: theme.spacing(50)
        }
    }))

export default (props: any) => {
    const {
        onChange,
        editorState,
        title
    } = props

    const [currentEntity, setCurrentEntity] = useState(null as any)
    const [text, setText] = useState('')
    const [target, setTarget] = useState('')
    const [targetOption, setTargetOption] = useState('')
    const [inLink, setInLink] = useState(true)
    const classes = useStyles()
    const input = useRef(null as any)


    const confirm = () => {
        const linkified = linkifyLink({ title: text, target, targetOption });
        addLink(linkified.title, linkified.target, linkified.targetOption);
    };

    const getCurrentValues = () => { 
        const contentState = editorState.getCurrentContent();
        const currentValues: any = {};
        if (
            currentEntity &&
            contentState.getEntity(currentEntity).get("type") === "LINK"
        ) {
            currentValues.link = {};
            const entityRange =
                currentEntity && getEntityRange(editorState, currentEntity);
            currentValues.link.target =
                currentEntity && contentState.getEntity(currentEntity).get("data").url;
            currentValues.link.targetOption =
                currentEntity &&
                contentState.getEntity(currentEntity).get("data").targetOption;
            currentValues.link.title = entityRange && entityRange.text;
        }
        currentValues.selectionText = getSelectionText(editorState);
        return currentValues;
    };
    const query = () => {
        const currentEntity = getSelectionEntity(editorState);
        setCurrentEntity(currentEntity)
        const { link } = getCurrentValues();
        setInLink(Boolean(link))
    }
    useEffect(query)

    const removeLink = () => {
        let selection = editorState.getSelection();
        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();
            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end
                });
            }
            onChange(RichUtils.toggleLink(editorState, selection, null));
        }
    };

    const addLink = (linkTitle: string, linkTarget: string, linkTargetOption: any) => {
        let selection = editorState.getSelection();

        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();
            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end
                });
            }
        }
        const entityKey = editorState
            .getCurrentContent()
            .createEntity("LINK", "MUTABLE", {
                url: linkTarget,
                targetOption: linkTargetOption
            })
            .getLastCreatedEntityKey();

        let contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            selection,
            `${linkTitle}`,
            editorState.getCurrentInlineStyle(),
            entityKey
        );
        let newEditorState = EditorState.push(
            editorState,
            contentState,
            "insert-characters"
        );

        // insert a blank space after link
        selection = newEditorState.getSelection().merge({
            anchorOffset: selection.get("anchorOffset") + linkTitle.length,
            focusOffset: selection.get("anchorOffset") + linkTitle.length
        });
        newEditorState = EditorState.acceptSelection(newEditorState, selection);
        contentState = Modifier.insertText(
            newEditorState.getCurrentContent(),
            selection,
            " ",
            newEditorState.getCurrentInlineStyle(),
            undefined
        );
        onChange(
            EditorState.push(newEditorState, contentState, "insert-characters")
        );
    };

    // 弹出层
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    /**
     * 打开输入框
     */
    const prompt = (e: any) => {
        e.preventDefault();
        const { link, selectionText } = getCurrentValues();
        setTarget((link && link.target) || '')
        setTargetOption((link && link.targetOption) || '')
        setText((link && link.title) || selectionText);
        setAnchorEl(e.currentTarget);
    }

    return (<>
        <Button onMouseDown={prompt}
            title={title[0]}>
            <Icon name="AddLink"/>
        </Button>
        <Button
            onMouseDown={removeLink}
            disabled={!inLink}
            title={title[1]}>
            <Icon name="RemoveLink"
                color={!inLink ? '#aaa' : '#000'}
               />
        </Button>
        <Modal {...{
            anchorEl,
            title: '链接地址',
            ok: () => {
                confirm();
                setAnchorEl(null);
            },
            cancel: () => {
                setAnchorEl(null);
            }
        }}>
            <Box className={classes.inputContainer}>
                <TextField
                    ref={input as any}
                    name="text"
                    required
                    fullWidth
                    margin="dense"
                    label="文本"
                    placeholder=""
                    onChange={(e: any) => {
                        e.preventDefault();
                        setText(e.target.value)
                    }}
                    value={text}
              />
                <TextField
                    ref={input as any}
                    name="target"
                    required
                    fullWidth
                    margin="dense"
                    label="链接地址"
                    placeholder="链接地址以http://或https://开头"
                    onChange={(e: any) => {
                        e.preventDefault();
                        setTarget(e.target.value)
                    }}
                    value={target}
              />

                {/* 状态 */}
                <RadioInput
                    labelWidth={60}
                    value={targetOption}
                    options={[
                        { label: "默认", value: "" },
                        { label: "新窗口打开", value: "_target" }
                    ]}
                    label="目标："
                    onChange={(event: any, value: any) => {
                        event.preventDefault();
                        setTargetOption(value)
                    }} />
            </Box>
        </Modal>
    </>
    );
}