import { createStore, Store, applyMiddleware } from 'redux'
import { multiClientMiddleware } from 'redux-axios-middleware';

import * as State from './state';
import { reducers, initialState } from './reducers';
import { clients } from './clients'


export const store: Store<State.Root> = createStore(
  reducers,
  initialState,
  applyMiddleware(
    multiClientMiddleware(clients)
  )
);