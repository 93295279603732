import React from "react";

import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TextField from "./TextField";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { PhotoshopPicker, HSLColor, RGBColor } from "react-color";

import Icon from "../../Icon";
import { ColorInputProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    label: {
      textAlign: "right",
      flexWrap: "nowrap"
    },
    input: {
      flex: 1,
      margin: theme.spacing(1)
    },
    inputBox: {
      display: "inline-flex"
    },
    inputInput: {},
    tips: {
      padding: theme.spacing(1, 0),
      fontSize: 12,
      color: "#999"
    }
  })
);

export default (props: ColorInputProps) => {
  const classes = useStyles();
  const { label, labelWidth, inputWidth, tips, value, onChange } = props;
  const [color, setColor] = React.useState<
    string | HSLColor | RGBColor | undefined
  >(value || "");
  const labelStyle: React.CSSProperties = labelWidth
    ? {
        width: labelWidth
      }
    : {};
  const inputStyle: React.CSSProperties = inputWidth
    ? {
        width: inputWidth
      }
    : {};
  // const currentStyle = editorState.getCurrentInlineStyle();
  const stopPropagation = (event: any) => {
    event.stopPropagation();
  };
  const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
  function handleClick(event: any) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const renderModal = () => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={stopPropagation}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <PhotoshopPicker
          color={color}
          onAccept={() => {
            handleClose();
          }}
          onCancel={() => {
            handleClose();
          }}
          onChange={(color: any) => {
            setColor(color.hex || "");
            onChange(color.hex || "");
          }}
        />
      </Popover>
    );
  };

  return (
    <Box className={classes.root}>
      <Box component="span" style={labelStyle} className={classes.label}>
        {label}
      </Box>
      <Box component="span" className={classes.input}>
        <Box component="span" className={classes.inputBox}>
          <TextField
            className={classes.inputInput}
            style={inputStyle}
            inputProps={{
              "aria-label": "description"
            }}
            value={color}
            onChange={(e: any) => {
              setColor(e.target.value);
              onChange(e.target.value);
            }}
          />
          <IconButton onClick={handleClick}>
            <Icon name="ColorPicker" bgColor={color} width={18} height={18} />
          </IconButton>
        </Box>
        {tips && <Box className={classes.tips}>{tips}</Box>}
      </Box>
      {renderModal()}
    </Box>
  );
};
