import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024"
    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="20739"
    width={width} height={height}><path fill={color} d="M519.67 0.00001C415.134001 0.00001 330.077002 82.942009 326.493002 186.876008H82.587004a49.195 49.195 0 0 0-49.195999 49.15 49.195 49.195 0 0 0 49.195999 49.151999h54.315v550.899995c0 103.421999 70.209999 187.899998 156.801998 187.899998h441.689996c86.593999 0 156.802998-83.965999 156.802998-187.899998V285.690007h49.195a49.195 49.195 0 0 0 49.195999-49.150999 49.195 49.195 0 0 0-49.195999-49.151H712.332998A192.663998 192.663998 0 0 0 519.692 0.00001zM411.817001 178.083008c3.562-50.064 46.902-89.040999 100.415999-89.040999 53.536999 0 96.877999 38.977 99.926999 89.040999H411.818001zM299.180002 934.424001c-31.164 0-65.444999-39.356-65.444999-95.585999V289.385007h578.769994V839.350002c0 56.229999-34.281 95.585999-65.445999 95.585999H299.180002v-0.512z" p-id="20740"></path>
    <path fill={color} d="M367.297001 801.374002c24.487 0 44.52-21.815 44.52-49.106999V472.054005c0-27.291-20.033-49.107-44.52-49.106999s-44.52 21.816-44.519999 49.106999v280.212998c0 27.292 19.477 49.107 44.519999 49.106999z m155.822999 0c24.486 0 44.52-21.815 44.519999-49.106999V472.054005c0-27.291-20.034-49.107-44.519999-49.106999s-44.521 21.816-44.521 49.106999v280.212998c0 27.292 20.034 49.107 44.52 49.106999z m133.561999 0c24.487 0 44.52-21.815 44.519999-49.106999V472.054005c0-27.291-20.033-49.107-44.519999-49.106999s-44.52 21.816-44.52 49.106999v280.212998c0 27.292 19.477 49.107 44.52 49.106999z" p-id="20741"></path></svg>
    )
