
import React from 'react';
import {  makeStyles, createStyles,  Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap:'nowrap'
    },
    label: {
      textAlign: 'right',
      flexWrap:'nowrap'
    },
    field: {
      flex:1,
      margin: theme.spacing(1),
    },
  }),
);

const FormField = (props: any) => {
  const classes = useStyles()
  const { label,labelWidth, children,...rest} = props
  const labelStyle: React.CSSProperties = labelWidth?{
    width: labelWidth
  }:{};
  return (
    <Box className={classes.root} {...rest}>
      <Box component="span" 
        style={labelStyle}
        className={classes.label}>
        {label} 
      </Box>
      <Box component="span" className={classes.field}> 
      {children}
      </Box>
    </Box>
  )
}
export default FormField