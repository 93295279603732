import React from "react"

import { ConfirmDialog } from "../../../../components/ui/dialog"
import AdListPage from "../../../common/ad/list"

type Props = {
    ids?: string[],// 已选择的ID
    success: (ids: string[]) => void,
    cancel: () => void
}


export default (props: Props) => {
    const { ids, success, cancel } = props

    const [selected, setSelected] = React.useState(ids || [])

    return <ConfirmDialog
        {...{
            showTitle: false,
            open: true,
            fullWidth: true,
            maxWidth: "md",
            title: "选择广告",
            ok: () => {
                if (selected) {
                    success(selected)
                }
            },
            cancel: () => {
                cancel()
            }
        }}>
        <AdListPage
            selected={selected}
            onSelected={(selected: string[]) => {
                setSelected(selected)
            }} />
    </ConfirmDialog>
}