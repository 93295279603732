import { getSelectedBlock } from "../utils";
import { Modifier, EditorState, DraftHandleValue } from "draft-js";
import convertFromHtml from './convertFromHtml'

export default (
  text: string,
  html: string,
  editorState: EditorState,
  onChange: any,
  onMediaClick: any
): DraftHandleValue => {
  const selectedBlock: any = getSelectedBlock(editorState);
  if (html) {
    const pasteContent = convertFromHtml(html)({onMediaClick});
    let contentState = editorState.getCurrentContent();
    contentState = Modifier.replaceWithFragment(
      contentState,
      editorState.getSelection(),
      pasteContent.getBlockMap()
    );
    onChange(EditorState.push(editorState, contentState, "insert-fragment"));
  }else  if (selectedBlock) {//&& selectedBlock.type === "code"
  const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text,
    editorState.getCurrentInlineStyle()
  );
  onChange(EditorState.push(editorState, contentState, "insert-characters"));
} 
  return 'handled';
};
