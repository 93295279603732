import React, { useState, useEffect } from 'react'

import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Icon from '../../../components/Icon'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { BodyTableCell, TableRow, TableHead, TableRowToolbar } from '../../../components/ui/table'
import PageToolbar from '../../../components/ui/PageToolbar'

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { withRouter } from "react-router-dom";
import { stableSort, getSorting } from '../../../lib/sort'
import { createId } from '../../../lib/util'
import { useBrand } from '../../../provides/shopping/BrandProvide'
import { useLocale } from '../../../config/Locale'

import { useListPageStyles } from '../../../assets/jss/pages'
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, AlertDialog } from '../../../components/ui/dialog'
import { attachPrefix } from '../../../config';

import RadioInput from '../../../components/ui/form/RadioInput'


const fields = [
  { id: 'image', numeric: false, disablePadding: false, label: '品牌LOGO' },
  { id: 'name', numeric: false, disablePadding: true, label: '品牌名称' },
  { id: 'status', numeric: false, disablePadding: false, label: '状态' },
  { id: 'sorter', numeric: false, disablePadding: false, label: '排序' }
];


const EnhancedPageToolbar = (props: any) => {

  const [names, setNames] = useState("")
  const { translate } = useLocale()
  const { numSelected, onAddClick, onDelClick, onSelectCat, onRefresh, onSearch } = props;

  const title = <span>
    <span> {names || translate('shopping.brand.pageTitle')} </span>
    {numSelected > 0 && (
      <span style={{ paddingLeft: 16 }}>
        {numSelected} {translate('selected')}
      </span>
    )}
  </span>
  const label = {
    add: translate('shopping.brand.add')
  }
  return <PageToolbar
    {...{
      title,
      label,
      numSelected,
      onAddClick,
      onDelClick,
      onRefresh
    }} />
};

const BrandListPage = (props: any) => {
  const classes = useListPageStyles()
  const { history } = props
  const { translate } = useLocale()
  const { getList, remove, setStatus } = useBrand()
  // 列表
  const [list, setList] = useState<any>([])
  // 预览
  const [preview, setPreview] = useState({ open: false, row: {} as any })

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  // 设置状态
  const handleStatus = (id: string, status: string) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在更改状态...'
    })
    setStatus({
      data: {
        id,
        status
      },
      success: (list: any) => {
        loadList()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  // 获取列表
  const loadList = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载品牌列表...'
    })
    getList({
      success: (list: any) => {
        setList(list)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        setList([])
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadList();
    return () => {
      // 返回空方法，否则会陷入无限循环
    };
  }, []);

  const [order, setOrder] = React.useState<"asc" | "desc">('asc');
  const [orderBy, setOrderBy] = React.useState<string>('name');
  const [selected, setSelected] = React.useState([] as string[])
  const [dense, setDense] = React.useState(true);

  const handleRequestSort = (event: any, property: any) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = list && list.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleClick = (event: any, id: string) => {
    let newSelected = selected || [];
    const index = newSelected.indexOf(id)
    if (index > -1) {
      newSelected.splice(newSelected.indexOf(id), 1)
    } else {
      newSelected.push(id)
    }
    setSelected(Object.assign([], newSelected));
  }


  const handleChangeDense = (event: any) => {
    setDense(event.target.checked);
  }

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
        {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
        <Box>
          <Box>{translate('shopping.brand.removeTips')}</Box>
          <Box>{translate('shopping.brand.removeAsk')}</Box>
        </Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        setLoading({
          open: true,
          text: translate('shopping.brand.removing')//'正在删除模块...'
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadList()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
  }

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  const renderPageContent = (
    <div className={classes.pageContent}>
        <EnhancedPageToolbar
          numSelected={selected && selected.length}
          {...{
            onAddClick: () => {
              history.replace(`/shopping/brand/edit?id=${createId()}`)
            },
            onDelClick: () => {
              handleDelete(selected)
            },
            onRefresh: () => {
              loadList()
            }
          }} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
          <TableHead
            checkbox
            fields={fields}
            numSelected={selected && selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
            <TableBody>

              {stableSort(list, getSorting(order, orderBy))
                .map((row: any, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <BodyTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="default"
                        className={classes.checked}
                        checkedIcon={<span className={classNames(classes.checkBoxIcon, classes.checkedIcon)} />}
                        icon={<span className={classes.checkBoxIcon} />}
                      />
                      </BodyTableCell>
                      <BodyTableCell align="left" style={{ width: 60 }}>
                        {row.image ? <img src={`${attachPrefix}${row.image}`} width={32} height={32} />:'无'}
                      </BodyTableCell>
                      <BodyTableCell align="left">  {row.name}  </BodyTableCell>
                      <BodyTableCell align="left">
                            <RadioInput
                              labelWidth={0}
                              value={row.status}
                              options={[
                                { label: "未启用", value: "0" },
                                { label: "启用", value: "1" }
                              ]}
                              label=""
                              margin="0"
                              onChange={(e: any, value: any) => {
                                e.preventDefault()
                                handleStatus(row.id, value)
                                return false;
                              }} />
                      </BodyTableCell>
                      <BodyTableCell align="left">{row.sorter}</BodyTableCell>
                      <BodyTableCell align="right" className={classes.rowToolbar}>
                      <TableRowToolbar
                        {...{
                          editLabel: translate('shopping.brand.edit'),
                          deleteLabel: translate('shopping.brand.remove'),
                          previewLabel: translate('shopping.brand.preview'),
                          onPreview: (event: any) => {
                            event.preventDefault();
                            setPreview({
                              open: true,
                              row
                            })
                          },
                          onEdit: () => {
                            history.replace(`/shopping/brand/edit?id=${row.id}`)
                          },
                          onDelete: () => {
                            handleDelete([row.id])
                          },
                        }} />

                      </BodyTableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <LoadingBox {...loading} />

        {list.length === 0 && !loading.open && (<Box className="error">
          <Icon name="Error" width={32} height={32} color="gray" />
          <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
        </Box>)}
        <Box style={{paddingLeft:16}}>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label={translate('dense')}
            />
        </Box>
    </div>)

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setPreview({ open, row: {} as any });
  };
  const renderPreview = () => {
    const brand = preview.row
    return <Drawer anchor="right" open={preview.open} onClose={toggleDrawer(false)}>
      <div className={classes.preview} style={{ padding: 10 }} >
        <div>
          {brand.name}
        </div>
        <div dangerouslySetInnerHTML={{ __html: brand.descri }} />
      </div>
    </Drawer>
  }
  return (<Box className={classes.root}>

    {renderPageContent}

    {renderPreview()}

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </Box>
  )
}
export default withRouter(BrandListPage)