import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="19116" width={width} height={height}>
    <path fill={color} d="M597.333333 725.333333 725.333333 725.333333 810.666667 554.666667 810.666667 298.666667 554.666667 298.666667 554.666667 554.666667 682.666667 554.666667M256 725.333333 384 725.333333 469.333333 554.666667 469.333333 298.666667 213.333333 298.666667 213.333333 554.666667 341.333333 554.666667 256 725.333333Z" p-id="19117"></path></svg>)
