import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'


// Action Types
export type Action = {
    // 创建文章索引
    type: 'COMMON_INDEXED_ARTICLE',
    payload: { client: 'default', request: AxiosRequestConfig }
}|{
    // 清空文章索引
    type: 'COMMON_INDEXED_ARTICLE_CLEAR',
    payload: { client: 'default', request: AxiosRequestConfig }
}|{
    // 创建商品索引
    type: 'COMMON_INDEXED_PRODUCT',
    payload: { client: 'default', request: AxiosRequestConfig }
}|{
    // 清空商品索引
    type: 'COMMON_INDEXED_PRODUCT_CLEAR',
    payload: { client: 'default', request: AxiosRequestConfig }
};

// 创建文章索引
export const INDEXED_ARTICLE = 'COMMON_INDEXED_ARTICLE'
export const articleIndexed = (): Action => ({
    type: INDEXED_ARTICLE,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/indexed`
        }
    }
})


// 创建文章索引
export const INDEXED_ARTICLE_CLEAR = 'COMMON_INDEXED_ARTICLE_CLEAR'
export const clearArticleIndexed = (): Action => ({
    type: INDEXED_ARTICLE_CLEAR,
    payload: {
        client: 'default',
        request: {
            url: `${API}content/article/clearIndexed`
        }
    }
})


// 创建商品索引
export const INDEXED_PRODUCT = 'COMMON_INDEXED_PRODUCT'
export const productIndexed = (): Action => ({
    type: INDEXED_PRODUCT,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/indexed`
        }
    }
})


// 创建商品索引
export const INDEXED_PRODUCT_CLEAR = 'COMMON_INDEXED_PRODUCT_CLEAR'
export const clearProductIndexed = (): Action => ({
    type: INDEXED_PRODUCT_CLEAR,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/product/clearIndexed`
        }
    }
})