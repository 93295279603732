import * as ConfigStorage from "../../storage/ConfigStorage";
import * as RouteStorage from "../../storage/RouteStorage";

import { useDispatch } from "react-redux";
import * as actions from "../../store/actions/app";
import * as appTypes from "../../types/app";
import * as factory from "../../config/factory"

import { Route} from '../../types/app'

interface Result {
  configFactory: Function;
  openPage: Function;
  closePage: Function;
  closeAllPage: Function;
  closeOtherPage:Function;
}


/**
 * 系统配置逻辑
 */
export const useConfig = (): Result => {
  const dispatch = useDispatch()
  //  初始化导航菜单路由数据
  const configFactory = async () => {
    // 数据工厂初始化配置数据
    const locale = ConfigStorage.getLocale();
    const config: appTypes.Config = await factory.build({ locale });
    // 保存到本地
    ConfigStorage.setConfig(config);
    dispatch(actions.factory(config));
  };
  /**
   * 页面操作完成
   */
  const pageTodo = (currentRoutes: appTypes.CurrentRoutes) => {
    dispatch(actions.currentRoutes(currentRoutes));
  };

  // 查找当前的路由，并返回当前打开的路由列表及标签页序号
  const openPage = (pathname: string) => {
    let currentRoute = RouteStorage.getCurrent();
    // 是否需要切换当前路由标识
    let mastChange = false;
    if (currentRoute && currentRoute.path === pathname) {
      // 查找的路由是当前路由
    } else if (currentRoute && currentRoute.path !== pathname) {
      // 查找的路由不是当前路由
      mastChange = true;
    } else {
      // 当前路由不存在
      mastChange = true;
    }
    if (mastChange) {
      const config = ConfigStorage.getConfig();
      const routes = config ? config.routes:[]
      if (routes)
        for (let i = 0; i < routes.length; i++) {
          const route = routes[i];
          if (route.path === pathname) {
            currentRoute = route;
            continue;
          }
        }
    }
    // 添加路由到缓存
    if (currentRoute) {
      const currentRoutes = RouteStorage.setCurrent(currentRoute);
      pageTodo(currentRoutes);
    }
  };
  // 关闭当前路由
  const closePage = (pathname: string, callback?: Function) => {
    const currentRoutes = RouteStorage.remove(pathname);
    if (callback) {
      callback(currentRoutes);
    }
    pageTodo(currentRoutes);
  };
  // 关闭所有路由
  const closeAllPage = (callback: Function) => {
    const currentRoutes = RouteStorage.clear();
    if (callback) {
      callback(currentRoutes);
    }
    pageTodo(currentRoutes);
  };
  // 关闭当前路由
  const closeOtherPage = (route: Route, callback?: Function) => {
    const currentRoutes = RouteStorage.removeOther(route);
    if (callback) {
      callback(currentRoutes);
    }
    pageTodo(currentRoutes);
  };

  return {
    configFactory,
    openPage,
    closePage,
    closeAllPage,
    closeOtherPage
  };
};
