
import React, { useEffect } from 'react'

import Box from '@material-ui/core/Box';
import CircularProgress from './CircularProgress';

import { useLoadMoreStyles } from '../../assets/jss/components';

type Props = {
    status:"ready" | "loadBegin"| "loading" | "noMore" | "error",
    onStatusChange:(status:"ready" | "loadBegin"| "loading" | "noMore" | "error")=>void,
    loadData:(cb:(status:"ready" |"noMore" | "error")=>void)=>void,
    showReady?:boolean,
    options?:{
        loadingText?:string,
        noMoreText?:string,
        defaultText?:string
    },
    onMoreClick?:()=>void
}  
/**
 * 分页 
 * @param props 
 */
export default (props: Props) => {

    const {options = {},loadData,showReady,status = 'ready',onStatusChange,onMoreClick} = props
    const classes = useLoadMoreStyles()

    const loadingText = options.loadingText || "正在加载更多..."
    const noMoreText = options.noMoreText || "〜到底了~"
    const defaultText =options.defaultText ||  "上拉加载更多."

    const render = ()=>{

        switch (status) {
            case "ready":
                if(showReady)
                return <Box className={classes.defaultBox} onClick={onMoreClick}>
                    <span className={classes.defaultText}>{defaultText}</span>
                </Box>
                return <></>
            case "loadBegin":
                onStatusChange("loading")
                loadData((status:"ready" | "noMore" | "error")=>{
                    onStatusChange(status)
                })
            case "loading":
                return <Box className={classes.loadingBox}>
                    <CircularProgress />
                    <span className={classes.loadingText}>{loadingText}</span>
                </Box>
            case "noMore":
                return <Box className={classes.noMoreBox}>
                    <span className={classes.noMoreText}>{noMoreText}</span>
                </Box>
            case "error":
                return <Box className={classes.errorBox}>
                    <span className={classes.errorText}>加载失败！</span>
                </Box>

        }
        return <></>
    }
    return render()
}