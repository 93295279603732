import React from 'react';
import Box from '@material-ui/core/Box';

import {ChatUser } from "./types"
import ChatUsers from "./ChatUsers"
import ChatMessages from "./ChatMessages"
import { useSysState } from '../../provides/sys'

import Error from "../../components/Error"
import Client from "./Client"
 
import { useChatStyles } from '../../assets/jss/components'
  
import {TabPanel} from '../../components/ui/tabs/StyleTabs'

export default (props:any) => {

    const classes = useChatStyles()
    const { account } = useSysState()
    const {history,onChating} = props
    
    if(!account){
      return <Error message="用户未登录"/>
    }
    const client = Client()
    
    const [message,setMessage] = React.useState<any>({})
    const [others,setOthers] = React.useState<any>({})
    const [otherId,setOtherId] = React.useState<string>('')
    
    const onSelect = (user:ChatUser)=>{
      if(otherId !== user.id){
        others[user.id] = user
        setOthers(Object.assign({},others))
        onChating(user)
        setOtherId(user.id)
      }
    } 

    return (<Box className={classes.root}>
       <Box className={classes.side}>
        <ChatUsers onSelect={onSelect} client={client}/>
       </Box> 
            {Object.keys(others).map((id:string,i:number)=><TabPanel
              key={i}  
              value={otherId}
              index={id} 
              ariaprefix="user-online">
                <ChatMessages other={others[id]}  client={client}/>
        </TabPanel>)}
    </Box>
    )
}