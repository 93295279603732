
import React, { useState, useCallback } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { CloseButton } from '../button';

import { useLocale } from '../../../config/Locale'
import { AlertDialog } from '../dialog'

import Icon from '../../Icon'

import Multi from './Multi'

const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      background: '#f5f5f5',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px solid #dedede',
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    }
  }))

const DialogTitle = (props: any) => {
  const classes = useStylesTitle()
  const { translate } = useLocale()
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span>{children}</span>
      {onClose ? (
        <span className={classes.closeButton} onClick={(e) => { onClose(e) }}>
          <CloseButton open={true} close={onClose} />
        </span>
      ) : null}
    </MuiDialogTitle>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    alert: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alertIcon: {
      margin: theme.spacing(2)
    }
  }))


const MultiDialog = (props: any) => {
  const {
    open,
    onClose,
    success,
    type,
    defaultGroup,
    noShowGroup,
    onProgress
  } = props;
  const { translate } = useLocale()
  const classes = useStyles()

  const [fullWidth, setFullWidth] = useState(false);
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('md');

  const [progress,setProgress] = useState(null as any)
  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })
  const handleClose = () => {
    if(progress && progress.progress){
      const description = (<Box className={classes.alert}>
        <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
        <Box>
          {/* 正在上传文件，关闭窗口会中断上传!
        确定要继续么？ */}
          <Box>{translate('upload.interruptTips')}</Box>
          <Box>{translate('upload.interruptAsk')}</Box>
        </Box>
      </Box>)
      setAlertOpen(true)
      setAlertProps({
        description,
        title: '',
        ok: () => {
          setAlertOpen(false)
          setProgress(null)
          onClose()
        },
        cancel: () => {
          setAlertOpen(false)
        }
      })
    }else{
      onClose()
    }
  }
  return (<>
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}>
      <DialogTitle onClose={handleClose}>
        {type === 'image' || type === 'images' ?
          translate('upload.uploadImage') :
          translate('upload.uploadFile')
        }
      </DialogTitle>
      <DialogContent className={classes.content} dividers={true}>

        <Multi {...{
          type,
          defaultGroup,
          noShowGroup,
          onProgress:(props:any)=>{
            setProgress(props)
            if(onProgress){
              onProgress(props)
            }
          },
          success: (attaches: any) => {
            // 上传成功，返回编辑页面
            success({
              attaches
            })
          }
        }} />
      </DialogContent>
    </Dialog>
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </>);
}
export default MultiDialog