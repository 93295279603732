import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconEdit from "@material-ui/icons/Edit";

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "../../../components/ui/expansion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PageToolbar from "../../../components/ui/PageToolbar";
import LoadingBox from "../../../components/ui/LoadingBox";
import Icon from "../../../components/Icon";
import { SubmitedDialog } from "../../../components/ui/dialog";

import { useUIPageStyles } from "../../../assets/jss/pages";

import { usePage, usePageState } from "../../../provides/ui";
import { Page } from "../../../types/ui";

type ItemProps = {
  item: Page;
  expanded?: boolean;
  onChange: any;
  onEdit: () => void;
};

const Item = (props: ItemProps) => {
  const classes = useUIPageStyles();
  const { item, expanded, onEdit, onChange } = props;

  return (
    <ExpansionPanel square expanded={expanded} onChange={onChange}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls={`${item.id}d-content`}
        id={`${item.id}d-header`}
        className={clsx(classes.heading, {
          [classes.headingActive]: expanded
        })}
      >
        <Button 
          variant="outlined"
          onClick={onEdit} 
          style={{ paddingRight: 0 ,marginRight:16}}>
          <IconEdit className={classes.icon} />
          编辑
        </Button>
        <span className={classes.headingLabel}>页面ID:</span>
        <span className={classes.headingValue}> {item.id}</span>
        <span className={classes.headingLabel}>页面名称:</span>
        <span className={classes.headingValue}>{item.name}</span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <List style={{ width: "100%" }}>
          <ListItem>
            <span className={classes.label}>页面标题：</span>
            {item.title || "未填写"}
          </ListItem>
          <ListItem>
            <span className={classes.label}>页面描述：</span>
            {item.description || "未填写"}
          </ListItem>
          <ListItem>
            <span className={classes.label}>关键词组：</span>
            {item.keywords || "未填写"}
          </ListItem>
          <ListItem>
            <span className={classes.label}>页面配置：</span>
            {item.setting ? "已设置" : "未设置"}
          </ListItem>
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withRouter((props: any) => {
  const { history } = props;
  const { list } = usePageState();
  const { getList } = usePage();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  // 事件反馈
  const initialMessage = {
    open: false,
    type: "",
    title: "",
    text: ""
  };
  const [message, setMessage] = useState(initialMessage);
  const initialLoading = { open: false, text: "" };
  const [loading, setLoading] = useState(initialLoading);

  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: "正在加载数据..."
    });
    getList({
      success: function() {
        setLoading({
          open: false,
          text: ""
        });
      },
      failure: function() {
        setLoading({
          open: false,
          text: ""
        });
      }
    });
  };

  useEffect(loadData, []);

  return (
    <Box>
      <PageToolbar
        {...{
          title: "页面设置",
          onRefresh: () => {
            // 刷新数据
            loadData();
          }
        }}
      />
      {list.map((item: Page, index: number) => (
        <Item
          key={index}
          item={item}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          onEdit={() => {
            history.replace(`/ui/page/edit?id=${item.id}`);
          }}
        />
      ))}

      {list.length === 0 && !loading.open && (
        <Box className="error">
          <Icon name="Error" width={32} height={32} color="gray" />
          <span style={{ marginLeft: "10px" }}>
            {message && message.type === "error"
              ? message.text
              : "查询结果为空！"}
          </span>
        </Box>
      )}

      <LoadingBox {...loading} />
      <SubmitedDialog
        onClose={() => {
          setMessage(initialMessage);
        }}
        message={message}
      />
    </Box>
  );
});
