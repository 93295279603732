import React from "react";
import {
  fade,
  makeStyles,
  createStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { ImagesInputProps } from "./types";
// 上传组件
import Upload from "../upload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    label: {
      textAlign: "right",
      flexWrap: "nowrap"
    },
    input: {
      flex: 1,
      margin: theme.spacing(1)
    },
    tips: {
      padding: theme.spacing(1, 0),
      fontSize: 12,
      color: "#999"
    }
  })
);

const ImagesInput = (props: ImagesInputProps) => {
  const classes = useStyles();
  const { label, labelWidth, onChange, value, tips, ...rest } = props;
  const labelStyle: React.CSSProperties = labelWidth
    ? {
        width: labelWidth
      }
    : {};
  return (
    <Box className={classes.root}>
      <Box component="span" style={labelStyle} className={classes.label}>
        {label}
      </Box>
      <Box className={classes.input}>
        {Upload({
          type: "images",
          value,
          onChange: (props: any) => {
            let urls = [];
            let attaches = props.attaches;
            if (attaches) {
              attaches.map((attach: any) => urls.push(attach.url));
            }
            if (props.url && urls.length === 0) {
              urls.push(props.url);
            }
            if (onChange) {
              onChange(urls);
            }
          },
          ...rest
        })}
      </Box>
      {tips && <Box className={classes.tips}>{tips}</Box>}
    </Box>
  );
};
export default ImagesInput;
