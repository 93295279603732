import React from "react"

import { ComponentOption } from "../../../../types/ui"
import OptionInput from "./components/OptionInput"
import OptionField from "./components/OptionField"

type Props = {
  value?: any,
  option: ComponentOption,
  onChange: (name: string, value: any) => void,
  editing?: boolean
}

export default (props: Props) => {
  const { value, option, onChange, editing } = props
  if (editing) {
    return <OptionInput
      option={option}
      value={value}
      onChange={(name: string, value: any) => {
        onChange(name, value)
      }} />
  }
  return <OptionField
    option={option}
    value={value} />
}

