import React from "react"

import { formatDateTime } from '../../../../lib/util'
import { attachPrefix } from "../../../../config"

import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Icon from "../../../../components/Icon"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconDelete from '@material-ui/icons/Delete';
import IconList from '@material-ui/icons/List';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableRow, BodyTableCell, TableHead } from '../../../../components/ui/table'

import AdListDialog from './AdListDialog'

import LoadingBox from '../../../../components/ui/LoadingBox'

import { useAd } from "../../../../provides/common"

type Props = {
    tools?:any,
    ids?: string[],
    onChange: (ids: string[]) => void
}

const fields = [
    { id: 'image', numeric: false, disablePadding: false, label: '图片' },
    { id: 'title', numeric: false, disablePadding: true, label: '标题' },
    { id: 'link', numeric: true, disablePadding: false, label: '链接地址' },
    { id: 'createTime', numeric: false, disablePadding: false, label: '发布时间' },
    { id: 'browser', numeric: true, disablePadding: false, label: '浏览' },
];

// 组件数据输入
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const { tools,ids, onChange } = props

    const [selected, setSelected] = React.useState<string[]>(ids || [])
    const getAdList = useAd().getListByIds
    const [adList, setAdList] = React.useState<any>([])
    const [open, setOpen] = React.useState(false)

    // 事件反馈
    const initialMessage = {
        open: false,
        type: '',
        title: '',
        text: ''
    }
    const [message, setMessage] = React.useState(initialMessage)
    const initialLoading = { open: false, text: '' }
    const [loading, setLoading] = React.useState(initialLoading)
    const loadAdList = (id: string[]) => {
        if (id.length > 0) {
            // 获取列表
            setLoading({
                open: true,
                text: '正在加载广告列表数据...'
            })
            getAdList({
                id,
                success: (list: any) => {
                    setAdList(list)
                    // 关闭加载条
                    setLoading(initialLoading)
                },
                failure: (message: string) => {
                    setAdList([])
                    // 关闭加载条
                    setLoading(initialLoading)
                    setMessage({
                        open: false,
                        type: 'error',
                        title: '提示',
                        text: message
                    })
                }
            })
        } else {
            setAdList([])
        }
    }
    React.useEffect(() => loadAdList(selected || []), []);

    const handleChange = (ids: string[]) => {
        setSelected(ids)
        loadAdList(ids)
        onChange(ids)
    }

    return <Box className={classes.list}>
        <Box className={classes.listToolbar}>
            <Box className={classes.listToolbarTitle}> <IconList />数据列表</Box>
            {tools && <Box>
                {tools}
            </Box>}
            <Box className={classes.grow} />
            <Button color="primary" onClick={() => setOpen(true)}>
                <IconAdd />导入广告
            </Button>
        </Box>
        {adList && adList.length > 0 &&
            <Table
                aria-labelledby="tableTitle"
                size="medium"
            >
                <TableHead fields={fields} />
                <TableBody>
                    {adList.map((row: any, index: number) => {
                        return (
                            <TableRow
                                hover
                                key={row.id}
                            >
                                <BodyTableCell align="left" style={{ width: 80,}}>
                                    <Box style={{ 
                                        display:"flex",
                                        alignItems:"center",
                                        whiteSpace:"nowrap",
                                        color:"#888",
                                        fontWeight:"bold",
                                    }}>
                                    <span style={{padding:"0 8px 0 4px"}}> {index + 1}.</span>
                                    {row.image ? <img src={`${attachPrefix}${row.image}`} width={64} height={32} />
                                        :
                                        '无'
                                    }
                                    </Box>
                                </BodyTableCell>
                                <BodyTableCell align="left">
                                    {row.title}
                                </BodyTableCell>
                                <BodyTableCell align="left">{row.link}</BodyTableCell>
                                <BodyTableCell align="left">{formatDateTime(row.createTime)}</BodyTableCell>
                                <BodyTableCell align="left">{row.browser || 0}</BodyTableCell>
                                <BodyTableCell align="right">
                                    <Button size="small" onClick={() => {
                                        const newIds = ids || []
                                        if (newIds.length === 1) {
                                            handleChange([])
                                        } else {
                                            handleChange(newIds.filter((id: any) => row.id !== id))
                                        }
                                    }}>
                                        <IconDelete className={classes.icon} />
                                        删除
                                        </Button>
                                </BodyTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        }
        {
            open && <AdListDialog
                ids={selected}
                success={(ids: string[]) => {
                    handleChange(ids)
                    setOpen(false);
                }}
                cancel={() => setOpen(false)} />
        }
        {adList.length === 0 && !loading.open && (<Box className="error">
            <Icon name="Error" width={32} height={32} color="gray" />
            <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
        </Box>)}
        <LoadingBox {...loading} />
    </Box>
}