import React from "react"

import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import NumberInput from '../../../../components/ui/form/NumberInput'
import RadioInput from '../../../../components/ui/form/RadioInput'

import { CarouselSetting } from "./types"

type Props = {
    value?: CarouselSetting,
    onChange: (value: CarouselSetting) => void
}

// 组件数据输入
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const { value = {}, onChange } = props

    const setItem = (name: "dots" | "arrows" | "fade" | "infinite" | "speed" | "autoplay" | "autoplaySpeed" | "slidesToShow" | "slidesToScroll" | "slidesToScroll" | "vertical", val: any) => {
        const settings = value || {}
        settings[name] = val
        onChange(settings)
    }

    const labelWidth = 120

    return <Box className={classes.fieldSet}>
        <Box className={classes.fieldSetHeading}>轮播设置</Box>
        <Box className={classes.wrapBox}>
        <Box className={classes.wrapBoxItem}>
            <RadioInput
                label="序列点："
                labelWidth={labelWidth}
                value={value.dots ? "1" : "0"}
                options={[
                    { label: "否", value: "0" },
                    { label: "是", value: "1" }
                ]}
                onChange={(e: any, value: any) => {
                    setItem("dots", value === "1" ? true : false)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <RadioInput
                label="切换箭头："
                labelWidth={labelWidth}
                value={value.arrows ? "1" : "0"}
                options={[
                    { label: "否", value: "0" },
                    { label: "是", value: "1" }
                ]}
                onChange={(e: any, value: any) => {
                    setItem("arrows", value === "1" ? true : false)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <RadioInput
                label="淡入淡出："
                labelWidth={labelWidth}
                value={value.fade ? "1" : "0"}
                options={[
                    { label: "否", value: "0" },
                    { label: "是", value: "1" }
                ]}
                onChange={(e: any, value: any) => {
                    setItem("fade", value === "1" ? true : false)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <RadioInput
                label="无限循环："
                labelWidth={labelWidth}
                value={value.infinite ? "1" : "0"}
                options={[
                    { label: "否", value: "0" },
                    { label: "是", value: "1" }
                ]}
                onChange={(e: any, value: any) => {
                    setItem("infinite", value === "1" ? true : false)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <NumberInput
                label="滑动速度："
                labelWidth={labelWidth}
                inputWidth={60}
                value={value.speed || 500}
                onChange={(value: any) => {
                    setItem("speed", value)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <RadioInput
                label="自动播放："
                labelWidth={labelWidth}
                value={value.autoplay ? "1" : "0"}
                options={[
                    { label: "否", value: "0" },
                    { label: "是", value: "1" }
                ]}
                onChange={(e: any, value: any) => {
                    setItem("autoplay", value === "1" ? true : false)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <NumberInput
                label="切换延时："
                labelWidth={labelWidth}
                inputWidth={60}
                value={value.autoplaySpeed || 3000}
                onChange={(value: any) => {
                    setItem("autoplaySpeed", value)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <NumberInput
                label="显示滑块数量："
                labelWidth={labelWidth}
                inputWidth={60}
                value={value.slidesToShow || 1}
                onChange={(value: any) => {
                    setItem("slidesToShow", value)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <NumberInput
                label="切换到滑动："
                labelWidth={labelWidth}
                inputWidth={60}
                value={value.slidesToScroll || 1}
                onChange={(value: any) => {
                    setItem("slidesToScroll", value)
                }} />
        </Box>

        <Box className={classes.wrapBoxItem}>
            <RadioInput
                label="纵向轮播："
                labelWidth={labelWidth}
                value={value.vertical ? "1" : "0"}
                options={[
                    { label: "否", value: "0" },
                    { label: "是", value: "1" }
                ]}
                onChange={(e: any, value: any) => {
                    setItem("vertical", value === "1" ? true : false)
                }} />
        </Box>
        </Box>
    </Box>
}