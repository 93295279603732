import React  from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useLocale } from '../../../config/Locale';
import FormField from './FormField'
import SplitButton from '../button/SplitButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      zIndex: 999
    },
  }))

  const list = [{
    id:"all",
    label:"全部订单"
  },{
    id:"0",
    label:"新增订单"
  },
  {
    id:"1",
    label:"订单待付款", 
  },
    {
      id:"paied",
      label:"已付款订单"
    },
    {
      id:"2",
      label:"待发货"
    },//已付款
    {
    id:"3",
    label:"付款失败"
  },
    {
      id:"4",
      label:"已发货"
    },
    {
      id:"5",
      label:"已到货"
    },
    {
      id:"6",
      label:"已收货"
    },
    {
      id:"20",
      label:"申请退款"
    },
    {
      id:"21",
      label:"退款申请通过"
    },
    {
      id:"22",
      label:"退款申请不通过"
    },
    {
      id: "23",
      label:"退款退货在途"
    },
    {
      id:"24",
      label:"退款退货到途"
    },
    {
      id:"25",
      label:"退款资金退回"
    },
    {
      id:"26",
      label:"退款完成"
    },
    {
      id:"-1",
      label:"已取消"
    }]
    
export type InputProps = {
  value?: any,
  label?:any,
  labelWidth?: number,
  onChange: (item: any) => void
}
export default (props: InputProps)=> {
  const classes = useStyles()
  const { label,labelWidth, onChange, value } = props
  const { translate } = useLocale()

  return (<FormField
    labelWidth={labelWidth}
    label={`${ label || `${translate('trade.order.status.label')}：`}`}>
    <SplitButton
      label={translate('trade.order.status.choose')}
      variant="default"
      className={classes.button}
      options={list}
      value={value}
      size="small"
      onSelect={(item: any) => {
        onChange(item)
      }} />
  </FormField>);
}

