import React from "react"

import { DataSource } from "../../../../types/ui"
import { CardItem } from "./types"
import InputList from "./InputList"

type Props = {
    data?: CardItem[],
    onChange: (dataSource: DataSource, data: CardItem[]) => void
}

const options = [
    {
        id: "1",
        name: 'title',// 类型名称
        alias: '标题',// 别名
        variableType: 'string',// 变量类型
        notNull: false,// 非空
    },
    {
        id: "2",
        name: 'link',// 类型名称
        alias: '链接地址',// 别名
        variableType: 'link',// 变量类型
        notNull: false,// 非空
    },
    {
        id: "3",
        name: 'image',// 类型名称
        alias: '图片',// 别名
        variableType: 'image',// 变量类型
        notNull: true,// 非空
    },
    {
        id: "4",
        name: 'content',// 类型名称
        alias: '内容',// 别名
        variableType: 'html',// 变量类型
        notNull: false,// 非空
    },
    {
        id: "5",
        name: 'description',// 类型名称
        alias: '附加说明',// 别名
        variableType: 'html',// 变量类型
        notNull: false,// 非空
    },
    {
        id: "6",
        name: 'buttons',// 类型名称
        alias: '按钮组',// 别名
        variableType: 'Button[]',// 变量类型
        notNull: false,// 非空
    }]


// 组件数据输入
export default (props: Props) => {
    const { data = [], onChange } = props

    return <InputList
        options={options}
        data={data || []}
        edittable
        onChange={(data: CardItem[]) => {
            onChange({ type: "input" }, data)
        }} />
}