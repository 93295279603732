import React from 'react';
import Icon from './Icon'
import Button from './Button'

const Screen = (props: any) => {
    const {fullScreen, setFullScreen} = props

    return (
        <Button
            onMouseDown={() => {
                fullScreen ?
                setFullScreen(false) :
                setFullScreen(true)
            }}>
            {fullScreen ?
                <Icon name="FullScreenExit" width={18} height={18} /> :
                <Icon name="FullScreen" width={18} height={18} />}
        </Button>
    );
}

export default Screen