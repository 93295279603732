import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ErrorIcon from '../../icons/Error';
import SuccessIcon from '../../icons/Success';

import { useLocale } from '../../../config/Locale'
import { SubmitedDialogProps } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(1),
      fontSize: '1em'
    },
    content: {
      display: 'flex',
      padding: theme.spacing(1),
      minWidth: theme.spacing(25),
      alignItems: 'center',
      justifyContent: 'center'
    },
    actions: {
      padding: theme.spacing(2)
    },
    success: {
      color: 'green',
    },
    error: {
      color: 'red',
    },
    icon: {
    },
    text: {
      marginLeft: '10px',
    }
  }))

export default function SubmitedDialog(props: SubmitedDialogProps) {
  const { message, goList, goAdd, goModify, onClose } = props
  const { translate } = useLocale()

  const classes = useStyles()

  if (message.open) {
    return (
      <div>
        <Dialog
          open={message.open}
          onClose={onClose}
        >
          <DialogTitle className={classes.title}>
            {/* 标题 */}
            {message.title || translate('alert.tips')}
          </DialogTitle>
          <DialogContent className={classes.content}>
            {/* 内容 */}
            <span>
              {message.type === 'success' ? (
                <SuccessIcon className={classes.icon} width={32} height={32} color="green" />
              ) : (
                  <ErrorIcon className={classes.icon} width={32} height={32} color="gray" />
                )}
            </span>
            <span className={classes.text}>{message.text}</span>
          </DialogContent>
          <DialogActions className={classes.actions}>
            {
              message.type === 'success' && (
                <>
                  {goList &&
                    <Button onClick={goList} color="primary" autoFocus>
                      {/* 返回列表 */}
                      {translate('goList')}
                    </Button>
                  }
                  {goAdd &&

                    <Button variant="contained" onClick={goAdd} color="primary" autoFocus>
                      {/* 继续添加 */}
                      {translate('goAdd')}
                    </Button>
                  }
                </>
              )
            }
                  {goModify &&
                    <Button onClick={goModify} color="primary" autoFocus>
                      {/* 返回修改 */}
                      {translate('goModify')}
                    </Button>
                  }
            <Button onClick={onClose} color="primary">
              {/* 关闭 */}
              {translate('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return <></>
}

