import React from "react"

import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import NumberInput from '../../../../components/ui/form/NumberInput'
import SpecialTabInput from "./SpecialTabInput"

import { DataSource } from "../../../../types/ui"
import { SpecialTabItem } from "./types"

import { useItemMethods } from './components/Util';

type Props = {
    data?: SpecialTabItem[],
    dataSource?: DataSource,
    onChange: (dataSource: DataSource, data: SpecialTabItem[]) => void
} 

// 组件数据输入
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const { data = [], dataSource, onChange } = props

    const [maxItems, setMaxItems] = React.useState<number>(dataSource && dataSource.maxItems || 5)

    const {
        onItemAdd,
        onItemSave,
        onItemRemove,
        sortUp,
        sortDown
    } = useItemMethods({
        data,
        initialData: {
            type: "product"
        },
        onChange: (data: any) => {
            if (onChange) {
                onChange({ type: "input",maxItems }, data)
            }
        }
    });

    return <Box>
        <Box className={classes.list}>
            <Box className={classes.listToolbar}>
                <Box className={classes.listToolbarTitle}> <IconList />标签页列表</Box>
                <NumberInput
                    labelWidth={120}
                    value={maxItems}
                    label="最大条目数："
                    onChange={(value: any) => {
                        setMaxItems(value);
                    }} />
                <Box className={classes.grow} />
                <Button color="primary" onClick={() => onItemAdd()}>
                    <IconAdd />添加标签页
            </Button>
            </Box>

            {data.map((item: any, index: number) => <SpecialTabInput
                key={item.id || index}
                item={item}
                onSave={onItemSave}
                onRemove={onItemRemove}
                showUp={index > 0}
                showDown={index < data.length - 1}
                sortUp={() => {
                    sortUp(index)
                }}
                sortDown={() => {
                    sortDown(index)
                }} />)}

        </Box>
    </Box>
}