import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { useLocale } from '../../../../config/Locale'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            padding: theme.spacing(1),
            fontSize: '1em'
        },
        content: {
            padding: theme.spacing(1)
        },
        actions: {
            padding: theme.spacing(1),
            textAlign:'right'
        },
        button:{
            marginLeft:theme.spacing(1)
        }
    }))

export type ModalProps = {
    children?: any,
    title?: string,
    anchorEl: any,
    ok: () => void,
    cancel: () => void
}
const Modal = (props: any) => {
    const classes = useStyles()
    const { translate } = useLocale()
    const { children, title, anchorEl, ok, cancel } = props

    const stopPropagation = (event: any) => {
        event.stopPropagation();
    };
    function handleClose() {
        cancel()
    }
    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={cancel}
            onClick={stopPropagation}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box className={classes.title}>
                {/* 标题 */}
                {title || translate('alert.tips')}
            </Box>

            <Box className={classes.content}>
                {/* 内容 */}
                {children}
            </Box>
            <Box className={classes.actions}>
                <Button variant="contained" onClick={ok} color="primary" autoFocus>
                    {/* 确定 */}
                    {translate('alert.sure')}
                </Button>
                <Button onClick={handleClose} color="primary" className={classes.button}>
                    {/* 取消 */}
                    {translate('alert.cancel')}
                </Button>
            </Box>
        </Popover>
    );

}

export default Modal