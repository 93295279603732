import service from './service'
/**
 * get数据
 * @param url
 * @param params
 * @param cache
 * @returns {Promise<*>}
 */
export const get = (props:any) => {
  const { url, params } = props
  return service({
    url: url,
    method: 'get',
    params
  })
}
/**
 * 提交数据
 * @param url
 * @param data
 * @param cache
 * @returns {*}
 */
export const post = (props:any) => {
  const { url, data } = props

  return service({
    url: url,
    method: 'post',
    data
  })
}