
import * as actions from '../../store/actions/common/indexed'
import { useDispatch } from "react-redux";

interface Result {
    articleIndexed: Function,
    clearArticleIndexed: Function,
    productIndexed: Function,
    clearProductIndexed: Function,
}

export const useIndexed = (): Result => {
    const dispatch:Function = useDispatch();

    // 创建文章索引
    const articleIndexed = (props: any) => {
        const { success, failure } = props
        dispatch(actions.articleIndexed()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    // 创建文章索引
    const clearArticleIndexed = (props: any) => {
        const { success, failure } = props
        dispatch(actions.clearArticleIndexed()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    // 创建商品索引
    const productIndexed = (props: any) => {
        const { success, failure } = props
        dispatch(actions.productIndexed()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    // 创建商品索引
    const clearProductIndexed = (props: any) => {
        const { success, failure } = props
        dispatch(actions.clearProductIndexed()).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    return {
        articleIndexed,
        clearArticleIndexed,
        productIndexed,
        clearProductIndexed
    }
}