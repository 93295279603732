import React, { useState, useEffect,useCallback } from 'react';

import { getSelectedBlocksMetadata, setBlockData } from '../utils';
import Icon from './Icon'
import Button from './Button'

export default (props: any) => {
    const {
        onChange,
        editorState,
        title
    } = props

    const [atLeft, setAtLeft] = useState(false)
    const [atCenter, setAtCenter] = useState(false)
    const [atRight, setAtRight] = useState(false)
   
    const query = () => {
        const data = getSelectedBlocksMetadata(editorState)
        if(data && data.get){
            const value = data.get('textAlign')
            setAtLeft(value === 'left')
            setAtCenter(value === 'center')
            setAtRight(value === 'right')
        }
    }
    useEffect(query) 

    // 设置
    const addBlockAlignmentData = (value: string) => {
        let nextEditorState
        if (getSelectedBlocksMetadata(editorState).get('textAlign') !== value) {
            nextEditorState = setBlockData(editorState, { 'textAlign': value });
        } else {
            nextEditorState = setBlockData(editorState, { 'textAlign': '' });
        }
        onChange(nextEditorState);
    }

    return (<>
        <Button onMouseDown={() => {
            addBlockAlignmentData('left')
        }}
            active={atLeft}
            title={title[0]}>
            <Icon name="TextAlignLeft"
                color={atLeft ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
        <Button onMouseDown={() => {
            addBlockAlignmentData('center')
        }}
            active={atCenter}
            title={title[1]}>
            <Icon name="TextAlignCenter"
                color={atCenter ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
        <Button onMouseDown={() => {
            addBlockAlignmentData('right')
        }}
            active={atRight}
            title={title[2]}>
            <Icon name="TextAlignRight"
                color={atRight ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
    </>
    );
}