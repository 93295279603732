import React from "react"

import Box from '@material-ui/core/Box';
import Icon from "../../../components/Icon"
import NumberInput from '../../../components/ui/form/NumberInput'

import { PageSetting } from "../../../types/ui"
import { ConfirmDialog } from "../../../components/ui/dialog"
import { useUIPageStyles } from "../../../assets/jss/pages"


type Props = {
    setting: PageSetting,
    onChange: (setting: PageSetting) => void,
    cancel: () => void
}
export default (props: Props) => {
    const classes = useUIPageStyles()
    const { setting, onChange, cancel } = props

    const [form, setForm] = React.useState<any>(setting)
    const setFormItem = (key: "options", value: any) => {
        form[key] = value
        setForm(Object.assign({}, form))
    }

    const [options, setOptions] = React.useState<any>(setting.options || {})
    const setOption = (key: string, value: any) => {
        const newForm = options
        newForm[key] = value;
        setOptions(Object.assign({}, newForm))
        setFormItem("options", newForm)
    }

    const labelWidth = 120

    const children = <Box className={classes.dialog}>
        <NumberInput
            labelWidth={labelWidth}
            value={options.cols || 2}
            label="每行列数："
            tips="每行列数即为每一行显示子节点数量。子节点平均分配宽度。"
            onChange={(value: any) => {
                setOption('cols', value)
            }} />

        <NumberInput
            labelWidth={labelWidth}
            value={options.spacing || 0}
            label="子节点间隔："
            tips="组件之间的间隔"
            onChange={(value: any) => {
                setOption('spacing', value)
            }} />
    </Box>

    return <ConfirmDialog  {...{
        open: true,
        fullWidth: true,
        title: "表格Grid",
        ok: () => {
            onChange(form)
        },
        cancel: () => {
            cancel()
        },
        footer: <Box className={classes.tips}>
            <Icon name="Alert" className={classes.icon} />
            表格须作为容器子元素，并须添加组件子集。<br />
            PC，手机，PAD，PC浏览器自动适配样式。
        </Box>
    }}>
        {children}
    </ConfirmDialog>
}