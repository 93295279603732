import React from "react"

import { useUIPageStyles } from "../../../../assets/jss/pages"
import Icon from "../../../../components/Icon"

import Box from '@material-ui/core/Box';
import ModuleInput from '../../../../components/common/ModuleInput';
import FormField from '../../../../components/ui/form/FormField';
import RadioInput from '../../../../components/ui/form/RadioInput'

import { DataSource } from "../../../../types/ui"

type DataInputProps = {
    dataSource?: DataSource,
    onChange: (dataSource: DataSource) => void
}


// 组件数据输入
export default (props: DataInputProps) => {
    const classes = useUIPageStyles()
    const { dataSource, onChange } = props

    const [dynamic, setDynamic] = React.useState<boolean>((dataSource && dataSource.dynamic) || false)
    const [inputType, setInputType] = React.useState((dataSource && dataSource.type) || "")

    return <Box>
        <RadioInput
            labelWidth={0}
            value={dynamic ? "1" : "0"}
            options={[
                { label: "静态数据", value: "0" },
                { label: "动态数据", value: "1" }
            ]}
            onChange={(e: any, value: any) => {
                setDynamic(value === "1" ? true : false)
            }} />

        {
            dynamic &&
            <Box className={classes.startTips} style={{ paddingBottom: 10 }}>
                <Icon name="Alert" className={classes.icon} />
                动态解析页面参数加载数据！
                </Box>
        }
        <RadioInput
            labelWidth={0}
            value={inputType}
            options={[
                { label: "文章分类列表", value: "articleCats" },
                { label: "商品分类列表", value: "productCats" }
            ]}
            onChange={(e: any, value: any) => {
                setInputType(value)
                if (value === "productCats") {
                    onChange({
                        type: value,
                        dynamic
                    })
                }
            }} />
        {inputType === "articleCats" && <ModuleInput
            labelWidth={0}
            value={dataSource && dataSource.moduleId}
            onChange={(moduleId: any) => {
                onChange({
                    type: "articleCats",
                    moduleId,
                    dynamic
                })
            }} />}
        <FormField labelWidth={0}>
            {(inputType === "articleCats" || inputType === "productCats") ?
                <Box className={classes.startTips} style={{ paddingTop: 20 }}>
                    <Icon name="Success" color="green" className={classes.icon} />
                    已选择数据源为{inputType === "articleCats" ? "文章分类列表" : "商品分类列表"}！
               </Box> :
                <Box className={classes.startTips} style={{ paddingTop: 20 }}>
                    <Icon name="Alert" className={classes.icon} />
                    请选择数据源类型！
                 </Box>
            }
        </FormField>
    </Box>
}