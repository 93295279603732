import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useLocale } from '../../../config/Locale'

import { ConfirmDialogProps } from './types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: theme.spacing(1, 2),
      fontSize: '1em'
    },
    content: {
      padding: theme.spacing(1)
    },
    actions: {
      padding: theme.spacing(1)
    },
    footer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1),
      textAlign: 'left'
    },
  }))

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const { children, showTitle = true, title, open, ok, cancel, okLabel, cancelLabel, fullWidth, maxWidth, scroll, footer } = props
  const { translate } = useLocale()

  const classes = useStyles()

  if (open) {
    return (
      <div>
        <Dialog
          open={open}
          onClose={cancel}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
        >{showTitle &&
          <DialogTitle className={classes.title}>
            {/* 标题 */}
            {title || translate('alert.tips')}
          </DialogTitle>}
          <DialogContent className={classes.content} dividers={scroll && scroll === "paper"}>
            {/* 内容 */}
            {children}
          </DialogContent>
          <DialogActions className={classes.actions}>
            {footer && <Box component="span" className={classes.footer}>
              {footer}
            </Box>}
            <Button variant="contained" onClick={ok} color="primary" autoFocus>
              {/* 确定 */}
              {okLabel || translate('alert.sure')}
            </Button>
            <Button onClick={cancel} color="primary">
              {/* 取消 */}
              {cancelLabel || translate('alert.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return <></>
}

