import React from 'react'
export default ({
  width = 22,
  height = 22,
  color = "#333",
  className = "icon"
}) => (<svg className={className} viewBox="0 0 1228 1024" version="1.1"
  xmlns="http://www.w3.org/2000/svg" p-id="23660"
  width={width} height={height}><path fill={color}
    d="M410.87 533.75l199.68-1.27 90.89 204.8 117.76-0.369L572.17 81.92l-119.03 1.27-250.593 652.902 120.013 1.188 88.31-203.53z m80.65-287.99h42.23l81.92 204.8H408.33l83.19-204.8z m421.356 122.675l156.344 172.81 152.084-172.81H912.876zM0 902.39h977.92V819.2H0v83.19z" p-id="23661"></path></svg>
  )
