import * as actions from "../../store/actions/chat";
import { useDispatch } from "react-redux";

interface Result {
  uploadImages: Function;
  uploadFiles: Function;
  getFiles:Function;
  download: Function;
}

export const useChat = (): Result => {
  const dispatch:any = useDispatch()

  // 上传
  const uploadImages = (props: any) => {
    const { success, failure, data,onProgress } = props
    // 提交redux
    dispatch(actions.uploadImages(data,onProgress)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        // 成功返回
        if (success)
          success(data.result.chatMessage)
      } else {
        // 失败返回
        if(failure){
          failure(message || '上传失败' )
        }
      }
    })
  }

  // 上传
  const uploadFiles = (props: any) => {
    const { success, failure, data,onProgress } = props
    // 提交redux
    dispatch(actions.uploadFiles(data,onProgress)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        // 成功返回
        if (success)
          success(data.result.chatMessage)
      } else {
        // 失败返回
        if(failure){
          failure(message || '上传失败' )
        }
      }
    })
  }

  // 获取文件/图片组
  const getFiles = (props: any) => {
    const { data,success,failure,onProgress} = props
    // 提交redux
    dispatch(actions.getFiles(data,onProgress)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        // 成功返回
        if (success)
          success(data.result)
      } else {
        // 失败返回
        if(failure){
          failure(message || '加载失败！')
        }
      }
    })
  }
  // 显示/下载
  const download = (props: any) => {
    const { data,success,failure,onProgress} = props
    // 提交redux
    dispatch(actions.download(data,onProgress)).then((res: any) => {
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        // 成功返回
        if (success)
          success(data.result)
      } else {
        // 失败返回
        if(failure){
          failure(message || '下载失败！')
        }
      }
    })
  }

  return {
    uploadImages,
    uploadFiles,
    getFiles,
    download
  };
};
