import React from "react"
import {formatNumber} from '../../../lib/util'
// 容器
import Box from '@material-ui/core/Box';

import {useOrderStyles,useListPageStyles} from '../../../assets/jss/pages'

import DetailItem from './DetailItem'
 
import { OrderDetail,SendDetail} from "../../../types/trade" 
type Props = {
  list:OrderDetail[],
  selected?:SendDetail[],
  onToggle?:(detail:OrderDetail)=>void
}

export default (props: any) => {
  const classes = useOrderStyles()
  const lpClasses = useListPageStyles()
  const {  list,selected,onToggle } = props

  const [sumNum,setSumNum]  = React.useState<number>(0)
  const [sumPrice,setSumPrice]  = React.useState<number>(0)

  /**
   * 合计
   */
  const calSum = ()=>{
    let num = 0;
    let price = 0;
    if(list.length>0){
      list.map((item:any)=>{
        num += item.num;
        price += (item.price * item.num);
      })
    }
      setSumNum(num)
      setSumPrice(price);
  }

  React.useEffect(calSum);

  return <Box>
            <Box className={lpClasses.listTitle} style={{padding:8}}>
            <span className={classes.number} style={{marginRight:16}}>{sumNum}件商品</span>
            商品总价：<span className={classes.number}>￥{formatNumber(sumPrice)}</span>
            </Box>
            <Box className={lpClasses.tableWrapper}>
                {list.map((row: any,index:number) => <DetailItem
                  key={index}
                  detail={row} 
                  selected={selected}
                  onToggle={onToggle}
                  />)}
          </Box>
        </Box>
}