import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useConfig } from '../../provides/app/ConfigProvide'

import { CloseButton } from '../../components/ui/button'


/**
 * 标签栏
 */
const useTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 40, 
      maxHeight: 40,
      marginTop: 0,
      padding: theme.spacing(0),
      // background: "#e3e3e3",
      boxShadow: "0 5px 20px -6px rgba(225, 225, 225, 0.52), 0 2px 15px 0px rgba(225, 225, 225, 0.22), 0 4px 5px -2px rgba(225, 225, 225, 0.3)",
    },   
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 48,
        width: '100%',
        backgroundColor: 'transparent',
      },
    },
    scrollButtons: {
      color: '#666',
    }
  }))
export const MainTabs = (props: any) => {
  const classes = useTabsStyles()
  return <Tabs classes={classes} {...props} TabIndicatorProps={{ children: <span /> }} />
}

/**
 * 标签条目
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display:"flex",
      alignItems:"center",
      position:"relative",
      margin:"4px 0 0 8px",
      padding: "0 24px",
      minWidth: theme.spacing(7),
      minHeight: 32,
      maxHeight: 32, 
      justifyContent:"center",
      // borderTopLeftRadius: 4,
      // borderTopRightRadius: 4,
      border:"1px solid #ccc",
      borderRadius:3,
      // borderTop: "1px solid #e3e3e3",
      // borderLeft: "1px solid #e3e3e3",
      // borderRight: "1px solid #e3e3e3",
      // borderBottom: "1px solid #ccc",
      // '&:first-child':{
      //   borderLeft:0,
      //   borderTopLeftRadius: 0,
      // },
      background: "#fff",
      // fontWeight: 600,
      fontSize:"13px",
      '&:hover': {
        color: "#333",
        transition: 'all 300ms linear',
        opacity: 1,
        '& a': {
          transition: 'all 300ms linear',
        },
      },
      '& svg': {
        width:"14px",
        height:"14px",
        margin:"2px 4px 0 0"
      },
      '& a': {
        color: "#666",
        padding: 0,
        margin: 0,
        '&:hover': {
          textDecoration: "none",
        },
      },
    },
    selected: {
      transition: 'all 300ms linear',
      background:"#1890FF",
      border:"1px solid #1890FF",
      // borderTop: "1px solid #ccc",
      // borderLeft: "1px solid #ccc",
      // borderRight: "1px solid #ccc",
      // borderBottom: "1px solid #fff",
      // boxShadow:
      // "0 5px 20px -6px rgba(50,50,50, 0.42), 0 2px 15px 0px rgba(50,50,50, 0.12), 0 4px 5px -2px rgba(50,50,50, 0.2)",
    
      color: "#fff",
      '& a': {
        color: "#fff",
      },
      '& svg': {
        fill: "#fff"
      },
      '&:hover': {
        color: "#fff",
        transition: 'all 300ms linear',
        '& a': {
          transition: 'all 300ms linear',
        }
      },
    }
  }))
export const MainTab = (props: any) => {
  const classes = useStyles()
  const { to, onClick, onClose, active, label, index, onContextMenu } = props
  const [open, setOpen] = useState(false)
  const { closePage } = useConfig()
  // 删除当前路由，返回当前路由及打开的路由开列表
  const remove = (e: any) => {
    if (to === '/') {
      return;
    }
    if (e.preventDefault) {
      e.preventDefault();
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    closePage(to, (props: any) => {
      const { route } = props
      onClose(route)
    })
  }
  return (
    <Tab className={clsx(classes.root, {
      [classes.selected]: active
    })}
      label={<>
      {label}
      {to !== '/' ? <CloseButton
        open={open}
        close={remove}
      /> : <></>}
      </>}
      selected={active}
      onClick={onClick}
      onMouseEnter={() => { setOpen(true) }}
      onMouseLeave={() => { setOpen(false) }}
      disableRipple
      id={`scrollable-auto-tab-${index}`}
      aria-controls={`scrollable-auto-tabpanel-${index}`}
      onContextMenu={onContextMenu} />)
} 
