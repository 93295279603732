import { combineReducers } from 'redux';

import * as State from '../state';

import {
    initialState as appInitialState,
    reducer as appReducer
} from './app';

import {
    initialState as sysInitialState,
    reducer as sysReducer
} from './sys';

import {
    initialState as contentInitialState,
    reducers as contentReducers
} from './content';
 
import {
    initialState as shoppingInitialState,
    reducers as shoppingReducers
} from './shopping';
 
import {
    initialState as commonInitialState,
    reducers as commonReducers
} from './common'; 
 
import {
    initialState as uiInitialState,
    reducers as uiReducers
} from './ui'; 
 
 
export const initialState: State.Root = {
    app: appInitialState,
    sys: sysInitialState,
    content:contentInitialState,
    shopping: shoppingInitialState,
    common: commonInitialState,
    ui: uiInitialState
};

export const reducers = combineReducers<State.Root>({
    app: appReducer,
    sys: sysReducer,
    content: contentReducers,
    shopping: shoppingReducers,
    common :commonReducers,
    ui :uiReducers
});