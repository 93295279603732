import React  from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Login from './pages/sys/Login'

import {
  BrowserRouter as Router,// HashRouter
  Route,
  Redirect
} from "react-router-dom";

import Layout from './layout'
import Routes from './routes'

import { useConfigState } from './provides/app'
import { useSysState } from './provides/sys'


const Page = () => {
  const { theme } = useConfigState()
  const { account } = useSysState()

  if (account && account.user && account.token) {
    return (
      <ThemeProvider theme={theme}>
        <div id="app">
          <Router>
            <Layout>
              <Routes />
            </Layout>
          </Router>
        </div>
      </ThemeProvider>
    );
  }
  // 用户未登录
  // 跳转登录界面
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <Router>
          <Route path="/login" component={Login} />
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default Page