import { Action } from '../../actions';
import * as Actions from '../../actions/content/cat';
import * as State from '../../state/content';

export const initialState: State.Cat = {
};

export const reducer = (state: State.Cat = initialState, action: Action): State.Cat => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        case Actions.FETCH_ACTIVE_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_ACTIVE_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                cat: action.payload.cat
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        default: return state;
    }
};