import React  from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { useLocale } from '../../config/Locale';
import FormField from '../ui/form/FormField'
import { useModuleState } from '../../provides/common'
import SplitButton from '../ui/button/SplitButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      zIndex: 999
    },
  }))

export type ModuleInputProps = {
  value?: any,
  labelWidth?: number,
  onChange: (item: any) => void
}
export default (props: ModuleInputProps)=> {
  const classes = useStyles()
  const { labelWidth, onChange, value } = props
  const { translate } = useLocale()
  const { list } = useModuleState()

  return (<FormField
    labelWidth={labelWidth}
    label={`${translate('common.module.pageTitle')}：`}>
    <SplitButton
      label={translate('common.module.choose')}
      variant="default"
      className={classes.button}
      options={list}
      value={value}
      onSelect={(item: any) => {
        onChange(item)
      }} />
  </FormField>);
}

