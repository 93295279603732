import React, { useState, useEffect, useCallback } from 'react'

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { createTId } from '../../../lib/util'

// 表单组件
import TextInput from '../../../components/ui/form/TextInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import AreaInput from '../../../components/common/AreaInput';
// 弹框
import { LoadingDialog, SubmitedDialog } from '../../../components/ui/dialog'

import { withRouter } from "react-router-dom";

import { useLocale } from '../../../config/Locale'

// 逻辑
import { useArea } from '../../../provides/common'
import { useConfig } from '../../../provides/app/ConfigProvide'

const EditPage = (props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { get, save } = useArea()
  const { closePage } = useConfig()

  const [imageLoaded,setImageLoaded] = useState(false)
  const resetState = ()=>{
    setImageLoaded(false)// 图片
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const [form, setForm] = useState({} as any)
  // 设置表单元素
  const setFormItem = (key: string, value: any) => {
    const newForm = form || {}
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }

  const loadData = (cb?: () => void) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载分类数据...'
    })
    get({
      id: form.id,
      success: (area: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        setForm(area)
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        if (cb) {
          //  查询上级分类
          cb()
        }
      }
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  const query = () => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id && id !== form.id) {
      resetForm()
      setFormItem('id', id)
      loadData(() => {
        const parentId = params.get('parentId')
        setFormItem('parentId', parentId)
        resetState()
      })
    } 
  };
  useEffect(query);

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      area: {
          ...form
      },
      success: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route.path) {
        history.push(route.path)
      }
    })
  }

  const renderPageTitle = (
    <Box className="page-title">
      {translate('common.pageTitle')}
    </Box>)



  const renderPageForm = (<Box>
    <form onSubmit={onSubmit}>
      {/* 上级 */}
      <AreaInput
        label="上级行政区划"
        labelWidth={120}
        value={`${form.parentId || ''}`}
        onChange={(item: any) => {
          setFormItem('parentId', item.id)
        }} />
      {/* 名称 */}
      <TextInput
        labelWidth={120}
        value={`${form.name || ''}`}
        fullWidth
        label="区划名称："
        required
        placeholder="请填写区划名称，如“贵州省”"
        onChange={(event: any) => {
          setFormItem('name', event.target.value)
        }} />

      {/* 行政区划代码 */}
      <TextInput
        labelWidth={120}
        value={`${form.code || ''}`}
        fullWidth
        label="行政区划代码："
        required
        placeholder="请填写区划名称，如“52”"
        onChange={(event: any) => {
          setFormItem('code', event.target.value)
        }} />

      {/* 英文名称 */}
      <TextInput
        labelWidth={120}
        value={`${form.enname || ''}`}
        fullWidth
        label="英文名称："
        required
        placeholder="请填写英文名称，如“Gui yang”"
        onChange={(event: any) => {
          setFormItem('enname', event.target.value)
        }} />

      {/* shortName */}
      <TextInput
        labelWidth={120}
        value={`${form.shortName || ''}`}
        fullWidth
        label="短名称："
        required
        placeholder="请填写短名称，如“黔”"
        onChange={(event: any) => {
          setFormItem('shortName', event.target.value)
        }} />

      {/* 区域级别 */}
      <RadioInput
        labelWidth={120}
        value={form.level || '2'}
        options={[
          { label: '国别', value: "0" },
          { label: '省/直辖市/自治区', value: "1" },
          { label: '城市', value: "2" },
          { label: '区/县', value: "3" },
          { label: '路', value: "4" },
          { label: '号', value: "5" }
        ]}
        label="区域级别："
        onChange={(e: any, value: any) => {
          setFormItem('level', value)
        }} />

      {/* shortName */}
      <TextInput
        labelWidth={120}
        value={`${form.tag || ''}`}
        fullWidth
        label="标签："
        required
        placeholder=""
        onChange={(event: any) => {
          setFormItem('tag', event.target.value)
        }} />

      {/* 状态 */}
      <RadioInput
        labelWidth={120}
        value={form.status}
        options={[
          { label: translate('off'), value: "0" },
          { label: translate('on'), value: "1" }
        ]}
        label="状态："
        onChange={(e: any, value: any) => {
          setFormItem('status', value)
        }} />


      {/* 排序 */}
      <TextInput
        labelWidth={120}
        value={`${form.sorter || 0}`}
        inputWidth={60}
        label="排序："
        type="number"
        onChange={(event: any) => {
          setFormItem('sorter', event.target.value)
        }} />

      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          }
        }} />
    </form>
  </Box>)


  return (<Box>

    {renderPageTitle}
    <Divider />
    <Box style={{
      flex: 1
    }}>
      {renderPageForm}
    </Box>

    <LoadingDialog open={loading.open} text={loading.text} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/common/area`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`/common/area/edit?id=${createTId()}&parentId=${form.parentId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        history.replace(`/common/area/edit?id=${form.id}`)
      }}
  />
  </Box>
  )
}
export default withRouter(EditPage)