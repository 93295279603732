import React from "react"

import classNames from "classnames"
import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import { SectionButton } from "./types"
import ButtonInput from "./ButtonInput"

import { useItemMethods } from './components/Util';

type Props = {
  data?: SectionButton[],
  onChange?: (data: SectionButton[]) => void
}

export default (props: Props) => {
  const { data = [], onChange } = props
  const classes = useUIInputsStyles()

  const {
    onItemAdd,
    onItemSave,
    onItemRemove,
    sortUp,
    sortDown
  } = useItemMethods({
    data,
    initialData: {
      text: ""
    },
    onChange: (data: any) => {
      if (onChange) {
        onChange(data)
      }
    }
  });

  return <Box className={classes.list}>
    <Box className={classNames(classes.listToolbar, classes.light)}>
      <Box className={classes.listToolbarTitle}> <IconList />按钮列表</Box>
      <Box className={classes.grow} />
      <Button color="primary" onClick={()=>onItemAdd()}>
        <IconAdd />添加按钮
        </Button>
    </Box>
    {data.map((button: any, index: number) => <ButtonInput
      key={button.id || index}
      button={button}
      onSave={onItemSave}
      onRemove={onItemRemove}
      showUp={index > 0}
      showDown={index < data.length - 1}
      sortUp={() => {
        sortUp(index)
      }}
      sortDown={() => {
        sortDown(index)
      }} />)}

  </Box>
}