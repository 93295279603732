
import React, { useState, useEffect } from 'react'

import clsx from "clsx"
import { attachPrefix } from '../../../config'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import Thumb from './Thumb'
import Thumbs from './Thumbs'
import LoadingBox from '../LoadingBox'
import UploadDialog from './UploadDialog'

import { useLocale } from '../../../config/Locale'

import { useAttach } from '../../../provides/common/AttachProvide'
import * as commonTypes from '../../../types/common'
import * as UploadType from './UploadType'

import { isFunction } from '../../../lib/util'

import Icon from '../../Icon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    thumbs: {
      display: 'flex',
      flexDirection: 'column'
    },
    thumbImage: {
      position: 'relative',
      width: 100,
      height: 100,
    },
    small:{
      width: 48,
      height: 48,
    },
    thumbButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      textAlign: 'center',
      verticalAlign: 'middile'
    },
    button: {
      float: 'left',
      margin: theme.spacing(1, 1, 1, 0),
      alignItems: 'center',
      display: 'flex'
    },
    buttonIcon: {
      margin: '0 5px 0 0',
    },
    thumbTool: {
      display: 'flex',
      position: 'absolute',
      right: 2,
      top: 2
    },
  }))
/**
 * 上传组件接收参数
 */
export type IUploaderResult = {
  attaches?: commonTypes.Attach[],
  attach?: commonTypes.Attach,
  url?: string,
  urls?: string[],
}
export type UploaderProps = {
  deleteButton?: boolean,// 单图删除按钮
  type: string,// 上传类型，image:单图，images:多图，file:单文件，files多文件
  onChange: (IUploaderResult: any) => void,// 返回附件数组或附件对象,如果是直接输入地址，直接返回url
  cancel?: () => void,//直接关闭，会触发取消方法
  loaded?: boolean,// 初始化状态
  onLoaded?: () => void,// 初始化完成
  value?: any,//已存地址 | 已存地址列表
  defaultAttach?: commonTypes.Attach,//已存附件
  defaultAttaches?: commonTypes.Attach[],//已存附件列表
  size?:"default"|"small",//图片上传尺寸
}
/**
 * 支持上传单个文件，单张图片，多个文件，多张图片
 * 上传组件根节点
 * 主要功能在对话框实现
 * @param props 接收编辑页面参数，返回单个附件对象或附件列表
 */
const Uploader = (props: UploaderProps) => {
  const { getAttachGroupList, getAttachListByUrls } = useAttach()
  const { translate } = useLocale()

  React.useEffect(() => {
    // 提前载入分组列表，其他组件使用
    getAttachGroupList({
      success: (message: string) => {
      },
      failure: (message: string) => {
      }
    })
    return () => { };
  }, []);

  const { type, onChange, cancel, loaded, onLoaded, value, defaultAttach, defaultAttaches, deleteButton,size } = props
  const classes = useStyles()
  // 上传类型
  const mode = UploadType.getUploadMode(type)

  const [open, setOpen] = useState(false)
  // 显示已上传的附件
  const [attach, setAttach] = useState(defaultAttach as any)
  const [url, setUrl] = useState('' as any)
  const [attaches, setAttaches] = useState(defaultAttaches as any)
  const [urls, setUrls] = useState([] as string[])

  // 加载初始化附件列表
  useEffect(() => {
    if (!loaded) {
      if (onLoaded && isFunction(onLoaded)) {
        onLoaded()
      }
      loadValues()
    }
  })
  const loadValues = () => {
    let urls: string[] = []
    if (UploadType.UploadModes.SINGLE === mode) {
      // 单个上传
      if (value) {
        urls.push(value)
      }
      let url = value
      if (value &&
        !(value.startsWith('http://') || value.startsWith('https://'))) {
        url = attachPrefix + value
      }
      setUrl(url || '')
      setAttach(null)
    } else if (value) {
      urls = value
    }
    setAttaches([])
    // 查询出附件列表
    if (urls.length > 0) {
      getAttachListByUrls({
        urls,
        success: (list: any) => {
          setAttaches(list)
        },
        failure: (message: any) => {
        }
      })
    }

  }
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  const remove = (index: number, finished: Function) => {
    const newAttaches = attaches;
    newAttaches.splice(index, 1)
    setAttaches(attaches)
    onChange({
      attaches// 返回当前state的列表
    })
    finished()
  }

  const clear = () => {
    setAttaches([])
    setUrls([])
    setAttach(null)
    setUrl('')
    onChange({
      attach: null,
      url: ''
    })
  }

  const filesRender = (<Box className={classes.thumbs}>
    {/* 上传多文件或多图片 */}
    <Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => {
          // 上传文件
          setOpen(true)
        }}>

        <Icon
          className={classes.buttonIcon}
          name={type === 'images' ? "UploadImage" : "Upload"}
          width={16}
          height={16}
          color="#fff" />
        {translate(type === 'images' ? 'upload.uploadImage' : 'upload.uploadFile')}
      </Button>
      <Button
        variant="outlined"
        color="default"
        disabled={!(attaches && attaches.length > 0)}
        className={classes.button}
        onClick={clear}
        style={{ color: !(attaches && attaches.length > 0) ? "#aaa" : '#333' }}
      >
        <Icon
          className={classes.buttonIcon}
          name="RemoveBatch"
          width={16}
          height={16}
          color={!(attaches && attaches.length > 0) ? "#aaa" : '#333'} />
        {translate('clear')}
      </Button>
    </Box>
    <Thumbs {...{
      type,
      attaches,
      onRemove: (props: any) => {
        setLoading({
          open: true,
          text: translate('upload.removingItem')//正在移除条目...
        })
        const { index } = props
        remove(index, () => {
          // 关闭加载条
          setLoading(initialLoading)
        })
      }
    }} />
  </Box >)


  const fileRender = (<Box className={classes.thumbs}>
    <Box>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => {
          // 上传文件
          setOpen(true)
        }}
      >
        <Icon className={classes.buttonIcon} name="Upload" color="#fff" />
        {translate('upload.uploadFile')}
      </Button>
      <Button
        variant="outlined"
        color="default"
        disabled={!(attach ? attachPrefix + attach.url : url)}
        className={classes.button}
        onClick={clear}
        style={{ color: !(attach ? attachPrefix + attach.url : url) ? "#aaa" : '#333' }}
      >
        <Icon
          className={classes.buttonIcon}
          name="Remove"
          width={16}
          height={16}
          color={!(attach ? attachPrefix + attach.url : url) ? "#aaa" : '#333'} />
        {translate('upload.removeFile')}
      </Button>
    </Box>
    <Box>
      {attach ? attachPrefix + attach.url : url}
    </Box>
  </Box>)

  const imageRender = (<Box className={clsx(classes.thumbImage,{
    [classes.small]:size === 'small'
  })}>
    <Thumb src={attach ? attachPrefix + attach.url : url} {...{
      width:size === 'small'?48:100,
      height:size === 'small'?48:100
    }} />
    <IconButton
      className={classes.thumbButton}
      aria-haspopup="true"
      color="primary"
      onClick={() => {
        setOpen(true)
      }}
    >
      <AddIcon />
    </IconButton>
    {deleteButton && (url || attach) && <Box className={classes.thumbTool} >
      <IconButton
        size="small"
        color="default"
        onClick={() =>clear()}>
        <Tooltip
          aria-label="add"
          title={translate('remove')}>
          <IconDelete color="error" />
        </Tooltip>
      </IconButton>
    </Box>}
  </Box>)

  const render = () => {
    if (type === 'image') {
      return imageRender
    } else if (mode === UploadType.UploadModes.SINGLE) {
      // 单个上传
      return fileRender
    }
    return filesRender
  }

  return (<Box className={classes.root}>
    {render()}
    <UploadDialog
      {...{
        type,
        open,
        attaches,
        url: value,
        success: (props: IUploaderResult) => {
          if (mode === UploadType.UploadModes.SINGLE) {
            // 单文件/图片上传
            let att = props.attach
            if (props.attach) {
              att = props.attach
            } else if (props.attaches) {
              att = props.attaches[0]
            }
            // 预览
            setAttach(att)
            setUrl(props.url);
            // 触发调用页面事件
            onChange({
              attach: att,
              url: props.url
            })
          } else {
            // 追加附件列表
            let newAttaches = attaches
            let newUrls = urls || []
            if (props.attaches) {
              newAttaches = newAttaches.concat(props.attaches)
            }
            if (props.urls) {
              newUrls = newUrls.concat(props.urls)
            }
            setAttaches(newAttaches);
            setUrls(newUrls);
            onChange({
              attaches: newAttaches,
              urls: newUrls
            })
          }
          setOpen(false)
        },
        onClose: () => {
          setOpen(false)
          if (cancel) {
            cancel()
          }
        }
      }} />
    <LoadingBox {...loading} />
  </Box>
  )
}
export default Uploader