import { blockRenderMap } from './renders/blocks'
import Link from './plugins/Link';
import Alignment from './plugins/Alignment';
import Media from './plugins/Media';
import ColorPicker from './plugins/ColorPicker';
import FontSize from './plugins/FontSize';
import InlineStyle from './plugins/InlineStyle';
import Block from './plugins/Block';
import Heading from './plugins/Heading'
import List from './plugins/List'
import Clear from './plugins/Clear'
import Screen from './plugins/Screen'
import Preview from './plugins/Preview'
import Select from './plugins/Select'
import History from './plugins/History'
import Emoji from './plugins/Emoji'

import { ToolbarItemProps } from './types'
// 图标
const ICONS: any = {
  'bold': 'Bold',
  'italic': 'Italic',
  'underline': 'Underline',
  'code': 'Code',
  'fontColor': 'FontColor',
  'fontSize': 'FontSize',
  'fontFamily': 'FontFamily',
  'backgroundColor': 'BackgroundColor',
  'heading': 'Heading',
  'heading-one': 'LooksOne',
  'heading-two': 'LooksTwo',
  'textAlignLeft': 'TextAlignLeft',
  'textAlignCenter': 'TextAlignCenter',
  'textAlignRight': 'TextAlignRight',
  'blockquote': 'BlockQuote',
  'strikethrough': 'Strikethrough',
  'ordered-list-item': 'OrderList',
  'unordered-list-item': 'UnOrderList',
  'indent': 'Indent',
  'outdent': 'Outdent',
  'image': 'Image',
  'images': 'Images',
  'audio': 'Audio',
  'audios': 'Audio',
  'video': 'Video',
  'videos': 'Videos',
  'clear': 'Clear',
  'undo': 'Undo',
  'redo': 'Redo',
  'emoji': 'Emoji'
}

const ToolbarItem = (props: ToolbarItemProps) => {
  const { type, icon, ...rest } = props
  if (type === 'link') {
    return Link({ ...rest });
  } if (type === 'screen') {
    return Screen({ ...rest });
  } if (type === 'preview') {
    return Preview({ ...rest });
  } else if (type === 'alignment') {
    return Alignment({ ...rest });
  } else if (type === 'media') {
    return Media({
      ...{
        type,
        ...rest
      }
    });
  } else if (type === 'heading') {
    return Heading({
      ...{
        ...rest
      }
    });
  } else if (type === 'list') {
    return List({
      ...{
        ...rest
      }
    });
  } else if (type === 'fontSize') {
    return FontSize({
      ...{
        type,
        icon: icon || ICONS[type],
        ...rest
      }
    });
  } else if (type === 'fontColor'
    || type === 'backgroundColor') {
    return ColorPicker({
      type,
      icon: icon || ICONS[type],
      ...rest
    });
  } else if (type === 'fontFamily'
    || type === 'lineHeight'
    || type === 'justifyContent'
    || type === 'alignItems'
    || type === 'overflow'
    || type === 'position'
    || type === 'whiteSpace'
    || type === 'cursor') {
    // 下拉
    return Select({
      type,
      ...rest
    });
  } else if (type === 'emoji') {
    return Emoji({
      type,
      icon: icon || ICONS[type],
      ...rest
    });
  }else if (type === 'clear') {
    return Clear({
      type,
      icon: icon || ICONS[type],
      ...rest
    });
  } else if (type === 'history') {
    return History({
      type,
      icon: icon || ICONS[type],
      ...rest
    });
  } else if (blockRenderMap.get(type)) {
    // 定义的块
    return Block({
      ...{
        type,
        icon: icon || ICONS[type],
        ...rest
      }
    });
  }
  // 默认为行内样式
  return InlineStyle({
    ...{
      type,
      icon: icon || ICONS[type],
      ...rest
    }
  });
}
export default ToolbarItem