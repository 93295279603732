import { Action } from '../../actions';
import * as Actions from '../../actions/shopping/spec';
import * as State from '../../state/shopping';

export const initialState: State.Spec = {
};

export const reducer = (state: State.Spec = initialState, action: Action): State.Spec => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_LIST_BY_CAT_SUCCESS:
            return {
                ...state,
                list: action.payload.list,
                pagination: action.payload.pagination
            };

        case Actions.FETCH_LIST_BY_CAT_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                spec: action.payload.spec
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        default: return state;
    }
};