import { AxiosRequestConfig } from "axios";
import * as userTypes from "../../../types/user";
import { API } from '../../../config'

// Action Types
export type Action ={
      // 点赞列表
      type: "LIKE_LIKETOME";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
    | {
      // 当前对象是否点赞
      type: "LIKE_LIKED";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
    | {
    // 点赞或取消点赞
    type: "LIKE_TOGGLE";
    payload: { client: "default"; request: AxiosRequestConfig };
  };

  
export const LIKE_LIKETOME = "LIKE_LIKETOME";
export const likeToMe = (params:any): Action => ({
  type: LIKE_LIKETOME,
  payload: {
    client: "default",
    request: { 
      url: `${API}/user/like/liketome`,
      method: "GET",
      params
    }
  }
});

export const LIKED = "LIKE_LIKED";
export const liked = (params:any): Action => ({
  type: LIKED,
  payload: {
    client: "default",
    request: { 
      url: `${API}/user/like/liked`,
      method: "GET",
      params
    }
  }
});



export const TOGGLE = "LIKE_TOGGLE";
export const toggle = (log: userTypes.UserLog): Action => ({
  type: TOGGLE,
  payload: {
    client: "default",
    request: {
      url: `${API}user/like/toggle`,
      method: "POST",
      data: {
        log
      }
    }
  }
});

