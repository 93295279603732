import React,{useState} from 'react';
import Box from '@material-ui/core/Box';

import {BodyTableCell, TableRow, TableRowToolbar } from '../../../components/ui/table'
import { useLocale } from '../../../config/Locale' 
import { attachPrefix } from '../../../config';

import TextInput from '../../../components/ui/form/TextInput'
import ImageInput from '../../../components/ui/form/ImageInput'
import RadioInput from '../../../components/ui/form/RadioInput'

import { useListPageStyles } from '../../../assets/jss/pages'

type Props = { 
      index:number,
       handleClick:(event: any, index: number)=>void,
       onCancel:()=>void,
       onSave:(row:any)=>void,
       onDelete:()=>void,
       onEdit:()=>void,
       row:any
}

export default (props:Props)=> {
     const classes = useListPageStyles()
     const {
       index,
       handleClick,
       onCancel,
       onSave,
       onDelete,
       onEdit,
       row
    } = props
    const [editRow,setEditRow] = useState<any>(row)
    const [imageLoaded, setImageLoaded] = useState<boolean>(false)
    const setEditRowItem = (key:string,value:any)=>{
      editRow[key] = value
      setEditRow(Object.assign({},editRow))
    }
     const { translate } = useLocale()
     
    return <TableRow  onClick={event => handleClick(event, index)}>
                    <BodyTableCell align="left">
                      {row.editing?
                                    <ImageInput
                                        labelWidth={0}
                                        value={editRow.logo}
                                        size="small"
                                        onChange={(url: string) => {
                                                setEditRowItem("logo",url)
                                        }}
                                        loaded={imageLoaded}
                                        onLoaded={() => { 
                                            setImageLoaded(true) 
                                        }}
                                    />
                      :<img src={`${attachPrefix}${row.logo}`} width={32} height={32} />}

                    </BodyTableCell>
                    <BodyTableCell align="left">
                      {row.editing?
                                <TextInput
                                    value={editRow.sitename}
                                    width={0}
                                    onChange={(event: any) => {
                                        setEditRowItem("sitename",event.target.value) 
                                    }} />:
                      row.sitename
                      }
                    </BodyTableCell>
                    <BodyTableCell align="left">
                      {row.editing?
                                <TextInput
                                    value={editRow.url}
                                    width={0}
                                    onChange={(event: any) => {
                                        setEditRowItem("url",event.target.value) 
                                    }} />:
                      row.url
                      }</BodyTableCell>
                    <BodyTableCell align="left"> 
                     {row.editing?
                                <TextInput
                                    value={editRow.sorter}
                                    type="number"
                                    width={0}
                                    onChange={(event: any) => {
                                         setEditRowItem("sorter",event.target.value) 
                                    }} />:
                      row.sorter
                      }
                    </BodyTableCell>
                    <BodyTableCell align="left">
                     {row.editing?
                      <RadioInput
                        labelWidth={0}
                        value={editRow.status}
                        options={[
                          { label: "未启用", value: "0" },
                          { label: "启用", value: "1" }
                        ]}
                        label=""
                        margin="0"
                        onChange={(event: any, value: any) => {
                          setEditRowItem("status",value) 
                        }} />:<>
                        {row.status === "0" && <span style={{color:"gray"}}>未启用</span>}
                        {row.status === "1" && <span style={{color:"green"}}>启用中</span>}
                        </>
                      }
                    </BodyTableCell>
                    <BodyTableCell align="right" className={classes.rowToolbar}>
                    {
                      row.editing ? 
                      <TableRowToolbar
                        {...{
                          saveLabel: translate('common.friendUrl.save'),
                          onCancel,
                          onSave:()=>{
                            onSave(editRow)
                          }
                        }} />:

                      <TableRowToolbar
                        {...{
                          editLabel: translate('common.friendUrl.edit'),
                          deleteLabel: translate('common.friendUrl.remove'),
                          onEdit,
                          onDelete
                        }} />
                    }
                    </BodyTableCell>
                  </TableRow>
}