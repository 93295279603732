import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2519"
    width={width} height={height}>
    <path fill={color} d="M256 512H170.666667c0 185.002667 156.288 341.333333 341.333333 341.333333s341.333333-156.330667 341.333333-341.333333-156.288-341.333333-341.333333-341.333333V85.333333L342.4 213.333333 512 341.333333V256c138.752 0 256 117.248 256 256s-117.248 256-256 256-256-117.248-256-256z" p-id="2520"></path>
    <path fill={color} d="M640 512c0-71.210667-56.704-128-128-128s-128 56.789333-128 128 56.704 128 128 128 128-56.789333 128-128z" p-id="2521"></path></svg>
    )