import React, { useState, useEffect } from 'react'

import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withRouter } from "react-router-dom";

// 逻辑
import { useLocale } from '../../../config/Locale'
import { useProduct } from '../../../provides/shopping'
import { useConfig } from '../../../provides/app'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { SubmitedDialog } from '../../../components/ui/dialog'
// 上传组件
import { getAccept } from '../../../components/ui/upload/Accepts'
import { FormTabPanel, FormTab, FormTabs } from '../../../components/ui/tabs/FormTabs';
 
// 表单组件 
import BrandInput from '../../../components/shopping/BrandInput'
import FormField from '../../../components/ui/form/FormField'
import TextInput from '../../../components/ui/form/TextInput'
import ImageInput from '../../../components/ui/form/ImageInput'
import ImagesInput from '../../../components/ui/form/ImagesInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import VideoInput from '../../../components/ui/form/VideoInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import CatInput from '../../../components/shopping/CatInput';
import Editor from '../../../components/ui/editor'
import { createId } from '../../../lib/util'
import ProductSpec from '../../../components/shopping/spec/ProductSpec'
import ProductAttr from '../../../components/shopping/attr/ProductAttr'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      background: `#fff`,
      marginTop: 10,
      height: 38,
      borderBottom: '1px solid #dedede'
    },
    contentContainer: {
      flex: 1,
    },
    margin: {
      margin: theme.spacing(1, 0)
    },
    thumbImage: {
      position: 'relative',
      width: 100,
      height: 100,
    },
    thumbButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      textAlign: 'center',
      verticalAlign: 'middile'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }))


const EditPage = (props: any) => {
  const { history } = props

  const { translate } = useLocale()
  const { get, save } = useProduct()
  const { closePage } = useConfig()

  const classes = useStyles()
  const theme = useTheme()

  const [loaded, setLoaded] = useState<boolean>(false)

  const [specLoaded, setSpecLoaded] = useState<boolean>(false)
  const [attrLoaded, setAttrLoaded] = useState<boolean>(false)
  const [brandLoaded, setBrandLoaded] = useState<boolean>(false)

  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const [imagesLoaded, setImagesLoaded] = useState<boolean>(false)
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false)
  const resetState = () => {
    setLoaded(false)// 富文本
    setImageLoaded(false)// 图片
    setImagesLoaded(false)// 图片列表
    setVideoLoaded(false)// 视频
    setSpecLoaded(false)// 规格
    setAttrLoaded(false)// 属性
    setBrandLoaded(false)// 属性
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }
  // 设置表单元素
  const [form, setForm] = useState<any>({})
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载商品...'
    })
    get({
      id: form.id,
      success: (product: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        const newForm = product
        setForm(Object.assign({}, newForm))
        // 重置表单元素加载状态
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        reloadParams()
        resetState()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id"),
      catId: params.get("catId")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id !== form.id) {
      resetForm()
      setFormItem('id', id)
      setFormItem('catId', params.get("catId"))
      loadData()
    }
    return () => {
      // setForm({id:createId()})
    }
  });

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        product: form
      },
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route.path) {
        history.push(route.path)
      }
    })
  }

  function a11yProps(index: number, label: string) {
    return {
      index,
      label,
      ariaprefix: "product-edit",
    };
  }


  return (<Box>
    <form onSubmit={onSubmit}>
      <Box className={classes.contentHeader}>
        <FormTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}>
          <FormTab {...a11yProps(0, '基本信息')} />
          <FormTab {...a11yProps(1, '商品详情')} />
          <FormTab {...a11yProps(2, '规格及属性')} />
        </FormTabs>
      </Box>
      <Box className={classes.contentContainer}>
        <FormTabPanel
          ariaprefix="product-edit"
          value={value}
          index={0}
          dir={theme.direction}>
          <Box className={classes.margin}>
            {/* 分类 */}
            <CatInput
              label="商品分类："
              labelWidth={120}
              value={form.catId}
              onChange={(item: any) => {
                setFormItem('catId', item.id)
                setSpecLoaded(false)
                setAttrLoaded(false)
              }} />

          <FormField
            labelWidth={120}
            label="品牌：">
            <BrandInput
              value={form.brand}
              loaded={brandLoaded}
              onLoaded={() => { setBrandLoaded(true) }}
              onChange={(brand: any) => {
                setFormItem('brand', brand)
              }}
            />

          </FormField>
            {/* 名称 */}
            <TextInput 
              labelWidth={120}
              value={`${form.name || ''}`}
              fullWidth
              label="商品名称："
              required
              placeholder="请输入商品名称"
              onChange={(event: any) => {
                setFormItem('name', event.target.value)
              }} />

            {/* 标题 */}
            <TextInput
              labelWidth={120}
              value={`${form.title || ''}`}
              fullWidth
              label="标题："
              required
              placeholder="请输入商品标题，不可重复"
              onChange={(event: any) => {
                setFormItem('title', event.target.value)
              }} />

            {/* 副标题 */}
            <TextInput
              labelWidth={120}
              value={`${form.subtitle || ''}`}
              fullWidth
              label="副标题："
              placeholder="商品内容概述，作为description输出"
              onChange={(event: any) => {
                setFormItem('subtitle', event.target.value)
              }} />
            {/* 关键字： */}
            <TextInput
              labelWidth={120}
              value={`${form.keywords || ''}`}
              fullWidth
              label="关键字："
              placeholder="用英文逗号或空格分隔关键字，作为Keywords输出"
              onChange={(event: any) => {
                setFormItem('keywords', event.target.value)
              }} />

            {/* 主图 */}
            <ImageInput
              labelWidth={120}
              value={form.image}
              label="置顶主图："
              onChange={(url: string) => {
                setFormItem('image', url)
              }}
              loaded={imageLoaded}
              onLoaded={() => { setImageLoaded(true) }} />

            <ImagesInput
              labelWidth={120}
              value={form.images}
              label="其他主图："
              onChange={(urls: string[]) => {
                setFormItem('images', urls)
              }}
              loaded={imagesLoaded}
              onLoaded={() => { setImagesLoaded(true) }} />

            {/* 视频 */}
            <VideoInput
              labelWidth={120}
              value={form.video}
              label="视频："
              tips={`文件后缀名包含“${getAccept('video')}”。`}
              onChange={(url: string) => {
                setFormItem('video', url)
              }}
              loaded={videoLoaded}
              onLoaded={() => { setVideoLoaded(true) }} />
            {/* 排序 */}
            <TextInput
              labelWidth={120}
              value={`${form.sorter}`}
              inputWidth={60}
              label="排序："
              type="number"
              onChange={(event: any) => {
                setFormItem('sorter', event.target.value)
              }} />
            {/* 状态 */}
            <RadioInput
              labelWidth={120}
              value={form.status}
              options={[
                { label: "未上架", value: "0" },
                { label: "上架", value: "1" }
              ]}
              label="状态："
              onChange={(e: any, value: any) => {
                setFormItem('status', value)
              }} />
          </Box>
        </FormTabPanel>

        <FormTabPanel
          ariaprefix="product-edit"
          value={value} index={1} dir={theme.direction}>
          {/* 商品详情 */}
          <Editor
            value={form.detail}
            loaded={loaded}
            onLoaded={() => {
              setLoaded(true)
            }}
            onChange={(value: string) => {
              setFormItem('detail', value)
            }} />
          {/*<div dangerouslySetInnerHTML={createMarkup(form.detail)} style={{ padding: 10 }} />*/}

        </FormTabPanel>

        <FormTabPanel
          ariaprefix="product-edit"
          value={value} index={2} dir={theme.direction}>

          {/* 商品属性 */}
          <FormField
            labelWidth={120}
            label="商品属性：">
            <ProductAttr
              catId={form.catId}
              productAttrs={form.attrs || []}
              loaded={attrLoaded}
              onLoaded={() => { setAttrLoaded(true) }}
              onChange={(attrs: any) => {
                setFormItem('attrs', attrs)
              }}
            />

          </FormField>
          {/* 规格 */}
          <RadioInput
            labelWidth={120}
            value={form.specMode}
            options={[
              { label: "统一规格", value: "0" },
              { label: "多规格", value: "1" }
            ]}
            label="规格类型："
            onChange={(e: any, value: any) => {
              setFormItem('specMode', value)
            }} />
          {
            form.specMode === '0' && (<>
              <TextInput
                labelWidth={120}
                value={`${form.price}`}
                inputWidth={120}
                label="售价："
                type="number"
                onChange={(event: any) => {
                  setFormItem('price', event.target.value)
                }} />
              <TextInput
                labelWidth={120}
                value={`${form.oprice}`}
                inputWidth={120}
                label="原价："
                type="number"
                onChange={(event: any) => {
                  setFormItem('oprice', event.target.value)
                }} />

              <TextInput
                labelWidth={120}
                value={`${form.inprice}`}
                inputWidth={120}
                label="进价："
                type="number"
                onChange={(event: any) => {
                  setFormItem('inprice', event.target.value)
                }} />
              {/* 库存 */}
              <TextInput
                labelWidth={120}
                value={`${form.stock}`}
                inputWidth={120}
                label="库存："
                type="number"
                onChange={(event: any) => {
                  setFormItem('stock', event.target.value)
                }} />
            </>)
          }
          {
            form.specMode === '1' &&
            <ProductSpec
              labelWidth={120}
              catId={form.catId}
              productSpecs={form.specs || []}
              loaded={specLoaded}
              onLoaded={() => { setSpecLoaded(true) }}
              onChange={(specs: any) => {
                setFormItem('specs', specs)
              }}
            />
          }
        </FormTabPanel>
      </Box>
      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          },
          onReset: () => {
            loadData()
          }
        }} />
    </form>

    <LoadingBox {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/shopping/product?catId=${form.catId}`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`?id=${createId()}&catId=${form.catId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        // loadData()
      }}
    />

  </Box>
  )
}
export default withRouter(EditPage)