import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
    p-id="3066" width={width} height={height}>
    <path fill={color} d="M452.275757 649.898697 452.275757 103.626125c0-13.311167-13.765515-22.164812-25.87839-16.645099L89.288582 240.600777c-6.52153 2.971681-10.707876 9.478885-10.707876 16.645099l0 667.177222c0 15.412015 17.892509 23.916712 29.843702 14.184047l337.109808-274.5244C449.800379 660.609643 452.275757 655.399991 452.275757 649.898697zM278.775214 510.705518l-93.855598 63.452147c-4.89959 3.312442-11.523451-0.096191-11.67797-6.007831l-5.039783-193.497992c-0.076748-2.932796 1.565658-5.63944 4.202719-6.924713l94.310969-45.980217c4.897543-2.38635 10.623965 1.088798 10.766205 6.534833l4.585435 176.025039C282.132682 506.86198 280.891412 509.273912 278.775214 510.705518zM398.74661 439.320701l-84.827991 56.172347c-3.965312 2.625804-9.269108-0.1361-9.392928-4.889357l-4.683672-179.818435c-0.061398-2.368954 1.26276-4.554736 3.391237-5.595438l85.205591-41.687448c3.960195-1.938142 8.596795 0.871857 8.711406 5.28026l4.307096 165.334559C401.51056 436.202687 400.487254 438.169481 398.74661 439.320701z" p-id="3067"></path><path d="M934.865937 240.307088 506.213203 81.736583c-10.434653-3.859911-21.936615 2.940982-21.936615 12.969383l0 555.266409c0 4.438079 2.363837 8.616238 6.375198 11.267625l428.653757 283.373952c10.447956 6.90834 25.304316 0.291642 25.304316-11.267625L944.609859 253.278517C944.609859 247.606331 940.767344 242.490823 934.865937 240.307088z" p-id="3068"></path></svg>
    )