import React from 'react';
import Box from '@material-ui/core/Box';

import {TabPanel,StyleTabs,StyleTab,a11yProps} from '../../components/ui/tabs/StyleTabs'

import {ChatUser } from "./types"
import { useChatStyles } from '../../assets/jss/components'
import Registers from "./Registers"
import UserList from "./UserList"
  
type Props = {
  onSelect:(user:ChatUser)=>void,
  closed?:boolean,
  client:any
}

export default (props:Props) => {
    const classes = useChatStyles()
    const {onSelect,client} = props 
    const [tabIndex,setTabIndex] = React.useState<number>(0)
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number)=>{
        setTabIndex(newValue)
    }
    return (
       <Box>
       <Box  className={classes.sideHeader}>
          <StyleTabs 
          value={tabIndex}
          onChange={handleTabChange}
          className={classes.tabs}
          > 
              <StyleTab
              selected={tabIndex === 0}
              index={0}
              ariaprefix="user-online"
              label="聊天"
              defaultClassName={classes.tab}
              selectedClassName={classes.tabSelected}
              />
              <StyleTab
              selected={tabIndex === 1}
              index={1}
              ariaprefix="user-all"
              label="所有用户"
              defaultClassName={classes.tab}
              selectedClassName={classes.tabSelected}
              />
          </StyleTabs>
        </Box>
        <TabPanel  value={tabIndex}
              index={0} 
              ariaprefix="user-online">
              <Registers onSelect={onSelect}  client={client}/>
        </TabPanel>
        <TabPanel  
              value={tabIndex}
              index={1} 
              ariaprefix="user-all">
              <UserList onSelect={onSelect}  client={client}/>
        </TabPanel>
       
      </Box>
    )
}