import React from "react"

import Box from '@material-ui/core/Box';
import ColorInput from '../../../components/ui/form/ColorInput'
import TextInput from '../../../components/ui/form/TextInput'
import Icon from "../../../components/Icon"

import { PageSetting, Side } from "../../../types/ui"
import { ConfirmDialog } from "../../../components/ui/dialog"
import { useUIPageStyles } from "../../../assets/jss/pages"

import { FormTabPanel, FormTab, FormTabs } from '../../../components/ui/tabs/FormTabs';
import EditSide from "./EditSide"

const a11yProps = (index: number, label: string) => {
    return {
        index,
        label,
        ariaprefix: "page-edit-container",
    };
}

type Props = {
    setting: PageSetting,
    onChange: (setting: PageSetting) => void,
    cancel: () => void
}
export default (props: Props) => {
    const classes = useUIPageStyles()
    const { setting, onChange, cancel } = props

    const [form, setForm] = React.useState<any>(setting)
    const setFormItem = (key: "options" | "sideLeft" | "sideRight", value: any) => {
        form[key] = value
        setForm(Object.assign({}, form))
    }

    const [options, setOptions] = React.useState<any>(setting.options || {})
    const setOption = (key: string, value: any) => {
        const newForm = options
        newForm[key] = value;
        setOptions(Object.assign({}, newForm))
        setFormItem("options", newForm)
    }
    // 标签页 
    const [value, setValue] = React.useState(0);
    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    const labelWidth = 120

    const base = <Box className={classes.dialog}>
        <ColorInput
            labelWidth={labelWidth}
            value={`${options.backgroundColor || ''}`}
            inputWidth={80}
            label="背景颜色："
            onChange={(color: string) => {
                setOption('backgroundColor', color)
            }} />

        <TextInput
            labelWidth={labelWidth}
            value={`${options.padding || ''}`}
            fullWidth
            label="内边距："
            placeholder="内边距为数值或像素字符串，如8,8px,8px 16px"
            onChange={(event: any) => {
                setOption('padding', event.target.value)
            }} />

        <TextInput
            labelWidth={labelWidth}
            value={`${options.style || ''}`}
            fullWidth
            label="容器样式："
            placeholder={`格式如{"display":"flex","flexDirection":"column","fontColor":'#333'}`}
            onChange={(event: any) => {
                setOption('style', event.target.value)
            }} />


        <TextInput
        labelWidth={labelWidth}
        value={`${options.mainStyle || ''}`}
        fullWidth
        label="主内容样式："
        placeholder={`格式如{"display":"flex","flexDirection":"column","fontColor":'#333'}`}
        onChange={(event: any) => {
            setOption('mainStyle', event.target.value)
        }} />
    </Box>

    const editSideLeft = <EditSide
        side={setting.sideLeft || {}}
        onChange={(side: Side) => setFormItem("sideLeft", side)} />
    const editSideRight = <EditSide
        side={setting.sideRight || {}}
        onChange={(side: Side) => setFormItem("sideRight", side)} />

    const children = <Box>
        <Box className={classes.contentHeader} style={{
            padding: 0
        }}>
            <FormTabs
                variant="scrollable"
                value={value}
                onChange={handleChange}>
                <FormTab {...a11yProps(0, '容器基本配置')} />
                <FormTab {...a11yProps(1, '左侧边栏')} />
                <FormTab {...a11yProps(2, '右侧边栏')} />
            </FormTabs>
        </Box>
        <Box className={classes.contentContainer}>
            <FormTabPanel
                ariaprefix="page-edit-container"
                value={value}
                index={0}>
                {base}
            </FormTabPanel>
            <FormTabPanel
                ariaprefix="page-edit-container"
                value={value}
                index={1}>
                {editSideLeft}
            </FormTabPanel>

            <FormTabPanel
                ariaprefix="page-edit-container"
                value={value}
                index={2}>
                {editSideRight}
            </FormTabPanel>
        </Box>
    </Box>

    return <ConfirmDialog  {...{
        open: true,
        fullWidth: true,
        maxWidth: "md",
        title: "容器Container",
        ok: () => onChange(form),
        cancel: () => {
            cancel()
        },
        footer: <Box className={classes.tips}>
            <Icon name="Alert" className={classes.icon} />
            可以不用填写以上元素，创建空容器，浏览器自动适配样式.<br />
            PC浏览器将居中显示.
           </Box>
    }}>
        {children}
    </ConfirmDialog>
}