import React from 'react'
import * as styles from '../renders/styles'

export const renderLink = (url: string, children: any) => {
    return (
        <a href={url} style={styles.link}>
            {children}
        </a>
    )
}
export default (props: any) => {
    const entity = props.contentState.getEntity(props.entityKey)
    const { url } = entity.getData();
    return renderLink(url, props.children)
};