
import { shallowEqual, useSelector } from "react-redux";
import * as State from "../../store/state";

export { useConfig } from './ConfigProvide'
export { useLocale } from './LocaleProvide'
export { useTheme } from './ThemeProvide'

export const useConfigState = () => {
  return useSelector((state: State.Root) => {
    return {
      config:state.app.config,
      localeConfig:state.app.localeConfig,
      theme: state.app.theme
    };
  }, shallowEqual);
};

export const useRoutesState = () => {
  return useSelector((state: State.Root) => {
    return {
      currentRoutes:state.app.currentRoutes
    };
  }, shallowEqual);
};
