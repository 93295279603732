import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
})=>(<svg className={className} viewBox="0 0 1024 1024" version="1.1" 
xmlns="http://www.w3.org/2000/svg" p-id="7125"  width={width} height={height}>
<path fill={color} d="M810.666667 917.333333H213.333333c-59.733333 0-106.666667-46.933333-106.666666-106.666666V213.333333c0-59.733333 46.933333-106.666667 106.666666-106.666666h597.333334c59.733333 0 106.666667 46.933333 106.666666 106.666666v597.333334c0 59.733333-46.933333 106.666667-106.666666 106.666666zM213.333333 149.333333c-34.133333 0-64 29.866667-64 64v597.333334c0 34.133333 29.866667 64 64 64h597.333334c34.133333 0 64-29.866667 64-64V213.333333c0-34.133333-29.866667-64-64-64H213.333333z" p-id="7126"></path>
<path fill={color} d="M682.666667 533.333333H341.333333c-12.8 0-21.333333-8.533333-21.333333-21.333333s8.533333-21.333333 21.333333-21.333333h341.333334c12.8 0 21.333333 8.533333 21.333333 21.333333s-8.533333 21.333333-21.333333 21.333333z" p-id="7127"></path></svg>
)