
import React from 'react';

export default (props:any)=>{
    const {name,...other} = props
    try {
        const Icon = require(`./icons/${name}`).default
        return (<span><Icon {...other}/></span>)
    }catch{
        return <span>{name}</span>
    }
}