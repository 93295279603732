import React from 'react'
export default ({
    width = 16,
    height = 16,
    className = "icon"
})=>(<svg className={className}  viewBox="0 0 1733 1024" version="1.1"
 xmlns="http://www.w3.org/2000/svg" p-id="10470" width={width} height={height}>
 <path d="M824.870387 0c-99.575508 0-193.381943 44.651348-256.005351 117.204427-62.601962-72.553079-156.429842-117.204427-255.962458-117.204427-176.396415 0-312.902578 133.911151-312.902578 306.940486 0 212.061733 193.381943 382.260147 486.382286 644.506392l82.461303 72.553079 82.504196-72.553079c293.000344-262.246245 486.339394-432.444659 486.339394-644.506392C1137.730072 133.932597 1001.22391 0 824.870387 0L824.870387 0 824.870387 0zM604.015641 878.014498l-11.559595 10.337152-23.59101 20.738642-23.505224-20.738642-11.516702-10.337152c-137.943069-123.359536-257.01333-229.990901-335.764411-325.255688-76.456319-92.562545-110.556051-168.375472-110.556051-245.818324 0-60.972038 23.076297-117.140088 64.896724-158.166999 41.670302-40.876786 98.610422-63.416924 160.504653-63.416924 71.459314 0 142.125112 32.405469 189.028329 86.772024l66.912683 77.635869 66.998468-77.635869c46.817432-54.345108 117.526122-86.772024 189.006883-86.772024 61.808446 0 118.812905 22.540138 160.5261 63.416924 41.841873 41.026911 64.875277 97.194961 64.875277 158.166999 0 77.421405-34.142625 153.255779-110.534605 245.818324C860.900294 648.023597 741.894371 754.633516 604.015641 878.014498L604.015641 878.014498 604.015641 878.014498z" p-id="10471"></path>
 <path d="M1212.320556 474.329425l500.129411 0c10.637401 0 19.237396 8.621442 19.237396 19.237396l0 38.496239c0 10.594508-8.621442 19.194504-19.237396 19.194504l-500.129411 0c-10.680294 0-19.237396-8.621442-19.237396-19.194504l0-38.496239C1193.08316 482.92942 1201.661709 474.329425 1212.320556 474.329425L1212.320556 474.329425z" p-id="10472"></path>
 <path d="M1212.320556 897.487804l500.129411 0c10.637401 0 19.237396 8.621442 19.237396 19.237396l0 38.496239c0 10.637401-8.621442 19.237396-19.237396 19.237396l-500.129411 0c-10.680294 0-19.237396-8.621442-19.237396-19.237396l0-38.496239C1193.08316 906.109246 1201.661709 897.487804 1212.320556 897.487804L1212.320556 897.487804z" p-id="10473"></path>
 <path d="M1212.320556 49.584014l500.129411 0c10.637401 0 19.237396 8.621442 19.237396 19.237396l0 38.453346c0 10.637401-8.621442 19.237396-19.237396 19.237396l-500.129411 0c-10.680294 0-19.237396-8.621442-19.237396-19.237396l0-38.453346C1193.08316 58.184009 1201.661709 49.584014 1212.320556 49.584014L1212.320556 49.584014z" p-id="10474"></path></svg>
)