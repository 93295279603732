import React, { useState, useEffect, useCallback } from 'react';

import { RichUtils } from 'draft-js';
import {
    changeDepth,
    getBlockBeforeSelectedBlock,
    getSelectedBlock,
    isListBlock
} from '../utils';
import Icon from './Icon'
import Button from './Button'

export default (props: any) => {
    const {
        onChange,
        editorState,
        title
    } = props

    const [currentBlock, setCurrentBlock] = useState(null as any)
    const query = () => {
        const currentBlock = getSelectedBlock(editorState)
        setCurrentBlock(currentBlock)
    }
    useEffect(query)

    const toggleBlockType = (blockType: any) => {
        const newState = RichUtils.toggleBlockType(
            editorState,
            blockType
        );
        if (newState) {
            onChange(newState);
        }
    };

    const adjustDepth = (adjustment: any) => {
        const newState = changeDepth(
            editorState,
            adjustment,
            4,
        );
        if (newState) {
            onChange(newState);
        }
    };

    const indentDisabled = () => {
        const previousBlock = getBlockBeforeSelectedBlock(editorState);
        if (!previousBlock ||
            !isListBlock(currentBlock) ||
            (previousBlock.get('type') !== currentBlock.get('type')) ||
            (previousBlock.get('depth') < currentBlock.get('depth'))
        ) {
            return true;
        }
        return false;
    }

    const outdentDisabled = () => {
        return !currentBlock || !isListBlock(currentBlock) || currentBlock.get('depth') <= 0;
    }

    const toggle = (value: string): void => {
        if (value === 'unordered-list-item'
            || value === 'ordered-list-item') {
            toggleBlockType(value);
        } else if (value === 'indent') {
            adjustDepth(1);
        } else {
            adjustDepth(-1);
        }
    };

    return (<>
        <Button onMouseDown={() => {
            toggle('ordered-list-item')
        }}
            active={currentBlock && currentBlock.get('type') === 'ordered-list-item'}
            title={title[0]}>
            <Icon name="OrderList"
                color={currentBlock && currentBlock.get('type') === 'ordered-list-item' ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
        <Button onMouseDown={() => {
            toggle('unordered-list-item')
        }}
            active={currentBlock && currentBlock.get('type') === 'unordered-list-item'}
            title={title[1]}>
            <Icon name="UnOrderList"
                color={currentBlock && currentBlock.get('type') === 'unordered-list-item' ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
        <Button onMouseDown={() => {
            toggle('indent')
        }}
            disabled={indentDisabled()}
            title={title[2]}>
            {/* 缩进 */}
            <Icon name="Indent"
                color={indentDisabled() ? '#ccc' : '#484848'}
                width={18} height={18} />
        </Button>
        <Button onMouseDown={() => {
            toggle('outdent')
        }}
            disabled={outdentDisabled()}
            title={title[3]}>
            {/* 缩进 */}
            <Icon name="Outdent"
                color={outdentDisabled() ? '#ccc' : '#484848'}
                width={18} height={18} />
        </Button>
    </>
    );
}