import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
 p-id="7598"  width={width} height={height}>
 <path fill={color} d="M930.704662 202.89706 91.20677 202.89706c-15.436574 0-27.983331-12.538571-27.983331-27.982308 0-15.436574 12.546757-27.983331 27.983331-27.983331l839.497892 0c15.406898 0 27.983331 12.546757 27.983331 27.983331C958.687994 190.358489 946.110537 202.89706 930.704662 202.89706z" p-id="7599"></path>
 <path fill={color} d="M930.704662 370.797048 482.971361 370.797048c-15.435551 0-27.983331-12.537548-27.983331-27.983331 0-15.434527 12.546757-27.982308 27.983331-27.982308l447.733301 0c15.406898 0 27.983331 12.547781 27.983331 27.982308C958.687994 358.258477 946.110537 370.797048 930.704662 370.797048z" p-id="7600"></path>
 <path fill={color} d="M930.704662 538.696012 482.971361 538.696012c-15.435551 0-27.983331-12.568247-27.983331-27.983331 0-15.442714 12.546757-27.983331 27.983331-27.983331l447.733301 0c15.406898 0 27.983331 12.540617 27.983331 27.983331C958.687994 526.127765 946.110537 538.696012 930.704662 538.696012z" p-id="7601"></path>
 <path fill={color} d="M930.704662 706.594977 482.971361 706.594977c-15.435551 0-27.983331-12.567223-27.983331-27.983331 0-15.414061 12.546757-27.982308 27.983331-27.982308l447.733301 0c15.406898 0 27.983331 12.567223 27.983331 27.982308C958.687994 694.027753 946.110537 706.594977 930.704662 706.594977z" p-id="7602"></path>
 <path fill={color} d="M930.704662 874.497011 91.20677 874.497011c-15.436574 0-27.983331-12.568247-27.983331-27.982308 0-15.417131 12.546757-27.987425 27.983331-27.987425l839.497892 0c15.406898 0 27.983331 12.56927 27.983331 27.987425C958.687994 861.928764 946.110537 874.497011 930.704662 874.497011z" p-id="7603"></path>
 <path fill={color} d="M91.20677 706.594977c-4.261047 0-8.552793-0.976234-12.539594-2.948145-9.456373-4.817726-15.443737-14.434757-15.443737-25.035186L63.223439 342.813716c0-10.574846 5.988388-20.303418 15.443737-25.032116 9.456373-4.700045 20.824281-3.682879 29.316699 2.65241l223.86665 167.899988c7.055696 5.246491 11.205203 13.57518 11.205203 22.379707s-4.149507 17.162892-11.205203 22.410406L107.983875 701.022051C103.072005 704.685487 97.134783 706.594977 91.20677 706.594977zM119.190101 398.780379l0 223.86665 149.261489-111.933325L119.190101 398.780379z" p-id="7604"></path></svg>
)
