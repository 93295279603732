import { AxiosRequestConfig } from "axios";
import { API } from "../../../config";
import * as types from "../../../types";
import * as uiTypes from "../../../types/ui";

// Action Types
export type Action =
  | {
      // 列表
      type: "UI_PAGE_FETCH_LIST";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | { type: "UI_PAGE_FETCH_LIST_SUCCESS"; payload: any }
  | {
      type: "UI_PAGE_FETCH_LIST_FAILURE";
      payload: { error: types.Error };
    }
  | {
      // 获取
      type: "UI_PAGE_FETCH";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | { type: "UI_PAGE_FETCH_SUCCESS"; payload: any }
  | { type: "UI_PAGE_FETCH_FAILURE"; payload: { error: types.Error } }
  | {
      // 保存
      type: "UI_PAGE_SAVE";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 删除
      type: "UI_PAGE_REMOVE";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 设置状态
      type: "UI_PAGE_SET_STATUS";
      payload: { client: "default"; request: AxiosRequestConfig };
    };

// 列表
export const FETCH_LIST = "UI_PAGE_FETCH_LIST";
export const FETCH_LIST_SUCCESS = "UI_PAGE_FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "UI_PAGE_FETCH_LIST_FAILURE";
export const fetchList = (params: any): Action => ({
  type: FETCH_LIST,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/page/getList`,
      method: "GET",
      params
    }
  }
});
export const fetchListSucess = (list: uiTypes.Page[]): Action => ({
  type: FETCH_LIST_SUCCESS,
  payload: {
    list
  }
});
export const fetchListFailure = (error: types.Error): Action => ({
  type: FETCH_LIST_FAILURE,
  payload: {
    error
  }
});

// 获取
export const FETCH = "UI_PAGE_FETCH";
export const FETCH_SUCCESS = "UI_PAGE_FETCH_SUCCESS";
export const FETCH_FAILURE = "UI_PAGE_FETCH_FAILURE";
export const fetch = (id: any): Action => ({
  type: FETCH,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/page/get`,
      method: "GET",
      params:{
        id
      }
    }
  }
});
export const fetchSucess = (page: uiTypes.Page): Action => ({
  type: FETCH_SUCCESS,
  payload: {
    page
  }
});
export const fetchFailure = (error: types.Error): Action => ({
  type: FETCH_FAILURE,
  payload: {
    error
  }
});

// 保存
export const SAVE = "UI_PAGE_SAVE";
export const save = (data: any): Action => ({
  type: SAVE,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/page/save`,
      method: "POST",
      data
    }
  }
});

// 删除
export const REMOVE = "UI_PAGE_REMOVE";
export const remove = (id: string[]): Action => ({
  type: REMOVE,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/page/del`,
      method: "POST",
      data: {
        id
      }
    }
  }
});

// 设置状态
export const SET_STATUS = "UI_PAGE_SET_STATUS";
export const setStatus = (data:any): Action => ({
  type: SET_STATUS,
  payload: {
    client: "default",
    request: {
      url: `${API}ui/page/setStatus`,
      method: "POST",
      data
    }
  }
});
