import React, { useState, useEffect } from 'react'
import { createTId } from '../../../lib/util'
import * as Config  from '../../../config';

import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import IconClose from '@material-ui/icons/Close'
import Icon from '../../../components/Icon'
import PageToolbar from '../../../components/ui/PageToolbar'

import { withRouter } from "react-router-dom";
import { useAreaState, useArea } from '../../../provides/common'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, ConfirmDialog } from '../../../components/ui/dialog'
// 分类树
import DataTreeView from '../../../components/ui/tree/DataTree'
import { useLocale } from '../../../config/Locale'
import { useListPageStyles } from '../../../assets/jss/pages'
import { useSysState } from '../../../provides/sys'

const AreaPage = (props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { getList,buildJsonFile, readJsonData,remove } = useArea()
  const [checked, setChecked] = useState<any>({})
  const classes = useListPageStyles()

  const { account } = useSysState()

  // 预览
  const [jsonPreview, setJsonPreview] = useState({ open: false, json: ''})
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  // 原始列表
  const originList = useAreaState().list;
  // 最终显示列表
  const [list, setList] = useState<any>(originList)
  // 关键字
  const [keyword, setKeyword] = useState<string>("")
  // 过滤
  const search = (params?: any) => {
    let k = params && params.keyword
    k = k ? k.trim().toLowerCase() : ""
    if (k) {
      setList(originList.filter((item: any) => item.id.toLowerCase().indexOf(k) !== -1 ||
        (item.name && item.name.indexOf(k) !== -1)||
        (item.shortName && item.shortName.indexOf(k) !== -1)||
        (item.enname && item.enname.indexOf(k) !== -1)))
    } else {
      setList(originList)
    }
  }
  const postBuildJsonFile = ()=>{
    // 获取列表
    setLoading({
      open: true,
      text: '正在生成行政区划JSON数据文件...'
    })
    buildJsonFile({
      success: (message: string) => {
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }

  const readJson = ()=>{
    setLoading({
      open: true,
      text: '正在读取行政区划JSON数据...'
    })
    readJsonData({
      success: (json: string) => {
        setJsonPreview({
          open: true,
          json
        })
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }


  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载行政区划列表数据...'
    })
    getList({
      success: (list: string) => {
        setKeyword("")
        setList(list)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadData()
    return () => {
    };
  }, []);

  // 警示框
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState({
    children: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    setConfirmOpen(true)
    setConfirmProps({
      children: <Box className={classes.alert}>
        <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
        <Box>
          <Box>
            <Box>{translate('area.removeTips')}</Box>
            <Box>{translate('area.removeAsk')}</Box>
          </Box>
        </Box>
      </Box>,
      title: '',
      ok: () => {
        setConfirmOpen(false)
        setLoading({
          open: true,
          text: translate('area.removing')//'正在删除...'
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadData()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setConfirmOpen(false)
      }
    })
  }

  const renderPageToolbar = (
    <PageToolbar
      {...{
        keyword,
        onFilter: (keyword: string) => {
          setKeyword(keyword)
          search({ keyword })
        },
        title: translate('area.self'),
        label: {
          add: translate('area.add')
        },
        onAddClick: () => {
          // 跳转添加页面
          const location = {
            pathname: "/common/area/edit",
            search: `?id=${createTId()}`,
            state: {
              fromList: true
            }
          }
          history.push(location)
          history.replace(location)
        },
        onDelClick: () => {
          // 批量删除
          if (checked)
            handleDelete(Object.keys(checked))
        },
        onRefresh: () => {
          // 刷新数据
          loadData()
        },
        tools:<>
        <Button variant="outlined" onClick={()=>postBuildJsonFile()}>生成JSON文件</Button>
        <span style={{width:8}}/>
        {account && <Button
        variant="outlined"
        onClick={readJson}>读取JSON文件</Button>}
        </>
      }} />)

  const renderPageContent = (<Box className="page-content">
    <DataTreeView
      items={list}
      edit={true}
      showCheckbox={true}
      showDescri={true}
      onEdit={(item: any) => {
        history.replace(`/common/area/edit?id=${item.id}`)
      }}
      onAppend={(item: any) => {
        history.replace(`/common/area/edit?parentId=${item.id}&id=${createTId()}`)
      }}
      onRemove={(item: any) => {
        handleDelete([item.id])
      }}
      onItemChecked={(checked: any) => {
        setChecked(checked)
      }}
    />
  </Box>)


  const renderPreview = <Drawer anchor="right" 
    open={jsonPreview.open} 
    onClose={()=>setJsonPreview({
      open:false,
      json:jsonPreview.json
    })}>
      <div style={{
        position:"fixed",
        width:'100%',
        borderBottom:"1px solid #dedede",
        cursor:"pointer",
        background:"#f8f8f8",
        padding:10}}>
        可复制JSON数据并存储为area.json文件
        <Button 
        onClick={()=>setJsonPreview({
            open:false,
            json:jsonPreview.json
          })}>
          <IconClose/>
          关闭
          </Button>
      </div>
      <div  style={{ padding: '60px 10px 10px 10px' }} >
        {jsonPreview.json}
      </div>
    </Drawer>

  return (<Box>

    {renderPageToolbar}

    <LoadingBox {...loading} />

    {list.length === 0 && !loading.open && (<Box className="error">
      <Icon name="Error" width={32} height={32} color="gray" />
      <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
    </Box>)}

    {renderPageContent}

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 确认框 */}
    <ConfirmDialog open={confirmOpen} {...confirmProps} />
    {renderPreview}
  </Box>
  )
}
export default withRouter(AreaPage)