import React from 'react'
export default ({
    width = 16,height=16,className = "icon",color="gray"
})=>(
    <svg className={className}  viewBox="0 0 1024 1024"
     version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9464" 
     width={width} height={height}><path 
     fill={color} 
     d="M512.911 1021.999c-68.712 0-135.387-13.465-198.17-40.02-60.625-25.643-115.066-62.346-161.81-109.089C106.188 826.146 69.485 771.706 43.842 711.08c-26.555-62.783-40.02-129.457-40.02-198.17 0-68.712 13.465-135.387 40.02-198.17 25.643-60.626 62.345-115.066 109.089-161.81 46.743-46.744 101.184-83.446 161.81-109.089 62.784-26.556 129.458-40.02 198.17-40.02 68.713 0 135.387 13.464 198.17 40.02 60.626 25.643 115.066 62.345 161.811 109.089 46.743 46.744 83.446 101.184 109.089 161.81 26.555 62.784 40.02 129.458 40.02 198.17 0 68.713-13.465 135.387-40.02 198.17-25.643 60.626-62.346 115.066-109.089 161.811-46.744 46.743-101.185 83.446-161.811 109.089C648.298 1008.534 581.624 1021.999 512.911 1021.999zM512.911 67.821c-245.423 0-445.089 199.666-445.089 445.089 0 245.423 199.666 445.089 445.089 445.089S958 758.333 958 512.91C958 267.487 758.334 67.821 512.911 67.821z" p-id="9465"></path><path d="M510 671c-17.673 0-32-14.327-32-32L478 206c0-17.673 14.327-32 32-32s32 14.327 32 32l0 433C542 656.673 527.673 671 510 671z" p-id="9466"></path><path d="M510.5 797.5m-31.5 0a31.5 31.5 0 1 0 63 0 31.5 31.5 0 1 0-63 0Z" p-id="9467"></path></svg>)
    // <svg className={className} viewBox="0 0 1024 1024" version="1.1"
    //  xmlns="http://www.w3.org/2000/svg" p-id="2215" 
    //   width={width} height={height}><path fill={color}  d="M512 981.333333c259.2 0 469.333333-210.133333 469.333333-469.333333S771.2 42.666667 512 42.666667 42.666667 252.8 42.666667 512s210.133333 469.333333 469.333333 469.333333z m0 42.666667C229.226667 1024 0 794.773333 0 512S229.226667 0 512 0s512 229.226667 512 512-229.226667 512-512 512z" p-id="2216"></path><path d="M706.88 724.266667a21.333333 21.333333 0 1 1-31.616 28.629333A212.992 212.992 0 0 0 516.736 682.666667a213.44 213.44 0 0 0-126.890667 41.6 21.333333 21.333333 0 0 1-25.322666-34.325334A256.085333 256.085333 0 0 1 516.714667 640a255.637333 255.637333 0 0 1 190.165333 84.266667zM330.346667 424.533333l-60.330667 60.330667a21.333333 21.333333 0 0 1-30.165333-30.165333l60.330666-60.352-60.330666-60.330667a21.333333 21.333333 0 0 1 30.165333-30.165333l60.330667 60.330666 60.352-60.330666a21.333333 21.333333 0 1 1 30.165333 30.165333l-60.352 60.330667 60.352 60.352a21.333333 21.333333 0 1 1-30.165333 30.165333l-60.352-60.352zM693.013333 424.533333l-60.330666 60.330667a21.333333 21.333333 0 0 1-30.165334-30.165333l60.330667-60.352-60.330667-60.330667a21.333333 21.333333 0 1 1 30.165334-30.165333l60.330666 60.330666 60.352-60.330666a21.333333 21.333333 0 1 1 30.165334 30.165333l-60.352 60.330667 60.352 60.352a21.333333 21.333333 0 1 1-30.165334 30.165333l-60.352-60.352z" p-id="2217"></path></svg>)
