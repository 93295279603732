import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as shoppingTypes from '../../../types/shopping'


// Action Types
export type Action = {
    // 规格列表 
    type: 'SHOPPING_SPEC_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type: 'SHOPPING_SPEC_FETCH_LIST_SUCCESS', payload: { list: shoppingTypes.Spec[], pagination: types.Pagination } }
    | { type: 'SHOPPING_SPEC_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 按分类获取
        type: 'SHOPPING_SPEC_FETCH_LIST_BY_CAT',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'SHOPPING_SPEC_FETCH_LIST_BY_CAT_SUCCESS', payload: { list: shoppingTypes.Spec[], pagination?: types.Pagination } }
    | { type: 'SHOPPING_SPEC_FETCH_LIST_BY_CAT_FAILURE', payload: { error: types.Error } }
    |  {
        // 检测名称
        type: 'SHOPPING_SPEC_CHECK_NAME',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'SHOPPING_SPEC_CHECK_NAME_SUCCESS', payload: { success: types.Success } }
    | { type: 'SHOPPING_SPEC_CHECK_NAME_FAILURE', payload: { error: types.Error } }
    | {
        // 规格
        type: 'SHOPPING_SPEC_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'SHOPPING_SPEC_FETCH_SUCCESS', payload: { spec: shoppingTypes.Spec } }
    | { type: 'SHOPPING_SPEC_FETCH_FAILURE', payload: { error: types.Error } }
    | {
        // 保存列表
        type: 'SHOPPING_SPEC_SAVE_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 保存单个规格
        type: 'SHOPPING_SPEC_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 检测名称
        type: 'SHOPPING_SPEC_CHECK_NAME',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 删除
        type: 'SHOPPING_SPEC_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | {
        // 设置状态
        type: 'SHOPPING_SPEC_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };




// 规格列表
export const FETCH_LIST = 'SHOPPING_SPEC_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'SHOPPING_SPEC_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'SHOPPING_SPEC_FETCH_LIST_FAILURE';
export const fetchList = (params:any): Action => ({
    type: FETCH_LIST,
    payload: { 
        client: 'default',
        request: {
            url: `${API}shopping/spec/getList`,
            method: 'GET',
            params
        }
    }
})
export const fetchListSucess = (list: shoppingTypes.Spec[], pagination: types.Pagination): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list, pagination
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});




// 按分类获取
export const FETCH_LIST_BY_CAT = 'SHOPPING_SPEC_FETCH_LIST_BY_CAT'
export const FETCH_LIST_BY_CAT_SUCCESS = 'SHOPPING_SPEC_FETCH_LIST_BY_CAT_SUCCESS';
export const FETCH_LIST_BY_CAT_FAILURE = 'SHOPPING_SPEC_FETCH_LIST_BY_CAT_FAILURE';
export const fetchListByCat = (catId: string): Action => ({
    type: FETCH_LIST_BY_CAT,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/getListByCat`,
            method: 'GET',
            params:{
                catId
            }
        }
    }
})
export const fetchListByCatSucess = (list: shoppingTypes.Spec[]): Action => ({
    type: FETCH_LIST_BY_CAT_SUCCESS,
    payload: {
        list
    } 
});
export const fetchListByCatFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_BY_CAT_FAILURE,
    payload: {
        error
    }
});


// 按分类获取
export const FETCH = 'SHOPPING_SPEC_FETCH'
export const FETCH_SUCCESS = 'SHOPPING_SPEC_FETCH_SUCCESS';
export const FETCH_FAILURE = 'SHOPPING_SPEC_FETCH_FAILURE';
export const fetch = (params: any): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/get`,
            method: 'GET',
            params
        }
    }
})
export const fetchSucess = (spec: shoppingTypes.Spec): Action => ({
    type: FETCH_SUCCESS,
    payload: {
        spec
    } 
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});



// 保存
export const SAVE_LIST = 'SHOPPING_SPEC_SAVE_LIST'
export const saveList = (specs: shoppingTypes.Spec[],catId:string,status:string): Action => ({
    type: SAVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/saveList`,
            method: 'POST',
            data:{
                specs,
                catId,
                status
            }
        }
    }
})



// 保存
export const SAVE = 'SHOPPING_SPEC_SAVE'
export const save = (spec: shoppingTypes.Spec,values:string[]): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/save`,
            method: 'POST',
            data:{
                spec,
                values
            }
        }
    }
})

// 检测名称
export const CHECK_NAME = 'SHOPPING_SPEC_CHECK_NAME'
export const checkName = (name:string,catId:string,id:string): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/save`,
            method: 'POST',
            data:{
                name,
                catId,
                id
            }
        }
    }
})



// 删除
export const REMOVE = 'SHOPPING_SPEC_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})



// 设置状态
export const SET_STATUS = 'SHOPPING_SPEC_SET_STATUS'
export const setStatus = (data: any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/spec/setStatus`,
            method: 'POST',
            data
        }
    }
})
