
import React, { useState } from 'react';
import classNames from "classnames"
import { fade, makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import MuiButtonGroup from '@material-ui/core/ButtonGroup';

import { RadioInputOption, RadioInputProps } from './types'

const Button = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border:0,
      whiteSpace:"nowrap"
    }
  }))(MuiButton)

const ButtonGroup = withStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      background:"#f7f7f7",
      border:"1px solid #dedede"
    }
  }))(MuiButtonGroup)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap'
    },
    label: {
      textAlign: 'right',
      flexWrap: 'nowrap'
    },
    wrapGroup:{
      flexWrap: "wrap",
    },
    input: {
      margin: theme.spacing(1),
    },
    tips: {
      padding:theme.spacing(1,0),
      fontSize:12,
      color: '#999'
    },
  }),
);
const RadioInput = (props: RadioInputProps) => {
  const classes = useStyles()
  const { label, labelWidth, onChange, value, options, tips, margin } = props
  const labelStyle: React.CSSProperties = labelWidth ? {
    width: labelWidth
  } : {};
  return (
    <Box className={classes.root} style={{ margin: margin ? margin : '' }}>
      <Box component="span"
        style={labelStyle}
        className={classes.label}>
        {label}
      </Box>
      <Box component="span" className={classes.input}>
        <ButtonGroup
          className={classNames({
            [classes.wrapGroup]:options.length>5
          })}
          size='small'
          variant="outlined"
          color="primary">
          {
            options.map((option: RadioInputOption) => (
              <Button
                size='small'
                key={option.value}
                variant={value === option.value ? "contained" : "outlined"}
                color={value === option.value ? "primary" : "default"}
                onClick={(e: any) => {
                  if (onChange) {
                    onChange(e, option.value)
                  }
                }}
              >{option.label}</Button>
            ))
          }
        </ButtonGroup>
        {tips && <Box className={classes.tips}>
          {tips}
        </Box>}
      </Box>
    </Box>
  )
}
export default RadioInput