import useMediaQuery from '@material-ui/core/useMediaQuery';

export const mobileWidth = '(max-width:600px)'
export const padWidth = '(min-width:680px)'
export const pcLowWidth = '(min-width:960px)'
export const pcWidth = '(min-width:1200px)'

export const MOBILE = 1 // 手机
export const PAD = 2 // PAD
export const PC_LOW = 3 // 小尺寸PC
export const PC = 4 // 正常尺寸PC
/**
 * 对应jss设置
 */
export const useMediaMatches = ()=>{
    const mobile = useMediaQuery(mobileWidth);
    const pad = useMediaQuery(padWidth);
    const pcLow = useMediaQuery(pcLowWidth);
    const pc = useMediaQuery(pcWidth);
    
    if(mobile){
        return MOBILE
    }else if(pc){
        return PC
    }else if(pcLow){
        return PC_LOW
    }else if(pad){
        return PAD
    }
    return 0
}