import React, { useState, useCallback, useEffect } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

import {  useAttrState } from '../../../provides/shopping'

import { useLocale } from '../../../config/Locale'
import { useAttr } from '../../../provides/shopping/AttrProvide'

import LoadingBox from '../../ui/LoadingBox'
import { MessageDialog, ConfirmDialog } from '../../ui/dialog'

import { isFunction } from '../../../lib/util'
import { ProductAttrProps } from './types'

import Form from './Form'
import List from './List'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            clear: 'both',
            margin: '5px 0',
            borderBottom: '1px solid #dedede'
        },
        rowLabel: {
            whiteSpace: 'nowrap',
            float: 'left',
            padding: '0 10px'
        },
        rowContent: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            flexWrap: 'wrap',
            userSelect: 'none'
        },
        button: {
            fontSize: '0.8em',
            marginRight: theme.spacing(1)
        },
        error: {
            display: 'inline-flex',
            color: '#ff6600',
            fontSize: '0.8em',
            alignItems: 'center'
        },
        form: {
            borderRadius: 5
        },
        list: {
            borderRadius: 5
        },
        icon: {
            margin: '0 5px 0 0',
            width: 16,
            height: 16
        },
        aBoxContainer: {
            margin: '15px 10px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            flexWrap: 'wrap',
            userSelect: 'none'
        },
        aBox: {
            position: 'relative',
            border: '1px solid #eee',
            color: '#666',
            margin: '5px 10px 5px 0',
            padding: '5px 25px 5px 10px',
            cursor: 'pointer',
            borderRadius: 3,
            '&:hover': {
                border: '1px solid #666',
                color: '#000',
            }
        },
        aBoxValue: {
            fontStyle: 'italic',
            fontWeight: 'bold'
        },
        aBoxClose: {
            position: 'absolute',
            top: '-5px',
            right: '-5px',
            borderRadius: '50%',
            background: '#fff'
        },
        active: {
            color: '#333',
            background: '#f3f3f3',
            border: '1px inset #ddd',
        },
        paper: {
            margin: theme.spacing(1, 0)
        },
        buttons: {
            alignItems: 'center',
            justifyContent: 'flex-start',
            display: 'flex',
            clear: 'both',
            margin: '5px 0'
        },
    }))

export default (props: ProductAttrProps) => {
    const { translate } = useLocale()
    const classes = useStyles()
    const {
        getListByCat
    } = useAttr()
    const {
        catId,
        loaded,
        onLoaded,
        productAttrs,
        onChange
    } = props

    const attrList = useAttrState().list;

    // 事件反馈
    const initialMessage = {
        open: false,
        type: '',
        title: '',
        text: ''
    }
    const [message, setMessage] = useState(initialMessage)
    const initialLoading = { open: false, text: '' }
    const [loading, setLoading] = useState({
        open: false,
        text: ''
    })

    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState<any>({})

    const currentSelects = () => {
        let newSelected: any = selected || {}
        let currentValId: any = []
        if (productAttrs)
            productAttrs.map((ps: any) => {
                const valId = ps.AttrValId
                if(valId){
                    const array = valId.split(',')
                    currentValId = currentValId.concat(array)
                }
            })
        currentValId = Array.from(new Set(currentValId))
        if (attrList)
        attrList.map((attr: any) => {
                if (attr.valList) {
                    attr.valList.map((attrVal: any) => {
                        // 如果当前值已存在于已保存的商口属性
                        if (currentValId.indexOf(attrVal.id) != -1) {
                            // 添加
                            newSelected[attr.id] = newSelected[attr.id] || {
                                attr,
                               attrVals: {}
                            };
                            newSelected[attr.id]["attrVals"][attrVal.id] = attrVal;
                        }
                    })
                }
            })
        setSelected(Object.assign({}, newSelected))
        // on data change 
        onDataChange()
    }
    const onDataChange=()=> {
      const productAttrs:any = [];
      Object.keys(selected).map(key => {
          let attrVals = selected[key]['attrVals']
          let attrValId = '' 
          Object.keys(attrVals).map(key=>{
              attrValId += (attrValId.length>0?',':'')+key
          });
          productAttrs.push({
            attrValId
          })
      });
      onChange(productAttrs);
  }

    // 查询属性列表
    const loadAttrList = () => {
        // 保存属性列表
        setLoading({
            open: true,
            text: translate('loadingText.data')
        })
        getListByCat({
            params: {
                catId
            },
            success: (list: any) => {
                // 关闭加载条
                setLoading(initialLoading)
                currentSelects()
            },
            failure: (message: string) => {
                // 关闭加载条
                setLoading(initialLoading)
                currentSelects()
            }
        })
    }
    // 加载初始化列表
    useEffect(() => {
        if (!loaded) {
            if (onLoaded && isFunction(onLoaded)) {
                onLoaded()
            }
            loadAttrList()
        }
    })

    const renderButtons = (
        <Box className={classes.buttons}>
            <Button
                variant="outlined"
                className={classes.button}
                onMouseDown={() => {
                    setShow(true)
                }} >
                添加属性
             </Button>
            <Button
                variant="outlined"
                className={classes.button}
                onMouseDown={() => {
                    // go to Attr list page
                }} >
                管理属性
        </Button>
        </Box>
    )

    /**
     * 表单
     */
    const renderForm = <Form {...{
        catId,
        classes,
        onHide: () => {
            setShow(false)
        },
        onSaved: (loading: any) => {
            setLoading(loading)
        },
        onSavedSuccess: (message: string) => {
            // 关闭加载条
            loadAttrList()
        },
        onSavedFailure: (message: string) => {
            // 关闭加载条
            setLoading(initialLoading)
            setMessage({
                open: true,
                type: 'error',
                title: translate('alert.tips'),// 提示
                text: message
            })
        }
    }} />

    // <!-- 已添加的键值对属性列表 -->
    const renderList = <List
        {...{
            classes,
            attrList,
            selected,
            onSelected: (selected: any) => {
                setSelected(selected)
                // on data change 
                onDataChange()
            }
        }} />

    return (<>
        {renderButtons}

        {show && renderForm}

        {renderList}

        <LoadingBox {...loading} />

        <MessageDialog
            onClose={() => {
                setMessage(initialMessage)
            }}
            message={message} />
    </>)
}