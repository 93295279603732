import { Action } from '../../actions';
import * as Actions from '../../actions/common/area';
import * as State from '../../state/common';

export const initialState: State.Area = {
};

export const reducer = (state: State.Area = initialState, action: Action): State.Area => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return Object.assign({
                ...state,
                list: action.payload.list
            });

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                list:[],
                error: action.payload.error
            };

        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                area: action.payload.area
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };
        default: return state;
    }
};