
import axios from 'axios'
import service from '../api/service'

/**
 * 网络请求
 */
export const clients = {
  default: {
      client: service
  }, googleMaps: {
      client: axios.create({
          baseURL: 'https://maps.googleapis.com/maps/api',
          responseType: 'json'
      })
  }
}