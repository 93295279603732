import {
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  Modifier
} from 'draft-js';
import {
  changeDepth,
  getSelectedBlocksType,
  removeEntityOnBackspace
} from '../utils';

type SyntheticKeyboardEvent = React.KeyboardEvent<{}>;

export default function events(onChange: Function, editorState: EditorState) {

  const { isCtrlKeyCommand } = KeyBindingUtil;

  const keyBindingFn = (e: SyntheticKeyboardEvent) => {
    const blocksType = getSelectedBlocksType(editorState);
    //
    //回车，ASCII码13
    //换行，ASCII码10
    //空格，ASCII码32
    //S:83
    if (e.keyCode === 13 && (
      blocksType === 'code' || blocksType === 'code-block' || blocksType === 'pre'
    )) {
      return 'enter';
    } else if (e.keyCode === 83 /* `S` key */ && isCtrlKeyCommand(e)) {
      // ctrl + s
      return 'save';
    }
    return getDefaultKeyBinding(e);
  }


  const handleKeyCommand = (command: any) => {
    const nextState = RichUtils.handleKeyCommand(editorState, command);
    switch (command) {
      case 'enter': {
        // 回车键
        const nextState = editorState.getCurrentContent()
        onChange(EditorState.push(editorState, Modifier.insertText(
          nextState,
          editorState.getSelection(),
          '\r\n'
        ), 'insert-characters'))
        return 'not-handled';
      }
      case 'backspace':{
        // 退格键
        const nextState = removeEntityOnBackspace(editorState)
        if(nextState){
          onChange(nextState)
        }
      }
    }
    if (nextState) {
      return 'handled';
    }
    return 'not-handled';
  }

  const onTab = (event: SyntheticKeyboardEvent) => {
    const nextEditorState = changeDepth(
      editorState,
      event.shiftKey ? -1 : 1,
      4
    );
    if (nextEditorState && nextEditorState !== editorState) {
      onChange(nextEditorState);
      event.preventDefault();
    }
  };
  const onFocus = (event: any) => {
  };

  return {
    keyBindingFn,
    handleKeyCommand,
    onTab,
    onFocus
  }
}
