import React, { useState } from "react"

import classNames from "classnames"
import { useUIPageStyles, useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import Icon from '../../../../components/Icon'
import { AlertDialog } from '../../../../components/ui/dialog'

import { PageSetting } from '../../../../types/ui';

import EditComponent from "../EditComponent"

import { useItemMethods } from './components/Util';
import ItemTools from "./components/ItemTools"

type Props = {
    data: PageSetting[],
    onChange: (data: PageSetting[]) => void,
}


type ItemProps = {
    item: PageSetting,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onRemove: (item: PageSetting) => void,
    onEdit: (item: PageSetting) => void
}

type EditDialogProps = {
    setting: PageSetting,
    cancel: () => void,
    onChange: (setting: PageSetting) => void
}

const Item = (props: ItemProps) => {
    const { item, showUp, showDown, sortUp, sortDown, onRemove, onEdit } = props
    const classes = useUIInputsStyles()
    const pageClasses = useUIPageStyles()


    const renderComponentInfo = <Box>
        <Box>{item.component ? <>
            <span>{item.component.name}</span>
            <span className={pageClasses.label}>({item.component.alias})</span>
        </> :
            "未设置组件"
        }</Box>
        <Box>
        <span className={pageClasses.label}>数据源类型：</span>
        <span>{item.dataSource && item.dataSource.type}</span>
        </Box>
    </Box>

    return <Box className={classes.listItem}>
        <Box className={classes.itemFields}>
            {renderComponentInfo}
        </Box>
        <ItemTools
            onEditing={() => onEdit(item)}
            onRemove={() => onRemove(item)}
            showUp={showUp}
            showDown={showDown}
            sortUp={sortUp}
            sortDown={sortDown} />
    </Box>
}

/** 
 * 组件列表输入，用于标签页子集，文本轮播子集或其它引入组件列表的组件
 */
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const pageClasses = useUIPageStyles()
    const { data = [] } = props

    const [editProps, setEditProps] = useState<EditDialogProps | null>(null)
    // 编辑
    const handleEdit = (
        setting: PageSetting,
        onChange?: (setting: PageSetting) => void
    ) => {
        setEditProps({
            setting,
            cancel: () => {
                setEditProps(null)
            },
            onChange: (setting: PageSetting) => {
                if (onChange) {
                    onChange(setting)
                } else {
                    // 更新列表
                    data.forEach((oldItem: any, index: number) => {
                        if (setting.id === oldItem.id) {
                            data.splice(index, 1, setting)
                            props.onChange(data)
                            return
                        }
                    })
                }
                setEditProps(null)
            }
        })
    }

    // 添加子元素
    const handleAdd = () => {
        handleEdit({
            id: new Date().getTime() + "-" + Math.ceil(Math.random() * 9999),
            type: "component"
        }, (setting: PageSetting) => {
            // 插入列表
            data.unshift(setting)
            props.onChange(data)
        })
    }

    // 警示框
    const [alertOpen, setAlertOpen] = useState(false)
    const [alertProps, setAlertProps] = useState({
        description: <></>,
        title: '',
        ok: () => { },
        cancel: () => { }
    })
    // 删除
    const remove = (item: any) => {
        if (data.length === 1) {
            props.onChange([])
        } else {
            props.onChange(data.filter((oldItem: any) => item.id !== oldItem.id))
        }
    }
    const handleRemove = (setting: PageSetting) => {
        const description = (<Box className={pageClasses.alert}>
            <Box className={pageClasses.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
            <Box>
                <Box>
                    <Box>您正在移除节点！</Box>
                    <Box>保存后将不可恢复，确定要继续么？</Box>
                </Box>
            </Box>
        </Box>)
        setAlertOpen(true)
        setAlertProps({
            description,
            title: '',
            ok: () => {
                remove(setting)
                setAlertOpen(false)
            },
            cancel: () => {
                setAlertOpen(false)
            }
        })
    }
    // 排序
    const {
        sortUp,
        sortDown
    } = useItemMethods({
        data,
        initialData: {},
        onChange: (data: any) => {
            props.onChange(data)
        }
    });

    return <Box className={classes.list}>
        <Box className={classNames(classes.listToolbar, classes.light)}>
            <Box className={classes.listToolbarTitle}> <IconList />组件列表</Box>
            <Box className={classes.grow} />
            <Button color="primary" onClick={handleAdd}>
                <IconAdd />添加组件
        </Button>
        </Box>
        {data.map((item: PageSetting, index: number) => <Item key={item.id}
            item={item}
            onRemove={handleRemove}
            onEdit={handleEdit}
            showUp={index > 0}
            showDown={index < data.length - 1}
            sortUp={() => {
                sortUp(index)
            }}
            sortDown={() => {
                sortDown(index)
            }}
        />)}
        {editProps && <EditComponent  {...editProps} />}
        {alertOpen && <AlertDialog open={true} {...alertProps} />}
    </Box >
}