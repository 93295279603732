import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="19244" width={width} height={height}>
    <path fill={color} d="M85.333333 725.333333h85.333334v21.333334H128v42.666666h42.666667v21.333334H85.333333v42.666666h128v-170.666666H85.333333v42.666666z m42.666667-384h42.666667V170.666667H85.333333v42.666666h42.666667v128z m-42.666667 128h76.8L85.333333 558.933333V597.333333h128v-42.666666H136.533333l76.8-89.6V426.666667H85.333333v42.666666z m213.333334-256v85.333334h597.333333v-85.333334H298.666667z m0 597.333334h597.333333v-85.333334H298.666667v85.333334z m0-256h597.333333v-85.333334H298.666667v85.333334z" p-id="19245"></path></svg>
    )
