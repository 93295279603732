import React from "react"
import {formatDateTime2} from '../../../lib/util'
import {EXPRESS_LIST}  from '../../../config';
// 容器
import Box from '@material-ui/core/Box';

import {useOrderStyles,useListPageStyles} from '../../../assets/jss/pages'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableRow, BodyTableCell, TableHead,TableRowToolbar } from '../../../components/ui/table'

import { SendDetail} from "../../../types/trade" 
import SimpleDetailItem from './SimpleDetailItem'

const fields = [
  { id: 'mode', numeric: false, disablePadding: false, label: '寄送方式' },
  { id: 'express', numeric: false, disablePadding: true, label: '快递公司' },
  { id: 'expressNo', numeric: true, disablePadding: false, label: '快递单号' },
  { id: 'details', numeric: true, disablePadding: false, label: '送货商品' },
  { id: 'sendTime', numeric: false, disablePadding: false, label: '发货时间' }
];

export default (props: any) => {
  const {list = [],onEdit,onDelete} = props
  const classes = useOrderStyles()
  const lpClasses = useListPageStyles()

  const getExpressName = (express:string)=>{
    const result = EXPRESS_LIST.filter((item:any)=>item.code === express)
    return result.length>0 && result[0].name
  }

  if(list.length === 0){
    return <></>
  }
  else
  return <Box>
            <Box className={lpClasses.listTitle} style={{padding:8}}>
            <span className={classes.number} style={{marginRight:16}}>{list.length}条发货信息</span>
            </Box>
            <Box className={lpClasses.tableWrapper}>
                <Table
                className={lpClasses.table}
                aria-labelledby="tableTitle"
                size="medium">
              <TableHead
                fields={fields}
                rowCount={list ? list.length:0}
              />
                <TableBody>
                {list.map((row: any,index:number) => {
                    return (
                      <TableRow
                        tabIndex={-1}
                        key={index}
                      >
                        <BodyTableCell>
                        {row.mode === '0' && '快递'}
                        {row.mode === '1' && '自提或其它'}
                        </BodyTableCell>
                        <BodyTableCell>{getExpressName(row.express)} </BodyTableCell>
                        <BodyTableCell>{row.expressNo} </BodyTableCell>
                        <BodyTableCell>
                          {
                            row.details && <>
                            {row.details.map((item:SendDetail)=><SimpleDetailItem detail={item.orderDetail}/>)}
                            </>
                          }
                         </BodyTableCell>
                        <BodyTableCell>{formatDateTime2(row.sendTime)}</BodyTableCell>
                        <BodyTableCell align="right" className={lpClasses.rowToolbar}>
                          <TableRowToolbar
                            {...{
                              editLabel: '修改发货物流信息',
                              deleteLabel: '删除发货物流信息',
                              onEdit: () => {
                                 if(onEdit){
                                   onEdit(row)
                                 }
                              },
                              onDelete: () => {
                                if(onDelete){
                                  onDelete([row.id])
                                }
                              },
                            }} />
                        </BodyTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
}