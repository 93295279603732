import React, { useState, useEffect } from 'react'
import { stableSort, getSorting } from '../../../lib/sort'

import Select from 'react-select';
import Box from '@material-ui/core/Box';
import Icon from '../../../components/Icon'

import { withRouter } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableHead } from '../../../components/ui/table'
import PageToolbar from '../../../components/ui/PageToolbar'
import FormField from '../../../components/ui/form/FormField'

import { useNav } from '../../../provides/ui'
import { useLocale } from '../../../config/Locale'

import { useListPageStyles } from '../../../assets/jss/pages'
import { LoadingDialog, MessageDialog, AlertDialog } from '../../../components/ui/dialog'

import ListRow from "./ListRow"

import { Nav } from "../../../types/ui"


const fields = [
  { id: 'name', label: '导航名称' },
  { id: 'icon', label: '导航图标' },
  { id: 'link', label: '链接地址' },
  { id: 'display', label: '显示平台' },
  { id: 'dropdown', label: '下位列表' },
  { id: 'dropdownType', label: '下拉类型' },
  { id: 'dropdownOrientation', label: '下位列表排列' },
  { id: 'position', label: '导航位置' },
  { id: 'status', label: '状态' },
];

export default withRouter((props: any) => {
  const classes = useListPageStyles()
  const { translate } = useLocale()
  const { history } = props
  const { getList, remove } = useNav()
  // 切换
  const positions = [
    { value: "1", label: "主导航" },
    { value: "2", label: "底部导航" }
  ]
  const [position, setPosition] = useState<string>('')
  const getPosition = (value: string) => {
    const result = positions.filter((dt: any) => dt.value === value)
    return result && result.length > 0 ? result[0] : undefined
  } 

  // 原始列表
  const [originList, setOriginList] = useState<Nav[]>([])
  // 最终显示列表
  const [list, setList] = useState<Nav[]>([])
  // 关键字
  const [keyword, setKeyword] = useState<string>("")

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  // 过滤
  const search = (params?: any) => {
    let k = params && params.keyword
    let list = (params && params.list) || originList
    k = k ? k.trim().toLowerCase() : ""
    if (k) {
      setList(list.filter((item: Nav) => item.name.toLowerCase().indexOf(k) !== -1))
    } else {
      setList(list)
    }
  }
  // 获取列表
  const loadData = (pos?: string) => {
    fetchList(
      pos || position,
      (list: any) => {
        search({ list })
      })
  }
  // 获取列表
  const fetchList = (position: string, cb: (result: any) => void) => {
    // 获取列表  
    setLoading({
      open: true,
      text: '正在加载组件列表...'
    })
    getList({  
      params: {
        position
      },
      success: (list: any) => {
        setOriginList(list)
        cb(list)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setOriginList([])
        cb([])
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadData();
    return () => {
      // 返回空方法，否则会陷入无限循环
    };
  }, []);

  const [order, setOrder] = React.useState<"asc" | "desc">('asc');
  const [orderBy, setOrderBy] = React.useState('stock');
  const [selected, setSelected] = React.useState([] as string[])

  const handleRequestSort = (event: any, property: any) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = list && list.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleClick = (event: any, id: string) => {
    let newSelected = selected || [];
    const index = newSelected.indexOf(id)
    if (index > -1) {
      newSelected.splice(newSelected.indexOf(id), 1)
    } else {
      newSelected.push(id)
    }
    setSelected(Object.assign([], newSelected));
  }

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
        {/* 您正在删除数据! 此操作不可恢复，确定要继续么？ */}
        <Box>
          <Box>{translate('removeTips')}</Box>
          <Box>{translate('removeAsk')}</Box>
        </Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        setLoading({
          open: true,
          text: translate('ui.removing')//'正在删除商品...'
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadData()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
  }

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  const renderPageContent = (
    <div className={classes.pageContent}>
      <PageToolbar
        {...{
          keyword,
          onFilter: (keyword: string) => {
            setKeyword(keyword)
            search({ keyword })
          },
          title: <span>
            <span>{translate('ui.nav.manager')} </span>
            {selected && selected.length > 0 && <span>
              {selected && selected.length} {translate('selected')}
            </span>}
          </span>,
          numSelected: selected && selected.length,
          label: {
            add: translate("ui.nav.add")
          },
          onAddClick: () => {
            history.replace(`/ui/nav/edit?id=${Math.ceil(Math.random() * 9999)}`)
          },
          onDelClick: () => {
            handleDelete(selected)
          },
          onRefresh: () => {
            loadData()
          },
          tools: <Box style={{ width: 150 }}>
            <FormField>
              <Select
                value={getPosition(position)}
                placeholder="导航位置"
                onChange={(selectOption: any) => {
                  setPosition(selectOption.value)
                  loadData(selectOption.value)
                }}
                options={positions}
              />
            </FormField>
          </Box>
        }} />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead
            checkbox
            fields={fields}
            numSelected={selected && selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
          <TableBody>

            {stableSort(list, getSorting(order, orderBy))
              .map((row: Nav, index: number) => <ListRow key={index}
                row={row}
                index={index}
                isSelected={isSelected}
                onClick={(event: any) => {
                  handleClick(event, row.id)
                }}
                onEdit={() => {
                  history.replace(`/ui/nav/edit?id=${row.id}`)
                }}
                onRemove={() => handleDelete([row.id])} />)}
          </TableBody>
        </Table>
      </div>
      <LoadingDialog {...loading} />

      {list.length === 0 && !loading.open && (<Box className="error">
        <Icon name="Error" width={32} height={32} color="gray" />
        <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
      </Box>)}
    </div>)


  return (<Box className={classes.root}>

    {renderPageContent}

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </Box>
  )
})