import { Action } from '../../actions';
import * as Actions from '../../actions/ui/datatype';
import * as State from '../../state/ui';

export const initialState: State.Datatype = {
}; 

export const reducer = (state: State.Datatype = initialState, action: Action): State.Datatype => {
    switch (action.type) {
        case Actions.FETCH_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload.list
            };

        case Actions.FETCH_LIST_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

            case Actions.FETCH_ACTIVE_LIST_SUCCESS:
                return {
                    ...state,
                    list: action.payload.list
                };
    
            case Actions.FETCH_ACTIVE_LIST_FAILURE:
                return {
                    ...state,
                    error: action.payload.error
                };
    
        case Actions.FETCH_SUCCESS:
            return {
                ...state,
                datatype: action.payload.datatype
            };

        case Actions.FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.error
            };

        default: return state;
    }
};