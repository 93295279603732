import { AxiosRequestConfig } from "axios";
import { API } from '../../../config'

// Action Types
export type Action ={
      // 上传图片
      type: "CHAT_UPLOAD_IMAGES";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
    |{
      // 上传文件
      type: "CHAT_UPLOAD_FILES";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
    |{
      // 读取图片/文件
      type: "CHAT_GET_FILES";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
    |{
      // 下载文件
      type: "CHAT_DOWNLOAD";
      payload: { client: "default"; request: AxiosRequestConfig };
    };
    

    
export const CHAT_UPLOAD_IMAGES = "CHAT_UPLOAD_IMAGES";
export const uploadImages = (data:any,onProgress:Function): Action => ({
  type: CHAT_UPLOAD_IMAGES,
  payload: {
    client: 'default',
    request: {
      url: `${API}chat/message/uploadImages`,
      method:'POST',
      timeout: 1000*60*60*24,
      // `onUploadProgress` 允许为上传处理进度事件
      onUploadProgress: (event:any) =>{
        // 对原生进度事件的处理
        if(onProgress){
          onProgress(event)
        }
      },
      headers:{
        'Content-Type':'multipart/form-data'
      },
      data
    }
  }
})


export const CHAT_UPLOAD_FILES = "CHAT_UPLOAD_FILES";
export const uploadFiles = (data:any,onProgress:Function): Action => ({
  type: CHAT_UPLOAD_FILES,
  payload: {
    client: 'default',
    request: {
      url: `${API}chat/message/uploadFiles`,
      method:'POST',
      timeout: 1000*60*60*24,
      // `onUploadProgress` 允许为上传处理进度事件
      onUploadProgress: (event:any) =>{
        // 对原生进度事件的处理
        if(onProgress){
          onProgress(event)
        }
      },
      headers:{
        'Content-Type':'multipart/form-data'
      },
      data
    }
  }
})

export const CHAT_GET_FILES = "CHAT_GET_FILES";
export const getFiles = (data:any,onProgress:Function): Action => ({
  type: CHAT_GET_FILES,
  payload: {
    client: "default",
    request: { 
      url: `${API}chat/message/getFiles`,
      method: "POST",
      // `onDownloadProgress` 允许为下载处理进度事件
      onDownloadProgress:  (event:any) =>{
        // 对原生进度事件的处理
        if(onProgress){
            onProgress(event)
          }
      },
      data
    }
  }
});


export const CHAT_DOWNLOAD = "CHAT_DOWNLOAD";
export const download = (data:any,onProgress:Function): Action => ({
  type: CHAT_DOWNLOAD,
  payload: {
    client: "default",
    request: { 
      url: `${API}chat/message/download`,
      method: "POST",
      // `onDownloadProgress` 允许为下载处理进度事件
      onDownloadProgress:  (event:any) =>{
        // 对原生进度事件的处理
        if(onProgress){
            onProgress(event)
          }
      },
      data
    }
  }
});

