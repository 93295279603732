export const defaultOptions = [
  'heading',
  'fontWeight',
  'fontStyle',
  'underline',
  'lineThrough',
  'fontFamily',
  'lineHeight',
  'fontSize',
  'fontColor',
  'backgroundColor',
  'alignment',
  'code',
  'blockquote',
  'link',
  'media',
  'list',
  'emoji',
  'clear',
  'history',
  'screen',
  'preview'
]
const defaultEmojis = [
  "😀",
  "😁",
  "😂",
  "😃",
  "😉",
  "😋",
  "😎",
  "😍",
  "😗",
  "🤗",
  "🤔",
  "😣",
  "😫",
  "😴",
  "😌",
  "🤓",
  "😛",
  "😜",
  "😠",
  "😇",
  "😷",
  "😈",
  "👻",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "🙈",
  "🙉",
  "🙊",
  "👼",
  "👮",
  "🕵",
  "💂",
  "👳",
  "🎅",
  "👸",
  "👰",
  "👲",
  "🙍",
  "🙇",
  "🚶",
  "🏃",
  "💃",
  "⛷",
  "🏂",
  "🏌",
  "🏄",
  "🚣",
  "🏊",
  "⛹",
  "🏋",
  "🚴",
  "👫",
  "💪",
  "👈",
  "👉",
  "👉",
  "👆",
  "🖕",
  "👇",
  "🖖",
  "🤘",
  "🖐",
  "👌",
  "👍",
  "👎",
  "✊",
  "👊",
  "👏",
  "🙌",
  "🙏",
  "🐵",
  "🐶",
  "🐇",
  "🐥",
  "🐸",
  "🐌",
  "🐛",
  "🐜",
  "🐝",
  "🍉",
  "🍄",
  "🍔",
  "🍤",
  "🍨",
  "🍪",
  "🎂",
  "🍰",
  "🍾",
  "🍷",
  "🍸",
  "🍺",
  "🌍",
  "🚑",
  "⏰",
  "🌙",
  "🌝",
  "🌞",
  "⭐",
  "🌟",
  "🌠",
  "🌨",
  "🌩",
  "⛄",
  "🔥",
  "🎄",
  "🎈",
  "🎉",
  "🎊",
  "🎁",
  "🎗",
  "🏀",
  "🏈",
  "🎲",
  "🔇",
  "🔈",
  "📣",
  "🔔",
  "🎵",
  "🎷",
  "💰",
  "🖊",
  "📅",
  "✅",
  "❎",
  "💯"
]

type Props = {
  translate: Function,
  options?: string[],
  emojis?:string[],
  mediaPluginProps: any,
  setMediaPluginProps: Function,
  fullScreen: boolean,
  setFullScreen: Function,
  setPreview: Function
}
export const toolbarOptions = (props: Props) => {
  const { translate, options = defaultOptions,emojis = defaultEmojis, mediaPluginProps, setMediaPluginProps, fullScreen, setFullScreen, setPreview } = props
  const result: any = []

  const toolbarOption = (option: string) => {
    switch (option) {
      case 'heading':
        return { type: 'heading', title: [translate('editor.heading')] }
      case 'fontWeight':
        return { type: 'fontWeight', value: 'bold', icon: 'Bold', title: [translate('editor.bold')], float: true }

      case 'fontStyle':
        return { type: 'fontStyle', value: 'italic', icon: 'Italic', title: [translate('editor.italic')], float: true }

      case 'underline':
        return { type: 'textDecoration', value: 'underline', icon: 'Underline', title: [translate('editor.underline')], float: true }

      case 'lineThrough':
        return { type: 'textDecoration', value: 'line-through', icon: 'Strikethrough', title: [translate('editor.strikethrough')], float: true }

      case 'fontFamily':
        return { type: 'fontFamily', title: [translate('editor.fontFamily')], options: ['宋体', '仿宋体', '黑体', '隶书', '微软雅黑', '楷体', '行楷', '幼圆', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'] }

      case 'lineHeight':
        return { type: 'lineHeight', title: [translate('editor.lineHeight')], options: ['16px', '22px', '26px', '32px', '36px', '42px', '50px', '60px', '72px'] }

      case 'fontSize':
        return { type: 'fontSize', title: [translate('editor.fontSize')] }

      case 'fontColor':
        return { type: 'fontColor', title: [translate('editor.fontColor')] }

      case 'backgroundColor':
        return { type: 'backgroundColor', title: [translate('editor.backgroundColor')] }

      case 'alignment':
        return { type: 'alignment', title: [translate('editor.alignLeft'), translate('editor.alignCenter'), translate('editor.alignRight')], float: true }

      case 'code':
        return { type: 'code', title: [translate('editor.code')] }

      case 'blockquote':
        return { type: 'blockquote', title: [translate('editor.blockquote')] }

      case 'link':
        return { type: 'link', title: [translate('editor.link.add'), translate('editor.link.remove')] }

      case 'media':
        return {
          type: 'media', title: [
            translate('editor.media.image'),
            translate('editor.media.video'),
            translate('editor.media.audio')],
          mediaPluginProps,
          setMediaPluginProps
        }

      case 'list':
        return {
          type: 'list', title: [
            translate('editor.orderList'),
            translate('editor.unOrderList'),
            translate('editor.indent'),
            translate('editor.outdent')
          ]
        }

        case 'emoji':
          return { type: 'emoji',  icon: 'Emoji', title: [translate('editor.emoji')],emojis }
  
      case 'clear':
        return { type: 'clear', title: [translate('editor.clear')] }

      case 'history':
        return {
          type: 'history', title: [
            translate('editor.history.undo'),
            translate('editor.history.redo')
          ]
        }

      case 'screen':
        return {
          type: 'screen', fullScreen, setFullScreen, title: [
            translate('editor.fullScreen'),
            translate('editor.fullScreenExit')
          ]
        }

      case 'preview':
        return {
          type: 'preview',
          onOpen: () => {
            setPreview(true)
          }, title: [
            translate('editor.preview')
          ]
        }
      default:
        break;
    }
  }
  options.map((option: string) => result.push(toolbarOption(option)))
  return result
}