import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as commonTypes from '../../../types/common'


// Action Types
export type Action = {
    // 广告列表
    type:'COMMON_AD_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type:'COMMON_AD_FETCH_LIST_SUCCESS', payload: { list: commonTypes.Ad[] ,pagination:types.Pagination} }
    | { type:'COMMON_AD_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        //  启用广告列表
        type:'COMMON_AD_FETCH_ACTIVE_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type:'COMMON_AD_FETCH_ACTIVE_LIST_SUCCESS', payload: { list: commonTypes.Ad[] ,pagination:types.Pagination } }
    | { type:'COMMON_AD_FETCH_ACTIVE_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 广告
        type:'COMMON_AD_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type:'COMMON_AD_FETCH_SUCCESS', payload: any }
    | { type:'COMMON_AD_FETCH_FAILURE', payload: { error: types.Error } }
    |  {
        // 保存广告
        type:'COMMON_AD_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 删除广告
        type:'COMMON_AD_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 设置状态
        type:'COMMON_AD_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 按ID查询广告列表
        type:'COMMON_AD_FETCH_LIST_BY_IDS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };




// 广告列表
export const FETCH_LIST = 'COMMON_AD_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'COMMON_AD_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'COMMON_AD_FETCH_LIST_FAILURE';
export const fetchList = (params: any): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/getList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchListSucess = (list: commonTypes.Ad[], pagination:types.Pagination): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});


// 按ID获取广告列表
export const FETCH_LIST_BYIDS = 'COMMON_AD_FETCH_LIST_BY_IDS'
export const fetchListByIds = (id: any): Action => ({
    type: FETCH_ACTIVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/getListByIds`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})

// 上架广告列表
export const FETCH_ACTIVE_LIST = 'COMMON_AD_FETCH_ACTIVE_LIST'
export const FETCH_ACTIVE_LIST_SUCCESS = 'COMMON_AD_FETCH_ACTIVE_LIST_SUCCESS';
export const FETCH_ACTIVE_LIST_FAILURE = 'COMMON_AD_FETCH_ACTIVE_LIST_FAILURE';
export const fetchActiveList = (params: any): Action => ({
    type: FETCH_ACTIVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/getActiveList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchActiveListSucess = (list: commonTypes.Ad[],pagination:types.Pagination): Action => ({
    type: FETCH_ACTIVE_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchActiveListFailure = (error: types.Error): Action => ({
    type: FETCH_ACTIVE_LIST_FAILURE,
    payload: {
        error
    }
});



// 广告
export const FETCH = 'COMMON_AD_FETCH'
export const FETCH_SUCCESS = 'COMMON_AD_FETCH_SUCCESS';
export const FETCH_FAILURE = 'COMMON_AD_FETCH_FAILURE';
export const fetch = (id: any): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/get`,
            method: 'GET',
            params: {
                id
            }
        }
    }
})
export const fetchSucess = (ad: commonTypes.Ad): Action => ({
    type: FETCH_SUCCESS,
    payload: {
        ad
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});


// 保存
export const SAVE = 'COMMON_AD_SAVE'
export const save = (ad: commonTypes.Ad): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/save`,
            method: 'POST',
            data: {
                ad
            }
        }
    }
})



// 删除
export const REMOVE = 'COMMON_AD_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})



// 设置状态
export const SET_STATUS = 'COMMON_AD_SET_STATUS'
export const setStatus = (data: any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/ad/setStatus`,
            method: 'POST',
            data
        }
    }
})