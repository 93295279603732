import React from 'react';
import clsx from 'clsx';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Icon from '../../components/Icon'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow:{
            flexGrow:1
        },
        listItem: {
            paddingLeft: 0,
            padding: theme.spacing(1,1,1,0),
            fontFamily:"inherit"
        },
        listItemBox: {
            padding: theme.spacing(1,1,1,0),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        },
        listItemBoxActive:{
            background:"#243448"
        },
        listItemText: {
            color:"#BFCBD9",
            paddingLeft: 0,
            paddingRight: 0,
            fontSize: '14px'
        },
        listItemTextPopper: {
            paddingRight: 30
        },
        active: {
            // fontWeight: 'bold',
            color: '#2B7BFF',
        },
        sidebarIsOpen: {
            paddingLeft: 0,
            paddingRight: 0,
            transition: 'padding-left 295ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
        },
        sidebarIsClosed: {
            paddingLeft: 0,
            paddingRight: 0,
            transition: 'padding-left 295ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
        icon: {
            minWidth: 26,
            paddingLeft: '5px',
        },
        iconOnly: {
            paddingTop: '10px',
            paddingBottom: '10px',
            minWidth: '26px',
        },
        arrowDownIcon: {
          transition: "all 150ms ease-in", 
          transform: "rotate(-90deg)",
          margin:0,
          padding:0,
          color:"#BFCBD9"
        },
        arrowDownIconActive: {
          transform: "rotate(0deg)",
          color:"#BFCBD9"
        }, 
    }))

const SubMenuItem = (props: any) => {
    const {
        handleOpens,
        sidebarIsOpen,
        active,
        isOpen,
        title,
        icon,
        paddingLeft,
        // 弹框元素
        isTop,
        anchorId,
        popoverShow
    } = props
    const classes = useStyles()

    return (
        <ListItem
            dense
            button
            onClick={handleOpens}
            className={clsx(classes.listItem,{
                [classes.listItemBoxActive]:active
            })}
        >
            <Box
                onMouseDown={popoverShow && popoverShow(anchorId)}
                className={classes.listItemBox}
                style={{ paddingLeft: `${paddingLeft - (!sidebarIsOpen && paddingLeft > 20 ? 15 : 0)}px` }}>
                <Tooltip title={title}>
                    <ListItemIcon
                        className={sidebarIsOpen  || !isTop ? classes.icon : classes.iconOnly}
                        aria-describedby={anchorId}>
                    {isOpen ?    
                        <ExpandMore className={clsx({
                        [classes.arrowDownIcon]: true,
                        [classes.arrowDownIconActive]: isOpen
                      })} />:
                      <Icon name={icon} width={18} height={18} color={active ? '#2B7BFF' : '#BFCBD9'} />
                    }
                    </ListItemIcon>
                </Tooltip>
                <Box
                    component="span"
                    className={clsx(classes.listItemText, {
                        [classes.active]: active,
                        [classes.listItemTextPopper]: !isTop
                    })}
                >{sidebarIsOpen || !isTop ? title : ''}</Box>
            </Box>
        </ListItem>
    );
}

export default SubMenuItem;
