import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="18597"
    width={width} height={height}><path fill={color} d="M640 470.016l0-86.016q0-36.010667-25.984-61.013333t-59.989333-25.002667l-169.984 0 0 86.016 169.984 0 0 86.016-84.010667 0q-36.010667 0-61.013333 24.021333t-25.002667 59.989333l0 171.989333 256 0 0-86.016-169.984 0 0-86.016 84.010667 0q36.010667 0 61.013333-24.021333t25.002667-59.989333zM809.984 128q34.005333 0 59.989333 25.984t25.984 59.989333l0 596.010667q0 34.005333-25.984 59.989333t-59.989333 25.984l-596.010667 0q-34.005333 0-59.989333-25.984t-25.984-59.989333l0-596.010667q0-34.005333 25.984-59.989333t59.989333-25.984l596.010667 0z" p-id="18598"></path></svg>)
