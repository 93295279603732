import React from 'react';
import { useSysState } from '../provides/sys'


export default () => {
    const { account } = useSysState()
    return (
        <div>

      username:{account && account.user && account.user.username}<br />
      appType:{process.env.REACT_APP_appType}<br />
      version:{process.env.REACT_APP_version}<br />
      moduleId:{process.env.REACT_APP_moduleId}<br />
      appId:{process.env.REACT_APP_appId}<br />
      attachPrefix:{process.env.REACT_APP_attachPrefix}<br />
    
        </div>  
    )
}