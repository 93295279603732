import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useOrderStyles = makeStyles((theme: Theme) => createStyles({
  grow:{
      flexGrow:1
  },
  backgroundImageStyles:{
      backgroundRepeat:'no-repeat',
      backgroundPosition: "50%",
      backgroundSize: "auto 100%",
        transition: 'all 200ms linear',
      '&:hover':{
        /* 设置默认样式，开启3d硬件加速 */
        WebkitTransform:"translate3d(0,0,0)",
        MozTransform:"translate3d(0,0,0)",
        transform:"translate3d(0,0,0)",
        transition: 'all 300ms linear',
        backgroundSize: "auto 110%",
      }
  },
  clearfix:{
    clear:"both"
  },
  pageTitle:{
    display:"flex",
    fontWeight:500,
    fontSize:"1.2em",
    padding:theme.spacing(1),
    color:"#ff6600",
    alignItems:"center",
    "@media (max-width:650px)": {
      flexDirection:"column"
    },
  },
  tableToolbar:{
    display:"flex",
    alignItems:"center",
    margin:theme.spacing(1,0,1,0),
    background:"#f5f5f5",
  },
  href:{
    color:"#484848",
    textDirection:"none",
    '&:hover':{
      color:"#ff6600"
    }
  },
  number:{
    fontWeight:600,
    color:"#ff0000"
  },
  amount:{
    fontSize:'1.2em',
    fontWeight:600,
    color:"#ff0000"
  },
  bar:{
    display:"flex",
    alignItems:"center"
  },
  barText:{
    textAlign:"right",
    padding:theme.spacing(1),
    grow:1
  },
  submitButton:{
    fontWeight:500,
    fontSize:"1.5em",
    padding:theme.spacing(1,5),
    background:"#ff6600",
    color:"#fff",
    borderRadius:0,
    '&:hover':{
      background:"#ff0000"
    }
  },
  radioGroup:{
    paddingLeft:16,
    display:"flex",
    alignItems:"center"
  },
  radio:{
    display:"flex",
    alignItems:"center"
  },

  productInfo:{
    display:"flex",
    alignItems:"center"
  },
  image:{
      borderRadius:3,
      backgroundRepeat:'no-repeat',
      backgroundPosition: "50%",
      backgroundSize: "auto 100%",
        transition: 'all 200ms linear',
      '&:hover':{
        /* 设置默认样式，开启3d硬件加速 */
        WebkitTransform:"translate3d(0,0,0)",
        MozTransform:"translate3d(0,0,0)",
        transform:"translate3d(0,0,0)",
        transition: 'all 300ms linear',
        backgroundSize: "auto 110%",
      },
    width:70,
    height:70,
    minWidth:70,
    minHeight:70,
    overflow:"hidden"
  },
  title:{
    padding:theme.spacing(0,1),
    width:350,
    '& a':{
      color:"#888",
      textDecoration:'none',
      '&:hover':{
        color:"#333"
      }
    }
  }, 
  spec:{
    padding:theme.spacing(0,1)
  },
  block:{
    margin:theme.spacing(1,0),
    borderRadius:4,
    border:"1px solid #dedede"
  },
  blockTitle:{
    display:"flex",
    padding:theme.spacing(1)
  },
  blockTitleLabel:{
    display:"flex",
    alignItems:"center",
    color:"#484848",
    fontWeight:600,
  },
  blockContent:{
    padding:theme.spacing(1)
  },
        item:{
            whiteSpace:"nowrap"
        },
        label:{
            color:"#777"
        },
        iconButton:{
            minWidth:22
        },
        icon:{
            color:"#888"
        },
        disabled:{
          color:"#999",
          background:"#ddd"
        },
        word:{
          marginRight:4,
          '&:last-child':{
            marginRight:0,
          }
        }
}));
