import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useLocale } from '../../config/Locale';
import FormField from '../ui/form/FormField'
import CatTreeDialog from './CatTreeDialog'
import { useCat } from '../../provides/content/CatProvide'

import { makeStyles, createStyles } from '@material-ui/core/styles';

import Icon from '../Icon'

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      padding: "0 8px 0 0"
    },
    tip: {
      padding: "5px 3px 0 0"
    }
  }))

export type CatInputProps = {
  editing?: boolean,
  label?: string,
  moduleId?: string,
  notShowAll?: boolean,
  value?: any,
  labelWidth?: number,
  onChange: (value: any, names: string) => void,
  showLabel?: boolean,
  children?: any
}
export default function CatInput(props: CatInputProps) {
  const { label, labelWidth, onChange, value, notShowAll, moduleId, showLabel = true, children, editing = true } = props
  const [open, setOpen] = useState(false)
  const { translate } = useLocale()
  const [names, setNames] = useState()
  const classes = useStyles()

  const { getNames } = useCat()
  // 查询分类名称，含父级分类
  const queryNames = () => {
    if (value && !names) {
      getNames({
        id: value,
        success: (names: string) => {
          setNames(names)
        },
        failure: (message: string) => {
        }
      })
    } else if (!value) {
      setNames('')
    }
  }
  useEffect(queryNames);

  if (!editing) {
    return <FormField
      labelWidth={showLabel ? labelWidth : 0}
      label={showLabel ? `${label}` : ""}>
      {names}
    </FormField>
  }

  return (<FormField
    labelWidth={showLabel ? labelWidth : 0}
    label={showLabel ? `${label || ""}` : ""}>
    <Box>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true)
        }}
      >
        <Icon name="Tree" className={classes.icon} color="#fff" />
        {translate('chooseCat')}
      </Button>
    </Box>
    <Box className={classes.tip}>
      {names}
    </Box>
    {children}
    {open && <CatTreeDialog
      {...{
        moduleId,
        notShowAll,
        open,
        currentId: value,
        ok: (item: any, names: string) => {
          onChange(item, names)
          setNames(names)
          setOpen(false)
        },
        cancel: () => {
          setOpen(false)
        }
      }} />
    }
  </FormField>);
}

