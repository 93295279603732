import React from "react";

import MuiCircularProgress from '@material-ui/core/CircularProgress';

import { createStyles, withStyles } from "@material-ui/core/styles";

export const CircularProgress = withStyles(() =>
    createStyles({
        root: {
            color: '#666',
            animationDuration: '550ms',
        }
    }))(MuiCircularProgress)

export default (props: any) => {

    return  <CircularProgress
        variant="indeterminate"
        disableShrink
        size={24}
        thickness={5}
        {...props}
    />
}
