import React from "react"

import classNames from "classnames"
import { useUIInputsStyles, useUIPageStyles } from "../../../assets/jss/pages"

import Box from '@material-ui/core/Box';

import Icon from "../../../components/Icon"
import FormField from '../../../components/ui/form/FormField'
import ArticleCatInput from '../../../components/content/CatInput';
import ProductCatInput from '../../../components/shopping/CatInput';
import InputListItem from "../page/inputs/InputListItem"
import InputList from "../page/inputs/InputList"
import OptionInput from "../page/inputs/components/OptionInput"
import OptionField from "../page/inputs/components/OptionField"

import ItemTools from "../page/inputs/components/ItemTools"
import { NavDropdown } from "../../../types/ui"
import { ImageLink, ImageTextListItem } from "../page/inputs/types"

type Props = {
    position?: "1" | "2",
    editing?: boolean,
    dropdown?: NavDropdown,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave?: (item: any) => void,
    onRemove?: (item: any) => void,
}
/** 标签页输入 */
export default (props: Props) => {
    const { dropdown, showUp, showDown, sortUp, sortDown, onSave, onRemove, editing = true, position } = props
    const classes = useUIInputsStyles()
    const pageClasses = useUIPageStyles()

    const [form, setForm] = React.useState<any>(dropdown)
    const setFormItem = (key: string, value: any) => {
        form[key] = value;
        setForm(Object.assign({}, form))
    }

    const dataInput = () => {
        switch (form.datatype) {
            case "imageLinks":
                return <InputList
                    editing={editing}
                    options={[
                        {
                            id: "1",
                            name: 'image',// 类型名称
                            alias: '图片',// 别名
                            variableType: 'image',// 变量类型
                            notNull: true,// 非空
                        },
                        {
                            id: "2",
                            name: 'title',// 类型名称
                            alias: '标题',// 别名
                            variableType: 'string',// 变量类型
                            notNull: true,// 非空
                        },
                        {
                            id: "4",
                            name: 'link',// 类型名称
                            alias: '链接地址',// 别名
                            variableType: 'link',// 变量类型
                            notNull: false,// 非空
                        }]}
                    data={form.imageLinks || []}
                    edittable
                    onChange={(data: ImageLink[]) => {
                        setFormItem("imageLinks", data)
                    }} />
            case "imageTexts":
                return <InputList
                    editing={editing}
                    options={[
                        {
                            id: "1",
                            name: 'image',// 类型名称
                            alias: '图片',// 别名
                            variableType: 'image',// 变量类型
                            notNull: true,// 非空
                        },
                        {
                            id: "2",
                            name: 'title',// 类型名称
                            alias: '标题',// 别名
                            variableType: 'string',// 变量类型
                            notNull: true,// 非空
                        },
                        {
                            id: "3",
                            name: 'subtitle',// 类型名称
                            alias: '副标题',// 别名
                            variableType: 'string',// 变量类型
                            notNull: false,// 非空
                        },
                        {
                            id: "4",
                            name: 'link',// 类型名称
                            alias: '链接地址',// 别名
                            variableType: 'link',// 变量类型
                            notNull: false,// 非空
                        }]}
                    data={form.imageTexts || []}
                    edittable
                    onChange={(data: ImageTextListItem[]) => {
                        setFormItem("imageTexts", data)
                    }} />
            case "articleCats":
                return <ArticleCatInput
                    labelWidth={150}
                    editing={editing && form.editing}
                    label="上级文章分类："
                    value={form.parentCatId}
                    onChange={(item: any) => {
                        setFormItem("parentCatId", item.id)
                    }} >
                </ArticleCatInput>
            case "productCats":
                return <ProductCatInput
                    editing={editing && form.editing}
                    labelWidth={150}
                    label="上级商品分类："
                    value={form.parentCatId}
                    onChange={(item: any) => {
                        setFormItem("parentCatId", item.id)
                    }} >
                </ProductCatInput>

            case "articleCat":
                return <ArticleCatInput
                    labelWidth={150}
                    editing={editing && form.editing}
                    label="文章分类："
                    value={form.catId}
                    onChange={(item: any) => {
                        setFormItem("catId", item.id)
                    }} >
                </ArticleCatInput>
            case "productCat":
                return <ProductCatInput
                    editing={editing && form.editing}
                    labelWidth={150}
                    label="商品分类："
                    value={form.catId}
                    onChange={(item: any) => {
                        setFormItem("catId", item.id)
                    }} >
                </ProductCatInput>
            default:
                return <FormField labelWidth={150}>
                    <Box className={pageClasses.alertBox}>
                        <Icon name="Alert" className={pageClasses.icon} />
                        请选择下拉数据类型!
                    </Box>
                </FormField>
        }
    }

    const datatypeOption = {
        id: "0",
        name: 'datatype',// 类型名称
        alias: '数据类型',// 别名
        variableType: 'enum',// 变量类型
        variableValues: '[' +
            '{"label":"图片链接列表","value":"imageLinks"},' +
            (position !== "2" ? '{"label":"图文列表","value":"imageTexts"},' : '') +
            '{"label":"文章分类列表","value":"articleCats"},' +
            '{"label":"商品分类列表","value":"productCats"},' +
            '{"label":"文章分类","value":"articleCat"},' +
            '{"label":"商品分类","value":"productCat"}]',
        notNull: false,// 非空
    }
    const getDatatypeLabel = (value: string) => {
        switch (value) {
            case "imageLinks":
                return "图片链接列表"
            case "imageTexts":
                return "图文列表"
            case "articleCats":
                return "文章分类列表"
            case "productCats":
                return "商品分类列表"
            case "articleCat":
                return "文章分类"
            case "productCat":
                return "商品分类"
        }
        return ""
    }

    return <Box className={classNames(classes.listItem, {
        [classes.editing]: form.editing
    })}>
        <Box className={classes.itemFields}>

            {
                editing && form.editing ?
                    <OptionInput
                        option={datatypeOption}
                        value={form.datatype}
                        onChange={(name: string, value: any) => {
                            setFormItem(name, value)
                        }} /> :
                    <OptionField
                        option={datatypeOption}
                        value={getDatatypeLabel(form.datatype)} />
            }

            <InputListItem
                key={0}
                editing={editing && form.editing}
                option={{
                    id: "0",
                    name: 'title',// 类型名称
                    alias: '列表标题',// 别名
                    variableType: 'string',// 变量类型
                    notNull: false,// 非空
                }}
                value={form.title}
                onChange={(name: string, value: any) => {
                    setFormItem('title', value)
                }} />

            {dataInput()}

        </Box>
        {editing &&
            <ItemTools
                editing={form.editing}
                onSave={() => {
                    setFormItem("editing", false);
                    if (onSave) onSave(form);
                }}
                onCancel={() => setFormItem("editing", false)}
                onEditing={() => setFormItem("editing", true)}
                onRemove={() => {
                    if (onRemove) onRemove(form);
                }}
                showUp={showUp}
                showDown={showDown}
                sortUp={sortUp}
                sortDown={sortDown} />}
    </Box>
}