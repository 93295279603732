import {
  scrolling,
  grow,
  arrowDownIcon,
  arrowDownIconActive,
  backgroundImageStyles
} from "../baseStyles";

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useListStyles = makeStyles((theme: Theme) => createStyles({
  scrolling,
  grow,  
  arrowDownIcon,
  arrowDownIconActive,
  listHeader:{
    display:"flex",
    alignItems:"center",
    padding:theme.spacing(0),
    borderBottom:"1px solid #dedede",
    "@media (max-width:650px)": {
      display:"none"
    },
  },
  borderRow:{
    display:"flex",
    alignItems:"center",
    padding:theme.spacing(0),
    borderBottom:"1px solid #dedede",
  },
  listHeaderTexts:{
    display:"flex",
    alignItems:"center",
    padding:theme.spacing(1),
  },
  listContent:{
    padding:theme.spacing(1,0),
  },
  listItem:{
    display:"flex",
    flexDirection:"column",
    overflow:"hidden",
    borderBottom:"1px solid #dedede",
    "@media (min-width:680px)": {
      margin:theme.spacing(1,0),
      border:"1px solid #dedede",
      borderRadius:3
    },
  },
  productInfo:{
    display:"flex",
    alignItems:"center",
    margin:theme.spacing(1)
  },
  image:{
      borderRadius:3,
    ...backgroundImageStyles,
    width:70,
    height:70,
    minWidth:70,
    minHeight:70,
    overflow:"hidden"
  },
  listItemTitle:{
    padding:theme.spacing(1),
    display:"flex",
    alignItems:"center",
    borderBottom:"1px solid #dedede",
    '&>div':{
      marginRight:theme.spacing(1),
      '&:last-child':{
       marginRight:0 
      }
    }
  },
  listItemFooter:{
    padding:theme.spacing(1),
    display:"flex",
    alignItems:"center",
    borderTop:"1px solid #dedede",
    '&>div':{
      marginRight:theme.spacing(1),
      '&:last-child':{
       marginRight:0 
      }
    }
  },
  title:{
    padding:theme.spacing(0,1),
    // width:350
  },
  listPrice:{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
    minWidth:100
  },
  href:{
    color:"#484848",
    textDirection:"none",
    '&:hover':{
      color:"#ff6600"
    }
  },
  button:{
    padding:"6px 12px",
    cursor:"pointer",
    color:"#fff",
    margin:theme.spacing(0,1,0,0),
    borderRadius:4,
    fontWeight:500
  },
  yellowButton:{ 
    background:"#ff6600",
    '&:hover':{
      background:"#ff0000"
    }
  },
  blueButton:{
      background:"#3F92D2",
      '&:hover':{
      background:"#66A3D2",
      }  
  },
  grayButton:{ 
      color:"#666",
      background:"#f1f2f3",
      '&:hover':{
      color:"#333",
            background:"#efefef",
       }
  },
  spec:{
    padding:theme.spacing(0,1)
  },
  item:{
      whiteSpace:"nowrap"
  },
  label:{
      color:"#777"
  },
  detailItem:{
    borderBottom:"1px solid #dedede",
    '&:last-child':{
      borderBottom:'none'
    }
  }
}));
