import React,{useState,useEffect} from 'react';

import { RichUtils } from 'draft-js';
import { getSelectedBlocksType } from '../utils';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OptionModal from './OptionModal'

import * as types from './types'

const defaultOptions = [
    { label: '标题一', value: 'heading-one', nodeName: 'h1' },
    { label: '标题二', value: 'heading-two', nodeName: 'h2' },
    { label: '标题三', value: 'heading-three', nodeName: 'h3' },
    { label: '标题四', value: 'heading-four', nodeName: 'h4' },
    { label: '标题五', value: 'heading-five', nodeName: 'h5' },
    { label: '标题六', value: 'heading-six', nodeName: 'h6' },
    { label: '正文', value: 'unstyled', nodeName: 'p' },
    { label: '段落', value: 'paragraph', nodeName: 'p' }
]
const getBlocksType = (value:string)=>{
    const result:any = defaultOptions.filter((option:any)=>option.value === value)
    if(result && result.length > 0 )
        return  result[0].label;
    return ''
}

const Heading = (props: any) => {
    const {
        onChange,
        editorState,
        title,
        options
    } = props

    const [currentBlockType,setCurrentBlockType] = useState('')
    const query = () => {
        const value = getSelectedBlocksType(editorState)
        const type = getBlocksType(value)
        setCurrentBlockType(type)
    }
    useEffect(query)
    // useEffect(() => {
    //     editorWrapper.current.addEventListener('click', query);
    //     return () => { 
    //          editorWrapper.current.removeEventListener('click', query);
    //      };
    
    // }, [currentBlockType])


    const anchorRef = React.useRef(null as any);
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    function handleToggle() {
        setAnchorEl(anchorEl ? null : anchorRef.current);
    }

    const toggle = (event: any, value: string) => {
        event.preventDefault();
        onChange(RichUtils.toggleBlockType(
            editorState,
            value,
        ));
    }

    return (<>
        <ButtonGroup
            className="toolbar-button-group"
            ref={anchorRef as any} >
            <Button
                size="small"
                onMouseDown={handleToggle}
                className="toolbar-button-toogle-button"
                title={title[0]} >
                {currentBlockType || title[0]}
                <ArrowDropDownIcon style={{width:16,height:16}} />
            </Button>
        </ButtonGroup>

        <OptionModal {...{
            anchorEl,
            options: options || defaultOptions,
            select: (event: any, option: types.Option) => {
                toggle(event, option.value)
                setAnchorEl(null);
            },
            cancel: () => {
                setAnchorEl(null);
            }
        }}>
        </OptionModal>
    </>
    );

}

export default Heading