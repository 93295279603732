import React, { useState, useEffect } from "react";

import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import { useConfig } from "../provides/app";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Main from "./Main";
import { useRoutesState } from "../provides/app";

import { sideBarWidth, sideBarCloseWidth } from "./size";
import ChatDialog from '../pages/chat/ChatDialog'

const Index = (props: any) => {
  const { children, location } = props;
  const [pathname, setPathname] = useState<string>("");
  const { openPage } = useConfig();

  const routesState = useRoutesState();
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);
  const [mainWidth, setMainWidth] = useState<number | string>("auto");
  
  const [chatDialogProps,setChatDialogProps] = useState<any>({
    open:false,
    close:()=>{}
  })

  // 页面尺寸变化
  const toggleResize = (sidebarIsOpen: boolean) => {
    setMainWidth(
      window.innerWidth - (sidebarIsOpen ? sideBarWidth : sideBarCloseWidth)
    );
  };
  const handleResize = () => {
    toggleResize(sidebarIsOpen);
  };

  useEffect(() => {
    if (pathname !== location.pathname) {
      setPathname(location.pathname);
      openPage(location.pathname);
    }
  });
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // 切换侧栏展开和收缩
  const toggleSidebarOpen = () => {
    setSidebarIsOpen(!sidebarIsOpen);
    toggleResize(!sidebarIsOpen);
    window.addEventListener("resize", () => toggleResize(!sidebarIsOpen));
  };

  return (
    <>
      <Box className="layout">
        <Box className="screen">
          <Sidebar sidebarIsOpen={sidebarIsOpen} />
              
        <Box id="screenMain"
          style={{
            width:mainWidth,
            overflow:"auto",
            paddingBottom: '25px'
          }}
          >
          <Box className="layout-header">
            <Header
              toggleSidebarOpen={toggleSidebarOpen}
              sidebarIsOpen={sidebarIsOpen}
              onChat={()=>{
                setChatDialogProps({
                  open:true,
                  close:()=>{
                    setChatDialogProps({
                      open:false,
                      close:()=>{}
                    })
                  }
                })
              }}
            />
          </Box>
          <Main
            width={mainWidth}
            {...{
              tabIndex: routesState.currentRoutes.tabIndex,
              currentRoute: routesState.currentRoutes.route,
              currentRoutes: routesState.currentRoutes.routes,
            }}
          >
            {children}
            
          <Box 
          className="footer"
          style={{
            width:mainWidth,
            overflow:"hidden"
          }}>
           <Box style={{background: "#fff"}}>
            技术支持：J2MVC ver1.0
            </Box> 
          </Box>
          </Main>
        </Box>
        </Box>
      </Box>
      {chatDialogProps.open && <ChatDialog {...chatDialogProps}/>}
    </> 
  );
};
export default withRouter(Index);
