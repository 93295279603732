import React from 'react';

import * as types from './types'
import Popover from '@material-ui/core/Popover';

export type ModalProps = {
    styleName?: string,
    options: types.Option[],
    value?: string,
    anchorEl: any,
    select: (event: any, option: any) => void,
    cancel: () => void
}
const OptionModal = (props: any) => {
    const { styleName, options, anchorEl, select, cancel } = props
    const stopPropagation = (event: any) => {
        event.stopPropagation();
    };

    const renderNode = (nodeName: any, style: any, label: string) => {
        if (nodeName) {
            switch (nodeName.toLowerCase()) {
                case 'h1':
                    return <h1>{label}</h1>
                case 'h2':
                    return <h2>{label}</h2>
                case 'h3':
                    return <h3>{label}</h3>
                case 'h4':
                    return <h4>{label}</h4>
                case 'h5':
                    return <h5>{label}</h5>
                case 'h6':
                    return <h6>{label}</h6>
                default:
                    return <p>{label}</p>
            }
        } else if (style) {
            return <span style={style}>{label}</span>
        }
        return label
    }

    const renderItem = (option: types.Option) => {
        let style
        if (styleName) {
            style = { [styleName]: option.value }
        }
        return renderNode(option.nodeName, style, option.label)
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={cancel}
            onClick={stopPropagation}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className="option-editor-modal"
        >
            <>
                {options && options.map((option: any) => (
                    <div
                        className="editor-option-item"
                        key={option.value}
                        onMouseDown={(event: any) => { select && select(event, option) }}
                    >
                        {renderItem(option)}
                    </div>
                ))}
            </>
        </Popover>
    );

}

export default OptionModal