import React from 'react';
import Box from '@material-ui/core/Box';

import { useChat } from '../../provides/chat'

import { useChatStyles } from '../../assets/jss/components'
  
import LoadingBox from "../../components/ui/LoadingBox"

type Props = {
  id:string
}

export default (props:Props) => {
  const { id } = props
    const classes = useChatStyles() 

    const {
      getFiles
    } = useChat()

    const [message,setMessage] = React.useState<any>({})
    
    const [downloadPrecent,setDownloadPrecent] = React.useState<string>()
    const [images,setImages] = React.useState<string[]>([])
    
    React.useEffect(()=>{
      setMessage({
        type:"loading",
        text:"加载图片..."
      });
      getFiles({
        data:{
          id
        },
        success:(result:any)=>{
          setImages(result.images)
          setMessage({
            type:"",
            text:""
          });
        },
        failure:(message:string)=>{
          setMessage({
            type:"error",
            text:message || "图片加载失败！"
          });
        },
        onProgress:(event:any)=>{
          if (event.lengthComputable) {
            //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
            //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
            // callback(progressEvent);
            //{{Math.ceil(precent)}}%
            // precent.toFixed(2)
            const loaded = event.loaded;
            const total = event.total;
            const precent = (loaded / total) * 100;
            setDownloadPrecent(precent+"%")
          }
        }
      });
    },[])


    return (
      <Box className={classes.images}>
          {images.map((image:string,index:number)=><Box 
        key={index} 
        className={classes.image}>
          <img src={'data:image/png;base64,'+image}/>
        </Box>
        )}
         {message.type === 'loading' && <LoadingBox open={true} text={`${message.text || ''} ${downloadPrecent || ''}`}/>}
         {message.type === 'success' && <Box className={classes.success}>{message.text}</Box>}
         {message.type === 'error' && <Box className={classes.error}>{message.text}</Box>}
         {message.type === 'info' && <Box className={classes.info}>{message.text}</Box>}
      
       </Box>
    )
}