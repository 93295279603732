import React from "react"

import classNames from "classnames"
import { useUIInputsStyles } from "../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import Dropdown from "./Dropdown"
import { useItemMethods } from '../page/inputs/components/Util';

import { NavDropdown } from "../../../types/ui"

type Props = {
  position?:"1"|"2",
  editing?: boolean,
  data?: NavDropdown[],
  onChange?: (data: NavDropdown[]) => void
}

export default (props: Props) => {
  const { data = [], onChange, editing = true ,position} = props
  const classes = useUIInputsStyles()

  const {
    onItemAdd,
    onItemSave,
    onItemRemove,
    sortUp,
    sortDown
  } = useItemMethods({
    data,
    initialData: {
      title: "",
      datatype: "",
      catId: "",
    },
    onChange: (data: any) => {
      if (onChange) {
        onChange(data)
      }
    }
  });

  return <Box className={classes.list}>
    <Box className={classNames(classes.listToolbar, classes.light)}>
      <Box className={classes.listToolbarTitle}> <IconList />下拉数据列表</Box>
      <Box className={classes.grow} />
      {editing && <Button color="primary" onClick={() => onItemAdd()}>
        <IconAdd />添加下拉列表
        </Button>}
    </Box>
    {data.map((dropdown: NavDropdown, index: number) => <Dropdown
      key={dropdown.id || index}
      position={position}
      editing={editing}
      dropdown={dropdown}
      onSave={onItemSave}
      onRemove={onItemRemove}
      showUp={index > 0}
      showDown={index < data.length - 1}
      sortUp={() => {
        sortUp(index)
      }}
      sortDown={() => {
        sortDown(index)
      }} />)}

  </Box>
}