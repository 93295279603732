
import * as actions from '../../store/actions/content/cat'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    getActiveList: Function,
    getNames:Function,
    get: Function,
    save: Function,
    remove: Function,
    setStatus: Function,
}

/**
 * 系统配置逻辑
 */
export const useCat = (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const { params,success, failure } = props || {}
        dispatch(actions.fetchList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                // 通知Redux
                dispatch(actions.fetchListSucess(list))
                // 成功返回
                if (success) {
                    success(list)
                }
            } else {
                // 失败
                const error = {
                    code: data && data.code || 0,
                    message: message || '请求失败'
                }
                dispatch(actions.fetchListFailure(error))
                // 失败返回
                if(failure)
                failure(message || '请求失败')
            }
        })
    }
    // 获取启用列表
    const getActiveList = (props: any) => {
        const { params,success, failure } = props || {}
        dispatch(actions.fetchActiveList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                // 通知Redux
                dispatch(actions.fetchListSucess(list))
                // 成功返回
                if (success) {
                    success(list)
                }
            } else {
                // 失败
                const error = {
                    code: data && data.code || 0,
                    message: message || '请求失败'
                }
                dispatch(actions.fetchListFailure(error))
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取名称
    const getNames = (props: any) => {
        const { id,success, failure } = props
        dispatch(actions.fetchNames(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const names = data.result.names
                // 成功返回
                if (success) {
                    success(names)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }
    // 获取
    const get = (props: any) => {
        const { id ,success, failure } = props
        dispatch(actions.fetch(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const cat = data.result.cat
                // 成功返回
                if (success) {
                    success(cat)
                }
            } else {
                // 失败返回
                if(failure)
                failure(message || '请求失败')
            }
        })
    }


    // 保存
    const save = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.save(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    // 删除
    const remove = (props: any) => {
        const { id,success, failure } = props
        dispatch(actions.remove(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    // 设置状态
    const setStatus = (props: any) => {
        const { id,status,success, failure } = props
        dispatch(actions.setStatus({id,status})).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    return {
        getList,
        getActiveList,
        getNames,
        get,
        save,
        remove,
        setStatus
    }
}