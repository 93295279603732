import { combineReducers } from 'redux';

import {State} from '../../state/shopping';

import {
    initialState as catInitialState,
    reducer as catReducer
} from './cat';

import {
    initialState as productInitialState,
    reducer as productReducer
} from './product';

import {
    initialState as specInitialState,
    reducer as specReducer
} from './spec';

import {
    initialState as attrInitialState,
    reducer as attrReducer
} from './attr';

import {
    initialState as promotionInitialState,
    reducer as promotionReducer
} from './promotion';

import {
    initialState as brandInitialState,
    reducer as brandReducer
} from './brand';

export const initialState: State = {
    cat: catInitialState,
    product: productInitialState,
    spec: specInitialState,
    attr: attrInitialState,
    promotion: promotionInitialState,
    brand: brandInitialState
};

export const reducers = combineReducers<State>({
    cat: catReducer,
    product: productReducer,
    spec: specReducer,
    attr: attrReducer,
    promotion: promotionReducer,
    brand: brandReducer
});