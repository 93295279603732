import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// 上传组件
import Upload from "../upload";
import { VideoInputProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    label: {
      textAlign: "right",
      flexWrap: "nowrap"
    },
    input: {
      flex: 1,
      margin: theme.spacing(1)
    },
    tips: {
      color: "#999"
    }
  })
);

const VideoInput = (props: VideoInputProps) => {
  const classes = useStyles();
  const { label, labelWidth, onChange, value, tips, ...rest } = props;
  const labelStyle: React.CSSProperties = labelWidth
    ? {
        width: labelWidth
      }
    : {};
  return (
    <Box className={classes.root}>
      <Box component="span" style={labelStyle} className={classes.label}>
        {label}
      </Box>
      <Box className={classes.input}>
        {Upload({
          type: "video",
          value,
          onChange: (props: any) => {
            const { attach, url } = props;
            onChange((attach && attach.url) || url);
          },
          ...rest
        })}
        {tips && <Box className={classes.tips}>{tips}</Box>}
      </Box>
    </Box>
  );
};
export default VideoInput;
