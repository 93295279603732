import React, { useState, useMemo } from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

// import LoadingBox from '../../../components/ui/LoadingBox';
import { MessageDialog } from '../../../components/ui/dialog';

import { useLocale } from '../../../config/Locale'

import { useDropzone } from 'react-dropzone';

import * as Accepts from '../../../components/ui/upload/Accepts'

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  padding: '20px 40px',
  minWidth: '300px',
  transition: 'border .24s ease-in-out'
};

const activeStyle: React.CSSProperties = {
  borderColor: '#2196f3'
};

const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676'
};

const rejectStyle: React.CSSProperties = {
  borderColor: '#ff1744'
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { 
      margin: 0,
      padding: 0,
      fontSize: '1rem',
      display: 'flex',
      flexDirection: 'row'
    },
    main: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    uploadContainer: {
      margin: theme.spacing(2)
    },
    icon: {
      marginLeft: 5
    },
    progressMessage: {
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    footerText: {
    },
    button: {
      margin: theme.spacing(3, 2, 2, 0),
    },
  }))

type Props = {
  success:Function,
  type:"image"|"file"
}
const SimpleUpload = (props: Props) => {
  const { translate } = useLocale()
  const { success, type="image"  } = props
  // 样式 
  const classes = useStyles()

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  // const [precent, setPrecent] = useState<number>(0)

  const [message, setMessage] = useState(initialMessage)
  // const initialLoading = { open: false, text: 'Loading...' }
  // const [loading, setLoading] = useState(initialLoading)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: Accepts.getAccept(type),
    onDrop: acceptedFiles => {
       success(acceptedFiles)
      // if (acceptedFiles.length > 1) {
      //   setMessage({
      //     open: true,
      //     type: 'error',
      //     title: translate('alert.tips'),
      //     text: translate('upload.onceMastFile')//只能选择一个文件
      //   })
      // } else {
      //   // 直接上传
      //   setLoading({
      //     open: true,
      //     text: '正在上传图片...'
      //   })
      //   let data = new FormData()
      //   data.append('file', acceptedFiles[0])
      //   // uploadCardImg({
      //   //   data,
      //   //   onProgress: (event: any) => {
      //   //     if (event.lengthComputable) {
      //   //       //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
      //   //       //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
      //   //       // callback(progressEvent);
      //   //       //{{Math.ceil(precent)}}%
      //   //       // precent.toFixed(2)
      //   //       const loaded = event.loaded;
      //   //       const total = event.total;
      //   //       const precent = (loaded / total) * 100;
      //   //       setPrecent(precent)
      //   //       if (onProgress)
      //   //         onProgress({
      //   //           precent,
      //   //           progress: true
      //   //         })
      //   //     }
      //   //   },
      //   //   success: (message: string) => {
      //   //     setLoading(initialLoading)
      //   //     success(message)
      //   //     if (onProgress)
      //   //       onProgress({
      //   //         precent,
      //   //         progress: false
      //   //       })
      //   //   },
      //   //   failure: (message: string) => {
      //   //     setLoading(initialLoading)
      //   //     setMessage({
      //   //       open: true,
      //   //       type: 'error',
      //   //       title: translate('alert.tips'),
      //   //       text: message
      //   //     })
      //   //     if (onProgress)
      //   //       onProgress({
      //   //         precent,
      //   //         progress: false
      //   //       })
      //   //   }
      //   // })
      // }
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);

  return (
    <div className={classes.uploadContainer}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{translate('upload.uploadDrag')}</p>
      </div>
      {/* <LoadingBox {...loading} precent={precent}/> */}

      <MessageDialog
        onClose={() => {
          setMessage(initialMessage)
        }}
        message={message} />
    </div>
  )
}
export default SimpleUpload