import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box';
import { withRouter } from "react-router-dom";
// 逻辑
import { useLocale } from '../../../config/Locale'
import { useAd } from '../../../provides/common'
import { useConfig } from '../../../provides/app'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { SubmitedDialog } from '../../../components/ui/dialog'
// 上传组件
import { getAccept } from '../../../components/ui/upload/Accepts'

// 表单组件 

import FormField from '../../../components/ui/form/FormField'
import TextInput from '../../../components/ui/form/TextInput'
import ImageInput from '../../../components/ui/form/ImageInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import VideoInput from '../../../components/ui/form/VideoInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import Editor from '../../../components/ui/editor'
import { createId } from '../../../lib/util'
import { useEditPageStyles } from '../../../assets/jss/pages'


const EditPage = (props: any) => {
  const { history } = props

  const { translate } = useLocale()
  const { get, save } = useAd()
  const { closePage } = useConfig()

  const classes = useEditPageStyles()

  const [loaded, setLoaded] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const resetState = () => {
    setLoaded(false)// 富文本
    setImageLoaded(false)// 图片列表
    setVideoLoaded(false)// 视频
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  
  // 设置表单元素
  const [form, setForm] = useState({} as any)
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载广告...'
    })
    get({
      id: form.id,
      success: (ad: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        const newForm = ad
        setForm(Object.assign({}, newForm))
        // 重置表单元素加载状态
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        reloadParams()
        resetState()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id !== form.id) {
      resetForm()
      setFormItem('id', id)
      loadData()
    }
    return () => {
    }
  });

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      ad: form,
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route && route.path) {
        history.push(route.path)
      }
    })
  }

  return (<Box>
    <form onSubmit={onSubmit}>
      <Box className={classes.margin}>
        {/* 标题 */}
        <TextInput
          labelWidth={120}
          value={`${form.title || ''}`}
          fullWidth
          label="标题："
          required
          placeholder="请输入广告标题..."
          onChange={(event: any) => {
            setFormItem('title', event.target.value)
          }} />
        {/* 图片 */}
        <ImageInput
          labelWidth={120}
          value={form.image}
          label="图片："
          onChange={(url: string) => {
            setFormItem('image', url)
          }}
          loaded={imageLoaded}
          onLoaded={() => { setImageLoaded(true) }} />
        {/* 视频 */}
        <VideoInput
          labelWidth={120}
          value={form.video}
          label="视频："
          tips={`文件后缀名包含“${getAccept('video')}”。`}
          onChange={(url: string) => {
            setFormItem('video', url)
          }}
          loaded={videoLoaded}
          onLoaded={() => { setVideoLoaded(true) }} />
        <TextInput
          labelWidth={120}
          value={`${form.link || ''}`}
          fullWidth
          label="链接地址："
          required
          placeholder="链接地址以http://或https://开头..."
          onChange={(event: any) => {
            setFormItem('link', event.target.value)
          }} />
        {/* 详情 */}<FormField
          labelWidth={120}
          label={`文本内容：`}>
          <Editor
            value={form.content}
            loaded={loaded}
            onLoaded={() => {
              setLoaded(true)
            }}
            onChange={(value: string) => {
              setFormItem('content', value)
            }}
            fixed={false}
            options={[
              'fontWeight',
              'fontStyle',
              'underline',
              'lineThrough',
              'fontFamily',
              'lineHeight',
              'fontSize',
              'fontColor',
              'alignment',
              'link',
              'clear',
            ]
            } />
        </FormField>
        {/* 排序 */}
        <TextInput
          labelWidth={120}
          value={`${form.sorter || 0}`}
          inputWidth={60}
          label="排序："
          type="number"
          onChange={(event: any) => {
            setFormItem('sorter', event.target.value)
          }} />
        {/* 状态 */}
        <RadioInput
          labelWidth={120}
          value={form.status}
          options={[
            { label: "未启用", value: "0" },
            { label: "启用", value: "1" }
          ]}
          label="状态："
          onChange={(e: any, value: any) => {
            setFormItem('status', value)
          }} />
      </Box>
      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          },
          onReset: () => {
            loadData()
          }
        }} />
    </form>

    <LoadingBox {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/common/ad`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`?id=${createId()}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        loadData()
      }}
    />
  </Box>
  )
}
export default withRouter(EditPage)