
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

export default withStyles(() => createStyles({
  root: {
    '& label.Mui-focused': {
      color: '#484848',
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#ccc',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#e2e2e1',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#aaa',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#e2e2e1',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#aaa',
      },
    },
  }
}))(MuiTextField)
