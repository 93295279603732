
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useChatStyles = makeStyles((theme: Theme) => createStyles({
  grow:{
    flexGrow:1
  },  
  avatar:{
    width:32,
    height:32,
    overflow:"hidden",
    border:0,
    borderRadius:"50%",
    backgroundSize:"cover",
    backgroundRepeat:"no-repeat"
  },
  root:{
      display:"flex",
      background:"#fff",
      borderBottomLeftRadius:8,
      borderBottomRightRadius:8,
      borderLeft:"1px solid #dedede",
      borderRight:"1px solid #dedede",
      borderBottom:"1px solid #dedede"
  },
  side:{
    flex:1,
    // padding:theme.spacing(1,1,0,1),
    borderRight:"1px solid #dedede",
    minWidth:200,
    overflow:"hidden",
  },
  tab:{
    background:"#f3f3f3",
    minWidth:100,
    minHeight:32,
  },
  tabSelected:{
    background:"#ff6600",
    color:"#fff",
    minWidth:90,
    minHeight:32,
  },
  sideHeader:{
    padding:theme.spacing(1,1,0,1)
  },
  main:{
    flexGrow:1,
    display:"flex",
    flexDirection:"column"
  },
  tabs:{
    // minHeight:32
  },
  chatContainer:{
        flexGrow:1,
        minWidth:750,
        minHeight:380,
        background:"#f5f5f5",
        overflowY:"auto"
  },
  footerToolbar: {
    display:"flex",
    justifyContent:'center',
    top: 'auto',
    bottom: 0
  },
  editor:{
    background:"#fff",
    minHeight:150,
    maxHeight:150,
    overflowY:'auto'
  },
  tools:{
    flexGrow:1,
    // borderTop:"1px solid #e0e0e0",
    // background:"#f5f8f6",
    display:"flex"
  },
  form:{
    display:"flex",
    alignItems:"center",
    flex:1,
    margin:0,
    padding:theme.spacing(0,1),
    '& svg':{
      margin:4
    }
  },
  search:{
    background:"#f9f9f9",
    display:"flex",
    borderBottom:"1px solid #dedede",
    borderTop:"1px solid #dedede"
  },
  tips:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    '& div':{
      fontSize:'12px',
    }
  },
  success:{
      padding:theme.spacing(1),
    display:"flex",
    justifyContent:'center',
    alignItems:"center",
    color:"green",
    '& svg':{
      marginRight:4,
       "& path":{
            fill:"green",
          },
    }
  },
  error:{
      padding:theme.spacing(1),
    display:"flex",
    justifyContent:'center',
    alignItems:"center",
    color:"red",
    '& svg':{
      marginRight:4,
       "& path":{
            fill:"red",
          },
    }
  },
  info:{
      padding:theme.spacing(1),
      display:"flex",
      justifyContent:'center',
      alignItems:"center",
      color:"#666",
      '& svg':{
        marginRight:4,
        "& path":{
              fill:"#666",
            },
      }
  },
  messageList:{

  },
  messageListItem:{
    marginLeft:0,
    marginRight:theme.spacing(5),
    justifyContent:"flex-start",
    display:"flex",
    padding:theme.spacing(1)
  },
  messageMe:{
    marginLeft:theme.spacing(5),
    marginRight:0,
    justifyContent:"flex-end",
  },
  messageContainer:{
    marginLeft:0,
    display:"flex",
    flexDirection:"column",
  },
  messageUser:{
      color:"#484848",
      margin:theme.spacing(0,1),
      padding:theme.spacing(0,1,1,1),
  },
  alignRight:{
    display:"flex",
    justifyContent:"flex-end"
  },
  messageBody:{
    padding:theme.spacing(1),
    margin:theme.spacing(0,1),
    background:"#fff",
    borderRadius:5,
    flexGrow:1
  },
  messageBodyMe:{
    background:"#99CC66",
    color:"#fff"
  },
  messageAvatar:{

  },
  messageTime:{
    display:"flex",
    padding:theme.spacing(1)
  },
  alignStart:{
    justifyContent:"flex-start",
  },
  alignEnd:{
    justifyContent:"flex-end",
  },
  listitemText:{
    display:"flex",
    flexDirection:"column",
    position:"relative"
  },
  listitemUsername:{
    
  },
  listitemBody:{
    fontSize:"11px",
    color:"#666",
    width:100,
    overflow:"hidden"
  },
  unreadTotal:{
    fontSize:"10px",
    position:"absolute",
    right:4,
    top:4,
    background:"#ff6600",
    color:"#fff",
    padding:4,
    borderRadius:'50%',
    width:26,
    height:26,
    display:"flex", 
    alignItems:"center",
    justifyContent:"center"
  },
  images:{
  },
  image:{
    '& img':{
      maxWidth:360,
      border:0,
      "@media (max-width:650px)": {
        maxWidth:"98%",
      },
    }
  },
  files:{
    display:"flex",
    flexDirection:"column"
  },
  file:{
    display:"flex",
    alignItems:"center"
  }
}));
