
export const formatDateTime = (value:string) => {

  if (value && /\d+/.test(value)) {
    value = value.length === 10 ? value + '000' : value;
    let date = new Date(Number(value));
    let now = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    // let hour = date.getHours();
    // let minute = date.getMinutes();
    // let second = date.getSeconds();
    let time = now.getTime(); 

    if (now.getFullYear() == year
      && now.getMonth() == month
    ) {
      // 本月
      if (now.getDate() == day) {
        // 今天
        let s = 1000;
        let m = Number(time) - Number(value);
        //console.log('time:'+hour+":"+minute+':'+second);
        if (m < (s * 60)) {
          return Math.ceil(Number(m / s)) + "秒前";
        } else if (m < (s * 60 * 60)) {
          return Math.ceil(Number(m / (s * 60)))+ "分钟前";
        } else {
          return Math.ceil(Number(m / (s * 60 * 60))) + "小时前";
        }
      } else {
        return (now.getDate() - day) + "天前";
      }
    } else {
      return year + '-' + (month + 1) + '-' + day
    }
  } else {
    return '';
  }
}

export const formatAPDateTime = (value:string) => {

  if (value && /\d+/.test(value)) {
    value = value.length === 10 ? value + '000' : value;
    let date = new Date(Number(value));
    let now = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let time = now.getTime(); 

    if (now.getFullYear() == year
      && now.getMonth() == month
    ) {
      // 本月
      if (now.getDate() == day) {
        // 今天
        let s = 1000;
        let m = Number(time) - Number(value);
        //console.log('time:'+hour+":"+minute+':'+second);
        if (m < (s * 60)) {
          return Math.ceil(Number(m / s)) + "秒前";
        } else if (m < (s * 60 * 60)) {
          return Math.ceil(Number(m / (s * 60)))+ "分钟前";
        } else {
          //  判断上午还是下午
          if(hour <13){
             return "上午 "+(hour<10?'0'+hour:hour)+":"+  (minute<10?'0'+minute:minute)  + ":" +  (second<10?'0'+second:second);
          }else{
             return "下午 "+(hour-12)+":"+  (minute<10?'0'+minute:minute)  + ":" +  (second<10?'0'+second:second);
          }
        }
      } else {
          if(hour <13){
             return (month + 1) + '-' + day + " 上午 "+(hour<10?'0'+hour:hour)+":"+ (minute<10?'0'+minute:minute) + ":" + (second<10?'0'+second:second);
          }else{
             return (month + 1) + '-' + day + " 下午 "+(hour-12)+":"+ (minute<10?'0'+minute:minute) + ":" + (second<10?'0'+second:second);
          }
      }
    } else {
          if(hour <13){
             return year + '-' +(month + 1) + '-' + day + " 上午 "+(hour<10?'0'+hour:hour)+":"+ (minute<10?'0'+minute:minute) + ":" + (second>0?'0'+second:second);
          }else{
             return year + '-' +(month + 1) + '-' + day + " 下午 "+(hour-12)+":"+ (minute<10?'0'+minute:minute) + ":" + (second<10?'0'+second:second);
          }
    }
  } else {
    return '';
  }
}
export const formatDate= (value:string) => {
  if (value && /\d+/.test(value)) {
    value = value.length === 10 ? value + '000' : value;
    let date = new Date(Number(value));
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    return year + '-' + (month + 1) + '-' + day
  } 
}

export const parseDate = (value:string)=>{
  if (value ) {
    return new Date(value)
  } 
}

export const formatDateTime2 = (value:string) => {

  if (value && /\d+/.test(value)) {
    value = value.length === 10 ? value + '000' : value;
    const date = new Date(Number(value));
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();

    return year + '-' + (month + 1) + '-' + day+ " "+ (hour<10?"0"+hour:hour) + ":"+(minutes < 10 ?"0"+minutes:minutes);
   } else {
    return '';
  }
}

/**
 * @description 生成UUID
 */
export const createId = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0; var v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
} 

/**
 * @description 生成时间戳ID
 */
export const createTId = function () {
    var v = Math.random() * 1000; 
    return v.toString(16)
} 
export const isFunction = (obj:any) => !!(obj && obj.constructor === Function)

export const preventDefault = (event:any)=>{
  if (event.stopPropagation) {
    event.stopPropagation();
  }
  else {
    event.cancelBubble = true;
  }

  if (event.preventDefault) {
    event.preventDefault();
  }
  else {
    event.returnValue = false;
  }
}
  /**
   * 千分位加,
   */
  export function formatNumber(value:number){
    if(!value)
      return 0.00 
    return  ("" + value.toFixed(2)).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,"); 
  }
  /**
   * 千分位加,
   */
  export function formatNumberNoFixed(value:number){
    if(!value)
      return 0.00 
    return  ("" + value).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,"); 
  }
 /**
  * 还原数值
  */
  export function parseNumber(value:string){
    if(!value)
      return 0
    return value.replace(',','') 
  }

export const pageX=(elem:any):number=>{
  return elem.offsetParent ? elem.offsetLeft + pageY(elem.offsetParent) : elem.offsetLeft;
}
export const pageY=(elem:any):number=>{
        return elem.offsetParent ? elem.offsetTop + pageY(elem.offsetParent) : elem.offsetTop;
}


export const scrollAnimation=(node:HTMLElement,targetY:number,_y?:number)=> {
  // 获取当前位置方法
  const currentY = _y || pageY(node)

  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    node.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(node,targetY, _currentY)
    } else {
      node.scrollTo(_currentY,targetY )
    }
  }, 1)
}
export const getParentDom = (elem:Element,parentId:string):Element|null=>{
  const parent = elem.parentElement
  if(parent){
    if(parent.getAttribute("id") === parentId){
      return parent
    }else{
      return getParentDom(parent,parentId)
    }
  }
  return null;
}

export const removeHtmlTag = (str:string,cut?:number)=>{
  if(!str)
    return ''
  const rst =  str.replace(/<[^>]+>/g,"");  //正则去掉所有的html标记
  if(cut && cut > 0 && rst.length > cut){
    return str.substr(0,cut) + "..."
  }else{
    return rst;
  }
}