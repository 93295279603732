import React from 'react';

import { css } from 'emotion';
export default () => {
    const files = require["context"]('../../components/icons', false, /\.tsx$/)
    const icons: any = {}
    files.keys().forEach((key: string) => {
        icons[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
    })

    const styles = {
        title: css`
        padding:10px;
        border-bottom:1px solid #ccc;
        `,
        content: css`
          padding:10px;
          display:block;
          `,
        listItem: css`
          float:left;
          margin:10px;
          min-width:120px;
          border-radius:6px;
          padding:10px;
          text-align:center;
          align-items:center;
          border:1px solid #eee;
          display:flex;
          flex-direction:column;
          cursor:pointer;
          :hover{
            background:#f7f7f7;
            color:#26a69a
          }
          `,
        iconSvg: css`
          color:blue
          `,
        label: css`
          color:#666
          `,
    }
    return (
        <div>
            <div className={styles.title}>图标</div>
            <div className={styles.content} id="content">
                {Object.keys(icons).map((key, index) => (
                    <span
                        className={styles.listItem}
                        key={index}>
                        <span>
                            {icons[key]({
                                width: 32,
                                height: 32,
                                className: styles.iconSvg
                            })}
                        </span>
                        <span className={styles.label}>{key}</span>
                    </span>))}
            </div>
        </div>
    )
}