import React from 'react';

import Popover from '@material-ui/core/Popover';
import { EditorState} from 'draft-js';

import {styles} from '../renders'

import { PhotoshopPicker } from 'react-color';
import Button from './Button'
import Icon from './Icon'

const ColorPicker = (props: any) => {
    const {
        onChange,
        editorState,
        title,
        type,
        icon
    } = props
    // const currentStyle = editorState.getCurrentInlineStyle();
    const stopPropagation = (event: any) => {
        event.stopPropagation();
    };
    const [anchorEl, setAnchorEl] = React.useState<any | null>(null);
    function handleClick(event: any) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    const [newColor, setNewColor] = React.useState()

    const toggleColor = () => {
        const nextContentState = editorState.getCurrentContent()
        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );
        const color = `rgba(${newColor.rgb.r},${newColor.rgb.g},${newColor.rgb.b},${newColor.rgb.a})`
        if (type === 'backgroundColor') {
            nextEditorState = styles.backgroundColor.toggle(editorState, color);
        } else {
            nextEditorState = styles.color.toggle(editorState, color);
        }
        onChange(nextEditorState);
    }


    const renderModal = () => {
        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={stopPropagation}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <PhotoshopPicker
                    color={newColor}
                    onAccept={() => {
                        toggleColor()
                        handleClose()
                    }}
                    onCancel={() => {
                        handleClose()
                    }}
                    onChange={(color: any) => {
                        setNewColor(color);
                    }} />
            </Popover>
        );
    };

    return (<>
        <Button onMouseDown={handleClick} title={title[0]} >
            <Icon name={icon} width={18} height={18} />
        </Button>
        {renderModal()}
    </>
    );

}

export default ColorPicker