import React, { useState, useEffect } from 'react'
import { stableSort, getSorting } from '../../../lib/sort'
import { formatDateTime } from '../../../lib/util'

import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Icon from '../../../components/Icon'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { BodyTableCell, TableRow, TableHead, TableRowToolbar } from '../../../components/ui/table'
import PageToolbar from '../../../components/ui/PageToolbar'

import Checkbox from '@material-ui/core/Checkbox';

import { withRouter } from "react-router-dom";
import { createId,preventDefault } from '../../../lib/util'
import { useAttr } from '../../../provides/shopping'
import { useLocale } from '../../../config/Locale'

import { useListPageStyles } from '../../../assets/jss/pages'
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, AlertDialog } from '../../../components/ui/dialog'
import ListPagination from '../../../components/ui/pagination'
import { attachPrefix } from '../../../config';

import CatInput from '../../../components/shopping/CatInput';
import RadioInput from '../../../components/ui/form/RadioInput'
import page from '../../ui/page';

interface IRequestParams {
  keyword?: string;// 关键字
  catId?: string;// 分类ID 
  page?: number;// 当前页号
  pageSize?: number// 每页条数
}

const fields = [
  { id: 'name', label: '属性名称' },
  { id: 'values', label: '属性值' },
  { id: 'catName', label: '所属分类'},
  { id: 'status', label: '状态' },
];


const EnhancedPageToolbar = (props: any) => {

  const [names, setNames] = useState("")
  const { translate } = useLocale()
  const { numSelected, onAddClick, onDelClick, onSelectCat, onRefresh, onSearch, params } = props;

  const title = <span>
    <span> {names || translate('attr.manager')} </span>
    {numSelected > 0 && (
      <span style={{ paddingLeft: 16 }}>
        {numSelected} {translate('selected')}
      </span>
    )}
  </span>
  const label = {
    add: translate('attr.add')
  }
  const tools = <CatInput showLabel={false}
    showName={false}
    value={params.catId}
    onChange={(item: any, names: string) => {
      onSelectCat(item.id);
      setNames(names)
    }} />
  return <PageToolbar
    {...{
      title,
      label,
      tools,
      numSelected,
      onAddClick,
      onDelClick,
      onRefresh,
      keyword: params.keyword,
      onSearch
    }} />
};


const SpecListPage = (props: any) => {
  const classes = useListPageStyles()
  const { history } = props
  const { translate } = useLocale()
  const { getList, remove,setStatus } = useAttr()
  // 列表
  const [list, setList] = useState<any>([])
  const [pagination, setPagination] = useState<any>({})

  // 分页
  const total = (pagination && pagination.total) || 0
  // 请求参数
  const [params, setParams] = useState<any>({
    keyword: "",// 关键字
    catId: "",// 分类ID 
    page: 1,// 当前页号
    pageSize: 15// 每页条数
  })
  const setParam = (key: "catId" |  "keyword"  | "page" | "pageSize", value: string | number) => {
    const newParams = params || {}
    newParams[key] = value
    setParams(Object.assign({}, newParams))
  }
  const onSearch = (keyword: string) => {
    setParam("keyword", keyword)
    setParam("page", 1)
    loadData()
  }
  const onPageChange = (page: number) => {
    setParam("page", page)
    loadData()
  }
  const onPageSizeChange = (pageSize: number) => {
    setParam("pageSize", pageSize)
    setParam("page", 1)
    loadData()
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  // 设置状态 
  const handleStatus = (id: string, status: string) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在更改状态...'
    })
    setStatus({
      data: {
        id,
        status
      },
      success: (message: string) => {
        loadData()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  // 获取列表
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载规格列表...'
    })
    getList({
      params,
      success: (list:any, pagination:any) => {
        setList(list)
        setPagination(pagination)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setList([])
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadData();
    return () => {
      // 返回空方法，否则会陷入无限循环
    };
  }, []);

  const [order, setOrder] = React.useState<"asc" | "desc">('asc');
  const [orderBy, setOrderBy] = React.useState('stock');
  const [selected, setSelected] = React.useState([] as string[])

  const handleRequestSort = (event: any, property: any) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = list && list.map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  const handleClick = (event: any, id: string) => {
    let newSelected = selected || [];
    const index = newSelected.indexOf(id)
    if (index > -1) {
      newSelected.splice(newSelected.indexOf(id), 1)
    } else {
      newSelected.push(id)
    }
    setSelected(Object.assign([], newSelected));
  }

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
        {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
        <Box>
          <Box>{translate('attr.removeTips')}</Box>
          <Box>{translate('attr.removeAsk')}</Box>
        </Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        setLoading({
          open: true,
          text: translate('attr.removing')
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadData()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
  }

  const isSelected = (id: string) => selected && selected.indexOf(id) !== -1;

  const renderPageContent = (
    <div className={classes.pageContent}>
      <EnhancedPageToolbar
        numSelected={selected && selected.length}
        {...{
          onAddClick: () => {
            history.replace(`/shopping/attr/edit?id=${createId()}`)
          },
          onDelClick: () => {
            handleDelete(selected)
          },
          onRefresh: () => {
            loadData()
          },
          params,
          onSelectCat: (catId: string) => {
            setParam("catId", catId);
            loadData()
          },
          onSearch
        }} />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead
            checkbox
            fields={fields}
            numSelected={selected && selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={list.length}
          />
          <TableBody>

            {stableSort(list, getSorting(order, orderBy))
              //.slice((params.page - 1) * params.pageSize, (params.page - 1) * params.pageSize + params.pageSize)
              .map((row: any, index: number) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <BodyTableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="default"
                        className={classes.checked}
                        checkedIcon={<span className={clsx(classes.checkBoxIcon, classes.checkedIcon)} />}
                        icon={<span className={classes.checkBoxIcon} />}
                      />
                    </BodyTableCell>
                    <BodyTableCell align="left">
                      {row.name}
                    </BodyTableCell>
                    <BodyTableCell align="left">
                    {row.valList && row.valList.map((item:any)=><span style={{padding:8,border:"1px solid #f3f3f3",margin:"0 8px 8px 0"}}>
                      {item.value}
                      </span>)}
                    </BodyTableCell>
                    <BodyTableCell align="left">
                    {row.catName}
                    </BodyTableCell>
                    <BodyTableCell align="left">
                      <RadioInput
                        labelWidth={0}
                        value={row.status}
                        options={[
                          { label: "未启用", value: "0" },
                          { label: "启用", value: "1" }
                        ]}
                        label=""
                        margin="0"
                        onChange={(event: any, value: any) => {
                          preventDefault(event)
                          handleStatus(row.id, value)
                        }} />
                    </BodyTableCell>
                    <BodyTableCell align="right" className={classes.rowToolbar}>
                      <TableRowToolbar
                        {...{
                          editLabel: translate('attr.edit'),
                          deleteLabel: translate('attr.remove'),
                          onEdit: () => {
                            history.replace(`/shopping/attr/edit?id=${row.id}`)
                          },
                          onDelete: () => {
                            handleDelete([row.id])
                          },
                        }} />
                    </BodyTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <LoadingBox {...loading} />

      {list.length === 0 && !loading.open && (<Box className="error">
        <Icon name="Error" width={32} height={32} color="gray" />
        <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
      </Box>)}
      <Box className={classes.pagination}>
        <ListPagination
          {...{
            total,
            page: params.page,
            pageSize: params.pageSize,
            onPageChange,
            onPageSizeChange,
            maxShow: 5
          }}
        />
      </Box>
    </div>)

  return (<Box className={classes.root}>

    {renderPageContent}


    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </Box>
  )
}
export default withRouter(SpecListPage)