import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "./TextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    label: {
      textAlign: "right",
      flexWrap: "nowrap"
    },
    input: {
      flex: 1,
      margin: theme.spacing(1)
    },
    inputInput: {},
    tips: {
      padding: theme.spacing(1, 0),
      fontSize: 12,
      color: "#999"
    }
  })
);

const TextInput = (props: any) => {
  const classes = useStyles();
  const { label, labelWidth, inputWidth, tips, ...rest } = props;
  const labelStyle: React.CSSProperties = labelWidth
    ? {
        width: labelWidth
      }
    : {};
  const inputStyle: React.CSSProperties = inputWidth
    ? {
        width: inputWidth
      }
    : {};
  return (
    <Box className={classes.root}>
      <Box component="span" style={labelStyle} className={classes.label}>
        {label}
      </Box>
      <Box component="span" className={classes.input}>
        <TextField
          className={classes.inputInput}
          style={inputStyle}
          inputProps={{
            "aria-label": "description"
          }}
          {...rest}
        />
        {tips && <Box className={classes.tips}>{tips}</Box>}
      </Box>
    </Box>
  );
};
export default TextInput;
