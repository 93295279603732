import React  from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { EXPRESS_LIST } from '../../../config';
import FormField from './FormField'
import SplitButton from '../button/SplitButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      zIndex: 999
    },
  }))

export type InputProps = {
  value?: any,
  label?:any,
  labelWidth?: number,
  onChange: (item: any) => void
}
export default (props: InputProps)=> {
  const classes = useStyles()
  const { label,labelWidth, onChange, value } = props

  return (<FormField
    labelWidth={labelWidth}
    label={`${ label || `快递公司：`}`}>
    <SplitButton
      label="请选择快递公司"
      variant="default"
      className={classes.button}
      options={EXPRESS_LIST}
      value={value}
      size="small"
      onSelect={(item: any) => {
        onChange(item)
      }} />
  </FormField>);
}

