import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as commonTypes from '../../../types/common'


// Action Types
export type Action = {
    // 行政区划列表
    type: 'COMMON_AREA_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | { type: 'COMMON_AREA_FETCH_LIST_SUCCESS', payload: any }
    | { type: 'COMMON_AREA_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 行政区划
        type: 'COMMON_AREA_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 名称
        type: 'COMMON_AREA_FETCH_NAMES',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
        | { type: 'COMMON_AREA_FETCH_SUCCESS', payload: any }
        | { type: 'COMMON_AREA_FETCH_FAILURE', payload: { error: types.Error } }
        | {
        // 保存
        type: 'COMMON_AREA_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 删除
        type: 'COMMON_AREA_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 生成JSON文件
        type: 'COMMON_AREA_JSON_FILE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }| {
        // 读JSON数据
        type: 'COMMON_AREA_JSON_DATA',
        payload: { client: 'default', request: AxiosRequestConfig }
    };


// 列表
export const FETCH_LIST = 'COMMON_AREA_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'COMMON_AREA_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'COMMON_AREA_FETCH_LIST_FAILURE';
export const fetchList = (id?:string): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/getList`,
            method: 'GET',
            params: {
                id
            }
        }
    }
})
export const fetchListSucess = (list: commonTypes.Area[]): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});



// 列表
export const FETCH = 'COMMON_AREA_FETCH'
export const FETCH_SUCCESS = 'COMMON_AREA_FETCH_SUCCESS';
export const FETCH_FAILURE = 'COMMON_AREA_FETCH_FAILURE';
export const fetch = (id:string): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/get`,
            method: 'GET',
            params: {
                id
            }
        }
    }
})
export const fetchSucess = (area: commonTypes.Area): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        area
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});





// 保存
export const SAVE = 'COMMON_AREA_SAVE'
export const save = (area: commonTypes.Area): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/save`,
            method: 'POST',
            data: {
                area
            }
        }
    }
})



// 删除分组
export const REMOVE = 'COMMON_AREA_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})


// 分类名称
export const FETCH_NAMES = 'COMMON_AREA_FETCH_NAMES'
export const fetchNames = (id:string): Action => ({
    type: FETCH_NAMES,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/getNames`,
            method: 'GET',
            params: {
                id
            }
        }
    }
})


export const JSON_FILE = 'COMMON_AREA_JSON_FILE'
export const jsonFile = (): Action => ({
    type: JSON_FILE,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/buildJsonFile`,
            method: 'GET'
        }
    }
})

export const JSON_DATA = 'COMMON_AREA_JSON_DATA'
export const jsonData = (): Action => ({
    type: JSON_DATA,
    payload: {
        client: 'default',
        request: {
            url: `${API}common/area/json`,
            method: 'GET'
        }
    }
})