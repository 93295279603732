import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as types from '../../../types'
import * as shoppingTypes from '../../../types/shopping'


// Action Types
export type Action = {
        //  优惠活动列表
        type: 'SHOPPING_PROMOTION_FETCH_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'SHOPPING_PROMOTION_FETCH_LIST_SUCCESS', payload: any }
    | { type: 'SHOPPING_PROMOTION_FETCH_LIST_FAILURE', payload: { error: types.Error } }
    | {
        //  优惠活动列表
        type: 'SHOPPING_PROMOTION_FETCH_ACTIVE_LIST',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'SHOPPING_PROMOTION_FETCH_ACTIVE_LIST_SUCCESS', payload: any }
    | { type: 'SHOPPING_PROMOTION_FETCH_ACTIVE_LIST_FAILURE', payload: { error: types.Error } }
    | {
        // 优惠活动
        type: 'SHOPPING_PROMOTION_FETCH',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | { type: 'SHOPPING_PROMOTION_FETCH_SUCCESS', payload: any }
    | { type: 'SHOPPING_PROMOTION_FETCH_FAILURE', payload: { error: types.Error } }
    | {
        // 保存
        type: 'SHOPPING_PROMOTION_SAVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    } | {
        // 删除
        type: 'SHOPPING_PROMOTION_REMOVE',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | {
        // 设置状态
        type: 'SHOPPING_PROMOTION_SET_STATUS',
        payload: { client: 'default', request: AxiosRequestConfig }
    };




// 列表
export const FETCH_LIST = 'SHOPPING_PROMOTION_FETCH_LIST'
export const FETCH_LIST_SUCCESS = 'SHOPPING_PROMOTION_FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'SHOPPING_PROMOTION_FETCH_LIST_FAILURE';
export const fetchList = (params: any): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/promotion/getList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchListSucess = (list: shoppingTypes.Promotion[], pagination:types.Pagination): Action => ({
    type: FETCH_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchListFailure = (error: types.Error): Action => ({
    type: FETCH_LIST_FAILURE,
    payload: {
        error
    }
});





// 列表
export const FETCH_ACTIVE_LIST = 'SHOPPING_PROMOTION_FETCH_ACTIVE_LIST'
export const FETCH_ACTIVE_LIST_SUCCESS = 'SHOPPING_PROMOTION_FETCH_ACTIVE_LIST_SUCCESS';
export const FETCH_ACTIVE_LIST_FAILURE = 'SHOPPING_PROMOTION_FETCH_ACTIVE_LIST_FAILURE';
export const fetchActiveList = (params: any): Action => ({
    type: FETCH_ACTIVE_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/promotion/getList`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchActiveListSucess = (list: shoppingTypes.Promotion[], pagination:types.Pagination): Action => ({
    type: FETCH_ACTIVE_LIST_SUCCESS,
    payload: {
        list,pagination
    }
});
export const fetchActiveListFailure = (error: types.Error): Action => ({
    type: FETCH_ACTIVE_LIST_FAILURE,
    payload: {
        error
    }
});



// 商品
export const FETCH = 'SHOPPING_PROMOTION_FETCH'
export const FETCH_SUCCESS = 'SHOPPING_PROMOTION_FETCH_SUCCESS';
export const FETCH_FAILURE = 'SHOPPING_PROMOTION_FETCH_FAILURE';
export const fetch = (params: any): Action => ({
    type: FETCH,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/promotion/get`,
            method: 'GET',
            params: {
                ...params
            }
        }
    }
})
export const fetchSucess = (promotion: shoppingTypes.Promotion): Action => ({
    type: FETCH_SUCCESS,
    payload: {
        promotion
    }
});
export const fetchFailure = (error: types.Error): Action => ({
    type: FETCH_FAILURE,
    payload: {
        error
    }
});


// 保存
export const SAVE = 'SHOPPING_PROMOTION_SAVE'
export const save = (data: shoppingTypes.Promotion): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/promotion/save`,
            method: 'POST',
            data: {
                ...data
            }
        }
    }
})



// 删除
export const REMOVE = 'SHOPPING_PROMOTION_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/promotion/del`,
            method: 'POST',
            data: {
                id
            }
        }
    }
})



// 设置状态
export const SET_STATUS = 'SHOPPING_PROMOTION_SET_STATUS'
export const setStatus = (data: any): Action => ({
    type: SET_STATUS,
    payload: {
        client: 'default',
        request: {
            url: `${API}shopping/promotion/setStatus`,
            method: 'GET',
            data: {
                ...data
            }
        }
    }
})