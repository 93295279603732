
import axios from 'axios'
import { appId } from '../config'
import { getAppToken,getAccount } from '../storage/AccountStorage';

// 创建一个 axios 实例
const service = axios.create({
  baseURL: '',
  responseType: 'json',
  timeout: 10000,
})
// 请求拦截器
service.interceptors.request.use(
  function (config) {
    config.headers['App-Id'] = appId
    // 从内存数据库获取token
    const appToken = getAppToken()
    const account = getAccount()
    if (appToken) {
      config.headers['App-Token'] = appToken
    }
    if(account && account.user && account.token){    
      config.headers['User-Id'] = account.user.id  
      config.headers['User-Token'] = account.token
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

export default service