import React, { useState, useEffect } from 'react'

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { createId } from '../../../lib/util'

// 表单组件
import TextInput from '../../../components/ui/form/TextInput'
import NumberInput from '../../../components/ui/form/NumberInput';
import ImageInput from '../../../components/ui/form/ImageInput'
import RadioInput from '../../../components/ui/form/RadioInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import CatInput from '../../../components/content/CatInput';
import ModuleInput from '../../../components/common/ModuleInput';
// 弹框
import { LoadingDialog, SubmitedDialog } from '../../../components/ui/dialog'

import { withRouter } from "react-router-dom";

import { useLocale } from '../../../config/Locale'

import { useModule } from '../../../provides/common'
import { useCat } from '../../../provides/content'
import { useConfig } from '../../../provides/app'

const EditPage = (props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { get, save } = useCat()
  const { closePage, configFactory } = useConfig()
  const fetchModuleList = useModule().getList

  const [imageLoaded, setImageLoaded] = useState(false)
  const resetState = () => {
    setImageLoaded(false)// 图片
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const [form, setForm] = useState<any>({})
  // 设置表单元素
  const setFormItem = (key: string, value: any) => {
    form[key] = value;
    setForm(Object.assign({}, form))
  }

  const loadData = (id: string, cb?: () => void) => {
    setLoading({
      open: true,
      text: '正在加载分类数据...'
    })
    get({
      id,
      success: (cat: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        setForm(cat)
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        if (cb) {
          //  查询上级分类
          cb()
        }
      }
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }

  const query = () => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id && id !== form.id) {
      fetchModuleList()// 模块列表
      resetForm()
      setFormItem('id', id)
      setFormItem('moduleId', params.get('moduleId'))
      setFormItem('parentId', params.get('parentId'))
      loadData(id, () => {
        resetState()
      })
    }
  };
  useEffect(query);

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        cat: {
          ...form
        }
      },
      success: (msg: string) => {
        configFactory()// 重置菜单栏
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: (msg: string) => {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route.path) {
        history.push(route.path)
      }
    })
  }

  const renderPageTitle = (
    <Box className="page-title">
      {translate('content.cat')}
    </Box>)



  const renderPageForm = (<Box>
    <form onSubmit={onSubmit}>
      {/* 模块 */}
      <ModuleInput
        labelWidth={120}
        value={`${form.moduleId || ''}`}
        onChange={(value: any) => {
          setFormItem('moduleId', value)
        }} />
      {/* 上级分类 */}
      <CatInput
        label="上级分类："
        moduleId={form.moduleId || ''}
        labelWidth={120}
        value={`${form.parentId || ''}`}
        onChange={(item: any) => {
          setFormItem('parentId', item.id)
        }} />
      {/* 名称 */}
      <TextInput
        labelWidth={120}
        value={`${form.name || ''}`}
        fullWidth
        label={translate('catName.label')}
        required
        placeholder={translate('catName.placeholder')}
        onChange={(event: any) => {
          setFormItem('name', event.target.value)
        }} />

      {/* 别名 */}
      <TextInput
        labelWidth={120}
        value={`${form.alias || ''}`}
        fullWidth
        label={translate('catAlias.label')}
        required
        placeholder={translate('catAlias.placeholder')}
        onChange={(event: any) => {
          setFormItem('alias', event.target.value)
        }} />

      {/* 图标 */}
      <ImageInput
        labelWidth={120}
        value={form.icon}
        label={translate('catIcon.label')}
        onChange={(url: string) => {
          setFormItem('icon', url)
        }}
        loaded={imageLoaded}
        onLoaded={() => { setImageLoaded(true) }}
      />

      {/* 访问地址 */}
      <TextInput
        labelWidth={120}
        value={`${form.address || ''}`}
        fullWidth
        label={translate('catAddress.label')}
        required
        placeholder={translate('catAddress.placeholder')}
        onChange={(event: any) => {
          setFormItem('address', event.target.value)
        }} />

      {/* 分类类型： */}
      <RadioInput
        labelWidth={120}
        value={form.catType}
        options={[
          { label: '列表分类', value: "1" },
          { label: '单页分类', value: "2" },
          { label: '组图分类', value: "3" }
        ]}
        label="分类类型："
        onChange={(e: any, value: any) => {
          setFormItem('catType', value)
        }} />

      {/* 排序 */}
      <NumberInput
        labelWidth={120}
        value={form.sorter}
        inputWidth={60}
        label="排序："
        onChange={(value: string|number) => {
          setFormItem('sorter', value)
        }} />

      {/* 状态 */}
      <RadioInput
        labelWidth={120} 
        value={form.status}
        options={[
          { label: translate('off'), value: "0" },
          { label: translate('on'), value: "1" }
        ]}
        label="状态："
        onChange={(e: any, value: any) => {
          setFormItem('status', value)
        }} />

      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          },
          onReset: () => {
            const params = new URLSearchParams(history.location.search);
            const id = params.get("id")
            if (id) {
              loadData(id, () => {
                resetState()
              })
            }
          }
        }} />
    </form>
  </Box>)


  return (<Box>

    {renderPageTitle}
    <Divider />
    <Box style={{
      flex: 1
    }}>
      {renderPageForm}
    </Box>

    <LoadingDialog open={loading.open} text={loading.text} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/content/cat`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`/content/cat/edit?id=${createId()}&parentId=${form.parentId}&moduleId=${form.moduleId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        history.replace(`/content/cat/edit?id=${form.id}`)
      }}
    />
  </Box>
  )
}
export default withRouter(EditPage)