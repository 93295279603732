import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { CloseButton } from '../ui/button';

// 分类树
import DataTreeView from '../ui/tree/DataTree'
import { useCatState } from '../../provides/shopping'
import { useCat } from '../../provides/shopping/CatProvide'
import { useLocale } from '../../config/Locale'
// 提示
import LoadingBox from '../ui/LoadingBox'
import ErrorBox from '../ui/ErrorBox'
import Progress from '../ui/Progress'

const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      background: '#f5f5f5',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px solid #dedede',
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
  }))

const DialogTitle = (props: any) => {
  const classes = useStylesTitle()
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span>{children}</span>
      {onClose ? (
        <span className={classes.closeButton} onClick={(e) => { onClose(e) }}>
          <CloseButton open={true} close={onClose} />
        </span>
      ) : null}
    </MuiDialogTitle>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      padding: theme.spacing(2),
      minWidth: theme.spacing(25),
      alignItems: 'center',
      justifyContent: 'center'
    },
    actions: {
      background: '#f7f7f7'
    },
    result: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1),
      textAlign: 'left'
    },
    progress: {
      marginRight: theme.spacing(1)
    }
  }));
export type CatTreeDialogProps = {
  open: boolean,
  cancel: () => void,
  ok: (value: any, names: string) => void,
  onItemClick?: (item: any) => void,
  currentId?: string
}
export default (props: CatTreeDialogProps) => {
  const classes = useStyles();
  const { ok, cancel, currentId, open, onItemClick } = props;

  const [value, setValue] = useState({} as any)
  const [names, setNames] = useState()

  const { getList, getNames } = useCat()

  // 查询分类名称，含父级分类
  const loadNames = (id: string) => {
    setShowProgress(true)
    getNames({
      id,
      success: (names: string) => {
        setShowProgress(false)
        setNames(names)
      },
      failure: (message: string) => {
        setShowProgress(false)
      }
    })
  }
  useEffect(() => {
    if (currentId) {
      loadNames(currentId)
    }
    return () => {

    };
  }, []);
  // 事件反馈
  const [showProgress, setShowProgress] = useState(false)
  const initialMessage = { open: false, text: '' }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState({
    open: true,
    text: '正在加载数据...'
  })

  const { translate } = useLocale()
  const [list,setList]   = useState();
  useEffect(() => {
    // 获取列表
    setMessage({
      open: false,
      text: ''
    })
    setLoading({
      open: true,
      text: '正在加载数据...'
    })
    getList({
      success: (list: any) => {
        // 关闭加载条
        setList(list || [])
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setMessage({
          open: true,
          text: message
        })
      }
    })
    return () => {
    };
  }, []);

  return (
    <Dialog onClose={cancel} open={open}>
      <DialogTitle onClose={cancel}>
        {/* 标题 */}
        {translate('shopping.productCat')}
      </DialogTitle>
      <DialogContent className={classes.content}>

        <DataTreeView
          items={list}
          showDescri={true}
          onClickAll={() => {
            setValue({});
            if (onItemClick) {
              onItemClick({})
            }
            setNames('')
            ok('', '')
          }}
          onItemClick={(item: any) => {
            setValue(item);
            loadNames(item.id)
            if (onItemClick) {
              onItemClick(item)
            }
          }}
        />
        <LoadingBox {...loading} />
        <ErrorBox {...message} />

      </DialogContent>
      <DialogActions className={classes.actions}>
        <Box component="span" className={classes.result}>
          <Progress show={showProgress} className={classes.progress} />
          {names}
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            ok(value, names)
          }}
          color="primary"
          autoFocus>
          {/* 确定 */}
          {translate('alert.sure')}
        </Button>
        <Button onClick={cancel} color="primary">
          {/* 取消 */}
          {translate('alert.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}