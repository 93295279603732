import React from 'react'
export default ({
    width = 16,
    height = 16,
    borderColor = "#FFFFFF",
    bgColor = "#88C057",
    color = "#CCCCCC",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="7650" width={width} height={height}>
    <path d="M960 0H64a64 64 0 0 0-64 64.016V960a64 64 0 0 0 64 64h896a64 64 0 0 0 64-64V64.016A64 64 0 0 0 960 0z"
        fill={color} p-id="7651"></path>
    <path d="M928 64.016H96A32 32 0 0 0 64 96v832a32 32 0 0 0 32 32h512a32 32 0 0 0 32-32V704a32 32 0 0 1 32-32h256a32 32 0 0 0 32-31.984V96a32 32 0 0 0-32-31.984z"
        fill={borderColor} p-id="7652"></path>
    <path d="M576 672a64 64 0 0 1 64-63.984h256V128H128v768h448V672z"
        fill={bgColor} p-id="7653"></path>
    <path d="M896 768c-8.848 0-16.848 3.584-22.64 9.36L832 818.736l-41.376-41.376a32 32 0 0 0-45.264 45.264l64.016 64A31.84 31.84 0 0 0 832 896c8.832 0 16.832-3.584 22.624-9.376l64-64A32 32 0 0 0 896 768z"
        fill={borderColor} p-id="7654"></path></svg>
    )