import React, { useState, useEffect } from "react"
import Select from 'react-select';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import FormField from '../../../components/ui/form/FormField'
import TextInput from '../../../components/ui/form/TextInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import RadioInput from '../../../components/ui/form/RadioInput'
import NumberInput from '../../../components/ui/form/NumberInput';
import IconInput from '../../../components/ui/form/IconInput';

import { withRouter } from "react-router-dom";

import { useNav } from '../../../provides/ui'
import { useConfig } from '../../../provides/app'
import { Nav, NavDropdown } from "../../../types/ui"
import { useLocale } from '../../../config/Locale'
// 弹框
import { LoadingDialog, SubmitedDialog } from '../../../components/ui/dialog'

import { FormTabPanel, FormTab, FormTabs } from '../../../components/ui/tabs/FormTabs';
import { useEditPageStyles } from '../../../assets/jss/pages'

import DropdownList from "./DropdownList"

const a11yProps = (index: number, label: string) => {
  return {
    index,
    label,
    ariaprefix: "product-edit",
  };
}


export default withRouter((props: any) => {
  const { history } = props
  const { get, save } = useNav()
  const { closePage } = useConfig()
  const { translate } = useLocale()
  const classes = useEditPageStyles()

  const [value, setValue] = React.useState(0);
  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }

  const [form, setForm] = useState<any>({})
  const setFormItem = (key: string, value: any) => {
    form[key] = value;
    setForm(Object.assign({}, form))
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)

  const loadData = () => {
    // 获取
    setLoading({
      open: true,
      text: '正在加载导航信息...'
    })
    get({
      id: form.id,
      success: (nav: Nav) => {
        // 关闭加载条
        setLoading(initialLoading)
        setForm(nav || { id: form.id })
      },
      failure: (message: string) => {
        // 关闭加载条 
        setLoading(initialLoading)
        reloadParams()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id !== form.id) {
      resetForm()
      setFormItem('id', id)
      loadData()
    }
    return () => {
    }
  });
  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        nav: {
          ...form
        }
      },
      success: (message: string) => {
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: message
        })
      },
      failure: (message: string) => {
        setLoading(initialLoading)
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  const renderPageTitle = (
    <Box className="page-title">
      {translate('ui.nav.edit')}
    </Box>)

  const positions = [
    { value: "1", label: "主导航" },
    { value: "2", label: "底部导航" }
  ]
  const getPosition = (value: string) => {
    const result = positions.filter((dt: any) => dt.value === value)
    return result && result.length > 0 ? result[0] : undefined
  }
 
  const labelWidth = 120

  const renderForm = <Box>
    <form onSubmit={onSubmit}>
      <Box className={classes.contentHeader}>
        <FormTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}>
          <FormTab {...a11yProps(0, '导航信息')} />
          <FormTab {...a11yProps(1, '下拉设置')} />
        </FormTabs>
      </Box>
      <Box className={classes.contentContainer}>
        <FormTabPanel
          ariaprefix="product-edit"
          value={value}
          index={0}>
          <Box className={classes.margin}>
            <FormField
              labelWidth={labelWidth}
              label="导航位置：">
              <Box style={{ width: 150 }}>
                <Select
                  value={getPosition(form.position)}
                  onChange={(selectOption: any) => {
                    setFormItem('position', selectOption.value)
                  }}
                  options={positions}
                />
              </Box>
            </FormField>

            <TextInput
              labelWidth={labelWidth}
              value={`${form.name || ''}`}
              fullWidth
              label="导航名称："
              placeholder="导航名称具有唯一性..."
              onChange={(event: any) => {
                setFormItem('name', event.target.value)
              }} />

            <TextInput
              labelWidth={labelWidth}
              value={`${form.link || ''}`}
              fullWidth
              label="链接地址："
              placeholder=""
              onChange={(event: any) => {
                setFormItem('link', event.target.value)
              }} />

            <RadioInput
              labelWidth={labelWidth}
              label="显示平台："
              value={`${form.display || ''}`}
              options={[
                { label: "所有显示", value: "all" },
                { label: "仅PC端显示", value: "pc" },
                { label: "仅手机端显示", value: "mobile" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('display', value)
              }} />

            <RadioInput
              labelWidth={labelWidth}
              label="下拉列表："
              value={`${form.dropdown || ''}`}
              options={[
                { label: "无", value: "none" },
                { label: "仅PC端显示", value: "pc" },
                { label: "全部平台显示", value: "all" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('dropdown', value)
              }} />


            <RadioInput
              labelWidth={labelWidth}
              label="下拉类型："
              value={`${form.dropdownType || ''}`}
              options={[
                { label: "弹出框", value: "popper" },
                { label: "抽屉", value: "drawer" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('dropdownType', value)
              }} />


            <RadioInput
              labelWidth={labelWidth}
              label="下拉列表排列："
              value={`${form.dropdownOrientation || ''}`}
              options={[
                { label: "列表横排", value: "horizontal" },
                { label: "列表竖排", value: "vertical" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('dropdownOrientation', value)
              }} />
              
              <IconInput
                labelWidth={120}
                value={form.icon}
                label="图标："
                onChange={(value: string | number) => {
                  setFormItem('icon', value)
                }} />
            {/* 排序 */}
            <NumberInput
              labelWidth={120}
              value={form.sorter}
              inputWidth={60}
              label="排序："
              onChange={(value: string | number) => {
                setFormItem('sorter', value)
              }} />

            <RadioInput
              labelWidth={labelWidth}
              label="状态："
              value={`${form.status || ''}`}
              options={[
                { label: "未启用", value: "0" },
                { label: "启用", value: "1" }
              ]}
              onChange={(e: any, value: any) => {
                setFormItem('status', value)
              }} />
          </Box>
        </FormTabPanel>

        <FormTabPanel
          ariaprefix="product-edit"
          value={value} index={1}>
          <DropdownList
            position={form.position}
            data={form.dropdownList}
            onChange={(data: NavDropdown[]) => {
              setFormItem('dropdownList', data)
            }}
          />
        </FormTabPanel>
      </Box>

      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            closePage(history.location.pathname, (props: any) => {
              const { route } = props
              if (route.path) {
                history.push(route.path)
              }
            })
          },
          onReset: () => {
            loadData()
          }
        }} />
    </form>
  </Box>

  return <Box>
    {renderPageTitle}
    <Divider />
    {renderForm}

    <LoadingDialog {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/ui/nav`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`/ui/nav/edit?id=${Math.ceil(Math.random() * 9999)}&parentId=${form.parentId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        history.replace(`/ui/nav/edit?id=${form.id}`)
      }}
    />
  </Box>
})