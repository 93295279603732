import React from 'react'
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import IconBack from '@material-ui/icons/ArrowBackIos'; 
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Icon from "../../../components/Icon"
import Dialog from "../../../components/ui/dialog/BaseDialog"
import Refund from "./Refund"

type Props = {
    open:boolean,
    close:()=>void,
    success:(order:any)=>void,
    order:any,
}  
 
export default (props:Props)=>{
    const {open,close,success,order} = props
    const title = <Box style={{display:"flex",alignItems:"center",color:"#999"}}>
        <Icon name="Refund"/>
        处理退款
    </Box>
    return <Dialog {...{
        title,
        open,
        close
    }}>
            <Refund {...{
                    success,
                    order,
                    close
             }}/>
    </Dialog>
}