import React, { useState, useEffect } from 'react'

import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { TableProps } from './types'
import { sortKeys, getTableData } from './util';

import TextInput from '../../ui/form/InputBase'
import ImageInput from '../../ui/form/ImageInput'

const HeadTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#f3f3f3',
      color: '#333',
      padding:10,
      fontWeight:'bold',
      fontSize:14 
    }
  }),
)(TableCell);

const BodyTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      padding:5,
      fontSize:14
    },
  }),
)(TableCell);

export default (props: TableProps) => {
    const {
        classes,
        selected,
        productSpecs,
        loaded,
        onLoaded,
        onChange
    } = props

    const [columns, setColumns] = useState<any>([])
    const [tableData, setTableData] = useState<any>([])

    const [imageLoaded, setImageLoaded] = useState<any>({})


    const createColumns = (keys: string[]) => {
        // 重置字段
        const columns: any = []
        if (keys.length > 0) {
            keys.forEach((key: string) => {
                const item = selected[key]
                // 通过数据生成字段
                columns.push({
                    id: item.spec.id,
                    name: item.spec.name
                })
            });
        }
        setColumns(columns)
    }

    const onLoad = () => {
        // 重新排序，得到数组
        const keys = sortKeys(selected);
        if (keys) {
            // 生成字段和数据
            createColumns(keys)
            // 生成表格数据
            setTableData(getTableData({
                data: selected,
                keys,
                productSpecs
            }))
        }
    }
    
    useEffect(() => {
        if (!loaded) {
            onLoaded()
            onLoad()
        }
    })
    const initNumberInput = (type:"price"|"oprice"|"stock"|"inprice",value:number)=>{
        tableData.map((row: any)=>{
            switch (type) {
                case "oprice":
                    if(row.oprice === 0){
                        row.oprice = value;
                    }
                    break;
                    case "price":
                        if(row.price === 0){
                            row.price = value;
                        }
                        break;
                        case "inprice":
                            if(row.inprice === 0){
                                row.inprice = value;
                            }
                            break;
                        case "stock":
                            if(row.stock === 0){
                                row.stock = value;
                            }
                            break;
            
                default:
                    break;
            }
        })
        const newData = Object.assign([],tableData)
        setTableData(newData)
    }
    const onDataChange = ()=>{
        const newData = Object.assign([],tableData)
        setTableData(newData)
        onChange(newData)
    }

    return (
        <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((column: any) => <HeadTableCell key={column.id} className={classes.dynamicTableCell}>{column.name}</HeadTableCell>)}
                        <HeadTableCell className={classes.fixedTableCell}>售价</HeadTableCell>
                        <HeadTableCell className={classes.fixedTableCell}>原价</HeadTableCell>
                        <HeadTableCell className={classes.fixedTableCell}>进价</HeadTableCell>
                        <HeadTableCell className={classes.fixedTableCell}>库存（件）</HeadTableCell>
                        <HeadTableCell className={classes.fixedTableCell}>图片</HeadTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row: any, index: number) => (
                        <TableRow key={index}>
                            {columns.map((column: any, colIndex: number) => {
                                const data = row[column.id]
                                // 上一行同列
                                const prev = index > 0 ? tableData[index - 1][column.id] : undefined
                                // 下一行同列
                                const next = tableData.length - 1 > index ? tableData[index + 1][column.id] : undefined
                                // 显示的DOM节点
                                let node
                                if (prev && prev.value === data.value) {
                                    // 当前和上一行同列一样，不显示
                                } else if ((!prev || (prev && prev.value !== data.value))
                                    && next && next.value === data.value) {
                                    // 没有上一行或与上一行不一样
                                    // 当前和下一行同列一样，且没有上一行
                                    // 显示合并行
                                    node = <BodyTableCell
                                        rowSpan={data.rowSpan}
                                        key={colIndex}
                                        className={classes.dynamicTableCell}>{data.value}</BodyTableCell>
                                } else {
                                    node = <BodyTableCell
                                        key={colIndex}
                                        className={classes.dynamicTableCell}>{data.value}</BodyTableCell>
                                }
                                return node
                            })}
                            <BodyTableCell>
                                <TextInput
                                    value={`${row.price}`}
                                    type="number"
                                    width={70}
                                    onBlur={(event: any)=>{
                                        initNumberInput("price",event.target.value)
                                    }}
                                    onChange={(event: any) => {
                                        row.price = event.target.value
                                        onDataChange()
                                    }} />
                            </BodyTableCell>
                            <BodyTableCell>
                                <TextInput
                                    value={`${row.oprice}`}
                                    type="number"
                                    width={70}
                                    onBlur={(event: any)=>{
                                        initNumberInput("oprice",event.target.value)
                                    }}
                                    onChange={(event: any) => {
                                        row.oprice = event.target.value
                                        onDataChange()
                                    }} />
                            </BodyTableCell>
                            <BodyTableCell>
                                <TextInput
                                    value={`${row.inprice}`}
                                    type="number"
                                    width={70}
                                    onBlur={(event: any)=>{
                                        initNumberInput("inprice",event.target.value)
                                    }}
                                    onChange={(event: any) => {
                                        row.inprice = event.target.value
                                        onDataChange()
                                    }} />
                            </BodyTableCell>
                            <BodyTableCell>
                                <TextInput
                                    value={`${row.stock}`}
                                    width={70}
                                    onBlur={(event: any)=>{
                                        initNumberInput("stock",event.target.value)
                                    }}
                                    onChange={(event: any) => {
                                        row.stock = event.target.value
                                        onDataChange()
                                    }} />
                            </BodyTableCell>
                            <BodyTableCell>
                                    <ImageInput
                                        labelWidth={0}
                                        value={row.image}
                                        size="small"
                                        onChange={(url: string) => {
                                                row.image = url
                                                 onDataChange()
                                        }}
                                        loaded={imageLoaded['i_'+index]}
                                        onLoaded={() => { 
                                            imageLoaded['i_'+index] = true
                                            setImageLoaded(Object.assign({},imageLoaded)) 
                                        }}
                                    />
                            </BodyTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>)
}