import React, { forwardRef } from "react"
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props: any, ref: any) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref: any) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref: any) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props: any, ref: any) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props: any, ref: any) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: any, ref: any) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props: any, ref: any) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props: any, ref: any) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props: any, ref: any) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props: any, ref: any) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props: any, ref: any) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props: any, ref: any) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props: any, ref: any) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref: any) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props: any, ref: any) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props: any, ref: any) => <ViewColumn {...props} ref={ref} />)
};

export default (props: any) => {
  return <MaterialTable
    style={{
      height:"auto",
    }}
    localization={{
      pagination: {
        labelRowsSelect: "条",
        firstAriaLabel: "首页",
        firstTooltip: "首页",
        previousAriaLabel: "上一页",
        previousTooltip: "上一页",
        nextAriaLabel: "下一页",
        nextTooltip: "下一页",
        lastAriaLabel: "尾页",
        lastTooltip: "尾页"
      },
      header: {
        actions: ''
      },
      toolbar: {
        nRowsSelected: '{0} row(s) 选中',
        searchTooltip: "搜索",
        searchPlaceholder: "输入关键字查询..."
      },
      body: {
        emptyDataSourceMessage: '未找到匹配的数据.',
        addTooltip: '添加',
        deleteTooltip: '删除',
        editTooltip: '编辑',
        editRow: {
          cancelTooltip: '取消',
          saveTooltip: '确认',
          deleteText: "您确定删除该条目么？"
        },
        filterRow: {
          filterTooltip: '筛选',
        }
      }
    }}
    icons={tableIcons}
    {...props}
  />
}