import {
    keyPrefix,
    setObject,
    getObject
} from './base'

import { Route,CurrentRoutes} from '../types/app'

// 首页路由
const indexRoute:Route = {
    path:'/',
    paths:['/'],
    component : require(`../pages`).default,
    extra:{
      title:'首页'
    }
}
// 获取当前路由在历史的序号
export const historyIndex = (path:string)=>{
    let paths = histories() || []
    return paths.indexOf(path)
}
// 获取历史路由
export const histories = ()=>{
    return getObject(`${keyPrefix}-histories`)
}
/** 获取不相同的第一条历史路由 */
export const nearHistory = (path:string, index:number) : any =>{
    let paths = histories() || []
    if(paths.length > index){
        const near = paths[index];
        if(near !== path){
            return near
        }else{
            // 是当前路由
            // 继续判断下一个
            return nearHistory(path,index+1)
        }
    }
}
// 删除历史
export const removeHistory = (path:string)=>{
    let paths = histories() || []
    setObject(`${keyPrefix}-histories`,paths.filter((p:string)=>p!=path))
}
// 保存历史路由
export const saveHistory = (path:string)=>{
    // 需要先移除历史记录，改变路由历史顺序
    removeHistory(path)
    // 重新保存历史
    let paths = histories() || []
    if(paths.length > 0){
        paths.unshift(path)
    }else{
        paths.push(path)
    }
    setObject(`${keyPrefix}-histories`,paths.filter((p:string)=>p))
}
// 删除所有历史
export const clearHistories = ()=>{
    setObject(`${keyPrefix}-histories`,[])
}
// 清空路由
export const clear = ():CurrentRoutes=>{
    setObject(`${keyPrefix}-currents`,{})
    setObject(`${keyPrefix}-current`,{})
    // 默认添加首页
    return setCurrent(indexRoute)
}
/** 删除指定路由 */
export const removeOther = (route:Route): CurrentRoutes =>{
    setObject(`${keyPrefix}-currents`,{})
    setObject(`${keyPrefix}-current`,{})
    return setCurrent(route)
}
// 获取当前路由 
export const getCurrent = ():Route=>{
    return getObject(`${keyPrefix}-current`)
}
// 获取打开的路由
export const getCurrents = ():object=>{
    return getObject(`${keyPrefix}-currents`)
}

/** 设置当前的路由 */
export const setCurrent = (route:Route) : CurrentRoutes =>{
    const routes:any = getCurrents() || {}
    if(Object.keys(routes).length === 0 && route.path !== indexRoute.path ){
        // 打开的路由总数为0，且当前路由不是首页，先添加首页到列表
        routes[indexRoute.path] = indexRoute
    }
    // 保存
    routes[route.path] = route
    setObject(`${keyPrefix}-current`,route)
    setObject(`${keyPrefix}-currents`,routes)
    let tabIndex = 0
    Object.keys(routes).map((key,index)=>{
        const r = routes[key]
        if(r.path === route.path){
            tabIndex = index
        }
    })
    return {tabIndex,route,routes}
}
/** 删除指定路由 */
export const remove = (path:string): CurrentRoutes =>{
    // 当前路由
    let route:Route = getCurrent() || {}
    const routes:any = getCurrents() || {}
    if(route.path === path){
        // 如果当前路由是要删除的路由
        // 需要查找历史，获取上一次历史路由
        // 获取到当前路由在历史路由中的序号
        const index = historyIndex(path)
        if(index != -1){
            // 查找最近的历史路由
            const near = nearHistory(path,index)
            route = routes[near]
        }
    }
    // 删除路径历史
    removeHistory(path)
    // 删除打开的路由
    delete routes[path]
    if(Object.keys(routes).length === 0 ){
        // 打开的路由总数为0，添加首页到缓存
        return setCurrent(indexRoute)
    }
    // 保存当前路由列表
    setObject(`${keyPrefix}-currents`,routes)
    setObject(`${keyPrefix}-current`,route)
    let tabIndex = 0
    Object.keys(routes).map((key,index)=>{
        const r = routes[key]
        if(route && r.path === route.path){
            tabIndex = index
        }
    })
    return {tabIndex,route,routes}
}