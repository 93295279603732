
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useDialogTitleStyles = makeStyles((theme: Theme) =>
createStyles({
  root: {
    margin: 0,
    background: '#f5f5f5',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: '1px solid #dedede',
    fontSize: '1rem'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 5
  },
}))