
export const version = process.env.REACT_APP_version
/**
 * 默认模块ID
 */
export const moduleId = process.env.REACT_APP_moduleId
/**
   * 小型网站：miniature
   * 通用网站：community
   * 企业版：enterprise
   * 专业版：professional
   */
export const appType = process.env.REACT_APP_appType
// appId
export const appId = process.env.REACT_APP_appId 
// appSecret
export const appSecret = process.env.REACT_APP_appSecret
// 接口前缀
export const API = process.env.REACT_APP_API
// 完整接口地址
export const fullApi = process.env.REACT_APP_fullApi
// 附件前缀
export const attachPrefix = process.env.REACT_APP_attachPrefix
// 聊天服务器地址
export const chatHost = process.env.REACT_APP_chatHost
// 列表分类
export const catTypeList= process.env.REACT_APP_catTypeList
// 单页分类
export const catTypeSingle= process.env.REACT_APP_catTypeSingle
// 组图分类
export const catTypeImageList= process.env.REACT_APP_catTypeImageList
// 验证码地址
export const identifyCode = process.env.REACT_APP_identifyCode

	/** 生成订单 */
export const ORDER_STATUS_CREATE = "0";		
	/** 订单待付款 */
export const ORDER_STATUS_PAY_WAIT = "1";
	/** 订单已付款 */
export const ORDER_STATUS_PAY_SUCCESS = "2";
	/** 订单付款失败 */
export const ORDER_STATUS_PAY_FAIL = "3";
	/** 订单已发货，在途 */
export const ORDER_STATUS_SEND_ONWAY = "4";
	/** 订单已到货 */
export const ORDER_STATUS_SEND_DEST = "5";
	/** 订单已收货 */
export const ORDER_STATUS_SEND_OK = "6";
	/** 订单已评价 */
export const ORDER_STATUS_COMMENTED = "7";
	
	/** 订单拒收货 */
export const ORDER_STATUS_REJECT = "10";
	/** 订单拒收退货在途 */
export const ORDER_STATUS_REJECT_ONWAY = "11";
	/** 订单拒收退货到途 */
export const ORDER_STATUS_REJECT_DEST = "12";
	/** 订单拒收资金退回 */
export const ORDER_STATUS_REJECT_RETURN_FUND = "13";
	/** 订单拒收完成／资金到帐 */
export const ORDER_STATUS_REJECT_OK = "14";
	
	/** 订单退款申请 */
export const ORDER_STATUS_REFUND = "20";
	/** 订单退款申请通过 */
export const ORDER_STATUS_REFUND_APPLY_OK = "21";
	/** 订单退款申请不通过 */
export const ORDER_STATUS_REFUND_APPLY_FAIL = "22";
	/** 订单退款退货在途 */
export const ORDER_STATUS_REFUND_ONWAY = "23";
	/** 订单退款退货到途 */
export const ORDER_STATUS_REFUND_DEST = "24";
	/** 订单退款资金退回 */
export const ORDER_STATUS_REFUND_RETURN_FUND = "25";
	/** 订单退款完成／资金到帐 */
export const ORDER_STATUS_REFUND_OK = "26";
	/** 订单取消 */
export const ORDER_STATUS_CANCEL = "-1";
	/** 订单完成 */
export const ORDER_STATUS_OK = "99";


// 用户日志类型
export const MODE_LIKE_ARTICLE = "likeArticle"
export const MODE_LIKE_COMMENT = "likeComment"

export const ORDER_STATUS:any = {
	"0":"创建订单",
	"1":"待付款", 
	"2":"待发货",//已付款
	"3":"付款失败",
	"4":"已发货",
	"5":"已到货",
	"6":"已收货",
	"10":"拒收货",
	"11":"拒收退货在途",
	"12":"拒收退货到途",
	"13":"拒收资金退回",
	"14":"拒收退货完成",
	"20":"申请退款",
	"21":"退款申请通过",
	"22":"退款申请不通过",
	"23":"退货在途",
	"24":"退货到途",
	"25":"退款处理中...",
	"26":"已退款"
  }
// 快递公司代码
export const EXPRESS_LIST = [
	{"name":"顺丰速运","code":"SF"},
	{"name":"百世快递","code":"BEST"},
	{"name":"中通快递","code":"ZTO"},
	{"name":"申通快递","code":"STO"},
	{"name":"圆通速递","code":"YTO"},
	{"name":"韵达速递","code":"YD"},
	{"name":"邮政快递包裹","code":"YZPY"},
	{"name":"EMS","code":"EMS"},
	{"name":"天天快递","code":"HHTT"},
	{"name":"京东快递","code":"JD"},
	{"name":"优速快递","code":"UC"},
	{"name":"德邦快递","code":"DBL"},
	{"name":"宅急送","code":"ZJS"}
]

export const ordersDownloadUrl = '/trade/excel/downloadOrders'
export const areaJsonDownloadUrl = '/common/area/downloadJson'
export const alipayRefundUri = '/alipay/refund'