import { combineReducers } from 'redux';

import {State} from '../../state/content';

import {
    initialState as catInitialState,
    reducer as catReducer
} from './cat';

import {
    initialState as articleInitialState,
    reducer as articleReducer
} from './article';


export const initialState: State = {
    cat: catInitialState,
    article: articleInitialState
};

export const reducers = combineReducers<State>({
    cat: catReducer,
    article: articleReducer
});