import React from "react";

import NumberFormat from "react-number-format";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "./TextField";
import { NumberInputProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    label: {
      textAlign: "right",
      flexWrap: "nowrap"
    },
    input: {
      flex: 1,
      margin: theme.spacing(1)
    },
    inputInput: {},
    tips: {
      padding: theme.spacing(1, 0),
      fontSize: 12,
      color: "#999"
    }
  })
);

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
  prefix?: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, prefix, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
      prefix={prefix || ""}
    />
  );
}

export default (props: NumberInputProps) => {
  const classes = useStyles();
  const {
    label,
    labelWidth,
    inputWidth,
    tips,
    value,
    prefix,
    onChange
  } = props;
  const labelStyle: React.CSSProperties = labelWidth
    ? {
        width: labelWidth
      }
    : {};
  const inputStyle: React.CSSProperties = inputWidth
    ? {
        width: inputWidth
      }
    : {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box className={classes.root}>
      <Box component="span" style={labelStyle} className={classes.label}>
        {label}
      </Box>
      <Box component="span" className={classes.input}>
        <TextField
          className={classes.inputInput}
          value={value}
          onChange={handleChange}
          style={inputStyle}
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: { prefix }
          }}
        />
        {tips && <Box className={classes.tips}>{tips}</Box>}
      </Box>
    </Box>
  );
};
