import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { CloseButton } from '../button';
import {BaseDialogProps} from './types'

const useStylesTitle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      background: '#f5f5f5',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px solid #dedede',
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
  }))

export default function BaseDialog(props: BaseDialogProps) {
  const { children, open, title, close,...rest } = props
  const classes = useStylesTitle()

  if (open) {
    return (
      <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...rest}
      >
        <DialogTitle id="alert-dialog-title">
          {/* 标题 */}
          {title}
        {close ? (
          <span className={classes.closeButton} onClick={() => { close() }}>
            <CloseButton open={true} close={close} />
          </span>
        ) : null}
        </DialogTitle>
          <DialogContent>
            <Box id="alert-dialog-description">
              {/* 内容 */}
              {children}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
  return <></>
}

