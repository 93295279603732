import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { withRouter } from "react-router-dom";

import InputAdornment from '@material-ui/core/InputAdornment';
import CircleUserIcon from '../../components/icons/CircleUser'
import PasswordIcon from '../../components/icons/Password'
import CodeIcon from '../../components/icons/Code'

import { useLocale } from '../../config/Locale'

// 弹框
import { LoadingDialog, MessageDialog } from '../../components/ui/dialog'

// 系统配置
import * as config from '../../config'
// 语种组件
import Locale from '../../components/app/locale'
// 用户逻辑
import { useAccount } from '../../provides/sys/AccountProvide'

function MadeWithLove(props: any) {
  const { children } = props

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://www.j2mvc.com/">
        {children}
      </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }, 
    header: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      margin: '10px',
      lineHeight: '50px'
    },
    floatLeft: {
      float: "left"
    },
    inputRoot: {
      position: 'relative',
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff',
      },
      marginTop: theme.spacing(20),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    inputInput: {
      width: 'auto',
      marginTop: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      border: 0,
      backgroundColor: '#fff',
    },
    inputIcon: {
      margin: '0 0 5px 10px'
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      margin: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    formControl: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    codeControl: {
      display: 'flex',
      marginTop: theme.spacing(2),
      flexDirection: 'row'
    },
    loginCode: {
      height: 38,
      display: 'block',
      margin: '20px 10px',
      border: '1px solid #eee',
      borderRadius: 5,
    }
  }));


const Login = (props: any) => {
  const { history } = props
  const classes = useStyles();
  const { translate } = useLocale()

  // 表单数据
  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)
  const [code, setCode] = useState(null)
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const [loading, setLoading] = useState({ open: false, text: '' })
  const { login } = useAccount()

  let timestamp = (new Date()).valueOf();
  const url = config.identifyCode + '?type=login&timestamp=' + timestamp
  const [identifyCode, setIdentifyCode] = useState<string>(url)
  const handleIdentifyCode = () => {
    setIdentifyCode(url)
  }

  const handleSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.login')
    })
    login({
      loginType:"account",
      userType:"1",
      username,
      password,
      code,
      appSecret: config.appSecret,
      success: function (msg: string) {
        // 跳转首页
        history.push('/')
        window.location.reload(true)
        setLoading({
          open: true,
          text: translate('loadingText.goIndex')
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: translate('alert.tips'),//提示
          text: msg
        })
      }
    })
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box className={classes.header}>
          <Box className={classes.floatLeft}>
            <Box component="span" className="app-logo">
              <img width={50} height={50} src={require('../../assets/icons/react.svg')} />
            </Box>
          </Box>
          <Box className={classes.floatLeft}>SIP管理系统</Box>
          <Locale />
        </Box>
        <Box className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {translate('signIn')}
          </Typography>
        </Box>
        <form className={classes.form} onSubmit={handleSubmit} >
          <Box className={classes.formControl}>
            <TextField
              id="username"
              name="username"
              fullWidth
              label={translate('username.label')}
              placeholder={translate('username.placeholder')}
              onChange={(event: any) => { setUsername(event.target.value) }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <CircleUserIcon className={classes.inputIcon} width={20} height={20} />
                  </InputAdornment>
                )
              }}
          />
          </Box>
          <Box className={classes.formControl}>
            <TextField
              id="password"
              name="password"
              type="password"
              required
              fullWidth
              label={translate('password.label')}
              placeholder={translate('password.placeholder')}
              onChange={(event: any) => { setPassword(event.target.value) }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon className={classes.inputIcon} width={20} height={20} />
                  </InputAdornment>
                )
              }}
          />
          </Box>
          <Box className={classes.codeControl}>
            <TextField
              id="code"
              name="code"
              required
              label={translate('identifyCode.label')}
              placeholder={translate('identifyCode.placeholder')}
              onChange={(event: any) => { setCode(event.target.value) }}
              InputProps={{
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <CodeIcon className={classes.inputIcon} width={20} height={20} />
                  </InputAdornment>
                )
              }}
          />
            <img className={classes.loginCode} onClick={handleIdentifyCode} src={identifyCode} />

          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {translate('signIn').toUpperCase()}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {translate('forgot')}
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {translate('signUpTip')}
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <MadeWithLove>{translate('technicalSupport')}</MadeWithLove>
          </Box>
        </form>
        <LoadingDialog open={loading.open} text={loading.text} />
        <MessageDialog
          onClose={() => {
            setMessage(initialMessage)
          }}
          message={message} />
      </Grid>
    </Grid >
  )
}
export default withRouter(Login) 