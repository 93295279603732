import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Icon from "../components/Icon"

import { withRouter } from "react-router-dom"; 
// export { ColorTabs, ColorTab } from "./ColorTabs"
// export { BorderTabs, BorderTab } from "./BorderTabs"
// export { MenuTabs, MenuTab } from "./MenuTabs"
// export { NavbarTabs, NavbarTab } from "./NavbarTabs"
// export { SpecialTabs, SpecialTab } from "./SpecialTabs"
// export { FormTabPanel, FormTab, FormTabs ,a11yProps } from "./FormTabs" 
import { MainTab,MainTabs } from './components/MainTabs';
import { useLocale } from '../config/Locale'
import { useConfig } from '../provides/app' 

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      marginRight: 4
    },
    screenMainContainer: {
      flex: 1,
      padding: 0
    }
  }));

const initialState = {
  mouseX: null,
  mouseY: null,
  route: null,
};

const Main = (props: any) => {
  const {
    children,
    history,
    tabIndex,
    currentRoute,
    currentRoutes
  } = props;
  const { translate } = useLocale()
  const { closeAllPage, closePage, closeOtherPage } = useConfig();

  // 页面样式
  const classes = useStyles();

  const [state, setState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
    route: null | any;
  }>(initialState);

  const handleClick = (route: any) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      route,
    });  
  };

  const handleClose = (type: any) => () => {
    setState(initialState);
    switch (type) {
      case "closeAll":
        closeAllPage((props: any) => {
          const { route } = props
          history.push(route.path)
        })
        break;
      case "closeOther":
        if (state.route) {
          closeOtherPage(state.route, (props: any) => {
            const { route } = props
            history.push(route.path)
          })
        }
        break;
      case "close":
        if (state.route) {
          closePage(state.route.path, (props: any) => {
            const { route } = props
            history.push(route.path)
          })
        }
        break;
    }
  };

  return (<>
      <MainTabs
        value={tabIndex}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >{currentRoutes ? Object.keys(currentRoutes).map((key: any, index:number) => {
        const route = currentRoutes[key]
        const active = currentRoute && currentRoute.path === route.path
        return (<MainTab key={index}
          to={route.path}
          label={<span style={{display:"flex",alignItems:"center"}}>
          {route.extra.icon && <Icon name={route.extra.icon} color="inherit"/>}
          {route.extra.title}
          </span>}
          active={active}
          onClose={(route: any) => {
            if (route && route.path) {
              history.push(route.path)
            }
          }}
          onContextMenu={handleClick(route)}
          onClick={() => {
            history.push(route.path)
          }} />)
      }) : (<MainTab 
        to="/"
        label="首页"
        onClick={() => {
          history.push("/")
        }} />
        )}
      </MainTabs>
      <Box id="screenMainContainer" className={classes.screenMainContainer}>
        {children}
      </Box>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose("close")}>
          {translate('closePage')}
        </MenuItem>
        <MenuItem onClick={handleClose("closeOther")}>
          {translate('closeOtherPage')}
        </MenuItem>
        <MenuItem onClick={handleClose("closeAll")}>
          {translate('closeAllPage')}
        </MenuItem>
      </Menu>
    </>
  );
}
export default withRouter(Main)