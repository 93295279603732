
import * as actions from '../../store/actions/shopping/brand'
import { useDispatch } from "react-redux";

interface Result {
    getList: Function,
    getActiveList: Function,
    getListByName:Function,
    get: Function,
    save: Function,
    remove: Function,
    setStatus: Function,
}

/**
 * 系统配置逻辑
 */
export const useBrand= (): Result => {
    const dispatch:Function = useDispatch();

    // 获取列表
    const getList = (props: any) => {
        const {params, success, failure } = props
        dispatch(actions.fetchList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                const pagination = data.result.pagination
                
                // 通知Redux
                dispatch(actions.fetchListSucess(list,pagination))
                // 成功返回
                if (success) {
                    success(list,pagination)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }
    // 获取启用列表
    const getActiveList = (props: any) => {
        const { params,success, failure } = props
        dispatch(actions.fetchActiveList(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                const pagination = data.result.pagination
                // 通知Redux
                dispatch(actions.fetchListSucess(list,pagination))
                // 成功返回
                if (success) {
                    success(list,pagination)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }

    // 获取启用列表
    const getListByName = (props: any) => {
        const { params,success, failure } = props
        dispatch(actions.fetchListByName(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const list = data.result.list
                // 成功返回
                if (success) {
                    success(list)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }
    // 获取
    const get = (props: any) => {
        const { params ,success, failure } = props
        dispatch(actions.fetch(params)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                const brand = data.result.brand
                // 成功返回
                if (success) {
                    success(brand)
                }
            } else {
                // 失败返回
                failure(message || '请求失败')
            }
        })
    }


    // 保存
    const save = (props: any) => {
        const { data ,success, failure } = props
        dispatch(actions.save(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }

    // 删除
    const remove = (props: any) => {
        const { id,success, failure } = props
        dispatch(actions.remove(id)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    // 设置状态
    const setStatus = (props: any) => {
        const { data,success, failure } = props
        dispatch(actions.setStatus(data)).then((res: any) => {
            const data = res.payload && res.payload.data;
            const message = data && data.message
            if (data && data.code === 1) {
                // 成功返回
                if (success) {
                    success(message || '请求成功')
                }
            } else {
                // 失败返回
                if (failure){
                    failure(message || '请求失败')
                }
            }
        })
    }
    
    return {
        getList,
        getActiveList,
        getListByName,
        get,
        save,
        remove,
        setStatus
    }
}