import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#666",
    className = "icon"
})=>(<svg className={className} viewBox="0 0 1024 1024" version="1.1" 
xmlns="http://www.w3.org/2000/svg" p-id="2892" 
width={width} height={height}>
<path fill={color} d="M390.088928 561.624211c-40.580231-46.854121-93.196589-137.204895-65.687048-246.345618 146.501632 64.620763 131.918495 253.587556 147.796114 541.943986l-32.841989 0 0-65.687048L324.400856 791.535531c0 0-114.953102 8.341992-114.953102 49.267077s90.942245 82.108043 328.441381 82.108043c237.511415 0 279.174304-62.321394 279.174304-82.108043 0-19.822464-23.61586-49.267077-114.954125-49.267077l-65.687048 0c0 0-12.187577-185.849802 114.954125-377.722784 16.077164 22.36538 38.229696 73.010851-32.846082 164.235528 84.638679-7.966439 101.554954-59.416228 98.533131-131.393539 23.795962 12.33698 41.923832 37.214577 32.846082 131.393539 49.462528-29.791513 76.492138-118.28601 0-180.659593 34.568307 2.086521 53.172013 3.332908 65.687048 65.686025 46.228881-40.772612-15.321964-156.48603-114.954125-131.37512-19.510356-118.483508-197.066261-102.047164-197.066261-32.846082 39.709397-7.862062 84.966137-6.371104 98.533131 16.425088-44.685735 1.790786-98.533131 8.290827-98.533131 131.37512 40.316218-45.567825 56.493665-69.51319 131.37512-65.686025-48.378847 29.591969-147.796114 164.909887-147.796114 476.254891-27.081799 0.398066-49.267077 0-49.267077 0s21.166065-442.902272-164.221202-574.789045c75.457576-6.931876 135.201262-8.131191 197.063191 114.954125 9.29776-67.330478 9.493212-211.760938-147.796114-197.063191 17.256012-33.897018 15.534811-57.69298 147.796114-49.266054-85.886089-126.45097-274.198989-54.327326-279.175327 65.687048 0 0-197.705827-40.712237-197.066261 164.221202 52.467979-51.829436 77.07133-73.80289 98.533131-82.113159-27.981285 38.71065-97.413634 141.42808 0 229.92667 3.365654-118.861108 30.071899-137.993864 49.267077-164.234505C243.408217 423.925059 247.428787 557.830815 390.088928 561.624211" p-id="2893"></path></svg>
)