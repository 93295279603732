import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1098 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="18988" width={width} height={height}>
    <path fill={color} d="M352.548571 799.451429l-28.598857 28.598857q-5.705143 5.705143-13.165714 5.705143t-13.165714-5.705143l-266.313143-266.313143q-5.705143-5.705143-5.705143-13.165714t5.705143-13.165714l266.313143-266.313143q5.705143-5.705143 13.165714-5.705143t13.165714 5.705143l28.598857 28.598857q5.705143 5.705143 5.705143 13.165714t-5.705143 13.165714l-224.548571 224.548571 224.548571 224.548571q5.705143 5.705143 5.705143 13.165714t-5.705143 13.165714zM690.322286 189.732571l-213.138286 737.718857q-2.267429 7.460571-8.850286 11.117714t-13.458286 1.462857l-35.401143-9.728q-7.460571-2.267429-11.117714-8.850286t-1.462857-13.970286l213.138286-737.718857q2.267429-7.460571 8.850286-11.117714t13.458286-1.462857l35.401143 9.728q7.460571 2.267429 11.117714 8.850286t1.462857 13.970286zM1065.691429 561.737143l-266.313143 266.313143q-5.705143 5.705143-13.165714 5.705143t-13.165714-5.705143l-28.598857-28.598857q-5.705143-5.705143-5.705143-13.165714t5.705143-13.165714l224.548571-224.548571-224.548571-224.548571q-5.705143-5.705143-5.705143-13.165714t5.705143-13.165714l28.598857-28.598857q5.705143-5.705143 13.165714-5.705143t13.165714 5.705143l266.313143 266.313143q5.705143 5.705143 5.705143 13.165714t-5.705143 13.165714z" p-id="18989"></path></svg>
    )
