import React from "react"

import classNames from "classnames"
import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import { Link } from "./types"
import LinkInput from "./LinkInput"
import { useItemMethods } from './components/Util';

type Props = {
  data?: Link[],
  onChange?: (data: Link[]) => void
}

export default (props: Props) => {
  const { data = [], onChange } = props
  const classes = useUIInputsStyles()

  const {
    onItemAdd,
    onItemSave,
    onItemRemove,
    sortUp,
    sortDown
  } = useItemMethods({
    data,
    initialData: {
      title: "",
      link: ""
    },
    onChange: (data: any) => {
      if (onChange) {
        onChange(data)
      }
    }
  });


  return <Box className={classes.list}>
    <Box className={classNames(classes.listToolbar, classes.light)}>
      <Box className={classes.listToolbarTitle}> <IconList />链接列表</Box>
      <Box className={classes.grow} />
      <Button color="primary" onClick={()=>onItemAdd()}>
        <IconAdd />添加链接
        </Button>
    </Box>
    {data.map((link: any, index: number) => <LinkInput
      key={link.id || index}
      link={link}
      onSave={onItemSave}
      onRemove={onItemRemove}
      showUp={index > 0}
      showDown={index < data.length - 1}
      sortUp={() => {
        sortUp(index)
      }}
      sortDown={() => {
        sortDown(index)
      }} />)}

  </Box>
}