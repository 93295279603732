import React from "react"

import classNames from "classnames"
import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';

import { TabItem } from "./types"
import { PageSetting } from '../../../../types/ui';

import InputListItem from "./InputListItem"
import ComponentsInput from "./ComponentsInput"

import ItemTools from "./components/ItemTools"

type Props = {
    item: TabItem,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave: (item: TabItem) => void,
    onRemove: (item: TabItem) => void,
}
/** 标签页输入 */
export default (props: Props) => {
    const classes = useUIInputsStyles()
    const { item = {}, showUp, showDown, sortUp, sortDown, onSave, onRemove } = props

    const [form, setForm] = React.useState<any>(item || {})
    const setFormItem = (key: string, value: any) => {
        form[key] = value;
        setForm(Object.assign({}, form))
    }

    const [tab, setTab] = React.useState<any>(form.tab || {})
    const setTabItem = (key: "label" | "link", value: any) => {
        tab[key] = value;
        setTab(Object.assign({}, tab))
        setFormItem("tab", tab)
    }

    return <Box className={classNames(classes.listItem, {
        [classes.editing]: form.editing
    })}>
        <Box className={classes.itemFields}>
            <InputListItem
                key={0}
                editing={form.editing}
                option={{
                    id: "0",
                    name: 'label',// 类型名称
                    alias: '标签名',// 别名
                    variableType: 'string',// 变量类型
                    notNull: true,// 非空
                }}
                value={tab.label}
                onChange={(name: string, value: any) => {
                    setTabItem('label', value)
                }} />

            <InputListItem
                key={1}
                editing={form.editing}
                option={{
                    id: "1",
                    name: 'link',// 类型名称
                    alias: '标签链接',// 别名
                    variableType: 'link',// 变量类型
                    notNull: true,// 非空
                }}
                value={tab.link}
                onChange={(name: string, value: any) => {
                    setTabItem('link', value)
                }} />
            {/** 标签页子集 */}
            <ComponentsInput
                data={form.children}
                onChange={(data: PageSetting[]) => setFormItem("children", data)} />
        </Box>
        <ItemTools
            editing={form.editing}
            onSave={() => {
                setFormItem("editing", false);
                if (onSave) onSave(form);
            }}
            onCancel={() => setFormItem("editing", false)}
            onEditing={() => setFormItem("editing", true)}
            onRemove={() => {
                if (onRemove) onRemove(form);
            }}
            showUp={showUp}
            showDown={showDown}
            sortUp={sortUp}
            sortDown={sortDown} />
    </Box>
}