import { add } from '../../../../sip-sites/shopping/src/store/actions/trade/cart';
export const words = {
  technicalSupport: "技术支持 J2mvc",
  index: "首页",
  home: "首页",
  email: {
    label: "邮箱",
    placeholder: "邮箱格式如master@j2mvc.com"
  },
  username: {
    label: "用户名",
    placeholder: "用户名/手机号/邮箱"
  },
  password: {
    label: "密码",
    placeholder: "******"
  },
  identifyCode: {
    label: "验证码",
    placeholder: "请输入验证码"
  },
  loadingText: {
    login: "正在提交登录...",
    submit: "正在提交数据...",
    goIndex: "正在跳转管理界面...",
    data: '正在加载数据...'
  },
  keyword: {
    label: "关键字",
    placeholder: "关键字搜索..."
  },
  catName: {
    label: "分类名称：",
    placeholder: "请输入分类名称"
  },
  catAlias: {
    label: "分类别名：",
    placeholder: "请输入分类别名"
  },
  catIcon: {
    label: "分类图标：",
    placeholder: ""
  },
  catAddress: {
    label: "访问地址：",
    placeholder: "请输入访问地址,由字母数字_-组成"
  },
  title: {
    label: "标题",
    placeholder: "请输入标题"
  },
  linkAddress: {
    label: "链接地址",
    placeholder: "链接地址以http://或https://开头"
  },
  removing: "正在删除数据...",
  removeTips: "您正在删除数据!",
  removeAsk: "此操作不可恢复，确定要继续么？",
  sorter: "排序",
  status: "状态",
  on: "启用",
  off: "禁用",
  sure: "确定",
  refresh: "刷新",
  prevPage: "上一页",
  nextPage: "下一页",
  modify: "修改",
  modifyCat: "修改分类",
  append: "添加",
  appendCat: "添加分类",
  appendChildCat: "添加下级分类",
  removeCat: "删除分类",
  messages: "消息",
  profile: "账户设置",
  desc: "倒序",
  asc: "升序",
  account: "我的账户",
  notify: "最新消息",
  mailbox: "我的邮件",
  rememberMe: "记住我",
  close: "关闭",
  closeAll: "关闭所有",
  closePage: "关闭当前页面",
  closeAllPage: "关闭所有页面",
  closeOtherPage: "关闭其它页面",
  signIn: "登录",
  logout: "退出",
  forgot: "忘记密码?",
  signUpTip: `没有帐户?点击注册`,
  submit: "提交",
  save: "保存",
  cancel: "取消",
  subscribe: "订阅",
  reset: "重置",
  clear: "清除",
  add: "添加",
  mode: "修改",
  edit: "编辑",
  remove: "删除",
  removeBatch: "批量删除",
  all: "全部",
  list: "列表",
  whole: "全局",
  global: "全局",
  create: "创建",
  search: "搜索",
  link: "链接",
  scroll: "滚动",
  setting: "设置",
  manager: "管理",
  client: "客户端",
  ad: "广告",
  board: "留言",
  module: "模块",
  document: "文档",
  article: "文章",
  cat: "分类",
  allCats: "所有分类",
  chooseCat: "选择分类",
  table: "表格",
  content:{
    article:"文章",
    articles:"文章列表",
    cat:"文章分类",
    cats:"文章分类",
    addCat: "添加文章分类",
    deleteCat: "删除文章分类",
    editCat: "编辑文章分类",
    addArticle: "添加文章",
    deleteArticle: "删除文章",
    editArticle: "编辑文章",
    removingCat: "正在删除文章分类...",
    removingArticle: "正在删除文章...",
    removeCatTips: "您正在删除文章分类，将会同时删除所有子分类，会影响关联的文章!",
    removeArticleTips: "您正在删除文章!",
    removeAsk: "此操作不可恢复，确定要继续么？",
  },
  shopping: {
    shopping: "商城",
    product: "商品",
    products: "商品列表",
    productCat: "商品分类",
    addProduct: "添加商品",
    deleteProduct: "删除商品",
    editProduct: "编辑商品",
    removingCat: "正在删除商品分类...",
    removingProduct: "正在删除商品...",
    removeCatTips: "您正在删除商品分类，将会同时删除所有子分类，会影响关联的商品!",
    removeProductTips: "您正在删除商品!",
    removeAsk: "此操作不可恢复，确定要继续么？",
    attr: "属性",
    spec: "规格",
    saveSpecs:"保存规格",
    brand:{
      pageTitle:"品牌",
      choose:"选择品牌",
      add:"添加品牌",
      remove:"删除品牌",
      edit:"修改品牌",
      preview:"查看品牌描述",
      list:"品牌列表",
      removing:"正在删除品牌...",
      removeTips:"您正在删除品牌!",
      removeAsk:"此操作不可恢复，确定要继续么？",
      name:"品牌名称"
    },
  },
  spec:{
    manager:"规格管理",
    title:"规格",
    add:"添加规格",
    edit:"编辑规格",
    remove:"删除规格",
    list:"规格列表",
    removing: "正在删除规格...",
    removeTips: "您正在删除规格，会影响关联的商品!",
    removeAsk: "此操作不可恢复，确定要继续么？",
  },
  attr:{
    manager:"属性管理",
    title:"属性",
    add:"添加属性",
    edit:"编辑属性",
    remove:"删除属性",
    list:"属性列表",
    removing: "正在删除属性...",
    removeTips: "您正在删除属性，会影响关联的商品!",
    removeAsk: "此操作不可恢复，确定要继续么？",
  },
  trade:{
    order:{
      pageTitle:"订单管理",
      status:{
        label:"订单状态",
        choose:"选择订单状态"
      }
    },
    refundReason:{
      pageTitle:"退款原因设置",
      add:"添加退款原因",
      edit:"编辑退款原因",
      remove:"删除退款原因",
      save:"保存退款原因",
      removing:"正在删除退款原因...",
      removeTips:"您正在删除退款原因!",
      removeAsk:"此操作不可恢复，确定要继续么？",
    }
  },
  activity: "活动",
  promotion: "促销活动",
  coupon: "优惠券",
  freight: "运费",
  icon: "图标",
  children: "子集",
  order: "订单",
  delivery: "发货",
  integral: "积分",
  solid: "实心",
  exchange: "兑换",
  interact: "互动",
  comment: "评论",
  share: "分享",
  like: "点赞",
  collect: "收藏",
  stat: "统计",
  analysis: "分析",
  layout: "布局",
  item: "条目",
  typography: "排版",
  component: "组件",
  user: "用户",
  write: "输入",
  width: "宽",
  height: "高",
  alert: {
    tips: "提示",
    warning: "警告",
    disagree: "不同意",
    agree: "同意",
    sure: "确定",
    cancel: "取消"
  },
  fullScreen: "全屏",
  fullScreenExit: "退出全屏",
  loadListData: "正在加载列表数据...",
  image: "图片",
  file: "文件",
  dense: "密集",
  selected: "选中",
  goList: "返回列表",
  goAdd: "继续添加",
  goModify: "返回修改",
  preview: "预览",
  editor: {
    heading: "标题样式",
    bold: "加粗",
    italic: "斜体",
    underline: "下划线",
    strikethrough: "删除线",
    fontFamily: "字体",
    lineHeight: "行高",
    fontSize: "字体大小",
    fontColor: "字体颜色",
    backgroundColor: "背景颜色",
    alignLeft: "靠左",
    alignCenter: "居中",
    alignRight: "靠右",
    code: "代码块",
    blockquote: "块引用",
    link: {
      add: "添加超链接",
      remove: "取消超链接"
    },
    media: {
      image: "上传图片",
      video: "上传视频",
      audio: "上传音频"
    },
    orderList: "有序列表",
    unOrderList: "符号列表",
    indent: "缩进",
    outdent: "取消缩进",
    clear: "清除样式",
    history: {
      history: "历史",
      undo: "撤销",
      redo: "恢复"
    },
    fullScreen: '全屏',
    fullScreenExit: '退出全屏',
    preview: '预览',
    emoji:"表情" 
  },
  collapse: {
    open: '展开',
    close: '收起'
  },
  upload: {
    interruptTips: "您正在删除文件，这会影响文件关联的内容!",
    interruptAsk: "确定要中断上传么？",
    loadingGroupList: "正在加载分组数据...",
    removeTips: "您正在删除文件，这会影响文件关联的内容!",
    removeAsk: "此操作不可恢复，确定要继续么？",
    removingAttch: "正在删除附件...",
    movingAttach: "正在移动附件...",
    saveGroup: '正在保存分组...',
    removingItem: "正在移除条目...",
    uploaded: "上传完毕",
    uploading: "正在上传...",
    uploadingNoEllipsis: "正在上传",
    waitUpload: "等待上传.",
    removeGroupTips: "您正在删除附件分组，当前文件将移动到未分组!",
    onceMastFile: "只能选择一个文件",
    chooseImage: "选择图片",
    chooseFile: "选择文件",
    uploadFile: "上传文件",
    removeFile: "删除文件",
    uploadImage: "上传图片",
    uploadImages: "上传多张图片",
    uploadDrag: "拖拽文件到此处上传，或点击上传",
    uploadSubmit: "提交上传",
    insertAll: "全部插入",
    insertImage: "插入图片",
    insertSelected: "插入选中",
    insert: "插入",
    removeSelected: "删除选中",
    chooseAll: "选择全部",
    editGroupTip: "创建附件分组，以便方便查询...",
    addGroup: "添加分组",
    editGroup: "编辑分组",
    groupName: "分组名称",
    removeGroup: "删除分组",
    ungrouped: "未分组",
    localUpload: "本地上传",
    remoteFiles: "远程文件",
    fileLinks: "文件链接",
    removePictures: "远程图片",
    picturesLinking: "图片链接",
    moveToGroup: "移动到分组",
    uploadToGroup: "上传到分组",
  },
  common:{
    ad:{
      manager:"广告管理",
      pageTitle:"广告",
      add:"添加广告",
      remove:"删除广告",
      modify:"修改广告",
      list:"广告列表",
      removing:"正在删除广告...",
      removeTips:"您正在删除广告，这会影响所有关联!",
      removeAsk:"此操作不可恢复，确定要继续么？"
    },
    module:{
      pageTitle:"模块",
      choose:"选择模块",
      add:"添加模块",
      remove:"删除模块",
      modify:"修改模块",
      list:"模块列表",
      removing:"正在删除模块...",
      removeTips:"您正在删除模块，这会影响所有关联的分类及文章!",
      removeAsk:"此操作不可恢复，确定要继续么？",
      name:"模块名称"
    },
    indexed:{
      pageTitle:"索引管理",
      createArticleIndexed:"创建文章索引",
      clearArticleIndexed:"清空文章索引",
      createProductIndexed:"创建商品索引",
      clearProductIndexed:"清空商品索引",
    },
    friendUrl:{
      pageTitle:"友情链接管理",
      add:"添加链接",
      edit:"编辑链接",
      remove:"删除链接",
      save:"保存链接",
      removing:"正在删除友情链接...",
      removeTips:"您正在删除友情链接!",
      removeAsk:"此操作不可恢复，确定要继续么？",
    }
  },
  area:{ 
    choose:"选择行政区划",
    self:"行政区划",
    pageTitle:"行政区划",
    add:"添加行政区划",
    remove:"删除行政区划",
    removing:"正在删除行政区划...",
    modify:"修改行政区划",
    removeTips:"您正在删除行政区划，这会影响所有关联的数据!",
    removeAsk:"此操作不可恢复，确定要继续么？",
  },
  ui:{
    component:{
      manager:"组件管理",
      add:"注册组件",
      edit:"编辑组件",
      delete:"删除组件"
    },
    ui:"UI",
    nav:{
      manager:"导航设置",
      add:"添加导航",
      edit:"编辑导航",
      delete:"删除导航"
    },
    page:{
      manager:"页面设置",
      edit:"编辑页面"
    }
  }
};
