import React, { useState } from 'react'

import clsx from 'clsx';
import Box from '@material-ui/core/Box';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import IconArrow from "@material-ui/icons/ArrowRight"

import { TableRow, BodyTableCell, TableRowToolbar } from '../../../components/ui/table'

import Checkbox from '@material-ui/core/Checkbox';

import { useLocale } from '../../../config/Locale'

import { useListPageStyles } from '../../../assets/jss/pages'
import { Component, Datatype } from "../../../types/ui"
import { useDatatypeState } from '../../../provides/ui'

import OptionsTable from "./OptionsTable"

type Props = {
    row: Component,
    index: number,
    isSelected: (id: string) => boolean,
    onClick: (event: any) => void,
    onEdit: () => void,
    onRemove: () => void
}

export default (props: Props) => {
    const { row, index, onEdit, onRemove, onClick, isSelected } = props
    const classes = useListPageStyles()
    const { translate } = useLocale()
    const datatypes = useDatatypeState().list || []

    const [collapse, setCollapse] = useState(index === 0);

    const handleCollapse = () => {
        setCollapse(prev => !prev);
    }
    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    // 数据类型别名
    const getDatatypeLabel = (value: string) => {
        const result = datatypes.filter((dt: Datatype) => dt.value === value)
        return result && result.length > 0 ? result[0].label : value
    }

    return <>
        <TableRow
            hover
            onClick={onClick}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            className={clsx(classes.tableRow, {
                [classes.tableRowActive]: collapse
            })}
        >
            <BodyTableCell padding="checkbox">
                <Box className={classes.inline}>
                    <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="default"
                        className={classes.checked}
                        checkedIcon={<span className={clsx(classes.checkBoxIcon, classes.checkedIcon)} />}
                        icon={<span className={classes.checkBoxIcon} />}
                    />
                    <IconButton
                        component="span"
                        onClick={handleCollapse}>
                        <IconArrow className={clsx(classes.iconArrow, {
                            [classes.iconArrowActive]: collapse
                        })} />
                    </IconButton>
                    {index + 1}.
                </Box>
            </BodyTableCell>
            <BodyTableCell align="left">
                {row.name}
            </BodyTableCell>
            <BodyTableCell align="left">{row.alias}</BodyTableCell>
            <BodyTableCell align="left">{getDatatypeLabel(row.datatype)}</BodyTableCell>
            <BodyTableCell align="left">{row.description}</BodyTableCell>
            <BodyTableCell align="right" className={classes.rowToolbar}>
                <TableRowToolbar
                    {...{
                        editLabel: translate('ui.component.edit'),
                        deleteLabel: translate('ui.component.delete'),
                        onEdit,
                        onDelete: () => {
                            onRemove()
                        },
                    }} />
            </BodyTableCell>
        </TableRow>
        {collapse &&
            <TableRow>
                <BodyTableCell></BodyTableCell>
                <BodyTableCell colSpan={5}>
                    <Collapse in={collapse}>
                        <Box className={classes.extend}>
                            <Box className={classes.extendItem}>
                                <OptionsTable options={row.options || []} />
                            </Box>
                        </Box>
                    </Collapse>
                </BodyTableCell>
            </TableRow>}
    </>
}