import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useCommentWriteStyles = makeStyles((theme: Theme) => createStyles({
  section:{
    padding:theme.spacing(1),
    borderRadius:4
  },
  grow:{
    flexGrow:1
  },
  buttons:{
    display:"flex",
    alignItems:"center",
  },
  ratings:{
    display:"flex",
    padding:theme.spacing(1),
    alignItems:"center",
    '& > * + *': {
    },
  },
  submitButton:{
    margin: theme.spacing(1, 1, 1, 0),
    whiteSpace:"nowrap"
  }
}));
