
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useListPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {

    },
    pageContent: {
    },
    paper: {
      marginBottom: theme.spacing(2),
    },
    table: {
    },
    tableHead:{
      borderTopLeftRadius:5,
      borderTopRightRadius:5,
    },
    tableWrapper: {
      overflowX: 'auto',
      padding:0,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    errorIcon: {
      margin: theme.spacing(1)
    },
    cancelIcon:{
      transform: "rotate(180deg)"
    },
    alert: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alertIcon: {
      margin: theme.spacing(1)
    },
    rowToolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      whiteSpace: 'nowrap'
    },
    rowButton: {
    },
    icon: {
      width: 18,
      height: 18,
      margin: 0,
    },
    pagination: {
      padding: theme.spacing(1),
    },
    preview: {
      padding: 10,
      maxWidth: 750,
      minWidth: 350,
    },
    checkBoxIcon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checked: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checkedIcon: {
      width: 16,
      height: 16,
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    extend:{
      display:"flex",
      flexDirection:"column"
    },
    extendItem:{
      flex:"1",
      margin:theme.spacing(1),
      borderRadius:4,
      border:"1px solid #dedede"
    },
    extendItemHeading:{
      padding:theme.spacing(1),
      borderTopLeftRadius:4,
      borderTopRightRadius:4,
      background:"#f7f7f7",
      borderBottom:"1px solid #dedede"
    },
    extendItemBody:{
      padding:theme.spacing(1),
    },
    inline:{
      display:"inline-flex",
      alignItems:"center",
      justifyContent:"center"
    },
    tableRow:{
    },
    tableRowActive:{
      '& td':{
        fontWeight:600,
        color:"#000"
      }
    },
    iconArrow:{
      transition: "all 150ms ease-in",
    },
    iconArrowActive:{
      transform: "rotate(90deg)"
    },
    previewRow:{
      padding:theme.spacing(1,0),
      color: '#666' 
    },
    subtitle:{
      background:"#f5f5f5",
      borderRadius:4,
      padding:theme.spacing(1),
      color: '#484848' ,
      fontSize:".975em",
    },
    brand:{
      display:"flex",
      alignItems:"center",
    },
    brandLogo:{
      maxWidth:120,
      maxHeight:60,
      border:"none",
      marginRight:theme.spacing(1)
    },
    listTitle:{
      display:"flex",
      alignItems:"center",
      padding:theme.spacing(1),
      color:"#484848",
      fontWeight:600,
      cursor:"pointer"
    },
    rowListItem:{
      fontSize:".9em",
      fontStyle:"italic",
      color:"#777",
      '& > span':{
        fontStyle:"normal",
        margin:"0 5px 0 0",
        color:"#333"
      }
    },
    marginRight:{
        marginRight:theme.spacing(1)
    },
    blockTitle:{
      display:"flex",
      padding:theme.spacing(1)
    },
    blockTitleLabel:{ 
      display:"flex",
      alignItems:"center",
      color:"#484848",
      fontWeight:600,
    },
    blockContent:{
      padding:theme.spacing(1)
    },
    blockRows:{
      display:"flex",
      flexWrap:"wrap",
      padding:theme.spacing(1)
    },
    item:{
        whiteSpace:"nowrap"
    },
    label:{
        color:"#777"
    },
    value:{
        color:"#666",
        fontWeight:600,
        marginRight:theme.spacing(1)
    },
    wordRow:{
      whiteSpace:"nowrap",
      display:"flex",
    },
  }));