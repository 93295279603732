import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="19372" width={width} height={height}>
    <path fill={color} d="M170.666667 448c-35.413333 0-64 28.586667-64 64s28.586667 64 64 64 64-28.586667 64-64-28.586667-64-64-64zM170.666667 192c-35.413333 0-64 28.586667-64 64s28.586667 64 64 64 64-28.586667 64-64-28.586667-64-64-64z m0 512c-35.626667 0-64 28.8-64 64s28.8 64 64 64 64-28.8 64-64-28.373333-64-64-64z m128 106.666667h597.333333v-85.333334H298.666667v85.333334z m0-256h597.333333v-85.333334H298.666667v85.333334z m0-341.333334v85.333334h597.333333v-85.333334H298.666667z" p-id="19373"></path></svg>
    )
