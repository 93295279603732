import { useConfigState } from '../provides/app'

const createTranslate = (localeConfig: any) => (key: string) => {
    let { words } = localeConfig
    key.split('.').forEach((k: string) => {
        words = words[k]
    })
    return words
}
export const useLocale = () => {
    const { localeConfig } = useConfigState(); 
    return {
        translate :createTranslate(localeConfig),
        ...localeConfig
    }
}