import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import MuiTableCell from '@material-ui/core/TableCell';

export const HeadTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#fcfcfc',
      color: '#333',
      padding:'10px 5px ',
      fontWeight:'bold',
      fontSize:14 ,
      whiteSpace:'nowrap'
    }
  }),
)(MuiTableCell);

export const BodyTableCell = withStyles((theme: Theme) =>
  createStyles({
    body: {
      padding:5,
      fontSize:14,
    },
  }),
)(MuiTableCell);
