import React from 'react'
export default ({
    width = 16,
    height = 16,
    color = "#ea9518",
    className = "icon"
})=>(
    <svg className={className} viewBox="0 0 1024 1024" version="1.1" 
    xmlns="http://www.w3.org/2000/svg" p-id="9409" data-spm-anchor-id="a313x.7781069.0.i28"
    width={width} height={height}>
    <path d="M665.327084 420.415119h-308.33546l203.050555 34.802643-218.08804 177.75238h330.893222l-195.533348-42.321898z" p-id="9410" data-spm-anchor-id="a313x.7781069.0.i27"></path>
    <path d="M504.459257 70.862931c-245.211794 0-444.020746 198.808952-444.020746 444.020746s198.808952 443.99721 444.020746 443.99721 443.973674-198.785415 443.973673-443.99721c0-245.211794-198.761879-444.020746-443.973673-444.020746z m288.665468 364.497575l-112.850207 95.644337 37.499056 240.783948c-7.517207 7.519254-7.701402 7.519254-15.244193 7.519254l-195.901739-120.392997-196.3172 120.392997c-15.037485 0-16.581654 0-16.581654-7.519254l27.006074-240.783948-119.006418-105.329931-40.685631-28.876677h-11.508102v-1.244341l11.508102 1.244341h233.543034l30.097482-53.598732 75.187423-158.955269h15.059997l82.70463 158.955269 30.097482 53.598732h203.052602c7.519254 0 7.519254-4.380774 7.519254 3.136433l-45.179992 35.425838z" p-id="9411" data-spm-anchor-id="a313x.7781069.0.i19" fill={color}></path></svg>
)