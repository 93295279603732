import React, { useState, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useLocale } from '../../config/Locale';
import FormField from '../ui/form/FormField'
import CatTreeDialog from './CatTreeDialog'
import { useCat } from '../../provides/shopping/CatProvide'

import Icon from '../Icon'

import { makeStyles, createStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      padding: "0 8px 0 0"
    },
    tip: {
      padding: "5px 3px 0 0"
    }
  }))
export type CatInputProps = {
  editing?: boolean,
  label?: string,
  value?: any,
  labelWidth?: number,
  onChange: (value: any, names: string) => void,
  showLabel?: boolean,
  showName?: boolean,
  children?: any
}
export default function CatInput(props: CatInputProps) {
  const { label, labelWidth, onChange, value, showLabel = true, showName = true, children, editing = true } = props
  const [open, setOpen] = useState(false)
  const { translate } = useLocale()
  const [names, setNames] = useState()

  const { getNames } = useCat()
  // 查询分类名称，含父级分类
  const queryNames = () => {
    if (value && !names) {
      getNames({
        id: value,
        success: (names: string) => {
          setNames(names)
        },
        failure: (message: string) => {
        }
      })
    } else if (!value) {
      setNames('')
    }
  }
  useEffect(queryNames);


  const classes = useStyles();

  if (!editing) {
    return  <FormField
      labelWidth={showLabel ? labelWidth : 0}
      label={showLabel ? `${label}` : ""}>
      {names}
    </FormField>
  }

  return (
    <FormField
      labelWidth={showLabel ? labelWidth : 0}
      label={showLabel ? `${label}` : ""}>
      <Box>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(true)
          }}
        >
          <Icon name="Tree" className={classes.icon} color="#fff" />
          {translate('chooseCat')}
        </Button>
      </Box>
      {showName && <Box className={classes.tip}>
        {names}
      </Box>}
      {children}
      {
        open &&
        <CatTreeDialog
          {...{
            open,
            currentId: value,
            ok: (item: any, names: string) => {
              onChange(item, names)
              setNames(names)
              setOpen(false)
            },
            cancel: () => {
              setOpen(false)
            }
          }} />
      }
    </FormField>);
}

