import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "svg-icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="5754" width={width} height={height}>
    <path fill={color} d="M774.204 2.276c94.568 5.528 179.822 52.21 223.942 156.012 44.824 105.47 24.872 203.57-52.47 287.366-54.756 59.228-113.552 114.772-171.032 171.456-24.962 24.658-64.95 25.446-86.572 2.72-21.884-22.99-19.51-54.578 6.768-80.99 56.424-56.596 113.64-112.49 169.45-169.612 84.11-86.08 46.758-222.962-69.432-253.85-48.338-12.81-94.744-3.948-131.482 30.272-59.15 55.016-114.784 113.63-173.668 168.736-12.478 11.67-33.132 21.76-49.306 20.708-43.33-2.632-65.214-55.106-35.242-86.342 64.422-67.126 130.164-133.2 199.332-195.322 42.1-37.82 96.942-50.804 169.712-51.156zM2.276 759.784c0.44-72.39 25.664-134.952 76.376-186.548 55.984-57.034 112.85-113.192 169.626-169.438 26.982-26.762 60.118-28.78 82.966-5.79 23.73 23.954 21.446 54.14-6.678 82.392-54.052 54.226-108.982 107.752-162.508 162.592-66.18 67.652-68.994 158.994-7.998 220.33 58.36 58.702 154.862 58.964 215.856-0.438 55.458-54.052 109.42-109.682 164.706-163.822 33.222-32.466 83.67-20.884 92.458 22.816 3.164 15.706-3.164 39.398-14.062 50.894-60.114 63.35-120.144 127.23-185.62 184.702-80.154 70.372-174.108 82.044-272.016 39.398-94.656-41.24-152.838-134.162-153.104-237.086zM735.62 346.94c-3.516 6.58-7.558 20.796-16.698 30.094a51963.094 51963.094 0 0 1-332.308 334.312c-23.644 23.604-57.304 22.024-79.54-0.438-22.412-22.638-21.796-53.702 3.164-78.796 109.248-109.506 218.844-218.75 328.356-327.904 17.754-17.726 38.582-25.184 62.838-14.654 21.62 9.39 32.608 26.588 34.19 57.386z" p-id="5755"></path></svg>)
