import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="19512"
    width={width} height={height}><path fill={color} d="M228.693333 634.88c-10.24 13.653333-17.066667 27.306667-17.066666 47.786667 0 17.066667 6.826667 30.72 17.066666 40.96 10.24 10.24 27.306667 17.066667 47.786667 17.066666 27.306667 0 51.2-10.24 68.266667-27.306666 17.066667-20.48 27.306667-44.373333 27.306666-71.68v-34.133334l-81.92 6.826667c-30.72 3.413333-51.2 10.24-61.44 20.48zM873.813333 40.96H150.186667C68.266667 40.96 3.413333 105.813333 3.413333 184.32v655.36c0 81.92 64.853333 146.773333 146.773334 146.773333h727.04c81.92 0 146.773333-64.853333 146.773333-146.773333V184.32c-3.413333-78.506667-68.266667-143.36-150.186667-143.36zM433.493333 781.653333h-61.44v-54.613333c-23.893333 40.96-61.44 61.44-109.226666 61.44-34.133333 0-61.44-10.24-81.92-27.306667-20.48-17.066667-30.72-44.373333-30.72-75.093333 0-64.853333 37.546667-105.813333 116.053333-116.053333l105.813333-13.653334c0-58.026667-23.893333-85.333333-71.68-85.333333-40.96 0-81.92 13.653333-116.053333 44.373333v-61.44c34.133333-20.48 75.093333-34.133333 122.88-34.133333 85.333333 0 126.293333 44.373333 126.293333 133.12v228.693333z m392.533334-44.373333c-30.72 34.133333-68.266667 51.2-116.053334 51.2-44.373333 0-81.92-20.48-105.813333-58.026667v51.2h-61.44V259.413333h61.44v232.106667c27.306667-47.786667 68.266667-68.266667 122.88-68.266667 44.373333 0 78.506667 17.066667 105.813333 47.786667 27.306667 30.72 37.546667 75.093333 37.546667 126.293333-3.413333 54.613333-17.066667 102.4-44.373333 139.946667z m-119.466667-266.24c-30.72 0-54.613333 10.24-75.093333 34.133333-20.48 20.48-30.72 51.2-30.72 81.92v47.786667c0 27.306667 10.24 51.2 27.306666 71.68 17.066667 20.48 40.96 30.72 68.266667 30.72 34.133333 0 58.026667-13.653333 78.506667-37.546667 17.066667-23.893333 27.306667-61.44 27.306666-105.813333 0-37.546667-10.24-68.266667-27.306666-88.746667-13.653333-23.893333-37.546667-34.133333-68.266667-34.133333z" p-id="19513"></path></svg>
    )
