import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import * as Config  from '../../config';

import { useChat } from '../../provides/chat'
import { useSysState } from '../../provides/sys'

import { useChatStyles } from '../../assets/jss/components'
  
import LoadingBox from "../../components/ui/LoadingBox"

type FileInfo = {
  filename:string,
  id:string
}

type Props = {
  id:string
}

export default (props:Props) => {
    const { id } = props
    const classes = useChatStyles() 
    const { account } = useSysState()

    const {
      getFiles,
      download
    } = useChat()

    const [message,setMessage] = React.useState<any>({})
    const [files,setFiless] = React.useState<FileInfo[]>([])
    // const [downloadPrecent,setDownloadPrecent] = React.useState<number|null>(null)
    
    React.useEffect(()=>{
      setMessage({
        type:"loading",
        text:"加载文件列表..."
      });
      getFiles({
        data:{
          id
        },
        success:(result:any)=>{
          setFiless(result.files)
          setMessage({
            type:"",
            text:""
          });
        },
        failure:(message:string)=>{
          setMessage({
            type:"error",
            text:message || "图片加载失败！"
          });
        }
      });
    },[])

    const handleDownload = (id:string)=>{
      if(!account){
        setMessage({
          type:"error",
          text:"用户未登录!"
        });
        return 
      }
      window.open(Config.fullApi + '/chat/message/download?'
      +'appId='+Config.appId
      +'&userId='+account.user.id
      +'&token='+account.token
      +'&id='+id)
      // download({
      //   data:{
      //     id
      //   },
      //   success:()=>{
      //     setMessage({
      //       type:"",
      //       text:""
      //     });
      //   },
      //   failure:(message:string)=>{
      //     setMessage({
      //       type:"error",
      //       text:message || "下载失败！"
      //     });
      //   },
      //   onProgress:(event:any)=>{
      //     if (event.lengthComputable) {
      //       //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
      //       //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
      //       // callback(progressEvent);
      //       //{{Math.ceil(precent)}}%
      //       // precent.toFixed(2)
      //       const loaded = event.loaded;
      //       const total = event.total;
      //       const precent = (loaded / total) * 100;
      //       setDownloadPrecent(precent)
      //     }
      //   }
      // })
    }


    return (
      <Box className={classes.files}>
          {files.map((file:FileInfo,index:number)=><Box 
          key={index} 
          className={classes.file}>
            <Box>{file.filename}</Box>
          <Button onClick={()=>handleDownload(file.id)}>下载{/*downloadPrecent && <>{downloadPrecent}%</>*/}</Button>
          </Box>
          )}
         {message.type === 'loading' && <LoadingBox open={true} text={message.text  || ''}/>}
         {message.type === 'success' && <Box className={classes.success}>{message.text}</Box>}
         {message.type === 'error' && <Box className={classes.error}>{message.text}</Box>}
         {message.type === 'info' && <Box className={classes.info}>{message.text}</Box>}
      
       </Box>
    )
}