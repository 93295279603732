import React from "react";

import classNames from "classnames";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import IconDelete from '@material-ui/icons/DeleteForever';
import Icon from '../../Icon'
import Icons from '../../Icons'
import Search from '../Search';
import { IconInputProps } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "nowrap"
    },
    label: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      textAlign: "right",
      flexWrap: "nowrap",
      paddingTop: theme.spacing(2)
    },
    value: {
      margin: theme.spacing(1),
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#666",
      '& svg': {
        '& path': {
          fill: "#ff6600"
        }
      }
    },
    input: {
      flex: 1,
      margin: theme.spacing(1)
    },
    icons: {
      display: "flex",
      flexWrap: "wrap"
    },
    iconItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      border: "1px solid #ddd",
      cursor: "pointer",
      borderRadius: 4,
      minWidth: 120,
      '&:hover': {
        backgroundColor: "#f0f0f0",
      },
      '& svg': {
        '& path': {
          fill: "#666"
        }
      }
    },
    selected: {
      border: "1px solid #dedede",
      backgroundColor: "#f3f3f3",
      color: "#ff6600",
      '& svg': {
        '& path': {
          fill: "#ff6600"
        }
      }
    },
    tips: {
      padding: theme.spacing(1, 0),
      fontSize: 12,
      color: "#999"
    },
    delButton: {
      marginLeft: theme.spacing(1),
      color:"#666",
      '& svg': {
        '& path': {
          fill: "#666"
        }
      },
      "&:hover":{
        '& svg': {
          '& path': {
            fill: "#ff6600"
          }
        }
      }
    },
  })
);


export default (props: IconInputProps) => {
  const classes = useStyles();
  const {
    label,
    labelWidth,
    tips,
    value,
    onChange
  } = props;
  const labelStyle: React.CSSProperties = labelWidth
    ? {
      width: labelWidth,
    }
    : {
    };

  const handleChange = (key: string) => {
    onChange(key);
  };

  const originList = Object.keys(Icons)
  // 关键字
  const [keyword, setKeyword] = React.useState<string>(value || "")
  const [list, setList] = React.useState<string[]>(originList)
  // 过滤
  const search = (params?: any) => {
    let k = params && params.keyword
    k = k ? k.trim().toLowerCase() : ""
    if (k) {
      setList(originList.filter((item: any) => item.toLowerCase().indexOf(k) !== -1))
    } else {
      setList(originList)
    }
  }

  const icons = <Box className={classes.icons}>
    {list.map((key: string) => (
      <span
        className={classNames(classes.iconItem, {
          [classes.selected]: value === key
        })}
        onClick={() => handleChange(key)}
        key={key}>
        <span>
          {Icons[key]({
            width: 26,
            height: 26
          })}
        </span>
        <span className="label">{key}</span>
      </span>))}
  </Box>

  return (
    <Box className={classes.root}>
      <Box style={labelStyle} className={classes.label}>
        {label}
      </Box>
      <Box className={classes.input}>
        <Box className={classes.value}>
          <Icon name={value} width={32} height={32} />
          {value} 
          {value && 
          <IconButton className={classes.delButton} onClick={() =>handleChange('')}>
            <IconDelete />
          </IconButton>}
        </Box>
        <Search
          value={keyword || ""}
          onChange={(keyword: string) => {
            setKeyword(keyword)
            search({ keyword })
          }}
        />
        {icons}
        {tips && <Box className={classes.tips}>{tips}</Box>}
      </Box>
    </Box>
  );
};
