import React from "react"

import classNames from "classnames"
import { useUIInputsStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';

import { SectionButton } from "./types"

import InputListItem from "./InputListItem"

import ItemTools from "./components/ItemTools"

type Props = {
    button?: SectionButton,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave?: (item: any) => void,
    onRemove?: (item: any) => void,
}
/** 标签页输入 */
export default (props: Props) => {
    const { button = { id: "0", text: "" }, showUp, showDown, sortUp, sortDown, onSave, onRemove } = props
    const classes = useUIInputsStyles()

    const [form, setForm] = React.useState<any>(button)
    const setFormItem = (key: "id" | "editing" | "icon" | "text" | "link" | "color", value: any) => {
        form[key] = value;
        setForm(Object.assign({}, form))
    }

    return <Box className={classNames(classes.listItem, {
        [classes.editing]: form.editing
    })}>
        <Box className={classes.itemFields}>
            <InputListItem
                key={0}
                editing={form.editing}
                option={{
                    id: "0",
                    name: 'text',// 类型名称
                    alias: '按钮文本',// 别名
                    variableType: 'string',// 变量类型
                    notNull: false,// 非空
                }}
                value={form.text}
                onChange={(name: string, value: any) => {
                    setFormItem('text', value)
                }} />

            <InputListItem
                key={1}
                editing={form.editing}
                option={{
                    id: "1",
                    name: 'link',// 类型名称
                    alias: '按钮链接',// 别名
                    variableType: 'link',// 变量类型
                    notNull: true,// 非空
                }}
                value={form.link}
                onChange={(name: string, value: any) => {
                    setFormItem('link', value)
                }} />

            <InputListItem
                key={2}
                editing={form.editing}
                option={{
                    id: "1",
                    name: 'icon',// 类型名称
                    alias: '按钮图标',// 别名
                    variableType: 'icon',// 变量类型
                    notNull: true,// 非空
                }}
                value={form.icon}
                onChange={(name: string, value: any) => {
                    setFormItem('icon', value)
                }} />
            <InputListItem
                key={2}
                editing={form.editing}
                option={{
                    id: "1",
                    name: 'color',// 类型名称
                    alias: '按钮颜色',// 别名
                    variableType: 'enum',// 变量类型
                    variableValues: "primary,info,success,warning,danger,rose,white,facebook,twitter,google,github,transparent",
                    notNull: true,// 非空
                }}
                value={form.color}
                onChange={(name: string, value: any) => {
                    setFormItem('color', value)
                }} />
        </Box>
        <ItemTools
            editing={form.editing}
            onSave={() => {
                setFormItem("editing", false);
                if (onSave) onSave(form);
            }}
            onCancel={() => setFormItem("editing", false)}
            onEditing={() => setFormItem("editing", true)}
            onRemove={() => {
                if (onRemove) onRemove(form);
            }}
            showUp={showUp}
            showDown={showDown}
            sortUp={sortUp}
            sortDown={sortDown} />
    </Box>
}