
import { createStyles,makeStyles, withStyles,Theme } from "@material-ui/core/styles";
import {grow,toolbar} from "../../baseStyles"

export const withDrawerStyles = withStyles(() =>
  createStyles({
    root: {
       background:"transparent",
    },
    paper: {
      position:"relative",
      top:0,
      margin:0,
      padding:0,
       background:"transparent",
      // backgroundColor: "rgba(250,250,250,.98)",
      overflow:"auto",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
    }
  })
);

export const useDrawerStyles = makeStyles((theme:Theme) =>
  createStyles({
    grow,
    toolbar,
    paddingTop:{
      paddingTop:50
    },
    paddingBottom:{
      paddingBottom:50
    },
    closeBar: {
      zIndex:999,
      position:"absolute",
      top:0,
      right:0,
      display:"flex",
    },
    close:{
      color:"#ff0000",
      '&:hover':{
        color:"#fff"
      }
    },
    page:{
      display:"flex",
      flexDirection:"column"
    },
    header:{
      display:"flex",
      alignItems:"center",
      border:"1px solid #dedede",
      borderTopLeftRadius:8,
      borderTopRightRadius:8,
      boxShadow:
        "0 5px 20px -6px rgba(225, 225, 225, 0.42), 0 2px 15px 0px rgba(225, 225, 225, 0.12), 0 4px 5px -2px rgba(225, 225, 225, 0.2)",
    },
    title:{
      display:"flex",
      alignItems:"center",
      padding:theme.spacing(0,1),
    },
    content:{
      flexGrow:1,
      boxShadow:
        "0 5px 20px -6px rgba(225, 225, 225, 0.42), 0 2px 15px 0px rgba(225, 225, 225, 0.12), 0 4px 5px -2px rgba(225, 225, 225, 0.2)",
    },
    footer:{
      display:"flex",
      alignItems:"center",
      padding:theme.spacing(1)
    }
  })
);