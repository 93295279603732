import React from "react";

import {
  withStyles,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";

import CatMenusDataInput from "./CatMenusDataInput";
import CarouselDataInput from "./CarouselDataInput";
import SpecialTabListInput from "./SpecialTabListInput";

import { DataSource } from "../../../../types/ui";
import { ShoppingSplash, CarouselItem, SpecialTabItem, Menu } from "./types";

export const Tabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 6,
      backgroundColor: "#f9f9f9",
      border: `1px solid #f0f0f0`,
      minWidth: 160
    },
    indicator: {
      display: "none",
      "& > div": {
        maxWidth: "60%",
        minHeight: "100%",
        maxHeight: "100%"
      }
    }
  })
)(MuiTabs);

const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      opacity: 0.9,
      "&:hover": {
        backgroundColor: "#efefef",
        transition: "all 300ms linear",
        opacity: 1,
        color: "#333",
        "& a": {
          transition: "all 300ms linear",
          color: "#333"
        }
      },
      "& a": {
        padding: 0,
        margin: 0,
        fontSize: "1em",
        color: "#484848",
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none"
        }
      }
    },
    selected: {
      backgroundColor: `#f0f0f0`,
      transition: "all 300ms linear",
      color: `${theme.palette.text.secondary}`,
      fontWeight: 600,
      "& a": {
        color: `${theme.palette.text.secondary}`,
        "&:hover": {
          color: `${theme.palette.text.secondary}`
        }
      }
    }
  })
)(MuiTab);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      // backgroundColor: theme.palette.background.paper,
      display: "flex",
      minHeight: 280
    },
    panels: {
      flexGrow: 1,
      paddingLeft: 8
    }
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}
function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}
type Props = {
  data?: ShoppingSplash;
  onChange: (dataSource: DataSource, data: ShoppingSplash) => void;
};
type DataChangeProps = {
  type: "Menu[]" | "CarouselItem[]" | "SpecialTabItem[]";
  dataSource?: DataSource;
  value?: any;
};

// 显示组数据输入
export default (props: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const { data = {}, onChange } = props;

  const handleDataChange = (props: DataChangeProps) => {
    const { type, dataSource, value } = props;
    switch (type) {
      case "Menu[]":
        data.menus = value;
        data.menusDataSource = dataSource;
        break;
      case "CarouselItem[]":
        data.carousels = value;
        data.carouselsDataSource = dataSource;
        break;
      case "SpecialTabItem[]":
        data.tabs = value;
        data.tabsDataSource = dataSource;
        break;
    }
    onChange({ type: "input" }, Object.assign({}, data));
  };

  return (
    <Box className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        <Tab label="设置分类菜单" {...a11yProps(0)} />
        <Tab label="设置轮播数据" {...a11yProps(1)} />
        <Tab label="设置标签页" {...a11yProps(2)} />
      </Tabs>
      <Box className={classes.panels}>
        <TabPanel value={value} index={0}>
          <CatMenusDataInput
            data={(data && data.menus) || []}
            onChange={(dataSource: DataSource, value?: Menu[]) =>
              handleDataChange({ type: "Menu[]", dataSource, value })
            }
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CarouselDataInput
            dataSource={data && data.carouselsDataSource}
            data={data && data.carousels}
            onChange={(dataSource: DataSource, value?: CarouselItem[]) =>
              handleDataChange({ type: "CarouselItem[]", dataSource, value })
            }
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SpecialTabListInput
            data={data && data.tabs}
            dataSource={data && data.tabsDataSource}
            onChange={(dataSource: DataSource, value: SpecialTabItem[]) =>
              handleDataChange({ type: "SpecialTabItem[]", dataSource, value })
            }
          /> 
        </TabPanel>
      </Box>
    </Box>
  );
};
