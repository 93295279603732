
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useCommentListStyles = makeStyles((theme: Theme) => createStyles({
  grow:{
    flexGrow:1
  },
  title:{
    display: "flex",
    alignItems: "center",
    padding:theme.spacing(1),
    borderBottom:"1px solid #dedede",
    color:"#666"
  },
  section:{
    '& button':{
      minWidth:32,
      cursor:'pointer',
    }
  },
  originText:{
    padding:theme.spacing(1),
  },
  avatar: {
    minWidth: 32,
    minHeight: 32,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff"
  },
  list:{
    borderRadius:3,
    padding:theme.spacing(1,0)
  },
  border:{
    border:"1px solid #dedede",
  },
  borderBottom:{
    borderBottom:"1px solid #dedede",
  },
  listItem:{
    display: "flex",
    borderBottom:"1px solid #dedede",
    padding:theme.spacing(1),
    '&:last-child':{
      borderBottom:0
    }
  },
  vertical:{
    flexDirection:"column",
    padding:0,
    borderBottom:"none",
  },
  alignStart:{
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection:"row",
    padding:theme.spacing(1,0),
    '&:first-child':{
      borderTop:"1px solid #dedede",
    },
  },
  fullWidth:{
    width:"100%",
    // padding:theme.spacing(0)
  },
  moreButton:{
    padding:theme.spacing(1),
    marginBottom:theme.spacing(1),
    cursor:"pointer",
    color:"#666",
    '&:hover':{
      color:"#333"
    }
  },
  userInfo: {
    display: "flex",
    flexDirection:"column",
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding:theme.spacing(1,2)
  },
  itemBody:{
    flexGrow:1,
    display: "flex",
    flexDirection:"column"
  },
  uname: {
    cursor: "pointer",
    color: "#0099cc",
    fontSize: "14px",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"微软雅黑","Tahoma","Arail"',
    padding: theme.spacing(1),
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  etc:{
    display: "flex",
    alignItems: 'center',
  },
  total:{

  },
  content:{
    padding:theme.spacing(1)
  },
  toolbar:{
    display: "flex",
    alignItems: 'center',
    padding:theme.spacing(1),
    '& button':{
      cursor:'pointer',
      color:"#666",
      '&:hover':{
        color:"#ff6600"
      }
    }
  },
  alert: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  alertIcon: {
    margin: theme.spacing(1)
  },
  iconArrow:{
    cursor:'pointer',
    maxWidth:"16px",
    transform: "rotate(90deg)",
    transition: "all 150ms ease-in",
  },
  iconArrowActive:{
    transition: "all 150ms ease-in",
    transform: "rotate(-90deg)"
  },
}));
