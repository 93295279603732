import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import classnames from 'classnames'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Icon from './Icon'
import { useLocale } from '../../../../config/Locale'
import TextInput from '../../form/TextInput'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      width: '100%',
      height: '100%',
      background: 'rgba(225, 225, 225,0.2)',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
      top: 0,
      left: 0
    },
    box: {
      background: '#fafafa',
      borderRadius: '5px',
      border: '1px solid #dedede',
      // boxShadow: '0px 0 2vh 2vh #f3f3f3',
      margin: '0 auto',
      padding: '5px 10px',
    },
    toolbar: {
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: 5,
      background: '#f7f7f7',
      border: '1px solid #f0f0f0',
      padding: '5px 10px'
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 32,
      minHeight: 32,
      padding: '3px 5px',
      borderRadius: 3,
      background: 'transparent',
      border: '1px solid transparent',
      marginRight: 5,
      fontSize: '12px',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #dedede',
        background: '#f0f0f0',
      }
    },
    active: {
      backgroundColor: '#eee',
      border: '1px inset #dedede'
    },
    title: {
      padding: theme.spacing(1),
      fontSize: '1em'
    },
    content: {
      padding: 0
    },
    actions: {
      padding: '10px 20px 10px 10px',
      textAlign: 'right'
    },
    actionButton: {
      marginRight: 10
    },
    iconButton: {
      margin: '10px 5px 5px 0'
    },
    icon: {
      marginRight: 5
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
    }
  }),
);
export default (props: any) => {
  const {
    open,
    close,
    onRemove,
    onReplace,
    changeAttributes,
    attributes
  } = props
  const { translate } = useLocale()

  const classes = useStyles()
  const [form,setForm] = useState(attributes || {} as any)

  useEffect(() => {
    if (attributes) {
      setForm(attributes)
    }
  })
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    changeAttributes(form)
    close()
  }
  const renderToolbar = () => {
    return <div className={classes.root}>
      <div className={classes.box}>
        <div className={classes.title}>
          {/* 标题 */}
          设置属性
    </div>
        <div className={classes.content}>
          <div className={classes.toolbar}>
            <span
              title="更换"
              className={classes.button}
              onMouseDown={onReplace}>
              <Icon name="Change"  className={classes.icon} />
              <span>更换</span>
            </span>
            <span
              title="删除"
              className={classes.button}
              onMouseDown={()=>{
                close()
                onRemove()
              }}>
              <Icon name="Remove"  className={classes.icon} />
              <span>删除</span>
            </span>

            <span onMouseDown={() => {
              if (form.float === 'left') {
                setFormItem('float', '')
              } else {
                setFormItem('clear', '')
                setFormItem('textAlign', '')
                setFormItem('float', 'left')
              }
            }}
              className={clsx(classes.button, {
                [classes.active]: form.float && form.float === 'left'
              })}
              title="靠左浮动">
              <Icon name="TextAlignLeft"
                color={form.float === 'left' ? '#0288d1' : '#484848'}
                width={18} height={18} />
            </span>



            <span onMouseDown={() => {
              if (form.textAlign === 'center') {
                setFormItem('textAlign', '')
              } else {
                setFormItem('float', '')
                setFormItem('clear', '')
                setFormItem('textAlign', 'center')
              }
            }}
              className={clsx(classes.button, {
                [classes.active]: form.textAlign && form.textAlign === 'center'
              })}
              title="居中">
              <Icon name="TextAlignCenter"
                color={form.textAlign === 'center' ? '#0288d1' : '#484848'}
                width={18} height={18} />
            </span>


            <span onMouseDown={() => {
              if (form.float === 'right') {
                setFormItem('float', '')
              } else {
                setFormItem('clear', '')
                setFormItem('textAlign', '')
                setFormItem('float', 'right')
              }
            }}
              className={clsx(classes.button, {
                [classes.active]: form.float === 'right'
              })}
              title="靠右浮动">
              <Icon name="TextAlignRight"
                color={form.float && form.float === 'right' ? '#0288d1' : '#484848'}
                width={18} height={18} />
            </span>

            <span onMouseDown={() => {
              if (form.clear === 'both') {
                setFormItem('clear', '')
              } else {
                setFormItem('float', '')
                setFormItem('textAlign', '')
                setFormItem('clear', 'both')
              }
            }}
              className={clsx(classes.button, {
                [classes.active]: form.clear && form.clear === 'both'
              })}
              title="清除浮动">
              <Icon name="Clearfix"
                color={form.clear === 'both' ? '#0288d1' : '#484848'}
                width={18} height={18} />
            </span>

          </div>

          {/* Alt */}
          <TextInput
            labelWidth={80}
            defaultValue={form.alt}
            fullWidth
            label="ALT："
            // onKeyDown={(event:any)=>event.preventDefault()}
            onChange={(event: any) => {
              event.preventDefault()
              setFormItem('alt', event.target.value)
            }} />
          <div className={classes.row}>
            <TextInput
              labelWidth={80}
              defaultValue={form.width}
              inputWidth={100}
              label="宽："
              // onKeyDown={(event:any)=>event.preventDefault()}
              onChange={(event: any) => {
                event.preventDefault()
                setFormItem('width', event.target.value)
              }} />

            <span title="Auto"
              className={classnames(classes.button, classes.iconButton)}
              onMouseDown={(event: any) => {
                event.preventDefault()
                setFormItem('width', '')
                setFormItem('height', '')
              }}>
              <Icon name="ChangeAuto" width={18} height={18} />
            </span>
            <TextInput
              labelWidth={32}
              defaultValue={form.height}
              inputWidth={100}
              label="高："
              // onKeyDown={(event:any)=>event.preventDefault()}
              onChange={(event: any) => {
                event.preventDefault()
                setFormItem('height', event.target.value)
              }} />
          </div>
        </div>
        <div className={classes.actions}>
          <Button variant="contained" onClick={onSubmit} color="primary" className={classes.actionButton} autoFocus>
            {/* 确定 */}
            {translate('alert.sure')}
          </Button>
          <Button onClick={(event: any) => {
            event.preventDefault()
            close()
          }} color="primary" className={classes.actionButton}>
            {/* 取消 */}
            {translate('alert.cancel')}
          </Button>
        </div>
      </div>
    </div>
  }

  return (<>
    {open && renderToolbar()}
  </>)
}