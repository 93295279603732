import React from "react"

import Box from '@material-ui/core/Box';
import ColorInput from '../../../components/ui/form/ColorInput'
import TextInput from '../../../components/ui/form/TextInput'
import NumberInput from '../../../components/ui/form/NumberInput'
import RadioInput from '../../../components/ui/form/RadioInput'

import { Side, PageSetting } from "../../../types/ui"
import { useUIPageStyles } from "../../../assets/jss/pages"

import ComponentsInput from "./inputs/ComponentsInput"


type Props = {
    side?: Side,
    onChange: (side: Side) => void
}

export default (props: Props) => {
    const classes = useUIPageStyles()
    const { side = {}, onChange } = props

    const [form, setForm] = React.useState<Side>(side || {})
    const setFormItem = (key: "status" | "children" | "options", value: any) => {
        form[key] = value;
        const newForm = Object.assign({}, form)
        setForm(newForm)
        onChange(newForm)
    }

    const [options, setOptions] = React.useState<any>(form.options || {})
    const setOption = (key: string, value: any) => {
        options[key] = value;
        const newOptions = Object.assign({}, options)
        setOptions(newOptions)
        setFormItem("options", newOptions)
    } 
    const labelWidth = 120

    return <Box className={classes.dialog}>
        <RadioInput
            labelWidth={labelWidth}
            label="状态："
            value={`${form.status || '0'}`}
            options={[
                { label: "开启侧边栏", value: "1" },
                { label: "关闭侧边栏", value: "0" }
            ]}
            onChange={(e: any, value: any) => {
                setFormItem('status', value)
            }} />
        <NumberInput
            labelWidth={labelWidth}
            label="侧栏宽度："
            value={options.width || 280}
            onChange={(value: any) => {
                setOption('width', value)
            }} />
        <ColorInput
            labelWidth={labelWidth}
            value={`${options.backgroundColor || ''}`}
            inputWidth={labelWidth}
            label="背景颜色："
            onChange={(color: string) => {
                setOption('backgroundColor', color)
            }} />

        <TextInput
            labelWidth={labelWidth}
            value={`${options.padding || ''}`}
            fullWidth
            label="内边距："
            placeholder="内边距为数值或像素字符串，如8,8px,8px 16px"
            onChange={(event: any) => {
                setOption('padding', event.target.value)
            }} />

        <TextInput
            labelWidth={labelWidth}
            value={`${options.style || ''}`}
            fullWidth
            label="自定义样式："
            placeholder="格式如fontColor:'#333',borderRadius:5"
            onChange={(event: any) => {
                setOption('style', event.target.value)
            }} />

        <ComponentsInput
            data={form.children || []}
            onChange={(data: PageSetting[]) => setFormItem("children", data)} />

    </Box>
}