import React, { useEffect, useCallback } from 'react';

import { EditorState, RichUtils, Modifier } from 'draft-js';
import { getSelectionInlineStyle } from '../utils';

import Icon from './Icon'
import Button from './Button'

const styeMap: any = {
    'textDecoration_underline': 'UNDERLINE',
    'textDecoration_line-through': 'STRIKETHROUGH',
    'fontStyle_italic': 'ITALIC',
    'fontWeight_bold': 'BOLD'
}

export default (props: any) => {
    const {
        onChange,
        editorState,
        title,
        icon,
        type,
        value
    } = props

    /**
     * 获取样式
     * 键：[type]_[value]
     */
    const style = styeMap[`${type}_${value}`]
    // 获取当前样式，改变工具条样式
    const [current, setCurrent] = React.useState(false)
    const query = () => {
        const currentStyles:any = getSelectionInlineStyle(editorState)
        const current = currentStyles[style]
        setCurrent(current)
    }
    useEffect(query)

    const toggleInlineStyle = (): void => {
        let newState = RichUtils.toggleInlineStyle(
            editorState,
            style,
        );
        if (current) {
            const contentState = Modifier.removeInlineStyle(
                newState.getCurrentContent(),
                newState.getSelection(),
                style,
            ); 
            newState = EditorState.push(newState, contentState, 'change-inline-style');
        } 
        if (newState) {
            onChange(newState);
        }
    };

    return (<>
        <Button
            onMouseDown={toggleInlineStyle}
            active={current}
            title={title[0]} >
            <Icon name={icon}
                color={current ? '#0288d1' : '#484848'}
                width={18} height={18} />
        </Button>
    </>
    );
}