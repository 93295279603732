import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'


// Action Types
export type Action = {
    // 处理订单
    type: 'TRADE_ORDER_DEAL',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 订单列表
    type: 'TRADE_ORDER_GET_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 获取订单
    type: 'TRADE_ORDER_GET',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 订单报表
    type: 'TRADE_ORDER_REPORT',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 查询微信订单
    type: 'TRADE_ORDER_WX_QUERY',
    payload: { client: 'default', request: AxiosRequestConfig }
}| {
    // 查询支付宝订单
    type: 'TRADE_ORDER_ALIPAY_QUERY',
    payload: { client: 'default', request: AxiosRequestConfig }
};


// 处理订单
export const ORDER_DEAL = 'TRADE_ORDER_DEAL'
export const deal = (data:any): Action => ({
    type: ORDER_DEAL,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/order/deal`,
            method: 'POST',
            data
        }
    }
})


// 订单列表
export const GET_LIST = 'TRADE_ORDER_GET_LIST'
export const fetchList = (params:any): Action => ({
    type: ORDER_DEAL,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/order/getAllList`,
            method: 'GET',
            params
        }
    }
})


// 订单列表
export const GET = 'TRADE_ORDER_GET'
export const fetch = (params:any): Action => ({
    type: ORDER_DEAL,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/order/get`,
            method: 'GET',
            params
        }
    }
})


// 订单报表
export const REPORT = 'TRADE_ORDER_REPORT'
export const report = (params:any): Action => ({
    type: REPORT,
    payload: {
        client: 'default',
        request: {
            url: `${API}trade/order/report`,
            method: 'GET',
            params
        }
    }
})

// 订单查询
export const WX_QUERY = 'TRADE_ORDER_WX_QUERY'
export const wxQuery = (data:any): Action => ({
    type: WX_QUERY,
    payload: {
        client: 'default',
        request: {
            url: `${API}wx/orderquery`,
            method: 'POST',
            data
        }
    }
})

// 支付宝查询
export const ALIPAY_QUERY = 'TRADE_ORDER_ALIPAY_QUERY'
export const alipayQuery = (data:any): Action => ({
    type: ALIPAY_QUERY,
    payload: {
        client: 'default',
        request: {
            url: `${API}alipay/query`,
            method: 'POST',
            data
        }
    }
})