import { AxiosRequestConfig } from "axios";
import { API } from "../../../config";
import * as types from "../../../types";
import * as shoppingTypes from "../../../types/shopping";

// Action Types
export type Action =
  | {
      // 属性列表
      type: "SHOPPING_ATTR_FETCH_LIST";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | { type: "SHOPPING_ATTR_FETCH_LIST_SUCCESS"; payload: any }
  | {
      type: "SHOPPING_ATTR_FETCH_LIST_FAILURE";
      payload: { error: types.Error };
    }
  | {
      // 按分类获取属性列表
      type: "SHOPPING_ATTR_FETCH_LIST_BY_CAT";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | { type: "SHOPPING_ATTR_FETCH_LIST_BY_CAT_SUCCESS"; payload: any }
  | {
      type: "SHOPPING_ATTR_FETCH_LIST_BY_CAT_FAILURE";
      payload: { error: types.Error };
    }
  | {
      // 属性
      type: "SHOPPING_ATTR_FETCH";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | { type: "SHOPPING_ATTR_FETCH_SUCCESS"; payload: any }
  | { type: "SHOPPING_ATTR_FETCH_FAILURE"; payload: { error: types.Error } }
  | {
      // 保存单个
      type: "SHOPPING_ATTR_SAVE";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 保存列表
      type: "SHOPPING_ATTR_SAVE_LIST";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 删除
      type: "SHOPPING_ATTR_REMOVE";
      payload: { client: "default"; request: AxiosRequestConfig };
    }
  | {
      // 设置状态
      type: "SHOPPING_ATTR_SET_STATUS";
      payload: { client: "default"; request: AxiosRequestConfig };
    };

// 列表
export const FETCH_LIST = "SHOPPING_ATTR_FETCH_LIST";
export const FETCH_LIST_SUCCESS = "SHOPPING_ATTR_FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "SHOPPING_ATTR_FETCH_LIST_FAILURE";
export const fetchList = (params: any): Action => ({
  type: FETCH_LIST,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/getList`,
      method: "GET",
      params
    }
  }
});
export const fetchListSucess = (
  list: shoppingTypes.Attr[],
  pagination: types.Pagination
): Action => ({
  type: FETCH_LIST_SUCCESS,
  payload: {
    list,
    pagination
  }
});
export const fetchListFailure = (error: types.Error): Action => ({
  type: FETCH_LIST_FAILURE,
  payload: {
    error
  }
});

// 列表
export const FETCH_LIST_BY_CAT = "SHOPPING_ATTR_FETCH_LIST_BY_CAT";
export const FETCH_LIST_BY_CAT_SUCCESS =
  "SHOPPING_ATTR_FETCH_LIST_BY_CAT_SUCCESS";
export const FETCH_LIST_BY_CAT_FAILURE =
  "SHOPPING_ATTR_FETCH_LIST_BY_CAT_FAILURE";
export const fetchListByCat = (catId: string): Action => ({
  type: FETCH_LIST_BY_CAT,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/getListByCat`,
      method: "GET",
      params:{
        catId
      }
    }
  }
});
export const fetchListByCatSucess = ( list: shoppingTypes.Attr[]): Action => ({
  type: FETCH_LIST_BY_CAT_SUCCESS,
  payload: {
    list
  }
});
export const fetchListByCatFailure = (error: types.Error): Action => ({
  type: FETCH_LIST_BY_CAT_FAILURE,
  payload: {
    error
  }
});

// 属性
export const FETCH = "SHOPPING_ATTR_FETCH";
export const FETCH_SUCCESS = "SHOPPING_ATTR_FETCH_SUCCESS";
export const FETCH_FAILURE = "SHOPPING_ATTR_FETCH_FAILURE";
export const fetch = (params: any): Action => ({
  type: FETCH,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/get`,
      method: "GET",
      params
    }
  }
});
export const fetchSucess = (attr: shoppingTypes.Attr): Action => ({
  type: FETCH_SUCCESS,
  payload: {
    attr
  }
});
export const fetchFailure = (error: types.Error): Action => ({
  type: FETCH_FAILURE,
  payload: {
    error
  }
});

// 保存
export const SAVE_LIST = "SHOPPING_ATTR_SAVE_LIST";
export const saveList = (
  attrs: shoppingTypes.Attr[],
  catId: string,
  status: string
): Action => ({
  type: SAVE_LIST,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/saveList`,
      method: "POST",
      data: {
        attrs,
        catId,
        status
      }
    }
  }
});

// 保存
export const SAVE = "SHOPPING_ATTR_SAVE";
export const save = (attr: shoppingTypes.Attr, values: string[]): Action => ({
  type: SAVE,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/save`,
      method: "POST",
      data: {
        attr,
        values
      }
    }
  }
});

// 检测名称
export const CHECK_NAME = "SHOPPING_ATTR_CHECK_NAME";
export const checkName = (name: string, catId: string, id: string): Action => ({
  type: SAVE,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/spec/save`,
      method: "POST",
      data: {
        name,
        catId,
        id
      }
    }
  }
});

// 删除
export const REMOVE = "SHOPPING_ATTR_REMOVE";
export const remove = (id: string[]): Action => ({
  type: REMOVE,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/del`,
      method: "POST",
      data: {
        id
      }
    }
  }
});

// 设置状态
export const SET_STATUS = "SHOPPING_ATTR_SET_STATUS";
export const setStatus = (id: string[], status: string): Action => ({
  type: SET_STATUS,
  payload: {
    client: "default",
    request: {
      url: `${API}shopping/attr/setStatus`,
      method: "POST",
      data: {
        id,
        status
      }
    }
  }
});
