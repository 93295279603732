import * as actions from '../../store/actions/sys'
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as SysState from "../../store/state/sys";
import * as State from "../../store/state";
import {saveAccount,removeAccount,saveAppToken} from '../../storage/AccountStorage'

export const useSysState = (): SysState.State => {
  const state = useSelector((state: State.Root) => {
    return {
      appToken:state.sys.appToken,
      account:state.sys.account,
      code:state.sys.code,
      message: state.sys.message
    };
  }, shallowEqual);
  return state;
};
/**
 * 组织逻辑
 */
export const useAccount = () => {
  const dispatch:Function = useDispatch();

  // 请求AppToken
  const fetchAppToken = () => {
    // 提交redux
    dispatch(actions.fetchAppToken()).then((res:any)=>{
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 获取成功
        // 保存
        saveAppToken(data.result.token)
        // 通知Redux
        dispatch(actions.fetchAppTokenSuccess(data.result.token))
      } 
    })
  }
  // 登录请求
  const login = (props:any) => {
    const {success,failure} = props
    // 提交redux
    dispatch(actions.login(props)).then((res:any)=>{
      const data = res.payload && res.payload.data;
      const message = data && data.message
      if (data && data.code === 1) {
        const result = data.result
        // 登录成功
        const account = {
            user:result.user,
            token :result.token,
            roles:result.roles
        }
        // 保存
        saveAccount(account)
        // 通知Redux
        dispatch(actions.loginSuccess(account))
        // 成功返回
        if(success)
          success(message ||'登录成功')
      } else {
        // 失败
        dispatch(actions.loginFailure(data && data.code || 0,message))
        // 失败返回
        failure(message ||'登录失败')
      }
    })
  }
  const logout = ()=>{
    // 登出
    removeAccount()
    dispatch(actions.logout()).then((res:any)=>{
      const data = res.payload && res.payload.data;
      const message = (data && data.message) || '退出失败'
      const code = (data && data.code) || '-1' 
      dispatch(actions.logoutFinished(code,message))
    })
  }
  // 返回实时信息提交页面处理
  return {
    fetchAppToken,
    login,
    logout
  }
}
