import React from "react"

import { PageSetting, DataSource } from '../../../../types/ui';

import ComponentsInput from "./ComponentsInput"

type Props = {
    data?: PageSetting[],
    onChange: (dataSource: DataSource, data: PageSetting[]) => void
}
/** 标签页输入 */
export default (props: Props) => {
    const { data = [], onChange } = props

    return <ComponentsInput
        data={data}
        onChange={(data: PageSetting[]) => onChange({ type: "input" }, data)} />
}