
export const data = [{
  name: '',
  title: 'home',
  icon: 'Home',
  exact: true
}, {
  name: 'common',
  title: 'Commons',
  icon: 'Setting',
  children: [
    { name: `module`, title: 'Modules', icon: 'GridSetting', edit: true, editTitle: 'Edit Module' },
    { name: 'area', title: 'Areas', icon: 'Area', edit: true, editTitle: 'Edit Area' },
    { name: `ad`, title: 'Ad manager', icon: 'Ad',edit: true, editTitle: 'Edit Ad' },
    { name: `setting`, title: 'Global settings', icon: 'Setting' },
    { name: `client`, title: 'Clients', icon: 'Client' },
    { name: `keyword`, title: 'Search keywords', icon: 'Keyword' },
    { name: `indexed`, title: 'Create indexed', icon: 'Indexed' },
    { name: `friendUrl`, title: 'Friend link', icon: 'FLink' },
    { name: `board`, title: 'Boards', icon: 'Books' }
  ]
}, {
  name: 'ui',
  title: 'User interface',
  icon: 'CircleLayout',
  children: [
    { name: `nav`, title: 'Navigations', icon: 'CircleLayout', edit: true, editTitle: 'Edit Nav' },
    { name: `component`, title: 'Component manager', icon: 'Component', edit: true, editTitle: 'Edit Component' },
    { name: `page`, title: 'Page manager', icon: 'Html', edit: true, editTitle: 'Edit Page'  }
  ]
}, {
  name: 'content',
  title: 'Content Manager',
  icon: 'DocumentSetting',
  children: [
    { name: `cat`, title: 'Article categories', icon: 'Tree', edit: true, editTitle: 'Edit Category' },
    { name: `article`, title: 'Articles', icon: 'Document', edit: true, editTitle: 'Edit Article' }
  ]
}, {
  name: 'shopping',
  title: 'Shopping Manager',
  icon: 'ShopSetting',
  children: [
    { name: `brand`, title: 'Brand', icon: 'Brand', edit: true, editTitle: 'Edit Brand' },
    { name: `cat`, title: 'Product categories', icon: 'Tree', edit: true, editTitle: 'Edit Product Category' },
    { name: `product`, title: 'Products', icon: 'ThumbList', edit: true, editTitle: 'Edit Product' },
    { name: `attr`, title: 'Attributes', icon: 'CircleList', edit: true, editTitle: 'Edit Attribute' },
    { name: `spec`, title: 'Specifications', icon: 'DotList', edit: true, editTitle: 'Edit Specification' },
    { name: `promotion`, title: 'Promotions', icon: 'Percent', edit: true, editTitle: 'Edit Promotion' },
    { name: `coupon`, title: 'Coupons', icon: 'Coupon', edit: true, editTitle: 'Edit Coupon' },
    { name: `freight`, title: 'Freight', icon: 'Freight', editTitle: 'Edit Freight' }
  ]
}, {
      name: 'trade',
      title: 'Trade',
      icon: 'RMB',
      children: [
        // { name: `setting`, title: 'Trade setting', icon: 'RMB' },
        { name: `order`, title: 'Orders', icon: 'Books' },
        { name: `refund-reason`, title: 'Refund reason', icon: 'Refund' }
      ]
    }, {
  name: 'integral',
  title: 'Integral Manager',
  icon: 'SolidIntegral',
  children: [
    { name: `setting`, title: 'Integral setting', icon: 'Integral' },
    { name: `exchange`, title: 'Integral exchange', icon: 'Integral' }
  ]
}, {
  name: 'interact',
  title: 'Interact Manager',
  icon: 'Interact',
  children: [
    { name: `comment`, title: 'Comments', icon: 'Comments' },
    { name: `share`, title: 'Shares', icon: 'ShareDown' },
    { name: `like`, title: 'Thumbs up', icon: 'Like' },
    { name: `collect`, title: 'Collections', icon: 'StarSetting' }
  ]
}, {
  name: 'stat',
  title: 'Statistics Analysis',
  icon: 'CircleStat',
  children: [
    { name: `index`, title: 'Article analysis', icon: 'Stat' },
    { name: `index`, title: 'Shopping analysis', icon: 'Stat' },
    { name: `users`, title: 'User analysis', icon: 'Stat' }
  ]
}, {
  name: 'user',
  title: 'User Manager',
  icon: 'UserSetting',
  children: [
    { name: `comment`, title: 'Users', icon: 'Users' }
  ]
}]