import React from "react"
import classNames from "classnames"
import { useUIInputsStyles,useUIPageStyles } from "../../../../assets/jss/pages"

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconAdd from '@material-ui/icons/Add';
import IconList from '@material-ui/icons/List';

import FormField from "../../../../components/ui/form/FormField"

import ArticleCatTreeDialog from '../../../../components/content/CatTreeDialog'
import ProductCatTreeDialog from '../../../../components/shopping/CatTreeDialog'

import { DataSource,PageSetting } from "../../../../types/ui"
import { Menu, Cat } from "./types"

import ComponentsInput from "./ComponentsInput"

import { useItemMethods } from './components/Util';
import ItemTools from "./components/ItemTools"


type DataInputProps = {
    data: Menu[],
    onChange: (dataSource: DataSource, data: Menu[]) => void
}
type ItemProps = {
    item: Menu,
    showUp?: boolean,
    showDown?: boolean,
    sortUp?: () => void,
    sortDown?: () => void,
    onSave?: (item: any) => void,
    onRemove?: (item: any) => void,
}

const Item = (props: ItemProps) => {
    const { item, showUp, showDown, sortUp, sortDown, onSave, onRemove } = props
    const classes = useUIInputsStyles()
    const pageClasses = useUIPageStyles()

    const [form, setForm] = React.useState<any>(item)
    const setFormItem = (key: "id" | "editing" | "items", value: any) => {
        form[key] = value;
        setForm(Object.assign({}, form))
    }

    return <Box className={classNames(classes.listItem, {
        [classes.editing]: form.editing
    })}>
        <Box className={classes.itemFields}>
                <FormField
                    label="分类："
                    labelWidth={120}>
                    {form.cat ? <>
                        {form.cat.name}
                        <span className={pageClasses.label}>({form.cat.descri})</span>
                    </> :
                        "未设置"
                    }
                </FormField>
                
            <ComponentsInput
                data={form.items}
                onChange={(data: PageSetting[]) => setFormItem("items", data)} />
        </Box>
        <ItemTools
            editing={form.editing}
            onSave={() => {
                setFormItem("editing", false);
                if (onSave) onSave(form);
            }}
            onCancel={() => setFormItem("editing", false)}
            onEditing={() => setFormItem("editing", true)}
            onRemove={() => {
                if (onRemove) onRemove(form);
            }}
            showUp={showUp}
            showDown={showDown}
            sortUp={sortUp}
            sortDown={sortDown} />
    </Box>
}

// 分类菜单数据输入
export default (props: DataInputProps) => {
    const classes = useUIInputsStyles()
    const { data, onChange } = props


    const [openA, setOpenA] = React.useState<boolean>(false)
    const [openB, setOpenB] = React.useState<boolean>(false)

    const {
        onItemAdd,
        onItemSave,
        onItemRemove,
        sortUp,
        sortDown
    } = useItemMethods({
        data,
        onChange: (data: any) => {
            if (onChange) {
                onChange({ type: "input" }, data)
            }
        }
    });

    const handleAdd = (catType: string, cat: Cat) => {
        onItemAdd({
            catType,
            cat
        })
    }


    return <Box>
        <Box className={classes.list}>
            <Box className={classes.listToolbar}>
                <Box className={classes.listToolbarTitle}> <IconList />菜单列表</Box>
                <Box className={classes.grow} />
                <Button color="primary" onClick={() => setOpenA(true)}>
                    <IconAdd />添加文章分类
            </Button>
                <Button color="primary" onClick={() => setOpenB(true)}>
                    <IconAdd />添加商品分类
            </Button>
            </Box>

            {data.map((item: any, index: number) => <Item
                key={item.id || index}
                item={item}
                onSave={onItemSave}
                onRemove={onItemRemove}
                showUp={index > 0}
                showDown={index < data.length - 1}
                sortUp={() => {
                    sortUp(index)
                }}
                sortDown={() => {
                    sortDown(index)
                }} />)}

        </Box>
        {
            openA &&
            <ArticleCatTreeDialog
                {...{
                    open: openA,
                    ok: (item: any, names: string) => {
                        handleAdd("article", item)
                        setOpenA(false)
                    },
                    cancel: () => {
                        setOpenA(false)
                    }
                }} />
        }

        {
            openB &&
            <ProductCatTreeDialog
                {...{
                    open: openB,
                    ok: (item: any, names: string) => {
                        handleAdd("product", item)
                        setOpenB(false)
                    },
                    cancel: () => {
                        setOpenB(false)
                    }
                }} />
        }
    </Box>
}