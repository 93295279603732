import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'


// Action Types
export type Action =  {
    // 上传
    type: 'COMMON_UPLOAD',
    payload: {
      client: 'default',
      request: AxiosRequestConfig
    }
  };


const getUploadUrl = (type:string)=>{
  if(type === 'images' || type === 'image'){
    return 'uploadImage'
  }else if(type === 'medias' || type === 'media'){
    return 'uploadMedia'
  }else if(type === 'videos' || type === 'video'){
    return 'uploadVideo'
  }else if(type === 'audios' || type === 'audio'){
    return 'uploadAudio'
  }else if(type === 'flashes' || type === 'flash'){
    return 'uploadFlash'
  }else {
    return 'uploadFile'
  }
}
/** 获取appToken  */
export const UPLOAD = 'COMMON_UPLOAD';
export const upload = (type:string,data:any,onProgress:Function): Action => ({
  type: UPLOAD,
  payload: {
    client: 'default',
    request: {
      url: `${API}file/${getUploadUrl(type)}`,
      method:'POST',
      timeout: 1000*60*60*24,
      // `onUploadProgress` 允许为上传处理进度事件
      onUploadProgress: function (event) {
        // 对原生进度事件的处理
        if(onProgress){
          onProgress(event)
        }
      },
      // `onDownloadProgress` 允许为下载处理进度事件
      // onDownloadProgress: function (progressEvent) {
      //   // 对原生进度事件的处理
      // },
      headers:{
        'Content-Type':'multipart/form-data'
      },
      data
    }
  }
})