import React from "react";

import classNames from "classnames";
import { useUIInputsStyles } from "../../../../assets/jss/pages";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import IconList from "@material-ui/icons/List";

import { ComponentOption } from "../../../../types/ui";
import InputListItem from "./InputListItem";

import { useItemMethods } from './components/Util';
import ItemTools from "./components/ItemTools"

type Props = {
  editing?: boolean,
  data: any;
  options: ComponentOption[];
  edittable?: boolean;
  onChange?: (data: any) => void;
  tools?: any;
};
type ItemProps = {
  editing?: boolean,
  item: any;
  showUp?: boolean;
  showDown?: boolean;
  options: ComponentOption[];
  sortUp?: () => void;
  sortDown?: () => void;
  onUpdate?: (item: any) => void;
  onSave?: (item: any) => void;
  onRemove?: (item: any) => void;
};

/**
 * 列表条目
 */
const Item = (props: ItemProps) => {
  const {
    item,
    showUp,
    showDown,
    sortUp,
    sortDown,
    options,
    onSave,
    onRemove,
    editing = true
  } = props;
  const classes = useUIInputsStyles();

  const [form, setForm] = React.useState<any>(item);
  const setFormItem = (key: string, value: any) => {
    const newForm = form;
    newForm[key] = value;
    setForm(Object.assign({}, newForm));
  };

  return (
    <Box className={classNames(classes.listItem, {
      [classes.editing]: form.editing
    })}
    >
      <Box className={classes.itemFields}>
        {options.map((option: ComponentOption, index: number) => (
          <InputListItem
            key={index}
            editing={editing && form.editing}
            option={option}
            value={form[option.name]}
            onChange={(name: string, value: any) => {
              setFormItem(name, value);
            }}
          />
        ))}
      </Box>
      {editing &&
        <ItemTools
          editing={form.editing}
          onSave={() => {
            setFormItem("editing", false);
            if (onSave) onSave(form);
          }}
          onCancel={() => setFormItem("editing", false)}
          onEditing={() => setFormItem("editing", true)}
          onRemove={() => {
            if (onRemove) onRemove(form);
          }}
          showUp={showUp}
          showDown={showDown}
          sortUp={sortUp}
          sortDown={sortDown} />}
    </Box>
  );
};

export default (props: Props) => {
  const { options, data = [], onChange, tools, editing = true } = props;
  const classes = useUIInputsStyles();

  const {
    onItemAdd,
    onItemSave,
    onItemRemove,
    sortUp,
    sortDown
  } = useItemMethods({
    data,
    initialData: {},
    onChange: (data: any) => {
      if (onChange) {
        onChange(data);
      }
    }
  });

  return (
    <Box className={classes.list}>
      <Box className={classes.listToolbar}>
        <Box className={classes.listToolbarTitle}>
          {" "}
          <IconList />
          数据列表
        </Box>
        {tools && <Box>{tools}</Box>}
        <Box className={classes.grow} />
        {editing && <Button color="primary" onClick={() => onItemAdd()}>
          <IconAdd />
          添加数据
        </Button>}
      </Box>
      {data.map((item: any, index: number) => (
        <Item
          key={item.id || index}
          options={options}
          item={item}
          onSave={onItemSave}
          onRemove={onItemRemove}
          showUp={index > 0}
          showDown={index < data.length - 1}
          sortUp={() => {
            sortUp(index);
          }}
          sortDown={() => {
            sortDown(index);
          }}
          editing={editing}
        />
      ))}
    </Box>
  );
};
