import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// Inspired by the Facebook spinners.
const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    top: {
      color: '#eef3fd',
    },
    bottom: {
      color: '#6798e5',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
  }));
function FacebookProgress(props: any) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={24}
        thickness={4}
        {...props}
    />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={24}
        thickness={4}
        {...props}
    />
    </div>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    //   position: 'fixed',
    //   width: '100%',
    //   height: '100%',
    //   background: 'rgba(225, 225, 225,0.2)',
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      // zIndex: 999,
      // top: 0,
      // left: 0,
      padding:theme.spacing(1,0)
    },
    loading: {
      // background: 'rgba(0, 0, 0,0.3)',
      // borderRadius: '4px',
      // border: '1px solid #eee',
      // boxShadow:
      // "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px 0 rgba(0, 0, 0, 0.15)",
      margin: '0 auto',
      padding: '5px 10px',
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      // padding: '15px 20px 10px 20px',
      alignItems:'center'
    },
    text: {
      color: '#333',
      fontSize:'.8em',
      margin: '2px 10px'
    },
    precent: {
      color: '#333',
      fontSize:'.8em',
    },
  }))
export default (props: any) => {
  const classes = useStyles();
  const { open, text,precent } = props;
  if (open) {
    return (<div className={classes.root}>
      <div className={classes.loading}>
        <div className={classes.box}>
          <FacebookProgress />
          <span className={classes.text}>{text || 'Loading...'}</span>
          <span className={classes.precent}>{precent && precent >= 0 && `${Math.ceil(precent)}%`}</span>
        </div>
      </div>
    </div>)
  }else{
    return <></>
  }
}