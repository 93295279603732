import React, { useState, useEffect } from 'react'
import { createId } from '../../../lib/util'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Icon from '../../../components/Icon'

import { withRouter } from "react-router-dom";
import { useCat } from '../../../provides/content'
import { useModule } from '../../../provides/common'
import { useLocale } from '../../../config/Locale'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, ConfirmDialog } from '../../../components/ui/dialog'
// 分类树
import DataTreeView from '../../../components/ui/tree/DataTree'
// 工具栏
import PageToolbar from '../../../components/ui/PageToolbar'

import { useListPageStyles } from '../../../assets/jss/pages'

const CatTree = (props: any) => {
  const { history, moduleId, loading, loaded, onLoaded, onRemove, onItemChecked } = props
  const [list, setList] = useState<any>([]);
  const { getList } = useCat();

  const loadList = () => {
    loading()
    getList({
      params: {
        moduleId
      },
      success: (list: any) => {
        setList(list)
        onLoaded()
      },
      failure: (message: string) => {
        setList([])
        onLoaded()
      }
    })
  }

  useEffect(() => {
    if (!loaded) {
      loadList()
    }
  })


  return <DataTreeView
    notShowAll
    items={list || []}
    edit={true}
    showCheckbox={true}
    showDescri={true}
    onEdit={(item: any) => {
      history.replace(`/content/cat/edit?id=${item.id}`)
    }}
    onAppend={(item: any) => {
      history.replace(`/content/cat/edit?parentId=${item.id}&id=${createId()}`)
    }}
    onRemove={onRemove}
    onItemChecked={onItemChecked}
  />
}

const CatPage = (props: any) => {
  const { history } = props
  const { translate } = useLocale()
  const { getList } = useModule()
  const { remove } = useCat()
  const [list, setList] = useState<any>([])
  const [checked, setChecked] = useState<any>({})
  const classes = useListPageStyles()

  const [loaded, setLoaded] = useState<any>({})
  const handleLoaded = (id: string, load: boolean) => {
    if (load) {
      loaded[id] = true
    } else {
      delete loaded[id]
    }
    setLoaded(Object.assign({}, loaded))
  }

  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)


  const loadData = (cb?:()=>void) => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载数据...'
    })
    getList({
      success: (list: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        setList(list)
        if(cb){
          cb()
        }
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setList([])
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
  }
  useEffect(() => {
    loadData()
    return () => {
    };
  }, []);

  // 警示框
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState({
    children: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[],cb:()=>void) => {
    setConfirmOpen(true)
    setConfirmProps({
      children: <Box className={classes.alert}>
        <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
        <Box>
          {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
          <Box>
            <Box>{translate('shopping.removeCatTips')}</Box>
            <Box>{translate('shopping.removeAsk')}</Box>
          </Box>
        </Box>
      </Box>,
      title: '',
      ok: () => {
        setConfirmOpen(false)
        setLoading({
          open: true,
          text: translate('shopping.removingCat')//'正在删除商品...'
        })
        remove({
          id,
          success: (message: string) => {
            loadData(cb)
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setConfirmOpen(false)
      }
    }) 
  }


  const renderPageToolbar = (
    <PageToolbar
      {...{
        title: translate('content.cats'),
        label: {
          add: translate('appendCat')
        },
        onAddClick: () => {
          // 跳转添加页面
          const location = {
            pathname: "/content/cat/edit",
            search: `?id=${createId()}`,
            state: {
              fromList: true
            }
          }
          history.push(location)
          history.replace(location)
        },
        onDelClick: () => {
          // 批量删除
          if (checked) {
            handleDelete(Object.keys(checked),()=>{
              setLoaded({})
            })
          }
        },
        onRefresh: () => {
          // 刷新数据
          setLoaded({})
          loadData()
        },
      }} />)


  return (<Box className={classes.root}>

    {renderPageToolbar}

    <LoadingBox {...loading} />

    {list.length === 0 && !loading.open && (<Box className="error">
      <Icon name="Error" width={32} height={32} color="gray" />
      <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
    </Box>)}
    <Box className={classes.pageContent}>
      {list.map((module: any) =>
        <Paper key={module.id} style={{ margin: '10px 0 20px 0' }}>
          <Box style={{ padding: 10, boxShadow: '0 2px 4px rgba(125,125,125,.2)' }}>{module.name}</Box>
          <CatTree
            moduleId={module.id}
            history={history}
            loading={() => {
              handleLoaded(module.id, true)
              setLoading({
                open: true,
                text: '正在加载数据...'
              })
            }}
            loaded={loaded[module.id]}
            onLoaded={() => {
              setLoading(initialLoading)
            }}
            onRemove={(item: any) => {
              handleLoaded(module.id, false)
              handleDelete([item.id],()=>{
                setLoaded({})
              })
            }}
            onItemChecked={(checked: any) => {
              setChecked(checked)
            }} />
        </Paper>
      )}
    </Box>

    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 确认框 */}
    <ConfirmDialog open={confirmOpen} {...confirmProps} />
  </Box>
  )
}
export default withRouter(CatPage)