import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import MuiTableRow from '@material-ui/core/TableRow';

export const TableRow = withStyles((theme: Theme) =>
  createStyles({
    root:{
      backgroundColor: '#fff',
      '&:hover':{
      }
    },
  }),
)(MuiTableRow);
