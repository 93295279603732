import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "svg-icon"
}) => (<svg className={className}
    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5091"
    width={width} height={height}>
    <path fill={color} d="M950.4 361.6l-288-288c-12.8-12.8-32-12.8-44.8 0s-12.8 32 0 44.8l233.6 233.6L96 352c-19.2 0-32 12.8-32 32s12.8 32 32 32l832 0c12.8 0 25.6-6.4 28.8-19.2C963.2 384 960 371.2 950.4 361.6z" p-id="5092"></path><path d="M928 608 96 608c-12.8 0-25.6 6.4-28.8 19.2C60.8 640 64 652.8 73.6 662.4l288 288c6.4 6.4 16 9.6 22.4 9.6s16-3.2 22.4-9.6c12.8-12.8 12.8-32 0-44.8L172.8 672 928 672c19.2 0 32-12.8 32-32S947.2 608 928 608z" p-id="5093"></path></svg>)
