import * as actions from '../../store/actions/common/file'
import { useDispatch } from "react-redux";

export type FileUploadProps = {
  type:string,
  success:(props?:any)=>void,
  failure?:(message?:any)=>void,
  data:any,
  onProgress?:any
}
/**
 * 组织逻辑
 */
export const useFile = () => {
  const dispatch:Function = useDispatch();

  // 上传
  const upload = (props: FileUploadProps) => {
    const { type,success, failure, data,onProgress } = props
    // 提交redux
    dispatch(actions.upload(type,data,onProgress)).then((res: any) => {
      const data = res.payload && res.payload.data;
      if (data && data.code === 1) {
        // 成功返回
        if (success)
          success(data.result.attaches)
      } else {
        // 失败返回
        if(failure){
          const message = data && data.message || '上传失败'
          failure(message)
        }
      }
    })
  }
  // 返回实时信息提交页面处理
  return {
    upload
  }
}
