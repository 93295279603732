
import React from 'react';

import classNames from "classnames"
import { useGalleryStyles } from "../../assets/jss/components"
import { useMediaMatches, MOBILE,PAD } from "../MediaMatches"

import Slider from "./Slider"

// 轮播设置
export type CarouselSetting = {
    dots?:boolean,
    arrows?:boolean,
    fade?: boolean,
    infinite?: boolean,
    speed?:number,
    autoplay?: boolean,
    autoplaySpeed?: number,
    slidesToShow?: number,
    slidesToScroll?: number,
    vertical?:boolean
}
type SliderItemProps = {
  height?: number,
  mobileHeight?:number,
  padHeight?:number,
  image: string,
  round?: boolean,
  selected?:boolean,
  onSelected?:()=>void
}


type Props = {
  backgroundColor?: string,
  width?: number,
  height?: number,
  mobileHeight?:number,
  padHeight?:number,
  mobileImgHeight?:number,
  padImgHeight?:number,
  images: string[],
  setting?: CarouselSetting,
  variant?: "default" | "rounddot" | "gray"|"gallery",
  margin?: string | number,
  padding?: string | number,
  round?: boolean,
  align?: string,
  onSelected?:(index:number)=>void
}

const SliderItem = (props: SliderItemProps) => {
  const matches = useMediaMatches()
  const classes = useGalleryStyles();
  const {
    height,
    mobileHeight,
    padHeight,
    image,
    round,
    selected,
    onSelected
  } = props

    let cssHeight =  height ? `${height- 8}px` : "100%"
    if(matches === MOBILE && mobileHeight){
         cssHeight =  `${mobileHeight - 8}px`
    }else   if(matches === PAD && padHeight){
         cssHeight = `${padHeight- 8}px`
    }

  return <div className={classNames(classes.item,classes.imageItem,{
    [classes.imageItemSelected]:selected
    })}
    onClick={()=>{
      if(onSelected){
        onSelected()
      }
    }}
    style={{
      borderRadius: round ? 3 : 0,
      margin:4
    }}>
    <img src={image} height={cssHeight} width="auto"/>
  </div>
}

export default (props: Props) => {
  const {
    images,
    backgroundColor,
    margin,
    padding,
    variant = "default",
    height,
    mobileHeight,
    padHeight,
    width,
    round,
    setting = {},
    onSelected
  } = props

  const [selected,setSelected] = React.useState<number>(0)

//  if(!data){
//    return <></>
//  }
 
  const slideSetting = {
      dots: setting.dots?true:false,
      arrows: setting.arrows?true:false,
      fade: setting.fade?true:false,
      infinite:  setting.infinite?true:false,
      speed: setting.speed || 500,
      autoplay: setting.autoplay?true:false,
      autoplaySpeed: setting.autoplaySpeed || 3000,
      slidesToShow: setting.slidesToShow || 1,
      centerMode: false,
      variableWidth: true,
      slidesToScroll: setting.slidesToScroll || 1,
      vertical: setting.vertical?true:false
  }
  const classes = useGalleryStyles();

  const matches = useMediaMatches()

  let cssHeight =  height ? `${height+8}px` : "100%"
  if(matches === MOBILE && mobileHeight){
       cssHeight =  `${mobileHeight+8}px`
  }else if(matches === PAD && padHeight){
       cssHeight = `${padHeight+8}px`
  }

  return <div className={classes.staticContainer}
    style={{
      backgroundColor,
      maxWidth: width ? `${width}px` : "100%",
      margin, 
      padding,
      borderRadius: round ? 3 : 0,
      paddingLeft:variant === 'gallery' ?26:0,
      paddingRight:variant === 'gallery' ?26:0
    }}>
    <Slider
    {...slideSetting}
    className={variant + "-slick" + (slideSetting.vertical ? " vertical" : "")}
    style={{
      height: cssHeight,
    }}>
    {images.map((image: string, index: number) =>
      <SliderItem
        key={index}
        {...{
          height,
          mobileHeight,
          padHeight,
          image,
          selected:selected === index,
          onSelected:()=>{
              setSelected(index)
            if(onSelected){
              onSelected(index)
            }
          }
        }} />
    )}
  </Slider>
  </div>
}

