import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1097 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="6121"
    width={width} height={height}>
    <path fill={color} d="M365.728 329.152q0 45.728-32 77.728t-77.728 32-77.728-32-32-77.728 32-77.728 77.728-32 77.728 32 32 77.728z m585.12 219.424v256H146.272V694.848L329.12 512l91.424 91.424L713.12 310.848z m54.88-402.304H91.456q-7.424 0-12.864 5.44t-5.44 12.864v694.848q0 7.424 5.44 12.864t12.864 5.44h914.272q7.424 0 12.864-5.44t5.44-12.864V164.576q0-7.424-5.44-12.864t-12.864-5.44z m91.424 18.304v694.848q0 37.728-26.848 64.576t-64.576 26.848H91.456q-37.728 0-64.576-26.848T0.032 859.424V164.576q0-37.728 26.848-64.576t64.576-26.848h914.272q37.728 0 64.576 26.848t26.848 64.576z" p-id="6122"></path></svg>
    )
