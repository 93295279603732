import React from "react"
import classNames from "classnames"
import Box from '@material-ui/core/Box';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import reactComposition from 'react-composition'

import { useSearchStyles } from "../../assets/jss/components"

type Props = {
  value?: string,
  placeholder?: string,
  onChange?: (value: string) => void,
  onSearch?: (value: string) => void
}

export default (props: Props) => {

  const classes = useSearchStyles()

  const { value, placeholder, onChange, onSearch } = props
  const [newValue, setNewValue] = React.useState<string>(value || "")

  return <Box className={classes.search}>
    <InputBase
      value={newValue}
      placeholder={placeholder || "Search…"}
      classes={{
        root: classNames(classes.inputRoot, {
          [classes.inputSearch]: Boolean(onSearch)
        }),
        input: classes.inputInput,
      }}
      inputProps={{ 'aria-label': 'search' }}
      {...reactComposition({
        onChange: function (event: any) {
          var value = event.target.value
          setNewValue(value)
          if (event.reactComposition.composition === false) {
            if (onChange) onChange(value)
          }
        },
        /*
        ,
        onCompositionStart: function (event) {},
        onCompositionUpdate: function (event) {}, 
        onCompositionEnd: function (event) {}
        */
        // 若要监听原生的 onCompositionStart|Update|End 事件
        // 需在 reactComposition(settings) 的 settings 中绑定回调函数
      })}
    />
    <Button className={classNames(classes.searchIcon, {
      [classes.searchButton]: Boolean(onSearch)
    })}
      onClick={() => {
        if (onSearch)
          onSearch(newValue)

      }}>
      <SearchIcon />
    </Button>
  </Box>
}
