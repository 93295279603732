import React from 'react';
import {attachPrefix} from "../../config"
import { useChatStyles } from '../../assets/jss/components'
  
export default (props:any) => {
  const { avatar } = props
    const classes = useChatStyles()
    return  <span 
               className={classes.avatar}
               style={{ backgroundImage: `url(${
                 avatar?
                 avatar.indexOf('http')!=-1?
                 avatar:
               `${attachPrefix}${avatar}`:
               require('../../assets/images/logo512.png')})` }}
             />
}