import React from 'react'
import clsx from 'clsx'
import { ListProps } from './types'
import Paper from '@material-ui/core/Paper';

export default (props: ListProps) => {
    const {
        classes,
        specList,
        selected,
        onSelected
    } = props

    const toggleSelect = (index: number, vIndex: number) => {
        // 选择规格作为商品规格
        let spec = specList[index];
        let specVal = spec.valList[vIndex];
        if (
            selected[spec.id] &&
            selected[spec.id]["specVals"][specVal.id]
        ) {
            // 已存在，则移除
            delete selected[spec.id]["specVals"][specVal.id];
            let keys = Object.keys(selected[spec.id]["specVals"]);
            if (keys.length === 0) {
                // 值列表为空，删除当前规格
                delete selected[spec.id];
            }
        } else {
            // 添加
            selected[spec.id] = selected[spec.id] || {
                spec,
                specVals: {}
            };
            selected[spec.id]["specVals"][specVal.id] = specVal;
        }
        // 需要重新给对象赋值，否则页面无法监测变化
        onSelected(Object.assign({}, selected));
    }


    // <!-- 已添加的键值对规格列表 -->
    return (
        <Paper className={classes.paper}>
            <div className={classes.list}>
                {
                    specList.map((spec: any, index: number) => (
                        <div key={spec.id} className={classes.row}>
                            <div className={classes.rowLabel}>{spec.name} ：</div>
                            <div className={classes.rowContent}>
                            {
                                spec.valList.map((val: any, vIndex: number) => (
                                    <a key={val.id}
                                        className={clsx(classes.aBox, {
                                            [classes.active]: selected[spec.id] && selected[spec.id]['specVals'][val.id]
                                        })}
                                        onClick={() => { toggleSelect(index, vIndex) }}>
                                        <span>{val.value}</span>
                                    </a>
                                ))
                            }
                            </div>
                        </div>
                    ))
                }
            </div>
    </Paper>)
}