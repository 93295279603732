import React from 'react'
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Chat from "./index"
import Icon from "../../components/Icon"
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close'
 
import { createStyles,makeStyles,Theme} from "@material-ui/core/styles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import {withDrawerStyles,useDrawerStyles} from "../../assets/jss/components/ui/drawerStyles"

import {ChatUser } from "./types"

const Drawer = withDrawerStyles(SwipeableDrawer)
 
const useTitleStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { 
      margin: 0,
      background: '#f5f5f5',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderBottom: '1px solid #dedede',
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
  }))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(1),
      minWidth: theme.spacing(5),
      alignItems: 'center',
      justifyContent: 'center'
    },
  }));
type Props = {
    open:boolean,
    close:()=>void,
    client:any
}  
 
export default (props:Props)=>{
    const classes = useStyles();
    const drawerClasses = useDrawerStyles()
    const {open,close,client} = props 
    
    const [title,setTitle] = React.useState<string>('客服')
    const onChating=(other:ChatUser)=>{
      if(other){
        setTitle('正在与“'+other.username+'”通话')
      }else{
        setTitle('客服')
      }
    }
    const handleClose = ()=>{
      close()
    }
  
    return  <Drawer
            anchor="right"
            open={open}
            onOpen={()=>{}}
            onClose={handleClose}
            variant="temporary">
            <Box>
            <Box className={drawerClasses.content}>
            <Box  className={drawerClasses.header} style={{color:"#333",flex:1,background:"#fff"}}>
                <Box className={drawerClasses.title}>
                     <Icon name="Comments"/> 
                     <span style={{marginLeft:8}}>{title}</span>
                </Box>
                <Box className={drawerClasses.grow}/>
                <IconButton onClick={handleClose}>
                    <IconClose />
                </IconButton>
              </Box>
                <Chat onChating={onChating} client={client}/>
            </Box>
            </Box>
        </Drawer>
}