
import {
  NotifyPayload,
  ChatPayload,
  MessagePayload,
  UsersPayload,
  ChatHistoryPayload,
  ChatMessage, 
  ChatUser,
  AskData,
  ChatAuth,
  MessageReceivedProps,
  ReadedPayload,
  RegisterPayload,
  Register
} from './types'

import {chatHost} from "../../config"

const io = require('socket.io-client')

export default ()=>{
    const client = io.connect(chatHost);

    // const client = io.connect(chatHost, {
    //   path:'/socket.io',
    //   secure: true,
    //   transports: ['websocket', 'xhr-polling', 'jsonp-polling']
    // });
      
      
    const onConnect = (cb:()=>void)=>{
      client.on('connect', ()  =>{
        cb()
      });
    }

    const onDisconnect = (cb:()=>void) =>{
      client.on('disconnect', () => {
        cb()
      }); 
    }

    const disconnect = ()=>{
      client.disconnect()
    }

    const registerRequest = (payload:RegisterPayload,cb:(askData:AskData)=>void)=>{
         client.emit('register', payload,cb);
    } 

    const registerNotifyRequest = (auth:ChatAuth,cb:(askData:AskData)=>void)=>{
      client.emit('registerNotify', auth,cb);
    } 

    const onRegistersReceived = (cb:(registers:Register[])=>void)=>{
         client.on("registers", cb);
    }
    const readedRequest = (data:ReadedPayload,cb:(askData:AskData)=>void)=>{
         client.emit('readed', data,cb);
    }
 
    const usersRequest = (payload:UsersPayload,cb:(askData:AskData)=>void)=>{
         client.emit('users', payload,cb);
    }

    const chatRequest = (payload:ChatPayload,cb:(askData:AskData)=>void)=>{
         client.emit('chat', payload,cb);
    }

    const chatHistory = (payload:ChatHistoryPayload,cb:(askData:AskData)=>void)=>{
         client.emit('history', payload,cb);
    }

    const sendMessage = (payload:MessagePayload,cb:(askData:AskData)=>void)=>{
         client.emit('message', payload,cb); 
    }

    const onNotifyReceived = (cb:(payload:NotifyPayload)=>void)=>{
      client.on("notify", cb);
    }

    const onMessageReceived = (props:MessageReceivedProps)=>{
          const {
            user,
            chatMessageCallback,
            leaveCallback,
            statusCallback
          }=props
           client.on("message", (payload:MessagePayload,ackServerCallback:any)  =>{
                if(payload.event  && payload.event === 'message' && payload.chatMessage && chatMessageCallback){
                   chatMessageCallback(payload.chatMessage)
                   if(payload.chatMessage.toUid === user.id && ackServerCallback){
                         // send ack data to server
                         ackServerCallback('server message was delivered to client!');
                   }
                }else  if(payload.event  && payload.event === 'leave' && payload.message && leaveCallback){
                  leaveCallback(payload.message)
                }else if(payload.event  && payload.event === 'status' && payload.id && payload.status && statusCallback){
                  statusCallback(payload.id,payload.status)
                }
           });
    }

  const offMessageReceived = ()=> {
    client.off('message') 
  }

    const leave = (room:string, cb:()=>void) => {
      client.emit('leave', room, cb)
    }
    const onError = (cb:(err:any)=>void) => {
      client.on('error', cb);
    }

     return {
         onConnect,
         onDisconnect,
         disconnect,
         registerRequest,
         usersRequest,
         chatRequest, 
         sendMessage, 
         chatHistory,
         registerNotifyRequest,
         onNotifyReceived,
         onRegistersReceived,
         onMessageReceived,
         offMessageReceived,
         leave,
         onError,
         readedRequest
    };
}