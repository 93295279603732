import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="16067"
    width={width} height={height}><path fill={color} d="M425.557333 658.410667l-71.061333 159.573333c-5.461333 12.266667-0.938667 24.106667 11.328 29.568 12.266667 5.504 24.106667 0.938667 29.568-11.328l71.061333-159.616c5.461333-12.266667 0.938667-24.106667-11.328-29.568-12.266667-5.482667-24.085333-0.917333-29.568 11.370667z m-130.944-58.325334l-71.104 159.616c-5.461333 12.266667-0.896 24.106667 11.370667 29.568s24.106667 0.896 29.568-11.328l71.061333-159.616c5.461333-12.266667 0.896-24.106667-11.328-29.568-8.192-3.690667-24.149333-0.96-29.568 11.328z m257.834667 114.773334l-71.061333 159.616c-5.461333 12.266667-0.938667 24.106667 11.328 29.568 12.266667 5.461333 24.106667 0.938667 29.568-11.328l71.061333-159.616c5.461333-12.266667 0.938667-24.106667-11.328-29.568-8.213333-3.648-24.106667-0.917333-29.568 11.328zM896.064 416.704c32.704 14.549333 48.661333 56 34.048 88.704l-36.416 81.856c-14.613333 32.746667-56 48.661333-88.704 34.048l-564.906667-251.370667c-32.704-14.613333-48.618667-56-34.048-88.704l36.458667-81.898666c14.570667-32.704 56-48.661333 88.704-34.048l184.170667 81.984 58.325333-130.944c14.549333-32.746667 56-48.661333 88.704-34.048l81.856 36.416c32.746667 14.549333 48.661333 56 34.048 88.704l-58.282667 130.986666L896 416.746667l0.064-0.042667zM733.333333 187.328c5.461333-12.266667 0.938667-24.106667-11.328-29.568l-81.856-36.458667c-8.192-3.584-20.032 0.896-25.493333 13.226667l-76.522667 171.861333-225.152-100.245333c-12.266667-5.461333-24.106667-0.896-29.568 11.370667l-36.416 81.856c-5.461333 12.266667-0.896 24.106667 11.328 29.568l564.8 251.456c12.266667 5.461333 24.106667 0.896 29.568-11.328l36.458667-81.898667c5.461333-12.266667 0.896-24.106667-11.370667-29.568l-220.949333-98.325333 76.522667-171.904v-0.042667z" p-id="16068"></path>
    <path fill={color}  d="M139.477333 629.098667c-14.613333 32.704 1.344 74.154667 34.048 88.704l417.450667 185.877333c32.704 14.549333 74.154667-1.344 88.704-34.048l112.981333-253.781333-540.245333-240.448-112.938667 253.738666v-0.042666z m712.32-35.925334l-131.178666 294.656a110.912 110.912 0 0 1-145.898667 57.621334l-1.941333-0.853334-417.493334-185.834666a110.912 110.912 0 0 1-57.642666-145.898667l0.896-1.984 131.221333-294.698667 622.08 276.992h-0.042667z" p-id="16069"></path></svg>
    )
