import React,{useState,useEffect} from 'react';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableHead } from '../../../components/ui/table'
import PageToolbar from '../../../components/ui/PageToolbar'

import {createId} from "../../../lib/util" 
import {useFriendUrl} from "../../../provides/common"
import { useLocale } from '../../../config/Locale' 
import { attachPrefix } from '../../../config';

import Icon from '../../../components/Icon'
import TextInput from '../../../components/ui/form/TextInput'
import ImageInput from '../../../components/ui/form/ImageInput'
import RadioInput from '../../../components/ui/form/RadioInput'

import { useListPageStyles } from '../../../assets/jss/pages'
import LoadingBox from '../../../components/ui/LoadingBox'
import { MessageDialog, AlertDialog } from '../../../components/ui/dialog'

import EditRow from "./EditRow"

const fields = [
  { id: 'logo', label: '站点LOGO' },
  { id: 'sitename', label: '站点名称'},
  { id: 'url', label: '站点网站' },
  { id: 'sorter', label: '排序'},
  { id: 'status', label: '状态'}
];


const EnhancedPageToolbar = (props: any) => {

  const { translate } = useLocale()
  const { onAddClick, onRefresh } = props;

  return <PageToolbar
    {...{
      title:translate('common.friendUrl.pageTitle'),
      label:{
        add: translate('common.friendUrl.add')
      },
      onAddClick,
      onRefresh
    }} />
};

export default ()=> {
     const classes = useListPageStyles()
     const {getList,save,remove} = useFriendUrl()
     const { translate } = useLocale()
     const  initialData:any = []

    const initialLoading = { open: false, text: '' }
    const [loading, setLoading] = useState(initialLoading)
     // 事件反馈
     const initialMessage = {
        open: false,
        type: '',
        title: '',
        text: ''
      }

    const [message, setMessage] = useState(initialMessage)

    const [list,setList] = useState<any>(initialData)

    const loadData = ()=>{
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载列表...'
    })
    getList({
      success: (props: any) => {
        const { list } = props
        setList(list)
        // 关闭加载条
        setLoading(initialLoading)
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        setList([])
        setMessage({
          open: false,
          type: 'error',
          title: '提示',
          text: message
        })
      }
    })
    }
    useEffect(() => {
      loadData()
      return () => {
      }
    }, [])

  const handleSave = (friendUrl: any,success:()=>void) => {
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        friendUrl
      }, 
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        // setMessage({
        //   open: true,
        //   type: 'success',
        //   title: '提示',
        //   text: msg
        // })
        success()
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }

  const handleClick = (event: any, index: number) => {
    // list[index].editing = !list[index].editing
  }

  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })

  const handleDelete = (id: string[]) => {
    const description = (<Box className={classes.alert}>
      <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
      <Box>
        {/* 您正在删除商品! 此操作不可恢复，确定要继续么？ */}
        <Box>
          <Box>{translate('common.friendUrl.removeProductTips')}</Box>
          <Box>{translate('common.friendUrl.removeAsk')}</Box>
        </Box>
      </Box>
    </Box>)
    setAlertOpen(true)
    setAlertProps({
      description,
      title: '',
      ok: () => {
        setAlertOpen(false)
        setLoading({
          open: true,
          text: translate('common.friendUrl.removing')//'正在删除商品...'
        })
        remove({
          id,
          success: (message: string) => {
            setLoading(initialLoading)
            loadData()
          },
          failure: (message: string) => {
            setLoading(initialLoading)
            setMessage({
              open: true,
              type: 'error',
              title: '提示',
              text: message
            })
          }
        })
      },
      cancel: () => {
        setAlertOpen(false)
      }
    })
  }

  return (<Box className={classes.root}>
    <div className={classes.pageContent}>
      <EnhancedPageToolbar
        {...{
          onAddClick: () => {
            list.unshift({
              id:createId(),
              editing:true,
              noStored:true
            })
            setList(Object.assign([],list))
          },
          onRefresh: () => {
            loadData()
          },
        }} />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead
            fields={fields}
            rowCount={list.length}
          />
                <TableBody>

            {list.map((row: any, index: number) =><EditRow
                    key={row.id}
                    {...{
                      index,
                      handleClick,
                      onCancel:()=>{
                              // 未存服务器端
                              if(row.noStored){
                                list.splice(index,1)
                              }else{
                                row.editing = false
                              }
                              setList(Object.assign([],list))
                      },
                      onSave:(row:any)=>{
                        handleSave(row,()=>{
                              row.noStored = false
                              row.editing = false
                              list.splice(index,1,row)
                              setList(Object.assign([],list))
                        })
                      },
                      onDelete:()=>{
                        handleDelete([row.id])
                      },
                      onEdit:()=>{
                          row.editing = true
                          setList(Object.assign([],list))
                      },
                      row
                    }}/ >)}
          </TableBody>
        </Table>
      <LoadingBox {...loading} />

      {list.length === 0 && !loading.open && (<Box className="error">
        <Icon name="Error" width={32} height={32} color="gray" />
        <span style={{ marginLeft: '10px' }} >{message && message.type === 'error' ? message.text : '查询结果为空！'}</span>
      </Box>)}
    </div>
    </div>
    <MessageDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message} />
    {/* 警告框 */}
    <AlertDialog open={alertOpen} {...alertProps} />
  </Box>
  );
}