import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024" version="1.1"
    xmlns="http://www.w3.org/2000/svg" p-id="12485" width={width} height={height}>
    <path fill={color} d="M512 725.333333c141.226667 0 256-114.773333 256-256V128h-106.666667v341.333333c0 82.346667-66.986667 149.333333-149.333333 149.333334S362.666667 551.68 362.666667 469.333333V128H256v341.333333c0 141.226667 114.773333 256 256 256z m-298.666667 85.333334v85.333333h597.333334v-85.333333H213.333333z"  p-id="12486"></path></svg>
    )
