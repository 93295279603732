import React, { useState, useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { CloseButton } from '../ui/button';

// 分类树
import DataTreeView from '../ui/tree/DataTree'
import { useModule } from '../../provides/common'
import { useCat } from '../../provides/content'
import { useLocale } from '../../config/Locale'
// 提示
import LoadingBox from '../ui/LoadingBox'
import ErrorBox from '../ui/ErrorBox'
import Progress from '../ui/Progress'

import { useDialogTitleStyles, useDialogStyles } from '../../assets/jss/components'

const DialogTitle = (props: any) => {
  const classes = useDialogTitleStyles()
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span>{children}</span>
      {onClose ? (
        <span className={classes.closeButton} onClick={(e) => { onClose(e) }}>
          <CloseButton open={true} close={onClose} />
        </span>
      ) : null}
    </MuiDialogTitle>
  );
};

const CatTree = (props: any) => {

  const { getActiveList } = useCat()
  const { moduleId, onClickAll, onItemClick, loading, loaded, notShowAll } = props

  const [list, setList] = useState<any>([]);
  useEffect(() => {
    // 获取列表
    loading()
    getActiveList({
      params: {
        moduleId
      },
      success: (list: any) => {
        setList(list)
        loaded()
      },
      failure: (message: string) => {
        loaded()
      }
    })
    return () => {
    };
  }, []);
  return < DataTreeView
    notShowAll={notShowAll}
    items={list || []}
    showDescri={true}
    onClickAll={onClickAll}
    onItemClick={onItemClick}
  />
}

export type CatTreeDialogProps = {
  moduleId?:string,
  notShowAll?:boolean,
  open: boolean,
  cancel: () => void,
  ok: (value: any, names: string) => void,
  onItemClick?: (item: any) => void,
  currentId?: string
}
export default (props: CatTreeDialogProps) => {
  const classes = useDialogStyles();
  const { ok, cancel, currentId, open, onItemClick, notShowAll,moduleId } = props;

  const [value, setValue] = useState({} as any)
  const [names, setNames] = useState()
  const { getList,get } = useModule()
  const { getNames } = useCat()

  // 查询分类名称，含父级分类
  const loadNames = (id: string) => {
    setShowProgress(true)
    getNames({
      id,
      success: (names: string) => {
        setShowProgress(false)
        setNames(names)
      },
      failure: (message: string) => {
        setShowProgress(false)
      }
    })
  }
  useEffect(() => {
    if (currentId) {
      loadNames(currentId)
    }
    return () => {

    };
  }, []);
  // 事件反馈
  const [showProgress, setShowProgress] = useState(false)
  const initialMessage = { open: false, text: '' }
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState({
    open: true,
    text: '正在加载数据...'
  })
  const { translate } = useLocale()
  const [list, setList] = useState<any>([]);
  useEffect(() => {
    // 获取列表
    setMessage({
      open: false,
      text: ''
    })
    setLoading({
      open: true,
      text: '正在加载数据...'
    })
    if(moduleId){
      get({
        id:moduleId,
        success:(module:any)=>{
          setList([module])
          setLoading(initialLoading)
        },
        failure:(message:string)=>{
          setLoading(initialLoading)
        }
      })
    }else{
      getList({
        success: (list: any) => {
          setList(list)
          // 关闭加载条
          setLoading(initialLoading)
        },
        failure: (message: string) => {
          // 关闭加载条
          setLoading(initialLoading)
          setMessage({
            open: true,
            text: message
          })
        }
      })
    }
    return () => {
    };
  }, []);

  return (
    <Dialog onClose={cancel} open={open}>
      <DialogTitle onClose={cancel}>
        {/* 标题 */}
        {translate('content.cats')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box>
          {list.map((module: any, index: number) =>
            <Box key={index} className={list.length>1?classes.paperContainer:''}>
              {list.length>1?<Box className={classes.paperTitle}>{module.name}</Box>:''}
              <CatTree
                notShowAll={notShowAll}
                moduleId={module.id}
                loading={() => {
                  setLoading({
                    open: true,
                    text: '正在加载数据...'
                  })
                }}
                loaded={() => {
                  setLoading(initialLoading)
                }}
                onClickAll={() => {
                  setValue({});
                  if (onItemClick) {
                    onItemClick({})
                  }
                  setNames('')
                  ok('', '')
                }}
                onItemClick={(item: any) => {
                  setValue(item);
                  loadNames(item.id)
                  if (onItemClick) {
                    onItemClick(item)
                  }
                }}
              />
            </Box>
          )
          }
        </Box>
        <LoadingBox {...loading} />
        <ErrorBox {...message} />

      </DialogContent>
      <DialogActions className={classes.actions}>
        <Box component="span" className={classes.result}>
          <Progress show={showProgress} className={classes.progress} />
          {names}
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            ok(value, names)
          }}
          color="primary"
          autoFocus>
          {/* 确定 */}
          {translate('alert.sure')}
        </Button>
        <Button onClick={cancel} color="primary">
          {/* 取消 */}
          {translate('alert.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}