import { AxiosRequestConfig } from 'axios';
import { API } from '../../../config'
import * as userTypes from '../../../types/user'


// Action Types
export type Action = {
    // 评论列表
    type:'COMMENT_FETCH_LIST',
    payload: { client: 'default', request: AxiosRequestConfig }
}
    | {
        // 评论列表
        type:'COMMENT_FETCH_LIST_BYUSER',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
    | {
        // 评论总数
        type:'COMMENT_FETCH_TOTAL',
        payload: { client: 'default', request: AxiosRequestConfig }
    }
        |{
            // 保存
            type:'COMMENT_SAVE',
            payload: { client: 'default', request: AxiosRequestConfig }
        } |{
            // 删除
            type:'COMMENT_REMOVE',
            payload: { client: 'default', request: AxiosRequestConfig }
        }
   ;


// 评论列表
export const FETCH_LIST = 'COMMENT_FETCH_LIST'
export const fetchList = (params: any): Action => ({
    type: FETCH_LIST,
    payload: {
        client: 'default',
        request: {
            url: `${API}user/comment/getList`,
            method: 'GET',
            params
        }
    }
})



// 评论列表
export const FETCH_LIST_BYUSER = 'COMMENT_FETCH_LIST_BYUSER'
export const fetchListByUser = (params: any): Action => ({
    type: FETCH_LIST_BYUSER,
    payload: {
        client: 'default',
        request: {
            url: `${API}user/comment/getListByUser`,
            method: 'GET',
            params
        }
    }
})

// 保存
export const SAVE = 'COMMENT_SAVE'
export const save = (comment: userTypes.Comment): Action => ({
    type: SAVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}user/comment/save`,
            method: 'POST',
            data:{
                comment
            }
        }
    }
})


// 删除
export const REMOVE = 'COMMENT_REMOVE'
export const remove = (id: string[]): Action => ({
    type: REMOVE,
    payload: {
        client: 'default',
        request: {
            url: `${API}user/comment/del`,
            method: 'POST',
            data:{
                id
            }
        }
    }
})



// 评论总数
export const FETCH_TOTAL = 'COMMENT_FETCH_TOTAL'
export const total = (params: any): Action => ({
    type: FETCH_TOTAL,
    payload: {
        client: 'default',
        request: {
            url: `${API}user/comment/total`,
            method: 'GET',
            params
        }
    }
})
