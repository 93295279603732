type Props = {
    data:any,
    initialData?:any,
    onChange:(data:any)=>void
}
// 条目方法
export const useItemMethods = (props:Props)=>{

    const {data,initialData,onChange} = props

  const onItemAdd = (item?:any) => {
    data.unshift({
      id: new Date().getTime() + "-" + Math.ceil(Math.random() * 9999),
      editing: true,
      ...(item||initialData)
    })
    onChange(data)
  }

  const onItemSave = (item: any) => {
    data.forEach((oldItem: any, index: number) => {
      if (item.id === oldItem.id) {
        data.splice(index, 1, item)
        onChange(data)
        return
      }
    })
  }
  const onItemRemove = (item: any) => {
    if (data.length === 1) {
      onChange([])
    } else {
      onChange(data.filter((oldItem: any) => item.id !== oldItem.id))
    }
  }
  // 往前排 
  const sortUp = (index: number) => {
    if (index > 0) {
      const temp = data[index]
      data[index] = data[index - 1];
      data[index - 1] = temp;
      onChange(data)
    }
  }
  // 往后排
  const sortDown = (index: number) => {
    if (index < data.length - 1 ) {
      const temp = data[index]
      data[index] = data[index + 1];
      data[index + 1] = temp;
      onChange(data)
    }
  }
 return {
    onItemAdd,
    onItemSave,
    onItemRemove,
    sortUp,
    sortDown
 }
}