import React, { useState, useEffect } from 'react'

import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import Icon from '../../../components/Icon'
import AddIcon from '@material-ui/icons/Add';

import { withRouter } from "react-router-dom";

import { SelectOption } from "react-select-material-ui";
import { SingleSelect } from "../../../components/ui/select"

// 逻辑
import { useLocale } from '../../../config/Locale'
import { useAttr,useCat } from '../../../provides/shopping'
import { useConfig } from '../../../provides/app'
// 弹框
import LoadingBox from '../../../components/ui/LoadingBox'
import { SubmitedDialog } from '../../../components/ui/dialog'
 
// 表单组件 
import RadioInput from '../../../components/ui/form/RadioInput'
import ImageInput from '../../../components/ui/form/ImageInput'
import FormField from '../../../components/ui/form/FormField'
import TextInput from '../../../components/ui/form/TextInput'
import SubmitBar from '../../../components/ui/form/SubmitBar';
import { createId } from '../../../lib/util'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      fontSize: '1rem'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 5
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 0,
      background: `#fff`,
      marginTop: 10,
      height: 38,
      borderBottom: '1px solid #dedede'
    },
    contentContainer: {
      flex: 1,
    },
    margin: {
      margin: theme.spacing(1, 0)
    },
    thumbImage: {
      position: 'relative',
      width: 100,
      height: 100,
    },
    thumbButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'block',
      textAlign: 'center',
      verticalAlign: 'middile'
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    button: {
        fontSize: '0.8em',
        marginRight: theme.spacing(1)
    },
    aBoxContainer: {
        margin: '15px 0',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        userSelect: 'none'
    },
    aBox: {
        position: 'relative',
        border: '1px solid #eee',
        color: '#666',
        margin: '5px 10px 5px 0',
        padding: '5px 25px 5px 10px',
        cursor: 'pointer',
        borderRadius: 3,
        '&:hover': {
            border: '1px solid #666',
            color: '#000',
        }
    },
    aBoxValue: {
        fontStyle: 'italic',
        fontWeight: 'bold'
    },
    aBoxClose: {
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        borderRadius: '50%',
        background: '#fff'
    },
    active: {
        color: '#333',
        background: '#f3f3f3',
        border: '1px inset #ddd',
    },
    paper: {
        margin: theme.spacing(1, 0)
    },
    row: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        clear: 'both',
        margin: '5px 0'
    },
    error: {
        display: 'inline-flex',
        color: '#ff6600',
        fontSize: '0.8em',
        alignItems: 'center'
    },
    icon: {
        margin: '0 5px 0 0',
        width: 16,
        height: 16
    },
  }))


const EditPage = (props: any) => {
  const { history } = props

  const { translate } = useLocale()
  const { get, save } = useAttr()
  const catProvide = useCat()
  const { closePage } = useConfig()

  const classes = useStyles()
  const theme = useTheme()

  const [imageLoaded, setImageLoaded] = useState(false)
  const resetState = () => {
    setImageLoaded(false)// 图片
  }
  // 组件列表转换下拉列表
  const [selectOptions, setSelectOptions] = React.useState<SelectOption[]>([])
 // 初始化组件列表
 const loadCats = () => catProvide.getList({
  success: (list: any) => {
      const cats: SelectOption[] = []
      setSelectOptions(cats.concat(list.map((cat: any) => Object.assign({}, {
          label: cat.name + " " +(cat.alias?'('+cat.alias+')':''),
          value: cat.id
      }))))
  },
  failure: (message: string) => setSelectOptions([])
})
  // 事件反馈
  const initialMessage = {
    open: false,
    type: '',
    title: '',
    text: ''
  }

  const [addError, setAddError] = useState('')
  const [message, setMessage] = useState(initialMessage)
  const initialLoading = { open: false, text: '' }
  const [loading, setLoading] = useState(initialLoading)
  const [value, setValue] = React.useState(0);

  function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
    setValue(newValue);
  }
  // 待添加的属性值 
  const [specVal,setSpecVal] = useState<string>('')
  // 设置表单元素
  const [form, setForm] = useState<any>({
    valList:[]
  })
  const setFormItem = (key: string, value: any) => {
    const newForm = form
    newForm[key] = value;
    setForm(Object.assign({}, newForm))
  }
  const loadData = () => {
    // 获取列表
    setLoading({
      open: true,
      text: '正在加载属性...'
    })
    get({
      params:{
        id: form.id
      } ,
      success: (product: any) => {
        // 关闭加载条
        setLoading(initialLoading)
        const newForm = product
        setForm(Object.assign({}, newForm))
        // 重置表单元素加载状态
        resetState()
      },
      failure: (message: string) => {
        // 关闭加载条
        setLoading(initialLoading)
        reloadParams()
        resetState()
      }
    })
  }
  const reloadParams = () => {
    const params = new URLSearchParams(history.location.search);
    setForm({
      id: params.get("id"),
      catId: params.get("catId")
    })
  }
  const resetForm = () => {
    Object.keys(form).forEach((key: string) => {
      if (key !== 'id') {
        delete form[key]
      }
    })
  }
  useEffect(()=> loadCats(),[])
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get("id")
    if (id && id !== form.id) {
      resetForm()
      setFormItem('id', id)
      setFormItem('catId', params.get("catId"))
      loadData()
    }
    return () => {
      // setForm({id:createId()})
    }
  });

  const onSubmit = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    else {
      event.cancelBubble = true;
    }

    if (event.preventDefault) {
      event.preventDefault();
    }
    else {
      event.returnValue = false;
    }
    // 打开进度条
    setLoading({
      open: true,
      text: translate('loadingText.submit')
    })
    save({
      data: {
        attr: form
      },
      success: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'success',
          title: '提示',
          text: msg
        })
      },
      failure: function (msg: string) {
        setLoading({
          open: false,
          text: ''
        })
        setMessage({
          open: true,
          type: 'error',
          title: '提示',
          text: msg
        })
      }
    })
  }
  // 删除当前路由，返回当前路由及打开的路由开列表
  const handleClose = () => {
    closePage(history.location.pathname, (props: any) => {
      const { route } = props
      if (route.path) {
        history.push(route.path)
      }
    })
  }

    /**
     * 添加校验
     */
    const onAddCheck = () => {
      if (specVal === "") {
          setAddError("请填写属性值");
          return false;
      }
      const result = form.valList.filter((val: any) => specVal === val.value);
      if (result.length > 0) {
          setAddError('属性值已存在')
          return false;
      }
      setAddError("");
      return true;
  }
    // 添加属性
    const onAdd = (e: any) => {
      e.preventDefault()
      if (!onAddCheck()) {
          return
      }
      form.valList = form.valList || []
      form.valList.push({
        value:specVal
      })
      setFormItem('valList',form.valList)
  }
    // 删除属性
    const onRemove = (index: number) => {
      form.valList.splice(index, 1)
      setFormItem('valList', form.valList)
  }

  const renderValues = (<>
    {form.valList && form.valList.length > 0 &&
        <Box className={classes.aBoxContainer}>
            {
                // 待添加的键值对属性列表 
                form.valList.map((item: any, index: number) => (
                    <a
                        className={classes.aBox}
                        key={index}
                        onMouseDown={() => {
                            onRemove(index)
                        }}>
                        <span className={classes.aBoxValue}>{item.value}</span>
                        <span className={classes.aBoxClose}>
                            <Icon name="Close" color="#999999" />
                        </span>
                    </a>))
            }
        </Box>
    }</>)


  return (<Box  style={{paddingTop:16,paddingBottom:16}}>
    <form onSubmit={onSubmit}> 
      <Box className={classes.contentContainer}>
            {/* 分类 */}

            <FormField
            labelWidth={120}
            label="选择分类：">
              <SingleSelect
              value={form.catId || ""}
              placeholder="选择分类..."
              options={selectOptions}
              onChange={(value:string)=>setFormItem('catId', value)} />
            </FormField>
            {/* 名称 */}
            <TextInput
              labelWidth={120}
              value={`${form.name || ''}`}
              label="属性名称："
              required
              placeholder="请输入属性名称"
              onChange={(event: any) => {
                setFormItem('name', event.target.value)
              }} />

            {/* 排序 */}
            <TextInput
              labelWidth={120}
              value={`${form.sorter || 0}`}
              inputWidth={60}
              label="排序："
              type="number"
              onChange={(event: any) => {
                setFormItem('sorter', event.target.value)
              }} />
            {/* 状态 */}
            <RadioInput
              labelWidth={120}
              value={form.status}
              options={[
                { label: "未启用", value: "0" },
                { label: "启用", value: "1" }
              ]}
              label="状态："
              onChange={(e: any, value: any) => {
                setFormItem('status', value)
              }} />
              <FormField
                labelWidth={120}
                label="属性值列表：">

            <Box className={classes.row}>
              <TextInput
                  labelWidth={0}
                  value={specVal}
                  label="属性值："
                  onChange={(event: any) => {
                    setSpecVal(event.target.value)
                  }} />
              <Button
                  variant="outlined"
                  onMouseDown={onAdd}
                  size="small"
                  className={classes.button}>
                  <AddIcon className={classes.icon} />
                  添加
                  </Button>
                  {
                      addError &&<Box className={classes.error}>
                          <Icon name="Wrong" className={classes.icon} color="#ff0000" />
                          {addError}
                      </Box>
                  }
              </Box>

                {renderValues}
              </FormField>
          </Box>
      <SubmitBar
        {...{
          labelWidth: 120,
          onSubmit,
          padding: "0 0 0 120px",
          onCancel: () => {
            handleClose()
          },
          onReset: () => {
            loadData()
          }
        }} />
    </form>

    <LoadingBox {...loading} />
    <SubmitedDialog
      onClose={() => {
        setMessage(initialMessage)
      }}
      message={message}
      goList={() => {
        setMessage(initialMessage)
        closePage(history.location.pathname, (props: any) => {
          history.replace(`/shopping/attr?catId=${form.catId}`)
        })
      }}
      goAdd={() => {
        setMessage(initialMessage)
        history.replace(`?id=${createId()}&catId=${form.catId}`)
      }}
      goModify={() => {
        setMessage(initialMessage)
        loadData()
      }}
    />
  </Box>
  )
}
export default withRouter(EditPage)