import React,{useState} from 'react';
import Box from '@material-ui/core/Box';

import {BodyTableCell, TableRow, TableRowToolbar } from '../../../components/ui/table'
import { useLocale } from '../../../config/Locale' 

import TextInput from '../../../components/ui/form/TextInput'

import { useListPageStyles } from '../../../assets/jss/pages'

type Props = { 
      index:number,
       handleClick:(event: any, index: number)=>void,
       onCancel:()=>void,
       onSave:(row:any)=>void,
       onDelete:()=>void,
       onEdit:()=>void,
       row:any
}

export default (props:Props)=> {
     const classes = useListPageStyles()
     const {
       index,
       handleClick,
       onCancel,
       onSave,
       onDelete,
       onEdit,
       row
    } = props
    const [editRow,setEditRow] = useState<any>(row)
    const setEditRowItem = (key:string,value:any)=>{
      editRow[key] = value
      setEditRow(Object.assign({},editRow))
    }
     const { translate } = useLocale()
     
    return <TableRow  onClick={event => handleClick(event, index)}>
                    <BodyTableCell align="left">
                      {row.editing?
                                <TextInput
                                    value={editRow.value}
                                    fullWidth
                                    onChange={(event: any) => {
                                        setEditRowItem("value",event.target.value) 
                                    }} />:
                      <span style={{paddingLeft:16,paddingRight:16}}>{row.value}</span>
                      }
                    </BodyTableCell>
                    <BodyTableCell align="left"> 
                     {row.editing?
                                <TextInput
                                    value={editRow.sorter}
                                    type="number"
                                    width={0}
                                    onChange={(event: any) => {
                                         setEditRowItem("sorter",event.target.value) 
                                    }} />:
                      row.sorter
                      }
                    </BodyTableCell>
                    <BodyTableCell align="right" className={classes.rowToolbar}>
                    {
                      row.editing ? 
                      <TableRowToolbar
                        {...{
                          saveLabel: translate('trade.refundReason.save'),
                          onCancel,
                          onSave:()=>{
                            onSave(editRow)
                          }
                        }} />:

                      <TableRowToolbar
                        {...{
                          editLabel: translate('trade.refundReason.edit'),
                          deleteLabel: translate('trade.refundReason.remove'),
                          onEdit,
                          onDelete
                        }} />
                    }
                    </BodyTableCell>
                  </TableRow>
}