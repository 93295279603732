
import React from 'react';
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      margin: '0 5px 5px 0',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 32,
      minHeight: 32,
      borderRadius: 3,
      border: '1px solid #f3f3f3',
      cursor: 'pointer',
      '&:hover': {
        border: '1px solid #dedede',
        backgroundColor: '#f0f0f0'
      }
    },
    active: {
      backgroundColor: '#eee',
      border: '1px inset #dedede'
    },
    disabled: {
      color:'#aaa',
      border: '1px solid transparent',
      '&:hover': {
        border: '1px solid transparent',
        backgroundColor: 'transparent'
      }
    }
  }),
);

const Button = (props: any) => {
  const classes = useStyles()
  const { children, active,disabled, title, ...other } = props

  const renderButton = <Box
    component="span"
    className={clsx(classes.button, {
      [classes.active]: active,
      [classes.disabled]: disabled
    })}
    {...other}>
    {children}
  </Box>

  if (title) {
    return (
      <Tooltip
        aria-label="add"
        title={title}>
        {renderButton}
      </Tooltip>
    )
  }
  return renderButton
}
export default Button