import React from 'react'
export default ({
    width = 22,
    height = 22,
    color = "#484848",
    className = "icon"
}) => (<svg className={className} viewBox="0 0 1024 1024"
    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8974"
    width={width} height={height}><path fill={color} d="M128 213.333333h85.333333v-85.333333h-85.333333v85.333333zM85.333333 426.666667V256h170.666667H85.333333v-85.333333c0-56.8832 28.450133-85.333333 85.333334-85.333334h85.333333v341.333334H85.333333h170.666667V256h512V85.333333h85.333333c56.8832 0 85.333333 28.450133 85.333334 85.333334v85.333333H768h170.666667v170.666667H768V256v170.666667h170.666667v170.666666H768v170.666667h170.666667-170.666667V597.333333h170.666667v256c0 56.8832-28.450133 85.333333-85.333334 85.333334h-85.333333V768H256v170.666667h-85.333333c-56.8832 0-85.333333-28.450133-85.333334-85.333334v-85.333333h170.666667V597.333333H85.333333h170.666667v170.666667H85.333333V426.666667z m811.52-213.333334v-85.333333h-85.333333v85.333333h85.333333z m-85.333333 170.666667h85.333333v-85.333333h-85.333333v85.333333z m85.333333 256.853333h-85.333333v85.333334h85.333333v-85.333334z m0-85.333333v-85.333333h-85.333333v85.333333h85.333333zM768 426.666667v170.666666-170.666666z m-170.666667 85.333333l-127.146666-85.333333v170.666666L597.333333 512zM128 384h85.333333v-85.333333h-85.333333v85.333333z m767.146667 424.106667h-85.333334v85.333333h85.333334v-85.333333z m-681.813334-167.253334h-85.333333v85.333334h85.333333v-85.333334z m0-170.666666h-85.333333v85.333333h85.333333v-85.333333zM256 597.333333V426.666667v170.666666z m-44.373333 210.773334h-85.333334v85.333333h85.333334v-85.333333z" p-id="8975"></path>
    <path fill={color} d="M853.333333 955.733333H170.666667c-66.030933 0-102.4-36.369067-102.4-102.4V170.666667c0-66.030933 36.369067-102.4 102.4-102.4h682.666666c66.030933 0 102.4 36.369067 102.4 102.4v682.666666c0 66.030933-36.369067 102.4-102.4 102.4z m-68.266666-34.133333h68.266666c47.223467 0 68.266667-21.0432 68.266667-68.266667v-68.266666h-136.533333v136.533333z m-512 0h477.866666v-136.533333H273.066667v136.533333zM102.4 785.066667v68.266666c0 47.223467 21.0432 68.266667 68.266667 68.266667h68.266666v-136.533333H102.4z m682.666667-34.133334h136.533333v-136.533333h-136.533333v136.533333z m-512 0h477.866666V273.066667H273.066667v477.866666zM102.4 750.933333h136.533333v-136.533333H102.4v136.533333z m682.666667-170.666666h136.533333v-136.533334h-136.533333v136.533334zM102.4 580.266667h136.533333v-136.533334H102.4v136.533334z m682.666667-170.666667h136.533333v-136.533333h-136.533333v136.533333zM102.4 409.6h136.533333v-136.533333H102.4v136.533333z m682.666667-170.666667h136.533333v-68.266666c0-47.223467-21.0432-68.266667-68.266667-68.266667h-68.266666v136.533333z m-512 0h477.866666V102.4H273.066667v136.533333zM102.4 238.933333h136.533333V102.4h-68.266666c-47.223467 0-68.266667 21.0432-68.266667 68.266667v68.266666z" p-id="8976"></path></svg>
    )
