import { appType } from '../config'
import { DataItem, Menu, Nav, Route } from '../types/app'
import { fetchContentCats } from './Fetches'
/**
 * 原始数据生成项目使用的导航navs/菜单menus/路由routes
 * 原始数据格式
 * {
 * name : string,//名称
 * title : string,//标题
 * icon : string,//本地图标
 * iconUrl : string,//远程图标
 * edit : boolean,//启用编辑页面
 * notNav : boolean,//不使用在导航栏 
 * children : [],//子集
 * className:string,// 比较特殊的数据节点，有特殊的默认样式
 * activeClassName:string,// 比较特殊的数据节点，有特殊的选中样式
 * exact:boolean,
 * from:string,// 重定向页面路由使用
 * to:string,// 重定向页面路由使用
 * status:number,// 重定向状态
 * render:function,// 页面输出内容，用于生成路由
 * component:function,//关联组件，用于生成路由
 * }
 * 导航元素
 * {
 * name : string,//导航标识名称
 * title : string,//菜单标题
 * icon : string,//导航图标
 * className:string,// 比较特殊的数据节点，有特殊的默认样式
 * activeClassName:string,// 比较特殊的数据节点，有特殊的选中样式
 * path : string,//导航跳转地址
 * children : [],//子导航
 * }
 * 菜单元素
 * {
 * name : string,//菜单标识名称
 * title : string,//菜单标题
 * icon : string,//菜单图标
 * path : string,//菜单跳转地址
 * className:string,// 比较特殊的数据节点，有特殊的默认样式
 * activeClassName:string,// 比较特殊的数据节点，有特殊的选中样式
 * children : [],//子菜单
 * }
 * 路由元素
 * {
 * exact : boolean,
 * path : string,//路径,规则>>上级数据拼接本节点数据的name
 * component : Component,//组件,规则>>上级数据拼接本节点数据的name
 * }
 */
// String.prototype.startsWith = function (str:string) {
//   var reg = new RegExp('^' + str)
//   return reg.test(this)
// }

// // eslint-disable-next-line no-extend-native
// String.prototype.endsWith = function (str:string) {
//   var reg = new RegExp(str + '$')
//   return reg.test(this)
// }

/**
 * 生成条目
 * @param {元素转换为菜单和路由} item
 * @param {上级路由路径} parentPath
 */
const itemBuild = async (item: DataItem, paths: string[]) => {
  // 递归的上级path列表
  paths = Object.assign([], paths || [])
  if (paths.length === 0) {
    // 插入顶层路径
    paths.push('/')
  }
  // 当前的上级path
  let parentPath = paths[0]

  let {
    title,
    name,
    icon,
    iconUrl,
    path,
    className,
    activeClassName,
    list,
    edit,
    editTitle,
    listTitle,
    from,
    to,
    // exact,
    status,
    render,
    component
  } = item
  // 设置path
  if (!path) {
    path = `${parentPath}${!parentPath.endsWith('/') ? '/' : ''}${name}`
  }
  // 设置引入文件，生成路由
  if (!component) {
    try {
      let file = path.startsWith('/') ? path.substring(1, path.length) : path
      if (item.file) {
        file = item.file
      }
      component = require(`../pages/${file}`).default
    } catch (e) {
    }
  }
  // 创建导航条目
  let nav: Nav = {
    name,
    title,
    path,
    paths,
    iconUrl,
    icon,
    className,
    activeClassName
  }
  // 创建菜单条目
  let menu: Menu = {
    name,
    title,
    path,
    paths,
    iconUrl,
    icon,
    className,
    activeClassName
  }
  let routes = []
  // 本节点路由
  let route: Route = {
    exact: true,
    path,
    paths,
    from,
    to,
    status,
    render,
    component,
    // 路由额外信息
    extra: {
      name,
      title,
      iconUrl,
      icon,
      className,
      activeClassName
    }
  }
  routes.push(route)
  // 添加编辑路由
  if (edit) {
    let editComponent
    try {
      editComponent = require(`../pages/${path.startsWith('/') ? path.substring(1, path.length) : path}/edit`).default
    } catch (e) {
    }
    if (editComponent) {
      let ps = paths
      ps.unshift(path)
      const editRoute: Route = {
        path: path + '/edit',
        paths: ps,
        exact: true,
        component: editComponent,
        // 路由额外信息
        extra: {
          name,
          title: editTitle || `编辑${title.replace('管理', '')}`,
          iconUrl,
          icon,
          className,
          activeClassName
        }
      }
      routes.push(editRoute)
    }
  }
  // 添加列表路由
  if (list === true) {
    let listComponent
    try {
      listComponent = require(`../pages/${path.startsWith('/') ? path.substring(1, path.length) : path}/list`).default
    } catch (e) {
    }
    if (listComponent) {
      let ps = paths
      ps.unshift(path)
      const listRoute: Route = {
        path: path + '/list',
        paths: ps,
        exact: true,
        component: listComponent,
        // 路由额外信息
        extra: {
          name,
          title: listTitle || `${title.replace('管理', '')}列表`,
          iconUrl,
          icon,
          className,
          activeClassName
        }
      }
      routes.push(listRoute)
    }
  }
  if (parentPath !== path)
    paths.unshift(path)
  return { paths, nav, menu, routes }
}
/**
 * 解析数据条目
 * @param {数据} item
 * @param {上级数据} parentMenu
 */
const parseItem = async (item: DataItem, parentPaths: string[]) => {
  // 若有子集，则转换子集
  let { children } = item
  // 生成配置条目
  let { paths, menu, nav, routes } = await itemBuild(item, parentPaths)
  if (item.name === 'article') {
    //   这里是文章的数据项，
    //   需要从服务器获取数据
    children = await fetchContentCats()
  }
  if (children && children.length > 0) {
    // 添加子集，再返回
    let { menus, navs, subRoutes } = await parseChidren(children, Object.assign([], paths))
    if (menus.length > 0) {
      menu.children = menus
    }
    if (navs.length > 0) {
      nav.children = navs
    }
    if (subRoutes.length > 0) {
      routes = routes.concat(subRoutes)
    }
    return { menu, nav, routes }
  }
  return { menu, nav, routes }
}
/**
 * 遍历数据，递归生成菜单树和路由
 * @param {数据条目} items
 * @param {上级菜单} parentMenu
 */
const parseChidren = async (items: DataItem[], paths: string[]) => {
  // 父级菜单子集
  let menus = []
  // 父级导航子集
  let navs = []
  // 子路由 
  let subRoutes: Route[] = []
  for (let i = 0; i < items.length; i++) {
    let item = items[i]
    const { menu, nav, routes } = await parseItem(item, paths)
    // 插入子菜单
    if (menu)
      menus.push(menu)
    // 插入子导航
    if (nav)
      navs.push(nav)
    if (routes) {
      subRoutes = subRoutes.concat(routes)
    }
  }
  return { menus, navs, subRoutes }
}

export const build = async ({ locale } = { locale: "zh" }) => {

  // 不同语种不同版本的基础数据，用于生成菜单和路由
  const type = appType
  const { data } = await require(`./data/${locale}/${type}`)
  /**
   * 根据不同版本获取不同的基础数据
   */
  let navs = []
  let menus = []
  let routes: Route[] = []
  for (let i = 0; i < data.length; i++) {
    let item: DataItem = data[i]
    // 解析数据
    const result = await parseItem(item, [])
    menus.push(result.menu)
    navs.push(result.nav)
    routes = routes.concat(result.routes)
  }
  return { menus, navs, routes }
}