import { EditorState, Modifier } from 'draft-js';
import { setBlockData,isFunction } from '../utils';
/**
 * 更新entity
 * @param props 更新的参数
 */
export const replaceEntity = (props: any) => {
  const { replaceProps, mediaProps, editorState } = props
  const { block } = mediaProps
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection()
  if (!block) {
    return
  }
  const entityKey = block.getCharacterList().get(0).getEntity()
  const entity = contentState.getEntity(entityKey);
  const data = entity.getData();
  const src = replaceProps.src || data.src;
  const attributes = replaceProps.attributes || data.attributes;
  let newState = contentState.mergeEntityData(
    entityKey,
    {
      src,
      attributes
    }
  )
  newState = Modifier.applyEntity(
    newState,
    selection,
    entityKey
  );
  let nextState = EditorState.push(editorState, newState, 'apply-entity')
  if (attributes && (attributes.align === 'center' || attributes.align === 'clearfix')) {
    nextState = setBlockData(nextState, { 'textAlign': attributes.align })
  }
  return nextState
}
/**
 * remove atomic entity
 * @param props 
 */
export function removeEntity(props: any) {
  let { block, editorState } = props
  if(!block || !editorState)
    return null
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const atomicBlockTarget = selection.merge({
    focusKey: block.getKey(),
    focusOffset: block.getLength(),
  });

  const withoutAtomicBlock = Modifier.removeRange(
    contentState,
    atomicBlockTarget,
    'forward',
  );
  if (withoutAtomicBlock !== contentState) {
    return EditorState.push(editorState, withoutAtomicBlock, 'remove-range');
  }
  return null
}


/**
 * remove atomic entity
 * @param props 
 */
export function removeEntityOnBackspace(editorState: EditorState) {
  const selection = editorState.getSelection();
  if (
    !selection.isCollapsed() ||
    selection.getAnchorOffset() ||
    selection.getFocusOffset()
  ) {
    return null;
  }

  // First, try to remove a preceding atomic block.
  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const blockBefore = content.getBlockBefore(startKey);

  if (blockBefore && blockBefore.getType() === 'atomic') {
    if(!isFunction(blockBefore.getKey))
      return null
    const blockMap = content.getBlockMap().delete(blockBefore.getKey());

    const withoutAtomicBlock: any = content.merge({
      blockMap,
      selectionAfter: selection,
    });
    if (withoutAtomicBlock !== content) {
      return EditorState.push(
        editorState,
        withoutAtomicBlock,
        'remove-range',
      );
    }
  }
  return null;

}


/**
 * remove atomic entity
 * @param props 
 */
export function removeEntityOnDelete(editorState: EditorState) {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    return null;
  }

  const content = editorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const block = content.getBlockForKey(startKey);
  const length = block.getLength();

  // The cursor is somewhere within the text. Behave normally.
  if (selection.getStartOffset() < length) {
    return null;
  }

  const blockAfter = content.getBlockAfter(startKey);

  if (!blockAfter) {
    return null;
  }
  if(blockAfter.getType() !== 'atomic'){
    return null;
  }
  if(!isFunction(blockAfter.getKey))
    return null

  const atomicBlockTarget: any = selection.merge({
    focusKey: blockAfter.getKey(),
    focusOffset: blockAfter.getLength(),
  });

  const withoutAtomicBlock = Modifier.removeRange(
    content,
    atomicBlockTarget,
    'forward',
  );

  if (withoutAtomicBlock !== content) {
    return EditorState.push(editorState, withoutAtomicBlock, 'remove-range');
  }


  return null;

}