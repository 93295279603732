import React, { useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import IconAdd from "@material-ui/icons/Add"
import IconRemove from "@material-ui/icons/Delete"
import IconEdit from "@material-ui/icons/Edit"
import IconUp from "@material-ui/icons/ArrowUpward"
import IconDown from "@material-ui/icons/ArrowDownward"

import Icon from '../../../components/Icon'

import EditContainer from "./EditContainer"
import EditGrid from "./EditGrid"
import EditComponent from "./EditComponent"

import { PageSetting } from "../../../types/ui"
import { useUIPageStyles } from "../../../assets/jss/pages"
import { AlertDialog } from '../../../components/ui/dialog'

type EditDialogProps = {
  setting: PageSetting,
  cancel: () => void,
  onChange: (setting: PageSetting) => void,
}

type SubItemsProps = {
  items: PageSetting[],
  paddingLeft: number,
  onChange: (items: PageSetting[]) => void
}

type ItemProps = {
  item: PageSetting,
  showUp?: boolean,
  showDown?: boolean,
  sortUp?: () => void,
  sortDown?: () => void,
  remove?: () => void,
  onItemChange: (item: PageSetting) => void,
  paddingLeft: number
}

type Props = {
  setting: PageSetting,
  onChange: (setting: PageSetting) => void
}

const EditDialog = (props: EditDialogProps) => {
  switch (props.setting.type) {
    case "container":
      return <EditContainer {...props} />
    case "grid": {
      return <EditGrid  {...props} />
    }
    case "component": {
      return <EditComponent  {...props} />
    }
    default:
      break;
  }
  return <></>
}

const Item = (props: ItemProps) => {
  const classes = useUIPageStyles()
  const [editProps, setEditProps] = useState<EditDialogProps | null>(null)
  const [collapse, setCollapse] = useState(true);
  // 警示框
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertProps, setAlertProps] = useState({
    description: <></>,
    title: '',
    ok: () => { },
    cancel: () => { }
  })
  
  const { item, showUp, showDown, sortUp, sortDown, remove, paddingLeft, onItemChange } = props
  if(item === null){
    return <></>
  }
  const { type, name, id } = item


  function handleCollapse() {
    setCollapse(prev => !prev);
  }

  const handleDelete = () => {
    if (!remove) {
      return
    }
    if ((item.children && item.children.length > 0) || type === "component") {
      const description = (<Box className={classes.alert}>
        <Box className={classes.alertIcon}><Icon name="Alert" width={32} height={32} /></Box>
        <Box>
          <Box>
            <Box>您正在移除节点{type !== "component" && "，该节点有子集"}！</Box>
            <Box>保存后将不可恢复，确定要继续么？</Box>
          </Box>
        </Box>
      </Box>)
      setAlertOpen(true)
      setAlertProps({
        description,
        title: '',
        ok: () => {
          remove()
          setAlertOpen(false)
        },
        cancel: () => {
          setAlertOpen(false)
        }
      })
    } else {
      remove()
    }
  }

  const editChild = (setting: PageSetting, onChange?: (setting: PageSetting) => void) => {
    setEditProps({
      setting,
      cancel: () => {
        setEditProps(null)
      },
      onChange: (setting: PageSetting) => {
        if (onChange) {
          onChange(setting)
        } else {
          onItemChange(setting)
        }
        setEditProps(null)
      }
    })
  }
  // 添加子元素
  const addChild = (type: "root" | "container" | "grid" | "component") => {
    const children = item.children || []
    const id = item.id + "-" + children.length
    editChild({
      id,
      type
    }, (setting: PageSetting) => {
      children.push(setting)
      item.children = children
      onItemChange(item)
      setCollapse(true)
    })
  }

  const icons = <IconButton className={classes.square}
    component="span"
    onClick={handleCollapse}
  >
    {(collapse && item.children && item.children.length > 0) ||// 子集大于0，且已展开
      (!item.children) || // 没有子集
      (item.children && item.children.length === 0) ?// 子集为空
      <Icon name="MinusSquare" /> :
      <Icon name="PlusSquare" />}
  </IconButton>

  const titleText = <Box className={classes.listItemTitleText}>
    <span className={classes.id}>{id}</span>
    <span>
      <span style={{ padding: "0 8px" }}>{item.component ? <>
        <span>{item.component.name}</span>
        <span className={classes.label}>({item.component.alias})</span>
      </> :
        name || type
      }</span>
      {item.dataSource && item.dataSource.type && <>
        <span className={classes.label}>数据源类型：</span>
        <span>{item.dataSource.type}</span>
        <span style={{marginLeft:8}}>{item.dataSource.desc}</span>
      </>} 
    </span> 
  </Box>

  const buttons = <Box>

    {type !== "root" && <>
      <IconButton onClick={() => editChild(item)}>
        <Tooltip title="修改">
          <IconEdit className={classes.smallIcon} />
        </Tooltip>
      </IconButton>
      <IconButton onClick={handleDelete} title="移除">
        <Tooltip title="移除">
          <IconRemove className={classes.smallIcon} />
        </Tooltip>
      </IconButton>
      {showUp &&
        <IconButton onClick={sortUp} title="往前排">
          <Tooltip title="往前排">
            <IconUp className={classes.smallIcon} />
          </Tooltip>
        </IconButton>}
      {showDown &&
        <IconButton onClick={sortDown} >
          <Tooltip title="往后排">
            <IconDown className={classes.smallIcon} />
          </Tooltip>
        </IconButton>}
    </>}
    {type === "root" &&
      <Button color="primary" onClick={() => addChild("container")}>
        <IconAdd className={classes.icon} />添加容器
  </Button>
    }
    {type === "container" && <>
      <Button color="primary" onClick={() => addChild("grid")}>
        <IconAdd className={classes.icon} />添加表格
       </Button>
    </>
    }

    {(type === "root" || type === "container" || type === "grid") &&
      <Button color="primary" onClick={() => addChild("component")}>
        <IconAdd className={classes.icon} />添加组件
    </Button>}


  </Box>
  return <Box
    className={classes.listItem}
    style={{ paddingLeft, width: `calc(100%-${paddingLeft}px)` }}>
    <Box className={classes.listItemTitle}>
      {icons}
      {titleText}
      <Box className={classes.grow} />
      {buttons}
    </Box>
    {item.children && item.children.length > 0 &&
      <Collapse in={collapse} className={classes.collapse}>
        <SubItems
          items={item.children}
          paddingLeft={paddingLeft + 8}
          onChange={(items: PageSetting[]) => {
            item.children = items
            onItemChange(item)
          }}
        />
      </Collapse>
    }
    {editProps && <EditDialog {...editProps} />}

    {alertOpen && <AlertDialog open={true} {...alertProps} />}

  </Box>
}

const SubItems = (props: SubItemsProps) => {
  const classes = useUIPageStyles()
  const {  items, paddingLeft, onChange } = props

  // 往前排 
  const sortUp = (index: number) => {
    if (index > 0) {
      const temp = items[index]
      items[index] = items[index - 1];
      items[index - 1] = temp;
      onChange(items)
    }
  }
  // 往后排
  const sortDown = (index: number) => {
    if (index < items.length - 1) {
      const temp = items[index]
      items[index] = items[index + 1];
      items[index + 1] = temp;
      onChange(items)
    }
  }

  return <Box className={classes.list}>
    {items.map((item: any, index: number) => <Item key={index}
      item={item}
      paddingLeft={paddingLeft}
      remove={() => {
        items.splice(index, 1)
        onChange(items)
      }}
      onItemChange={(item: PageSetting) => {
        items[index] = item
        onChange(items)
      }}
      showUp={index > 0}
      showDown={index < items.length - 1}
      sortUp={() => {
        sortUp(index)
      }}
      sortDown={() => {
        sortDown(index)
      }}
    />)}
  </Box>
}

export default (props: Props) => {

  return <Item item={props.setting || {
    id: "0",
    type: "root"
  }}
    paddingLeft={0}
    onItemChange={(item: PageSetting) => {
      const setting = Object.assign({}, item)
      props.onChange(setting)
    }} />
}   