import React from 'react';
import classNames from "classnames"
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import {  makeStyles, createStyles,  Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      padding:4,
      fontSize:'12px'
    },
  }),
);

// const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

export default function SplitButton(props: any) {
  const classes = useStyles()
  const { options, size,value, onSelect, label, onClick, className } = props
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null as any);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [minWidth, setMinWidth] = React.useState(0)

  const init = () => {
    options.map((option: any, index: number) => {
      if (!option.id && !option.name) {
        if (option === value) {
          setSelectedIndex(index)
        }
      } else if (option.id === value) {
        setSelectedIndex(index)
      } else if (!option.id && option.name === value) {
        setSelectedIndex(index)
      }
    })
  }

  const paperStyle: React.CSSProperties = {
    minWidth,
  }
  React.useEffect(() => {
    init()
    const groupWidth = anchorRef.current.clientWidth
    setMinWidth(groupWidth)
  })

  function handleMenuItemClick(index: number) {
    setSelectedIndex(index);
    setOpen(false);
    if (onSelect) {
      const option = options[index]
      const value = option.value || option.id || option
      onSelect(value)
    }
  }

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event: any) {
    setOpen(false);
  }

  const selectLabel = () => {
    const option = options && options[selectedIndex]
    if (option) {
      return option.label || option.name || option || label
    }
    return label
  }

  return (
    <Box className={className}>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef as any}
        aria-label="split button">
        <Button onClick={(event: any) => {
          if (onClick) {
            onClick(event, options && options[selectedIndex])
          }
        }}>{selectLabel()}</Button>
        <Button
          size="small"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open}
        anchorEl={anchorRef && anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 999 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="menu-list-grow" style={paperStyle}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options && options.map((option: any, index: number) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                      className={classNames({
                        [classes.small]:size === 'small'
                      })}
                    > 
                      {option.name || option.label || option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
